@import url(https://fonts.googleapis.com/css2?family=Assistant:wght@200;400;600;700&display=swap);
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.75rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.125rem; }

h2, .h2 {
  font-size: 1.7rem; }

h3, .h3 {
  font-size: 1.4875rem; }

h4, .h4 {
  font-size: 1.275rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 0.85rem; }

.lead {
  font-size: 1.0625rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.0625rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.0625rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.74375rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.85rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.74375rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.0625rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.275rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.1375rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.1375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.1375rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.1375rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.74375rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.0625rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.34062rem;
  padding-bottom: 0.34062rem;
  margin-right: 0;
  font-size: 1.0625rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.0625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.0625rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.74375rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.775rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.6375rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.275rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.74375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.85rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
          animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: .75s linear infinite spinner-grow;
          animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.375rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.375rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important; }

.m-2 {
  margin: 0.75rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.75rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.m-4 {
  margin: 2.25rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.25rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.25rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.25rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.25rem !important; }

.m-5 {
  margin: 4.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 4.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4.5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.375rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.375rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.375rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.375rem !important; }

.p-2 {
  padding: 0.75rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.75rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.75rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.75rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.5rem !important; }

.p-4 {
  padding: 2.25rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.25rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.25rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.25rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.25rem !important; }

.p-5 {
  padding: 4.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 4.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 4.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 4.5rem !important; }

.m-n1 {
  margin: -0.375rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important; }

.m-n2 {
  margin: -0.75rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important; }

.m-n4 {
  margin: -2.25rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2.25rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2.25rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2.25rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2.25rem !important; }

.m-n5 {
  margin: -4.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -4.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -4.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -4.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -4.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.375rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important; }
  .m-sm-2 {
    margin: 0.75rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .m-sm-4 {
    margin: 2.25rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.25rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.25rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.25rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.25rem !important; }
  .m-sm-5 {
    margin: 4.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.375rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important; }
  .p-sm-2 {
    padding: 0.75rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important; }
  .p-sm-4 {
    padding: 2.25rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.25rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.25rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.25rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.25rem !important; }
  .p-sm-5 {
    padding: 4.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4.5rem !important; }
  .m-sm-n1 {
    margin: -0.375rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important; }
  .m-sm-n2 {
    margin: -0.75rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important; }
  .m-sm-n4 {
    margin: -2.25rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2.25rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2.25rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2.25rem !important; }
  .m-sm-n5 {
    margin: -4.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.375rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important; }
  .m-md-2 {
    margin: 0.75rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .m-md-4 {
    margin: 2.25rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.25rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.25rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.25rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.25rem !important; }
  .m-md-5 {
    margin: 4.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.375rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important; }
  .p-md-2 {
    padding: 0.75rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .p-md-4 {
    padding: 2.25rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.25rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.25rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.25rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.25rem !important; }
  .p-md-5 {
    padding: 4.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4.5rem !important; }
  .m-md-n1 {
    margin: -0.375rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important; }
  .m-md-n2 {
    margin: -0.75rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important; }
  .m-md-n4 {
    margin: -2.25rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2.25rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2.25rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2.25rem !important; }
  .m-md-n5 {
    margin: -4.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.375rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important; }
  .m-lg-2 {
    margin: 0.75rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .m-lg-4 {
    margin: 2.25rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.25rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.25rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.25rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.25rem !important; }
  .m-lg-5 {
    margin: 4.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.375rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important; }
  .p-lg-2 {
    padding: 0.75rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .p-lg-4 {
    padding: 2.25rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.25rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.25rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.25rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.25rem !important; }
  .p-lg-5 {
    padding: 4.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4.5rem !important; }
  .m-lg-n1 {
    margin: -0.375rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important; }
  .m-lg-n2 {
    margin: -0.75rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important; }
  .m-lg-n4 {
    margin: -2.25rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2.25rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2.25rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2.25rem !important; }
  .m-lg-n5 {
    margin: -4.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.375rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important; }
  .m-xl-2 {
    margin: 0.75rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .m-xl-4 {
    margin: 2.25rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2.25rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2.25rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2.25rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2.25rem !important; }
  .m-xl-5 {
    margin: 4.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.375rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important; }
  .p-xl-2 {
    padding: 0.75rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .p-xl-4 {
    padding: 2.25rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2.25rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2.25rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2.25rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2.25rem !important; }
  .p-xl-5 {
    padding: 4.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4.5rem !important; }
  .m-xl-n1 {
    margin: -0.375rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important; }
  .m-xl-n2 {
    margin: -0.75rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important; }
  .m-xl-n4 {
    margin: -2.25rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2.25rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2.25rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2.25rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2.25rem !important; }
  .m-xl-n5 {
    margin: -4.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.gradient-indigo-light-blue {
  background-image: linear-gradient(45deg, #c5cae9, #b3e5fc);
  background-repeat: repeat-x; }

.btn.gradient-indigo-light-blue:active, .btn.gradient-indigo-light-blue.active {
  background-image: linear-gradient(45deg, #c5cae9, #b3e5fc);
  background-repeat: repeat-x; }

.gradient-light-blue-indigo {
  background-image: linear-gradient(45deg, #b3e5fc, #9fa8da);
  background-repeat: repeat-x; }

.btn.gradient-light-blue-indigo:active, .btn.gradient-light-blue-indigo.active {
  background-image: linear-gradient(45deg, #b3e5fc, #9fa8da);
  background-repeat: repeat-x; }

.gradient-yellow-green {
  background-image: linear-gradient(45deg, #ffff8d, #b9f6ca);
  background-repeat: repeat-x; }

.btn.gradient-yellow-green:active, .btn.gradient-yellow-green.active {
  background-image: linear-gradient(45deg, #ffff8d, #b9f6ca);
  background-repeat: repeat-x; }

.gradient-orange-deep-orange {
  background-image: linear-gradient(45deg, #ffe0b2, #ffccbc);
  background-repeat: repeat-x; }

.btn.gradient-orange-deep-orange:active, .btn.gradient-orange-deep-orange.active {
  background-image: linear-gradient(45deg, #ffe0b2, #ffccbc);
  background-repeat: repeat-x; }

.gradient-deep-purple-purple {
  background-image: linear-gradient(45deg, #d1c4e9, #f3e5f5);
  background-repeat: repeat-x; }

.btn.gradient-deep-purple-purple:active, .btn.gradient-deep-purple-purple.active {
  background-image: linear-gradient(45deg, #d1c4e9, #f3e5f5);
  background-repeat: repeat-x; }

.gradient-red-pink {
  background-image: linear-gradient(45deg, #ff5252, #f48fb1);
  background-repeat: repeat-x; }

.btn.gradient-red-pink:active, .btn.gradient-red-pink.active {
  background-image: linear-gradient(45deg, #ff5252, #f48fb1);
  background-repeat: repeat-x; }

.gradient-light-green-amber {
  background-image: linear-gradient(45deg, #c5e1a5, #fff8e1);
  background-repeat: repeat-x; }

.btn.gradient-light-green-amber:active, .btn.gradient-light-green-amber.active {
  background-image: linear-gradient(45deg, #c5e1a5, #fff8e1);
  background-repeat: repeat-x; }

.gradient-amber-amber {
  background-image: linear-gradient(45deg, #ff6f00, #ffca28);
  background-repeat: repeat-x; }

.btn.gradient-amber-amber:active, .btn.gradient-amber-amber.active {
  background-image: linear-gradient(45deg, #ff6f00, #ffca28);
  background-repeat: repeat-x; }

.gradient-purple-pink {
  background-image: linear-gradient(45deg, #ea80fc, #fce4ec);
  background-repeat: repeat-x; }

.btn.gradient-purple-pink:active, .btn.gradient-purple-pink.active {
  background-image: linear-gradient(45deg, #ea80fc, #fce4ec);
  background-repeat: repeat-x; }

.gradient-indigo-dark-blue {
  background-image: linear-gradient(45deg, #3949ab, #4fc3f7);
  background-repeat: repeat-x; }

.btn.gradient-indigo-dark-blue:active, .btn.gradient-indigo-dark-blue.active {
  background-image: linear-gradient(45deg, #3949ab, #4fc3f7);
  background-repeat: repeat-x; }

.gradient-teal-cyan {
  background-image: linear-gradient(45deg, #e0f2f1, #00e5ff);
  background-repeat: repeat-x; }

.btn.gradient-teal-cyan:active, .btn.gradient-teal-cyan.active {
  background-image: linear-gradient(45deg, #e0f2f1, #00e5ff);
  background-repeat: repeat-x; }

.gradient-blue-grey-blue-grey {
  background-image: linear-gradient(45deg, #cfd8dc, #546e7a);
  background-repeat: repeat-x; }

.btn.gradient-blue-grey-blue-grey:active, .btn.gradient-blue-grey-blue-grey.active {
  background-image: linear-gradient(45deg, #cfd8dc, #546e7a);
  background-repeat: repeat-x; }

.gradient-cyan-dark-green {
  background-image: linear-gradient(45deg, #0097a7, #b2ff59);
  background-repeat: repeat-x; }

.btn.gradient-cyan-dark-green:active, .btn.gradient-cyan-dark-green.active {
  background-image: linear-gradient(45deg, #0097a7, #b2ff59);
  background-repeat: repeat-x; }

.gradient-orange-amber {
  background-image: linear-gradient(45deg, #e65100, #ffa000);
  background-repeat: repeat-x; }

.btn.gradient-orange-amber:active, .btn.gradient-orange-amber.active {
  background-image: linear-gradient(45deg, #e65100, #ffa000);
  background-repeat: repeat-x; }

.gradient-indigo-blue {
  background-image: linear-gradient(45deg, #303f9f, #1976d2);
  background-repeat: repeat-x; }

.btn.gradient-indigo-blue:active, .btn.gradient-indigo-blue.active {
  background-image: linear-gradient(45deg, #303f9f, #1976d2);
  background-repeat: repeat-x; }

.gradient-brown-brown {
  background-image: linear-gradient(45deg, #6d4c41, #d7ccc8);
  background-repeat: repeat-x; }

.btn.gradient-brown-brown:active, .btn.gradient-brown-brown.active {
  background-image: linear-gradient(45deg, #6d4c41, #d7ccc8);
  background-repeat: repeat-x; }

.gradient-blue-grey-blue {
  background-image: linear-gradient(45deg, #263238, #2979ff);
  background-repeat: repeat-x; }

.btn.gradient-blue-grey-blue:active, .btn.gradient-blue-grey-blue.active {
  background-image: linear-gradient(45deg, #263238, #2979ff);
  background-repeat: repeat-x; }

.gradient-purple-deep-orange {
  background-image: linear-gradient(45deg, #8e24aa, #ff6e40);
  background-repeat: repeat-x; }

.btn.gradient-purple-deep-orange:active, .btn.gradient-purple-deep-orange.active {
  background-image: linear-gradient(45deg, #8e24aa, #ff6e40);
  background-repeat: repeat-x; }

.gradient-green-teal {
  background-image: linear-gradient(45deg, #43a047, #1de9b6);
  background-repeat: repeat-x; }

.btn.gradient-green-teal:active, .btn.gradient-green-teal.active {
  background-image: linear-gradient(45deg, #43a047, #1de9b6);
  background-repeat: repeat-x; }

.gradient-purple-light-blue {
  background-image: linear-gradient(45deg, #e040fb, #4fc3f7);
  background-repeat: repeat-x; }

.btn.gradient-purple-light-blue:active, .btn.gradient-purple-light-blue.active {
  background-image: linear-gradient(45deg, #e040fb, #4fc3f7);
  background-repeat: repeat-x; }

.gradient-cyan-cyan {
  background-image: linear-gradient(45deg, #18ffff, #00e5ff);
  background-repeat: repeat-x; }

.btn.gradient-cyan-cyan:active, .btn.gradient-cyan-cyan.active {
  background-image: linear-gradient(45deg, #18ffff, #00e5ff);
  background-repeat: repeat-x; }

.gradient-yellow-teal {
  background-image: linear-gradient(45deg, #fff9c4, #64ffda);
  background-repeat: repeat-x; }

.btn.gradient-yellow-teal:active, .btn.gradient-yellow-teal.active {
  background-image: linear-gradient(45deg, #fff9c4, #64ffda);
  background-repeat: repeat-x; }

.gradient-purple-deep-purple {
  background-image: linear-gradient(45deg, #7b1fa2, #7c4dff);
  background-repeat: repeat-x; }

.btn.gradient-purple-deep-purple:active, .btn.gradient-purple-deep-purple.active {
  background-image: linear-gradient(45deg, #7b1fa2, #7c4dff);
  background-repeat: repeat-x; }

.gradient-cyan-light-green {
  background-image: linear-gradient(45deg, #00e5ff, #ccff90);
  background-repeat: repeat-x; }

.btn.gradient-cyan-light-green:active, .btn.gradient-cyan-light-green.active {
  background-image: linear-gradient(45deg, #00e5ff, #ccff90);
  background-repeat: repeat-x; }

.gradient-purple-amber {
  background-image: linear-gradient(45deg, #d500f9, #ffa000);
  background-repeat: repeat-x; }

.btn.gradient-purple-amber:active, .btn.gradient-purple-amber.active {
  background-image: linear-gradient(45deg, #d500f9, #ffa000);
  background-repeat: repeat-x; }

.gradient-indigo-purple {
  background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
  background-repeat: repeat-x; }

.btn.gradient-indigo-purple:active, .btn.gradient-indigo-purple.active {
  background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
  background-repeat: repeat-x; }

.gradient-deep-purple-blue {
  background-image: linear-gradient(45deg, #6200ea, #1976d2);
  background-repeat: repeat-x; }

.btn.gradient-deep-purple-blue:active, .btn.gradient-deep-purple-blue.active {
  background-image: linear-gradient(45deg, #6200ea, #1976d2);
  background-repeat: repeat-x; }

.gradient-deep-orange-orange {
  background-image: linear-gradient(45deg, #bf360c, #f57c00);
  background-repeat: repeat-x; }

.btn.gradient-deep-orange-orange:active, .btn.gradient-deep-orange-orange.active {
  background-image: linear-gradient(45deg, #bf360c, #f57c00);
  background-repeat: repeat-x; }

.gradient-light-blue-cyan {
  background-image: linear-gradient(45deg, #0288d1, #26c6da);
  background-repeat: repeat-x; }

.btn.gradient-light-blue-cyan:active, .btn.gradient-light-blue-cyan.active {
  background-image: linear-gradient(45deg, #0288d1, #26c6da);
  background-repeat: repeat-x; }

.gradient-blue-indigo {
  background-image: linear-gradient(45deg, #2962ff, #3949ab);
  background-repeat: repeat-x; }

.btn.gradient-blue-indigo:active, .btn.gradient-blue-indigo.active {
  background-image: linear-gradient(45deg, #2962ff, #3949ab);
  background-repeat: repeat-x; }

.gradient-strawberry {
  background-image: linear-gradient(45deg, #FE0B46, #FFAB96);
  background-repeat: repeat-x; }

.btn.gradient-strawberry:active, .btn.gradient-strawberry.active {
  background-image: linear-gradient(45deg, #FE0B46, #FFAB96);
  background-repeat: repeat-x; }

.gradient-blueberry {
  background-image: linear-gradient(45deg, #6078EA, #17EAD9);
  background-repeat: repeat-x; }

.btn.gradient-blueberry:active, .btn.gradient-blueberry.active {
  background-image: linear-gradient(45deg, #6078EA, #17EAD9);
  background-repeat: repeat-x; }

.gradient-mint {
  background-image: linear-gradient(45deg, #23BCBB, #45E994);
  background-repeat: repeat-x; }

.btn.gradient-mint:active, .btn.gradient-mint.active {
  background-image: linear-gradient(45deg, #23BCBB, #45E994);
  background-repeat: repeat-x; }

.gradient-orange {
  background-image: linear-gradient(45deg, #C471F3, #F671CD);
  background-repeat: repeat-x; }

.btn.gradient-orange:active, .btn.gradient-orange.active {
  background-image: linear-gradient(45deg, #C471F3, #F671CD);
  background-repeat: repeat-x; }

.gradient-pomegranate {
  background-image: linear-gradient(45deg, #9B3cb7, #FF396f);
  background-repeat: repeat-x; }

.btn.gradient-pomegranate:active, .btn.gradient-pomegranate.active {
  background-image: linear-gradient(45deg, #9B3cb7, #FF396f);
  background-repeat: repeat-x; }

.gradient-green-tea {
  background-image: linear-gradient(45deg, #004b91, #78cc37);
  background-repeat: repeat-x; }

.btn.gradient-green-tea:active, .btn.gradient-green-tea.active {
  background-image: linear-gradient(45deg, #004b91, #78cc37);
  background-repeat: repeat-x; }

.gradient-blackberry {
  background-image: linear-gradient(45deg, #843cf7, #38b8f2);
  background-repeat: repeat-x; }

.btn.gradient-blackberry:active, .btn.gradient-blackberry.active {
  background-image: linear-gradient(45deg, #843cf7, #38b8f2);
  background-repeat: repeat-x; }

.gradient-plum {
  background-image: linear-gradient(45deg, #e35c67, #381ce2);
  background-repeat: repeat-x; }

.btn.gradient-plum:active, .btn.gradient-plum.active {
  background-image: linear-gradient(45deg, #e35c67, #381ce2);
  background-repeat: repeat-x; }

.gradient-passion-fruit {
  background-image: linear-gradient(45deg, #8137F7, #F6AB3E);
  background-repeat: repeat-x; }

.btn.gradient-passion-fruit:active, .btn.gradient-passion-fruit.active {
  background-image: linear-gradient(45deg, #8137F7, #F6AB3E);
  background-repeat: repeat-x; }

.gradient-sublime-vivid {
  background-image: linear-gradient(45deg, #FC466B, #3F5EFB);
  background-repeat: repeat-x; }

.btn.gradient-sublime-vivid:active, .btn.gradient-sublime-vivid.active {
  background-image: linear-gradient(45deg, #FC466B, #3F5EFB);
  background-repeat: repeat-x; }

.gradient-king-yna {
  background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
  background-repeat: repeat-x; }

.btn.gradient-king-yna:active, .btn.gradient-king-yna.active {
  background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
  background-repeat: repeat-x; }

.gradient-summer {
  background-image: linear-gradient(45deg, #22c1c3, #fdbb2d);
  background-repeat: repeat-x; }

.btn.gradient-summer:active, .btn.gradient-summer.active {
  background-image: linear-gradient(45deg, #22c1c3, #fdbb2d);
  background-repeat: repeat-x; }

.gradient-crystal-clear {
  background-image: linear-gradient(45deg, #159957, #155799);
  background-repeat: repeat-x; }

.btn.gradient-crystal-clear:active, .btn.gradient-crystal-clear.active {
  background-image: linear-gradient(45deg, #159957, #155799);
  background-repeat: repeat-x; }

.gradient-celestial {
  background-image: linear-gradient(45deg, #C33764, #1D2671);
  background-repeat: repeat-x; }

.btn.gradient-celestial:active, .btn.gradient-celestial.active {
  background-image: linear-gradient(45deg, #C33764, #1D2671);
  background-repeat: repeat-x; }

.gradient-mini {
  background-image: linear-gradient(45deg, #30E8BF, #FF8235);
  background-repeat: repeat-x; }

.btn.gradient-mini:active, .btn.gradient-mini.active {
  background-image: linear-gradient(45deg, #30E8BF, #FF8235);
  background-repeat: repeat-x; }

.gradient-ibiza-sunset {
  background-image: linear-gradient(45deg, #ee0979, #ff6a00);
  background-repeat: repeat-x; }

.btn.gradient-ibiza-sunset:active, .btn.gradient-ibiza-sunset.active {
  background-image: linear-gradient(45deg, #ee0979, #ff6a00);
  background-repeat: repeat-x; }

.gradient-dawn {
  background-image: linear-gradient(45deg, #F3904F, #3B4371);
  background-repeat: repeat-x; }

.btn.gradient-dawn:active, .btn.gradient-dawn.active {
  background-image: linear-gradient(45deg, #F3904F, #3B4371);
  background-repeat: repeat-x; }

.gradient-brady-brady-fun-fun {
  background-image: linear-gradient(45deg, #00c3ff, #ffff1c);
  background-repeat: repeat-x; }

.btn.gradient-brady-brady-fun-fun:active, .btn.gradient-brady-brady-fun-fun.active {
  background-image: linear-gradient(45deg, #00c3ff, #ffff1c);
  background-repeat: repeat-x; }

.gradient-cosmic-fusion {
  background-image: linear-gradient(45deg, #ff00cc, #333399);
  background-repeat: repeat-x; }

.btn.gradient-cosmic-fusion:active, .btn.gradient-cosmic-fusion.active {
  background-image: linear-gradient(45deg, #ff00cc, #333399);
  background-repeat: repeat-x; }

.gradient-nepal {
  background-image: linear-gradient(45deg, #de6161, #2657eb);
  background-repeat: repeat-x; }

.btn.gradient-nepal:active, .btn.gradient-nepal.active {
  background-image: linear-gradient(45deg, #de6161, #2657eb);
  background-repeat: repeat-x; }

.gradient-love-couple {
  background-image: linear-gradient(45deg, #3a6186, #89253e);
  background-repeat: repeat-x; }

.btn.gradient-love-couple:active, .btn.gradient-love-couple.active {
  background-image: linear-gradient(45deg, #3a6186, #89253e);
  background-repeat: repeat-x; }

.gradient-sunset {
  background-image: linear-gradient(45deg, #0B486B, #F56217);
  background-repeat: repeat-x; }

.btn.gradient-sunset:active, .btn.gradient-sunset.active {
  background-image: linear-gradient(45deg, #0B486B, #F56217);
  background-repeat: repeat-x; }

.gradient-grapefruit-sunset {
  background-image: linear-gradient(45deg, #e96443, #904e95);
  background-repeat: repeat-x; }

.btn.gradient-grapefruit-sunset:active, .btn.gradient-grapefruit-sunset.active {
  background-image: linear-gradient(45deg, #e96443, #904e95);
  background-repeat: repeat-x; }

.gradient-politics {
  background-image: linear-gradient(45deg, #2196f3, #f44336);
  background-repeat: repeat-x; }

.btn.gradient-politics:active, .btn.gradient-politics.active {
  background-image: linear-gradient(45deg, #2196f3, #f44336);
  background-repeat: repeat-x; }

.gradient-red-ocean {
  background-image: linear-gradient(45deg, #1D4350, #A43931);
  background-repeat: repeat-x; }

.btn.gradient-red-ocean:active, .btn.gradient-red-ocean.active {
  background-image: linear-gradient(45deg, #1D4350, #A43931);
  background-repeat: repeat-x; }

.gradient-back-to-earth {
  background-image: linear-gradient(45deg, #20CAE4, #1CE5B7);
  background-repeat: repeat-x; }

.btn.gradient-back-to-earth:active, .btn.gradient-back-to-earth.active {
  background-image: linear-gradient(45deg, #20CAE4, #1CE5B7);
  background-repeat: repeat-x; }

.gradient-flickr {
  background-image: linear-gradient(45deg, #33001b, #ff0084);
  background-repeat: repeat-x; }

.btn.gradient-flickr:active, .btn.gradient-flickr.active {
  background-image: linear-gradient(45deg, #33001b, #ff0084);
  background-repeat: repeat-x; }

.gradient-virgin-america {
  background-image: linear-gradient(45deg, #7b4397, #dc2430);
  background-repeat: repeat-x; }

.btn.gradient-virgin-america:active, .btn.gradient-virgin-america.active {
  background-image: linear-gradient(45deg, #7b4397, #dc2430);
  background-repeat: repeat-x; }

.gradient-purple-bliss {
  background-image: linear-gradient(45deg, #360033, #0b8793);
  background-repeat: repeat-x; }

.btn.gradient-purple-bliss:active, .btn.gradient-purple-bliss.active {
  background-image: linear-gradient(45deg, #360033, #0b8793);
  background-repeat: repeat-x; }

.gradient-man-of-steel {
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x; }

.btn.gradient-man-of-steel:active, .btn.gradient-man-of-steel.active {
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x; }

.gradient-shdow-night {
  background-image: linear-gradient(45deg, #000000, #53346D);
  background-repeat: repeat-x; }

.btn.gradient-shdow-night:active, .btn.gradient-shdow-night.active {
  background-image: linear-gradient(45deg, #000000, #53346D);
  background-repeat: repeat-x; }

.gradient-dracula {
  background-image: linear-gradient(45deg, #DC2424, #4A569D);
  background-repeat: repeat-x; }

.btn.gradient-dracula:active, .btn.gradient-dracula.active {
  background-image: linear-gradient(45deg, #DC2424, #4A569D);
  background-repeat: repeat-x; }

.gradient-bloody-mary {
  background-image: linear-gradient(45deg, #FF512F, #DD2476);
  background-repeat: repeat-x; }

.btn.gradient-bloody-mary:active, .btn.gradient-bloody-mary.active {
  background-image: linear-gradient(45deg, #FF512F, #DD2476);
  background-repeat: repeat-x; }

.gradient-harmonic-energy {
  background-image: linear-gradient(45deg, #16A085, #F4D03F);
  background-repeat: repeat-x; }

.btn.gradient-harmonic-energy:active, .btn.gradient-harmonic-energy.active {
  background-image: linear-gradient(45deg, #16A085, #F4D03F);
  background-repeat: repeat-x; }

.gradient-purple-love {
  background-image: linear-gradient(45deg, #cc2b5e, #753a88);
  background-repeat: repeat-x; }

.btn.gradient-purple-love:active, .btn.gradient-purple-love.active {
  background-image: linear-gradient(45deg, #cc2b5e, #753a88);
  background-repeat: repeat-x; }

.white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }
  .bg-white .card-header, .bg-white .card-footer {
    background-color: transparent; }

.toast-white {
  background-color: #FFFFFF; }

.alert-white {
  border-color: #FFFFFF !important;
  background-color: white !important;
  color: #a6a6a6 !important; }
  .alert-white .alert-link {
    color: #919191 !important; }

.border-white {
  border-color: #FFFFFF; }

.border-top-white {
  border-top-color: #FFFFFF; }

.border-bottom-white {
  border-bottom-color: #FFFFFF; }

[dir="ltr"] .border-left-white {
  border-left-color: #FFFFFF; }

[dir="ltr"] .border-right-white {
  border-right-color: #FFFFFF; }

[dir="rtl"] .border-left-white {
  border-right-color: #FFFFFF; }

[dir="rtl"] .border-right-white {
  border-left-color: #FFFFFF; }

.badge-white {
  background-color: #FFFFFF; }

.panel-white {
  border-color: #FFFFFF; }
  .panel-white .panel-heading {
    color: #FFF;
    border-color: #FFFFFF;
    background-color: white; }

.bg-white.tag-glow, .border-white.tag-glow {
  box-shadow: 0px 0px 10px #FFFFFF; }

.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.card.card-outline-white {
  border-width: 1px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: transparent; }
  .card.card-outline-white .card-header, .card.card-outline-white .card-footer {
    background-color: transparent; }

.btn-white.btn-flat {
  background-color: transparent !important;
  color: #FFFFFF;
  border: none; }

.btn-white.btn-raised, .btn-white.btn-fab {
  background-color: #FFFFFF !important;
  color: #fff !important;
  border-color: #FFFFFF; }
  .btn-white.btn-raised.active, .btn-white.btn-fab.active {
    background-color: #ededed !important;
    border-color: #ededed !important; }

.btn-group-raised .btn-white {
  background-color: #FFFFFF !important;
  color: #fff !important; }

.btn-outline-white {
  border: 1px solid;
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
  box-shadow: none !important; }
  .btn-outline-white:focus {
    background-color: transparent !important;
    color: #FFFFFF !important;
    box-shadow: transparent !important; }
  .btn-outline-white.active {
    background-color: #FFFFFF !important;
    color: #FFF !important; }
  .btn-outline-white:hover {
    background-color: #f7f7f7 !important;
    color: #FFF !important; }
    .btn-outline-white:hover svg {
      color: #FFF !important; }

.btn-outline-white.btn-raised, .btn-outline-white.btn-fab {
  border: 1px solid;
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
  box-shadow: none !important; }
  .btn-outline-white.btn-raised:focus, .btn-outline-white.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-white.btn-raised.active, .btn-outline-white.btn-fab.active {
    background-color: #FFFFFF !important;
    color: #FFF !important; }
  .btn-outline-white.btn-raised:hover, .btn-outline-white.btn-fab:hover {
    background-color: #f7f7f7 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-white {
  background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-white .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }
  .bg-black .card-header, .bg-black .card-footer {
    background-color: transparent; }

.toast-black {
  background-color: #000000; }

.alert-black {
  border-color: #000000 !important;
  background-color: #121212 !important;
  color: black !important; }
  .alert-black .alert-link {
    color: black !important; }

.border-black {
  border-color: #000000; }

.border-top-black {
  border-top-color: #000000; }

.border-bottom-black {
  border-bottom-color: #000000; }

[dir="ltr"] .border-left-black {
  border-left-color: #000000; }

[dir="ltr"] .border-right-black {
  border-right-color: #000000; }

[dir="rtl"] .border-left-black {
  border-right-color: #000000; }

[dir="rtl"] .border-right-black {
  border-left-color: #000000; }

.badge-black {
  background-color: #000000; }

.panel-black {
  border-color: #000000; }
  .panel-black .panel-heading {
    color: #FFF;
    border-color: #000000;
    background-color: #0d0d0d; }

.bg-black.tag-glow, .border-black.tag-glow {
  box-shadow: 0px 0px 10px #000000; }

.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.card.card-outline-black {
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  background-color: transparent; }
  .card.card-outline-black .card-header, .card.card-outline-black .card-footer {
    background-color: transparent; }

.btn-black.btn-flat {
  background-color: transparent !important;
  color: #000000;
  border: none; }

.btn-black.btn-raised, .btn-black.btn-fab {
  background-color: #000000 !important;
  color: #fff !important;
  border-color: #000000; }
  .btn-black.btn-raised.active, .btn-black.btn-fab.active {
    background-color: black !important;
    border-color: black !important; }

.btn-group-raised .btn-black {
  background-color: #000000 !important;
  color: #fff !important; }

.btn-outline-black {
  border: 1px solid;
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  box-shadow: none !important; }
  .btn-outline-black:focus {
    background-color: transparent !important;
    color: #000000 !important;
    box-shadow: transparent !important; }
  .btn-outline-black.active {
    background-color: #000000 !important;
    color: #FFF !important; }
  .btn-outline-black:hover {
    background-color: black !important;
    color: #FFF !important; }
    .btn-outline-black:hover svg {
      color: #FFF !important; }

.btn-outline-black.btn-raised, .btn-outline-black.btn-fab {
  border: 1px solid;
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  box-shadow: none !important; }
  .btn-outline-black.btn-raised:focus, .btn-outline-black.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-black.btn-raised.active, .btn-outline-black.btn-fab.active {
    background-color: #000000 !important;
    color: #FFF !important; }
  .btn-outline-black.btn-raised:hover, .btn-outline-black.btn-fab:hover {
    background-color: black !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-black {
  background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-webkit-progress-value {
    background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-moz-progress-bar {
    background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-ms-fill {
    background-color: #000000; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-black .progress-bar {
      background-color: #000000; } }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.primary.lighten-5 {
  color: #E0F3F4 !important; }

.bg-primary.bg-lighten-5 {
  background-color: #E0F3F4 !important; }

.btn-primary.btn-lighten-5 {
  border-color: #008B8E !important;
  background-color: #E0F3F4 !important; }
  .btn-primary.btn-lighten-5:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-5:focus, .btn-primary.btn-lighten-5:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-5 {
  border-color: #E0F3F4 !important;
  color: #E0F3F4 !important; }
  .btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #E0F3F4 !important; }

.progress-primary.progress-lighten-5 {
  background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-ms-fill {
    background-color: #E0F3F4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-5 .progress-bar {
      background-color: #E0F3F4; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F3F4 !important; }

.border-primary.border-lighten-5 {
  border: 1px solid #E0F3F4 !important; }

.border-top-primary.border-top-lighten-5 {
  border-top: 1px solid #E0F3F4 !important; }

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F3F4 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-5 {
  border-left: 1px solid #E0F3F4 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-5 {
  border-right: 1px solid #E0F3F4 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-5 {
  border-right: 1px solid #E0F3F4 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-5 {
  border-left: 1px solid #E0F3F4 !important; }

.overlay-primary.overlay-lighten-5 {
  background: #E0F3F4;
  /* The Fallback */
  background: rgba(224, 243, 244, 0.8); }

.primary.lighten-4 {
  color: #B3E2E3 !important; }

.bg-primary.bg-lighten-4 {
  background-color: #B3E2E3 !important; }

.btn-primary.btn-lighten-4 {
  border-color: #008B8E !important;
  background-color: #B3E2E3 !important; }
  .btn-primary.btn-lighten-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-4:focus, .btn-primary.btn-lighten-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-4 {
  border-color: #B3E2E3 !important;
  color: #B3E2E3 !important; }
  .btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #B3E2E3 !important; }

.progress-primary.progress-lighten-4 {
  background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-ms-fill {
    background-color: #B3E2E3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-4 .progress-bar {
      background-color: #B3E2E3; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B3E2E3 !important; }

.border-primary.border-lighten-4 {
  border: 1px solid #B3E2E3 !important; }

.border-top-primary.border-top-lighten-4 {
  border-top: 1px solid #B3E2E3 !important; }

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom: 1px solid #B3E2E3 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-4 {
  border-left: 1px solid #B3E2E3 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-4 {
  border-right: 1px solid #B3E2E3 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-4 {
  border-right: 1px solid #B3E2E3 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-4 {
  border-left: 1px solid #B3E2E3 !important; }

.overlay-primary.overlay-lighten-4 {
  background: #B3E2E3;
  /* The Fallback */
  background: rgba(179, 226, 227, 0.8); }

.primary.lighten-3 {
  color: #80CED0 !important; }

.bg-primary.bg-lighten-3 {
  background-color: #80CED0 !important; }

.btn-primary.btn-lighten-3 {
  border-color: #008B8E !important;
  background-color: #80CED0 !important; }
  .btn-primary.btn-lighten-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-3:focus, .btn-primary.btn-lighten-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-3 {
  border-color: #80CED0 !important;
  color: #80CED0 !important; }
  .btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #80CED0 !important; }

.progress-primary.progress-lighten-3 {
  background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-ms-fill {
    background-color: #80CED0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-3 .progress-bar {
      background-color: #80CED0; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80CED0 !important; }

.border-primary.border-lighten-3 {
  border: 1px solid #80CED0 !important; }

.border-top-primary.border-top-lighten-3 {
  border-top: 1px solid #80CED0 !important; }

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom: 1px solid #80CED0 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-3 {
  border-left: 1px solid #80CED0 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-3 {
  border-right: 1px solid #80CED0 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-3 {
  border-right: 1px solid #80CED0 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-3 {
  border-left: 1px solid #80CED0 !important; }

.overlay-primary.overlay-lighten-3 {
  background: #80CED0;
  /* The Fallback */
  background: rgba(128, 206, 208, 0.8); }

.primary.lighten-2 {
  color: #4DBABD !important; }

.bg-primary.bg-lighten-2 {
  background-color: #4DBABD !important; }

.btn-primary.btn-lighten-2 {
  border-color: #008B8E !important;
  background-color: #4DBABD !important; }
  .btn-primary.btn-lighten-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-2:focus, .btn-primary.btn-lighten-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-2 {
  border-color: #4DBABD !important;
  color: #4DBABD !important; }
  .btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #4DBABD !important; }

.progress-primary.progress-lighten-2 {
  background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-ms-fill {
    background-color: #4DBABD; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-2 .progress-bar {
      background-color: #4DBABD; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DBABD !important; }

.border-primary.border-lighten-2 {
  border: 1px solid #4DBABD !important; }

.border-top-primary.border-top-lighten-2 {
  border-top: 1px solid #4DBABD !important; }

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DBABD !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-2 {
  border-left: 1px solid #4DBABD !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-2 {
  border-right: 1px solid #4DBABD !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-2 {
  border-right: 1px solid #4DBABD !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-2 {
  border-left: 1px solid #4DBABD !important; }

.overlay-primary.overlay-lighten-2 {
  background: #4DBABD;
  /* The Fallback */
  background: rgba(77, 186, 189, 0.8); }

.primary.lighten-1 {
  color: #26ACAE !important; }

.bg-primary.bg-lighten-1 {
  background-color: #26ACAE !important; }

.btn-primary.btn-lighten-1 {
  border-color: #008B8E !important;
  background-color: #26ACAE !important; }
  .btn-primary.btn-lighten-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-1:focus, .btn-primary.btn-lighten-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-1 {
  border-color: #26ACAE !important;
  color: #26ACAE !important; }
  .btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #26ACAE !important; }

.progress-primary.progress-lighten-1 {
  background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-ms-fill {
    background-color: #26ACAE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-1 .progress-bar {
      background-color: #26ACAE; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26ACAE !important; }

.border-primary.border-lighten-1 {
  border: 1px solid #26ACAE !important; }

.border-top-primary.border-top-lighten-1 {
  border-top: 1px solid #26ACAE !important; }

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom: 1px solid #26ACAE !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-1 {
  border-left: 1px solid #26ACAE !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-1 {
  border-right: 1px solid #26ACAE !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-1 {
  border-right: 1px solid #26ACAE !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-1 {
  border-left: 1px solid #26ACAE !important; }

.overlay-primary.overlay-lighten-1 {
  background: #26ACAE;
  /* The Fallback */
  background: rgba(38, 172, 174, 0.8); }

.primary {
  color: #047bf8 !important; }

.bg-primary {
  background-color: #047bf8 !important; }
  .bg-primary .card-header, .bg-primary .card-footer {
    background-color: transparent; }

.toast-primary {
  background-color: #047bf8; }

.alert-primary {
  border-color: #047bf8 !important;
  background-color: #248dfb !important;
  color: #012448 !important; }
  .alert-primary .alert-link {
    color: #011020 !important; }

.border-primary {
  border-color: #047bf8; }

.border-top-primary {
  border-top-color: #047bf8; }

.border-bottom-primary {
  border-bottom-color: #047bf8; }

[dir="ltr"] .border-left-primary {
  border-left-color: #047bf8; }

[dir="ltr"] .border-right-primary {
  border-right-color: #047bf8; }

[dir="rtl"] .border-left-primary {
  border-right-color: #047bf8; }

[dir="rtl"] .border-right-primary {
  border-left-color: #047bf8; }

.badge-primary {
  background-color: #047bf8; }

.panel-primary {
  border-color: #047bf8; }
  .panel-primary .panel-heading {
    color: #FFF;
    border-color: #047bf8;
    background-color: #1a88fb; }

.bg-primary.tag-glow, .border-primary.tag-glow {
  box-shadow: 0px 0px 10px #047bf8; }

.overlay-primary {
  background: #047bf8;
  /* The Fallback */
  background: rgba(4, 123, 248, 0.8); }

.card.card-outline-primary {
  border-width: 1px;
  border-style: solid;
  border-color: #047bf8;
  background-color: transparent; }
  .card.card-outline-primary .card-header, .card.card-outline-primary .card-footer {
    background-color: transparent; }

.btn-primary.btn-flat {
  background-color: transparent !important;
  color: #047bf8;
  border: none; }

.btn-primary.btn-raised, .btn-primary.btn-fab {
  background-color: #047bf8 !important;
  color: #fff !important;
  border-color: #047bf8; }
  .btn-primary.btn-raised.active, .btn-primary.btn-fab.active {
    background-color: #036ad5 !important;
    border-color: #036ad5 !important; }

.btn-group-raised .btn-primary {
  background-color: #047bf8 !important;
  color: #fff !important; }

.btn-outline-primary {
  border: 1px solid;
  border-color: #047bf8;
  background-color: transparent;
  color: #047bf8;
  box-shadow: none !important; }
  .btn-outline-primary:focus {
    background-color: transparent !important;
    color: #047bf8 !important;
    box-shadow: transparent !important; }
  .btn-outline-primary.active {
    background-color: #047bf8 !important;
    color: #FFF !important; }
  .btn-outline-primary:hover {
    background-color: #0474e9 !important;
    color: #FFF !important; }
    .btn-outline-primary:hover svg {
      color: #FFF !important; }

.btn-outline-primary.btn-raised, .btn-outline-primary.btn-fab {
  border: 1px solid;
  border-color: #047bf8;
  background-color: transparent;
  color: #047bf8;
  box-shadow: none !important; }
  .btn-outline-primary.btn-raised:focus, .btn-outline-primary.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-primary.btn-raised.active, .btn-outline-primary.btn-fab.active {
    background-color: #047bf8 !important;
    color: #FFF !important; }
  .btn-outline-primary.btn-raised:hover, .btn-outline-primary.btn-fab:hover {
    background-color: #0474e9 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-primary {
  background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-webkit-progress-value {
    background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-moz-progress-bar {
    background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-ms-fill {
    background-color: #047bf8; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-primary .progress-bar {
      background-color: #047bf8; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #047bf8 !important; }

.primary.darken-1 {
  color: #009598 !important; }

.bg-primary.bg-darken-1 {
  background-color: #009598 !important; }

.btn-primary.btn-darken-1 {
  border-color: #008B8E !important;
  background-color: #009598 !important; }
  .btn-primary.btn-darken-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-1:focus, .btn-primary.btn-darken-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-1 {
  border-color: #009598 !important;
  color: #009598 !important; }
  .btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #009598 !important; }

.progress-primary.progress-darken-1 {
  background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-webkit-progress-value {
    background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-moz-progress-bar {
    background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-ms-fill {
    background-color: #009598; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-1 .progress-bar {
      background-color: #009598; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009598 !important; }

.border-primary.border-darken-1 {
  border: 1px solid #009598 !important; }

.border-top-primary.border-top-darken-1 {
  border-top: 1px solid #009598 !important; }

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom: 1px solid #009598 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-1 {
  border-left: 1px solid #009598 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-1 {
  border-right: 1px solid #009598 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-1 {
  border-right: 1px solid #009598 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-1 {
  border-left: 1px solid #009598 !important; }

.overlay-primary.overlay-darken-1 {
  background: #009598;
  /* The Fallback */
  background: rgba(0, 149, 152, 0.8); }

.primary.darken-2 {
  color: #008B8E !important; }

.bg-primary.bg-darken-2 {
  background-color: #008B8E !important; }

.btn-primary.btn-darken-2 {
  border-color: #008B8E !important;
  background-color: #008B8E !important; }
  .btn-primary.btn-darken-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-2:focus, .btn-primary.btn-darken-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-2 {
  border-color: #008B8E !important;
  color: #008B8E !important; }
  .btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #008B8E !important; }

.progress-primary.progress-darken-2 {
  background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-webkit-progress-value {
    background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-moz-progress-bar {
    background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-ms-fill {
    background-color: #008B8E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-2 .progress-bar {
      background-color: #008B8E; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008B8E !important; }

.border-primary.border-darken-2 {
  border: 1px solid #008B8E !important; }

.border-top-primary.border-top-darken-2 {
  border-top: 1px solid #008B8E !important; }

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom: 1px solid #008B8E !important; }

[dir="ltr"] .border-left-primary.border-left-darken-2 {
  border-left: 1px solid #008B8E !important; }

[dir="ltr"] .border-right-primary.border-right-darken-2 {
  border-right: 1px solid #008B8E !important; }

[dir="rtl"] .border-left-primary.border-left-darken-2 {
  border-right: 1px solid #008B8E !important; }

[dir="rtl"] .border-right-primary.border-right-darken-2 {
  border-left: 1px solid #008B8E !important; }

.overlay-primary.overlay-darken-2 {
  background: #008B8E;
  /* The Fallback */
  background: rgba(0, 139, 142, 0.8); }

.primary.darken-3 {
  color: #008184 !important; }

.bg-primary.bg-darken-3 {
  background-color: #008184 !important; }

.btn-primary.btn-darken-3 {
  border-color: #008B8E !important;
  background-color: #008184 !important; }
  .btn-primary.btn-darken-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-3:focus, .btn-primary.btn-darken-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-3 {
  border-color: #008184 !important;
  color: #008184 !important; }
  .btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #008184 !important; }

.progress-primary.progress-darken-3 {
  background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-webkit-progress-value {
    background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-moz-progress-bar {
    background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-ms-fill {
    background-color: #008184; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-3 .progress-bar {
      background-color: #008184; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008184 !important; }

.border-primary.border-darken-3 {
  border: 1px solid #008184 !important; }

.border-top-primary.border-top-darken-3 {
  border-top: 1px solid #008184 !important; }

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom: 1px solid #008184 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-3 {
  border-left: 1px solid #008184 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-3 {
  border-right: 1px solid #008184 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-3 {
  border-right: 1px solid #008184 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-3 {
  border-left: 1px solid #008184 !important; }

.overlay-primary.overlay-darken-3 {
  background: #008184;
  /* The Fallback */
  background: rgba(0, 129, 132, 0.8); }

.primary.darken-4 {
  color: #006F73 !important; }

.bg-primary.bg-darken-4 {
  background-color: #006F73 !important; }

.btn-primary.btn-darken-4 {
  border-color: #008B8E !important;
  background-color: #006F73 !important; }
  .btn-primary.btn-darken-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-4:focus, .btn-primary.btn-darken-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-4 {
  border-color: #006F73 !important;
  color: #006F73 !important; }
  .btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #006F73 !important; }

.progress-primary.progress-darken-4 {
  background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-ms-fill {
    background-color: #006F73; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-4 .progress-bar {
      background-color: #006F73; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006F73 !important; }

.border-primary.border-darken-4 {
  border: 1px solid #006F73 !important; }

.border-top-primary.border-top-darken-4 {
  border-top: 1px solid #006F73 !important; }

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom: 1px solid #006F73 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-4 {
  border-left: 1px solid #006F73 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-4 {
  border-right: 1px solid #006F73 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-4 {
  border-right: 1px solid #006F73 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-4 {
  border-left: 1px solid #006F73 !important; }

.overlay-primary.overlay-darken-4 {
  background: #006F73;
  /* The Fallback */
  background: rgba(0, 111, 115, 0.8); }

.primary.accent-1 {
  color: #A1FBFF !important; }

.bg-primary.bg-accent-1 {
  background-color: #A1FBFF !important; }

.btn-primary.btn-accent-1 {
  border-color: #008B8E !important;
  background-color: #A1FBFF !important; }
  .btn-primary.btn-accent-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-1:focus, .btn-primary.btn-accent-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-1 {
  border-color: #A1FBFF !important;
  color: #A1FBFF !important; }
  .btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #A1FBFF !important; }

.progress-primary.progress-accent-1 {
  background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-webkit-progress-value {
    background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-moz-progress-bar {
    background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-ms-fill {
    background-color: #A1FBFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-1 .progress-bar {
      background-color: #A1FBFF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A1FBFF !important; }

.border-primary.border-accent-1 {
  border: 1px solid #A1FBFF !important; }

.border-top-primary.border-top-accent-1 {
  border-top: 1px solid #A1FBFF !important; }

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom: 1px solid #A1FBFF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-1 {
  border-left: 1px solid #A1FBFF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-1 {
  border-right: 1px solid #A1FBFF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-1 {
  border-right: 1px solid #A1FBFF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-1 {
  border-left: 1px solid #A1FBFF !important; }

.overlay-primary.overlay-accent-1 {
  background: #A1FBFF;
  /* The Fallback */
  background: rgba(161, 251, 255, 0.8); }

.primary.accent-2 {
  color: #6EF9FF !important; }

.bg-primary.bg-accent-2 {
  background-color: #6EF9FF !important; }

.btn-primary.btn-accent-2 {
  border-color: #008B8E !important;
  background-color: #6EF9FF !important; }
  .btn-primary.btn-accent-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-2:focus, .btn-primary.btn-accent-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-2 {
  border-color: #6EF9FF !important;
  color: #6EF9FF !important; }
  .btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #6EF9FF !important; }

.progress-primary.progress-accent-2 {
  background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-webkit-progress-value {
    background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-moz-progress-bar {
    background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-ms-fill {
    background-color: #6EF9FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-2 .progress-bar {
      background-color: #6EF9FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6EF9FF !important; }

.border-primary.border-accent-2 {
  border: 1px solid #6EF9FF !important; }

.border-top-primary.border-top-accent-2 {
  border-top: 1px solid #6EF9FF !important; }

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom: 1px solid #6EF9FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-2 {
  border-left: 1px solid #6EF9FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-2 {
  border-right: 1px solid #6EF9FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-2 {
  border-right: 1px solid #6EF9FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-2 {
  border-left: 1px solid #6EF9FF !important; }

.overlay-primary.overlay-accent-2 {
  background: #6EF9FF;
  /* The Fallback */
  background: rgba(110, 249, 255, 0.8); }

.primary.accent-3 {
  color: #3BF7FF !important; }

.bg-primary.bg-accent-3 {
  background-color: #3BF7FF !important; }

.btn-primary.btn-accent-3 {
  border-color: #008B8E !important;
  background-color: #3BF7FF !important; }
  .btn-primary.btn-accent-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-3:focus, .btn-primary.btn-accent-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-3 {
  border-color: #3BF7FF !important;
  color: #3BF7FF !important; }
  .btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #3BF7FF !important; }

.progress-primary.progress-accent-3 {
  background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-ms-fill {
    background-color: #3BF7FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-3 .progress-bar {
      background-color: #3BF7FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3BF7FF !important; }

.border-primary.border-accent-3 {
  border: 1px solid #3BF7FF !important; }

.border-top-primary.border-top-accent-3 {
  border-top: 1px solid #3BF7FF !important; }

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom: 1px solid #3BF7FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-3 {
  border-left: 1px solid #3BF7FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-3 {
  border-right: 1px solid #3BF7FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-3 {
  border-right: 1px solid #3BF7FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-3 {
  border-left: 1px solid #3BF7FF !important; }

.overlay-primary.overlay-accent-3 {
  background: #3BF7FF;
  /* The Fallback */
  background: rgba(59, 247, 255, 0.8); }

.primary.accent-4 {
  color: #22F6FF !important; }

.bg-primary.bg-accent-4 {
  background-color: #22F6FF !important; }

.btn-primary.btn-accent-4 {
  border-color: #008B8E !important;
  background-color: #22F6FF !important; }
  .btn-primary.btn-accent-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-4:focus, .btn-primary.btn-accent-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-4 {
  border-color: #22F6FF !important;
  color: #22F6FF !important; }
  .btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #22F6FF !important; }

.progress-primary.progress-accent-4 {
  background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-webkit-progress-value {
    background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-moz-progress-bar {
    background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-ms-fill {
    background-color: #22F6FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-4 .progress-bar {
      background-color: #22F6FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #22F6FF !important; }

.border-primary.border-accent-4 {
  border: 1px solid #22F6FF !important; }

.border-top-primary.border-top-accent-4 {
  border-top: 1px solid #22F6FF !important; }

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom: 1px solid #22F6FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-4 {
  border-left: 1px solid #22F6FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-4 {
  border-right: 1px solid #22F6FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-4 {
  border-right: 1px solid #22F6FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-4 {
  border-left: 1px solid #22F6FF !important; }

.overlay-primary.overlay-accent-4 {
  background: #22F6FF;
  /* The Fallback */
  background: rgba(34, 246, 255, 0.8); }

.success.lighten-5 {
  color: #E2F8F0 !important; }

.bg-success.bg-lighten-5 {
  background-color: #E2F8F0 !important; }

.btn-success.btn-lighten-5 {
  border-color: #08B46B !important;
  background-color: #E2F8F0 !important; }
  .btn-success.btn-lighten-5:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-5:focus, .btn-success.btn-lighten-5:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-5 {
  border-color: #E2F8F0 !important;
  color: #E2F8F0 !important; }
  .btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #E2F8F0 !important; }

.progress-success.progress-lighten-5 {
  background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-ms-fill {
    background-color: #E2F8F0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-5 .progress-bar {
      background-color: #E2F8F0; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E2F8F0 !important; }

.border-success.border-lighten-5 {
  border: 1px solid #E2F8F0 !important; }

.border-top-success.border-top-lighten-5 {
  border-top: 1px solid #E2F8F0 !important; }

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom: 1px solid #E2F8F0 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-5 {
  border-left: 1px solid #E2F8F0 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-5 {
  border-right: 1px solid #E2F8F0 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-5 {
  border-right: 1px solid #E2F8F0 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-5 {
  border-left: 1px solid #E2F8F0 !important; }

.overlay-success.overlay-lighten-5 {
  background: #E2F8F0;
  /* The Fallback */
  background: rgba(226, 248, 240, 0.8); }

.success.lighten-4 {
  color: #B6EDD8 !important; }

.bg-success.bg-lighten-4 {
  background-color: #B6EDD8 !important; }

.btn-success.btn-lighten-4 {
  border-color: #08B46B !important;
  background-color: #B6EDD8 !important; }
  .btn-success.btn-lighten-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-4:focus, .btn-success.btn-lighten-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-4 {
  border-color: #B6EDD8 !important;
  color: #B6EDD8 !important; }
  .btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #B6EDD8 !important; }

.progress-success.progress-lighten-4 {
  background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-ms-fill {
    background-color: #B6EDD8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-4 .progress-bar {
      background-color: #B6EDD8; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B6EDD8 !important; }

.border-success.border-lighten-4 {
  border: 1px solid #B6EDD8 !important; }

.border-top-success.border-top-lighten-4 {
  border-top: 1px solid #B6EDD8 !important; }

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom: 1px solid #B6EDD8 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-4 {
  border-left: 1px solid #B6EDD8 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-4 {
  border-right: 1px solid #B6EDD8 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-4 {
  border-right: 1px solid #B6EDD8 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-4 {
  border-left: 1px solid #B6EDD8 !important; }

.overlay-success.overlay-lighten-4 {
  background: #B6EDD8;
  /* The Fallback */
  background: rgba(182, 237, 216, 0.8); }

.success.lighten-3 {
  color: #86E1BF !important; }

.bg-success.bg-lighten-3 {
  background-color: #86E1BF !important; }

.btn-success.btn-lighten-3 {
  border-color: #08B46B !important;
  background-color: #86E1BF !important; }
  .btn-success.btn-lighten-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-3:focus, .btn-success.btn-lighten-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-3 {
  border-color: #86E1BF !important;
  color: #86E1BF !important; }
  .btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #86E1BF !important; }

.progress-success.progress-lighten-3 {
  background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-ms-fill {
    background-color: #86E1BF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-3 .progress-bar {
      background-color: #86E1BF; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #86E1BF !important; }

.border-success.border-lighten-3 {
  border: 1px solid #86E1BF !important; }

.border-top-success.border-top-lighten-3 {
  border-top: 1px solid #86E1BF !important; }

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom: 1px solid #86E1BF !important; }

[dir="ltr"] .border-left-success.border-left-lighten-3 {
  border-left: 1px solid #86E1BF !important; }

[dir="ltr"] .border-right-success.border-right-lighten-3 {
  border-right: 1px solid #86E1BF !important; }

[dir="rtl"] .border-left-success.border-left-lighten-3 {
  border-right: 1px solid #86E1BF !important; }

[dir="rtl"] .border-right-success.border-right-lighten-3 {
  border-left: 1px solid #86E1BF !important; }

.overlay-success.overlay-lighten-3 {
  background: #86E1BF;
  /* The Fallback */
  background: rgba(134, 225, 191, 0.8); }

.success.lighten-2 {
  color: #55D4A5 !important; }

.bg-success.bg-lighten-2 {
  background-color: #55D4A5 !important; }

.btn-success.btn-lighten-2 {
  border-color: #08B46B !important;
  background-color: #55D4A5 !important; }
  .btn-success.btn-lighten-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-2:focus, .btn-success.btn-lighten-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-2 {
  border-color: #55D4A5 !important;
  color: #55D4A5 !important; }
  .btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #55D4A5 !important; }

.progress-success.progress-lighten-2 {
  background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-ms-fill {
    background-color: #55D4A5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-2 .progress-bar {
      background-color: #55D4A5; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #55D4A5 !important; }

.border-success.border-lighten-2 {
  border: 1px solid #55D4A5 !important; }

.border-top-success.border-top-lighten-2 {
  border-top: 1px solid #55D4A5 !important; }

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom: 1px solid #55D4A5 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-2 {
  border-left: 1px solid #55D4A5 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-2 {
  border-right: 1px solid #55D4A5 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-2 {
  border-right: 1px solid #55D4A5 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-2 {
  border-left: 1px solid #55D4A5 !important; }

.overlay-success.overlay-lighten-2 {
  background: #55D4A5;
  /* The Fallback */
  background: rgba(85, 212, 165, 0.8); }

.success.lighten-1 {
  color: #30CB91 !important; }

.bg-success.bg-lighten-1 {
  background-color: #30CB91 !important; }

.btn-success.btn-lighten-1 {
  border-color: #08B46B !important;
  background-color: #30CB91 !important; }
  .btn-success.btn-lighten-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-1:focus, .btn-success.btn-lighten-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-1 {
  border-color: #30CB91 !important;
  color: #30CB91 !important; }
  .btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #30CB91 !important; }

.progress-success.progress-lighten-1 {
  background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-ms-fill {
    background-color: #30CB91; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-1 .progress-bar {
      background-color: #30CB91; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #30CB91 !important; }

.border-success.border-lighten-1 {
  border: 1px solid #30CB91 !important; }

.border-top-success.border-top-lighten-1 {
  border-top: 1px solid #30CB91 !important; }

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom: 1px solid #30CB91 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-1 {
  border-left: 1px solid #30CB91 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-1 {
  border-right: 1px solid #30CB91 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-1 {
  border-right: 1px solid #30CB91 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-1 {
  border-left: 1px solid #30CB91 !important; }

.overlay-success.overlay-lighten-1 {
  background: #30CB91;
  /* The Fallback */
  background: rgba(48, 203, 145, 0.8); }

.success {
  color: #0CC27E !important; }

.bg-success {
  background-color: #0CC27E !important; }
  .bg-success .card-header, .bg-success .card-footer {
    background-color: transparent; }

.toast-success {
  background-color: #0CC27E; }

.alert-success {
  border-color: #0CC27E !important;
  background-color: #0ee494 !important;
  color: #021a11 !important; }
  .alert-success .alert-link {
    color: black !important; }

.border-success {
  border-color: #0CC27E; }

.border-top-success {
  border-top-color: #0CC27E; }

.border-bottom-success {
  border-bottom-color: #0CC27E; }

[dir="ltr"] .border-left-success {
  border-left-color: #0CC27E; }

[dir="ltr"] .border-right-success {
  border-right-color: #0CC27E; }

[dir="rtl"] .border-left-success {
  border-right-color: #0CC27E; }

[dir="rtl"] .border-right-success {
  border-left-color: #0CC27E; }

.badge-success {
  background-color: #0CC27E; }

.panel-success {
  border-color: #0CC27E; }
  .panel-success .panel-heading {
    color: #FFF;
    border-color: #0CC27E;
    background-color: #0dda8e; }

.bg-success.tag-glow, .border-success.tag-glow {
  box-shadow: 0px 0px 10px #0CC27E; }

.overlay-success {
  background: #0CC27E;
  /* The Fallback */
  background: rgba(12, 194, 126, 0.8); }

.card.card-outline-success {
  border-width: 1px;
  border-style: solid;
  border-color: #0CC27E;
  background-color: transparent; }
  .card.card-outline-success .card-header, .card.card-outline-success .card-footer {
    background-color: transparent; }

.btn-success.btn-flat {
  background-color: transparent !important;
  color: #0CC27E;
  border: none; }

.btn-success.btn-raised, .btn-success.btn-fab {
  background-color: #0CC27E !important;
  color: #fff !important;
  border-color: #0CC27E; }
  .btn-success.btn-raised.active, .btn-success.btn-fab.active {
    background-color: #0aa068 !important;
    border-color: #0aa068 !important; }

.btn-group-raised .btn-success {
  background-color: #0CC27E !important;
  color: #fff !important; }

.btn-outline-success {
  border: 1px solid;
  border-color: #0CC27E;
  background-color: transparent;
  color: #0CC27E;
  box-shadow: none !important; }
  .btn-outline-success:focus {
    background-color: transparent !important;
    color: #0CC27E !important;
    box-shadow: transparent !important; }
  .btn-outline-success.active {
    background-color: #0CC27E !important;
    color: #FFF !important; }
  .btn-outline-success:hover {
    background-color: #0bb475 !important;
    color: #FFF !important; }
    .btn-outline-success:hover svg {
      color: #FFF !important; }

.btn-outline-success.btn-raised, .btn-outline-success.btn-fab {
  border: 1px solid;
  border-color: #0CC27E;
  background-color: transparent;
  color: #0CC27E;
  box-shadow: none !important; }
  .btn-outline-success.btn-raised:focus, .btn-outline-success.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-success.btn-raised.active, .btn-outline-success.btn-fab.active {
    background-color: #0CC27E !important;
    color: #FFF !important; }
  .btn-outline-success.btn-raised:hover, .btn-outline-success.btn-fab:hover {
    background-color: #0bb475 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-success {
  background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-webkit-progress-value {
    background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-moz-progress-bar {
    background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-ms-fill {
    background-color: #0CC27E; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-success .progress-bar {
      background-color: #0CC27E; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0CC27E !important; }

.success.darken-1 {
  color: #0ABC76 !important; }

.bg-success.bg-darken-1 {
  background-color: #0ABC76 !important; }

.btn-success.btn-darken-1 {
  border-color: #08B46B !important;
  background-color: #0ABC76 !important; }
  .btn-success.btn-darken-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-1:focus, .btn-success.btn-darken-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-1 {
  border-color: #0ABC76 !important;
  color: #0ABC76 !important; }
  .btn-outline-success.btn-outline-darken-1:hover {
    background-color: #0ABC76 !important; }

.progress-success.progress-darken-1 {
  background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-webkit-progress-value {
    background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-moz-progress-bar {
    background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-ms-fill {
    background-color: #0ABC76; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-1 .progress-bar {
      background-color: #0ABC76; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0ABC76 !important; }

.border-success.border-darken-1 {
  border: 1px solid #0ABC76 !important; }

.border-top-success.border-top-darken-1 {
  border-top: 1px solid #0ABC76 !important; }

.border-bottom-success.border-bottom-darken-1 {
  border-bottom: 1px solid #0ABC76 !important; }

[dir="ltr"] .border-left-success.border-left-darken-1 {
  border-left: 1px solid #0ABC76 !important; }

[dir="ltr"] .border-right-success.border-right-darken-1 {
  border-right: 1px solid #0ABC76 !important; }

[dir="rtl"] .border-left-success.border-left-darken-1 {
  border-right: 1px solid #0ABC76 !important; }

[dir="rtl"] .border-right-success.border-right-darken-1 {
  border-left: 1px solid #0ABC76 !important; }

.overlay-success.overlay-darken-1 {
  background: #0ABC76;
  /* The Fallback */
  background: rgba(10, 188, 118, 0.8); }

.success.darken-2 {
  color: #08B46B !important; }

.bg-success.bg-darken-2 {
  background-color: #08B46B !important; }

.btn-success.btn-darken-2 {
  border-color: #08B46B !important;
  background-color: #08B46B !important; }
  .btn-success.btn-darken-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-2:focus, .btn-success.btn-darken-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-2 {
  border-color: #08B46B !important;
  color: #08B46B !important; }
  .btn-outline-success.btn-outline-darken-2:hover {
    background-color: #08B46B !important; }

.progress-success.progress-darken-2 {
  background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-webkit-progress-value {
    background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-moz-progress-bar {
    background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-ms-fill {
    background-color: #08B46B; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-2 .progress-bar {
      background-color: #08B46B; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #08B46B !important; }

.border-success.border-darken-2 {
  border: 1px solid #08B46B !important; }

.border-top-success.border-top-darken-2 {
  border-top: 1px solid #08B46B !important; }

.border-bottom-success.border-bottom-darken-2 {
  border-bottom: 1px solid #08B46B !important; }

[dir="ltr"] .border-left-success.border-left-darken-2 {
  border-left: 1px solid #08B46B !important; }

[dir="ltr"] .border-right-success.border-right-darken-2 {
  border-right: 1px solid #08B46B !important; }

[dir="rtl"] .border-left-success.border-left-darken-2 {
  border-right: 1px solid #08B46B !important; }

[dir="rtl"] .border-right-success.border-right-darken-2 {
  border-left: 1px solid #08B46B !important; }

.overlay-success.overlay-darken-2 {
  background: #08B46B;
  /* The Fallback */
  background: rgba(8, 180, 107, 0.8); }

.success.darken-3 {
  color: #06AC61 !important; }

.bg-success.bg-darken-3 {
  background-color: #06AC61 !important; }

.btn-success.btn-darken-3 {
  border-color: #08B46B !important;
  background-color: #06AC61 !important; }
  .btn-success.btn-darken-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-3:focus, .btn-success.btn-darken-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-3 {
  border-color: #06AC61 !important;
  color: #06AC61 !important; }
  .btn-outline-success.btn-outline-darken-3:hover {
    background-color: #06AC61 !important; }

.progress-success.progress-darken-3 {
  background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-webkit-progress-value {
    background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-moz-progress-bar {
    background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-ms-fill {
    background-color: #06AC61; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-3 .progress-bar {
      background-color: #06AC61; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #06AC61 !important; }

.border-success.border-darken-3 {
  border: 1px solid #06AC61 !important; }

.border-top-success.border-top-darken-3 {
  border-top: 1px solid #06AC61 !important; }

.border-bottom-success.border-bottom-darken-3 {
  border-bottom: 1px solid #06AC61 !important; }

[dir="ltr"] .border-left-success.border-left-darken-3 {
  border-left: 1px solid #06AC61 !important; }

[dir="ltr"] .border-right-success.border-right-darken-3 {
  border-right: 1px solid #06AC61 !important; }

[dir="rtl"] .border-left-success.border-left-darken-3 {
  border-right: 1px solid #06AC61 !important; }

[dir="rtl"] .border-right-success.border-right-darken-3 {
  border-left: 1px solid #06AC61 !important; }

.overlay-success.overlay-darken-3 {
  background: #06AC61;
  /* The Fallback */
  background: rgba(6, 172, 97, 0.8); }

.success.darken-4 {
  color: #039F4E !important; }

.bg-success.bg-darken-4 {
  background-color: #039F4E !important; }

.btn-success.btn-darken-4 {
  border-color: #08B46B !important;
  background-color: #039F4E !important; }
  .btn-success.btn-darken-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-4:focus, .btn-success.btn-darken-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-4 {
  border-color: #039F4E !important;
  color: #039F4E !important; }
  .btn-outline-success.btn-outline-darken-4:hover {
    background-color: #039F4E !important; }

.progress-success.progress-darken-4 {
  background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-webkit-progress-value {
    background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-moz-progress-bar {
    background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-ms-fill {
    background-color: #039F4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-4 .progress-bar {
      background-color: #039F4E; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039F4E !important; }

.border-success.border-darken-4 {
  border: 1px solid #039F4E !important; }

.border-top-success.border-top-darken-4 {
  border-top: 1px solid #039F4E !important; }

.border-bottom-success.border-bottom-darken-4 {
  border-bottom: 1px solid #039F4E !important; }

[dir="ltr"] .border-left-success.border-left-darken-4 {
  border-left: 1px solid #039F4E !important; }

[dir="ltr"] .border-right-success.border-right-darken-4 {
  border-right: 1px solid #039F4E !important; }

[dir="rtl"] .border-left-success.border-left-darken-4 {
  border-right: 1px solid #039F4E !important; }

[dir="rtl"] .border-right-success.border-right-darken-4 {
  border-left: 1px solid #039F4E !important; }

.overlay-success.overlay-darken-4 {
  background: #039F4E;
  /* The Fallback */
  background: rgba(3, 159, 78, 0.8); }

.success.accent-1 {
  color: #C9FFE0 !important; }

.bg-success.bg-accent-1 {
  background-color: #C9FFE0 !important; }

.btn-success.btn-accent-1 {
  border-color: #08B46B !important;
  background-color: #C9FFE0 !important; }
  .btn-success.btn-accent-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-1:focus, .btn-success.btn-accent-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-1 {
  border-color: #C9FFE0 !important;
  color: #C9FFE0 !important; }
  .btn-outline-success.btn-outline-accent-1:hover {
    background-color: #C9FFE0 !important; }

.progress-success.progress-accent-1 {
  background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-webkit-progress-value {
    background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-moz-progress-bar {
    background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-ms-fill {
    background-color: #C9FFE0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-1 .progress-bar {
      background-color: #C9FFE0; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C9FFE0 !important; }

.border-success.border-accent-1 {
  border: 1px solid #C9FFE0 !important; }

.border-top-success.border-top-accent-1 {
  border-top: 1px solid #C9FFE0 !important; }

.border-bottom-success.border-bottom-accent-1 {
  border-bottom: 1px solid #C9FFE0 !important; }

[dir="ltr"] .border-left-success.border-left-accent-1 {
  border-left: 1px solid #C9FFE0 !important; }

[dir="ltr"] .border-right-success.border-right-accent-1 {
  border-right: 1px solid #C9FFE0 !important; }

[dir="rtl"] .border-left-success.border-left-accent-1 {
  border-right: 1px solid #C9FFE0 !important; }

[dir="rtl"] .border-right-success.border-right-accent-1 {
  border-left: 1px solid #C9FFE0 !important; }

.overlay-success.overlay-accent-1 {
  background: #C9FFE0;
  /* The Fallback */
  background: rgba(201, 255, 224, 0.8); }

.success.accent-2 {
  color: #96FFC3 !important; }

.bg-success.bg-accent-2 {
  background-color: #96FFC3 !important; }

.btn-success.btn-accent-2 {
  border-color: #08B46B !important;
  background-color: #96FFC3 !important; }
  .btn-success.btn-accent-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-2:focus, .btn-success.btn-accent-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-2 {
  border-color: #96FFC3 !important;
  color: #96FFC3 !important; }
  .btn-outline-success.btn-outline-accent-2:hover {
    background-color: #96FFC3 !important; }

.progress-success.progress-accent-2 {
  background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-webkit-progress-value {
    background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-moz-progress-bar {
    background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-ms-fill {
    background-color: #96FFC3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-2 .progress-bar {
      background-color: #96FFC3; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #96FFC3 !important; }

.border-success.border-accent-2 {
  border: 1px solid #96FFC3 !important; }

.border-top-success.border-top-accent-2 {
  border-top: 1px solid #96FFC3 !important; }

.border-bottom-success.border-bottom-accent-2 {
  border-bottom: 1px solid #96FFC3 !important; }

[dir="ltr"] .border-left-success.border-left-accent-2 {
  border-left: 1px solid #96FFC3 !important; }

[dir="ltr"] .border-right-success.border-right-accent-2 {
  border-right: 1px solid #96FFC3 !important; }

[dir="rtl"] .border-left-success.border-left-accent-2 {
  border-right: 1px solid #96FFC3 !important; }

[dir="rtl"] .border-right-success.border-right-accent-2 {
  border-left: 1px solid #96FFC3 !important; }

.overlay-success.overlay-accent-2 {
  background: #96FFC3;
  /* The Fallback */
  background: rgba(150, 255, 195, 0.8); }

.success.accent-3 {
  color: #63FFA5 !important; }

.bg-success.bg-accent-3 {
  background-color: #63FFA5 !important; }

.btn-success.btn-accent-3 {
  border-color: #08B46B !important;
  background-color: #63FFA5 !important; }
  .btn-success.btn-accent-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-3:focus, .btn-success.btn-accent-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-3 {
  border-color: #63FFA5 !important;
  color: #63FFA5 !important; }
  .btn-outline-success.btn-outline-accent-3:hover {
    background-color: #63FFA5 !important; }

.progress-success.progress-accent-3 {
  background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-webkit-progress-value {
    background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-moz-progress-bar {
    background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-ms-fill {
    background-color: #63FFA5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-3 .progress-bar {
      background-color: #63FFA5; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #63FFA5 !important; }

.border-success.border-accent-3 {
  border: 1px solid #63FFA5 !important; }

.border-top-success.border-top-accent-3 {
  border-top: 1px solid #63FFA5 !important; }

.border-bottom-success.border-bottom-accent-3 {
  border-bottom: 1px solid #63FFA5 !important; }

[dir="ltr"] .border-left-success.border-left-accent-3 {
  border-left: 1px solid #63FFA5 !important; }

[dir="ltr"] .border-right-success.border-right-accent-3 {
  border-right: 1px solid #63FFA5 !important; }

[dir="rtl"] .border-left-success.border-left-accent-3 {
  border-right: 1px solid #63FFA5 !important; }

[dir="rtl"] .border-right-success.border-right-accent-3 {
  border-left: 1px solid #63FFA5 !important; }

.overlay-success.overlay-accent-3 {
  background: #63FFA5;
  /* The Fallback */
  background: rgba(99, 255, 165, 0.8); }

.success.accent-4 {
  color: #4AFF96 !important; }

.bg-success.bg-accent-4 {
  background-color: #4AFF96 !important; }

.btn-success.btn-accent-4 {
  border-color: #08B46B !important;
  background-color: #4AFF96 !important; }
  .btn-success.btn-accent-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-4:focus, .btn-success.btn-accent-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-4 {
  border-color: #4AFF96 !important;
  color: #4AFF96 !important; }
  .btn-outline-success.btn-outline-accent-4:hover {
    background-color: #4AFF96 !important; }

.progress-success.progress-accent-4 {
  background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-webkit-progress-value {
    background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-moz-progress-bar {
    background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-ms-fill {
    background-color: #4AFF96; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-4 .progress-bar {
      background-color: #4AFF96; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4AFF96 !important; }

.border-success.border-accent-4 {
  border: 1px solid #4AFF96 !important; }

.border-top-success.border-top-accent-4 {
  border-top: 1px solid #4AFF96 !important; }

.border-bottom-success.border-bottom-accent-4 {
  border-bottom: 1px solid #4AFF96 !important; }

[dir="ltr"] .border-left-success.border-left-accent-4 {
  border-left: 1px solid #4AFF96 !important; }

[dir="ltr"] .border-right-success.border-right-accent-4 {
  border-right: 1px solid #4AFF96 !important; }

[dir="rtl"] .border-left-success.border-left-accent-4 {
  border-right: 1px solid #4AFF96 !important; }

[dir="rtl"] .border-right-success.border-right-accent-4 {
  border-left: 1px solid #4AFF96 !important; }

.overlay-success.overlay-accent-4 {
  background: #4AFF96;
  /* The Fallback */
  background: rgba(74, 255, 150, 0.8); }

.info.lighten-5 {
  color: #E4F7FA !important; }

.bg-info.bg-lighten-5 {
  background-color: #E4F7FA !important; }

.btn-info.btn-lighten-5 {
  border-color: #14ADCE !important;
  background-color: #E4F7FA !important; }
  .btn-info.btn-lighten-5:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-5:focus, .btn-info.btn-lighten-5:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-5 {
  border-color: #E4F7FA !important;
  color: #E4F7FA !important; }
  .btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #E4F7FA !important; }

.progress-info.progress-lighten-5 {
  background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-ms-fill {
    background-color: #E4F7FA; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-5 .progress-bar {
      background-color: #E4F7FA; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E4F7FA !important; }

.border-info.border-lighten-5 {
  border: 1px solid #E4F7FA !important; }

.border-top-info.border-top-lighten-5 {
  border-top: 1px solid #E4F7FA !important; }

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom: 1px solid #E4F7FA !important; }

[dir="ltr"] .border-left-info.border-left-lighten-5 {
  border-left: 1px solid #E4F7FA !important; }

[dir="ltr"] .border-right-info.border-right-lighten-5 {
  border-right: 1px solid #E4F7FA !important; }

[dir="rtl"] .border-left-info.border-left-lighten-5 {
  border-right: 1px solid #E4F7FA !important; }

[dir="rtl"] .border-right-info.border-right-lighten-5 {
  border-left: 1px solid #E4F7FA !important; }

.overlay-info.overlay-lighten-5 {
  background: #E4F7FA;
  /* The Fallback */
  background: rgba(228, 247, 250, 0.8); }

.info.lighten-4 {
  color: #BBEBF3 !important; }

.bg-info.bg-lighten-4 {
  background-color: #BBEBF3 !important; }

.btn-info.btn-lighten-4 {
  border-color: #14ADCE !important;
  background-color: #BBEBF3 !important; }
  .btn-info.btn-lighten-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-4:focus, .btn-info.btn-lighten-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-4 {
  border-color: #BBEBF3 !important;
  color: #BBEBF3 !important; }
  .btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #BBEBF3 !important; }

.progress-info.progress-lighten-4 {
  background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-ms-fill {
    background-color: #BBEBF3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-4 .progress-bar {
      background-color: #BBEBF3; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BBEBF3 !important; }

.border-info.border-lighten-4 {
  border: 1px solid #BBEBF3 !important; }

.border-top-info.border-top-lighten-4 {
  border-top: 1px solid #BBEBF3 !important; }

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom: 1px solid #BBEBF3 !important; }

[dir="ltr"] .border-left-info.border-left-lighten-4 {
  border-left: 1px solid #BBEBF3 !important; }

[dir="ltr"] .border-right-info.border-right-lighten-4 {
  border-right: 1px solid #BBEBF3 !important; }

[dir="rtl"] .border-left-info.border-left-lighten-4 {
  border-right: 1px solid #BBEBF3 !important; }

[dir="rtl"] .border-right-info.border-right-lighten-4 {
  border-left: 1px solid #BBEBF3 !important; }

.overlay-info.overlay-lighten-4 {
  background: #BBEBF3;
  /* The Fallback */
  background: rgba(187, 235, 243, 0.8); }

.info.lighten-3 {
  color: #8EDEEC !important; }

.bg-info.bg-lighten-3 {
  background-color: #8EDEEC !important; }

.btn-info.btn-lighten-3 {
  border-color: #14ADCE !important;
  background-color: #8EDEEC !important; }
  .btn-info.btn-lighten-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-3:focus, .btn-info.btn-lighten-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-3 {
  border-color: #8EDEEC !important;
  color: #8EDEEC !important; }
  .btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #8EDEEC !important; }

.progress-info.progress-lighten-3 {
  background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-ms-fill {
    background-color: #8EDEEC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-3 .progress-bar {
      background-color: #8EDEEC; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8EDEEC !important; }

.border-info.border-lighten-3 {
  border: 1px solid #8EDEEC !important; }

.border-top-info.border-top-lighten-3 {
  border-top: 1px solid #8EDEEC !important; }

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom: 1px solid #8EDEEC !important; }

[dir="ltr"] .border-left-info.border-left-lighten-3 {
  border-left: 1px solid #8EDEEC !important; }

[dir="ltr"] .border-right-info.border-right-lighten-3 {
  border-right: 1px solid #8EDEEC !important; }

[dir="rtl"] .border-left-info.border-left-lighten-3 {
  border-right: 1px solid #8EDEEC !important; }

[dir="rtl"] .border-right-info.border-right-lighten-3 {
  border-left: 1px solid #8EDEEC !important; }

.overlay-info.overlay-lighten-3 {
  background: #8EDEEC;
  /* The Fallback */
  background: rgba(142, 222, 236, 0.8); }

.info.lighten-2 {
  color: #60D0E4 !important; }

.bg-info.bg-lighten-2 {
  background-color: #60D0E4 !important; }

.btn-info.btn-lighten-2 {
  border-color: #14ADCE !important;
  background-color: #60D0E4 !important; }
  .btn-info.btn-lighten-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-2:focus, .btn-info.btn-lighten-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-2 {
  border-color: #60D0E4 !important;
  color: #60D0E4 !important; }
  .btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #60D0E4 !important; }

.progress-info.progress-lighten-2 {
  background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-ms-fill {
    background-color: #60D0E4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-2 .progress-bar {
      background-color: #60D0E4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #60D0E4 !important; }

.border-info.border-lighten-2 {
  border: 1px solid #60D0E4 !important; }

.border-top-info.border-top-lighten-2 {
  border-top: 1px solid #60D0E4 !important; }

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom: 1px solid #60D0E4 !important; }

[dir="ltr"] .border-left-info.border-left-lighten-2 {
  border-left: 1px solid #60D0E4 !important; }

[dir="ltr"] .border-right-info.border-right-lighten-2 {
  border-right: 1px solid #60D0E4 !important; }

[dir="rtl"] .border-left-info.border-left-lighten-2 {
  border-right: 1px solid #60D0E4 !important; }

[dir="rtl"] .border-right-info.border-right-lighten-2 {
  border-left: 1px solid #60D0E4 !important; }

.overlay-info.overlay-lighten-2 {
  background: #60D0E4;
  /* The Fallback */
  background: rgba(96, 208, 228, 0.8); }

.info.lighten-1 {
  color: #3EC6DE !important; }

.bg-info.bg-lighten-1 {
  background-color: #3EC6DE !important; }

.btn-info.btn-lighten-1 {
  border-color: #14ADCE !important;
  background-color: #3EC6DE !important; }
  .btn-info.btn-lighten-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-1:focus, .btn-info.btn-lighten-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-1 {
  border-color: #3EC6DE !important;
  color: #3EC6DE !important; }
  .btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #3EC6DE !important; }

.progress-info.progress-lighten-1 {
  background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-ms-fill {
    background-color: #3EC6DE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-1 .progress-bar {
      background-color: #3EC6DE; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3EC6DE !important; }

.border-info.border-lighten-1 {
  border: 1px solid #3EC6DE !important; }

.border-top-info.border-top-lighten-1 {
  border-top: 1px solid #3EC6DE !important; }

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom: 1px solid #3EC6DE !important; }

[dir="ltr"] .border-left-info.border-left-lighten-1 {
  border-left: 1px solid #3EC6DE !important; }

[dir="ltr"] .border-right-info.border-right-lighten-1 {
  border-right: 1px solid #3EC6DE !important; }

[dir="rtl"] .border-left-info.border-left-lighten-1 {
  border-right: 1px solid #3EC6DE !important; }

[dir="rtl"] .border-right-info.border-right-lighten-1 {
  border-left: 1px solid #3EC6DE !important; }

.overlay-info.overlay-lighten-1 {
  background: #3EC6DE;
  /* The Fallback */
  background: rgba(62, 198, 222, 0.8); }

.info {
  color: #1CBCD8 !important; }

.bg-info {
  background-color: #1CBCD8 !important; }
  .bg-info .card-header, .bg-info .card-footer {
    background-color: transparent; }

.toast-info {
  background-color: #1CBCD8; }

.alert-info {
  border-color: #1CBCD8 !important;
  background-color: #33cae5 !important;
  color: #08323a !important; }
  .alert-info .alert-link {
    color: #031316 !important; }

.border-info {
  border-color: #1CBCD8; }

.border-top-info {
  border-top-color: #1CBCD8; }

.border-bottom-info {
  border-bottom-color: #1CBCD8; }

[dir="ltr"] .border-left-info {
  border-left-color: #1CBCD8; }

[dir="ltr"] .border-right-info {
  border-right-color: #1CBCD8; }

[dir="rtl"] .border-left-info {
  border-right-color: #1CBCD8; }

[dir="rtl"] .border-right-info {
  border-left-color: #1CBCD8; }

.badge-info {
  background-color: #1CBCD8; }

.panel-info {
  border-color: #1CBCD8; }
  .panel-info .panel-heading {
    color: #FFF;
    border-color: #1CBCD8;
    background-color: #2ac8e3; }

.bg-info.tag-glow, .border-info.tag-glow {
  box-shadow: 0px 0px 10px #1CBCD8; }

.overlay-info {
  background: #1CBCD8;
  /* The Fallback */
  background: rgba(28, 188, 216, 0.8); }

.card.card-outline-info {
  border-width: 1px;
  border-style: solid;
  border-color: #1CBCD8;
  background-color: transparent; }
  .card.card-outline-info .card-header, .card.card-outline-info .card-footer {
    background-color: transparent; }

.btn-info.btn-flat {
  background-color: transparent !important;
  color: #1CBCD8;
  border: none; }

.btn-info.btn-raised, .btn-info.btn-fab {
  background-color: #1CBCD8 !important;
  color: #fff !important;
  border-color: #1CBCD8; }
  .btn-info.btn-raised.active, .btn-info.btn-fab.active {
    background-color: #18a0b8 !important;
    border-color: #18a0b8 !important; }

.btn-group-raised .btn-info {
  background-color: #1CBCD8 !important;
  color: #fff !important; }

.btn-outline-info {
  border: 1px solid;
  border-color: #1CBCD8;
  background-color: transparent;
  color: #1CBCD8;
  box-shadow: none !important; }
  .btn-outline-info:focus {
    background-color: transparent !important;
    color: #1CBCD8 !important;
    box-shadow: transparent !important; }
  .btn-outline-info.active {
    background-color: #1CBCD8 !important;
    color: #FFF !important; }
  .btn-outline-info:hover {
    background-color: #1ab0ca !important;
    color: #FFF !important; }
    .btn-outline-info:hover svg {
      color: #FFF !important; }

.btn-outline-info.btn-raised, .btn-outline-info.btn-fab {
  border: 1px solid;
  border-color: #1CBCD8;
  background-color: transparent;
  color: #1CBCD8;
  box-shadow: none !important; }
  .btn-outline-info.btn-raised:focus, .btn-outline-info.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-info.btn-raised.active, .btn-outline-info.btn-fab.active {
    background-color: #1CBCD8 !important;
    color: #FFF !important; }
  .btn-outline-info.btn-raised:hover, .btn-outline-info.btn-fab:hover {
    background-color: #1ab0ca !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-info {
  background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-webkit-progress-value {
    background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-moz-progress-bar {
    background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-ms-fill {
    background-color: #1CBCD8; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-info .progress-bar {
      background-color: #1CBCD8; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1CBCD8 !important; }

.info.darken-1 {
  color: #19B6D4 !important; }

.bg-info.bg-darken-1 {
  background-color: #19B6D4 !important; }

.btn-info.btn-darken-1 {
  border-color: #14ADCE !important;
  background-color: #19B6D4 !important; }
  .btn-info.btn-darken-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-1:focus, .btn-info.btn-darken-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-1 {
  border-color: #19B6D4 !important;
  color: #19B6D4 !important; }
  .btn-outline-info.btn-outline-darken-1:hover {
    background-color: #19B6D4 !important; }

.progress-info.progress-darken-1 {
  background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-webkit-progress-value {
    background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-moz-progress-bar {
    background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-ms-fill {
    background-color: #19B6D4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-1 .progress-bar {
      background-color: #19B6D4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #19B6D4 !important; }

.border-info.border-darken-1 {
  border: 1px solid #19B6D4 !important; }

.border-top-info.border-top-darken-1 {
  border-top: 1px solid #19B6D4 !important; }

.border-bottom-info.border-bottom-darken-1 {
  border-bottom: 1px solid #19B6D4 !important; }

[dir="ltr"] .border-left-info.border-left-darken-1 {
  border-left: 1px solid #19B6D4 !important; }

[dir="ltr"] .border-right-info.border-right-darken-1 {
  border-right: 1px solid #19B6D4 !important; }

[dir="rtl"] .border-left-info.border-left-darken-1 {
  border-right: 1px solid #19B6D4 !important; }

[dir="rtl"] .border-right-info.border-right-darken-1 {
  border-left: 1px solid #19B6D4 !important; }

.overlay-info.overlay-darken-1 {
  background: #19B6D4;
  /* The Fallback */
  background: rgba(25, 182, 212, 0.8); }

.info.darken-2 {
  color: #14ADCE !important; }

.bg-info.bg-darken-2 {
  background-color: #14ADCE !important; }

.btn-info.btn-darken-2 {
  border-color: #14ADCE !important;
  background-color: #14ADCE !important; }
  .btn-info.btn-darken-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-2:focus, .btn-info.btn-darken-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-2 {
  border-color: #14ADCE !important;
  color: #14ADCE !important; }
  .btn-outline-info.btn-outline-darken-2:hover {
    background-color: #14ADCE !important; }

.progress-info.progress-darken-2 {
  background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-webkit-progress-value {
    background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-moz-progress-bar {
    background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-ms-fill {
    background-color: #14ADCE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-2 .progress-bar {
      background-color: #14ADCE; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #14ADCE !important; }

.border-info.border-darken-2 {
  border: 1px solid #14ADCE !important; }

.border-top-info.border-top-darken-2 {
  border-top: 1px solid #14ADCE !important; }

.border-bottom-info.border-bottom-darken-2 {
  border-bottom: 1px solid #14ADCE !important; }

[dir="ltr"] .border-left-info.border-left-darken-2 {
  border-left: 1px solid #14ADCE !important; }

[dir="ltr"] .border-right-info.border-right-darken-2 {
  border-right: 1px solid #14ADCE !important; }

[dir="rtl"] .border-left-info.border-left-darken-2 {
  border-right: 1px solid #14ADCE !important; }

[dir="rtl"] .border-right-info.border-right-darken-2 {
  border-left: 1px solid #14ADCE !important; }

.overlay-info.overlay-darken-2 {
  background: #14ADCE;
  /* The Fallback */
  background: rgba(20, 173, 206, 0.8); }

.info.darken-3 {
  color: #11A5C8 !important; }

.bg-info.bg-darken-3 {
  background-color: #11A5C8 !important; }

.btn-info.btn-darken-3 {
  border-color: #14ADCE !important;
  background-color: #11A5C8 !important; }
  .btn-info.btn-darken-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-3:focus, .btn-info.btn-darken-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-3 {
  border-color: #11A5C8 !important;
  color: #11A5C8 !important; }
  .btn-outline-info.btn-outline-darken-3:hover {
    background-color: #11A5C8 !important; }

.progress-info.progress-darken-3 {
  background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-webkit-progress-value {
    background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-moz-progress-bar {
    background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-ms-fill {
    background-color: #11A5C8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-3 .progress-bar {
      background-color: #11A5C8; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11A5C8 !important; }

.border-info.border-darken-3 {
  border: 1px solid #11A5C8 !important; }

.border-top-info.border-top-darken-3 {
  border-top: 1px solid #11A5C8 !important; }

.border-bottom-info.border-bottom-darken-3 {
  border-bottom: 1px solid #11A5C8 !important; }

[dir="ltr"] .border-left-info.border-left-darken-3 {
  border-left: 1px solid #11A5C8 !important; }

[dir="ltr"] .border-right-info.border-right-darken-3 {
  border-right: 1px solid #11A5C8 !important; }

[dir="rtl"] .border-left-info.border-left-darken-3 {
  border-right: 1px solid #11A5C8 !important; }

[dir="rtl"] .border-right-info.border-right-darken-3 {
  border-left: 1px solid #11A5C8 !important; }

.overlay-info.overlay-darken-3 {
  background: #11A5C8;
  /* The Fallback */
  background: rgba(17, 165, 200, 0.8); }

.info.darken-4 {
  color: #0997BF !important; }

.bg-info.bg-darken-4 {
  background-color: #0997BF !important; }

.btn-info.btn-darken-4 {
  border-color: #14ADCE !important;
  background-color: #0997BF !important; }
  .btn-info.btn-darken-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-4:focus, .btn-info.btn-darken-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-4 {
  border-color: #0997BF !important;
  color: #0997BF !important; }
  .btn-outline-info.btn-outline-darken-4:hover {
    background-color: #0997BF !important; }

.progress-info.progress-darken-4 {
  background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-ms-fill {
    background-color: #0997BF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-4 .progress-bar {
      background-color: #0997BF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0997BF !important; }

.border-info.border-darken-4 {
  border: 1px solid #0997BF !important; }

.border-top-info.border-top-darken-4 {
  border-top: 1px solid #0997BF !important; }

.border-bottom-info.border-bottom-darken-4 {
  border-bottom: 1px solid #0997BF !important; }

[dir="ltr"] .border-left-info.border-left-darken-4 {
  border-left: 1px solid #0997BF !important; }

[dir="ltr"] .border-right-info.border-right-darken-4 {
  border-right: 1px solid #0997BF !important; }

[dir="rtl"] .border-left-info.border-left-darken-4 {
  border-right: 1px solid #0997BF !important; }

[dir="rtl"] .border-right-info.border-right-darken-4 {
  border-left: 1px solid #0997BF !important; }

.overlay-info.overlay-darken-4 {
  background: #0997BF;
  /* The Fallback */
  background: rgba(9, 151, 191, 0.8); }

.info.accent-1 {
  color: #EAFAFF !important; }

.bg-info.bg-accent-1 {
  background-color: #EAFAFF !important; }

.btn-info.btn-accent-1 {
  border-color: #14ADCE !important;
  background-color: #EAFAFF !important; }
  .btn-info.btn-accent-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-1:focus, .btn-info.btn-accent-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-1 {
  border-color: #EAFAFF !important;
  color: #EAFAFF !important; }
  .btn-outline-info.btn-outline-accent-1:hover {
    background-color: #EAFAFF !important; }

.progress-info.progress-accent-1 {
  background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-webkit-progress-value {
    background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-moz-progress-bar {
    background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-ms-fill {
    background-color: #EAFAFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-1 .progress-bar {
      background-color: #EAFAFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EAFAFF !important; }

.border-info.border-accent-1 {
  border: 1px solid #EAFAFF !important; }

.border-top-info.border-top-accent-1 {
  border-top: 1px solid #EAFAFF !important; }

.border-bottom-info.border-bottom-accent-1 {
  border-bottom: 1px solid #EAFAFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-1 {
  border-left: 1px solid #EAFAFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-1 {
  border-right: 1px solid #EAFAFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-1 {
  border-right: 1px solid #EAFAFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-1 {
  border-left: 1px solid #EAFAFF !important; }

.overlay-info.overlay-accent-1 {
  background: #EAFAFF;
  /* The Fallback */
  background: rgba(234, 250, 255, 0.8); }

.info.accent-2 {
  color: #B7EDFF !important; }

.bg-info.bg-accent-2 {
  background-color: #B7EDFF !important; }

.btn-info.btn-accent-2 {
  border-color: #14ADCE !important;
  background-color: #B7EDFF !important; }
  .btn-info.btn-accent-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-2:focus, .btn-info.btn-accent-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-2 {
  border-color: #B7EDFF !important;
  color: #B7EDFF !important; }
  .btn-outline-info.btn-outline-accent-2:hover {
    background-color: #B7EDFF !important; }

.progress-info.progress-accent-2 {
  background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-webkit-progress-value {
    background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-moz-progress-bar {
    background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-ms-fill {
    background-color: #B7EDFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-2 .progress-bar {
      background-color: #B7EDFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B7EDFF !important; }

.border-info.border-accent-2 {
  border: 1px solid #B7EDFF !important; }

.border-top-info.border-top-accent-2 {
  border-top: 1px solid #B7EDFF !important; }

.border-bottom-info.border-bottom-accent-2 {
  border-bottom: 1px solid #B7EDFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-2 {
  border-left: 1px solid #B7EDFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-2 {
  border-right: 1px solid #B7EDFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-2 {
  border-right: 1px solid #B7EDFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-2 {
  border-left: 1px solid #B7EDFF !important; }

.overlay-info.overlay-accent-2 {
  background: #B7EDFF;
  /* The Fallback */
  background: rgba(183, 237, 255, 0.8); }

.info.accent-3 {
  color: #84E1FF !important; }

.bg-info.bg-accent-3 {
  background-color: #84E1FF !important; }

.btn-info.btn-accent-3 {
  border-color: #14ADCE !important;
  background-color: #84E1FF !important; }
  .btn-info.btn-accent-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-3:focus, .btn-info.btn-accent-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-3 {
  border-color: #84E1FF !important;
  color: #84E1FF !important; }
  .btn-outline-info.btn-outline-accent-3:hover {
    background-color: #84E1FF !important; }

.progress-info.progress-accent-3 {
  background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-webkit-progress-value {
    background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-moz-progress-bar {
    background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-ms-fill {
    background-color: #84E1FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-3 .progress-bar {
      background-color: #84E1FF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84E1FF !important; }

.border-info.border-accent-3 {
  border: 1px solid #84E1FF !important; }

.border-top-info.border-top-accent-3 {
  border-top: 1px solid #84E1FF !important; }

.border-bottom-info.border-bottom-accent-3 {
  border-bottom: 1px solid #84E1FF !important; }

[dir="ltr"] .border-left-info.border-left-accent-3 {
  border-left: 1px solid #84E1FF !important; }

[dir="ltr"] .border-right-info.border-right-accent-3 {
  border-right: 1px solid #84E1FF !important; }

[dir="rtl"] .border-left-info.border-left-accent-3 {
  border-right: 1px solid #84E1FF !important; }

[dir="rtl"] .border-right-info.border-right-accent-3 {
  border-left: 1px solid #84E1FF !important; }

.overlay-info.overlay-accent-3 {
  background: #84E1FF;
  /* The Fallback */
  background: rgba(132, 225, 255, 0.8); }

.info.accent-4 {
  color: #6ADAFF !important; }

.bg-info.bg-accent-4 {
  background-color: #6ADAFF !important; }

.btn-info.btn-accent-4 {
  border-color: #14ADCE !important;
  background-color: #6ADAFF !important; }
  .btn-info.btn-accent-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-4:focus, .btn-info.btn-accent-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-4 {
  border-color: #6ADAFF !important;
  color: #6ADAFF !important; }
  .btn-outline-info.btn-outline-accent-4:hover {
    background-color: #6ADAFF !important; }

.progress-info.progress-accent-4 {
  background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-ms-fill {
    background-color: #6ADAFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-4 .progress-bar {
      background-color: #6ADAFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6ADAFF !important; }

.border-info.border-accent-4 {
  border: 1px solid #6ADAFF !important; }

.border-top-info.border-top-accent-4 {
  border-top: 1px solid #6ADAFF !important; }

.border-bottom-info.border-bottom-accent-4 {
  border-bottom: 1px solid #6ADAFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-4 {
  border-left: 1px solid #6ADAFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-4 {
  border-right: 1px solid #6ADAFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-4 {
  border-right: 1px solid #6ADAFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-4 {
  border-left: 1px solid #6ADAFF !important; }

.overlay-info.overlay-accent-4 {
  background: #6ADAFF;
  /* The Fallback */
  background: rgba(106, 218, 255, 0.8); }

.warning.lighten-5 {
  color: #FFF1EC !important; }

.bg-warning.bg-lighten-5 {
  background-color: #FFF1EC !important; }

.btn-warning.btn-lighten-5 {
  border-color: #FF7A4E !important;
  background-color: #FFF1EC !important; }
  .btn-warning.btn-lighten-5:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-5:focus, .btn-warning.btn-lighten-5:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-5 {
  border-color: #FFF1EC !important;
  color: #FFF1EC !important; }
  .btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #FFF1EC !important; }

.progress-warning.progress-lighten-5 {
  background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-ms-fill {
    background-color: #FFF1EC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-5 .progress-bar {
      background-color: #FFF1EC; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF1EC !important; }

.border-warning.border-lighten-5 {
  border: 1px solid #FFF1EC !important; }

.border-top-warning.border-top-lighten-5 {
  border-top: 1px solid #FFF1EC !important; }

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF1EC !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-5 {
  border-left: 1px solid #FFF1EC !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-5 {
  border-right: 1px solid #FFF1EC !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-5 {
  border-right: 1px solid #FFF1EC !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-5 {
  border-left: 1px solid #FFF1EC !important; }

.overlay-warning.overlay-lighten-5 {
  background: #FFF1EC;
  /* The Fallback */
  background: rgba(255, 241, 236, 0.8); }

.warning.lighten-4 {
  color: #FFDDCF !important; }

.bg-warning.bg-lighten-4 {
  background-color: #FFDDCF !important; }

.btn-warning.btn-lighten-4 {
  border-color: #FF7A4E !important;
  background-color: #FFDDCF !important; }
  .btn-warning.btn-lighten-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-4:focus, .btn-warning.btn-lighten-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-4 {
  border-color: #FFDDCF !important;
  color: #FFDDCF !important; }
  .btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #FFDDCF !important; }

.progress-warning.progress-lighten-4 {
  background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-ms-fill {
    background-color: #FFDDCF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-4 .progress-bar {
      background-color: #FFDDCF; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFDDCF !important; }

.border-warning.border-lighten-4 {
  border: 1px solid #FFDDCF !important; }

.border-top-warning.border-top-lighten-4 {
  border-top: 1px solid #FFDDCF !important; }

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFDDCF !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-4 {
  border-left: 1px solid #FFDDCF !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-4 {
  border-right: 1px solid #FFDDCF !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-4 {
  border-right: 1px solid #FFDDCF !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-4 {
  border-left: 1px solid #FFDDCF !important; }

.overlay-warning.overlay-lighten-4 {
  background: #FFDDCF;
  /* The Fallback */
  background: rgba(255, 221, 207, 0.8); }

.warning.lighten-3 {
  color: #FFC6B0 !important; }

.bg-warning.bg-lighten-3 {
  background-color: #FFC6B0 !important; }

.btn-warning.btn-lighten-3 {
  border-color: #FF7A4E !important;
  background-color: #FFC6B0 !important; }
  .btn-warning.btn-lighten-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-3:focus, .btn-warning.btn-lighten-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-3 {
  border-color: #FFC6B0 !important;
  color: #FFC6B0 !important; }
  .btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #FFC6B0 !important; }

.progress-warning.progress-lighten-3 {
  background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-ms-fill {
    background-color: #FFC6B0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-3 .progress-bar {
      background-color: #FFC6B0; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC6B0 !important; }

.border-warning.border-lighten-3 {
  border: 1px solid #FFC6B0 !important; }

.border-top-warning.border-top-lighten-3 {
  border-top: 1px solid #FFC6B0 !important; }

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFC6B0 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-3 {
  border-left: 1px solid #FFC6B0 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-3 {
  border-right: 1px solid #FFC6B0 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-3 {
  border-right: 1px solid #FFC6B0 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-3 {
  border-left: 1px solid #FFC6B0 !important; }

.overlay-warning.overlay-lighten-3 {
  background: #FFC6B0;
  /* The Fallback */
  background: rgba(255, 198, 176, 0.8); }

.warning.lighten-2 {
  color: #FFAF90 !important; }

.bg-warning.bg-lighten-2 {
  background-color: #FFAF90 !important; }

.btn-warning.btn-lighten-2 {
  border-color: #FF7A4E !important;
  background-color: #FFAF90 !important; }
  .btn-warning.btn-lighten-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-2:focus, .btn-warning.btn-lighten-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-2 {
  border-color: #FFAF90 !important;
  color: #FFAF90 !important; }
  .btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #FFAF90 !important; }

.progress-warning.progress-lighten-2 {
  background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-ms-fill {
    background-color: #FFAF90; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-2 .progress-bar {
      background-color: #FFAF90; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAF90 !important; }

.border-warning.border-lighten-2 {
  border: 1px solid #FFAF90 !important; }

.border-top-warning.border-top-lighten-2 {
  border-top: 1px solid #FFAF90 !important; }

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFAF90 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-2 {
  border-left: 1px solid #FFAF90 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-2 {
  border-right: 1px solid #FFAF90 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-2 {
  border-right: 1px solid #FFAF90 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-2 {
  border-left: 1px solid #FFAF90 !important; }

.overlay-warning.overlay-lighten-2 {
  background: #FFAF90;
  /* The Fallback */
  background: rgba(255, 175, 144, 0.8); }

.warning.lighten-1 {
  color: #FF9E78 !important; }

.bg-warning.bg-lighten-1 {
  background-color: #FF9E78 !important; }

.btn-warning.btn-lighten-1 {
  border-color: #FF7A4E !important;
  background-color: #FF9E78 !important; }
  .btn-warning.btn-lighten-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-1:focus, .btn-warning.btn-lighten-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-1 {
  border-color: #FF9E78 !important;
  color: #FF9E78 !important; }
  .btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #FF9E78 !important; }

.progress-warning.progress-lighten-1 {
  background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-ms-fill {
    background-color: #FF9E78; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-1 .progress-bar {
      background-color: #FF9E78; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9E78 !important; }

.border-warning.border-lighten-1 {
  border: 1px solid #FF9E78 !important; }

.border-top-warning.border-top-lighten-1 {
  border-top: 1px solid #FF9E78 !important; }

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF9E78 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-1 {
  border-left: 1px solid #FF9E78 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-1 {
  border-right: 1px solid #FF9E78 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-1 {
  border-right: 1px solid #FF9E78 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-1 {
  border-left: 1px solid #FF9E78 !important; }

.overlay-warning.overlay-lighten-1 {
  background: #FF9E78;
  /* The Fallback */
  background: rgba(255, 158, 120, 0.8); }

.warning {
  color: #FF8D60 !important; }

.bg-warning {
  background-color: #FF8D60 !important; }
  .bg-warning .card-header, .bg-warning .card-footer {
    background-color: transparent; }

.toast-warning {
  background-color: #FF8D60; }

.alert-warning {
  border-color: #FF8D60 !important;
  background-color: #ffa784 !important;
  color: #ad3100 !important; }
  .alert-warning .alert-link {
    color: #842500 !important; }

.border-warning {
  border-color: #FF8D60; }

.border-top-warning {
  border-top-color: #FF8D60; }

.border-bottom-warning {
  border-bottom-color: #FF8D60; }

[dir="ltr"] .border-left-warning {
  border-left-color: #FF8D60; }

[dir="ltr"] .border-right-warning {
  border-right-color: #FF8D60; }

[dir="rtl"] .border-left-warning {
  border-right-color: #FF8D60; }

[dir="rtl"] .border-right-warning {
  border-left-color: #FF8D60; }

.badge-warning {
  background-color: #FF8D60; }

.panel-warning {
  border-color: #FF8D60; }
  .panel-warning .panel-heading {
    color: #FFF;
    border-color: #FF8D60;
    background-color: #ff9f7a; }

.bg-warning.tag-glow, .border-warning.tag-glow {
  box-shadow: 0px 0px 10px #FF8D60; }

.overlay-warning {
  background: #FF8D60;
  /* The Fallback */
  background: rgba(255, 141, 96, 0.8); }

.card.card-outline-warning {
  border-width: 1px;
  border-style: solid;
  border-color: #FF8D60;
  background-color: transparent; }
  .card.card-outline-warning .card-header, .card.card-outline-warning .card-footer {
    background-color: transparent; }

.btn-warning.btn-flat {
  background-color: transparent !important;
  color: #FF8D60;
  border: none; }

.btn-warning.btn-raised, .btn-warning.btn-fab {
  background-color: #FF8D60 !important;
  color: #fff !important;
  border-color: #FF8D60; }
  .btn-warning.btn-raised.active, .btn-warning.btn-fab.active {
    background-color: #ff733c !important;
    border-color: #ff733c !important; }

.btn-group-raised .btn-warning {
  background-color: #FF8D60 !important;
  color: #fff !important; }

.btn-outline-warning {
  border: 1px solid;
  border-color: #FF8D60;
  background-color: transparent;
  color: #FF8D60;
  box-shadow: none !important; }
  .btn-outline-warning:focus {
    background-color: transparent !important;
    color: #FF8D60 !important;
    box-shadow: transparent !important; }
  .btn-outline-warning.active {
    background-color: #FF8D60 !important;
    color: #FFF !important; }
  .btn-outline-warning:hover {
    background-color: #ff8251 !important;
    color: #FFF !important; }
    .btn-outline-warning:hover svg {
      color: #FFF !important; }

.btn-outline-warning.btn-raised, .btn-outline-warning.btn-fab {
  border: 1px solid;
  border-color: #FF8D60;
  background-color: transparent;
  color: #FF8D60;
  box-shadow: none !important; }
  .btn-outline-warning.btn-raised:focus, .btn-outline-warning.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-warning.btn-raised.active, .btn-outline-warning.btn-fab.active {
    background-color: #FF8D60 !important;
    color: #FFF !important; }
  .btn-outline-warning.btn-raised:hover, .btn-outline-warning.btn-fab:hover {
    background-color: #ff8251 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-warning {
  background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-webkit-progress-value {
    background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-moz-progress-bar {
    background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-ms-fill {
    background-color: #FF8D60; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-warning .progress-bar {
      background-color: #FF8D60; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8D60 !important; }

.warning.darken-1 {
  color: #FF8558 !important; }

.bg-warning.bg-darken-1 {
  background-color: #FF8558 !important; }

.btn-warning.btn-darken-1 {
  border-color: #FF7A4E !important;
  background-color: #FF8558 !important; }
  .btn-warning.btn-darken-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-1:focus, .btn-warning.btn-darken-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-1 {
  border-color: #FF8558 !important;
  color: #FF8558 !important; }
  .btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #FF8558 !important; }

.progress-warning.progress-darken-1 {
  background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-webkit-progress-value {
    background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-moz-progress-bar {
    background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-ms-fill {
    background-color: #FF8558; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-1 .progress-bar {
      background-color: #FF8558; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8558 !important; }

.border-warning.border-darken-1 {
  border: 1px solid #FF8558 !important; }

.border-top-warning.border-top-darken-1 {
  border-top: 1px solid #FF8558 !important; }

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom: 1px solid #FF8558 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-1 {
  border-left: 1px solid #FF8558 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-1 {
  border-right: 1px solid #FF8558 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-1 {
  border-right: 1px solid #FF8558 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-1 {
  border-left: 1px solid #FF8558 !important; }

.overlay-warning.overlay-darken-1 {
  background: #FF8558;
  /* The Fallback */
  background: rgba(255, 133, 88, 0.8); }

.warning.darken-2 {
  color: #FF7A4E !important; }

.bg-warning.bg-darken-2 {
  background-color: #FF7A4E !important; }

.btn-warning.btn-darken-2 {
  border-color: #FF7A4E !important;
  background-color: #FF7A4E !important; }
  .btn-warning.btn-darken-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-2:focus, .btn-warning.btn-darken-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-2 {
  border-color: #FF7A4E !important;
  color: #FF7A4E !important; }
  .btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #FF7A4E !important; }

.progress-warning.progress-darken-2 {
  background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-webkit-progress-value {
    background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-moz-progress-bar {
    background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-ms-fill {
    background-color: #FF7A4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-2 .progress-bar {
      background-color: #FF7A4E; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7A4E !important; }

.border-warning.border-darken-2 {
  border: 1px solid #FF7A4E !important; }

.border-top-warning.border-top-darken-2 {
  border-top: 1px solid #FF7A4E !important; }

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom: 1px solid #FF7A4E !important; }

[dir="ltr"] .border-left-warning.border-left-darken-2 {
  border-left: 1px solid #FF7A4E !important; }

[dir="ltr"] .border-right-warning.border-right-darken-2 {
  border-right: 1px solid #FF7A4E !important; }

[dir="rtl"] .border-left-warning.border-left-darken-2 {
  border-right: 1px solid #FF7A4E !important; }

[dir="rtl"] .border-right-warning.border-right-darken-2 {
  border-left: 1px solid #FF7A4E !important; }

.overlay-warning.overlay-darken-2 {
  background: #FF7A4E;
  /* The Fallback */
  background: rgba(255, 122, 78, 0.8); }

.warning.darken-3 {
  color: #FF7044 !important; }

.bg-warning.bg-darken-3 {
  background-color: #FF7044 !important; }

.btn-warning.btn-darken-3 {
  border-color: #FF7A4E !important;
  background-color: #FF7044 !important; }
  .btn-warning.btn-darken-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-3:focus, .btn-warning.btn-darken-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-3 {
  border-color: #FF7044 !important;
  color: #FF7044 !important; }
  .btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #FF7044 !important; }

.progress-warning.progress-darken-3 {
  background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-webkit-progress-value {
    background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-moz-progress-bar {
    background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-ms-fill {
    background-color: #FF7044; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-3 .progress-bar {
      background-color: #FF7044; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7044 !important; }

.border-warning.border-darken-3 {
  border: 1px solid #FF7044 !important; }

.border-top-warning.border-top-darken-3 {
  border-top: 1px solid #FF7044 !important; }

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom: 1px solid #FF7044 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-3 {
  border-left: 1px solid #FF7044 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-3 {
  border-right: 1px solid #FF7044 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-3 {
  border-right: 1px solid #FF7044 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-3 {
  border-left: 1px solid #FF7044 !important; }

.overlay-warning.overlay-darken-3 {
  background: #FF7044;
  /* The Fallback */
  background: rgba(255, 112, 68, 0.8); }

.warning.darken-4 {
  color: #FF5D33 !important; }

.bg-warning.bg-darken-4 {
  background-color: #FF5D33 !important; }

.btn-warning.btn-darken-4 {
  border-color: #FF7A4E !important;
  background-color: #FF5D33 !important; }
  .btn-warning.btn-darken-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-4:focus, .btn-warning.btn-darken-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-4 {
  border-color: #FF5D33 !important;
  color: #FF5D33 !important; }
  .btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #FF5D33 !important; }

.progress-warning.progress-darken-4 {
  background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-webkit-progress-value {
    background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-moz-progress-bar {
    background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-ms-fill {
    background-color: #FF5D33; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-4 .progress-bar {
      background-color: #FF5D33; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5D33 !important; }

.border-warning.border-darken-4 {
  border: 1px solid #FF5D33 !important; }

.border-top-warning.border-top-darken-4 {
  border-top: 1px solid #FF5D33 !important; }

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom: 1px solid #FF5D33 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-4 {
  border-left: 1px solid #FF5D33 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-4 {
  border-right: 1px solid #FF5D33 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-4 {
  border-right: 1px solid #FF5D33 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-4 {
  border-left: 1px solid #FF5D33 !important; }

.overlay-warning.overlay-darken-4 {
  background: #FF5D33;
  /* The Fallback */
  background: rgba(255, 93, 51, 0.8); }

.warning.accent-1 {
  color: #FFFFFF !important; }

.bg-warning.bg-accent-1 {
  background-color: #FFFFFF !important; }

.btn-warning.btn-accent-1 {
  border-color: #FF7A4E !important;
  background-color: #FFFFFF !important; }
  .btn-warning.btn-accent-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-1:focus, .btn-warning.btn-accent-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-1 {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #FFFFFF !important; }

.progress-warning.progress-accent-1 {
  background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-1 .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-warning.border-accent-1 {
  border: 1px solid #FFFFFF !important; }

.border-top-warning.border-top-accent-1 {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-warning.border-left-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-warning.border-right-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-warning.border-left-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-warning.border-right-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

.overlay-warning.overlay-accent-1 {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.warning.accent-2 {
  color: #FFEFEC !important; }

.bg-warning.bg-accent-2 {
  background-color: #FFEFEC !important; }

.btn-warning.btn-accent-2 {
  border-color: #FF7A4E !important;
  background-color: #FFEFEC !important; }
  .btn-warning.btn-accent-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-2:focus, .btn-warning.btn-accent-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-2 {
  border-color: #FFEFEC !important;
  color: #FFEFEC !important; }
  .btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #FFEFEC !important; }

.progress-warning.progress-accent-2 {
  background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-webkit-progress-value {
    background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-moz-progress-bar {
    background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-ms-fill {
    background-color: #FFEFEC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-2 .progress-bar {
      background-color: #FFEFEC; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEFEC !important; }

.border-warning.border-accent-2 {
  border: 1px solid #FFEFEC !important; }

.border-top-warning.border-top-accent-2 {
  border-top: 1px solid #FFEFEC !important; }

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom: 1px solid #FFEFEC !important; }

[dir="ltr"] .border-left-warning.border-left-accent-2 {
  border-left: 1px solid #FFEFEC !important; }

[dir="ltr"] .border-right-warning.border-right-accent-2 {
  border-right: 1px solid #FFEFEC !important; }

[dir="rtl"] .border-left-warning.border-left-accent-2 {
  border-right: 1px solid #FFEFEC !important; }

[dir="rtl"] .border-right-warning.border-right-accent-2 {
  border-left: 1px solid #FFEFEC !important; }

.overlay-warning.overlay-accent-2 {
  background: #FFEFEC;
  /* The Fallback */
  background: rgba(255, 239, 236, 0.8); }

.warning.accent-3 {
  color: #FFE4DE !important; }

.bg-warning.bg-accent-3 {
  background-color: #FFE4DE !important; }

.btn-warning.btn-accent-3 {
  border-color: #FF7A4E !important;
  background-color: #FFE4DE !important; }
  .btn-warning.btn-accent-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-3:focus, .btn-warning.btn-accent-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-3 {
  border-color: #FFE4DE !important;
  color: #FFE4DE !important; }
  .btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #FFE4DE !important; }

.progress-warning.progress-accent-3 {
  background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-webkit-progress-value {
    background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-moz-progress-bar {
    background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-ms-fill {
    background-color: #FFE4DE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-3 .progress-bar {
      background-color: #FFE4DE; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE4DE !important; }

.border-warning.border-accent-3 {
  border: 1px solid #FFE4DE !important; }

.border-top-warning.border-top-accent-3 {
  border-top: 1px solid #FFE4DE !important; }

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom: 1px solid #FFE4DE !important; }

[dir="ltr"] .border-left-warning.border-left-accent-3 {
  border-left: 1px solid #FFE4DE !important; }

[dir="ltr"] .border-right-warning.border-right-accent-3 {
  border-right: 1px solid #FFE4DE !important; }

[dir="rtl"] .border-left-warning.border-left-accent-3 {
  border-right: 1px solid #FFE4DE !important; }

[dir="rtl"] .border-right-warning.border-right-accent-3 {
  border-left: 1px solid #FFE4DE !important; }

.overlay-warning.overlay-accent-3 {
  background: #FFE4DE;
  /* The Fallback */
  background: rgba(255, 228, 222, 0.8); }

.warning.accent-4 {
  color: #FFCFC4 !important; }

.bg-warning.bg-accent-4 {
  background-color: #FFCFC4 !important; }

.btn-warning.btn-accent-4 {
  border-color: #FF7A4E !important;
  background-color: #FFCFC4 !important; }
  .btn-warning.btn-accent-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-4:focus, .btn-warning.btn-accent-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-4 {
  border-color: #FFCFC4 !important;
  color: #FFCFC4 !important; }
  .btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #FFCFC4 !important; }

.progress-warning.progress-accent-4 {
  background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-webkit-progress-value {
    background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-moz-progress-bar {
    background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-ms-fill {
    background-color: #FFCFC4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-4 .progress-bar {
      background-color: #FFCFC4; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCFC4 !important; }

.border-warning.border-accent-4 {
  border: 1px solid #FFCFC4 !important; }

.border-top-warning.border-top-accent-4 {
  border-top: 1px solid #FFCFC4 !important; }

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom: 1px solid #FFCFC4 !important; }

[dir="ltr"] .border-left-warning.border-left-accent-4 {
  border-left: 1px solid #FFCFC4 !important; }

[dir="ltr"] .border-right-warning.border-right-accent-4 {
  border-right: 1px solid #FFCFC4 !important; }

[dir="rtl"] .border-left-warning.border-left-accent-4 {
  border-right: 1px solid #FFCFC4 !important; }

[dir="rtl"] .border-right-warning.border-right-accent-4 {
  border-left: 1px solid #FFCFC4 !important; }

.overlay-warning.overlay-accent-4 {
  background: #FFCFC4;
  /* The Fallback */
  background: rgba(255, 207, 196, 0.8); }

.danger.lighten-5 {
  color: #FFEBED !important; }

.bg-danger.bg-lighten-5 {
  background-color: #FFEBED !important; }

.btn-danger.btn-lighten-5 {
  border-color: #FF4758 !important;
  background-color: #FFEBED !important; }
  .btn-danger.btn-lighten-5:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-5:focus, .btn-danger.btn-lighten-5:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-5 {
  border-color: #FFEBED !important;
  color: #FFEBED !important; }
  .btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #FFEBED !important; }

.progress-danger.progress-lighten-5 {
  background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-ms-fill {
    background-color: #FFEBED; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-5 .progress-bar {
      background-color: #FFEBED; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEBED !important; }

.border-danger.border-lighten-5 {
  border: 1px solid #FFEBED !important; }

.border-top-danger.border-top-lighten-5 {
  border-top: 1px solid #FFEBED !important; }

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFEBED !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-5 {
  border-left: 1px solid #FFEBED !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-5 {
  border-right: 1px solid #FFEBED !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-5 {
  border-right: 1px solid #FFEBED !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-5 {
  border-left: 1px solid #FFEBED !important; }

.overlay-danger.overlay-lighten-5 {
  background: #FFEBED;
  /* The Fallback */
  background: rgba(255, 235, 237, 0.8); }

.danger.lighten-4 {
  color: #FFCDD3 !important; }

.bg-danger.bg-lighten-4 {
  background-color: #FFCDD3 !important; }

.btn-danger.btn-lighten-4 {
  border-color: #FF4758 !important;
  background-color: #FFCDD3 !important; }
  .btn-danger.btn-lighten-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-4:focus, .btn-danger.btn-lighten-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-4 {
  border-color: #FFCDD3 !important;
  color: #FFCDD3 !important; }
  .btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #FFCDD3 !important; }

.progress-danger.progress-lighten-4 {
  background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-ms-fill {
    background-color: #FFCDD3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-4 .progress-bar {
      background-color: #FFCDD3; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCDD3 !important; }

.border-danger.border-lighten-4 {
  border: 1px solid #FFCDD3 !important; }

.border-top-danger.border-top-lighten-4 {
  border-top: 1px solid #FFCDD3 !important; }

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFCDD3 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-4 {
  border-left: 1px solid #FFCDD3 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-4 {
  border-right: 1px solid #FFCDD3 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-4 {
  border-right: 1px solid #FFCDD3 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-4 {
  border-left: 1px solid #FFCDD3 !important; }

.overlay-danger.overlay-lighten-4 {
  background: #FFCDD3;
  /* The Fallback */
  background: rgba(255, 205, 211, 0.8); }

.danger.lighten-3 {
  color: #FFACB5 !important; }

.bg-danger.bg-lighten-3 {
  background-color: #FFACB5 !important; }

.btn-danger.btn-lighten-3 {
  border-color: #FF4758 !important;
  background-color: #FFACB5 !important; }
  .btn-danger.btn-lighten-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-3:focus, .btn-danger.btn-lighten-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-3 {
  border-color: #FFACB5 !important;
  color: #FFACB5 !important; }
  .btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #FFACB5 !important; }

.progress-danger.progress-lighten-3 {
  background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-ms-fill {
    background-color: #FFACB5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-3 .progress-bar {
      background-color: #FFACB5; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFACB5 !important; }

.border-danger.border-lighten-3 {
  border: 1px solid #FFACB5 !important; }

.border-top-danger.border-top-lighten-3 {
  border-top: 1px solid #FFACB5 !important; }

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFACB5 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-3 {
  border-left: 1px solid #FFACB5 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-3 {
  border-right: 1px solid #FFACB5 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-3 {
  border-right: 1px solid #FFACB5 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-3 {
  border-left: 1px solid #FFACB5 !important; }

.overlay-danger.overlay-lighten-3 {
  background: #FFACB5;
  /* The Fallback */
  background: rgba(255, 172, 181, 0.8); }

.danger.lighten-2 {
  color: #FF8A97 !important; }

.bg-danger.bg-lighten-2 {
  background-color: #FF8A97 !important; }

.btn-danger.btn-lighten-2 {
  border-color: #FF4758 !important;
  background-color: #FF8A97 !important; }
  .btn-danger.btn-lighten-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-2:focus, .btn-danger.btn-lighten-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-2 {
  border-color: #FF8A97 !important;
  color: #FF8A97 !important; }
  .btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #FF8A97 !important; }

.progress-danger.progress-lighten-2 {
  background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-ms-fill {
    background-color: #FF8A97; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-2 .progress-bar {
      background-color: #FF8A97; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A97 !important; }

.border-danger.border-lighten-2 {
  border: 1px solid #FF8A97 !important; }

.border-top-danger.border-top-lighten-2 {
  border-top: 1px solid #FF8A97 !important; }

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom: 1px solid #FF8A97 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-2 {
  border-left: 1px solid #FF8A97 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-2 {
  border-right: 1px solid #FF8A97 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-2 {
  border-right: 1px solid #FF8A97 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-2 {
  border-left: 1px solid #FF8A97 !important; }

.overlay-danger.overlay-lighten-2 {
  background: #FF8A97;
  /* The Fallback */
  background: rgba(255, 138, 151, 0.8); }

.danger.lighten-1 {
  color: #FF7181 !important; }

.bg-danger.bg-lighten-1 {
  background-color: #FF7181 !important; }

.btn-danger.btn-lighten-1 {
  border-color: #FF4758 !important;
  background-color: #FF7181 !important; }
  .btn-danger.btn-lighten-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-1:focus, .btn-danger.btn-lighten-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-1 {
  border-color: #FF7181 !important;
  color: #FF7181 !important; }
  .btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #FF7181 !important; }

.progress-danger.progress-lighten-1 {
  background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-ms-fill {
    background-color: #FF7181; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-1 .progress-bar {
      background-color: #FF7181; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7181 !important; }

.border-danger.border-lighten-1 {
  border: 1px solid #FF7181 !important; }

.border-top-danger.border-top-lighten-1 {
  border-top: 1px solid #FF7181 !important; }

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF7181 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-1 {
  border-left: 1px solid #FF7181 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-1 {
  border-right: 1px solid #FF7181 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-1 {
  border-right: 1px solid #FF7181 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-1 {
  border-left: 1px solid #FF7181 !important; }

.overlay-danger.overlay-lighten-1 {
  background: #FF7181;
  /* The Fallback */
  background: rgba(255, 113, 129, 0.8); }

.danger {
  color: #FF586B !important; }

.bg-danger {
  background-color: #FF586B !important; }
  .bg-danger .card-header, .bg-danger .card-footer {
    background-color: transparent; }

.toast-danger {
  background-color: #FF586B; }

.alert-danger {
  border-color: #FF586B !important;
  background-color: #ff7c8b !important;
  color: #a50013 !important; }
  .alert-danger .alert-link {
    color: #7c000e !important; }

.border-danger {
  border-color: #FF586B; }

.border-top-danger {
  border-top-color: #FF586B; }

.border-bottom-danger {
  border-bottom-color: #FF586B; }

[dir="ltr"] .border-left-danger {
  border-left-color: #FF586B; }

[dir="ltr"] .border-right-danger {
  border-right-color: #FF586B; }

[dir="rtl"] .border-left-danger {
  border-right-color: #FF586B; }

[dir="rtl"] .border-right-danger {
  border-left-color: #FF586B; }

.badge-danger {
  background-color: #FF586B; }

.panel-danger {
  border-color: #FF586B; }
  .panel-danger .panel-heading {
    color: #FFF;
    border-color: #FF586B;
    background-color: #ff7282; }

.bg-danger.tag-glow, .border-danger.tag-glow {
  box-shadow: 0px 0px 10px #FF586B; }

.overlay-danger {
  background: #FF586B;
  /* The Fallback */
  background: rgba(255, 88, 107, 0.8); }

.card.card-outline-danger {
  border-width: 1px;
  border-style: solid;
  border-color: #FF586B;
  background-color: transparent; }
  .card.card-outline-danger .card-header, .card.card-outline-danger .card-footer {
    background-color: transparent; }

.btn-danger.btn-flat {
  background-color: transparent !important;
  color: #FF586B;
  border: none; }

.btn-danger.btn-raised, .btn-danger.btn-fab {
  background-color: #FF586B !important;
  color: #fff !important;
  border-color: #FF586B; }
  .btn-danger.btn-raised.active, .btn-danger.btn-fab.active {
    background-color: #ff344b !important;
    border-color: #ff344b !important; }

.btn-group-raised .btn-danger {
  background-color: #FF586B !important;
  color: #fff !important; }

.btn-outline-danger {
  border: 1px solid;
  border-color: #FF586B;
  background-color: transparent;
  color: #FF586B;
  box-shadow: none !important; }
  .btn-outline-danger:focus {
    background-color: transparent !important;
    color: #FF586B !important;
    box-shadow: transparent !important; }
  .btn-outline-danger.active {
    background-color: #FF586B !important;
    color: #FFF !important; }
  .btn-outline-danger:hover {
    background-color: #ff495d !important;
    color: #FFF !important; }
    .btn-outline-danger:hover svg {
      color: #FFF !important; }

.btn-outline-danger.btn-raised, .btn-outline-danger.btn-fab {
  border: 1px solid;
  border-color: #FF586B;
  background-color: transparent;
  color: #FF586B;
  box-shadow: none !important; }
  .btn-outline-danger.btn-raised:focus, .btn-outline-danger.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-danger.btn-raised.active, .btn-outline-danger.btn-fab.active {
    background-color: #FF586B !important;
    color: #FFF !important; }
  .btn-outline-danger.btn-raised:hover, .btn-outline-danger.btn-fab:hover {
    background-color: #ff495d !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-danger {
  background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-webkit-progress-value {
    background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-moz-progress-bar {
    background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-ms-fill {
    background-color: #FF586B; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-danger .progress-bar {
      background-color: #FF586B; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF586B !important; }

.danger.darken-1 {
  color: #FF5063 !important; }

.bg-danger.bg-darken-1 {
  background-color: #FF5063 !important; }

.btn-danger.btn-darken-1 {
  border-color: #FF4758 !important;
  background-color: #FF5063 !important; }
  .btn-danger.btn-darken-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-1:focus, .btn-danger.btn-darken-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-1 {
  border-color: #FF5063 !important;
  color: #FF5063 !important; }
  .btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #FF5063 !important; }

.progress-danger.progress-darken-1 {
  background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-webkit-progress-value {
    background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-moz-progress-bar {
    background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-ms-fill {
    background-color: #FF5063; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-1 .progress-bar {
      background-color: #FF5063; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5063 !important; }

.border-danger.border-darken-1 {
  border: 1px solid #FF5063 !important; }

.border-top-danger.border-top-darken-1 {
  border-top: 1px solid #FF5063 !important; }

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom: 1px solid #FF5063 !important; }

[dir="ltr"] .border-left-danger.border-left-darken-1 {
  border-left: 1px solid #FF5063 !important; }

[dir="ltr"] .border-right-danger.border-right-darken-1 {
  border-right: 1px solid #FF5063 !important; }

[dir="rtl"] .border-left-danger.border-left-darken-1 {
  border-right: 1px solid #FF5063 !important; }

[dir="rtl"] .border-right-danger.border-right-darken-1 {
  border-left: 1px solid #FF5063 !important; }

.overlay-danger.overlay-darken-1 {
  background: #FF5063;
  /* The Fallback */
  background: rgba(255, 80, 99, 0.8); }

.danger.darken-2 {
  color: #FF4758 !important; }

.bg-danger.bg-darken-2 {
  background-color: #FF4758 !important; }

.btn-danger.btn-darken-2 {
  border-color: #FF4758 !important;
  background-color: #FF4758 !important; }
  .btn-danger.btn-darken-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-2:focus, .btn-danger.btn-darken-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-2 {
  border-color: #FF4758 !important;
  color: #FF4758 !important; }
  .btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #FF4758 !important; }

.progress-danger.progress-darken-2 {
  background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-webkit-progress-value {
    background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-moz-progress-bar {
    background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-ms-fill {
    background-color: #FF4758; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-2 .progress-bar {
      background-color: #FF4758; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4758 !important; }

.border-danger.border-darken-2 {
  border: 1px solid #FF4758 !important; }

.border-top-danger.border-top-darken-2 {
  border-top: 1px solid #FF4758 !important; }

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom: 1px solid #FF4758 !important; }

[dir="ltr"] .border-left-danger.border-left-darken-2 {
  border-left: 1px solid #FF4758 !important; }

[dir="ltr"] .border-right-danger.border-right-darken-2 {
  border-right: 1px solid #FF4758 !important; }

[dir="rtl"] .border-left-danger.border-left-darken-2 {
  border-right: 1px solid #FF4758 !important; }

[dir="rtl"] .border-right-danger.border-right-darken-2 {
  border-left: 1px solid #FF4758 !important; }

.overlay-danger.overlay-darken-2 {
  background: #FF4758;
  /* The Fallback */
  background: rgba(255, 71, 88, 0.8); }

.danger.darken-3 {
  color: #FF3D4E !important; }

.bg-danger.bg-darken-3 {
  background-color: #FF3D4E !important; }

.btn-danger.btn-darken-3 {
  border-color: #FF4758 !important;
  background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-3:focus, .btn-danger.btn-darken-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-3 {
  border-color: #FF3D4E !important;
  color: #FF3D4E !important; }
  .btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #FF3D4E !important; }

.progress-danger.progress-darken-3 {
  background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-webkit-progress-value {
    background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-moz-progress-bar {
    background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-ms-fill {
    background-color: #FF3D4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-3 .progress-bar {
      background-color: #FF3D4E; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF3D4E !important; }

.border-danger.border-darken-3 {
  border: 1px solid #FF3D4E !important; }

.border-top-danger.border-top-darken-3 {
  border-top: 1px solid #FF3D4E !important; }

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom: 1px solid #FF3D4E !important; }

[dir="ltr"] .border-left-danger.border-left-darken-3 {
  border-left: 1px solid #FF3D4E !important; }

[dir="ltr"] .border-right-danger.border-right-darken-3 {
  border-right: 1px solid #FF3D4E !important; }

[dir="rtl"] .border-left-danger.border-left-darken-3 {
  border-right: 1px solid #FF3D4E !important; }

[dir="rtl"] .border-right-danger.border-right-darken-3 {
  border-left: 1px solid #FF3D4E !important; }

.overlay-danger.overlay-darken-3 {
  background: #FF3D4E;
  /* The Fallback */
  background: rgba(255, 61, 78, 0.8); }

.danger.darken-4 {
  color: #FF2D3C !important; }

.bg-danger.bg-darken-4 {
  background-color: #FF2D3C !important; }

.btn-danger.btn-darken-4 {
  border-color: #FF4758 !important;
  background-color: #FF2D3C !important; }
  .btn-danger.btn-darken-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-4:focus, .btn-danger.btn-darken-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-4 {
  border-color: #FF2D3C !important;
  color: #FF2D3C !important; }
  .btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #FF2D3C !important; }

.progress-danger.progress-darken-4 {
  background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-webkit-progress-value {
    background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-moz-progress-bar {
    background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-ms-fill {
    background-color: #FF2D3C; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-4 .progress-bar {
      background-color: #FF2D3C; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF2D3C !important; }

.border-danger.border-darken-4 {
  border: 1px solid #FF2D3C !important; }

.border-top-danger.border-top-darken-4 {
  border-top: 1px solid #FF2D3C !important; }

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom: 1px solid #FF2D3C !important; }

[dir="ltr"] .border-left-danger.border-left-darken-4 {
  border-left: 1px solid #FF2D3C !important; }

[dir="ltr"] .border-right-danger.border-right-darken-4 {
  border-right: 1px solid #FF2D3C !important; }

[dir="rtl"] .border-left-danger.border-left-darken-4 {
  border-right: 1px solid #FF2D3C !important; }

[dir="rtl"] .border-right-danger.border-right-darken-4 {
  border-left: 1px solid #FF2D3C !important; }

.overlay-danger.overlay-darken-4 {
  background: #FF2D3C;
  /* The Fallback */
  background: rgba(255, 45, 60, 0.8); }

.danger.accent-1 {
  color: #FFFFFF !important; }

.bg-danger.bg-accent-1 {
  background-color: #FFFFFF !important; }

.btn-danger.btn-accent-1 {
  border-color: #FF4758 !important;
  background-color: #FFFFFF !important; }
  .btn-danger.btn-accent-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-1:focus, .btn-danger.btn-accent-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-1 {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #FFFFFF !important; }

.progress-danger.progress-accent-1 {
  background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-1 .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-danger.border-accent-1 {
  border: 1px solid #FFFFFF !important; }

.border-top-danger.border-top-accent-1 {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-danger.border-left-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-danger.border-right-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-danger.border-left-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-danger.border-right-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

.overlay-danger.overlay-accent-1 {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.danger.accent-2 {
  color: #FBEBED !important; }

.bg-danger.bg-accent-2 {
  background-color: #FBEBED !important; }

.btn-danger.btn-accent-2 {
  border-color: #FF4758 !important;
  background-color: #FBEBED !important; }
  .btn-danger.btn-accent-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-2:focus, .btn-danger.btn-accent-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-2 {
  border-color: #FBEBED !important;
  color: #FBEBED !important; }
  .btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #FBEBED !important; }

.progress-danger.progress-accent-2 {
  background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-webkit-progress-value {
    background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-moz-progress-bar {
    background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-ms-fill {
    background-color: #FBEBED; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-2 .progress-bar {
      background-color: #FBEBED; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FBEBED !important; }

.border-danger.border-accent-2 {
  border: 1px solid #FBEBED !important; }

.border-top-danger.border-top-accent-2 {
  border-top: 1px solid #FBEBED !important; }

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom: 1px solid #FBEBED !important; }

[dir="ltr"] .border-left-danger.border-left-accent-2 {
  border-left: 1px solid #FBEBED !important; }

[dir="ltr"] .border-right-danger.border-right-accent-2 {
  border-right: 1px solid #FBEBED !important; }

[dir="rtl"] .border-left-danger.border-left-accent-2 {
  border-right: 1px solid #FBEBED !important; }

[dir="rtl"] .border-right-danger.border-right-accent-2 {
  border-left: 1px solid #FBEBED !important; }

.overlay-danger.overlay-accent-2 {
  background: #FBEBED;
  /* The Fallback */
  background: rgba(251, 235, 237, 0.8); }

.danger.accent-3 {
  color: #FFD9DB !important; }

.bg-danger.bg-accent-3 {
  background-color: #FFD9DB !important; }

.btn-danger.btn-accent-3 {
  border-color: #FF4758 !important;
  background-color: #FFD9DB !important; }
  .btn-danger.btn-accent-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-3:focus, .btn-danger.btn-accent-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-3 {
  border-color: #FFD9DB !important;
  color: #FFD9DB !important; }
  .btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #FFD9DB !important; }

.progress-danger.progress-accent-3 {
  background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-webkit-progress-value {
    background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-moz-progress-bar {
    background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-ms-fill {
    background-color: #FFD9DB; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-3 .progress-bar {
      background-color: #FFD9DB; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD9DB !important; }

.border-danger.border-accent-3 {
  border: 1px solid #FFD9DB !important; }

.border-top-danger.border-top-accent-3 {
  border-top: 1px solid #FFD9DB !important; }

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom: 1px solid #FFD9DB !important; }

[dir="ltr"] .border-left-danger.border-left-accent-3 {
  border-left: 1px solid #FFD9DB !important; }

[dir="ltr"] .border-right-danger.border-right-accent-3 {
  border-right: 1px solid #FFD9DB !important; }

[dir="rtl"] .border-left-danger.border-left-accent-3 {
  border-right: 1px solid #FFD9DB !important; }

[dir="rtl"] .border-right-danger.border-right-accent-3 {
  border-left: 1px solid #FFD9DB !important; }

.overlay-danger.overlay-accent-3 {
  background: #FFD9DB;
  /* The Fallback */
  background: rgba(255, 217, 219, 0.8); }

.danger.accent-4 {
  color: #FFBFC3 !important; }

.bg-danger.bg-accent-4 {
  background-color: #FFBFC3 !important; }

.btn-danger.btn-accent-4 {
  border-color: #FF4758 !important;
  background-color: #FFBFC3 !important; }
  .btn-danger.btn-accent-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-4:focus, .btn-danger.btn-accent-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-4 {
  border-color: #FFBFC3 !important;
  color: #FFBFC3 !important; }
  .btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #FFBFC3 !important; }

.progress-danger.progress-accent-4 {
  background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-webkit-progress-value {
    background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-moz-progress-bar {
    background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-ms-fill {
    background-color: #FFBFC3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-4 .progress-bar {
      background-color: #FFBFC3; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFBFC3 !important; }

.border-danger.border-accent-4 {
  border: 1px solid #FFBFC3 !important; }

.border-top-danger.border-top-accent-4 {
  border-top: 1px solid #FFBFC3 !important; }

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom: 1px solid #FFBFC3 !important; }

[dir="ltr"] .border-left-danger.border-left-accent-4 {
  border-left: 1px solid #FFBFC3 !important; }

[dir="ltr"] .border-right-danger.border-right-accent-4 {
  border-right: 1px solid #FFBFC3 !important; }

[dir="rtl"] .border-left-danger.border-left-accent-4 {
  border-right: 1px solid #FFBFC3 !important; }

[dir="rtl"] .border-right-danger.border-right-accent-4 {
  border-left: 1px solid #FFBFC3 !important; }

.overlay-danger.overlay-accent-4 {
  background: #FFBFC3;
  /* The Fallback */
  background: rgba(255, 191, 195, 0.8); }

.red.lighten-5 {
  color: #ffebee !important; }

.bg-red.bg-lighten-5 {
  background-color: #ffebee !important; }

.btn-red.btn-lighten-5 {
  border-color: #d32f2f !important;
  background-color: #ffebee !important; }
  .btn-red.btn-lighten-5:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-5:focus, .btn-red.btn-lighten-5:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-5 {
  border-color: #ffebee !important;
  color: #ffebee !important; }
  .btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #ffebee !important; }

.progress-red.progress-lighten-5 {
  background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-ms-fill {
    background-color: #ffebee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-5 .progress-bar {
      background-color: #ffebee; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffebee !important; }

.border-red.border-lighten-5 {
  border: 1px solid #ffebee !important; }

.border-top-red.border-top-lighten-5 {
  border-top: 1px solid #ffebee !important; }

.border-bottom-red.border-bottom-lighten-5 {
  border-bottom: 1px solid #ffebee !important; }

[dir="ltr"] .border-left-red.border-left-lighten-5 {
  border-left: 1px solid #ffebee !important; }

[dir="ltr"] .border-right-red.border-right-lighten-5 {
  border-right: 1px solid #ffebee !important; }

[dir="rtl"] .border-left-red.border-left-lighten-5 {
  border-right: 1px solid #ffebee !important; }

[dir="rtl"] .border-right-red.border-right-lighten-5 {
  border-left: 1px solid #ffebee !important; }

.overlay-red.overlay-lighten-5 {
  background: #ffebee;
  /* The Fallback */
  background: rgba(255, 235, 238, 0.8); }

.red.lighten-4 {
  color: #ffcdd2 !important; }

.bg-red.bg-lighten-4 {
  background-color: #ffcdd2 !important; }

.btn-red.btn-lighten-4 {
  border-color: #d32f2f !important;
  background-color: #ffcdd2 !important; }
  .btn-red.btn-lighten-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-4:focus, .btn-red.btn-lighten-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-4 {
  border-color: #ffcdd2 !important;
  color: #ffcdd2 !important; }
  .btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #ffcdd2 !important; }

.progress-red.progress-lighten-4 {
  background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-ms-fill {
    background-color: #ffcdd2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-4 .progress-bar {
      background-color: #ffcdd2; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcdd2 !important; }

.border-red.border-lighten-4 {
  border: 1px solid #ffcdd2 !important; }

.border-top-red.border-top-lighten-4 {
  border-top: 1px solid #ffcdd2 !important; }

.border-bottom-red.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffcdd2 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-4 {
  border-left: 1px solid #ffcdd2 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-4 {
  border-right: 1px solid #ffcdd2 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-4 {
  border-right: 1px solid #ffcdd2 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-4 {
  border-left: 1px solid #ffcdd2 !important; }

.overlay-red.overlay-lighten-4 {
  background: #ffcdd2;
  /* The Fallback */
  background: rgba(255, 205, 210, 0.8); }

.red.lighten-3 {
  color: #ef9a9a !important; }

.bg-red.bg-lighten-3 {
  background-color: #ef9a9a !important; }

.btn-red.btn-lighten-3 {
  border-color: #d32f2f !important;
  background-color: #ef9a9a !important; }
  .btn-red.btn-lighten-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-3:focus, .btn-red.btn-lighten-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-3 {
  border-color: #ef9a9a !important;
  color: #ef9a9a !important; }
  .btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #ef9a9a !important; }

.progress-red.progress-lighten-3 {
  background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-ms-fill {
    background-color: #ef9a9a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-3 .progress-bar {
      background-color: #ef9a9a; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef9a9a !important; }

.border-red.border-lighten-3 {
  border: 1px solid #ef9a9a !important; }

.border-top-red.border-top-lighten-3 {
  border-top: 1px solid #ef9a9a !important; }

.border-bottom-red.border-bottom-lighten-3 {
  border-bottom: 1px solid #ef9a9a !important; }

[dir="ltr"] .border-left-red.border-left-lighten-3 {
  border-left: 1px solid #ef9a9a !important; }

[dir="ltr"] .border-right-red.border-right-lighten-3 {
  border-right: 1px solid #ef9a9a !important; }

[dir="rtl"] .border-left-red.border-left-lighten-3 {
  border-right: 1px solid #ef9a9a !important; }

[dir="rtl"] .border-right-red.border-right-lighten-3 {
  border-left: 1px solid #ef9a9a !important; }

.overlay-red.overlay-lighten-3 {
  background: #ef9a9a;
  /* The Fallback */
  background: rgba(239, 154, 154, 0.8); }

.red.lighten-2 {
  color: #e57373 !important; }

.bg-red.bg-lighten-2 {
  background-color: #e57373 !important; }

.btn-red.btn-lighten-2 {
  border-color: #d32f2f !important;
  background-color: #e57373 !important; }
  .btn-red.btn-lighten-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-2:focus, .btn-red.btn-lighten-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-2 {
  border-color: #e57373 !important;
  color: #e57373 !important; }
  .btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #e57373 !important; }

.progress-red.progress-lighten-2 {
  background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-ms-fill {
    background-color: #e57373; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-2 .progress-bar {
      background-color: #e57373; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e57373 !important; }

.border-red.border-lighten-2 {
  border: 1px solid #e57373 !important; }

.border-top-red.border-top-lighten-2 {
  border-top: 1px solid #e57373 !important; }

.border-bottom-red.border-bottom-lighten-2 {
  border-bottom: 1px solid #e57373 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-2 {
  border-left: 1px solid #e57373 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-2 {
  border-right: 1px solid #e57373 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-2 {
  border-right: 1px solid #e57373 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-2 {
  border-left: 1px solid #e57373 !important; }

.overlay-red.overlay-lighten-2 {
  background: #e57373;
  /* The Fallback */
  background: rgba(229, 115, 115, 0.8); }

.red.lighten-1 {
  color: #ef5350 !important; }

.bg-red.bg-lighten-1 {
  background-color: #ef5350 !important; }

.btn-red.btn-lighten-1 {
  border-color: #d32f2f !important;
  background-color: #ef5350 !important; }
  .btn-red.btn-lighten-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-1:focus, .btn-red.btn-lighten-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-1 {
  border-color: #ef5350 !important;
  color: #ef5350 !important; }
  .btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #ef5350 !important; }

.progress-red.progress-lighten-1 {
  background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-ms-fill {
    background-color: #ef5350; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-1 .progress-bar {
      background-color: #ef5350; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef5350 !important; }

.border-red.border-lighten-1 {
  border: 1px solid #ef5350 !important; }

.border-top-red.border-top-lighten-1 {
  border-top: 1px solid #ef5350 !important; }

.border-bottom-red.border-bottom-lighten-1 {
  border-bottom: 1px solid #ef5350 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-1 {
  border-left: 1px solid #ef5350 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-1 {
  border-right: 1px solid #ef5350 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-1 {
  border-right: 1px solid #ef5350 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-1 {
  border-left: 1px solid #ef5350 !important; }

.overlay-red.overlay-lighten-1 {
  background: #ef5350;
  /* The Fallback */
  background: rgba(239, 83, 80, 0.8); }

.red {
  color: #f44336 !important; }

.bg-red {
  background-color: #f44336 !important; }
  .bg-red .card-header, .bg-red .card-footer {
    background-color: transparent; }

.toast-red {
  background-color: #f44336; }

.alert-red {
  border-color: #f44336 !important;
  background-color: #f66358 !important;
  color: #710e06 !important; }
  .alert-red .alert-link {
    color: #4b0904 !important; }

.border-red {
  border-color: #f44336; }

.border-top-red {
  border-top-color: #f44336; }

.border-bottom-red {
  border-bottom-color: #f44336; }

[dir="ltr"] .border-left-red {
  border-left-color: #f44336; }

[dir="ltr"] .border-right-red {
  border-right-color: #f44336; }

[dir="rtl"] .border-left-red {
  border-right-color: #f44336; }

[dir="rtl"] .border-right-red {
  border-left-color: #f44336; }

.badge-red {
  background-color: #f44336; }

.panel-red {
  border-color: #f44336; }
  .panel-red .panel-heading {
    color: #FFF;
    border-color: #f44336;
    background-color: #f55a4e; }

.bg-red.tag-glow, .border-red.tag-glow {
  box-shadow: 0px 0px 10px #f44336; }

.overlay-red {
  background: #f44336;
  /* The Fallback */
  background: rgba(244, 67, 54, 0.8); }

.card.card-outline-red {
  border-width: 1px;
  border-style: solid;
  border-color: #f44336;
  background-color: transparent; }
  .card.card-outline-red .card-header, .card.card-outline-red .card-footer {
    background-color: transparent; }

.btn-red.btn-flat {
  background-color: transparent !important;
  color: #f44336;
  border: none; }

.btn-red.btn-raised, .btn-red.btn-fab {
  background-color: #f44336 !important;
  color: #fff !important;
  border-color: #f44336; }
  .btn-red.btn-raised.active, .btn-red.btn-fab.active {
    background-color: #f22314 !important;
    border-color: #f22314 !important; }

.btn-group-raised .btn-red {
  background-color: #f44336 !important;
  color: #fff !important; }

.btn-outline-red {
  border: 1px solid;
  border-color: #f44336;
  background-color: transparent;
  color: #f44336;
  box-shadow: none !important; }
  .btn-outline-red:focus {
    background-color: transparent !important;
    color: #f44336 !important;
    box-shadow: transparent !important; }
  .btn-outline-red.active {
    background-color: #f44336 !important;
    color: #FFF !important; }
  .btn-outline-red:hover {
    background-color: #f33527 !important;
    color: #FFF !important; }
    .btn-outline-red:hover svg {
      color: #FFF !important; }

.btn-outline-red.btn-raised, .btn-outline-red.btn-fab {
  border: 1px solid;
  border-color: #f44336;
  background-color: transparent;
  color: #f44336;
  box-shadow: none !important; }
  .btn-outline-red.btn-raised:focus, .btn-outline-red.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-red.btn-raised.active, .btn-outline-red.btn-fab.active {
    background-color: #f44336 !important;
    color: #FFF !important; }
  .btn-outline-red.btn-raised:hover, .btn-outline-red.btn-fab:hover {
    background-color: #f33527 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-red {
  background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-webkit-progress-value {
    background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-moz-progress-bar {
    background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-ms-fill {
    background-color: #f44336; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-red .progress-bar {
      background-color: #f44336; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f44336 !important; }

.red.darken-1 {
  color: #e53935 !important; }

.bg-red.bg-darken-1 {
  background-color: #e53935 !important; }

.btn-red.btn-darken-1 {
  border-color: #d32f2f !important;
  background-color: #e53935 !important; }
  .btn-red.btn-darken-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-1:focus, .btn-red.btn-darken-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-1 {
  border-color: #e53935 !important;
  color: #e53935 !important; }
  .btn-outline-red.btn-outline-darken-1:hover {
    background-color: #e53935 !important; }

.progress-red.progress-darken-1 {
  background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-webkit-progress-value {
    background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-moz-progress-bar {
    background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-ms-fill {
    background-color: #e53935; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-1 .progress-bar {
      background-color: #e53935; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e53935 !important; }

.border-red.border-darken-1 {
  border: 1px solid #e53935 !important; }

.border-top-red.border-top-darken-1 {
  border-top: 1px solid #e53935 !important; }

.border-bottom-red.border-bottom-darken-1 {
  border-bottom: 1px solid #e53935 !important; }

[dir="ltr"] .border-left-red.border-left-darken-1 {
  border-left: 1px solid #e53935 !important; }

[dir="ltr"] .border-right-red.border-right-darken-1 {
  border-right: 1px solid #e53935 !important; }

[dir="rtl"] .border-left-red.border-left-darken-1 {
  border-right: 1px solid #e53935 !important; }

[dir="rtl"] .border-right-red.border-right-darken-1 {
  border-left: 1px solid #e53935 !important; }

.overlay-red.overlay-darken-1 {
  background: #e53935;
  /* The Fallback */
  background: rgba(229, 57, 53, 0.8); }

.red.darken-2 {
  color: #d32f2f !important; }

.bg-red.bg-darken-2 {
  background-color: #d32f2f !important; }

.btn-red.btn-darken-2 {
  border-color: #d32f2f !important;
  background-color: #d32f2f !important; }
  .btn-red.btn-darken-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-2:focus, .btn-red.btn-darken-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-2 {
  border-color: #d32f2f !important;
  color: #d32f2f !important; }
  .btn-outline-red.btn-outline-darken-2:hover {
    background-color: #d32f2f !important; }

.progress-red.progress-darken-2 {
  background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-webkit-progress-value {
    background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-moz-progress-bar {
    background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-ms-fill {
    background-color: #d32f2f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-2 .progress-bar {
      background-color: #d32f2f; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d32f2f !important; }

.border-red.border-darken-2 {
  border: 1px solid #d32f2f !important; }

.border-top-red.border-top-darken-2 {
  border-top: 1px solid #d32f2f !important; }

.border-bottom-red.border-bottom-darken-2 {
  border-bottom: 1px solid #d32f2f !important; }

[dir="ltr"] .border-left-red.border-left-darken-2 {
  border-left: 1px solid #d32f2f !important; }

[dir="ltr"] .border-right-red.border-right-darken-2 {
  border-right: 1px solid #d32f2f !important; }

[dir="rtl"] .border-left-red.border-left-darken-2 {
  border-right: 1px solid #d32f2f !important; }

[dir="rtl"] .border-right-red.border-right-darken-2 {
  border-left: 1px solid #d32f2f !important; }

.overlay-red.overlay-darken-2 {
  background: #d32f2f;
  /* The Fallback */
  background: rgba(211, 47, 47, 0.8); }

.red.darken-3 {
  color: #c62828 !important; }

.bg-red.bg-darken-3 {
  background-color: #c62828 !important; }

.btn-red.btn-darken-3 {
  border-color: #d32f2f !important;
  background-color: #c62828 !important; }
  .btn-red.btn-darken-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-3:focus, .btn-red.btn-darken-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-3 {
  border-color: #c62828 !important;
  color: #c62828 !important; }
  .btn-outline-red.btn-outline-darken-3:hover {
    background-color: #c62828 !important; }

.progress-red.progress-darken-3 {
  background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-webkit-progress-value {
    background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-moz-progress-bar {
    background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-ms-fill {
    background-color: #c62828; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-3 .progress-bar {
      background-color: #c62828; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c62828 !important; }

.border-red.border-darken-3 {
  border: 1px solid #c62828 !important; }

.border-top-red.border-top-darken-3 {
  border-top: 1px solid #c62828 !important; }

.border-bottom-red.border-bottom-darken-3 {
  border-bottom: 1px solid #c62828 !important; }

[dir="ltr"] .border-left-red.border-left-darken-3 {
  border-left: 1px solid #c62828 !important; }

[dir="ltr"] .border-right-red.border-right-darken-3 {
  border-right: 1px solid #c62828 !important; }

[dir="rtl"] .border-left-red.border-left-darken-3 {
  border-right: 1px solid #c62828 !important; }

[dir="rtl"] .border-right-red.border-right-darken-3 {
  border-left: 1px solid #c62828 !important; }

.overlay-red.overlay-darken-3 {
  background: #c62828;
  /* The Fallback */
  background: rgba(198, 40, 40, 0.8); }

.red.darken-4 {
  color: #b71c1c !important; }

.bg-red.bg-darken-4 {
  background-color: #b71c1c !important; }

.btn-red.btn-darken-4 {
  border-color: #d32f2f !important;
  background-color: #b71c1c !important; }
  .btn-red.btn-darken-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-4:focus, .btn-red.btn-darken-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-4 {
  border-color: #b71c1c !important;
  color: #b71c1c !important; }
  .btn-outline-red.btn-outline-darken-4:hover {
    background-color: #b71c1c !important; }

.progress-red.progress-darken-4 {
  background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-webkit-progress-value {
    background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-moz-progress-bar {
    background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-ms-fill {
    background-color: #b71c1c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-4 .progress-bar {
      background-color: #b71c1c; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b71c1c !important; }

.border-red.border-darken-4 {
  border: 1px solid #b71c1c !important; }

.border-top-red.border-top-darken-4 {
  border-top: 1px solid #b71c1c !important; }

.border-bottom-red.border-bottom-darken-4 {
  border-bottom: 1px solid #b71c1c !important; }

[dir="ltr"] .border-left-red.border-left-darken-4 {
  border-left: 1px solid #b71c1c !important; }

[dir="ltr"] .border-right-red.border-right-darken-4 {
  border-right: 1px solid #b71c1c !important; }

[dir="rtl"] .border-left-red.border-left-darken-4 {
  border-right: 1px solid #b71c1c !important; }

[dir="rtl"] .border-right-red.border-right-darken-4 {
  border-left: 1px solid #b71c1c !important; }

.overlay-red.overlay-darken-4 {
  background: #b71c1c;
  /* The Fallback */
  background: rgba(183, 28, 28, 0.8); }

.red.accent-1 {
  color: #ff8a80 !important; }

.bg-red.bg-accent-1 {
  background-color: #ff8a80 !important; }

.btn-red.btn-accent-1 {
  border-color: #d32f2f !important;
  background-color: #ff8a80 !important; }
  .btn-red.btn-accent-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-1:focus, .btn-red.btn-accent-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-1 {
  border-color: #ff8a80 !important;
  color: #ff8a80 !important; }
  .btn-outline-red.btn-outline-accent-1:hover {
    background-color: #ff8a80 !important; }

.progress-red.progress-accent-1 {
  background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-ms-fill {
    background-color: #ff8a80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-1 .progress-bar {
      background-color: #ff8a80; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a80 !important; }

.border-red.border-accent-1 {
  border: 1px solid #ff8a80 !important; }

.border-top-red.border-top-accent-1 {
  border-top: 1px solid #ff8a80 !important; }

.border-bottom-red.border-bottom-accent-1 {
  border-bottom: 1px solid #ff8a80 !important; }

[dir="ltr"] .border-left-red.border-left-accent-1 {
  border-left: 1px solid #ff8a80 !important; }

[dir="ltr"] .border-right-red.border-right-accent-1 {
  border-right: 1px solid #ff8a80 !important; }

[dir="rtl"] .border-left-red.border-left-accent-1 {
  border-right: 1px solid #ff8a80 !important; }

[dir="rtl"] .border-right-red.border-right-accent-1 {
  border-left: 1px solid #ff8a80 !important; }

.overlay-red.overlay-accent-1 {
  background: #ff8a80;
  /* The Fallback */
  background: rgba(255, 138, 128, 0.8); }

.red.accent-2 {
  color: #ff5252 !important; }

.bg-red.bg-accent-2 {
  background-color: #ff5252 !important; }

.btn-red.btn-accent-2 {
  border-color: #d32f2f !important;
  background-color: #ff5252 !important; }
  .btn-red.btn-accent-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-2:focus, .btn-red.btn-accent-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-2 {
  border-color: #ff5252 !important;
  color: #ff5252 !important; }
  .btn-outline-red.btn-outline-accent-2:hover {
    background-color: #ff5252 !important; }

.progress-red.progress-accent-2 {
  background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-ms-fill {
    background-color: #ff5252; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-2 .progress-bar {
      background-color: #ff5252; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5252 !important; }

.border-red.border-accent-2 {
  border: 1px solid #ff5252 !important; }

.border-top-red.border-top-accent-2 {
  border-top: 1px solid #ff5252 !important; }

.border-bottom-red.border-bottom-accent-2 {
  border-bottom: 1px solid #ff5252 !important; }

[dir="ltr"] .border-left-red.border-left-accent-2 {
  border-left: 1px solid #ff5252 !important; }

[dir="ltr"] .border-right-red.border-right-accent-2 {
  border-right: 1px solid #ff5252 !important; }

[dir="rtl"] .border-left-red.border-left-accent-2 {
  border-right: 1px solid #ff5252 !important; }

[dir="rtl"] .border-right-red.border-right-accent-2 {
  border-left: 1px solid #ff5252 !important; }

.overlay-red.overlay-accent-2 {
  background: #ff5252;
  /* The Fallback */
  background: rgba(255, 82, 82, 0.8); }

.red.accent-3 {
  color: #ff1744 !important; }

.bg-red.bg-accent-3 {
  background-color: #ff1744 !important; }

.btn-red.btn-accent-3 {
  border-color: #d32f2f !important;
  background-color: #ff1744 !important; }
  .btn-red.btn-accent-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-3:focus, .btn-red.btn-accent-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-3 {
  border-color: #ff1744 !important;
  color: #ff1744 !important; }
  .btn-outline-red.btn-outline-accent-3:hover {
    background-color: #ff1744 !important; }

.progress-red.progress-accent-3 {
  background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-ms-fill {
    background-color: #ff1744; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-3 .progress-bar {
      background-color: #ff1744; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff1744 !important; }

.border-red.border-accent-3 {
  border: 1px solid #ff1744 !important; }

.border-top-red.border-top-accent-3 {
  border-top: 1px solid #ff1744 !important; }

.border-bottom-red.border-bottom-accent-3 {
  border-bottom: 1px solid #ff1744 !important; }

[dir="ltr"] .border-left-red.border-left-accent-3 {
  border-left: 1px solid #ff1744 !important; }

[dir="ltr"] .border-right-red.border-right-accent-3 {
  border-right: 1px solid #ff1744 !important; }

[dir="rtl"] .border-left-red.border-left-accent-3 {
  border-right: 1px solid #ff1744 !important; }

[dir="rtl"] .border-right-red.border-right-accent-3 {
  border-left: 1px solid #ff1744 !important; }

.overlay-red.overlay-accent-3 {
  background: #ff1744;
  /* The Fallback */
  background: rgba(255, 23, 68, 0.8); }

.red.accent-4 {
  color: #d50000 !important; }

.bg-red.bg-accent-4 {
  background-color: #d50000 !important; }

.btn-red.btn-accent-4 {
  border-color: #d32f2f !important;
  background-color: #d50000 !important; }
  .btn-red.btn-accent-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-4:focus, .btn-red.btn-accent-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-4 {
  border-color: #d50000 !important;
  color: #d50000 !important; }
  .btn-outline-red.btn-outline-accent-4:hover {
    background-color: #d50000 !important; }

.progress-red.progress-accent-4 {
  background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-webkit-progress-value {
    background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-moz-progress-bar {
    background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-ms-fill {
    background-color: #d50000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-4 .progress-bar {
      background-color: #d50000; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d50000 !important; }

.border-red.border-accent-4 {
  border: 1px solid #d50000 !important; }

.border-top-red.border-top-accent-4 {
  border-top: 1px solid #d50000 !important; }

.border-bottom-red.border-bottom-accent-4 {
  border-bottom: 1px solid #d50000 !important; }

[dir="ltr"] .border-left-red.border-left-accent-4 {
  border-left: 1px solid #d50000 !important; }

[dir="ltr"] .border-right-red.border-right-accent-4 {
  border-right: 1px solid #d50000 !important; }

[dir="rtl"] .border-left-red.border-left-accent-4 {
  border-right: 1px solid #d50000 !important; }

[dir="rtl"] .border-right-red.border-right-accent-4 {
  border-left: 1px solid #d50000 !important; }

.overlay-red.overlay-accent-4 {
  background: #d50000;
  /* The Fallback */
  background: rgba(213, 0, 0, 0.8); }

.pink.lighten-5 {
  color: #fce4ec !important; }

.bg-pink.bg-lighten-5 {
  background-color: #fce4ec !important; }

.btn-pink.btn-lighten-5 {
  border-color: #c2185b !important;
  background-color: #fce4ec !important; }
  .btn-pink.btn-lighten-5:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-5:focus, .btn-pink.btn-lighten-5:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-5 {
  border-color: #fce4ec !important;
  color: #fce4ec !important; }
  .btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #fce4ec !important; }

.progress-pink.progress-lighten-5 {
  background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-ms-fill {
    background-color: #fce4ec; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-5 .progress-bar {
      background-color: #fce4ec; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fce4ec !important; }

.border-pink.border-lighten-5 {
  border: 1px solid #fce4ec !important; }

.border-top-pink.border-top-lighten-5 {
  border-top: 1px solid #fce4ec !important; }

.border-bottom-pink.border-bottom-lighten-5 {
  border-bottom: 1px solid #fce4ec !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-5 {
  border-left: 1px solid #fce4ec !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-5 {
  border-right: 1px solid #fce4ec !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-5 {
  border-right: 1px solid #fce4ec !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-5 {
  border-left: 1px solid #fce4ec !important; }

.overlay-pink.overlay-lighten-5 {
  background: #fce4ec;
  /* The Fallback */
  background: rgba(252, 228, 236, 0.8); }

.pink.lighten-4 {
  color: #f8bbd0 !important; }

.bg-pink.bg-lighten-4 {
  background-color: #f8bbd0 !important; }

.btn-pink.btn-lighten-4 {
  border-color: #c2185b !important;
  background-color: #f8bbd0 !important; }
  .btn-pink.btn-lighten-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-4:focus, .btn-pink.btn-lighten-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-4 {
  border-color: #f8bbd0 !important;
  color: #f8bbd0 !important; }
  .btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #f8bbd0 !important; }

.progress-pink.progress-lighten-4 {
  background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-ms-fill {
    background-color: #f8bbd0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-4 .progress-bar {
      background-color: #f8bbd0; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f8bbd0 !important; }

.border-pink.border-lighten-4 {
  border: 1px solid #f8bbd0 !important; }

.border-top-pink.border-top-lighten-4 {
  border-top: 1px solid #f8bbd0 !important; }

.border-bottom-pink.border-bottom-lighten-4 {
  border-bottom: 1px solid #f8bbd0 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-4 {
  border-left: 1px solid #f8bbd0 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-4 {
  border-right: 1px solid #f8bbd0 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-4 {
  border-right: 1px solid #f8bbd0 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-4 {
  border-left: 1px solid #f8bbd0 !important; }

.overlay-pink.overlay-lighten-4 {
  background: #f8bbd0;
  /* The Fallback */
  background: rgba(248, 187, 208, 0.8); }

.pink.lighten-3 {
  color: #f48fb1 !important; }

.bg-pink.bg-lighten-3 {
  background-color: #f48fb1 !important; }

.btn-pink.btn-lighten-3 {
  border-color: #c2185b !important;
  background-color: #f48fb1 !important; }
  .btn-pink.btn-lighten-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-3:focus, .btn-pink.btn-lighten-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-3 {
  border-color: #f48fb1 !important;
  color: #f48fb1 !important; }
  .btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #f48fb1 !important; }

.progress-pink.progress-lighten-3 {
  background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-ms-fill {
    background-color: #f48fb1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-3 .progress-bar {
      background-color: #f48fb1; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f48fb1 !important; }

.border-pink.border-lighten-3 {
  border: 1px solid #f48fb1 !important; }

.border-top-pink.border-top-lighten-3 {
  border-top: 1px solid #f48fb1 !important; }

.border-bottom-pink.border-bottom-lighten-3 {
  border-bottom: 1px solid #f48fb1 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-3 {
  border-left: 1px solid #f48fb1 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-3 {
  border-right: 1px solid #f48fb1 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-3 {
  border-right: 1px solid #f48fb1 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-3 {
  border-left: 1px solid #f48fb1 !important; }

.overlay-pink.overlay-lighten-3 {
  background: #f48fb1;
  /* The Fallback */
  background: rgba(244, 143, 177, 0.8); }

.pink.lighten-2 {
  color: #f06292 !important; }

.bg-pink.bg-lighten-2 {
  background-color: #f06292 !important; }

.btn-pink.btn-lighten-2 {
  border-color: #c2185b !important;
  background-color: #f06292 !important; }
  .btn-pink.btn-lighten-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-2:focus, .btn-pink.btn-lighten-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-2 {
  border-color: #f06292 !important;
  color: #f06292 !important; }
  .btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #f06292 !important; }

.progress-pink.progress-lighten-2 {
  background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-ms-fill {
    background-color: #f06292; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-2 .progress-bar {
      background-color: #f06292; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f06292 !important; }

.border-pink.border-lighten-2 {
  border: 1px solid #f06292 !important; }

.border-top-pink.border-top-lighten-2 {
  border-top: 1px solid #f06292 !important; }

.border-bottom-pink.border-bottom-lighten-2 {
  border-bottom: 1px solid #f06292 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-2 {
  border-left: 1px solid #f06292 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-2 {
  border-right: 1px solid #f06292 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-2 {
  border-right: 1px solid #f06292 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-2 {
  border-left: 1px solid #f06292 !important; }

.overlay-pink.overlay-lighten-2 {
  background: #f06292;
  /* The Fallback */
  background: rgba(240, 98, 146, 0.8); }

.pink.lighten-1 {
  color: #ec407a !important; }

.bg-pink.bg-lighten-1 {
  background-color: #ec407a !important; }

.btn-pink.btn-lighten-1 {
  border-color: #c2185b !important;
  background-color: #ec407a !important; }
  .btn-pink.btn-lighten-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-1:focus, .btn-pink.btn-lighten-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-1 {
  border-color: #ec407a !important;
  color: #ec407a !important; }
  .btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #ec407a !important; }

.progress-pink.progress-lighten-1 {
  background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-ms-fill {
    background-color: #ec407a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-1 .progress-bar {
      background-color: #ec407a; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ec407a !important; }

.border-pink.border-lighten-1 {
  border: 1px solid #ec407a !important; }

.border-top-pink.border-top-lighten-1 {
  border-top: 1px solid #ec407a !important; }

.border-bottom-pink.border-bottom-lighten-1 {
  border-bottom: 1px solid #ec407a !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-1 {
  border-left: 1px solid #ec407a !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-1 {
  border-right: 1px solid #ec407a !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-1 {
  border-right: 1px solid #ec407a !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-1 {
  border-left: 1px solid #ec407a !important; }

.overlay-pink.overlay-lighten-1 {
  background: #ec407a;
  /* The Fallback */
  background: rgba(236, 64, 122, 0.8); }

.pink {
  color: #e91e63 !important; }

.bg-pink {
  background-color: #e91e63 !important; }
  .bg-pink .card-header, .bg-pink .card-footer {
    background-color: transparent; }

.toast-pink {
  background-color: #e91e63; }

.alert-pink {
  border-color: #e91e63 !important;
  background-color: #ec3f7a !important;
  color: #4d081f !important; }
  .alert-pink .alert-link {
    color: #280410 !important; }

.border-pink {
  border-color: #e91e63; }

.border-top-pink {
  border-top-color: #e91e63; }

.border-bottom-pink {
  border-bottom-color: #e91e63; }

[dir="ltr"] .border-left-pink {
  border-left-color: #e91e63; }

[dir="ltr"] .border-right-pink {
  border-right-color: #e91e63; }

[dir="rtl"] .border-left-pink {
  border-right-color: #e91e63; }

[dir="rtl"] .border-right-pink {
  border-left-color: #e91e63; }

.badge-pink {
  background-color: #e91e63; }

.panel-pink {
  border-color: #e91e63; }
  .panel-pink .panel-heading {
    color: #FFF;
    border-color: #e91e63;
    background-color: #eb3573; }

.bg-pink.tag-glow, .border-pink.tag-glow {
  box-shadow: 0px 0px 10px #e91e63; }

.overlay-pink {
  background: #e91e63;
  /* The Fallback */
  background: rgba(233, 30, 99, 0.8); }

.card.card-outline-pink {
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  background-color: transparent; }
  .card.card-outline-pink .card-header, .card.card-outline-pink .card-footer {
    background-color: transparent; }

.btn-pink.btn-flat {
  background-color: transparent !important;
  color: #e91e63;
  border: none; }

.btn-pink.btn-raised, .btn-pink.btn-fab {
  background-color: #e91e63 !important;
  color: #fff !important;
  border-color: #e91e63; }
  .btn-pink.btn-raised.active, .btn-pink.btn-fab.active {
    background-color: #cf1454 !important;
    border-color: #cf1454 !important; }

.btn-group-raised .btn-pink {
  background-color: #e91e63 !important;
  color: #fff !important; }

.btn-outline-pink {
  border: 1px solid;
  border-color: #e91e63;
  background-color: transparent;
  color: #e91e63;
  box-shadow: none !important; }
  .btn-outline-pink:focus {
    background-color: transparent !important;
    color: #e91e63 !important;
    box-shadow: transparent !important; }
  .btn-outline-pink.active {
    background-color: #e91e63 !important;
    color: #FFF !important; }
  .btn-outline-pink:hover {
    background-color: #e2165b !important;
    color: #FFF !important; }
    .btn-outline-pink:hover svg {
      color: #FFF !important; }

.btn-outline-pink.btn-raised, .btn-outline-pink.btn-fab {
  border: 1px solid;
  border-color: #e91e63;
  background-color: transparent;
  color: #e91e63;
  box-shadow: none !important; }
  .btn-outline-pink.btn-raised:focus, .btn-outline-pink.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-pink.btn-raised.active, .btn-outline-pink.btn-fab.active {
    background-color: #e91e63 !important;
    color: #FFF !important; }
  .btn-outline-pink.btn-raised:hover, .btn-outline-pink.btn-fab:hover {
    background-color: #e2165b !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-pink {
  background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-webkit-progress-value {
    background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-moz-progress-bar {
    background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-ms-fill {
    background-color: #e91e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-pink .progress-bar {
      background-color: #e91e63; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e91e63 !important; }

.pink.darken-1 {
  color: #d81b60 !important; }

.bg-pink.bg-darken-1 {
  background-color: #d81b60 !important; }

.btn-pink.btn-darken-1 {
  border-color: #c2185b !important;
  background-color: #d81b60 !important; }
  .btn-pink.btn-darken-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-1:focus, .btn-pink.btn-darken-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-1 {
  border-color: #d81b60 !important;
  color: #d81b60 !important; }
  .btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #d81b60 !important; }

.progress-pink.progress-darken-1 {
  background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-webkit-progress-value {
    background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-moz-progress-bar {
    background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-ms-fill {
    background-color: #d81b60; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-1 .progress-bar {
      background-color: #d81b60; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d81b60 !important; }

.border-pink.border-darken-1 {
  border: 1px solid #d81b60 !important; }

.border-top-pink.border-top-darken-1 {
  border-top: 1px solid #d81b60 !important; }

.border-bottom-pink.border-bottom-darken-1 {
  border-bottom: 1px solid #d81b60 !important; }

[dir="ltr"] .border-left-pink.border-left-darken-1 {
  border-left: 1px solid #d81b60 !important; }

[dir="ltr"] .border-right-pink.border-right-darken-1 {
  border-right: 1px solid #d81b60 !important; }

[dir="rtl"] .border-left-pink.border-left-darken-1 {
  border-right: 1px solid #d81b60 !important; }

[dir="rtl"] .border-right-pink.border-right-darken-1 {
  border-left: 1px solid #d81b60 !important; }

.overlay-pink.overlay-darken-1 {
  background: #d81b60;
  /* The Fallback */
  background: rgba(216, 27, 96, 0.8); }

.pink.darken-2 {
  color: #c2185b !important; }

.bg-pink.bg-darken-2 {
  background-color: #c2185b !important; }

.btn-pink.btn-darken-2 {
  border-color: #c2185b !important;
  background-color: #c2185b !important; }
  .btn-pink.btn-darken-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-2:focus, .btn-pink.btn-darken-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-2 {
  border-color: #c2185b !important;
  color: #c2185b !important; }
  .btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #c2185b !important; }

.progress-pink.progress-darken-2 {
  background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-webkit-progress-value {
    background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-moz-progress-bar {
    background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-ms-fill {
    background-color: #c2185b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-2 .progress-bar {
      background-color: #c2185b; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c2185b !important; }

.border-pink.border-darken-2 {
  border: 1px solid #c2185b !important; }

.border-top-pink.border-top-darken-2 {
  border-top: 1px solid #c2185b !important; }

.border-bottom-pink.border-bottom-darken-2 {
  border-bottom: 1px solid #c2185b !important; }

[dir="ltr"] .border-left-pink.border-left-darken-2 {
  border-left: 1px solid #c2185b !important; }

[dir="ltr"] .border-right-pink.border-right-darken-2 {
  border-right: 1px solid #c2185b !important; }

[dir="rtl"] .border-left-pink.border-left-darken-2 {
  border-right: 1px solid #c2185b !important; }

[dir="rtl"] .border-right-pink.border-right-darken-2 {
  border-left: 1px solid #c2185b !important; }

.overlay-pink.overlay-darken-2 {
  background: #c2185b;
  /* The Fallback */
  background: rgba(194, 24, 91, 0.8); }

.pink.darken-3 {
  color: #ad1457 !important; }

.bg-pink.bg-darken-3 {
  background-color: #ad1457 !important; }

.btn-pink.btn-darken-3 {
  border-color: #c2185b !important;
  background-color: #ad1457 !important; }
  .btn-pink.btn-darken-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-3:focus, .btn-pink.btn-darken-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-3 {
  border-color: #ad1457 !important;
  color: #ad1457 !important; }
  .btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #ad1457 !important; }

.progress-pink.progress-darken-3 {
  background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-ms-fill {
    background-color: #ad1457; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-3 .progress-bar {
      background-color: #ad1457; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ad1457 !important; }

.border-pink.border-darken-3 {
  border: 1px solid #ad1457 !important; }

.border-top-pink.border-top-darken-3 {
  border-top: 1px solid #ad1457 !important; }

.border-bottom-pink.border-bottom-darken-3 {
  border-bottom: 1px solid #ad1457 !important; }

[dir="ltr"] .border-left-pink.border-left-darken-3 {
  border-left: 1px solid #ad1457 !important; }

[dir="ltr"] .border-right-pink.border-right-darken-3 {
  border-right: 1px solid #ad1457 !important; }

[dir="rtl"] .border-left-pink.border-left-darken-3 {
  border-right: 1px solid #ad1457 !important; }

[dir="rtl"] .border-right-pink.border-right-darken-3 {
  border-left: 1px solid #ad1457 !important; }

.overlay-pink.overlay-darken-3 {
  background: #ad1457;
  /* The Fallback */
  background: rgba(173, 20, 87, 0.8); }

.pink.darken-4 {
  color: #880e4f !important; }

.bg-pink.bg-darken-4 {
  background-color: #880e4f !important; }

.btn-pink.btn-darken-4 {
  border-color: #c2185b !important;
  background-color: #880e4f !important; }
  .btn-pink.btn-darken-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-4:focus, .btn-pink.btn-darken-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-4 {
  border-color: #880e4f !important;
  color: #880e4f !important; }
  .btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880e4f !important; }

.progress-pink.progress-darken-4 {
  background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-webkit-progress-value {
    background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-moz-progress-bar {
    background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-ms-fill {
    background-color: #880e4f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-4 .progress-bar {
      background-color: #880e4f; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880e4f !important; }

.border-pink.border-darken-4 {
  border: 1px solid #880e4f !important; }

.border-top-pink.border-top-darken-4 {
  border-top: 1px solid #880e4f !important; }

.border-bottom-pink.border-bottom-darken-4 {
  border-bottom: 1px solid #880e4f !important; }

[dir="ltr"] .border-left-pink.border-left-darken-4 {
  border-left: 1px solid #880e4f !important; }

[dir="ltr"] .border-right-pink.border-right-darken-4 {
  border-right: 1px solid #880e4f !important; }

[dir="rtl"] .border-left-pink.border-left-darken-4 {
  border-right: 1px solid #880e4f !important; }

[dir="rtl"] .border-right-pink.border-right-darken-4 {
  border-left: 1px solid #880e4f !important; }

.overlay-pink.overlay-darken-4 {
  background: #880e4f;
  /* The Fallback */
  background: rgba(136, 14, 79, 0.8); }

.pink.accent-1 {
  color: #ff80ab !important; }

.bg-pink.bg-accent-1 {
  background-color: #ff80ab !important; }

.btn-pink.btn-accent-1 {
  border-color: #c2185b !important;
  background-color: #ff80ab !important; }
  .btn-pink.btn-accent-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-1:focus, .btn-pink.btn-accent-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-1 {
  border-color: #ff80ab !important;
  color: #ff80ab !important; }
  .btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #ff80ab !important; }

.progress-pink.progress-accent-1 {
  background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-ms-fill {
    background-color: #ff80ab; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-1 .progress-bar {
      background-color: #ff80ab; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff80ab !important; }

.border-pink.border-accent-1 {
  border: 1px solid #ff80ab !important; }

.border-top-pink.border-top-accent-1 {
  border-top: 1px solid #ff80ab !important; }

.border-bottom-pink.border-bottom-accent-1 {
  border-bottom: 1px solid #ff80ab !important; }

[dir="ltr"] .border-left-pink.border-left-accent-1 {
  border-left: 1px solid #ff80ab !important; }

[dir="ltr"] .border-right-pink.border-right-accent-1 {
  border-right: 1px solid #ff80ab !important; }

[dir="rtl"] .border-left-pink.border-left-accent-1 {
  border-right: 1px solid #ff80ab !important; }

[dir="rtl"] .border-right-pink.border-right-accent-1 {
  border-left: 1px solid #ff80ab !important; }

.overlay-pink.overlay-accent-1 {
  background: #ff80ab;
  /* The Fallback */
  background: rgba(255, 128, 171, 0.8); }

.pink.accent-2 {
  color: #ff4081 !important; }

.bg-pink.bg-accent-2 {
  background-color: #ff4081 !important; }

.btn-pink.btn-accent-2 {
  border-color: #c2185b !important;
  background-color: #ff4081 !important; }
  .btn-pink.btn-accent-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-2:focus, .btn-pink.btn-accent-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-2 {
  border-color: #ff4081 !important;
  color: #ff4081 !important; }
  .btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #ff4081 !important; }

.progress-pink.progress-accent-2 {
  background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-ms-fill {
    background-color: #ff4081; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-2 .progress-bar {
      background-color: #ff4081; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff4081 !important; }

.border-pink.border-accent-2 {
  border: 1px solid #ff4081 !important; }

.border-top-pink.border-top-accent-2 {
  border-top: 1px solid #ff4081 !important; }

.border-bottom-pink.border-bottom-accent-2 {
  border-bottom: 1px solid #ff4081 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-2 {
  border-left: 1px solid #ff4081 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-2 {
  border-right: 1px solid #ff4081 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-2 {
  border-right: 1px solid #ff4081 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-2 {
  border-left: 1px solid #ff4081 !important; }

.overlay-pink.overlay-accent-2 {
  background: #ff4081;
  /* The Fallback */
  background: rgba(255, 64, 129, 0.8); }

.pink.accent-3 {
  color: #f50057 !important; }

.bg-pink.bg-accent-3 {
  background-color: #f50057 !important; }

.btn-pink.btn-accent-3 {
  border-color: #c2185b !important;
  background-color: #f50057 !important; }
  .btn-pink.btn-accent-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-3:focus, .btn-pink.btn-accent-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-3 {
  border-color: #f50057 !important;
  color: #f50057 !important; }
  .btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #f50057 !important; }

.progress-pink.progress-accent-3 {
  background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-webkit-progress-value {
    background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-moz-progress-bar {
    background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-ms-fill {
    background-color: #f50057; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-3 .progress-bar {
      background-color: #f50057; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f50057 !important; }

.border-pink.border-accent-3 {
  border: 1px solid #f50057 !important; }

.border-top-pink.border-top-accent-3 {
  border-top: 1px solid #f50057 !important; }

.border-bottom-pink.border-bottom-accent-3 {
  border-bottom: 1px solid #f50057 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-3 {
  border-left: 1px solid #f50057 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-3 {
  border-right: 1px solid #f50057 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-3 {
  border-right: 1px solid #f50057 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-3 {
  border-left: 1px solid #f50057 !important; }

.overlay-pink.overlay-accent-3 {
  background: #f50057;
  /* The Fallback */
  background: rgba(245, 0, 87, 0.8); }

.pink.accent-4 {
  color: #c51162 !important; }

.bg-pink.bg-accent-4 {
  background-color: #c51162 !important; }

.btn-pink.btn-accent-4 {
  border-color: #c2185b !important;
  background-color: #c51162 !important; }
  .btn-pink.btn-accent-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-4:focus, .btn-pink.btn-accent-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-4 {
  border-color: #c51162 !important;
  color: #c51162 !important; }
  .btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #c51162 !important; }

.progress-pink.progress-accent-4 {
  background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-webkit-progress-value {
    background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-moz-progress-bar {
    background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-ms-fill {
    background-color: #c51162; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-4 .progress-bar {
      background-color: #c51162; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c51162 !important; }

.border-pink.border-accent-4 {
  border: 1px solid #c51162 !important; }

.border-top-pink.border-top-accent-4 {
  border-top: 1px solid #c51162 !important; }

.border-bottom-pink.border-bottom-accent-4 {
  border-bottom: 1px solid #c51162 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-4 {
  border-left: 1px solid #c51162 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-4 {
  border-right: 1px solid #c51162 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-4 {
  border-right: 1px solid #c51162 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-4 {
  border-left: 1px solid #c51162 !important; }

.overlay-pink.overlay-accent-4 {
  background: #c51162;
  /* The Fallback */
  background: rgba(197, 17, 98, 0.8); }

.purple.lighten-5 {
  color: #f3e5f5 !important; }

.bg-purple.bg-lighten-5 {
  background-color: #f3e5f5 !important; }

.btn-purple.btn-lighten-5 {
  border-color: #7b1fa2 !important;
  background-color: #f3e5f5 !important; }
  .btn-purple.btn-lighten-5:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-5:focus, .btn-purple.btn-lighten-5:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-5 {
  border-color: #f3e5f5 !important;
  color: #f3e5f5 !important; }
  .btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #f3e5f5 !important; }

.progress-purple.progress-lighten-5 {
  background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #f3e5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-5 .progress-bar {
      background-color: #f3e5f5; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f3e5f5 !important; }

.border-purple.border-lighten-5 {
  border: 1px solid #f3e5f5 !important; }

.border-top-purple.border-top-lighten-5 {
  border-top: 1px solid #f3e5f5 !important; }

.border-bottom-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #f3e5f5 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-5 {
  border-left: 1px solid #f3e5f5 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-5 {
  border-right: 1px solid #f3e5f5 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-5 {
  border-right: 1px solid #f3e5f5 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-5 {
  border-left: 1px solid #f3e5f5 !important; }

.overlay-purple.overlay-lighten-5 {
  background: #f3e5f5;
  /* The Fallback */
  background: rgba(243, 229, 245, 0.8); }

.purple.lighten-4 {
  color: #e1bee7 !important; }

.bg-purple.bg-lighten-4 {
  background-color: #e1bee7 !important; }

.btn-purple.btn-lighten-4 {
  border-color: #7b1fa2 !important;
  background-color: #e1bee7 !important; }
  .btn-purple.btn-lighten-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-4:focus, .btn-purple.btn-lighten-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-4 {
  border-color: #e1bee7 !important;
  color: #e1bee7 !important; }
  .btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #e1bee7 !important; }

.progress-purple.progress-lighten-4 {
  background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #e1bee7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-4 .progress-bar {
      background-color: #e1bee7; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1bee7 !important; }

.border-purple.border-lighten-4 {
  border: 1px solid #e1bee7 !important; }

.border-top-purple.border-top-lighten-4 {
  border-top: 1px solid #e1bee7 !important; }

.border-bottom-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #e1bee7 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-4 {
  border-left: 1px solid #e1bee7 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-4 {
  border-right: 1px solid #e1bee7 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-4 {
  border-right: 1px solid #e1bee7 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-4 {
  border-left: 1px solid #e1bee7 !important; }

.overlay-purple.overlay-lighten-4 {
  background: #e1bee7;
  /* The Fallback */
  background: rgba(225, 190, 231, 0.8); }

.purple.lighten-3 {
  color: #ce93d8 !important; }

.bg-purple.bg-lighten-3 {
  background-color: #ce93d8 !important; }

.btn-purple.btn-lighten-3 {
  border-color: #7b1fa2 !important;
  background-color: #ce93d8 !important; }
  .btn-purple.btn-lighten-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-3:focus, .btn-purple.btn-lighten-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-3 {
  border-color: #ce93d8 !important;
  color: #ce93d8 !important; }
  .btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #ce93d8 !important; }

.progress-purple.progress-lighten-3 {
  background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #ce93d8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-3 .progress-bar {
      background-color: #ce93d8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ce93d8 !important; }

.border-purple.border-lighten-3 {
  border: 1px solid #ce93d8 !important; }

.border-top-purple.border-top-lighten-3 {
  border-top: 1px solid #ce93d8 !important; }

.border-bottom-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #ce93d8 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-3 {
  border-left: 1px solid #ce93d8 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-3 {
  border-right: 1px solid #ce93d8 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-3 {
  border-right: 1px solid #ce93d8 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-3 {
  border-left: 1px solid #ce93d8 !important; }

.overlay-purple.overlay-lighten-3 {
  background: #ce93d8;
  /* The Fallback */
  background: rgba(206, 147, 216, 0.8); }

.purple.lighten-2 {
  color: #ba68c8 !important; }

.bg-purple.bg-lighten-2 {
  background-color: #ba68c8 !important; }

.btn-purple.btn-lighten-2 {
  border-color: #7b1fa2 !important;
  background-color: #ba68c8 !important; }
  .btn-purple.btn-lighten-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-2:focus, .btn-purple.btn-lighten-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-2 {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important; }
  .btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #ba68c8 !important; }

.progress-purple.progress-lighten-2 {
  background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #ba68c8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-2 .progress-bar {
      background-color: #ba68c8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ba68c8 !important; }

.border-purple.border-lighten-2 {
  border: 1px solid #ba68c8 !important; }

.border-top-purple.border-top-lighten-2 {
  border-top: 1px solid #ba68c8 !important; }

.border-bottom-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #ba68c8 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-2 {
  border-left: 1px solid #ba68c8 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-2 {
  border-right: 1px solid #ba68c8 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-2 {
  border-right: 1px solid #ba68c8 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-2 {
  border-left: 1px solid #ba68c8 !important; }

.overlay-purple.overlay-lighten-2 {
  background: #ba68c8;
  /* The Fallback */
  background: rgba(186, 104, 200, 0.8); }

.purple.lighten-1 {
  color: #ab47bc !important; }

.bg-purple.bg-lighten-1 {
  background-color: #ab47bc !important; }

.btn-purple.btn-lighten-1 {
  border-color: #7b1fa2 !important;
  background-color: #ab47bc !important; }
  .btn-purple.btn-lighten-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-1:focus, .btn-purple.btn-lighten-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-1 {
  border-color: #ab47bc !important;
  color: #ab47bc !important; }
  .btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #ab47bc !important; }

.progress-purple.progress-lighten-1 {
  background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #ab47bc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-1 .progress-bar {
      background-color: #ab47bc; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ab47bc !important; }

.border-purple.border-lighten-1 {
  border: 1px solid #ab47bc !important; }

.border-top-purple.border-top-lighten-1 {
  border-top: 1px solid #ab47bc !important; }

.border-bottom-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #ab47bc !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-1 {
  border-left: 1px solid #ab47bc !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-1 {
  border-right: 1px solid #ab47bc !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-1 {
  border-right: 1px solid #ab47bc !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-1 {
  border-left: 1px solid #ab47bc !important; }

.overlay-purple.overlay-lighten-1 {
  background: #ab47bc;
  /* The Fallback */
  background: rgba(171, 71, 188, 0.8); }

.purple {
  color: #9c27b0 !important; }

.bg-purple {
  background-color: #9c27b0 !important; }
  .bg-purple .card-header, .bg-purple .card-footer {
    background-color: transparent; }

.toast-purple {
  background-color: #9c27b0; }

.alert-purple {
  border-color: #9c27b0 !important;
  background-color: #b62dcd !important;
  color: #1a071e !important; }
  .alert-purple .alert-link {
    color: black !important; }

.border-purple {
  border-color: #9c27b0; }

.border-top-purple {
  border-top-color: #9c27b0; }

.border-bottom-purple {
  border-bottom-color: #9c27b0; }

[dir="ltr"] .border-left-purple {
  border-left-color: #9c27b0; }

[dir="ltr"] .border-right-purple {
  border-right-color: #9c27b0; }

[dir="rtl"] .border-left-purple {
  border-right-color: #9c27b0; }

[dir="rtl"] .border-right-purple {
  border-left-color: #9c27b0; }

.badge-purple {
  background-color: #9c27b0; }

.panel-purple {
  border-color: #9c27b0; }
  .panel-purple .panel-heading {
    color: #FFF;
    border-color: #9c27b0;
    background-color: #af2cc5; }

.bg-purple.tag-glow, .border-purple.tag-glow {
  box-shadow: 0px 0px 10px #9c27b0; }

.overlay-purple {
  background: #9c27b0;
  /* The Fallback */
  background: rgba(156, 39, 176, 0.8); }

.card.card-outline-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #9c27b0;
  background-color: transparent; }
  .card.card-outline-purple .card-header, .card.card-outline-purple .card-footer {
    background-color: transparent; }

.btn-purple.btn-flat {
  background-color: transparent !important;
  color: #9c27b0;
  border: none; }

.btn-purple.btn-raised, .btn-purple.btn-fab {
  background-color: #9c27b0 !important;
  color: #fff !important;
  border-color: #9c27b0; }
  .btn-purple.btn-raised.active, .btn-purple.btn-fab.active {
    background-color: #822193 !important;
    border-color: #822193 !important; }

.btn-group-raised .btn-purple {
  background-color: #9c27b0 !important;
  color: #fff !important; }

.btn-outline-purple {
  border: 1px solid;
  border-color: #9c27b0;
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none !important; }
  .btn-outline-purple:focus {
    background-color: transparent !important;
    color: #9c27b0 !important;
    box-shadow: transparent !important; }
  .btn-outline-purple.active {
    background-color: #9c27b0 !important;
    color: #FFF !important; }
  .btn-outline-purple:hover {
    background-color: #9124a3 !important;
    color: #FFF !important; }
    .btn-outline-purple:hover svg {
      color: #FFF !important; }

.btn-outline-purple.btn-raised, .btn-outline-purple.btn-fab {
  border: 1px solid;
  border-color: #9c27b0;
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none !important; }
  .btn-outline-purple.btn-raised:focus, .btn-outline-purple.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-purple.btn-raised.active, .btn-outline-purple.btn-fab.active {
    background-color: #9c27b0 !important;
    color: #FFF !important; }
  .btn-outline-purple.btn-raised:hover, .btn-outline-purple.btn-fab:hover {
    background-color: #9124a3 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-purple {
  background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-webkit-progress-value {
    background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-moz-progress-bar {
    background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-ms-fill {
    background-color: #9c27b0; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-purple .progress-bar {
      background-color: #9c27b0; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9c27b0 !important; }

.purple.darken-1 {
  color: #8e24aa !important; }

.bg-purple.bg-darken-1 {
  background-color: #8e24aa !important; }

.btn-purple.btn-darken-1 {
  border-color: #7b1fa2 !important;
  background-color: #8e24aa !important; }
  .btn-purple.btn-darken-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-1:focus, .btn-purple.btn-darken-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-1 {
  border-color: #8e24aa !important;
  color: #8e24aa !important; }
  .btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8e24aa !important; }

.progress-purple.progress-darken-1 {
  background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-ms-fill {
    background-color: #8e24aa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-1 .progress-bar {
      background-color: #8e24aa; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8e24aa !important; }

.border-purple.border-darken-1 {
  border: 1px solid #8e24aa !important; }

.border-top-purple.border-top-darken-1 {
  border-top: 1px solid #8e24aa !important; }

.border-bottom-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #8e24aa !important; }

[dir="ltr"] .border-left-purple.border-left-darken-1 {
  border-left: 1px solid #8e24aa !important; }

[dir="ltr"] .border-right-purple.border-right-darken-1 {
  border-right: 1px solid #8e24aa !important; }

[dir="rtl"] .border-left-purple.border-left-darken-1 {
  border-right: 1px solid #8e24aa !important; }

[dir="rtl"] .border-right-purple.border-right-darken-1 {
  border-left: 1px solid #8e24aa !important; }

.overlay-purple.overlay-darken-1 {
  background: #8e24aa;
  /* The Fallback */
  background: rgba(142, 36, 170, 0.8); }

.purple.darken-2 {
  color: #7b1fa2 !important; }

.bg-purple.bg-darken-2 {
  background-color: #7b1fa2 !important; }

.btn-purple.btn-darken-2 {
  border-color: #7b1fa2 !important;
  background-color: #7b1fa2 !important; }
  .btn-purple.btn-darken-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-2:focus, .btn-purple.btn-darken-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-2 {
  border-color: #7b1fa2 !important;
  color: #7b1fa2 !important; }
  .btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7b1fa2 !important; }

.progress-purple.progress-darken-2 {
  background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-ms-fill {
    background-color: #7b1fa2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-2 .progress-bar {
      background-color: #7b1fa2; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7b1fa2 !important; }

.border-purple.border-darken-2 {
  border: 1px solid #7b1fa2 !important; }

.border-top-purple.border-top-darken-2 {
  border-top: 1px solid #7b1fa2 !important; }

.border-bottom-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #7b1fa2 !important; }

[dir="ltr"] .border-left-purple.border-left-darken-2 {
  border-left: 1px solid #7b1fa2 !important; }

[dir="ltr"] .border-right-purple.border-right-darken-2 {
  border-right: 1px solid #7b1fa2 !important; }

[dir="rtl"] .border-left-purple.border-left-darken-2 {
  border-right: 1px solid #7b1fa2 !important; }

[dir="rtl"] .border-right-purple.border-right-darken-2 {
  border-left: 1px solid #7b1fa2 !important; }

.overlay-purple.overlay-darken-2 {
  background: #7b1fa2;
  /* The Fallback */
  background: rgba(123, 31, 162, 0.8); }

.purple.darken-3 {
  color: #6a1b9a !important; }

.bg-purple.bg-darken-3 {
  background-color: #6a1b9a !important; }

.btn-purple.btn-darken-3 {
  border-color: #7b1fa2 !important;
  background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-3:focus, .btn-purple.btn-darken-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-3 {
  border-color: #6a1b9a !important;
  color: #6a1b9a !important; }
  .btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6a1b9a !important; }

.progress-purple.progress-darken-3 {
  background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-ms-fill {
    background-color: #6a1b9a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-3 .progress-bar {
      background-color: #6a1b9a; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6a1b9a !important; }

.border-purple.border-darken-3 {
  border: 1px solid #6a1b9a !important; }

.border-top-purple.border-top-darken-3 {
  border-top: 1px solid #6a1b9a !important; }

.border-bottom-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #6a1b9a !important; }

[dir="ltr"] .border-left-purple.border-left-darken-3 {
  border-left: 1px solid #6a1b9a !important; }

[dir="ltr"] .border-right-purple.border-right-darken-3 {
  border-right: 1px solid #6a1b9a !important; }

[dir="rtl"] .border-left-purple.border-left-darken-3 {
  border-right: 1px solid #6a1b9a !important; }

[dir="rtl"] .border-right-purple.border-right-darken-3 {
  border-left: 1px solid #6a1b9a !important; }

.overlay-purple.overlay-darken-3 {
  background: #6a1b9a;
  /* The Fallback */
  background: rgba(106, 27, 154, 0.8); }

.purple.darken-4 {
  color: #4a148c !important; }

.bg-purple.bg-darken-4 {
  background-color: #4a148c !important; }

.btn-purple.btn-darken-4 {
  border-color: #7b1fa2 !important;
  background-color: #4a148c !important; }
  .btn-purple.btn-darken-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-4:focus, .btn-purple.btn-darken-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-4 {
  border-color: #4a148c !important;
  color: #4a148c !important; }
  .btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4a148c !important; }

.progress-purple.progress-darken-4 {
  background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-ms-fill {
    background-color: #4a148c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-4 .progress-bar {
      background-color: #4a148c; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4a148c !important; }

.border-purple.border-darken-4 {
  border: 1px solid #4a148c !important; }

.border-top-purple.border-top-darken-4 {
  border-top: 1px solid #4a148c !important; }

.border-bottom-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #4a148c !important; }

[dir="ltr"] .border-left-purple.border-left-darken-4 {
  border-left: 1px solid #4a148c !important; }

[dir="ltr"] .border-right-purple.border-right-darken-4 {
  border-right: 1px solid #4a148c !important; }

[dir="rtl"] .border-left-purple.border-left-darken-4 {
  border-right: 1px solid #4a148c !important; }

[dir="rtl"] .border-right-purple.border-right-darken-4 {
  border-left: 1px solid #4a148c !important; }

.overlay-purple.overlay-darken-4 {
  background: #4a148c;
  /* The Fallback */
  background: rgba(74, 20, 140, 0.8); }

.purple.accent-1 {
  color: #ea80fc !important; }

.bg-purple.bg-accent-1 {
  background-color: #ea80fc !important; }

.btn-purple.btn-accent-1 {
  border-color: #7b1fa2 !important;
  background-color: #ea80fc !important; }
  .btn-purple.btn-accent-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-1:focus, .btn-purple.btn-accent-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-1 {
  border-color: #ea80fc !important;
  color: #ea80fc !important; }
  .btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #ea80fc !important; }

.progress-purple.progress-accent-1 {
  background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-ms-fill {
    background-color: #ea80fc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-1 .progress-bar {
      background-color: #ea80fc; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ea80fc !important; }

.border-purple.border-accent-1 {
  border: 1px solid #ea80fc !important; }

.border-top-purple.border-top-accent-1 {
  border-top: 1px solid #ea80fc !important; }

.border-bottom-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #ea80fc !important; }

[dir="ltr"] .border-left-purple.border-left-accent-1 {
  border-left: 1px solid #ea80fc !important; }

[dir="ltr"] .border-right-purple.border-right-accent-1 {
  border-right: 1px solid #ea80fc !important; }

[dir="rtl"] .border-left-purple.border-left-accent-1 {
  border-right: 1px solid #ea80fc !important; }

[dir="rtl"] .border-right-purple.border-right-accent-1 {
  border-left: 1px solid #ea80fc !important; }

.overlay-purple.overlay-accent-1 {
  background: #ea80fc;
  /* The Fallback */
  background: rgba(234, 128, 252, 0.8); }

.purple.accent-2 {
  color: #e040fb !important; }

.bg-purple.bg-accent-2 {
  background-color: #e040fb !important; }

.btn-purple.btn-accent-2 {
  border-color: #7b1fa2 !important;
  background-color: #e040fb !important; }
  .btn-purple.btn-accent-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-2:focus, .btn-purple.btn-accent-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-2 {
  border-color: #e040fb !important;
  color: #e040fb !important; }
  .btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #e040fb !important; }

.progress-purple.progress-accent-2 {
  background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-ms-fill {
    background-color: #e040fb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-2 .progress-bar {
      background-color: #e040fb; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e040fb !important; }

.border-purple.border-accent-2 {
  border: 1px solid #e040fb !important; }

.border-top-purple.border-top-accent-2 {
  border-top: 1px solid #e040fb !important; }

.border-bottom-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #e040fb !important; }

[dir="ltr"] .border-left-purple.border-left-accent-2 {
  border-left: 1px solid #e040fb !important; }

[dir="ltr"] .border-right-purple.border-right-accent-2 {
  border-right: 1px solid #e040fb !important; }

[dir="rtl"] .border-left-purple.border-left-accent-2 {
  border-right: 1px solid #e040fb !important; }

[dir="rtl"] .border-right-purple.border-right-accent-2 {
  border-left: 1px solid #e040fb !important; }

.overlay-purple.overlay-accent-2 {
  background: #e040fb;
  /* The Fallback */
  background: rgba(224, 64, 251, 0.8); }

.purple.accent-3 {
  color: #d500f9 !important; }

.bg-purple.bg-accent-3 {
  background-color: #d500f9 !important; }

.btn-purple.btn-accent-3 {
  border-color: #7b1fa2 !important;
  background-color: #d500f9 !important; }
  .btn-purple.btn-accent-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-3:focus, .btn-purple.btn-accent-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-3 {
  border-color: #d500f9 !important;
  color: #d500f9 !important; }
  .btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #d500f9 !important; }

.progress-purple.progress-accent-3 {
  background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-ms-fill {
    background-color: #d500f9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-3 .progress-bar {
      background-color: #d500f9; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d500f9 !important; }

.border-purple.border-accent-3 {
  border: 1px solid #d500f9 !important; }

.border-top-purple.border-top-accent-3 {
  border-top: 1px solid #d500f9 !important; }

.border-bottom-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #d500f9 !important; }

[dir="ltr"] .border-left-purple.border-left-accent-3 {
  border-left: 1px solid #d500f9 !important; }

[dir="ltr"] .border-right-purple.border-right-accent-3 {
  border-right: 1px solid #d500f9 !important; }

[dir="rtl"] .border-left-purple.border-left-accent-3 {
  border-right: 1px solid #d500f9 !important; }

[dir="rtl"] .border-right-purple.border-right-accent-3 {
  border-left: 1px solid #d500f9 !important; }

.overlay-purple.overlay-accent-3 {
  background: #d500f9;
  /* The Fallback */
  background: rgba(213, 0, 249, 0.8); }

.purple.accent-4 {
  color: #a0f !important; }

.bg-purple.bg-accent-4 {
  background-color: #a0f !important; }

.btn-purple.btn-accent-4 {
  border-color: #7b1fa2 !important;
  background-color: #a0f !important; }
  .btn-purple.btn-accent-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-4:focus, .btn-purple.btn-accent-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-4 {
  border-color: #a0f !important;
  color: #a0f !important; }
  .btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #a0f !important; }

.progress-purple.progress-accent-4 {
  background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-ms-fill {
    background-color: #a0f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-4 .progress-bar {
      background-color: #a0f; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a0f !important; }

.border-purple.border-accent-4 {
  border: 1px solid #a0f !important; }

.border-top-purple.border-top-accent-4 {
  border-top: 1px solid #a0f !important; }

.border-bottom-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #a0f !important; }

[dir="ltr"] .border-left-purple.border-left-accent-4 {
  border-left: 1px solid #a0f !important; }

[dir="ltr"] .border-right-purple.border-right-accent-4 {
  border-right: 1px solid #a0f !important; }

[dir="rtl"] .border-left-purple.border-left-accent-4 {
  border-right: 1px solid #a0f !important; }

[dir="rtl"] .border-right-purple.border-right-accent-4 {
  border-left: 1px solid #a0f !important; }

.overlay-purple.overlay-accent-4 {
  background: #a0f;
  /* The Fallback */
  background: rgba(170, 0, 255, 0.8); }

.deep-purple.lighten-5 {
  color: #ede7f6 !important; }

.bg-deep-purple.bg-lighten-5 {
  background-color: #ede7f6 !important; }

.btn-deep-purple.btn-lighten-5 {
  border-color: #512da8 !important;
  background-color: #ede7f6 !important; }
  .btn-deep-purple.btn-lighten-5:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-5:focus, .btn-deep-purple.btn-lighten-5:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-5 {
  border-color: #ede7f6 !important;
  color: #ede7f6 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-5:hover {
    background-color: #ede7f6 !important; }

.progress-deep-purple.progress-lighten-5 {
  background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #ede7f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-5 .progress-bar {
      background-color: #ede7f6; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ede7f6 !important; }

.border-deep-purple.border-lighten-5 {
  border: 1px solid #ede7f6 !important; }

.border-top-deep-purple.border-top-lighten-5 {
  border-top: 1px solid #ede7f6 !important; }

.border-bottom-deep-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #ede7f6 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-5 {
  border-left: 1px solid #ede7f6 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-5 {
  border-right: 1px solid #ede7f6 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-5 {
  border-right: 1px solid #ede7f6 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-5 {
  border-left: 1px solid #ede7f6 !important; }

.overlay-deep-purple.overlay-lighten-5 {
  background: #ede7f6;
  /* The Fallback */
  background: rgba(237, 231, 246, 0.8); }

.deep-purple.lighten-4 {
  color: #d1c4e9 !important; }

.bg-deep-purple.bg-lighten-4 {
  background-color: #d1c4e9 !important; }

.btn-deep-purple.btn-lighten-4 {
  border-color: #512da8 !important;
  background-color: #d1c4e9 !important; }
  .btn-deep-purple.btn-lighten-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-4:focus, .btn-deep-purple.btn-lighten-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-4 {
  border-color: #d1c4e9 !important;
  color: #d1c4e9 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-4:hover {
    background-color: #d1c4e9 !important; }

.progress-deep-purple.progress-lighten-4 {
  background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #d1c4e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-4 .progress-bar {
      background-color: #d1c4e9; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d1c4e9 !important; }

.border-deep-purple.border-lighten-4 {
  border: 1px solid #d1c4e9 !important; }

.border-top-deep-purple.border-top-lighten-4 {
  border-top: 1px solid #d1c4e9 !important; }

.border-bottom-deep-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #d1c4e9 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-4 {
  border-left: 1px solid #d1c4e9 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-4 {
  border-right: 1px solid #d1c4e9 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-4 {
  border-right: 1px solid #d1c4e9 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-4 {
  border-left: 1px solid #d1c4e9 !important; }

.overlay-deep-purple.overlay-lighten-4 {
  background: #d1c4e9;
  /* The Fallback */
  background: rgba(209, 196, 233, 0.8); }

.deep-purple.lighten-3 {
  color: #b39ddb !important; }

.bg-deep-purple.bg-lighten-3 {
  background-color: #b39ddb !important; }

.btn-deep-purple.btn-lighten-3 {
  border-color: #512da8 !important;
  background-color: #b39ddb !important; }
  .btn-deep-purple.btn-lighten-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-3:focus, .btn-deep-purple.btn-lighten-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-3 {
  border-color: #b39ddb !important;
  color: #b39ddb !important; }
  .btn-outline-deep-purple.btn-outline-lighten-3:hover {
    background-color: #b39ddb !important; }

.progress-deep-purple.progress-lighten-3 {
  background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #b39ddb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-3 .progress-bar {
      background-color: #b39ddb; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b39ddb !important; }

.border-deep-purple.border-lighten-3 {
  border: 1px solid #b39ddb !important; }

.border-top-deep-purple.border-top-lighten-3 {
  border-top: 1px solid #b39ddb !important; }

.border-bottom-deep-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #b39ddb !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-3 {
  border-left: 1px solid #b39ddb !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-3 {
  border-right: 1px solid #b39ddb !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-3 {
  border-right: 1px solid #b39ddb !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-3 {
  border-left: 1px solid #b39ddb !important; }

.overlay-deep-purple.overlay-lighten-3 {
  background: #b39ddb;
  /* The Fallback */
  background: rgba(179, 157, 219, 0.8); }

.deep-purple.lighten-2 {
  color: #9575cd !important; }

.bg-deep-purple.bg-lighten-2 {
  background-color: #9575cd !important; }

.btn-deep-purple.btn-lighten-2 {
  border-color: #512da8 !important;
  background-color: #9575cd !important; }
  .btn-deep-purple.btn-lighten-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-2:focus, .btn-deep-purple.btn-lighten-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-2 {
  border-color: #9575cd !important;
  color: #9575cd !important; }
  .btn-outline-deep-purple.btn-outline-lighten-2:hover {
    background-color: #9575cd !important; }

.progress-deep-purple.progress-lighten-2 {
  background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #9575cd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-2 .progress-bar {
      background-color: #9575cd; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9575cd !important; }

.border-deep-purple.border-lighten-2 {
  border: 1px solid #9575cd !important; }

.border-top-deep-purple.border-top-lighten-2 {
  border-top: 1px solid #9575cd !important; }

.border-bottom-deep-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #9575cd !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-2 {
  border-left: 1px solid #9575cd !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-2 {
  border-right: 1px solid #9575cd !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-2 {
  border-right: 1px solid #9575cd !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-2 {
  border-left: 1px solid #9575cd !important; }

.overlay-deep-purple.overlay-lighten-2 {
  background: #9575cd;
  /* The Fallback */
  background: rgba(149, 117, 205, 0.8); }

.deep-purple.lighten-1 {
  color: #7e57c2 !important; }

.bg-deep-purple.bg-lighten-1 {
  background-color: #7e57c2 !important; }

.btn-deep-purple.btn-lighten-1 {
  border-color: #512da8 !important;
  background-color: #7e57c2 !important; }
  .btn-deep-purple.btn-lighten-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-1:focus, .btn-deep-purple.btn-lighten-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-1 {
  border-color: #7e57c2 !important;
  color: #7e57c2 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-1:hover {
    background-color: #7e57c2 !important; }

.progress-deep-purple.progress-lighten-1 {
  background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #7e57c2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-1 .progress-bar {
      background-color: #7e57c2; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7e57c2 !important; }

.border-deep-purple.border-lighten-1 {
  border: 1px solid #7e57c2 !important; }

.border-top-deep-purple.border-top-lighten-1 {
  border-top: 1px solid #7e57c2 !important; }

.border-bottom-deep-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #7e57c2 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-1 {
  border-left: 1px solid #7e57c2 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-1 {
  border-right: 1px solid #7e57c2 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-1 {
  border-right: 1px solid #7e57c2 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-1 {
  border-left: 1px solid #7e57c2 !important; }

.overlay-deep-purple.overlay-lighten-1 {
  background: #7e57c2;
  /* The Fallback */
  background: rgba(126, 87, 194, 0.8); }

.deep-purple {
  color: #673ab7 !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }
  .bg-deep-purple .card-header, .bg-deep-purple .card-footer {
    background-color: transparent; }

.toast-deep-purple {
  background-color: #673ab7; }

.alert-deep-purple {
  border-color: #673ab7 !important;
  background-color: #794ec7 !important;
  color: #1b0f2f !important; }
  .alert-deep-purple .alert-link {
    color: #090510 !important; }

.border-deep-purple {
  border-color: #673ab7; }

.border-top-deep-purple {
  border-top-color: #673ab7; }

.border-bottom-deep-purple {
  border-bottom-color: #673ab7; }

[dir="ltr"] .border-left-deep-purple {
  border-left-color: #673ab7; }

[dir="ltr"] .border-right-deep-purple {
  border-right-color: #673ab7; }

[dir="rtl"] .border-left-deep-purple {
  border-right-color: #673ab7; }

[dir="rtl"] .border-right-deep-purple {
  border-left-color: #673ab7; }

.badge-deep-purple {
  background-color: #673ab7; }

.panel-deep-purple {
  border-color: #673ab7; }
  .panel-deep-purple .panel-heading {
    color: #FFF;
    border-color: #673ab7;
    background-color: #7446c4; }

.bg-deep-purple.tag-glow, .border-deep-purple.tag-glow {
  box-shadow: 0px 0px 10px #673ab7; }

.overlay-deep-purple {
  background: #673ab7;
  /* The Fallback */
  background: rgba(103, 58, 183, 0.8); }

.card.card-outline-deep-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #673ab7;
  background-color: transparent; }
  .card.card-outline-deep-purple .card-header, .card.card-outline-deep-purple .card-footer {
    background-color: transparent; }

.btn-deep-purple.btn-flat {
  background-color: transparent !important;
  color: #673ab7;
  border: none; }

.btn-deep-purple.btn-raised, .btn-deep-purple.btn-fab {
  background-color: #673ab7 !important;
  color: #fff !important;
  border-color: #673ab7; }
  .btn-deep-purple.btn-raised.active, .btn-deep-purple.btn-fab.active {
    background-color: #58319c !important;
    border-color: #58319c !important; }

.btn-group-raised .btn-deep-purple {
  background-color: #673ab7 !important;
  color: #fff !important; }

.btn-outline-deep-purple {
  border: 1px solid;
  border-color: #673ab7;
  background-color: transparent;
  color: #673ab7;
  box-shadow: none !important; }
  .btn-outline-deep-purple:focus {
    background-color: transparent !important;
    color: #673ab7 !important;
    box-shadow: transparent !important; }
  .btn-outline-deep-purple.active {
    background-color: #673ab7 !important;
    color: #FFF !important; }
  .btn-outline-deep-purple:hover {
    background-color: #6036ab !important;
    color: #FFF !important; }
    .btn-outline-deep-purple:hover svg {
      color: #FFF !important; }

.btn-outline-deep-purple.btn-raised, .btn-outline-deep-purple.btn-fab {
  border: 1px solid;
  border-color: #673ab7;
  background-color: transparent;
  color: #673ab7;
  box-shadow: none !important; }
  .btn-outline-deep-purple.btn-raised:focus, .btn-outline-deep-purple.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-deep-purple.btn-raised.active, .btn-outline-deep-purple.btn-fab.active {
    background-color: #673ab7 !important;
    color: #FFF !important; }
  .btn-outline-deep-purple.btn-raised:hover, .btn-outline-deep-purple.btn-fab:hover {
    background-color: #6036ab !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-deep-purple {
  background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-webkit-progress-value {
    background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-moz-progress-bar {
    background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-ms-fill {
    background-color: #673ab7; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-deep-purple .progress-bar {
      background-color: #673ab7; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #673ab7 !important; }

.deep-purple.darken-1 {
  color: #5e35b1 !important; }

.bg-deep-purple.bg-darken-1 {
  background-color: #5e35b1 !important; }

.btn-deep-purple.btn-darken-1 {
  border-color: #512da8 !important;
  background-color: #5e35b1 !important; }
  .btn-deep-purple.btn-darken-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-1:focus, .btn-deep-purple.btn-darken-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-1 {
  border-color: #5e35b1 !important;
  color: #5e35b1 !important; }
  .btn-outline-deep-purple.btn-outline-darken-1:hover {
    background-color: #5e35b1 !important; }

.progress-deep-purple.progress-darken-1 {
  background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-ms-fill {
    background-color: #5e35b1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-1 .progress-bar {
      background-color: #5e35b1; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5e35b1 !important; }

.border-deep-purple.border-darken-1 {
  border: 1px solid #5e35b1 !important; }

.border-top-deep-purple.border-top-darken-1 {
  border-top: 1px solid #5e35b1 !important; }

.border-bottom-deep-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #5e35b1 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-1 {
  border-left: 1px solid #5e35b1 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-1 {
  border-right: 1px solid #5e35b1 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-1 {
  border-right: 1px solid #5e35b1 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-1 {
  border-left: 1px solid #5e35b1 !important; }

.overlay-deep-purple.overlay-darken-1 {
  background: #5e35b1;
  /* The Fallback */
  background: rgba(94, 53, 177, 0.8); }

.deep-purple.darken-2 {
  color: #512da8 !important; }

.bg-deep-purple.bg-darken-2 {
  background-color: #512da8 !important; }

.btn-deep-purple.btn-darken-2 {
  border-color: #512da8 !important;
  background-color: #512da8 !important; }
  .btn-deep-purple.btn-darken-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-2:focus, .btn-deep-purple.btn-darken-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-2 {
  border-color: #512da8 !important;
  color: #512da8 !important; }
  .btn-outline-deep-purple.btn-outline-darken-2:hover {
    background-color: #512da8 !important; }

.progress-deep-purple.progress-darken-2 {
  background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-ms-fill {
    background-color: #512da8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-2 .progress-bar {
      background-color: #512da8; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #512da8 !important; }

.border-deep-purple.border-darken-2 {
  border: 1px solid #512da8 !important; }

.border-top-deep-purple.border-top-darken-2 {
  border-top: 1px solid #512da8 !important; }

.border-bottom-deep-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #512da8 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-2 {
  border-left: 1px solid #512da8 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-2 {
  border-right: 1px solid #512da8 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-2 {
  border-right: 1px solid #512da8 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-2 {
  border-left: 1px solid #512da8 !important; }

.overlay-deep-purple.overlay-darken-2 {
  background: #512da8;
  /* The Fallback */
  background: rgba(81, 45, 168, 0.8); }

.deep-purple.darken-3 {
  color: #4527a0 !important; }

.bg-deep-purple.bg-darken-3 {
  background-color: #4527a0 !important; }

.btn-deep-purple.btn-darken-3 {
  border-color: #512da8 !important;
  background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-3:focus, .btn-deep-purple.btn-darken-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-3 {
  border-color: #4527a0 !important;
  color: #4527a0 !important; }
  .btn-outline-deep-purple.btn-outline-darken-3:hover {
    background-color: #4527a0 !important; }

.progress-deep-purple.progress-darken-3 {
  background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-ms-fill {
    background-color: #4527a0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-3 .progress-bar {
      background-color: #4527a0; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4527a0 !important; }

.border-deep-purple.border-darken-3 {
  border: 1px solid #4527a0 !important; }

.border-top-deep-purple.border-top-darken-3 {
  border-top: 1px solid #4527a0 !important; }

.border-bottom-deep-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #4527a0 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-3 {
  border-left: 1px solid #4527a0 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-3 {
  border-right: 1px solid #4527a0 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-3 {
  border-right: 1px solid #4527a0 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-3 {
  border-left: 1px solid #4527a0 !important; }

.overlay-deep-purple.overlay-darken-3 {
  background: #4527a0;
  /* The Fallback */
  background: rgba(69, 39, 160, 0.8); }

.deep-purple.darken-4 {
  color: #311b92 !important; }

.bg-deep-purple.bg-darken-4 {
  background-color: #311b92 !important; }

.btn-deep-purple.btn-darken-4 {
  border-color: #512da8 !important;
  background-color: #311b92 !important; }
  .btn-deep-purple.btn-darken-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-4:focus, .btn-deep-purple.btn-darken-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-4 {
  border-color: #311b92 !important;
  color: #311b92 !important; }
  .btn-outline-deep-purple.btn-outline-darken-4:hover {
    background-color: #311b92 !important; }

.progress-deep-purple.progress-darken-4 {
  background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-ms-fill {
    background-color: #311b92; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-4 .progress-bar {
      background-color: #311b92; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #311b92 !important; }

.border-deep-purple.border-darken-4 {
  border: 1px solid #311b92 !important; }

.border-top-deep-purple.border-top-darken-4 {
  border-top: 1px solid #311b92 !important; }

.border-bottom-deep-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #311b92 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-4 {
  border-left: 1px solid #311b92 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-4 {
  border-right: 1px solid #311b92 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-4 {
  border-right: 1px solid #311b92 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-4 {
  border-left: 1px solid #311b92 !important; }

.overlay-deep-purple.overlay-darken-4 {
  background: #311b92;
  /* The Fallback */
  background: rgba(49, 27, 146, 0.8); }

.deep-purple.accent-1 {
  color: #b388ff !important; }

.bg-deep-purple.bg-accent-1 {
  background-color: #b388ff !important; }

.btn-deep-purple.btn-accent-1 {
  border-color: #512da8 !important;
  background-color: #b388ff !important; }
  .btn-deep-purple.btn-accent-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-1:focus, .btn-deep-purple.btn-accent-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-1 {
  border-color: #b388ff !important;
  color: #b388ff !important; }
  .btn-outline-deep-purple.btn-outline-accent-1:hover {
    background-color: #b388ff !important; }

.progress-deep-purple.progress-accent-1 {
  background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-ms-fill {
    background-color: #b388ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-1 .progress-bar {
      background-color: #b388ff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b388ff !important; }

.border-deep-purple.border-accent-1 {
  border: 1px solid #b388ff !important; }

.border-top-deep-purple.border-top-accent-1 {
  border-top: 1px solid #b388ff !important; }

.border-bottom-deep-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #b388ff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-1 {
  border-left: 1px solid #b388ff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-1 {
  border-right: 1px solid #b388ff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-1 {
  border-right: 1px solid #b388ff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-1 {
  border-left: 1px solid #b388ff !important; }

.overlay-deep-purple.overlay-accent-1 {
  background: #b388ff;
  /* The Fallback */
  background: rgba(179, 136, 255, 0.8); }

.deep-purple.accent-2 {
  color: #7c4dff !important; }

.bg-deep-purple.bg-accent-2 {
  background-color: #7c4dff !important; }

.btn-deep-purple.btn-accent-2 {
  border-color: #512da8 !important;
  background-color: #7c4dff !important; }
  .btn-deep-purple.btn-accent-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-2:focus, .btn-deep-purple.btn-accent-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-2 {
  border-color: #7c4dff !important;
  color: #7c4dff !important; }
  .btn-outline-deep-purple.btn-outline-accent-2:hover {
    background-color: #7c4dff !important; }

.progress-deep-purple.progress-accent-2 {
  background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-ms-fill {
    background-color: #7c4dff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-2 .progress-bar {
      background-color: #7c4dff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7c4dff !important; }

.border-deep-purple.border-accent-2 {
  border: 1px solid #7c4dff !important; }

.border-top-deep-purple.border-top-accent-2 {
  border-top: 1px solid #7c4dff !important; }

.border-bottom-deep-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #7c4dff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-2 {
  border-left: 1px solid #7c4dff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-2 {
  border-right: 1px solid #7c4dff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-2 {
  border-right: 1px solid #7c4dff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-2 {
  border-left: 1px solid #7c4dff !important; }

.overlay-deep-purple.overlay-accent-2 {
  background: #7c4dff;
  /* The Fallback */
  background: rgba(124, 77, 255, 0.8); }

.deep-purple.accent-3 {
  color: #651fff !important; }

.bg-deep-purple.bg-accent-3 {
  background-color: #651fff !important; }

.btn-deep-purple.btn-accent-3 {
  border-color: #512da8 !important;
  background-color: #651fff !important; }
  .btn-deep-purple.btn-accent-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-3:focus, .btn-deep-purple.btn-accent-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-3 {
  border-color: #651fff !important;
  color: #651fff !important; }
  .btn-outline-deep-purple.btn-outline-accent-3:hover {
    background-color: #651fff !important; }

.progress-deep-purple.progress-accent-3 {
  background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-ms-fill {
    background-color: #651fff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-3 .progress-bar {
      background-color: #651fff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #651fff !important; }

.border-deep-purple.border-accent-3 {
  border: 1px solid #651fff !important; }

.border-top-deep-purple.border-top-accent-3 {
  border-top: 1px solid #651fff !important; }

.border-bottom-deep-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #651fff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-3 {
  border-left: 1px solid #651fff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-3 {
  border-right: 1px solid #651fff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-3 {
  border-right: 1px solid #651fff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-3 {
  border-left: 1px solid #651fff !important; }

.overlay-deep-purple.overlay-accent-3 {
  background: #651fff;
  /* The Fallback */
  background: rgba(101, 31, 255, 0.8); }

.deep-purple.accent-4 {
  color: #6200ea !important; }

.bg-deep-purple.bg-accent-4 {
  background-color: #6200ea !important; }

.btn-deep-purple.btn-accent-4 {
  border-color: #512da8 !important;
  background-color: #6200ea !important; }
  .btn-deep-purple.btn-accent-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-4:focus, .btn-deep-purple.btn-accent-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-4 {
  border-color: #6200ea !important;
  color: #6200ea !important; }
  .btn-outline-deep-purple.btn-outline-accent-4:hover {
    background-color: #6200ea !important; }

.progress-deep-purple.progress-accent-4 {
  background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-ms-fill {
    background-color: #6200ea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-4 .progress-bar {
      background-color: #6200ea; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6200ea !important; }

.border-deep-purple.border-accent-4 {
  border: 1px solid #6200ea !important; }

.border-top-deep-purple.border-top-accent-4 {
  border-top: 1px solid #6200ea !important; }

.border-bottom-deep-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #6200ea !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-4 {
  border-left: 1px solid #6200ea !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-4 {
  border-right: 1px solid #6200ea !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-4 {
  border-right: 1px solid #6200ea !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-4 {
  border-left: 1px solid #6200ea !important; }

.overlay-deep-purple.overlay-accent-4 {
  background: #6200ea;
  /* The Fallback */
  background: rgba(98, 0, 234, 0.8); }

.indigo.lighten-5 {
  color: #e8eaf6 !important; }

.bg-indigo.bg-lighten-5 {
  background-color: #e8eaf6 !important; }

.btn-indigo.btn-lighten-5 {
  border-color: #303f9f !important;
  background-color: #e8eaf6 !important; }
  .btn-indigo.btn-lighten-5:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-5:focus, .btn-indigo.btn-lighten-5:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-5 {
  border-color: #e8eaf6 !important;
  color: #e8eaf6 !important; }
  .btn-outline-indigo.btn-outline-lighten-5:hover {
    background-color: #e8eaf6 !important; }

.progress-indigo.progress-lighten-5 {
  background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-ms-fill {
    background-color: #e8eaf6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-5 .progress-bar {
      background-color: #e8eaf6; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8eaf6 !important; }

.border-indigo.border-lighten-5 {
  border: 1px solid #e8eaf6 !important; }

.border-top-indigo.border-top-lighten-5 {
  border-top: 1px solid #e8eaf6 !important; }

.border-bottom-indigo.border-bottom-lighten-5 {
  border-bottom: 1px solid #e8eaf6 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-5 {
  border-left: 1px solid #e8eaf6 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-5 {
  border-right: 1px solid #e8eaf6 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-5 {
  border-right: 1px solid #e8eaf6 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-5 {
  border-left: 1px solid #e8eaf6 !important; }

.overlay-indigo.overlay-lighten-5 {
  background: #e8eaf6;
  /* The Fallback */
  background: rgba(232, 234, 246, 0.8); }

.indigo.lighten-4 {
  color: #c5cae9 !important; }

.bg-indigo.bg-lighten-4 {
  background-color: #c5cae9 !important; }

.btn-indigo.btn-lighten-4 {
  border-color: #303f9f !important;
  background-color: #c5cae9 !important; }
  .btn-indigo.btn-lighten-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-4:focus, .btn-indigo.btn-lighten-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-4 {
  border-color: #c5cae9 !important;
  color: #c5cae9 !important; }
  .btn-outline-indigo.btn-outline-lighten-4:hover {
    background-color: #c5cae9 !important; }

.progress-indigo.progress-lighten-4 {
  background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-ms-fill {
    background-color: #c5cae9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-4 .progress-bar {
      background-color: #c5cae9; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5cae9 !important; }

.border-indigo.border-lighten-4 {
  border: 1px solid #c5cae9 !important; }

.border-top-indigo.border-top-lighten-4 {
  border-top: 1px solid #c5cae9 !important; }

.border-bottom-indigo.border-bottom-lighten-4 {
  border-bottom: 1px solid #c5cae9 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-4 {
  border-left: 1px solid #c5cae9 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-4 {
  border-right: 1px solid #c5cae9 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-4 {
  border-right: 1px solid #c5cae9 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-4 {
  border-left: 1px solid #c5cae9 !important; }

.overlay-indigo.overlay-lighten-4 {
  background: #c5cae9;
  /* The Fallback */
  background: rgba(197, 202, 233, 0.8); }

.indigo.lighten-3 {
  color: #9fa8da !important; }

.bg-indigo.bg-lighten-3 {
  background-color: #9fa8da !important; }

.btn-indigo.btn-lighten-3 {
  border-color: #303f9f !important;
  background-color: #9fa8da !important; }
  .btn-indigo.btn-lighten-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-3:focus, .btn-indigo.btn-lighten-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-3 {
  border-color: #9fa8da !important;
  color: #9fa8da !important; }
  .btn-outline-indigo.btn-outline-lighten-3:hover {
    background-color: #9fa8da !important; }

.progress-indigo.progress-lighten-3 {
  background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-ms-fill {
    background-color: #9fa8da; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-3 .progress-bar {
      background-color: #9fa8da; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9fa8da !important; }

.border-indigo.border-lighten-3 {
  border: 1px solid #9fa8da !important; }

.border-top-indigo.border-top-lighten-3 {
  border-top: 1px solid #9fa8da !important; }

.border-bottom-indigo.border-bottom-lighten-3 {
  border-bottom: 1px solid #9fa8da !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-3 {
  border-left: 1px solid #9fa8da !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-3 {
  border-right: 1px solid #9fa8da !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-3 {
  border-right: 1px solid #9fa8da !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-3 {
  border-left: 1px solid #9fa8da !important; }

.overlay-indigo.overlay-lighten-3 {
  background: #9fa8da;
  /* The Fallback */
  background: rgba(159, 168, 218, 0.8); }

.indigo.lighten-2 {
  color: #7986cb !important; }

.bg-indigo.bg-lighten-2 {
  background-color: #7986cb !important; }

.btn-indigo.btn-lighten-2 {
  border-color: #303f9f !important;
  background-color: #7986cb !important; }
  .btn-indigo.btn-lighten-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-2:focus, .btn-indigo.btn-lighten-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-2 {
  border-color: #7986cb !important;
  color: #7986cb !important; }
  .btn-outline-indigo.btn-outline-lighten-2:hover {
    background-color: #7986cb !important; }

.progress-indigo.progress-lighten-2 {
  background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-ms-fill {
    background-color: #7986cb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-2 .progress-bar {
      background-color: #7986cb; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7986cb !important; }

.border-indigo.border-lighten-2 {
  border: 1px solid #7986cb !important; }

.border-top-indigo.border-top-lighten-2 {
  border-top: 1px solid #7986cb !important; }

.border-bottom-indigo.border-bottom-lighten-2 {
  border-bottom: 1px solid #7986cb !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-2 {
  border-left: 1px solid #7986cb !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-2 {
  border-right: 1px solid #7986cb !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-2 {
  border-right: 1px solid #7986cb !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-2 {
  border-left: 1px solid #7986cb !important; }

.overlay-indigo.overlay-lighten-2 {
  background: #7986cb;
  /* The Fallback */
  background: rgba(121, 134, 203, 0.8); }

.indigo.lighten-1 {
  color: #5c6bc0 !important; }

.bg-indigo.bg-lighten-1 {
  background-color: #5c6bc0 !important; }

.btn-indigo.btn-lighten-1 {
  border-color: #303f9f !important;
  background-color: #5c6bc0 !important; }
  .btn-indigo.btn-lighten-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-1:focus, .btn-indigo.btn-lighten-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-1 {
  border-color: #5c6bc0 !important;
  color: #5c6bc0 !important; }
  .btn-outline-indigo.btn-outline-lighten-1:hover {
    background-color: #5c6bc0 !important; }

.progress-indigo.progress-lighten-1 {
  background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-ms-fill {
    background-color: #5c6bc0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-1 .progress-bar {
      background-color: #5c6bc0; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5c6bc0 !important; }

.border-indigo.border-lighten-1 {
  border: 1px solid #5c6bc0 !important; }

.border-top-indigo.border-top-lighten-1 {
  border-top: 1px solid #5c6bc0 !important; }

.border-bottom-indigo.border-bottom-lighten-1 {
  border-bottom: 1px solid #5c6bc0 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-1 {
  border-left: 1px solid #5c6bc0 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-1 {
  border-right: 1px solid #5c6bc0 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-1 {
  border-right: 1px solid #5c6bc0 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-1 {
  border-left: 1px solid #5c6bc0 !important; }

.overlay-indigo.overlay-lighten-1 {
  background: #5c6bc0;
  /* The Fallback */
  background: rgba(92, 107, 192, 0.8); }

.indigo {
  color: #3f51b5 !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }
  .bg-indigo .card-header, .bg-indigo .card-footer {
    background-color: transparent; }

.toast-indigo {
  background-color: #3f51b5; }

.alert-indigo {
  border-color: #3f51b5 !important;
  background-color: #5465c4 !important;
  color: #111631 !important; }
  .alert-indigo .alert-link {
    color: #060812 !important; }

.border-indigo {
  border-color: #3f51b5; }

.border-top-indigo {
  border-top-color: #3f51b5; }

.border-bottom-indigo {
  border-bottom-color: #3f51b5; }

[dir="ltr"] .border-left-indigo {
  border-left-color: #3f51b5; }

[dir="ltr"] .border-right-indigo {
  border-right-color: #3f51b5; }

[dir="rtl"] .border-left-indigo {
  border-right-color: #3f51b5; }

[dir="rtl"] .border-right-indigo {
  border-left-color: #3f51b5; }

.badge-indigo {
  background-color: #3f51b5; }

.panel-indigo {
  border-color: #3f51b5; }
  .panel-indigo .panel-heading {
    color: #FFF;
    border-color: #3f51b5;
    background-color: #4d5ec1; }

.bg-indigo.tag-glow, .border-indigo.tag-glow {
  box-shadow: 0px 0px 10px #3f51b5; }

.overlay-indigo {
  background: #3f51b5;
  /* The Fallback */
  background: rgba(63, 81, 181, 0.8); }

.card.card-outline-indigo {
  border-width: 1px;
  border-style: solid;
  border-color: #3f51b5;
  background-color: transparent; }
  .card.card-outline-indigo .card-header, .card.card-outline-indigo .card-footer {
    background-color: transparent; }

.btn-indigo.btn-flat {
  background-color: transparent !important;
  color: #3f51b5;
  border: none; }

.btn-indigo.btn-raised, .btn-indigo.btn-fab {
  background-color: #3f51b5 !important;
  color: #fff !important;
  border-color: #3f51b5; }
  .btn-indigo.btn-raised.active, .btn-indigo.btn-fab.active {
    background-color: #36459b !important;
    border-color: #36459b !important; }

.btn-group-raised .btn-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.btn-outline-indigo {
  border: 1px solid;
  border-color: #3f51b5;
  background-color: transparent;
  color: #3f51b5;
  box-shadow: none !important; }
  .btn-outline-indigo:focus {
    background-color: transparent !important;
    color: #3f51b5 !important;
    box-shadow: transparent !important; }
  .btn-outline-indigo.active {
    background-color: #3f51b5 !important;
    color: #FFF !important; }
  .btn-outline-indigo:hover {
    background-color: #3b4caa !important;
    color: #FFF !important; }
    .btn-outline-indigo:hover svg {
      color: #FFF !important; }

.btn-outline-indigo.btn-raised, .btn-outline-indigo.btn-fab {
  border: 1px solid;
  border-color: #3f51b5;
  background-color: transparent;
  color: #3f51b5;
  box-shadow: none !important; }
  .btn-outline-indigo.btn-raised:focus, .btn-outline-indigo.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-indigo.btn-raised.active, .btn-outline-indigo.btn-fab.active {
    background-color: #3f51b5 !important;
    color: #FFF !important; }
  .btn-outline-indigo.btn-raised:hover, .btn-outline-indigo.btn-fab:hover {
    background-color: #3b4caa !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-indigo {
  background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-webkit-progress-value {
    background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-moz-progress-bar {
    background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-ms-fill {
    background-color: #3f51b5; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-indigo .progress-bar {
      background-color: #3f51b5; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3f51b5 !important; }

.indigo.darken-1 {
  color: #3949ab !important; }

.bg-indigo.bg-darken-1 {
  background-color: #3949ab !important; }

.btn-indigo.btn-darken-1 {
  border-color: #303f9f !important;
  background-color: #3949ab !important; }
  .btn-indigo.btn-darken-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-1:focus, .btn-indigo.btn-darken-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-1 {
  border-color: #3949ab !important;
  color: #3949ab !important; }
  .btn-outline-indigo.btn-outline-darken-1:hover {
    background-color: #3949ab !important; }

.progress-indigo.progress-darken-1 {
  background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-webkit-progress-value {
    background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-moz-progress-bar {
    background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-ms-fill {
    background-color: #3949ab; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-1 .progress-bar {
      background-color: #3949ab; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3949ab !important; }

.border-indigo.border-darken-1 {
  border: 1px solid #3949ab !important; }

.border-top-indigo.border-top-darken-1 {
  border-top: 1px solid #3949ab !important; }

.border-bottom-indigo.border-bottom-darken-1 {
  border-bottom: 1px solid #3949ab !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-1 {
  border-left: 1px solid #3949ab !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-1 {
  border-right: 1px solid #3949ab !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-1 {
  border-right: 1px solid #3949ab !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-1 {
  border-left: 1px solid #3949ab !important; }

.overlay-indigo.overlay-darken-1 {
  background: #3949ab;
  /* The Fallback */
  background: rgba(57, 73, 171, 0.8); }

.indigo.darken-2 {
  color: #303f9f !important; }

.bg-indigo.bg-darken-2 {
  background-color: #303f9f !important; }

.btn-indigo.btn-darken-2 {
  border-color: #303f9f !important;
  background-color: #303f9f !important; }
  .btn-indigo.btn-darken-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-2:focus, .btn-indigo.btn-darken-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-2 {
  border-color: #303f9f !important;
  color: #303f9f !important; }
  .btn-outline-indigo.btn-outline-darken-2:hover {
    background-color: #303f9f !important; }

.progress-indigo.progress-darken-2 {
  background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-webkit-progress-value {
    background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-moz-progress-bar {
    background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-ms-fill {
    background-color: #303f9f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-2 .progress-bar {
      background-color: #303f9f; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #303f9f !important; }

.border-indigo.border-darken-2 {
  border: 1px solid #303f9f !important; }

.border-top-indigo.border-top-darken-2 {
  border-top: 1px solid #303f9f !important; }

.border-bottom-indigo.border-bottom-darken-2 {
  border-bottom: 1px solid #303f9f !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-2 {
  border-left: 1px solid #303f9f !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-2 {
  border-right: 1px solid #303f9f !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-2 {
  border-right: 1px solid #303f9f !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-2 {
  border-left: 1px solid #303f9f !important; }

.overlay-indigo.overlay-darken-2 {
  background: #303f9f;
  /* The Fallback */
  background: rgba(48, 63, 159, 0.8); }

.indigo.darken-3 {
  color: #283593 !important; }

.bg-indigo.bg-darken-3 {
  background-color: #283593 !important; }

.btn-indigo.btn-darken-3 {
  border-color: #303f9f !important;
  background-color: #283593 !important; }
  .btn-indigo.btn-darken-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-3:focus, .btn-indigo.btn-darken-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-3 {
  border-color: #283593 !important;
  color: #283593 !important; }
  .btn-outline-indigo.btn-outline-darken-3:hover {
    background-color: #283593 !important; }

.progress-indigo.progress-darken-3 {
  background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-webkit-progress-value {
    background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-moz-progress-bar {
    background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-ms-fill {
    background-color: #283593; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-3 .progress-bar {
      background-color: #283593; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #283593 !important; }

.border-indigo.border-darken-3 {
  border: 1px solid #283593 !important; }

.border-top-indigo.border-top-darken-3 {
  border-top: 1px solid #283593 !important; }

.border-bottom-indigo.border-bottom-darken-3 {
  border-bottom: 1px solid #283593 !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-3 {
  border-left: 1px solid #283593 !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-3 {
  border-right: 1px solid #283593 !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-3 {
  border-right: 1px solid #283593 !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-3 {
  border-left: 1px solid #283593 !important; }

.overlay-indigo.overlay-darken-3 {
  background: #283593;
  /* The Fallback */
  background: rgba(40, 53, 147, 0.8); }

.indigo.darken-4 {
  color: #1a237e !important; }

.bg-indigo.bg-darken-4 {
  background-color: #1a237e !important; }

.btn-indigo.btn-darken-4 {
  border-color: #303f9f !important;
  background-color: #1a237e !important; }
  .btn-indigo.btn-darken-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-4:focus, .btn-indigo.btn-darken-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-4 {
  border-color: #1a237e !important;
  color: #1a237e !important; }
  .btn-outline-indigo.btn-outline-darken-4:hover {
    background-color: #1a237e !important; }

.progress-indigo.progress-darken-4 {
  background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-ms-fill {
    background-color: #1a237e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-4 .progress-bar {
      background-color: #1a237e; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1a237e !important; }

.border-indigo.border-darken-4 {
  border: 1px solid #1a237e !important; }

.border-top-indigo.border-top-darken-4 {
  border-top: 1px solid #1a237e !important; }

.border-bottom-indigo.border-bottom-darken-4 {
  border-bottom: 1px solid #1a237e !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-4 {
  border-left: 1px solid #1a237e !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-4 {
  border-right: 1px solid #1a237e !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-4 {
  border-right: 1px solid #1a237e !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-4 {
  border-left: 1px solid #1a237e !important; }

.overlay-indigo.overlay-darken-4 {
  background: #1a237e;
  /* The Fallback */
  background: rgba(26, 35, 126, 0.8); }

.indigo.accent-1 {
  color: #8c9eff !important; }

.bg-indigo.bg-accent-1 {
  background-color: #8c9eff !important; }

.btn-indigo.btn-accent-1 {
  border-color: #303f9f !important;
  background-color: #8c9eff !important; }
  .btn-indigo.btn-accent-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-1:focus, .btn-indigo.btn-accent-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-1 {
  border-color: #8c9eff !important;
  color: #8c9eff !important; }
  .btn-outline-indigo.btn-outline-accent-1:hover {
    background-color: #8c9eff !important; }

.progress-indigo.progress-accent-1 {
  background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-webkit-progress-value {
    background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-moz-progress-bar {
    background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-ms-fill {
    background-color: #8c9eff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-1 .progress-bar {
      background-color: #8c9eff; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8c9eff !important; }

.border-indigo.border-accent-1 {
  border: 1px solid #8c9eff !important; }

.border-top-indigo.border-top-accent-1 {
  border-top: 1px solid #8c9eff !important; }

.border-bottom-indigo.border-bottom-accent-1 {
  border-bottom: 1px solid #8c9eff !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-1 {
  border-left: 1px solid #8c9eff !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-1 {
  border-right: 1px solid #8c9eff !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-1 {
  border-right: 1px solid #8c9eff !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-1 {
  border-left: 1px solid #8c9eff !important; }

.overlay-indigo.overlay-accent-1 {
  background: #8c9eff;
  /* The Fallback */
  background: rgba(140, 158, 255, 0.8); }

.indigo.accent-2 {
  color: #536dfe !important; }

.bg-indigo.bg-accent-2 {
  background-color: #536dfe !important; }

.btn-indigo.btn-accent-2 {
  border-color: #303f9f !important;
  background-color: #536dfe !important; }
  .btn-indigo.btn-accent-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-2:focus, .btn-indigo.btn-accent-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-2 {
  border-color: #536dfe !important;
  color: #536dfe !important; }
  .btn-outline-indigo.btn-outline-accent-2:hover {
    background-color: #536dfe !important; }

.progress-indigo.progress-accent-2 {
  background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-webkit-progress-value {
    background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-moz-progress-bar {
    background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-ms-fill {
    background-color: #536dfe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-2 .progress-bar {
      background-color: #536dfe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #536dfe !important; }

.border-indigo.border-accent-2 {
  border: 1px solid #536dfe !important; }

.border-top-indigo.border-top-accent-2 {
  border-top: 1px solid #536dfe !important; }

.border-bottom-indigo.border-bottom-accent-2 {
  border-bottom: 1px solid #536dfe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-2 {
  border-left: 1px solid #536dfe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-2 {
  border-right: 1px solid #536dfe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-2 {
  border-right: 1px solid #536dfe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-2 {
  border-left: 1px solid #536dfe !important; }

.overlay-indigo.overlay-accent-2 {
  background: #536dfe;
  /* The Fallback */
  background: rgba(83, 109, 254, 0.8); }

.indigo.accent-3 {
  color: #3d5afe !important; }

.bg-indigo.bg-accent-3 {
  background-color: #3d5afe !important; }

.btn-indigo.btn-accent-3 {
  border-color: #303f9f !important;
  background-color: #3d5afe !important; }
  .btn-indigo.btn-accent-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-3:focus, .btn-indigo.btn-accent-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-3 {
  border-color: #3d5afe !important;
  color: #3d5afe !important; }
  .btn-outline-indigo.btn-outline-accent-3:hover {
    background-color: #3d5afe !important; }

.progress-indigo.progress-accent-3 {
  background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-ms-fill {
    background-color: #3d5afe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-3 .progress-bar {
      background-color: #3d5afe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3d5afe !important; }

.border-indigo.border-accent-3 {
  border: 1px solid #3d5afe !important; }

.border-top-indigo.border-top-accent-3 {
  border-top: 1px solid #3d5afe !important; }

.border-bottom-indigo.border-bottom-accent-3 {
  border-bottom: 1px solid #3d5afe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-3 {
  border-left: 1px solid #3d5afe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-3 {
  border-right: 1px solid #3d5afe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-3 {
  border-right: 1px solid #3d5afe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-3 {
  border-left: 1px solid #3d5afe !important; }

.overlay-indigo.overlay-accent-3 {
  background: #3d5afe;
  /* The Fallback */
  background: rgba(61, 90, 254, 0.8); }

.indigo.accent-4 {
  color: #304ffe !important; }

.bg-indigo.bg-accent-4 {
  background-color: #304ffe !important; }

.btn-indigo.btn-accent-4 {
  border-color: #303f9f !important;
  background-color: #304ffe !important; }
  .btn-indigo.btn-accent-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-4:focus, .btn-indigo.btn-accent-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-4 {
  border-color: #304ffe !important;
  color: #304ffe !important; }
  .btn-outline-indigo.btn-outline-accent-4:hover {
    background-color: #304ffe !important; }

.progress-indigo.progress-accent-4 {
  background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-webkit-progress-value {
    background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-moz-progress-bar {
    background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-ms-fill {
    background-color: #304ffe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-4 .progress-bar {
      background-color: #304ffe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #304ffe !important; }

.border-indigo.border-accent-4 {
  border: 1px solid #304ffe !important; }

.border-top-indigo.border-top-accent-4 {
  border-top: 1px solid #304ffe !important; }

.border-bottom-indigo.border-bottom-accent-4 {
  border-bottom: 1px solid #304ffe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-4 {
  border-left: 1px solid #304ffe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-4 {
  border-right: 1px solid #304ffe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-4 {
  border-right: 1px solid #304ffe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-4 {
  border-left: 1px solid #304ffe !important; }

.overlay-indigo.overlay-accent-4 {
  background: #304ffe;
  /* The Fallback */
  background: rgba(48, 79, 254, 0.8); }

.blue.lighten-5 {
  color: #e3f2fd !important; }

.bg-blue.bg-lighten-5 {
  background-color: #e3f2fd !important; }

.btn-blue.btn-lighten-5 {
  border-color: #1976d2 !important;
  background-color: #e3f2fd !important; }
  .btn-blue.btn-lighten-5:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-5:focus, .btn-blue.btn-lighten-5:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-5 {
  border-color: #e3f2fd !important;
  color: #e3f2fd !important; }
  .btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #e3f2fd !important; }

.progress-blue.progress-lighten-5 {
  background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e3f2fd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-5 .progress-bar {
      background-color: #e3f2fd; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e3f2fd !important; }

.border-blue.border-lighten-5 {
  border: 1px solid #e3f2fd !important; }

.border-top-blue.border-top-lighten-5 {
  border-top: 1px solid #e3f2fd !important; }

.border-bottom-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #e3f2fd !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-5 {
  border-left: 1px solid #e3f2fd !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-5 {
  border-right: 1px solid #e3f2fd !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-5 {
  border-right: 1px solid #e3f2fd !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-5 {
  border-left: 1px solid #e3f2fd !important; }

.overlay-blue.overlay-lighten-5 {
  background: #e3f2fd;
  /* The Fallback */
  background: rgba(227, 242, 253, 0.8); }

.blue.lighten-4 {
  color: #bbdefb !important; }

.bg-blue.bg-lighten-4 {
  background-color: #bbdefb !important; }

.btn-blue.btn-lighten-4 {
  border-color: #1976d2 !important;
  background-color: #bbdefb !important; }
  .btn-blue.btn-lighten-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-4:focus, .btn-blue.btn-lighten-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-4 {
  border-color: #bbdefb !important;
  color: #bbdefb !important; }
  .btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #bbdefb !important; }

.progress-blue.progress-lighten-4 {
  background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #bbdefb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-4 .progress-bar {
      background-color: #bbdefb; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bbdefb !important; }

.border-blue.border-lighten-4 {
  border: 1px solid #bbdefb !important; }

.border-top-blue.border-top-lighten-4 {
  border-top: 1px solid #bbdefb !important; }

.border-bottom-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #bbdefb !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-4 {
  border-left: 1px solid #bbdefb !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-4 {
  border-right: 1px solid #bbdefb !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-4 {
  border-right: 1px solid #bbdefb !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-4 {
  border-left: 1px solid #bbdefb !important; }

.overlay-blue.overlay-lighten-4 {
  background: #bbdefb;
  /* The Fallback */
  background: rgba(187, 222, 251, 0.8); }

.blue.lighten-3 {
  color: #90caf9 !important; }

.bg-blue.bg-lighten-3 {
  background-color: #90caf9 !important; }

.btn-blue.btn-lighten-3 {
  border-color: #1976d2 !important;
  background-color: #90caf9 !important; }
  .btn-blue.btn-lighten-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-3:focus, .btn-blue.btn-lighten-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-3 {
  border-color: #90caf9 !important;
  color: #90caf9 !important; }
  .btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90caf9 !important; }

.progress-blue.progress-lighten-3 {
  background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #90caf9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-3 .progress-bar {
      background-color: #90caf9; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90caf9 !important; }

.border-blue.border-lighten-3 {
  border: 1px solid #90caf9 !important; }

.border-top-blue.border-top-lighten-3 {
  border-top: 1px solid #90caf9 !important; }

.border-bottom-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #90caf9 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-3 {
  border-left: 1px solid #90caf9 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-3 {
  border-right: 1px solid #90caf9 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-3 {
  border-right: 1px solid #90caf9 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-3 {
  border-left: 1px solid #90caf9 !important; }

.overlay-blue.overlay-lighten-3 {
  background: #90caf9;
  /* The Fallback */
  background: rgba(144, 202, 249, 0.8); }

.blue.lighten-2 {
  color: #64b5f6 !important; }

.bg-blue.bg-lighten-2 {
  background-color: #64b5f6 !important; }

.btn-blue.btn-lighten-2 {
  border-color: #1976d2 !important;
  background-color: #64b5f6 !important; }
  .btn-blue.btn-lighten-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-2:focus, .btn-blue.btn-lighten-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-2 {
  border-color: #64b5f6 !important;
  color: #64b5f6 !important; }
  .btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64b5f6 !important; }

.progress-blue.progress-lighten-2 {
  background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #64b5f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-2 .progress-bar {
      background-color: #64b5f6; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64b5f6 !important; }

.border-blue.border-lighten-2 {
  border: 1px solid #64b5f6 !important; }

.border-top-blue.border-top-lighten-2 {
  border-top: 1px solid #64b5f6 !important; }

.border-bottom-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #64b5f6 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-2 {
  border-left: 1px solid #64b5f6 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-2 {
  border-right: 1px solid #64b5f6 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-2 {
  border-right: 1px solid #64b5f6 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-2 {
  border-left: 1px solid #64b5f6 !important; }

.overlay-blue.overlay-lighten-2 {
  background: #64b5f6;
  /* The Fallback */
  background: rgba(100, 181, 246, 0.8); }

.blue.lighten-1 {
  color: #42a5f5 !important; }

.bg-blue.bg-lighten-1 {
  background-color: #42a5f5 !important; }

.btn-blue.btn-lighten-1 {
  border-color: #1976d2 !important;
  background-color: #42a5f5 !important; }
  .btn-blue.btn-lighten-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-1:focus, .btn-blue.btn-lighten-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-1 {
  border-color: #42a5f5 !important;
  color: #42a5f5 !important; }
  .btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42a5f5 !important; }

.progress-blue.progress-lighten-1 {
  background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #42a5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-1 .progress-bar {
      background-color: #42a5f5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42a5f5 !important; }

.border-blue.border-lighten-1 {
  border: 1px solid #42a5f5 !important; }

.border-top-blue.border-top-lighten-1 {
  border-top: 1px solid #42a5f5 !important; }

.border-bottom-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #42a5f5 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-1 {
  border-left: 1px solid #42a5f5 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-1 {
  border-right: 1px solid #42a5f5 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-1 {
  border-right: 1px solid #42a5f5 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-1 {
  border-left: 1px solid #42a5f5 !important; }

.overlay-blue.overlay-lighten-1 {
  background: #42a5f5;
  /* The Fallback */
  background: rgba(66, 165, 245, 0.8); }

.blue {
  color: #2196f3 !important; }

.bg-blue {
  background-color: #2196f3 !important; }
  .bg-blue .card-header, .bg-blue .card-footer {
    background-color: transparent; }

.toast-blue {
  background-color: #2196f3; }

.alert-blue {
  border-color: #2196f3 !important;
  background-color: #43a6f5 !important;
  color: #05365d !important; }
  .alert-blue .alert-link {
    color: #031f36 !important; }

.border-blue {
  border-color: #2196f3; }

.border-top-blue {
  border-top-color: #2196f3; }

.border-bottom-blue {
  border-bottom-color: #2196f3; }

[dir="ltr"] .border-left-blue {
  border-left-color: #2196f3; }

[dir="ltr"] .border-right-blue {
  border-right-color: #2196f3; }

[dir="rtl"] .border-left-blue {
  border-right-color: #2196f3; }

[dir="rtl"] .border-right-blue {
  border-left-color: #2196f3; }

.badge-blue {
  background-color: #2196f3; }

.panel-blue {
  border-color: #2196f3; }
  .panel-blue .panel-heading {
    color: #FFF;
    border-color: #2196f3;
    background-color: #39a1f4; }

.bg-blue.tag-glow, .border-blue.tag-glow {
  box-shadow: 0px 0px 10px #2196f3; }

.overlay-blue {
  background: #2196f3;
  /* The Fallback */
  background: rgba(33, 150, 243, 0.8); }

.card.card-outline-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #2196f3;
  background-color: transparent; }
  .card.card-outline-blue .card-header, .card.card-outline-blue .card-footer {
    background-color: transparent; }

.btn-blue.btn-flat {
  background-color: transparent !important;
  color: #2196f3;
  border: none; }

.btn-blue.btn-raised, .btn-blue.btn-fab {
  background-color: #2196f3 !important;
  color: #fff !important;
  border-color: #2196f3; }
  .btn-blue.btn-raised.active, .btn-blue.btn-fab.active {
    background-color: #0c84e4 !important;
    border-color: #0c84e4 !important; }

.btn-group-raised .btn-blue {
  background-color: #2196f3 !important;
  color: #fff !important; }

.btn-outline-blue {
  border: 1px solid;
  border-color: #2196f3;
  background-color: transparent;
  color: #2196f3;
  box-shadow: none !important; }
  .btn-outline-blue:focus {
    background-color: transparent !important;
    color: #2196f3 !important;
    box-shadow: transparent !important; }
  .btn-outline-blue.active {
    background-color: #2196f3 !important;
    color: #FFF !important; }
  .btn-outline-blue:hover {
    background-color: #128ff2 !important;
    color: #FFF !important; }
    .btn-outline-blue:hover svg {
      color: #FFF !important; }

.btn-outline-blue.btn-raised, .btn-outline-blue.btn-fab {
  border: 1px solid;
  border-color: #2196f3;
  background-color: transparent;
  color: #2196f3;
  box-shadow: none !important; }
  .btn-outline-blue.btn-raised:focus, .btn-outline-blue.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-blue.btn-raised.active, .btn-outline-blue.btn-fab.active {
    background-color: #2196f3 !important;
    color: #FFF !important; }
  .btn-outline-blue.btn-raised:hover, .btn-outline-blue.btn-fab:hover {
    background-color: #128ff2 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-blue {
  background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-webkit-progress-value {
    background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-moz-progress-bar {
    background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-ms-fill {
    background-color: #2196f3; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-blue .progress-bar {
      background-color: #2196f3; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196f3 !important; }

.blue.darken-1 {
  color: #1e88e5 !important; }

.bg-blue.bg-darken-1 {
  background-color: #1e88e5 !important; }

.btn-blue.btn-darken-1 {
  border-color: #1976d2 !important;
  background-color: #1e88e5 !important; }
  .btn-blue.btn-darken-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-1:focus, .btn-blue.btn-darken-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-1 {
  border-color: #1e88e5 !important;
  color: #1e88e5 !important; }
  .btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1e88e5 !important; }

.progress-blue.progress-darken-1 {
  background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-ms-fill {
    background-color: #1e88e5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-1 .progress-bar {
      background-color: #1e88e5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1e88e5 !important; }

.border-blue.border-darken-1 {
  border: 1px solid #1e88e5 !important; }

.border-top-blue.border-top-darken-1 {
  border-top: 1px solid #1e88e5 !important; }

.border-bottom-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #1e88e5 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-1 {
  border-left: 1px solid #1e88e5 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-1 {
  border-right: 1px solid #1e88e5 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-1 {
  border-right: 1px solid #1e88e5 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-1 {
  border-left: 1px solid #1e88e5 !important; }

.overlay-blue.overlay-darken-1 {
  background: #1e88e5;
  /* The Fallback */
  background: rgba(30, 136, 229, 0.8); }

.blue.darken-2 {
  color: #1976d2 !important; }

.bg-blue.bg-darken-2 {
  background-color: #1976d2 !important; }

.btn-blue.btn-darken-2 {
  border-color: #1976d2 !important;
  background-color: #1976d2 !important; }
  .btn-blue.btn-darken-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-2:focus, .btn-blue.btn-darken-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-2 {
  border-color: #1976d2 !important;
  color: #1976d2 !important; }
  .btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976d2 !important; }

.progress-blue.progress-darken-2 {
  background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-ms-fill {
    background-color: #1976d2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-2 .progress-bar {
      background-color: #1976d2; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976d2 !important; }

.border-blue.border-darken-2 {
  border: 1px solid #1976d2 !important; }

.border-top-blue.border-top-darken-2 {
  border-top: 1px solid #1976d2 !important; }

.border-bottom-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #1976d2 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-2 {
  border-left: 1px solid #1976d2 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-2 {
  border-right: 1px solid #1976d2 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-2 {
  border-right: 1px solid #1976d2 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-2 {
  border-left: 1px solid #1976d2 !important; }

.overlay-blue.overlay-darken-2 {
  background: #1976d2;
  /* The Fallback */
  background: rgba(25, 118, 210, 0.8); }

.blue.darken-3 {
  color: #1565c0 !important; }

.bg-blue.bg-darken-3 {
  background-color: #1565c0 !important; }

.btn-blue.btn-darken-3 {
  border-color: #1976d2 !important;
  background-color: #1565c0 !important; }
  .btn-blue.btn-darken-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-3:focus, .btn-blue.btn-darken-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-3 {
  border-color: #1565c0 !important;
  color: #1565c0 !important; }
  .btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565c0 !important; }

.progress-blue.progress-darken-3 {
  background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-ms-fill {
    background-color: #1565c0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-3 .progress-bar {
      background-color: #1565c0; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565c0 !important; }

.border-blue.border-darken-3 {
  border: 1px solid #1565c0 !important; }

.border-top-blue.border-top-darken-3 {
  border-top: 1px solid #1565c0 !important; }

.border-bottom-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #1565c0 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-3 {
  border-left: 1px solid #1565c0 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-3 {
  border-right: 1px solid #1565c0 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-3 {
  border-right: 1px solid #1565c0 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-3 {
  border-left: 1px solid #1565c0 !important; }

.overlay-blue.overlay-darken-3 {
  background: #1565c0;
  /* The Fallback */
  background: rgba(21, 101, 192, 0.8); }

.blue.darken-4 {
  color: #0d47a1 !important; }

.bg-blue.bg-darken-4 {
  background-color: #0d47a1 !important; }

.btn-blue.btn-darken-4 {
  border-color: #1976d2 !important;
  background-color: #0d47a1 !important; }
  .btn-blue.btn-darken-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-4:focus, .btn-blue.btn-darken-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-4 {
  border-color: #0d47a1 !important;
  color: #0d47a1 !important; }
  .btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0d47a1 !important; }

.progress-blue.progress-darken-4 {
  background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-ms-fill {
    background-color: #0d47a1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-4 .progress-bar {
      background-color: #0d47a1; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0d47a1 !important; }

.border-blue.border-darken-4 {
  border: 1px solid #0d47a1 !important; }

.border-top-blue.border-top-darken-4 {
  border-top: 1px solid #0d47a1 !important; }

.border-bottom-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #0d47a1 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-4 {
  border-left: 1px solid #0d47a1 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-4 {
  border-right: 1px solid #0d47a1 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-4 {
  border-right: 1px solid #0d47a1 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-4 {
  border-left: 1px solid #0d47a1 !important; }

.overlay-blue.overlay-darken-4 {
  background: #0d47a1;
  /* The Fallback */
  background: rgba(13, 71, 161, 0.8); }

.blue.accent-1 {
  color: #82b1ff !important; }

.bg-blue.bg-accent-1 {
  background-color: #82b1ff !important; }

.btn-blue.btn-accent-1 {
  border-color: #1976d2 !important;
  background-color: #82b1ff !important; }
  .btn-blue.btn-accent-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-1:focus, .btn-blue.btn-accent-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-1 {
  border-color: #82b1ff !important;
  color: #82b1ff !important; }
  .btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82b1ff !important; }

.progress-blue.progress-accent-1 {
  background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-ms-fill {
    background-color: #82b1ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-1 .progress-bar {
      background-color: #82b1ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82b1ff !important; }

.border-blue.border-accent-1 {
  border: 1px solid #82b1ff !important; }

.border-top-blue.border-top-accent-1 {
  border-top: 1px solid #82b1ff !important; }

.border-bottom-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #82b1ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-1 {
  border-left: 1px solid #82b1ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-1 {
  border-right: 1px solid #82b1ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-1 {
  border-right: 1px solid #82b1ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-1 {
  border-left: 1px solid #82b1ff !important; }

.overlay-blue.overlay-accent-1 {
  background: #82b1ff;
  /* The Fallback */
  background: rgba(130, 177, 255, 0.8); }

.blue.accent-2 {
  color: #448aff !important; }

.bg-blue.bg-accent-2 {
  background-color: #448aff !important; }

.btn-blue.btn-accent-2 {
  border-color: #1976d2 !important;
  background-color: #448aff !important; }
  .btn-blue.btn-accent-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-2:focus, .btn-blue.btn-accent-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-2 {
  border-color: #448aff !important;
  color: #448aff !important; }
  .btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448aff !important; }

.progress-blue.progress-accent-2 {
  background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-ms-fill {
    background-color: #448aff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-2 .progress-bar {
      background-color: #448aff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448aff !important; }

.border-blue.border-accent-2 {
  border: 1px solid #448aff !important; }

.border-top-blue.border-top-accent-2 {
  border-top: 1px solid #448aff !important; }

.border-bottom-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #448aff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-2 {
  border-left: 1px solid #448aff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-2 {
  border-right: 1px solid #448aff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-2 {
  border-right: 1px solid #448aff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-2 {
  border-left: 1px solid #448aff !important; }

.overlay-blue.overlay-accent-2 {
  background: #448aff;
  /* The Fallback */
  background: rgba(68, 138, 255, 0.8); }

.blue.accent-3 {
  color: #2979ff !important; }

.bg-blue.bg-accent-3 {
  background-color: #2979ff !important; }

.btn-blue.btn-accent-3 {
  border-color: #1976d2 !important;
  background-color: #2979ff !important; }
  .btn-blue.btn-accent-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-3:focus, .btn-blue.btn-accent-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-3 {
  border-color: #2979ff !important;
  color: #2979ff !important; }
  .btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979ff !important; }

.progress-blue.progress-accent-3 {
  background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-ms-fill {
    background-color: #2979ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-3 .progress-bar {
      background-color: #2979ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979ff !important; }

.border-blue.border-accent-3 {
  border: 1px solid #2979ff !important; }

.border-top-blue.border-top-accent-3 {
  border-top: 1px solid #2979ff !important; }

.border-bottom-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #2979ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-3 {
  border-left: 1px solid #2979ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-3 {
  border-right: 1px solid #2979ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-3 {
  border-right: 1px solid #2979ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-3 {
  border-left: 1px solid #2979ff !important; }

.overlay-blue.overlay-accent-3 {
  background: #2979ff;
  /* The Fallback */
  background: rgba(41, 121, 255, 0.8); }

.blue.accent-4 {
  color: #2962ff !important; }

.bg-blue.bg-accent-4 {
  background-color: #2962ff !important; }

.btn-blue.btn-accent-4 {
  border-color: #1976d2 !important;
  background-color: #2962ff !important; }
  .btn-blue.btn-accent-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-4:focus, .btn-blue.btn-accent-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-4 {
  border-color: #2962ff !important;
  color: #2962ff !important; }
  .btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962ff !important; }

.progress-blue.progress-accent-4 {
  background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-ms-fill {
    background-color: #2962ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-4 .progress-bar {
      background-color: #2962ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962ff !important; }

.border-blue.border-accent-4 {
  border: 1px solid #2962ff !important; }

.border-top-blue.border-top-accent-4 {
  border-top: 1px solid #2962ff !important; }

.border-bottom-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #2962ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-4 {
  border-left: 1px solid #2962ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-4 {
  border-right: 1px solid #2962ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-4 {
  border-right: 1px solid #2962ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-4 {
  border-left: 1px solid #2962ff !important; }

.overlay-blue.overlay-accent-4 {
  background: #2962ff;
  /* The Fallback */
  background: rgba(41, 98, 255, 0.8); }

.light-blue.lighten-5 {
  color: #e1f5fe !important; }

.bg-light-blue.bg-lighten-5 {
  background-color: #e1f5fe !important; }

.btn-light-blue.btn-lighten-5 {
  border-color: #0288d1 !important;
  background-color: #e1f5fe !important; }
  .btn-light-blue.btn-lighten-5:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-5:focus, .btn-light-blue.btn-lighten-5:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-5 {
  border-color: #e1f5fe !important;
  color: #e1f5fe !important; }
  .btn-outline-light-blue.btn-outline-lighten-5:hover {
    background-color: #e1f5fe !important; }

.progress-light-blue.progress-lighten-5 {
  background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e1f5fe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-5 .progress-bar {
      background-color: #e1f5fe; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1f5fe !important; }

.border-light-blue.border-lighten-5 {
  border: 1px solid #e1f5fe !important; }

.border-top-light-blue.border-top-lighten-5 {
  border-top: 1px solid #e1f5fe !important; }

.border-bottom-light-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #e1f5fe !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-5 {
  border-left: 1px solid #e1f5fe !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-5 {
  border-right: 1px solid #e1f5fe !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-5 {
  border-right: 1px solid #e1f5fe !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-5 {
  border-left: 1px solid #e1f5fe !important; }

.overlay-light-blue.overlay-lighten-5 {
  background: #e1f5fe;
  /* The Fallback */
  background: rgba(225, 245, 254, 0.8); }

.light-blue.lighten-4 {
  color: #b3e5fc !important; }

.bg-light-blue.bg-lighten-4 {
  background-color: #b3e5fc !important; }

.btn-light-blue.btn-lighten-4 {
  border-color: #0288d1 !important;
  background-color: #b3e5fc !important; }
  .btn-light-blue.btn-lighten-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-4:focus, .btn-light-blue.btn-lighten-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-4 {
  border-color: #b3e5fc !important;
  color: #b3e5fc !important; }
  .btn-outline-light-blue.btn-outline-lighten-4:hover {
    background-color: #b3e5fc !important; }

.progress-light-blue.progress-lighten-4 {
  background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #b3e5fc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-4 .progress-bar {
      background-color: #b3e5fc; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b3e5fc !important; }

.border-light-blue.border-lighten-4 {
  border: 1px solid #b3e5fc !important; }

.border-top-light-blue.border-top-lighten-4 {
  border-top: 1px solid #b3e5fc !important; }

.border-bottom-light-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #b3e5fc !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-4 {
  border-left: 1px solid #b3e5fc !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-4 {
  border-right: 1px solid #b3e5fc !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-4 {
  border-right: 1px solid #b3e5fc !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-4 {
  border-left: 1px solid #b3e5fc !important; }

.overlay-light-blue.overlay-lighten-4 {
  background: #b3e5fc;
  /* The Fallback */
  background: rgba(179, 229, 252, 0.8); }

.light-blue.lighten-3 {
  color: #81d4fa !important; }

.bg-light-blue.bg-lighten-3 {
  background-color: #81d4fa !important; }

.btn-light-blue.btn-lighten-3 {
  border-color: #0288d1 !important;
  background-color: #81d4fa !important; }
  .btn-light-blue.btn-lighten-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-3:focus, .btn-light-blue.btn-lighten-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-3 {
  border-color: #81d4fa !important;
  color: #81d4fa !important; }
  .btn-outline-light-blue.btn-outline-lighten-3:hover {
    background-color: #81d4fa !important; }

.progress-light-blue.progress-lighten-3 {
  background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #81d4fa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-3 .progress-bar {
      background-color: #81d4fa; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81d4fa !important; }

.border-light-blue.border-lighten-3 {
  border: 1px solid #81d4fa !important; }

.border-top-light-blue.border-top-lighten-3 {
  border-top: 1px solid #81d4fa !important; }

.border-bottom-light-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #81d4fa !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-3 {
  border-left: 1px solid #81d4fa !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-3 {
  border-right: 1px solid #81d4fa !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-3 {
  border-right: 1px solid #81d4fa !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-3 {
  border-left: 1px solid #81d4fa !important; }

.overlay-light-blue.overlay-lighten-3 {
  background: #81d4fa;
  /* The Fallback */
  background: rgba(129, 212, 250, 0.8); }

.light-blue.lighten-2 {
  color: #4fc3f7 !important; }

.bg-light-blue.bg-lighten-2 {
  background-color: #4fc3f7 !important; }

.btn-light-blue.btn-lighten-2 {
  border-color: #0288d1 !important;
  background-color: #4fc3f7 !important; }
  .btn-light-blue.btn-lighten-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-2:focus, .btn-light-blue.btn-lighten-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-2 {
  border-color: #4fc3f7 !important;
  color: #4fc3f7 !important; }
  .btn-outline-light-blue.btn-outline-lighten-2:hover {
    background-color: #4fc3f7 !important; }

.progress-light-blue.progress-lighten-2 {
  background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #4fc3f7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-2 .progress-bar {
      background-color: #4fc3f7; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4fc3f7 !important; }

.border-light-blue.border-lighten-2 {
  border: 1px solid #4fc3f7 !important; }

.border-top-light-blue.border-top-lighten-2 {
  border-top: 1px solid #4fc3f7 !important; }

.border-bottom-light-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #4fc3f7 !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-2 {
  border-left: 1px solid #4fc3f7 !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-2 {
  border-right: 1px solid #4fc3f7 !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-2 {
  border-right: 1px solid #4fc3f7 !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-2 {
  border-left: 1px solid #4fc3f7 !important; }

.overlay-light-blue.overlay-lighten-2 {
  background: #4fc3f7;
  /* The Fallback */
  background: rgba(79, 195, 247, 0.8); }

.light-blue.lighten-1 {
  color: #29b6f6 !important; }

.bg-light-blue.bg-lighten-1 {
  background-color: #29b6f6 !important; }

.btn-light-blue.btn-lighten-1 {
  border-color: #0288d1 !important;
  background-color: #29b6f6 !important; }
  .btn-light-blue.btn-lighten-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-1:focus, .btn-light-blue.btn-lighten-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-1 {
  border-color: #29b6f6 !important;
  color: #29b6f6 !important; }
  .btn-outline-light-blue.btn-outline-lighten-1:hover {
    background-color: #29b6f6 !important; }

.progress-light-blue.progress-lighten-1 {
  background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #29b6f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-1 .progress-bar {
      background-color: #29b6f6; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #29b6f6 !important; }

.border-light-blue.border-lighten-1 {
  border: 1px solid #29b6f6 !important; }

.border-top-light-blue.border-top-lighten-1 {
  border-top: 1px solid #29b6f6 !important; }

.border-bottom-light-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #29b6f6 !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-1 {
  border-left: 1px solid #29b6f6 !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-1 {
  border-right: 1px solid #29b6f6 !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-1 {
  border-right: 1px solid #29b6f6 !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-1 {
  border-left: 1px solid #29b6f6 !important; }

.overlay-light-blue.overlay-lighten-1 {
  background: #29b6f6;
  /* The Fallback */
  background: rgba(41, 182, 246, 0.8); }

.light-blue {
  color: #03a9f4 !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }
  .bg-light-blue .card-header, .bg-light-blue .card-footer {
    background-color: transparent; }

.toast-light-blue {
  background-color: #03a9f4; }

.alert-light-blue {
  border-color: #03a9f4 !important;
  background-color: #1eb7fc !important;
  color: #012f44 !important; }
  .alert-light-blue .alert-link {
    color: #00131b !important; }

.border-light-blue {
  border-color: #03a9f4; }

.border-top-light-blue {
  border-top-color: #03a9f4; }

.border-bottom-light-blue {
  border-bottom-color: #03a9f4; }

[dir="ltr"] .border-left-light-blue {
  border-left-color: #03a9f4; }

[dir="ltr"] .border-right-light-blue {
  border-right-color: #03a9f4; }

[dir="rtl"] .border-left-light-blue {
  border-right-color: #03a9f4; }

[dir="rtl"] .border-right-light-blue {
  border-left-color: #03a9f4; }

.badge-light-blue {
  background-color: #03a9f4; }

.panel-light-blue {
  border-color: #03a9f4; }
  .panel-light-blue .panel-heading {
    color: #FFF;
    border-color: #03a9f4;
    background-color: #14b4fc; }

.bg-light-blue.tag-glow, .border-light-blue.tag-glow {
  box-shadow: 0px 0px 10px #03a9f4; }

.overlay-light-blue {
  background: #03a9f4;
  /* The Fallback */
  background: rgba(3, 169, 244, 0.8); }

.card.card-outline-light-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #03a9f4;
  background-color: transparent; }
  .card.card-outline-light-blue .card-header, .card.card-outline-light-blue .card-footer {
    background-color: transparent; }

.btn-light-blue.btn-flat {
  background-color: transparent !important;
  color: #03a9f4;
  border: none; }

.btn-light-blue.btn-raised, .btn-light-blue.btn-fab {
  background-color: #03a9f4 !important;
  color: #fff !important;
  border-color: #03a9f4; }
  .btn-light-blue.btn-raised.active, .btn-light-blue.btn-fab.active {
    background-color: #0391d1 !important;
    border-color: #0391d1 !important; }

.btn-group-raised .btn-light-blue {
  background-color: #03a9f4 !important;
  color: #fff !important; }

.btn-outline-light-blue {
  border: 1px solid;
  border-color: #03a9f4;
  background-color: transparent;
  color: #03a9f4;
  box-shadow: none !important; }
  .btn-outline-light-blue:focus {
    background-color: transparent !important;
    color: #03a9f4 !important;
    box-shadow: transparent !important; }
  .btn-outline-light-blue.active {
    background-color: #03a9f4 !important;
    color: #FFF !important; }
  .btn-outline-light-blue:hover {
    background-color: #039fe5 !important;
    color: #FFF !important; }
    .btn-outline-light-blue:hover svg {
      color: #FFF !important; }

.btn-outline-light-blue.btn-raised, .btn-outline-light-blue.btn-fab {
  border: 1px solid;
  border-color: #03a9f4;
  background-color: transparent;
  color: #03a9f4;
  box-shadow: none !important; }
  .btn-outline-light-blue.btn-raised:focus, .btn-outline-light-blue.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-light-blue.btn-raised.active, .btn-outline-light-blue.btn-fab.active {
    background-color: #03a9f4 !important;
    color: #FFF !important; }
  .btn-outline-light-blue.btn-raised:hover, .btn-outline-light-blue.btn-fab:hover {
    background-color: #039fe5 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-light-blue {
  background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-webkit-progress-value {
    background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-moz-progress-bar {
    background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-ms-fill {
    background-color: #03a9f4; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-light-blue .progress-bar {
      background-color: #03a9f4; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #03a9f4 !important; }

.light-blue.darken-1 {
  color: #039be5 !important; }

.bg-light-blue.bg-darken-1 {
  background-color: #039be5 !important; }

.btn-light-blue.btn-darken-1 {
  border-color: #0288d1 !important;
  background-color: #039be5 !important; }
  .btn-light-blue.btn-darken-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-1:focus, .btn-light-blue.btn-darken-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-1 {
  border-color: #039be5 !important;
  color: #039be5 !important; }
  .btn-outline-light-blue.btn-outline-darken-1:hover {
    background-color: #039be5 !important; }

.progress-light-blue.progress-darken-1 {
  background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-ms-fill {
    background-color: #039be5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-1 .progress-bar {
      background-color: #039be5; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039be5 !important; }

.border-light-blue.border-darken-1 {
  border: 1px solid #039be5 !important; }

.border-top-light-blue.border-top-darken-1 {
  border-top: 1px solid #039be5 !important; }

.border-bottom-light-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #039be5 !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-1 {
  border-left: 1px solid #039be5 !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-1 {
  border-right: 1px solid #039be5 !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-1 {
  border-right: 1px solid #039be5 !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-1 {
  border-left: 1px solid #039be5 !important; }

.overlay-light-blue.overlay-darken-1 {
  background: #039be5;
  /* The Fallback */
  background: rgba(3, 155, 229, 0.8); }

.light-blue.darken-2 {
  color: #0288d1 !important; }

.bg-light-blue.bg-darken-2 {
  background-color: #0288d1 !important; }

.btn-light-blue.btn-darken-2 {
  border-color: #0288d1 !important;
  background-color: #0288d1 !important; }
  .btn-light-blue.btn-darken-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-2:focus, .btn-light-blue.btn-darken-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-2 {
  border-color: #0288d1 !important;
  color: #0288d1 !important; }
  .btn-outline-light-blue.btn-outline-darken-2:hover {
    background-color: #0288d1 !important; }

.progress-light-blue.progress-darken-2 {
  background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-ms-fill {
    background-color: #0288d1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-2 .progress-bar {
      background-color: #0288d1; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0288d1 !important; }

.border-light-blue.border-darken-2 {
  border: 1px solid #0288d1 !important; }

.border-top-light-blue.border-top-darken-2 {
  border-top: 1px solid #0288d1 !important; }

.border-bottom-light-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #0288d1 !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-2 {
  border-left: 1px solid #0288d1 !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-2 {
  border-right: 1px solid #0288d1 !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-2 {
  border-right: 1px solid #0288d1 !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-2 {
  border-left: 1px solid #0288d1 !important; }

.overlay-light-blue.overlay-darken-2 {
  background: #0288d1;
  /* The Fallback */
  background: rgba(2, 136, 209, 0.8); }

.light-blue.darken-3 {
  color: #0277bd !important; }

.bg-light-blue.bg-darken-3 {
  background-color: #0277bd !important; }

.btn-light-blue.btn-darken-3 {
  border-color: #0288d1 !important;
  background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-3:focus, .btn-light-blue.btn-darken-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-3 {
  border-color: #0277bd !important;
  color: #0277bd !important; }
  .btn-outline-light-blue.btn-outline-darken-3:hover {
    background-color: #0277bd !important; }

.progress-light-blue.progress-darken-3 {
  background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-ms-fill {
    background-color: #0277bd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-3 .progress-bar {
      background-color: #0277bd; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0277bd !important; }

.border-light-blue.border-darken-3 {
  border: 1px solid #0277bd !important; }

.border-top-light-blue.border-top-darken-3 {
  border-top: 1px solid #0277bd !important; }

.border-bottom-light-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #0277bd !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-3 {
  border-left: 1px solid #0277bd !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-3 {
  border-right: 1px solid #0277bd !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-3 {
  border-right: 1px solid #0277bd !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-3 {
  border-left: 1px solid #0277bd !important; }

.overlay-light-blue.overlay-darken-3 {
  background: #0277bd;
  /* The Fallback */
  background: rgba(2, 119, 189, 0.8); }

.light-blue.darken-4 {
  color: #01579b !important; }

.bg-light-blue.bg-darken-4 {
  background-color: #01579b !important; }

.btn-light-blue.btn-darken-4 {
  border-color: #0288d1 !important;
  background-color: #01579b !important; }
  .btn-light-blue.btn-darken-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-4:focus, .btn-light-blue.btn-darken-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-4 {
  border-color: #01579b !important;
  color: #01579b !important; }
  .btn-outline-light-blue.btn-outline-darken-4:hover {
    background-color: #01579b !important; }

.progress-light-blue.progress-darken-4 {
  background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-ms-fill {
    background-color: #01579b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-4 .progress-bar {
      background-color: #01579b; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #01579b !important; }

.border-light-blue.border-darken-4 {
  border: 1px solid #01579b !important; }

.border-top-light-blue.border-top-darken-4 {
  border-top: 1px solid #01579b !important; }

.border-bottom-light-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #01579b !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-4 {
  border-left: 1px solid #01579b !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-4 {
  border-right: 1px solid #01579b !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-4 {
  border-right: 1px solid #01579b !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-4 {
  border-left: 1px solid #01579b !important; }

.overlay-light-blue.overlay-darken-4 {
  background: #01579b;
  /* The Fallback */
  background: rgba(1, 87, 155, 0.8); }

.light-blue.accent-1 {
  color: #80d8ff !important; }

.bg-light-blue.bg-accent-1 {
  background-color: #80d8ff !important; }

.btn-light-blue.btn-accent-1 {
  border-color: #0288d1 !important;
  background-color: #80d8ff !important; }
  .btn-light-blue.btn-accent-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-1:focus, .btn-light-blue.btn-accent-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-1 {
  border-color: #80d8ff !important;
  color: #80d8ff !important; }
  .btn-outline-light-blue.btn-outline-accent-1:hover {
    background-color: #80d8ff !important; }

.progress-light-blue.progress-accent-1 {
  background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-ms-fill {
    background-color: #80d8ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-1 .progress-bar {
      background-color: #80d8ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80d8ff !important; }

.border-light-blue.border-accent-1 {
  border: 1px solid #80d8ff !important; }

.border-top-light-blue.border-top-accent-1 {
  border-top: 1px solid #80d8ff !important; }

.border-bottom-light-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #80d8ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-1 {
  border-left: 1px solid #80d8ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-1 {
  border-right: 1px solid #80d8ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-1 {
  border-right: 1px solid #80d8ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-1 {
  border-left: 1px solid #80d8ff !important; }

.overlay-light-blue.overlay-accent-1 {
  background: #80d8ff;
  /* The Fallback */
  background: rgba(128, 216, 255, 0.8); }

.light-blue.accent-2 {
  color: #40c4ff !important; }

.bg-light-blue.bg-accent-2 {
  background-color: #40c4ff !important; }

.btn-light-blue.btn-accent-2 {
  border-color: #0288d1 !important;
  background-color: #40c4ff !important; }
  .btn-light-blue.btn-accent-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-2:focus, .btn-light-blue.btn-accent-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-2 {
  border-color: #40c4ff !important;
  color: #40c4ff !important; }
  .btn-outline-light-blue.btn-outline-accent-2:hover {
    background-color: #40c4ff !important; }

.progress-light-blue.progress-accent-2 {
  background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-ms-fill {
    background-color: #40c4ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-2 .progress-bar {
      background-color: #40c4ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #40c4ff !important; }

.border-light-blue.border-accent-2 {
  border: 1px solid #40c4ff !important; }

.border-top-light-blue.border-top-accent-2 {
  border-top: 1px solid #40c4ff !important; }

.border-bottom-light-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #40c4ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-2 {
  border-left: 1px solid #40c4ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-2 {
  border-right: 1px solid #40c4ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-2 {
  border-right: 1px solid #40c4ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-2 {
  border-left: 1px solid #40c4ff !important; }

.overlay-light-blue.overlay-accent-2 {
  background: #40c4ff;
  /* The Fallback */
  background: rgba(64, 196, 255, 0.8); }

.light-blue.accent-3 {
  color: #00b0ff !important; }

.bg-light-blue.bg-accent-3 {
  background-color: #00b0ff !important; }

.btn-light-blue.btn-accent-3 {
  border-color: #0288d1 !important;
  background-color: #00b0ff !important; }
  .btn-light-blue.btn-accent-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-3:focus, .btn-light-blue.btn-accent-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-3 {
  border-color: #00b0ff !important;
  color: #00b0ff !important; }
  .btn-outline-light-blue.btn-outline-accent-3:hover {
    background-color: #00b0ff !important; }

.progress-light-blue.progress-accent-3 {
  background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-ms-fill {
    background-color: #00b0ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-3 .progress-bar {
      background-color: #00b0ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b0ff !important; }

.border-light-blue.border-accent-3 {
  border: 1px solid #00b0ff !important; }

.border-top-light-blue.border-top-accent-3 {
  border-top: 1px solid #00b0ff !important; }

.border-bottom-light-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #00b0ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-3 {
  border-left: 1px solid #00b0ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-3 {
  border-right: 1px solid #00b0ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-3 {
  border-right: 1px solid #00b0ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-3 {
  border-left: 1px solid #00b0ff !important; }

.overlay-light-blue.overlay-accent-3 {
  background: #00b0ff;
  /* The Fallback */
  background: rgba(0, 176, 255, 0.8); }

.light-blue.accent-4 {
  color: #0091ea !important; }

.bg-light-blue.bg-accent-4 {
  background-color: #0091ea !important; }

.btn-light-blue.btn-accent-4 {
  border-color: #0288d1 !important;
  background-color: #0091ea !important; }
  .btn-light-blue.btn-accent-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-4:focus, .btn-light-blue.btn-accent-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-4 {
  border-color: #0091ea !important;
  color: #0091ea !important; }
  .btn-outline-light-blue.btn-outline-accent-4:hover {
    background-color: #0091ea !important; }

.progress-light-blue.progress-accent-4 {
  background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-ms-fill {
    background-color: #0091ea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-4 .progress-bar {
      background-color: #0091ea; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0091ea !important; }

.border-light-blue.border-accent-4 {
  border: 1px solid #0091ea !important; }

.border-top-light-blue.border-top-accent-4 {
  border-top: 1px solid #0091ea !important; }

.border-bottom-light-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #0091ea !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-4 {
  border-left: 1px solid #0091ea !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-4 {
  border-right: 1px solid #0091ea !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-4 {
  border-right: 1px solid #0091ea !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-4 {
  border-left: 1px solid #0091ea !important; }

.overlay-light-blue.overlay-accent-4 {
  background: #0091ea;
  /* The Fallback */
  background: rgba(0, 145, 234, 0.8); }

.cyan.lighten-5 {
  color: #e0f7fa !important; }

.bg-cyan.bg-lighten-5 {
  background-color: #e0f7fa !important; }

.btn-cyan.btn-lighten-5 {
  border-color: #0097a7 !important;
  background-color: #e0f7fa !important; }
  .btn-cyan.btn-lighten-5:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-5:focus, .btn-cyan.btn-lighten-5:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-5 {
  border-color: #e0f7fa !important;
  color: #e0f7fa !important; }
  .btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #e0f7fa !important; }

.progress-cyan.progress-lighten-5 {
  background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f7fa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-5 .progress-bar {
      background-color: #e0f7fa; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f7fa !important; }

.border-cyan.border-lighten-5 {
  border: 1px solid #e0f7fa !important; }

.border-top-cyan.border-top-lighten-5 {
  border-top: 1px solid #e0f7fa !important; }

.border-bottom-cyan.border-bottom-lighten-5 {
  border-bottom: 1px solid #e0f7fa !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-5 {
  border-left: 1px solid #e0f7fa !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-5 {
  border-right: 1px solid #e0f7fa !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-5 {
  border-right: 1px solid #e0f7fa !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-5 {
  border-left: 1px solid #e0f7fa !important; }

.overlay-cyan.overlay-lighten-5 {
  background: #e0f7fa;
  /* The Fallback */
  background: rgba(224, 247, 250, 0.8); }

.cyan.lighten-4 {
  color: #b2ebf2 !important; }

.bg-cyan.bg-lighten-4 {
  background-color: #b2ebf2 !important; }

.btn-cyan.btn-lighten-4 {
  border-color: #0097a7 !important;
  background-color: #b2ebf2 !important; }
  .btn-cyan.btn-lighten-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-4:focus, .btn-cyan.btn-lighten-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-4 {
  border-color: #b2ebf2 !important;
  color: #b2ebf2 !important; }
  .btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #b2ebf2 !important; }

.progress-cyan.progress-lighten-4 {
  background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-ms-fill {
    background-color: #b2ebf2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-4 .progress-bar {
      background-color: #b2ebf2; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ebf2 !important; }

.border-cyan.border-lighten-4 {
  border: 1px solid #b2ebf2 !important; }

.border-top-cyan.border-top-lighten-4 {
  border-top: 1px solid #b2ebf2 !important; }

.border-bottom-cyan.border-bottom-lighten-4 {
  border-bottom: 1px solid #b2ebf2 !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-4 {
  border-left: 1px solid #b2ebf2 !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-4 {
  border-right: 1px solid #b2ebf2 !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-4 {
  border-right: 1px solid #b2ebf2 !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-4 {
  border-left: 1px solid #b2ebf2 !important; }

.overlay-cyan.overlay-lighten-4 {
  background: #b2ebf2;
  /* The Fallback */
  background: rgba(178, 235, 242, 0.8); }

.cyan.lighten-3 {
  color: #80deea !important; }

.bg-cyan.bg-lighten-3 {
  background-color: #80deea !important; }

.btn-cyan.btn-lighten-3 {
  border-color: #0097a7 !important;
  background-color: #80deea !important; }
  .btn-cyan.btn-lighten-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-3:focus, .btn-cyan.btn-lighten-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-3 {
  border-color: #80deea !important;
  color: #80deea !important; }
  .btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80deea !important; }

.progress-cyan.progress-lighten-3 {
  background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-ms-fill {
    background-color: #80deea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-3 .progress-bar {
      background-color: #80deea; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80deea !important; }

.border-cyan.border-lighten-3 {
  border: 1px solid #80deea !important; }

.border-top-cyan.border-top-lighten-3 {
  border-top: 1px solid #80deea !important; }

.border-bottom-cyan.border-bottom-lighten-3 {
  border-bottom: 1px solid #80deea !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-3 {
  border-left: 1px solid #80deea !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-3 {
  border-right: 1px solid #80deea !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-3 {
  border-right: 1px solid #80deea !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-3 {
  border-left: 1px solid #80deea !important; }

.overlay-cyan.overlay-lighten-3 {
  background: #80deea;
  /* The Fallback */
  background: rgba(128, 222, 234, 0.8); }

.cyan.lighten-2 {
  color: #4dd0e1 !important; }

.bg-cyan.bg-lighten-2 {
  background-color: #4dd0e1 !important; }

.btn-cyan.btn-lighten-2 {
  border-color: #0097a7 !important;
  background-color: #4dd0e1 !important; }
  .btn-cyan.btn-lighten-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-2:focus, .btn-cyan.btn-lighten-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-2 {
  border-color: #4dd0e1 !important;
  color: #4dd0e1 !important; }
  .btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4dd0e1 !important; }

.progress-cyan.progress-lighten-2 {
  background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-ms-fill {
    background-color: #4dd0e1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-2 .progress-bar {
      background-color: #4dd0e1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4dd0e1 !important; }

.border-cyan.border-lighten-2 {
  border: 1px solid #4dd0e1 !important; }

.border-top-cyan.border-top-lighten-2 {
  border-top: 1px solid #4dd0e1 !important; }

.border-bottom-cyan.border-bottom-lighten-2 {
  border-bottom: 1px solid #4dd0e1 !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-2 {
  border-left: 1px solid #4dd0e1 !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-2 {
  border-right: 1px solid #4dd0e1 !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-2 {
  border-right: 1px solid #4dd0e1 !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-2 {
  border-left: 1px solid #4dd0e1 !important; }

.overlay-cyan.overlay-lighten-2 {
  background: #4dd0e1;
  /* The Fallback */
  background: rgba(77, 208, 225, 0.8); }

.cyan.lighten-1 {
  color: #26c6da !important; }

.bg-cyan.bg-lighten-1 {
  background-color: #26c6da !important; }

.btn-cyan.btn-lighten-1 {
  border-color: #0097a7 !important;
  background-color: #26c6da !important; }
  .btn-cyan.btn-lighten-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-1:focus, .btn-cyan.btn-lighten-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-1 {
  border-color: #26c6da !important;
  color: #26c6da !important; }
  .btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26c6da !important; }

.progress-cyan.progress-lighten-1 {
  background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-ms-fill {
    background-color: #26c6da; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-1 .progress-bar {
      background-color: #26c6da; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26c6da !important; }

.border-cyan.border-lighten-1 {
  border: 1px solid #26c6da !important; }

.border-top-cyan.border-top-lighten-1 {
  border-top: 1px solid #26c6da !important; }

.border-bottom-cyan.border-bottom-lighten-1 {
  border-bottom: 1px solid #26c6da !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-1 {
  border-left: 1px solid #26c6da !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-1 {
  border-right: 1px solid #26c6da !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-1 {
  border-right: 1px solid #26c6da !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-1 {
  border-left: 1px solid #26c6da !important; }

.overlay-cyan.overlay-lighten-1 {
  background: #26c6da;
  /* The Fallback */
  background: rgba(38, 198, 218, 0.8); }

.cyan {
  color: #00bcd4 !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }
  .bg-cyan .card-header, .bg-cyan .card-footer {
    background-color: transparent; }

.toast-cyan {
  background-color: #00bcd4; }

.alert-cyan {
  border-color: #00bcd4 !important;
  background-color: #00dcf8 !important;
  color: #001e22 !important; }
  .alert-cyan .alert-link {
    color: black !important; }

.border-cyan {
  border-color: #00bcd4; }

.border-top-cyan {
  border-top-color: #00bcd4; }

.border-bottom-cyan {
  border-bottom-color: #00bcd4; }

[dir="ltr"] .border-left-cyan {
  border-left-color: #00bcd4; }

[dir="ltr"] .border-right-cyan {
  border-right-color: #00bcd4; }

[dir="rtl"] .border-left-cyan {
  border-right-color: #00bcd4; }

[dir="rtl"] .border-right-cyan {
  border-left-color: #00bcd4; }

.badge-cyan {
  background-color: #00bcd4; }

.panel-cyan {
  border-color: #00bcd4; }
  .panel-cyan .panel-heading {
    color: #FFF;
    border-color: #00bcd4;
    background-color: #00d3ee; }

.bg-cyan.tag-glow, .border-cyan.tag-glow {
  box-shadow: 0px 0px 10px #00bcd4; }

.overlay-cyan {
  background: #00bcd4;
  /* The Fallback */
  background: rgba(0, 188, 212, 0.8); }

.card.card-outline-cyan {
  border-width: 1px;
  border-style: solid;
  border-color: #00bcd4;
  background-color: transparent; }
  .card.card-outline-cyan .card-header, .card.card-outline-cyan .card-footer {
    background-color: transparent; }

.btn-cyan.btn-flat {
  background-color: transparent !important;
  color: #00bcd4;
  border: none; }

.btn-cyan.btn-raised, .btn-cyan.btn-fab {
  background-color: #00bcd4 !important;
  color: #fff !important;
  border-color: #00bcd4; }
  .btn-cyan.btn-raised.active, .btn-cyan.btn-fab.active {
    background-color: #009cb0 !important;
    border-color: #009cb0 !important; }

.btn-group-raised .btn-cyan {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.btn-outline-cyan {
  border: 1px solid;
  border-color: #00bcd4;
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none !important; }
  .btn-outline-cyan:focus {
    background-color: transparent !important;
    color: #00bcd4 !important;
    box-shadow: transparent !important; }
  .btn-outline-cyan.active {
    background-color: #00bcd4 !important;
    color: #FFF !important; }
  .btn-outline-cyan:hover {
    background-color: #00aec5 !important;
    color: #FFF !important; }
    .btn-outline-cyan:hover svg {
      color: #FFF !important; }

.btn-outline-cyan.btn-raised, .btn-outline-cyan.btn-fab {
  border: 1px solid;
  border-color: #00bcd4;
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none !important; }
  .btn-outline-cyan.btn-raised:focus, .btn-outline-cyan.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-cyan.btn-raised.active, .btn-outline-cyan.btn-fab.active {
    background-color: #00bcd4 !important;
    color: #FFF !important; }
  .btn-outline-cyan.btn-raised:hover, .btn-outline-cyan.btn-fab:hover {
    background-color: #00aec5 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-cyan {
  background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-webkit-progress-value {
    background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-moz-progress-bar {
    background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-ms-fill {
    background-color: #00bcd4; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-cyan .progress-bar {
      background-color: #00bcd4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bcd4 !important; }

.cyan.darken-1 {
  color: #00acc1 !important; }

.bg-cyan.bg-darken-1 {
  background-color: #00acc1 !important; }

.btn-cyan.btn-darken-1 {
  border-color: #0097a7 !important;
  background-color: #00acc1 !important; }
  .btn-cyan.btn-darken-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-1:focus, .btn-cyan.btn-darken-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-1 {
  border-color: #00acc1 !important;
  color: #00acc1 !important; }
  .btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00acc1 !important; }

.progress-cyan.progress-darken-1 {
  background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-ms-fill {
    background-color: #00acc1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-1 .progress-bar {
      background-color: #00acc1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00acc1 !important; }

.border-cyan.border-darken-1 {
  border: 1px solid #00acc1 !important; }

.border-top-cyan.border-top-darken-1 {
  border-top: 1px solid #00acc1 !important; }

.border-bottom-cyan.border-bottom-darken-1 {
  border-bottom: 1px solid #00acc1 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-1 {
  border-left: 1px solid #00acc1 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-1 {
  border-right: 1px solid #00acc1 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-1 {
  border-right: 1px solid #00acc1 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-1 {
  border-left: 1px solid #00acc1 !important; }

.overlay-cyan.overlay-darken-1 {
  background: #00acc1;
  /* The Fallback */
  background: rgba(0, 172, 193, 0.8); }

.cyan.darken-2 {
  color: #0097a7 !important; }

.bg-cyan.bg-darken-2 {
  background-color: #0097a7 !important; }

.btn-cyan.btn-darken-2 {
  border-color: #0097a7 !important;
  background-color: #0097a7 !important; }
  .btn-cyan.btn-darken-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-2:focus, .btn-cyan.btn-darken-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-2 {
  border-color: #0097a7 !important;
  color: #0097a7 !important; }
  .btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097a7 !important; }

.progress-cyan.progress-darken-2 {
  background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-ms-fill {
    background-color: #0097a7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-2 .progress-bar {
      background-color: #0097a7; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097a7 !important; }

.border-cyan.border-darken-2 {
  border: 1px solid #0097a7 !important; }

.border-top-cyan.border-top-darken-2 {
  border-top: 1px solid #0097a7 !important; }

.border-bottom-cyan.border-bottom-darken-2 {
  border-bottom: 1px solid #0097a7 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-2 {
  border-left: 1px solid #0097a7 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-2 {
  border-right: 1px solid #0097a7 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-2 {
  border-right: 1px solid #0097a7 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-2 {
  border-left: 1px solid #0097a7 !important; }

.overlay-cyan.overlay-darken-2 {
  background: #0097a7;
  /* The Fallback */
  background: rgba(0, 151, 167, 0.8); }

.cyan.darken-3 {
  color: #00838f !important; }

.bg-cyan.bg-darken-3 {
  background-color: #00838f !important; }

.btn-cyan.btn-darken-3 {
  border-color: #0097a7 !important;
  background-color: #00838f !important; }
  .btn-cyan.btn-darken-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-3:focus, .btn-cyan.btn-darken-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-3 {
  border-color: #00838f !important;
  color: #00838f !important; }
  .btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838f !important; }

.progress-cyan.progress-darken-3 {
  background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-ms-fill {
    background-color: #00838f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-3 .progress-bar {
      background-color: #00838f; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838f !important; }

.border-cyan.border-darken-3 {
  border: 1px solid #00838f !important; }

.border-top-cyan.border-top-darken-3 {
  border-top: 1px solid #00838f !important; }

.border-bottom-cyan.border-bottom-darken-3 {
  border-bottom: 1px solid #00838f !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-3 {
  border-left: 1px solid #00838f !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-3 {
  border-right: 1px solid #00838f !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-3 {
  border-right: 1px solid #00838f !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-3 {
  border-left: 1px solid #00838f !important; }

.overlay-cyan.overlay-darken-3 {
  background: #00838f;
  /* The Fallback */
  background: rgba(0, 131, 143, 0.8); }

.cyan.darken-4 {
  color: #006064 !important; }

.bg-cyan.bg-darken-4 {
  background-color: #006064 !important; }

.btn-cyan.btn-darken-4 {
  border-color: #0097a7 !important;
  background-color: #006064 !important; }
  .btn-cyan.btn-darken-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-4:focus, .btn-cyan.btn-darken-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-4 {
  border-color: #006064 !important;
  color: #006064 !important; }
  .btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064 !important; }

.progress-cyan.progress-darken-4 {
  background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-ms-fill {
    background-color: #006064; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-4 .progress-bar {
      background-color: #006064; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important; }

.border-cyan.border-darken-4 {
  border: 1px solid #006064 !important; }

.border-top-cyan.border-top-darken-4 {
  border-top: 1px solid #006064 !important; }

.border-bottom-cyan.border-bottom-darken-4 {
  border-bottom: 1px solid #006064 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-4 {
  border-left: 1px solid #006064 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-4 {
  border-right: 1px solid #006064 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-4 {
  border-right: 1px solid #006064 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-4 {
  border-left: 1px solid #006064 !important; }

.overlay-cyan.overlay-darken-4 {
  background: #006064;
  /* The Fallback */
  background: rgba(0, 96, 100, 0.8); }

.cyan.accent-1 {
  color: #84ffff !important; }

.bg-cyan.bg-accent-1 {
  background-color: #84ffff !important; }

.btn-cyan.btn-accent-1 {
  border-color: #0097a7 !important;
  background-color: #84ffff !important; }
  .btn-cyan.btn-accent-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-1:focus, .btn-cyan.btn-accent-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-1 {
  border-color: #84ffff !important;
  color: #84ffff !important; }
  .btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84ffff !important; }

.progress-cyan.progress-accent-1 {
  background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-webkit-progress-value {
    background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-moz-progress-bar {
    background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-ms-fill {
    background-color: #84ffff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-1 .progress-bar {
      background-color: #84ffff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84ffff !important; }

.border-cyan.border-accent-1 {
  border: 1px solid #84ffff !important; }

.border-top-cyan.border-top-accent-1 {
  border-top: 1px solid #84ffff !important; }

.border-bottom-cyan.border-bottom-accent-1 {
  border-bottom: 1px solid #84ffff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-1 {
  border-left: 1px solid #84ffff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-1 {
  border-right: 1px solid #84ffff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-1 {
  border-right: 1px solid #84ffff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-1 {
  border-left: 1px solid #84ffff !important; }

.overlay-cyan.overlay-accent-1 {
  background: #84ffff;
  /* The Fallback */
  background: rgba(132, 255, 255, 0.8); }

.cyan.accent-2 {
  color: #18ffff !important; }

.bg-cyan.bg-accent-2 {
  background-color: #18ffff !important; }

.btn-cyan.btn-accent-2 {
  border-color: #0097a7 !important;
  background-color: #18ffff !important; }
  .btn-cyan.btn-accent-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-2:focus, .btn-cyan.btn-accent-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-2 {
  border-color: #18ffff !important;
  color: #18ffff !important; }
  .btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18ffff !important; }

.progress-cyan.progress-accent-2 {
  background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-webkit-progress-value {
    background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-moz-progress-bar {
    background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-ms-fill {
    background-color: #18ffff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-2 .progress-bar {
      background-color: #18ffff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18ffff !important; }

.border-cyan.border-accent-2 {
  border: 1px solid #18ffff !important; }

.border-top-cyan.border-top-accent-2 {
  border-top: 1px solid #18ffff !important; }

.border-bottom-cyan.border-bottom-accent-2 {
  border-bottom: 1px solid #18ffff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-2 {
  border-left: 1px solid #18ffff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-2 {
  border-right: 1px solid #18ffff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-2 {
  border-right: 1px solid #18ffff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-2 {
  border-left: 1px solid #18ffff !important; }

.overlay-cyan.overlay-accent-2 {
  background: #18ffff;
  /* The Fallback */
  background: rgba(24, 255, 255, 0.8); }

.cyan.accent-3 {
  color: #00e5ff !important; }

.bg-cyan.bg-accent-3 {
  background-color: #00e5ff !important; }

.btn-cyan.btn-accent-3 {
  border-color: #0097a7 !important;
  background-color: #00e5ff !important; }
  .btn-cyan.btn-accent-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-3:focus, .btn-cyan.btn-accent-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-3 {
  border-color: #00e5ff !important;
  color: #00e5ff !important; }
  .btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00e5ff !important; }

.progress-cyan.progress-accent-3 {
  background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-ms-fill {
    background-color: #00e5ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-3 .progress-bar {
      background-color: #00e5ff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e5ff !important; }

.border-cyan.border-accent-3 {
  border: 1px solid #00e5ff !important; }

.border-top-cyan.border-top-accent-3 {
  border-top: 1px solid #00e5ff !important; }

.border-bottom-cyan.border-bottom-accent-3 {
  border-bottom: 1px solid #00e5ff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-3 {
  border-left: 1px solid #00e5ff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-3 {
  border-right: 1px solid #00e5ff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-3 {
  border-right: 1px solid #00e5ff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-3 {
  border-left: 1px solid #00e5ff !important; }

.overlay-cyan.overlay-accent-3 {
  background: #00e5ff;
  /* The Fallback */
  background: rgba(0, 229, 255, 0.8); }

.cyan.accent-4 {
  color: #00b8d4 !important; }

.bg-cyan.bg-accent-4 {
  background-color: #00b8d4 !important; }

.btn-cyan.btn-accent-4 {
  border-color: #0097a7 !important;
  background-color: #00b8d4 !important; }
  .btn-cyan.btn-accent-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-4:focus, .btn-cyan.btn-accent-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-4 {
  border-color: #00b8d4 !important;
  color: #00b8d4 !important; }
  .btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00b8d4 !important; }

.progress-cyan.progress-accent-4 {
  background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-ms-fill {
    background-color: #00b8d4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-4 .progress-bar {
      background-color: #00b8d4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b8d4 !important; }

.border-cyan.border-accent-4 {
  border: 1px solid #00b8d4 !important; }

.border-top-cyan.border-top-accent-4 {
  border-top: 1px solid #00b8d4 !important; }

.border-bottom-cyan.border-bottom-accent-4 {
  border-bottom: 1px solid #00b8d4 !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-4 {
  border-left: 1px solid #00b8d4 !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-4 {
  border-right: 1px solid #00b8d4 !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-4 {
  border-right: 1px solid #00b8d4 !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-4 {
  border-left: 1px solid #00b8d4 !important; }

.overlay-cyan.overlay-accent-4 {
  background: #00b8d4;
  /* The Fallback */
  background: rgba(0, 184, 212, 0.8); }

.teal.lighten-5 {
  color: #e0f2f1 !important; }

.bg-teal.bg-lighten-5 {
  background-color: #e0f2f1 !important; }

.btn-teal.btn-lighten-5 {
  border-color: #00796b !important;
  background-color: #e0f2f1 !important; }
  .btn-teal.btn-lighten-5:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-5:focus, .btn-teal.btn-lighten-5:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-5 {
  border-color: #e0f2f1 !important;
  color: #e0f2f1 !important; }
  .btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #e0f2f1 !important; }

.progress-teal.progress-lighten-5 {
  background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f2f1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-5 .progress-bar {
      background-color: #e0f2f1; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f2f1 !important; }

.border-teal.border-lighten-5 {
  border: 1px solid #e0f2f1 !important; }

.border-top-teal.border-top-lighten-5 {
  border-top: 1px solid #e0f2f1 !important; }

.border-bottom-teal.border-bottom-lighten-5 {
  border-bottom: 1px solid #e0f2f1 !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-5 {
  border-left: 1px solid #e0f2f1 !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-5 {
  border-right: 1px solid #e0f2f1 !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-5 {
  border-right: 1px solid #e0f2f1 !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-5 {
  border-left: 1px solid #e0f2f1 !important; }

.overlay-teal.overlay-lighten-5 {
  background: #e0f2f1;
  /* The Fallback */
  background: rgba(224, 242, 241, 0.8); }

.teal.lighten-4 {
  color: #b2dfdb !important; }

.bg-teal.bg-lighten-4 {
  background-color: #b2dfdb !important; }

.btn-teal.btn-lighten-4 {
  border-color: #00796b !important;
  background-color: #b2dfdb !important; }
  .btn-teal.btn-lighten-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-4:focus, .btn-teal.btn-lighten-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-4 {
  border-color: #b2dfdb !important;
  color: #b2dfdb !important; }
  .btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #b2dfdb !important; }

.progress-teal.progress-lighten-4 {
  background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-ms-fill {
    background-color: #b2dfdb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-4 .progress-bar {
      background-color: #b2dfdb; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2dfdb !important; }

.border-teal.border-lighten-4 {
  border: 1px solid #b2dfdb !important; }

.border-top-teal.border-top-lighten-4 {
  border-top: 1px solid #b2dfdb !important; }

.border-bottom-teal.border-bottom-lighten-4 {
  border-bottom: 1px solid #b2dfdb !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-4 {
  border-left: 1px solid #b2dfdb !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-4 {
  border-right: 1px solid #b2dfdb !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-4 {
  border-right: 1px solid #b2dfdb !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-4 {
  border-left: 1px solid #b2dfdb !important; }

.overlay-teal.overlay-lighten-4 {
  background: #b2dfdb;
  /* The Fallback */
  background: rgba(178, 223, 219, 0.8); }

.teal.lighten-3 {
  color: #80cbc4 !important; }

.bg-teal.bg-lighten-3 {
  background-color: #80cbc4 !important; }

.btn-teal.btn-lighten-3 {
  border-color: #00796b !important;
  background-color: #80cbc4 !important; }
  .btn-teal.btn-lighten-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-3:focus, .btn-teal.btn-lighten-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-3 {
  border-color: #80cbc4 !important;
  color: #80cbc4 !important; }
  .btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80cbc4 !important; }

.progress-teal.progress-lighten-3 {
  background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-ms-fill {
    background-color: #80cbc4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-3 .progress-bar {
      background-color: #80cbc4; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80cbc4 !important; }

.border-teal.border-lighten-3 {
  border: 1px solid #80cbc4 !important; }

.border-top-teal.border-top-lighten-3 {
  border-top: 1px solid #80cbc4 !important; }

.border-bottom-teal.border-bottom-lighten-3 {
  border-bottom: 1px solid #80cbc4 !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-3 {
  border-left: 1px solid #80cbc4 !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-3 {
  border-right: 1px solid #80cbc4 !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-3 {
  border-right: 1px solid #80cbc4 !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-3 {
  border-left: 1px solid #80cbc4 !important; }

.overlay-teal.overlay-lighten-3 {
  background: #80cbc4;
  /* The Fallback */
  background: rgba(128, 203, 196, 0.8); }

.teal.lighten-2 {
  color: #4db6ac !important; }

.bg-teal.bg-lighten-2 {
  background-color: #4db6ac !important; }

.btn-teal.btn-lighten-2 {
  border-color: #00796b !important;
  background-color: #4db6ac !important; }
  .btn-teal.btn-lighten-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-2:focus, .btn-teal.btn-lighten-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-2 {
  border-color: #4db6ac !important;
  color: #4db6ac !important; }
  .btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4db6ac !important; }

.progress-teal.progress-lighten-2 {
  background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-ms-fill {
    background-color: #4db6ac; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-2 .progress-bar {
      background-color: #4db6ac; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4db6ac !important; }

.border-teal.border-lighten-2 {
  border: 1px solid #4db6ac !important; }

.border-top-teal.border-top-lighten-2 {
  border-top: 1px solid #4db6ac !important; }

.border-bottom-teal.border-bottom-lighten-2 {
  border-bottom: 1px solid #4db6ac !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-2 {
  border-left: 1px solid #4db6ac !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-2 {
  border-right: 1px solid #4db6ac !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-2 {
  border-right: 1px solid #4db6ac !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-2 {
  border-left: 1px solid #4db6ac !important; }

.overlay-teal.overlay-lighten-2 {
  background: #4db6ac;
  /* The Fallback */
  background: rgba(77, 182, 172, 0.8); }

.teal.lighten-1 {
  color: #26a69a !important; }

.bg-teal.bg-lighten-1 {
  background-color: #26a69a !important; }

.btn-teal.btn-lighten-1 {
  border-color: #00796b !important;
  background-color: #26a69a !important; }
  .btn-teal.btn-lighten-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-1:focus, .btn-teal.btn-lighten-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-1 {
  border-color: #26a69a !important;
  color: #26a69a !important; }
  .btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26a69a !important; }

.progress-teal.progress-lighten-1 {
  background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-ms-fill {
    background-color: #26a69a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-1 .progress-bar {
      background-color: #26a69a; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26a69a !important; }

.border-teal.border-lighten-1 {
  border: 1px solid #26a69a !important; }

.border-top-teal.border-top-lighten-1 {
  border-top: 1px solid #26a69a !important; }

.border-bottom-teal.border-bottom-lighten-1 {
  border-bottom: 1px solid #26a69a !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-1 {
  border-left: 1px solid #26a69a !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-1 {
  border-right: 1px solid #26a69a !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-1 {
  border-right: 1px solid #26a69a !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-1 {
  border-left: 1px solid #26a69a !important; }

.overlay-teal.overlay-lighten-1 {
  background: #26a69a;
  /* The Fallback */
  background: rgba(38, 166, 154, 0.8); }

.teal {
  color: #009688 !important; }

.bg-teal {
  background-color: #009688 !important; }
  .bg-teal .card-header, .bg-teal .card-footer {
    background-color: transparent; }

.toast-teal {
  background-color: #009688; }

.alert-teal {
  border-color: #009688 !important;
  background-color: #00baa8 !important;
  color: black !important; }
  .alert-teal .alert-link {
    color: black !important; }

.border-teal {
  border-color: #009688; }

.border-top-teal {
  border-top-color: #009688; }

.border-bottom-teal {
  border-bottom-color: #009688; }

[dir="ltr"] .border-left-teal {
  border-left-color: #009688; }

[dir="ltr"] .border-right-teal {
  border-right-color: #009688; }

[dir="rtl"] .border-left-teal {
  border-right-color: #009688; }

[dir="rtl"] .border-right-teal {
  border-left-color: #009688; }

.badge-teal {
  background-color: #009688; }

.panel-teal {
  border-color: #009688; }
  .panel-teal .panel-heading {
    color: #FFF;
    border-color: #009688;
    background-color: #00b09f; }

.bg-teal.tag-glow, .border-teal.tag-glow {
  box-shadow: 0px 0px 10px #009688; }

.overlay-teal {
  background: #009688;
  /* The Fallback */
  background: rgba(0, 150, 136, 0.8); }

.card.card-outline-teal {
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  background-color: transparent; }
  .card.card-outline-teal .card-header, .card.card-outline-teal .card-footer {
    background-color: transparent; }

.btn-teal.btn-flat {
  background-color: transparent !important;
  color: #009688;
  border: none; }

.btn-teal.btn-raised, .btn-teal.btn-fab {
  background-color: #009688 !important;
  color: #fff !important;
  border-color: #009688; }
  .btn-teal.btn-raised.active, .btn-teal.btn-fab.active {
    background-color: #007268 !important;
    border-color: #007268 !important; }

.btn-group-raised .btn-teal {
  background-color: #009688 !important;
  color: #fff !important; }

.btn-outline-teal {
  border: 1px solid;
  border-color: #009688;
  background-color: transparent;
  color: #009688;
  box-shadow: none !important; }
  .btn-outline-teal:focus {
    background-color: transparent !important;
    color: #009688 !important;
    box-shadow: transparent !important; }
  .btn-outline-teal.active {
    background-color: #009688 !important;
    color: #FFF !important; }
  .btn-outline-teal:hover {
    background-color: #00877a !important;
    color: #FFF !important; }
    .btn-outline-teal:hover svg {
      color: #FFF !important; }

.btn-outline-teal.btn-raised, .btn-outline-teal.btn-fab {
  border: 1px solid;
  border-color: #009688;
  background-color: transparent;
  color: #009688;
  box-shadow: none !important; }
  .btn-outline-teal.btn-raised:focus, .btn-outline-teal.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-teal.btn-raised.active, .btn-outline-teal.btn-fab.active {
    background-color: #009688 !important;
    color: #FFF !important; }
  .btn-outline-teal.btn-raised:hover, .btn-outline-teal.btn-fab:hover {
    background-color: #00877a !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-teal {
  background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-webkit-progress-value {
    background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-moz-progress-bar {
    background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-ms-fill {
    background-color: #009688; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-teal .progress-bar {
      background-color: #009688; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important; }

.teal.darken-1 {
  color: #00897b !important; }

.bg-teal.bg-darken-1 {
  background-color: #00897b !important; }

.btn-teal.btn-darken-1 {
  border-color: #00796b !important;
  background-color: #00897b !important; }
  .btn-teal.btn-darken-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-1:focus, .btn-teal.btn-darken-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-1 {
  border-color: #00897b !important;
  color: #00897b !important; }
  .btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897b !important; }

.progress-teal.progress-darken-1 {
  background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-ms-fill {
    background-color: #00897b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-1 .progress-bar {
      background-color: #00897b; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897b !important; }

.border-teal.border-darken-1 {
  border: 1px solid #00897b !important; }

.border-top-teal.border-top-darken-1 {
  border-top: 1px solid #00897b !important; }

.border-bottom-teal.border-bottom-darken-1 {
  border-bottom: 1px solid #00897b !important; }

[dir="ltr"] .border-left-teal.border-left-darken-1 {
  border-left: 1px solid #00897b !important; }

[dir="ltr"] .border-right-teal.border-right-darken-1 {
  border-right: 1px solid #00897b !important; }

[dir="rtl"] .border-left-teal.border-left-darken-1 {
  border-right: 1px solid #00897b !important; }

[dir="rtl"] .border-right-teal.border-right-darken-1 {
  border-left: 1px solid #00897b !important; }

.overlay-teal.overlay-darken-1 {
  background: #00897b;
  /* The Fallback */
  background: rgba(0, 137, 123, 0.8); }

.teal.darken-2 {
  color: #00796b !important; }

.bg-teal.bg-darken-2 {
  background-color: #00796b !important; }

.btn-teal.btn-darken-2 {
  border-color: #00796b !important;
  background-color: #00796b !important; }
  .btn-teal.btn-darken-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-2:focus, .btn-teal.btn-darken-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-2 {
  border-color: #00796b !important;
  color: #00796b !important; }
  .btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796b !important; }

.progress-teal.progress-darken-2 {
  background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-webkit-progress-value {
    background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-moz-progress-bar {
    background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-ms-fill {
    background-color: #00796b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-2 .progress-bar {
      background-color: #00796b; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796b !important; }

.border-teal.border-darken-2 {
  border: 1px solid #00796b !important; }

.border-top-teal.border-top-darken-2 {
  border-top: 1px solid #00796b !important; }

.border-bottom-teal.border-bottom-darken-2 {
  border-bottom: 1px solid #00796b !important; }

[dir="ltr"] .border-left-teal.border-left-darken-2 {
  border-left: 1px solid #00796b !important; }

[dir="ltr"] .border-right-teal.border-right-darken-2 {
  border-right: 1px solid #00796b !important; }

[dir="rtl"] .border-left-teal.border-left-darken-2 {
  border-right: 1px solid #00796b !important; }

[dir="rtl"] .border-right-teal.border-right-darken-2 {
  border-left: 1px solid #00796b !important; }

.overlay-teal.overlay-darken-2 {
  background: #00796b;
  /* The Fallback */
  background: rgba(0, 121, 107, 0.8); }

.teal.darken-3 {
  color: #00695c !important; }

.bg-teal.bg-darken-3 {
  background-color: #00695c !important; }

.btn-teal.btn-darken-3 {
  border-color: #00796b !important;
  background-color: #00695c !important; }
  .btn-teal.btn-darken-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-3:focus, .btn-teal.btn-darken-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-3 {
  border-color: #00695c !important;
  color: #00695c !important; }
  .btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695c !important; }

.progress-teal.progress-darken-3 {
  background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-ms-fill {
    background-color: #00695c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-3 .progress-bar {
      background-color: #00695c; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695c !important; }

.border-teal.border-darken-3 {
  border: 1px solid #00695c !important; }

.border-top-teal.border-top-darken-3 {
  border-top: 1px solid #00695c !important; }

.border-bottom-teal.border-bottom-darken-3 {
  border-bottom: 1px solid #00695c !important; }

[dir="ltr"] .border-left-teal.border-left-darken-3 {
  border-left: 1px solid #00695c !important; }

[dir="ltr"] .border-right-teal.border-right-darken-3 {
  border-right: 1px solid #00695c !important; }

[dir="rtl"] .border-left-teal.border-left-darken-3 {
  border-right: 1px solid #00695c !important; }

[dir="rtl"] .border-right-teal.border-right-darken-3 {
  border-left: 1px solid #00695c !important; }

.overlay-teal.overlay-darken-3 {
  background: #00695c;
  /* The Fallback */
  background: rgba(0, 105, 92, 0.8); }

.teal.darken-4 {
  color: #004d40 !important; }

.bg-teal.bg-darken-4 {
  background-color: #004d40 !important; }

.btn-teal.btn-darken-4 {
  border-color: #00796b !important;
  background-color: #004d40 !important; }
  .btn-teal.btn-darken-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-4:focus, .btn-teal.btn-darken-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-4 {
  border-color: #004d40 !important;
  color: #004d40 !important; }
  .btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004d40 !important; }

.progress-teal.progress-darken-4 {
  background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-webkit-progress-value {
    background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-moz-progress-bar {
    background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-ms-fill {
    background-color: #004d40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-4 .progress-bar {
      background-color: #004d40; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004d40 !important; }

.border-teal.border-darken-4 {
  border: 1px solid #004d40 !important; }

.border-top-teal.border-top-darken-4 {
  border-top: 1px solid #004d40 !important; }

.border-bottom-teal.border-bottom-darken-4 {
  border-bottom: 1px solid #004d40 !important; }

[dir="ltr"] .border-left-teal.border-left-darken-4 {
  border-left: 1px solid #004d40 !important; }

[dir="ltr"] .border-right-teal.border-right-darken-4 {
  border-right: 1px solid #004d40 !important; }

[dir="rtl"] .border-left-teal.border-left-darken-4 {
  border-right: 1px solid #004d40 !important; }

[dir="rtl"] .border-right-teal.border-right-darken-4 {
  border-left: 1px solid #004d40 !important; }

.overlay-teal.overlay-darken-4 {
  background: #004d40;
  /* The Fallback */
  background: rgba(0, 77, 64, 0.8); }

.teal.accent-1 {
  color: #a7ffeb !important; }

.bg-teal.bg-accent-1 {
  background-color: #a7ffeb !important; }

.btn-teal.btn-accent-1 {
  border-color: #00796b !important;
  background-color: #a7ffeb !important; }
  .btn-teal.btn-accent-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-1:focus, .btn-teal.btn-accent-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-1 {
  border-color: #a7ffeb !important;
  color: #a7ffeb !important; }
  .btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #a7ffeb !important; }

.progress-teal.progress-accent-1 {
  background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-webkit-progress-value {
    background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-moz-progress-bar {
    background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-ms-fill {
    background-color: #a7ffeb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-1 .progress-bar {
      background-color: #a7ffeb; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a7ffeb !important; }

.border-teal.border-accent-1 {
  border: 1px solid #a7ffeb !important; }

.border-top-teal.border-top-accent-1 {
  border-top: 1px solid #a7ffeb !important; }

.border-bottom-teal.border-bottom-accent-1 {
  border-bottom: 1px solid #a7ffeb !important; }

[dir="ltr"] .border-left-teal.border-left-accent-1 {
  border-left: 1px solid #a7ffeb !important; }

[dir="ltr"] .border-right-teal.border-right-accent-1 {
  border-right: 1px solid #a7ffeb !important; }

[dir="rtl"] .border-left-teal.border-left-accent-1 {
  border-right: 1px solid #a7ffeb !important; }

[dir="rtl"] .border-right-teal.border-right-accent-1 {
  border-left: 1px solid #a7ffeb !important; }

.overlay-teal.overlay-accent-1 {
  background: #a7ffeb;
  /* The Fallback */
  background: rgba(167, 255, 235, 0.8); }

.teal.accent-2 {
  color: #64ffda !important; }

.bg-teal.bg-accent-2 {
  background-color: #64ffda !important; }

.btn-teal.btn-accent-2 {
  border-color: #00796b !important;
  background-color: #64ffda !important; }
  .btn-teal.btn-accent-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-2:focus, .btn-teal.btn-accent-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-2 {
  border-color: #64ffda !important;
  color: #64ffda !important; }
  .btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64ffda !important; }

.progress-teal.progress-accent-2 {
  background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-webkit-progress-value {
    background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-moz-progress-bar {
    background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-ms-fill {
    background-color: #64ffda; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-2 .progress-bar {
      background-color: #64ffda; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64ffda !important; }

.border-teal.border-accent-2 {
  border: 1px solid #64ffda !important; }

.border-top-teal.border-top-accent-2 {
  border-top: 1px solid #64ffda !important; }

.border-bottom-teal.border-bottom-accent-2 {
  border-bottom: 1px solid #64ffda !important; }

[dir="ltr"] .border-left-teal.border-left-accent-2 {
  border-left: 1px solid #64ffda !important; }

[dir="ltr"] .border-right-teal.border-right-accent-2 {
  border-right: 1px solid #64ffda !important; }

[dir="rtl"] .border-left-teal.border-left-accent-2 {
  border-right: 1px solid #64ffda !important; }

[dir="rtl"] .border-right-teal.border-right-accent-2 {
  border-left: 1px solid #64ffda !important; }

.overlay-teal.overlay-accent-2 {
  background: #64ffda;
  /* The Fallback */
  background: rgba(100, 255, 218, 0.8); }

.teal.accent-3 {
  color: #1de9b6 !important; }

.bg-teal.bg-accent-3 {
  background-color: #1de9b6 !important; }

.btn-teal.btn-accent-3 {
  border-color: #00796b !important;
  background-color: #1de9b6 !important; }
  .btn-teal.btn-accent-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-3:focus, .btn-teal.btn-accent-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-3 {
  border-color: #1de9b6 !important;
  color: #1de9b6 !important; }
  .btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1de9b6 !important; }

.progress-teal.progress-accent-3 {
  background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-webkit-progress-value {
    background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-moz-progress-bar {
    background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-ms-fill {
    background-color: #1de9b6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-3 .progress-bar {
      background-color: #1de9b6; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1de9b6 !important; }

.border-teal.border-accent-3 {
  border: 1px solid #1de9b6 !important; }

.border-top-teal.border-top-accent-3 {
  border-top: 1px solid #1de9b6 !important; }

.border-bottom-teal.border-bottom-accent-3 {
  border-bottom: 1px solid #1de9b6 !important; }

[dir="ltr"] .border-left-teal.border-left-accent-3 {
  border-left: 1px solid #1de9b6 !important; }

[dir="ltr"] .border-right-teal.border-right-accent-3 {
  border-right: 1px solid #1de9b6 !important; }

[dir="rtl"] .border-left-teal.border-left-accent-3 {
  border-right: 1px solid #1de9b6 !important; }

[dir="rtl"] .border-right-teal.border-right-accent-3 {
  border-left: 1px solid #1de9b6 !important; }

.overlay-teal.overlay-accent-3 {
  background: #1de9b6;
  /* The Fallback */
  background: rgba(29, 233, 182, 0.8); }

.teal.accent-4 {
  color: #00bfa5 !important; }

.bg-teal.bg-accent-4 {
  background-color: #00bfa5 !important; }

.btn-teal.btn-accent-4 {
  border-color: #00796b !important;
  background-color: #00bfa5 !important; }
  .btn-teal.btn-accent-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-4:focus, .btn-teal.btn-accent-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-4 {
  border-color: #00bfa5 !important;
  color: #00bfa5 !important; }
  .btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00bfa5 !important; }

.progress-teal.progress-accent-4 {
  background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-ms-fill {
    background-color: #00bfa5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-4 .progress-bar {
      background-color: #00bfa5; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bfa5 !important; }

.border-teal.border-accent-4 {
  border: 1px solid #00bfa5 !important; }

.border-top-teal.border-top-accent-4 {
  border-top: 1px solid #00bfa5 !important; }

.border-bottom-teal.border-bottom-accent-4 {
  border-bottom: 1px solid #00bfa5 !important; }

[dir="ltr"] .border-left-teal.border-left-accent-4 {
  border-left: 1px solid #00bfa5 !important; }

[dir="ltr"] .border-right-teal.border-right-accent-4 {
  border-right: 1px solid #00bfa5 !important; }

[dir="rtl"] .border-left-teal.border-left-accent-4 {
  border-right: 1px solid #00bfa5 !important; }

[dir="rtl"] .border-right-teal.border-right-accent-4 {
  border-left: 1px solid #00bfa5 !important; }

.overlay-teal.overlay-accent-4 {
  background: #00bfa5;
  /* The Fallback */
  background: rgba(0, 191, 165, 0.8); }

.green.lighten-5 {
  color: #e8f5e9 !important; }

.bg-green.bg-lighten-5 {
  background-color: #e8f5e9 !important; }

.btn-green.btn-lighten-5 {
  border-color: #388e3c !important;
  background-color: #e8f5e9 !important; }
  .btn-green.btn-lighten-5:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-5:focus, .btn-green.btn-lighten-5:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-5 {
  border-color: #e8f5e9 !important;
  color: #e8f5e9 !important; }
  .btn-outline-green.btn-outline-lighten-5:hover {
    background-color: #e8f5e9 !important; }

.progress-green.progress-lighten-5 {
  background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-ms-fill {
    background-color: #e8f5e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-5 .progress-bar {
      background-color: #e8f5e9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8f5e9 !important; }

.border-green.border-lighten-5 {
  border: 1px solid #e8f5e9 !important; }

.border-top-green.border-top-lighten-5 {
  border-top: 1px solid #e8f5e9 !important; }

.border-bottom-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #e8f5e9 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-5 {
  border-left: 1px solid #e8f5e9 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-5 {
  border-right: 1px solid #e8f5e9 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-5 {
  border-right: 1px solid #e8f5e9 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-5 {
  border-left: 1px solid #e8f5e9 !important; }

.overlay-green.overlay-lighten-5 {
  background: #e8f5e9;
  /* The Fallback */
  background: rgba(232, 245, 233, 0.8); }

.green.lighten-4 {
  color: #c8e6c9 !important; }

.bg-green.bg-lighten-4 {
  background-color: #c8e6c9 !important; }

.btn-green.btn-lighten-4 {
  border-color: #388e3c !important;
  background-color: #c8e6c9 !important; }
  .btn-green.btn-lighten-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-4:focus, .btn-green.btn-lighten-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-4 {
  border-color: #c8e6c9 !important;
  color: #c8e6c9 !important; }
  .btn-outline-green.btn-outline-lighten-4:hover {
    background-color: #c8e6c9 !important; }

.progress-green.progress-lighten-4 {
  background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-ms-fill {
    background-color: #c8e6c9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-4 .progress-bar {
      background-color: #c8e6c9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c8e6c9 !important; }

.border-green.border-lighten-4 {
  border: 1px solid #c8e6c9 !important; }

.border-top-green.border-top-lighten-4 {
  border-top: 1px solid #c8e6c9 !important; }

.border-bottom-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #c8e6c9 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-4 {
  border-left: 1px solid #c8e6c9 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-4 {
  border-right: 1px solid #c8e6c9 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-4 {
  border-right: 1px solid #c8e6c9 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-4 {
  border-left: 1px solid #c8e6c9 !important; }

.overlay-green.overlay-lighten-4 {
  background: #c8e6c9;
  /* The Fallback */
  background: rgba(200, 230, 201, 0.8); }

.green.lighten-3 {
  color: #a5d6a7 !important; }

.bg-green.bg-lighten-3 {
  background-color: #a5d6a7 !important; }

.btn-green.btn-lighten-3 {
  border-color: #388e3c !important;
  background-color: #a5d6a7 !important; }
  .btn-green.btn-lighten-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-3:focus, .btn-green.btn-lighten-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-3 {
  border-color: #a5d6a7 !important;
  color: #a5d6a7 !important; }
  .btn-outline-green.btn-outline-lighten-3:hover {
    background-color: #a5d6a7 !important; }

.progress-green.progress-lighten-3 {
  background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-ms-fill {
    background-color: #a5d6a7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-3 .progress-bar {
      background-color: #a5d6a7; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a5d6a7 !important; }

.border-green.border-lighten-3 {
  border: 1px solid #a5d6a7 !important; }

.border-top-green.border-top-lighten-3 {
  border-top: 1px solid #a5d6a7 !important; }

.border-bottom-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #a5d6a7 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-3 {
  border-left: 1px solid #a5d6a7 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-3 {
  border-right: 1px solid #a5d6a7 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-3 {
  border-right: 1px solid #a5d6a7 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-3 {
  border-left: 1px solid #a5d6a7 !important; }

.overlay-green.overlay-lighten-3 {
  background: #a5d6a7;
  /* The Fallback */
  background: rgba(165, 214, 167, 0.8); }

.green.lighten-2 {
  color: #81c784 !important; }

.bg-green.bg-lighten-2 {
  background-color: #81c784 !important; }

.btn-green.btn-lighten-2 {
  border-color: #388e3c !important;
  background-color: #81c784 !important; }
  .btn-green.btn-lighten-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-2:focus, .btn-green.btn-lighten-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-2 {
  border-color: #81c784 !important;
  color: #81c784 !important; }
  .btn-outline-green.btn-outline-lighten-2:hover {
    background-color: #81c784 !important; }

.progress-green.progress-lighten-2 {
  background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-ms-fill {
    background-color: #81c784; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-2 .progress-bar {
      background-color: #81c784; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81c784 !important; }

.border-green.border-lighten-2 {
  border: 1px solid #81c784 !important; }

.border-top-green.border-top-lighten-2 {
  border-top: 1px solid #81c784 !important; }

.border-bottom-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #81c784 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-2 {
  border-left: 1px solid #81c784 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-2 {
  border-right: 1px solid #81c784 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-2 {
  border-right: 1px solid #81c784 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-2 {
  border-left: 1px solid #81c784 !important; }

.overlay-green.overlay-lighten-2 {
  background: #81c784;
  /* The Fallback */
  background: rgba(129, 199, 132, 0.8); }

.green.lighten-1 {
  color: #66bb6a !important; }

.bg-green.bg-lighten-1 {
  background-color: #66bb6a !important; }

.btn-green.btn-lighten-1 {
  border-color: #388e3c !important;
  background-color: #66bb6a !important; }
  .btn-green.btn-lighten-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-1:focus, .btn-green.btn-lighten-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-1 {
  border-color: #66bb6a !important;
  color: #66bb6a !important; }
  .btn-outline-green.btn-outline-lighten-1:hover {
    background-color: #66bb6a !important; }

.progress-green.progress-lighten-1 {
  background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-ms-fill {
    background-color: #66bb6a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-1 .progress-bar {
      background-color: #66bb6a; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #66bb6a !important; }

.border-green.border-lighten-1 {
  border: 1px solid #66bb6a !important; }

.border-top-green.border-top-lighten-1 {
  border-top: 1px solid #66bb6a !important; }

.border-bottom-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #66bb6a !important; }

[dir="ltr"] .border-left-green.border-left-lighten-1 {
  border-left: 1px solid #66bb6a !important; }

[dir="ltr"] .border-right-green.border-right-lighten-1 {
  border-right: 1px solid #66bb6a !important; }

[dir="rtl"] .border-left-green.border-left-lighten-1 {
  border-right: 1px solid #66bb6a !important; }

[dir="rtl"] .border-right-green.border-right-lighten-1 {
  border-left: 1px solid #66bb6a !important; }

.overlay-green.overlay-lighten-1 {
  background: #66bb6a;
  /* The Fallback */
  background: rgba(102, 187, 106, 0.8); }

.green {
  color: #4caf50 !important; }

.bg-green {
  background-color: #4caf50 !important; }
  .bg-green .card-header, .bg-green .card-footer {
    background-color: transparent; }

.toast-green {
  background-color: #4caf50; }

.alert-green {
  border-color: #4caf50 !important;
  background-color: #63bb67 !important;
  color: #163317 !important; }
  .alert-green .alert-link {
    color: #0a160a !important; }

.border-green {
  border-color: #4caf50; }

.border-top-green {
  border-top-color: #4caf50; }

.border-bottom-green {
  border-bottom-color: #4caf50; }

[dir="ltr"] .border-left-green {
  border-left-color: #4caf50; }

[dir="ltr"] .border-right-green {
  border-right-color: #4caf50; }

[dir="rtl"] .border-left-green {
  border-right-color: #4caf50; }

[dir="rtl"] .border-right-green {
  border-left-color: #4caf50; }

.badge-green {
  background-color: #4caf50; }

.panel-green {
  border-color: #4caf50; }
  .panel-green .panel-heading {
    color: #FFF;
    border-color: #4caf50;
    background-color: #5cb860; }

.bg-green.tag-glow, .border-green.tag-glow {
  box-shadow: 0px 0px 10px #4caf50; }

.overlay-green {
  background: #4caf50;
  /* The Fallback */
  background: rgba(76, 175, 80, 0.8); }

.card.card-outline-green {
  border-width: 1px;
  border-style: solid;
  border-color: #4caf50;
  background-color: transparent; }
  .card.card-outline-green .card-header, .card.card-outline-green .card-footer {
    background-color: transparent; }

.btn-green.btn-flat {
  background-color: transparent !important;
  color: #4caf50;
  border: none; }

.btn-green.btn-raised, .btn-green.btn-fab {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-color: #4caf50; }
  .btn-green.btn-raised.active, .btn-green.btn-fab.active {
    background-color: #419645 !important;
    border-color: #419645 !important; }

.btn-group-raised .btn-green {
  background-color: #4caf50 !important;
  color: #fff !important; }

.btn-outline-green {
  border: 1px solid;
  border-color: #4caf50;
  background-color: transparent;
  color: #4caf50;
  box-shadow: none !important; }
  .btn-outline-green:focus {
    background-color: transparent !important;
    color: #4caf50 !important;
    box-shadow: transparent !important; }
  .btn-outline-green.active {
    background-color: #4caf50 !important;
    color: #FFF !important; }
  .btn-outline-green:hover {
    background-color: #47a44b !important;
    color: #FFF !important; }
    .btn-outline-green:hover svg {
      color: #FFF !important; }

.btn-outline-green.btn-raised, .btn-outline-green.btn-fab {
  border: 1px solid;
  border-color: #4caf50;
  background-color: transparent;
  color: #4caf50;
  box-shadow: none !important; }
  .btn-outline-green.btn-raised:focus, .btn-outline-green.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-green.btn-raised.active, .btn-outline-green.btn-fab.active {
    background-color: #4caf50 !important;
    color: #FFF !important; }
  .btn-outline-green.btn-raised:hover, .btn-outline-green.btn-fab:hover {
    background-color: #47a44b !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-green {
  background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-webkit-progress-value {
    background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-moz-progress-bar {
    background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-ms-fill {
    background-color: #4caf50; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-green .progress-bar {
      background-color: #4caf50; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4caf50 !important; }

.green.darken-1 {
  color: #43a047 !important; }

.bg-green.bg-darken-1 {
  background-color: #43a047 !important; }

.btn-green.btn-darken-1 {
  border-color: #388e3c !important;
  background-color: #43a047 !important; }
  .btn-green.btn-darken-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-1:focus, .btn-green.btn-darken-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-1 {
  border-color: #43a047 !important;
  color: #43a047 !important; }
  .btn-outline-green.btn-outline-darken-1:hover {
    background-color: #43a047 !important; }

.progress-green.progress-darken-1 {
  background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-ms-fill {
    background-color: #43a047; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-1 .progress-bar {
      background-color: #43a047; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #43a047 !important; }

.border-green.border-darken-1 {
  border: 1px solid #43a047 !important; }

.border-top-green.border-top-darken-1 {
  border-top: 1px solid #43a047 !important; }

.border-bottom-green.border-bottom-darken-1 {
  border-bottom: 1px solid #43a047 !important; }

[dir="ltr"] .border-left-green.border-left-darken-1 {
  border-left: 1px solid #43a047 !important; }

[dir="ltr"] .border-right-green.border-right-darken-1 {
  border-right: 1px solid #43a047 !important; }

[dir="rtl"] .border-left-green.border-left-darken-1 {
  border-right: 1px solid #43a047 !important; }

[dir="rtl"] .border-right-green.border-right-darken-1 {
  border-left: 1px solid #43a047 !important; }

.overlay-green.overlay-darken-1 {
  background: #43a047;
  /* The Fallback */
  background: rgba(67, 160, 71, 0.8); }

.green.darken-2 {
  color: #388e3c !important; }

.bg-green.bg-darken-2 {
  background-color: #388e3c !important; }

.btn-green.btn-darken-2 {
  border-color: #388e3c !important;
  background-color: #388e3c !important; }
  .btn-green.btn-darken-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-2:focus, .btn-green.btn-darken-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-2 {
  border-color: #388e3c !important;
  color: #388e3c !important; }
  .btn-outline-green.btn-outline-darken-2:hover {
    background-color: #388e3c !important; }

.progress-green.progress-darken-2 {
  background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-ms-fill {
    background-color: #388e3c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-2 .progress-bar {
      background-color: #388e3c; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #388e3c !important; }

.border-green.border-darken-2 {
  border: 1px solid #388e3c !important; }

.border-top-green.border-top-darken-2 {
  border-top: 1px solid #388e3c !important; }

.border-bottom-green.border-bottom-darken-2 {
  border-bottom: 1px solid #388e3c !important; }

[dir="ltr"] .border-left-green.border-left-darken-2 {
  border-left: 1px solid #388e3c !important; }

[dir="ltr"] .border-right-green.border-right-darken-2 {
  border-right: 1px solid #388e3c !important; }

[dir="rtl"] .border-left-green.border-left-darken-2 {
  border-right: 1px solid #388e3c !important; }

[dir="rtl"] .border-right-green.border-right-darken-2 {
  border-left: 1px solid #388e3c !important; }

.overlay-green.overlay-darken-2 {
  background: #388e3c;
  /* The Fallback */
  background: rgba(56, 142, 60, 0.8); }

.green.darken-3 {
  color: #2e7d32 !important; }

.bg-green.bg-darken-3 {
  background-color: #2e7d32 !important; }

.btn-green.btn-darken-3 {
  border-color: #388e3c !important;
  background-color: #2e7d32 !important; }
  .btn-green.btn-darken-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-3:focus, .btn-green.btn-darken-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-3 {
  border-color: #2e7d32 !important;
  color: #2e7d32 !important; }
  .btn-outline-green.btn-outline-darken-3:hover {
    background-color: #2e7d32 !important; }

.progress-green.progress-darken-3 {
  background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-ms-fill {
    background-color: #2e7d32; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-3 .progress-bar {
      background-color: #2e7d32; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2e7d32 !important; }

.border-green.border-darken-3 {
  border: 1px solid #2e7d32 !important; }

.border-top-green.border-top-darken-3 {
  border-top: 1px solid #2e7d32 !important; }

.border-bottom-green.border-bottom-darken-3 {
  border-bottom: 1px solid #2e7d32 !important; }

[dir="ltr"] .border-left-green.border-left-darken-3 {
  border-left: 1px solid #2e7d32 !important; }

[dir="ltr"] .border-right-green.border-right-darken-3 {
  border-right: 1px solid #2e7d32 !important; }

[dir="rtl"] .border-left-green.border-left-darken-3 {
  border-right: 1px solid #2e7d32 !important; }

[dir="rtl"] .border-right-green.border-right-darken-3 {
  border-left: 1px solid #2e7d32 !important; }

.overlay-green.overlay-darken-3 {
  background: #2e7d32;
  /* The Fallback */
  background: rgba(46, 125, 50, 0.8); }

.green.darken-4 {
  color: #1b5e20 !important; }

.bg-green.bg-darken-4 {
  background-color: #1b5e20 !important; }

.btn-green.btn-darken-4 {
  border-color: #388e3c !important;
  background-color: #1b5e20 !important; }
  .btn-green.btn-darken-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-4:focus, .btn-green.btn-darken-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-4 {
  border-color: #1b5e20 !important;
  color: #1b5e20 !important; }
  .btn-outline-green.btn-outline-darken-4:hover {
    background-color: #1b5e20 !important; }

.progress-green.progress-darken-4 {
  background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-ms-fill {
    background-color: #1b5e20; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-4 .progress-bar {
      background-color: #1b5e20; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1b5e20 !important; }

.border-green.border-darken-4 {
  border: 1px solid #1b5e20 !important; }

.border-top-green.border-top-darken-4 {
  border-top: 1px solid #1b5e20 !important; }

.border-bottom-green.border-bottom-darken-4 {
  border-bottom: 1px solid #1b5e20 !important; }

[dir="ltr"] .border-left-green.border-left-darken-4 {
  border-left: 1px solid #1b5e20 !important; }

[dir="ltr"] .border-right-green.border-right-darken-4 {
  border-right: 1px solid #1b5e20 !important; }

[dir="rtl"] .border-left-green.border-left-darken-4 {
  border-right: 1px solid #1b5e20 !important; }

[dir="rtl"] .border-right-green.border-right-darken-4 {
  border-left: 1px solid #1b5e20 !important; }

.overlay-green.overlay-darken-4 {
  background: #1b5e20;
  /* The Fallback */
  background: rgba(27, 94, 32, 0.8); }

.green.accent-1 {
  color: #b9f6ca !important; }

.bg-green.bg-accent-1 {
  background-color: #b9f6ca !important; }

.btn-green.btn-accent-1 {
  border-color: #388e3c !important;
  background-color: #b9f6ca !important; }
  .btn-green.btn-accent-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-1:focus, .btn-green.btn-accent-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-1 {
  border-color: #b9f6ca !important;
  color: #b9f6ca !important; }
  .btn-outline-green.btn-outline-accent-1:hover {
    background-color: #b9f6ca !important; }

.progress-green.progress-accent-1 {
  background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-ms-fill {
    background-color: #b9f6ca; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-1 .progress-bar {
      background-color: #b9f6ca; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b9f6ca !important; }

.border-green.border-accent-1 {
  border: 1px solid #b9f6ca !important; }

.border-top-green.border-top-accent-1 {
  border-top: 1px solid #b9f6ca !important; }

.border-bottom-green.border-bottom-accent-1 {
  border-bottom: 1px solid #b9f6ca !important; }

[dir="ltr"] .border-left-green.border-left-accent-1 {
  border-left: 1px solid #b9f6ca !important; }

[dir="ltr"] .border-right-green.border-right-accent-1 {
  border-right: 1px solid #b9f6ca !important; }

[dir="rtl"] .border-left-green.border-left-accent-1 {
  border-right: 1px solid #b9f6ca !important; }

[dir="rtl"] .border-right-green.border-right-accent-1 {
  border-left: 1px solid #b9f6ca !important; }

.overlay-green.overlay-accent-1 {
  background: #b9f6ca;
  /* The Fallback */
  background: rgba(185, 246, 202, 0.8); }

.green.accent-2 {
  color: #69f0ae !important; }

.bg-green.bg-accent-2 {
  background-color: #69f0ae !important; }

.btn-green.btn-accent-2 {
  border-color: #388e3c !important;
  background-color: #69f0ae !important; }
  .btn-green.btn-accent-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-2:focus, .btn-green.btn-accent-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-2 {
  border-color: #69f0ae !important;
  color: #69f0ae !important; }
  .btn-outline-green.btn-outline-accent-2:hover {
    background-color: #69f0ae !important; }

.progress-green.progress-accent-2 {
  background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-ms-fill {
    background-color: #69f0ae; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-2 .progress-bar {
      background-color: #69f0ae; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #69f0ae !important; }

.border-green.border-accent-2 {
  border: 1px solid #69f0ae !important; }

.border-top-green.border-top-accent-2 {
  border-top: 1px solid #69f0ae !important; }

.border-bottom-green.border-bottom-accent-2 {
  border-bottom: 1px solid #69f0ae !important; }

[dir="ltr"] .border-left-green.border-left-accent-2 {
  border-left: 1px solid #69f0ae !important; }

[dir="ltr"] .border-right-green.border-right-accent-2 {
  border-right: 1px solid #69f0ae !important; }

[dir="rtl"] .border-left-green.border-left-accent-2 {
  border-right: 1px solid #69f0ae !important; }

[dir="rtl"] .border-right-green.border-right-accent-2 {
  border-left: 1px solid #69f0ae !important; }

.overlay-green.overlay-accent-2 {
  background: #69f0ae;
  /* The Fallback */
  background: rgba(105, 240, 174, 0.8); }

.green.accent-3 {
  color: #00e676 !important; }

.bg-green.bg-accent-3 {
  background-color: #00e676 !important; }

.btn-green.btn-accent-3 {
  border-color: #388e3c !important;
  background-color: #00e676 !important; }
  .btn-green.btn-accent-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-3:focus, .btn-green.btn-accent-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-3 {
  border-color: #00e676 !important;
  color: #00e676 !important; }
  .btn-outline-green.btn-outline-accent-3:hover {
    background-color: #00e676 !important; }

.progress-green.progress-accent-3 {
  background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-ms-fill {
    background-color: #00e676; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-3 .progress-bar {
      background-color: #00e676; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e676 !important; }

.border-green.border-accent-3 {
  border: 1px solid #00e676 !important; }

.border-top-green.border-top-accent-3 {
  border-top: 1px solid #00e676 !important; }

.border-bottom-green.border-bottom-accent-3 {
  border-bottom: 1px solid #00e676 !important; }

[dir="ltr"] .border-left-green.border-left-accent-3 {
  border-left: 1px solid #00e676 !important; }

[dir="ltr"] .border-right-green.border-right-accent-3 {
  border-right: 1px solid #00e676 !important; }

[dir="rtl"] .border-left-green.border-left-accent-3 {
  border-right: 1px solid #00e676 !important; }

[dir="rtl"] .border-right-green.border-right-accent-3 {
  border-left: 1px solid #00e676 !important; }

.overlay-green.overlay-accent-3 {
  background: #00e676;
  /* The Fallback */
  background: rgba(0, 230, 118, 0.8); }

.green.accent-4 {
  color: #00c853 !important; }

.bg-green.bg-accent-4 {
  background-color: #00c853 !important; }

.btn-green.btn-accent-4 {
  border-color: #388e3c !important;
  background-color: #00c853 !important; }
  .btn-green.btn-accent-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-4:focus, .btn-green.btn-accent-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-4 {
  border-color: #00c853 !important;
  color: #00c853 !important; }
  .btn-outline-green.btn-outline-accent-4:hover {
    background-color: #00c853 !important; }

.progress-green.progress-accent-4 {
  background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-ms-fill {
    background-color: #00c853; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-4 .progress-bar {
      background-color: #00c853; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00c853 !important; }

.border-green.border-accent-4 {
  border: 1px solid #00c853 !important; }

.border-top-green.border-top-accent-4 {
  border-top: 1px solid #00c853 !important; }

.border-bottom-green.border-bottom-accent-4 {
  border-bottom: 1px solid #00c853 !important; }

[dir="ltr"] .border-left-green.border-left-accent-4 {
  border-left: 1px solid #00c853 !important; }

[dir="ltr"] .border-right-green.border-right-accent-4 {
  border-right: 1px solid #00c853 !important; }

[dir="rtl"] .border-left-green.border-left-accent-4 {
  border-right: 1px solid #00c853 !important; }

[dir="rtl"] .border-right-green.border-right-accent-4 {
  border-left: 1px solid #00c853 !important; }

.overlay-green.overlay-accent-4 {
  background: #00c853;
  /* The Fallback */
  background: rgba(0, 200, 83, 0.8); }

.light-green.lighten-5 {
  color: #f1f8e9 !important; }

.bg-light-green.bg-lighten-5 {
  background-color: #f1f8e9 !important; }

.btn-light-green.btn-lighten-5 {
  border-color: #689f38 !important;
  background-color: #f1f8e9 !important; }
  .btn-light-green.btn-lighten-5:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-5:focus, .btn-light-green.btn-lighten-5:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-5 {
  border-color: #f1f8e9 !important;
  color: #f1f8e9 !important; }
  .btn-outline-light-green.btn-outline-lighten-5:hover {
    background-color: #f1f8e9 !important; }

.progress-light-green.progress-lighten-5 {
  background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-ms-fill {
    background-color: #f1f8e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-5 .progress-bar {
      background-color: #f1f8e9; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f1f8e9 !important; }

.border-light-green.border-lighten-5 {
  border: 1px solid #f1f8e9 !important; }

.border-top-light-green.border-top-lighten-5 {
  border-top: 1px solid #f1f8e9 !important; }

.border-bottom-light-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #f1f8e9 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-5 {
  border-left: 1px solid #f1f8e9 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-5 {
  border-right: 1px solid #f1f8e9 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-5 {
  border-right: 1px solid #f1f8e9 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-5 {
  border-left: 1px solid #f1f8e9 !important; }

.overlay-light-green.overlay-lighten-5 {
  background: #f1f8e9;
  /* The Fallback */
  background: rgba(241, 248, 233, 0.8); }

.light-green.lighten-4 {
  color: #dcedc8 !important; }

.bg-light-green.bg-lighten-4 {
  background-color: #dcedc8 !important; }

.btn-light-green.btn-lighten-4 {
  border-color: #689f38 !important;
  background-color: #dcedc8 !important; }
  .btn-light-green.btn-lighten-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-4:focus, .btn-light-green.btn-lighten-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-4 {
  border-color: #dcedc8 !important;
  color: #dcedc8 !important; }
  .btn-outline-light-green.btn-outline-lighten-4:hover {
    background-color: #dcedc8 !important; }

.progress-light-green.progress-lighten-4 {
  background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-ms-fill {
    background-color: #dcedc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-4 .progress-bar {
      background-color: #dcedc8; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dcedc8 !important; }

.border-light-green.border-lighten-4 {
  border: 1px solid #dcedc8 !important; }

.border-top-light-green.border-top-lighten-4 {
  border-top: 1px solid #dcedc8 !important; }

.border-bottom-light-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #dcedc8 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-4 {
  border-left: 1px solid #dcedc8 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-4 {
  border-right: 1px solid #dcedc8 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-4 {
  border-right: 1px solid #dcedc8 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-4 {
  border-left: 1px solid #dcedc8 !important; }

.overlay-light-green.overlay-lighten-4 {
  background: #dcedc8;
  /* The Fallback */
  background: rgba(220, 237, 200, 0.8); }

.light-green.lighten-3 {
  color: #c5e1a5 !important; }

.bg-light-green.bg-lighten-3 {
  background-color: #c5e1a5 !important; }

.btn-light-green.btn-lighten-3 {
  border-color: #689f38 !important;
  background-color: #c5e1a5 !important; }
  .btn-light-green.btn-lighten-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-3:focus, .btn-light-green.btn-lighten-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-3 {
  border-color: #c5e1a5 !important;
  color: #c5e1a5 !important; }
  .btn-outline-light-green.btn-outline-lighten-3:hover {
    background-color: #c5e1a5 !important; }

.progress-light-green.progress-lighten-3 {
  background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-ms-fill {
    background-color: #c5e1a5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-3 .progress-bar {
      background-color: #c5e1a5; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5e1a5 !important; }

.border-light-green.border-lighten-3 {
  border: 1px solid #c5e1a5 !important; }

.border-top-light-green.border-top-lighten-3 {
  border-top: 1px solid #c5e1a5 !important; }

.border-bottom-light-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #c5e1a5 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-3 {
  border-left: 1px solid #c5e1a5 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-3 {
  border-right: 1px solid #c5e1a5 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-3 {
  border-right: 1px solid #c5e1a5 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-3 {
  border-left: 1px solid #c5e1a5 !important; }

.overlay-light-green.overlay-lighten-3 {
  background: #c5e1a5;
  /* The Fallback */
  background: rgba(197, 225, 165, 0.8); }

.light-green.lighten-2 {
  color: #aed581 !important; }

.bg-light-green.bg-lighten-2 {
  background-color: #aed581 !important; }

.btn-light-green.btn-lighten-2 {
  border-color: #689f38 !important;
  background-color: #aed581 !important; }
  .btn-light-green.btn-lighten-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-2:focus, .btn-light-green.btn-lighten-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-2 {
  border-color: #aed581 !important;
  color: #aed581 !important; }
  .btn-outline-light-green.btn-outline-lighten-2:hover {
    background-color: #aed581 !important; }

.progress-light-green.progress-lighten-2 {
  background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-ms-fill {
    background-color: #aed581; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-2 .progress-bar {
      background-color: #aed581; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aed581 !important; }

.border-light-green.border-lighten-2 {
  border: 1px solid #aed581 !important; }

.border-top-light-green.border-top-lighten-2 {
  border-top: 1px solid #aed581 !important; }

.border-bottom-light-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #aed581 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-2 {
  border-left: 1px solid #aed581 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-2 {
  border-right: 1px solid #aed581 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-2 {
  border-right: 1px solid #aed581 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-2 {
  border-left: 1px solid #aed581 !important; }

.overlay-light-green.overlay-lighten-2 {
  background: #aed581;
  /* The Fallback */
  background: rgba(174, 213, 129, 0.8); }

.light-green.lighten-1 {
  color: #9ccc65 !important; }

.bg-light-green.bg-lighten-1 {
  background-color: #9ccc65 !important; }

.btn-light-green.btn-lighten-1 {
  border-color: #689f38 !important;
  background-color: #9ccc65 !important; }
  .btn-light-green.btn-lighten-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-1:focus, .btn-light-green.btn-lighten-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-1 {
  border-color: #9ccc65 !important;
  color: #9ccc65 !important; }
  .btn-outline-light-green.btn-outline-lighten-1:hover {
    background-color: #9ccc65 !important; }

.progress-light-green.progress-lighten-1 {
  background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-ms-fill {
    background-color: #9ccc65; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-1 .progress-bar {
      background-color: #9ccc65; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9ccc65 !important; }

.border-light-green.border-lighten-1 {
  border: 1px solid #9ccc65 !important; }

.border-top-light-green.border-top-lighten-1 {
  border-top: 1px solid #9ccc65 !important; }

.border-bottom-light-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #9ccc65 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-1 {
  border-left: 1px solid #9ccc65 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-1 {
  border-right: 1px solid #9ccc65 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-1 {
  border-right: 1px solid #9ccc65 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-1 {
  border-left: 1px solid #9ccc65 !important; }

.overlay-light-green.overlay-lighten-1 {
  background: #9ccc65;
  /* The Fallback */
  background: rgba(156, 204, 101, 0.8); }

.light-green {
  color: #8bc34a !important; }

.bg-light-green {
  background-color: #8bc34a !important; }
  .bg-light-green .card-header, .bg-light-green .card-footer {
    background-color: transparent; }

.toast-light-green {
  background-color: #8bc34a; }

.alert-light-green {
  border-color: #8bc34a !important;
  background-color: #9ccc65 !important;
  color: #2f4417 !important; }
  .alert-light-green .alert-link {
    color: #1a250c !important; }

.border-light-green {
  border-color: #8bc34a; }

.border-top-light-green {
  border-top-color: #8bc34a; }

.border-bottom-light-green {
  border-bottom-color: #8bc34a; }

[dir="ltr"] .border-left-light-green {
  border-left-color: #8bc34a; }

[dir="ltr"] .border-right-light-green {
  border-right-color: #8bc34a; }

[dir="rtl"] .border-left-light-green {
  border-right-color: #8bc34a; }

[dir="rtl"] .border-right-light-green {
  border-left-color: #8bc34a; }

.badge-light-green {
  background-color: #8bc34a; }

.panel-light-green {
  border-color: #8bc34a; }
  .panel-light-green .panel-heading {
    color: #FFF;
    border-color: #8bc34a;
    background-color: #97c95d; }

.bg-light-green.tag-glow, .border-light-green.tag-glow {
  box-shadow: 0px 0px 10px #8bc34a; }

.overlay-light-green {
  background: #8bc34a;
  /* The Fallback */
  background: rgba(139, 195, 74, 0.8); }

.card.card-outline-light-green {
  border-width: 1px;
  border-style: solid;
  border-color: #8bc34a;
  background-color: transparent; }
  .card.card-outline-light-green .card-header, .card.card-outline-light-green .card-footer {
    background-color: transparent; }

.btn-light-green.btn-flat {
  background-color: transparent !important;
  color: #8bc34a;
  border: none; }

.btn-light-green.btn-raised, .btn-light-green.btn-fab {
  background-color: #8bc34a !important;
  color: #fff !important;
  border-color: #8bc34a; }
  .btn-light-green.btn-raised.active, .btn-light-green.btn-fab.active {
    background-color: #79af3a !important;
    border-color: #79af3a !important; }

.btn-group-raised .btn-light-green {
  background-color: #8bc34a !important;
  color: #fff !important; }

.btn-outline-light-green {
  border: 1px solid;
  border-color: #8bc34a;
  background-color: transparent;
  color: #8bc34a;
  box-shadow: none !important; }
  .btn-outline-light-green:focus {
    background-color: transparent !important;
    color: #8bc34a !important;
    box-shadow: transparent !important; }
  .btn-outline-light-green.active {
    background-color: #8bc34a !important;
    color: #FFF !important; }
  .btn-outline-light-green:hover {
    background-color: #84bf3f !important;
    color: #FFF !important; }
    .btn-outline-light-green:hover svg {
      color: #FFF !important; }

.btn-outline-light-green.btn-raised, .btn-outline-light-green.btn-fab {
  border: 1px solid;
  border-color: #8bc34a;
  background-color: transparent;
  color: #8bc34a;
  box-shadow: none !important; }
  .btn-outline-light-green.btn-raised:focus, .btn-outline-light-green.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-light-green.btn-raised.active, .btn-outline-light-green.btn-fab.active {
    background-color: #8bc34a !important;
    color: #FFF !important; }
  .btn-outline-light-green.btn-raised:hover, .btn-outline-light-green.btn-fab:hover {
    background-color: #84bf3f !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-light-green {
  background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-webkit-progress-value {
    background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-moz-progress-bar {
    background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-ms-fill {
    background-color: #8bc34a; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-light-green .progress-bar {
      background-color: #8bc34a; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8bc34a !important; }

.light-green.darken-1 {
  color: #7cb342 !important; }

.bg-light-green.bg-darken-1 {
  background-color: #7cb342 !important; }

.btn-light-green.btn-darken-1 {
  border-color: #689f38 !important;
  background-color: #7cb342 !important; }
  .btn-light-green.btn-darken-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-1:focus, .btn-light-green.btn-darken-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-1 {
  border-color: #7cb342 !important;
  color: #7cb342 !important; }
  .btn-outline-light-green.btn-outline-darken-1:hover {
    background-color: #7cb342 !important; }

.progress-light-green.progress-darken-1 {
  background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-ms-fill {
    background-color: #7cb342; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-1 .progress-bar {
      background-color: #7cb342; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7cb342 !important; }

.border-light-green.border-darken-1 {
  border: 1px solid #7cb342 !important; }

.border-top-light-green.border-top-darken-1 {
  border-top: 1px solid #7cb342 !important; }

.border-bottom-light-green.border-bottom-darken-1 {
  border-bottom: 1px solid #7cb342 !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-1 {
  border-left: 1px solid #7cb342 !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-1 {
  border-right: 1px solid #7cb342 !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-1 {
  border-right: 1px solid #7cb342 !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-1 {
  border-left: 1px solid #7cb342 !important; }

.overlay-light-green.overlay-darken-1 {
  background: #7cb342;
  /* The Fallback */
  background: rgba(124, 179, 66, 0.8); }

.light-green.darken-2 {
  color: #689f38 !important; }

.bg-light-green.bg-darken-2 {
  background-color: #689f38 !important; }

.btn-light-green.btn-darken-2 {
  border-color: #689f38 !important;
  background-color: #689f38 !important; }
  .btn-light-green.btn-darken-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-2:focus, .btn-light-green.btn-darken-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-2 {
  border-color: #689f38 !important;
  color: #689f38 !important; }
  .btn-outline-light-green.btn-outline-darken-2:hover {
    background-color: #689f38 !important; }

.progress-light-green.progress-darken-2 {
  background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-ms-fill {
    background-color: #689f38; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-2 .progress-bar {
      background-color: #689f38; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #689f38 !important; }

.border-light-green.border-darken-2 {
  border: 1px solid #689f38 !important; }

.border-top-light-green.border-top-darken-2 {
  border-top: 1px solid #689f38 !important; }

.border-bottom-light-green.border-bottom-darken-2 {
  border-bottom: 1px solid #689f38 !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-2 {
  border-left: 1px solid #689f38 !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-2 {
  border-right: 1px solid #689f38 !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-2 {
  border-right: 1px solid #689f38 !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-2 {
  border-left: 1px solid #689f38 !important; }

.overlay-light-green.overlay-darken-2 {
  background: #689f38;
  /* The Fallback */
  background: rgba(104, 159, 56, 0.8); }

.light-green.darken-3 {
  color: #558b2f !important; }

.bg-light-green.bg-darken-3 {
  background-color: #558b2f !important; }

.btn-light-green.btn-darken-3 {
  border-color: #689f38 !important;
  background-color: #558b2f !important; }
  .btn-light-green.btn-darken-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-3:focus, .btn-light-green.btn-darken-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-3 {
  border-color: #558b2f !important;
  color: #558b2f !important; }
  .btn-outline-light-green.btn-outline-darken-3:hover {
    background-color: #558b2f !important; }

.progress-light-green.progress-darken-3 {
  background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-ms-fill {
    background-color: #558b2f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-3 .progress-bar {
      background-color: #558b2f; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #558b2f !important; }

.border-light-green.border-darken-3 {
  border: 1px solid #558b2f !important; }

.border-top-light-green.border-top-darken-3 {
  border-top: 1px solid #558b2f !important; }

.border-bottom-light-green.border-bottom-darken-3 {
  border-bottom: 1px solid #558b2f !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-3 {
  border-left: 1px solid #558b2f !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-3 {
  border-right: 1px solid #558b2f !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-3 {
  border-right: 1px solid #558b2f !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-3 {
  border-left: 1px solid #558b2f !important; }

.overlay-light-green.overlay-darken-3 {
  background: #558b2f;
  /* The Fallback */
  background: rgba(85, 139, 47, 0.8); }

.light-green.darken-4 {
  color: #33691e !important; }

.bg-light-green.bg-darken-4 {
  background-color: #33691e !important; }

.btn-light-green.btn-darken-4 {
  border-color: #689f38 !important;
  background-color: #33691e !important; }
  .btn-light-green.btn-darken-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-4:focus, .btn-light-green.btn-darken-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-4 {
  border-color: #33691e !important;
  color: #33691e !important; }
  .btn-outline-light-green.btn-outline-darken-4:hover {
    background-color: #33691e !important; }

.progress-light-green.progress-darken-4 {
  background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-ms-fill {
    background-color: #33691e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-4 .progress-bar {
      background-color: #33691e; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #33691e !important; }

.border-light-green.border-darken-4 {
  border: 1px solid #33691e !important; }

.border-top-light-green.border-top-darken-4 {
  border-top: 1px solid #33691e !important; }

.border-bottom-light-green.border-bottom-darken-4 {
  border-bottom: 1px solid #33691e !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-4 {
  border-left: 1px solid #33691e !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-4 {
  border-right: 1px solid #33691e !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-4 {
  border-right: 1px solid #33691e !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-4 {
  border-left: 1px solid #33691e !important; }

.overlay-light-green.overlay-darken-4 {
  background: #33691e;
  /* The Fallback */
  background: rgba(51, 105, 30, 0.8); }

.light-green.accent-1 {
  color: #ccff90 !important; }

.bg-light-green.bg-accent-1 {
  background-color: #ccff90 !important; }

.btn-light-green.btn-accent-1 {
  border-color: #689f38 !important;
  background-color: #ccff90 !important; }
  .btn-light-green.btn-accent-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-1:focus, .btn-light-green.btn-accent-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-1 {
  border-color: #ccff90 !important;
  color: #ccff90 !important; }
  .btn-outline-light-green.btn-outline-accent-1:hover {
    background-color: #ccff90 !important; }

.progress-light-green.progress-accent-1 {
  background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-ms-fill {
    background-color: #ccff90; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-1 .progress-bar {
      background-color: #ccff90; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ccff90 !important; }

.border-light-green.border-accent-1 {
  border: 1px solid #ccff90 !important; }

.border-top-light-green.border-top-accent-1 {
  border-top: 1px solid #ccff90 !important; }

.border-bottom-light-green.border-bottom-accent-1 {
  border-bottom: 1px solid #ccff90 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-1 {
  border-left: 1px solid #ccff90 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-1 {
  border-right: 1px solid #ccff90 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-1 {
  border-right: 1px solid #ccff90 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-1 {
  border-left: 1px solid #ccff90 !important; }

.overlay-light-green.overlay-accent-1 {
  background: #ccff90;
  /* The Fallback */
  background: rgba(204, 255, 144, 0.8); }

.light-green.accent-2 {
  color: #b2ff59 !important; }

.bg-light-green.bg-accent-2 {
  background-color: #b2ff59 !important; }

.btn-light-green.btn-accent-2 {
  border-color: #689f38 !important;
  background-color: #b2ff59 !important; }
  .btn-light-green.btn-accent-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-2:focus, .btn-light-green.btn-accent-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-2 {
  border-color: #b2ff59 !important;
  color: #b2ff59 !important; }
  .btn-outline-light-green.btn-outline-accent-2:hover {
    background-color: #b2ff59 !important; }

.progress-light-green.progress-accent-2 {
  background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-ms-fill {
    background-color: #b2ff59; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-2 .progress-bar {
      background-color: #b2ff59; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ff59 !important; }

.border-light-green.border-accent-2 {
  border: 1px solid #b2ff59 !important; }

.border-top-light-green.border-top-accent-2 {
  border-top: 1px solid #b2ff59 !important; }

.border-bottom-light-green.border-bottom-accent-2 {
  border-bottom: 1px solid #b2ff59 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-2 {
  border-left: 1px solid #b2ff59 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-2 {
  border-right: 1px solid #b2ff59 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-2 {
  border-right: 1px solid #b2ff59 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-2 {
  border-left: 1px solid #b2ff59 !important; }

.overlay-light-green.overlay-accent-2 {
  background: #b2ff59;
  /* The Fallback */
  background: rgba(178, 255, 89, 0.8); }

.light-green.accent-3 {
  color: #76ff03 !important; }

.bg-light-green.bg-accent-3 {
  background-color: #76ff03 !important; }

.btn-light-green.btn-accent-3 {
  border-color: #689f38 !important;
  background-color: #76ff03 !important; }
  .btn-light-green.btn-accent-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-3:focus, .btn-light-green.btn-accent-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-3 {
  border-color: #76ff03 !important;
  color: #76ff03 !important; }
  .btn-outline-light-green.btn-outline-accent-3:hover {
    background-color: #76ff03 !important; }

.progress-light-green.progress-accent-3 {
  background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-ms-fill {
    background-color: #76ff03; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-3 .progress-bar {
      background-color: #76ff03; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #76ff03 !important; }

.border-light-green.border-accent-3 {
  border: 1px solid #76ff03 !important; }

.border-top-light-green.border-top-accent-3 {
  border-top: 1px solid #76ff03 !important; }

.border-bottom-light-green.border-bottom-accent-3 {
  border-bottom: 1px solid #76ff03 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-3 {
  border-left: 1px solid #76ff03 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-3 {
  border-right: 1px solid #76ff03 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-3 {
  border-right: 1px solid #76ff03 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-3 {
  border-left: 1px solid #76ff03 !important; }

.overlay-light-green.overlay-accent-3 {
  background: #76ff03;
  /* The Fallback */
  background: rgba(118, 255, 3, 0.8); }

.light-green.accent-4 {
  color: #64dd17 !important; }

.bg-light-green.bg-accent-4 {
  background-color: #64dd17 !important; }

.btn-light-green.btn-accent-4 {
  border-color: #689f38 !important;
  background-color: #64dd17 !important; }
  .btn-light-green.btn-accent-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-4:focus, .btn-light-green.btn-accent-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-4 {
  border-color: #64dd17 !important;
  color: #64dd17 !important; }
  .btn-outline-light-green.btn-outline-accent-4:hover {
    background-color: #64dd17 !important; }

.progress-light-green.progress-accent-4 {
  background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-ms-fill {
    background-color: #64dd17; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-4 .progress-bar {
      background-color: #64dd17; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64dd17 !important; }

.border-light-green.border-accent-4 {
  border: 1px solid #64dd17 !important; }

.border-top-light-green.border-top-accent-4 {
  border-top: 1px solid #64dd17 !important; }

.border-bottom-light-green.border-bottom-accent-4 {
  border-bottom: 1px solid #64dd17 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-4 {
  border-left: 1px solid #64dd17 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-4 {
  border-right: 1px solid #64dd17 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-4 {
  border-right: 1px solid #64dd17 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-4 {
  border-left: 1px solid #64dd17 !important; }

.overlay-light-green.overlay-accent-4 {
  background: #64dd17;
  /* The Fallback */
  background: rgba(100, 221, 23, 0.8); }

.lime.lighten-5 {
  color: #f9fbe7 !important; }

.bg-lime.bg-lighten-5 {
  background-color: #f9fbe7 !important; }

.btn-lime.btn-lighten-5 {
  border-color: #afb42b !important;
  background-color: #f9fbe7 !important; }
  .btn-lime.btn-lighten-5:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-5:focus, .btn-lime.btn-lighten-5:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-5 {
  border-color: #f9fbe7 !important;
  color: #f9fbe7 !important; }
  .btn-outline-lime.btn-outline-lighten-5:hover {
    background-color: #f9fbe7 !important; }

.progress-lime.progress-lighten-5 {
  background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-ms-fill {
    background-color: #f9fbe7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-5 .progress-bar {
      background-color: #f9fbe7; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9fbe7 !important; }

.border-lime.border-lighten-5 {
  border: 1px solid #f9fbe7 !important; }

.border-top-lime.border-top-lighten-5 {
  border-top: 1px solid #f9fbe7 !important; }

.border-bottom-lime.border-bottom-lighten-5 {
  border-bottom: 1px solid #f9fbe7 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-5 {
  border-left: 1px solid #f9fbe7 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-5 {
  border-right: 1px solid #f9fbe7 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-5 {
  border-right: 1px solid #f9fbe7 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-5 {
  border-left: 1px solid #f9fbe7 !important; }

.overlay-lime.overlay-lighten-5 {
  background: #f9fbe7;
  /* The Fallback */
  background: rgba(249, 251, 231, 0.8); }

.lime.lighten-4 {
  color: #f0f4c3 !important; }

.bg-lime.bg-lighten-4 {
  background-color: #f0f4c3 !important; }

.btn-lime.btn-lighten-4 {
  border-color: #afb42b !important;
  background-color: #f0f4c3 !important; }
  .btn-lime.btn-lighten-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-4:focus, .btn-lime.btn-lighten-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-4 {
  border-color: #f0f4c3 !important;
  color: #f0f4c3 !important; }
  .btn-outline-lime.btn-outline-lighten-4:hover {
    background-color: #f0f4c3 !important; }

.progress-lime.progress-lighten-4 {
  background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-ms-fill {
    background-color: #f0f4c3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-4 .progress-bar {
      background-color: #f0f4c3; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f0f4c3 !important; }

.border-lime.border-lighten-4 {
  border: 1px solid #f0f4c3 !important; }

.border-top-lime.border-top-lighten-4 {
  border-top: 1px solid #f0f4c3 !important; }

.border-bottom-lime.border-bottom-lighten-4 {
  border-bottom: 1px solid #f0f4c3 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-4 {
  border-left: 1px solid #f0f4c3 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-4 {
  border-right: 1px solid #f0f4c3 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-4 {
  border-right: 1px solid #f0f4c3 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-4 {
  border-left: 1px solid #f0f4c3 !important; }

.overlay-lime.overlay-lighten-4 {
  background: #f0f4c3;
  /* The Fallback */
  background: rgba(240, 244, 195, 0.8); }

.lime.lighten-3 {
  color: #e6ee9c !important; }

.bg-lime.bg-lighten-3 {
  background-color: #e6ee9c !important; }

.btn-lime.btn-lighten-3 {
  border-color: #afb42b !important;
  background-color: #e6ee9c !important; }
  .btn-lime.btn-lighten-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-3:focus, .btn-lime.btn-lighten-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-3 {
  border-color: #e6ee9c !important;
  color: #e6ee9c !important; }
  .btn-outline-lime.btn-outline-lighten-3:hover {
    background-color: #e6ee9c !important; }

.progress-lime.progress-lighten-3 {
  background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-ms-fill {
    background-color: #e6ee9c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-3 .progress-bar {
      background-color: #e6ee9c; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e6ee9c !important; }

.border-lime.border-lighten-3 {
  border: 1px solid #e6ee9c !important; }

.border-top-lime.border-top-lighten-3 {
  border-top: 1px solid #e6ee9c !important; }

.border-bottom-lime.border-bottom-lighten-3 {
  border-bottom: 1px solid #e6ee9c !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-3 {
  border-left: 1px solid #e6ee9c !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-3 {
  border-right: 1px solid #e6ee9c !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-3 {
  border-right: 1px solid #e6ee9c !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-3 {
  border-left: 1px solid #e6ee9c !important; }

.overlay-lime.overlay-lighten-3 {
  background: #e6ee9c;
  /* The Fallback */
  background: rgba(230, 238, 156, 0.8); }

.lime.lighten-2 {
  color: #dce775 !important; }

.bg-lime.bg-lighten-2 {
  background-color: #dce775 !important; }

.btn-lime.btn-lighten-2 {
  border-color: #afb42b !important;
  background-color: #dce775 !important; }
  .btn-lime.btn-lighten-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-2:focus, .btn-lime.btn-lighten-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-2 {
  border-color: #dce775 !important;
  color: #dce775 !important; }
  .btn-outline-lime.btn-outline-lighten-2:hover {
    background-color: #dce775 !important; }

.progress-lime.progress-lighten-2 {
  background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-ms-fill {
    background-color: #dce775; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-2 .progress-bar {
      background-color: #dce775; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dce775 !important; }

.border-lime.border-lighten-2 {
  border: 1px solid #dce775 !important; }

.border-top-lime.border-top-lighten-2 {
  border-top: 1px solid #dce775 !important; }

.border-bottom-lime.border-bottom-lighten-2 {
  border-bottom: 1px solid #dce775 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-2 {
  border-left: 1px solid #dce775 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-2 {
  border-right: 1px solid #dce775 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-2 {
  border-right: 1px solid #dce775 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-2 {
  border-left: 1px solid #dce775 !important; }

.overlay-lime.overlay-lighten-2 {
  background: #dce775;
  /* The Fallback */
  background: rgba(220, 231, 117, 0.8); }

.lime.lighten-1 {
  color: #d4e157 !important; }

.bg-lime.bg-lighten-1 {
  background-color: #d4e157 !important; }

.btn-lime.btn-lighten-1 {
  border-color: #afb42b !important;
  background-color: #d4e157 !important; }
  .btn-lime.btn-lighten-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-1:focus, .btn-lime.btn-lighten-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-1 {
  border-color: #d4e157 !important;
  color: #d4e157 !important; }
  .btn-outline-lime.btn-outline-lighten-1:hover {
    background-color: #d4e157 !important; }

.progress-lime.progress-lighten-1 {
  background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-ms-fill {
    background-color: #d4e157; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-1 .progress-bar {
      background-color: #d4e157; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d4e157 !important; }

.border-lime.border-lighten-1 {
  border: 1px solid #d4e157 !important; }

.border-top-lime.border-top-lighten-1 {
  border-top: 1px solid #d4e157 !important; }

.border-bottom-lime.border-bottom-lighten-1 {
  border-bottom: 1px solid #d4e157 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-1 {
  border-left: 1px solid #d4e157 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-1 {
  border-right: 1px solid #d4e157 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-1 {
  border-right: 1px solid #d4e157 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-1 {
  border-left: 1px solid #d4e157 !important; }

.overlay-lime.overlay-lighten-1 {
  background: #d4e157;
  /* The Fallback */
  background: rgba(212, 225, 87, 0.8); }

.lime {
  color: #cddc39 !important; }

.bg-lime {
  background-color: #cddc39 !important; }
  .bg-lime .card-header, .bg-lime .card-footer {
    background-color: transparent; }

.toast-lime {
  background-color: #cddc39; }

.alert-lime {
  border-color: #cddc39 !important;
  background-color: #d5e157 !important;
  color: #4d540f !important; }
  .alert-lime .alert-link {
    color: #2d3109 !important; }

.border-lime {
  border-color: #cddc39; }

.border-top-lime {
  border-top-color: #cddc39; }

.border-bottom-lime {
  border-bottom-color: #cddc39; }

[dir="ltr"] .border-left-lime {
  border-left-color: #cddc39; }

[dir="ltr"] .border-right-lime {
  border-right-color: #cddc39; }

[dir="rtl"] .border-left-lime {
  border-right-color: #cddc39; }

[dir="rtl"] .border-right-lime {
  border-left-color: #cddc39; }

.badge-lime {
  background-color: #cddc39; }

.panel-lime {
  border-color: #cddc39; }
  .panel-lime .panel-heading {
    color: #FFF;
    border-color: #cddc39;
    background-color: #d2e04f; }

.bg-lime.tag-glow, .border-lime.tag-glow {
  box-shadow: 0px 0px 10px #cddc39; }

.overlay-lime {
  background: #cddc39;
  /* The Fallback */
  background: rgba(205, 220, 57, 0.8); }

.card.card-outline-lime {
  border-width: 1px;
  border-style: solid;
  border-color: #cddc39;
  background-color: transparent; }
  .card.card-outline-lime .card-header, .card.card-outline-lime .card-footer {
    background-color: transparent; }

.btn-lime.btn-flat {
  background-color: transparent !important;
  color: #cddc39;
  border: none; }

.btn-lime.btn-raised, .btn-lime.btn-fab {
  background-color: #cddc39 !important;
  color: #fff !important;
  border-color: #cddc39; }
  .btn-lime.btn-raised.active, .btn-lime.btn-fab.active {
    background-color: #becd24 !important;
    border-color: #becd24 !important; }

.btn-group-raised .btn-lime {
  background-color: #cddc39 !important;
  color: #fff !important; }

.btn-outline-lime {
  border: 1px solid;
  border-color: #cddc39;
  background-color: transparent;
  color: #cddc39;
  box-shadow: none !important; }
  .btn-outline-lime:focus {
    background-color: transparent !important;
    color: #cddc39 !important;
    box-shadow: transparent !important; }
  .btn-outline-lime.active {
    background-color: #cddc39 !important;
    color: #FFF !important; }
  .btn-outline-lime:hover {
    background-color: #cada2c !important;
    color: #FFF !important; }
    .btn-outline-lime:hover svg {
      color: #FFF !important; }

.btn-outline-lime.btn-raised, .btn-outline-lime.btn-fab {
  border: 1px solid;
  border-color: #cddc39;
  background-color: transparent;
  color: #cddc39;
  box-shadow: none !important; }
  .btn-outline-lime.btn-raised:focus, .btn-outline-lime.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-lime.btn-raised.active, .btn-outline-lime.btn-fab.active {
    background-color: #cddc39 !important;
    color: #FFF !important; }
  .btn-outline-lime.btn-raised:hover, .btn-outline-lime.btn-fab:hover {
    background-color: #cada2c !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-lime {
  background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-webkit-progress-value {
    background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-moz-progress-bar {
    background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-ms-fill {
    background-color: #cddc39; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-lime .progress-bar {
      background-color: #cddc39; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cddc39 !important; }

.lime.darken-1 {
  color: #c0ca33 !important; }

.bg-lime.bg-darken-1 {
  background-color: #c0ca33 !important; }

.btn-lime.btn-darken-1 {
  border-color: #afb42b !important;
  background-color: #c0ca33 !important; }
  .btn-lime.btn-darken-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-1:focus, .btn-lime.btn-darken-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-1 {
  border-color: #c0ca33 !important;
  color: #c0ca33 !important; }
  .btn-outline-lime.btn-outline-darken-1:hover {
    background-color: #c0ca33 !important; }

.progress-lime.progress-darken-1 {
  background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-webkit-progress-value {
    background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-moz-progress-bar {
    background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-ms-fill {
    background-color: #c0ca33; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-1 .progress-bar {
      background-color: #c0ca33; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c0ca33 !important; }

.border-lime.border-darken-1 {
  border: 1px solid #c0ca33 !important; }

.border-top-lime.border-top-darken-1 {
  border-top: 1px solid #c0ca33 !important; }

.border-bottom-lime.border-bottom-darken-1 {
  border-bottom: 1px solid #c0ca33 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-1 {
  border-left: 1px solid #c0ca33 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-1 {
  border-right: 1px solid #c0ca33 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-1 {
  border-right: 1px solid #c0ca33 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-1 {
  border-left: 1px solid #c0ca33 !important; }

.overlay-lime.overlay-darken-1 {
  background: #c0ca33;
  /* The Fallback */
  background: rgba(192, 202, 51, 0.8); }

.lime.darken-2 {
  color: #afb42b !important; }

.bg-lime.bg-darken-2 {
  background-color: #afb42b !important; }

.btn-lime.btn-darken-2 {
  border-color: #afb42b !important;
  background-color: #afb42b !important; }
  .btn-lime.btn-darken-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-2:focus, .btn-lime.btn-darken-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-2 {
  border-color: #afb42b !important;
  color: #afb42b !important; }
  .btn-outline-lime.btn-outline-darken-2:hover {
    background-color: #afb42b !important; }

.progress-lime.progress-darken-2 {
  background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-webkit-progress-value {
    background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-moz-progress-bar {
    background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-ms-fill {
    background-color: #afb42b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-2 .progress-bar {
      background-color: #afb42b; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #afb42b !important; }

.border-lime.border-darken-2 {
  border: 1px solid #afb42b !important; }

.border-top-lime.border-top-darken-2 {
  border-top: 1px solid #afb42b !important; }

.border-bottom-lime.border-bottom-darken-2 {
  border-bottom: 1px solid #afb42b !important; }

[dir="ltr"] .border-left-lime.border-left-darken-2 {
  border-left: 1px solid #afb42b !important; }

[dir="ltr"] .border-right-lime.border-right-darken-2 {
  border-right: 1px solid #afb42b !important; }

[dir="rtl"] .border-left-lime.border-left-darken-2 {
  border-right: 1px solid #afb42b !important; }

[dir="rtl"] .border-right-lime.border-right-darken-2 {
  border-left: 1px solid #afb42b !important; }

.overlay-lime.overlay-darken-2 {
  background: #afb42b;
  /* The Fallback */
  background: rgba(175, 180, 43, 0.8); }

.lime.darken-3 {
  color: #9e9d24 !important; }

.bg-lime.bg-darken-3 {
  background-color: #9e9d24 !important; }

.btn-lime.btn-darken-3 {
  border-color: #afb42b !important;
  background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-3:focus, .btn-lime.btn-darken-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-3 {
  border-color: #9e9d24 !important;
  color: #9e9d24 !important; }
  .btn-outline-lime.btn-outline-darken-3:hover {
    background-color: #9e9d24 !important; }

.progress-lime.progress-darken-3 {
  background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-webkit-progress-value {
    background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-moz-progress-bar {
    background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-ms-fill {
    background-color: #9e9d24; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-3 .progress-bar {
      background-color: #9e9d24; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9d24 !important; }

.border-lime.border-darken-3 {
  border: 1px solid #9e9d24 !important; }

.border-top-lime.border-top-darken-3 {
  border-top: 1px solid #9e9d24 !important; }

.border-bottom-lime.border-bottom-darken-3 {
  border-bottom: 1px solid #9e9d24 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-3 {
  border-left: 1px solid #9e9d24 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-3 {
  border-right: 1px solid #9e9d24 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-3 {
  border-right: 1px solid #9e9d24 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-3 {
  border-left: 1px solid #9e9d24 !important; }

.overlay-lime.overlay-darken-3 {
  background: #9e9d24;
  /* The Fallback */
  background: rgba(158, 157, 36, 0.8); }

.lime.darken-4 {
  color: #827717 !important; }

.bg-lime.bg-darken-4 {
  background-color: #827717 !important; }

.btn-lime.btn-darken-4 {
  border-color: #afb42b !important;
  background-color: #827717 !important; }
  .btn-lime.btn-darken-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-4:focus, .btn-lime.btn-darken-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-4 {
  border-color: #827717 !important;
  color: #827717 !important; }
  .btn-outline-lime.btn-outline-darken-4:hover {
    background-color: #827717 !important; }

.progress-lime.progress-darken-4 {
  background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-webkit-progress-value {
    background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-moz-progress-bar {
    background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-ms-fill {
    background-color: #827717; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-4 .progress-bar {
      background-color: #827717; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #827717 !important; }

.border-lime.border-darken-4 {
  border: 1px solid #827717 !important; }

.border-top-lime.border-top-darken-4 {
  border-top: 1px solid #827717 !important; }

.border-bottom-lime.border-bottom-darken-4 {
  border-bottom: 1px solid #827717 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-4 {
  border-left: 1px solid #827717 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-4 {
  border-right: 1px solid #827717 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-4 {
  border-right: 1px solid #827717 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-4 {
  border-left: 1px solid #827717 !important; }

.overlay-lime.overlay-darken-4 {
  background: #827717;
  /* The Fallback */
  background: rgba(130, 119, 23, 0.8); }

.lime.accent-1 {
  color: #f4ff81 !important; }

.bg-lime.bg-accent-1 {
  background-color: #f4ff81 !important; }

.btn-lime.btn-accent-1 {
  border-color: #afb42b !important;
  background-color: #f4ff81 !important; }
  .btn-lime.btn-accent-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-1:focus, .btn-lime.btn-accent-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-1 {
  border-color: #f4ff81 !important;
  color: #f4ff81 !important; }
  .btn-outline-lime.btn-outline-accent-1:hover {
    background-color: #f4ff81 !important; }

.progress-lime.progress-accent-1 {
  background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-ms-fill {
    background-color: #f4ff81; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-1 .progress-bar {
      background-color: #f4ff81; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4ff81 !important; }

.border-lime.border-accent-1 {
  border: 1px solid #f4ff81 !important; }

.border-top-lime.border-top-accent-1 {
  border-top: 1px solid #f4ff81 !important; }

.border-bottom-lime.border-bottom-accent-1 {
  border-bottom: 1px solid #f4ff81 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-1 {
  border-left: 1px solid #f4ff81 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-1 {
  border-right: 1px solid #f4ff81 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-1 {
  border-right: 1px solid #f4ff81 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-1 {
  border-left: 1px solid #f4ff81 !important; }

.overlay-lime.overlay-accent-1 {
  background: #f4ff81;
  /* The Fallback */
  background: rgba(244, 255, 129, 0.8); }

.lime.accent-2 {
  color: #eeff41 !important; }

.bg-lime.bg-accent-2 {
  background-color: #eeff41 !important; }

.btn-lime.btn-accent-2 {
  border-color: #afb42b !important;
  background-color: #eeff41 !important; }
  .btn-lime.btn-accent-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-2:focus, .btn-lime.btn-accent-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-2 {
  border-color: #eeff41 !important;
  color: #eeff41 !important; }
  .btn-outline-lime.btn-outline-accent-2:hover {
    background-color: #eeff41 !important; }

.progress-lime.progress-accent-2 {
  background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-ms-fill {
    background-color: #eeff41; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-2 .progress-bar {
      background-color: #eeff41; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eeff41 !important; }

.border-lime.border-accent-2 {
  border: 1px solid #eeff41 !important; }

.border-top-lime.border-top-accent-2 {
  border-top: 1px solid #eeff41 !important; }

.border-bottom-lime.border-bottom-accent-2 {
  border-bottom: 1px solid #eeff41 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-2 {
  border-left: 1px solid #eeff41 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-2 {
  border-right: 1px solid #eeff41 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-2 {
  border-right: 1px solid #eeff41 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-2 {
  border-left: 1px solid #eeff41 !important; }

.overlay-lime.overlay-accent-2 {
  background: #eeff41;
  /* The Fallback */
  background: rgba(238, 255, 65, 0.8); }

.lime.accent-3 {
  color: #c6ff00 !important; }

.bg-lime.bg-accent-3 {
  background-color: #c6ff00 !important; }

.btn-lime.btn-accent-3 {
  border-color: #afb42b !important;
  background-color: #c6ff00 !important; }
  .btn-lime.btn-accent-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-3:focus, .btn-lime.btn-accent-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-3 {
  border-color: #c6ff00 !important;
  color: #c6ff00 !important; }
  .btn-outline-lime.btn-outline-accent-3:hover {
    background-color: #c6ff00 !important; }

.progress-lime.progress-accent-3 {
  background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-webkit-progress-value {
    background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-moz-progress-bar {
    background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-ms-fill {
    background-color: #c6ff00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-3 .progress-bar {
      background-color: #c6ff00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c6ff00 !important; }

.border-lime.border-accent-3 {
  border: 1px solid #c6ff00 !important; }

.border-top-lime.border-top-accent-3 {
  border-top: 1px solid #c6ff00 !important; }

.border-bottom-lime.border-bottom-accent-3 {
  border-bottom: 1px solid #c6ff00 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-3 {
  border-left: 1px solid #c6ff00 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-3 {
  border-right: 1px solid #c6ff00 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-3 {
  border-right: 1px solid #c6ff00 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-3 {
  border-left: 1px solid #c6ff00 !important; }

.overlay-lime.overlay-accent-3 {
  background: #c6ff00;
  /* The Fallback */
  background: rgba(198, 255, 0, 0.8); }

.lime.accent-4 {
  color: #aeea00 !important; }

.bg-lime.bg-accent-4 {
  background-color: #aeea00 !important; }

.btn-lime.btn-accent-4 {
  border-color: #afb42b !important;
  background-color: #aeea00 !important; }
  .btn-lime.btn-accent-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-4:focus, .btn-lime.btn-accent-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-4 {
  border-color: #aeea00 !important;
  color: #aeea00 !important; }
  .btn-outline-lime.btn-outline-accent-4:hover {
    background-color: #aeea00 !important; }

.progress-lime.progress-accent-4 {
  background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-webkit-progress-value {
    background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-moz-progress-bar {
    background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-ms-fill {
    background-color: #aeea00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-4 .progress-bar {
      background-color: #aeea00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aeea00 !important; }

.border-lime.border-accent-4 {
  border: 1px solid #aeea00 !important; }

.border-top-lime.border-top-accent-4 {
  border-top: 1px solid #aeea00 !important; }

.border-bottom-lime.border-bottom-accent-4 {
  border-bottom: 1px solid #aeea00 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-4 {
  border-left: 1px solid #aeea00 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-4 {
  border-right: 1px solid #aeea00 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-4 {
  border-right: 1px solid #aeea00 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-4 {
  border-left: 1px solid #aeea00 !important; }

.overlay-lime.overlay-accent-4 {
  background: #aeea00;
  /* The Fallback */
  background: rgba(174, 234, 0, 0.8); }

.yellow.lighten-5 {
  color: #fffde7 !important; }

.bg-yellow.bg-lighten-5 {
  background-color: #fffde7 !important; }

.btn-yellow.btn-lighten-5 {
  border-color: #fbc02d !important;
  background-color: #fffde7 !important; }
  .btn-yellow.btn-lighten-5:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-5:focus, .btn-yellow.btn-lighten-5:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-5 {
  border-color: #fffde7 !important;
  color: #fffde7 !important; }
  .btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #fffde7 !important; }

.progress-yellow.progress-lighten-5 {
  background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-ms-fill {
    background-color: #fffde7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-5 .progress-bar {
      background-color: #fffde7; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fffde7 !important; }

.border-yellow.border-lighten-5 {
  border: 1px solid #fffde7 !important; }

.border-top-yellow.border-top-lighten-5 {
  border-top: 1px solid #fffde7 !important; }

.border-bottom-yellow.border-bottom-lighten-5 {
  border-bottom: 1px solid #fffde7 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-5 {
  border-left: 1px solid #fffde7 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-5 {
  border-right: 1px solid #fffde7 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-5 {
  border-right: 1px solid #fffde7 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-5 {
  border-left: 1px solid #fffde7 !important; }

.overlay-yellow.overlay-lighten-5 {
  background: #fffde7;
  /* The Fallback */
  background: rgba(255, 253, 231, 0.8); }

.yellow.lighten-4 {
  color: #fff9c4 !important; }

.bg-yellow.bg-lighten-4 {
  background-color: #fff9c4 !important; }

.btn-yellow.btn-lighten-4 {
  border-color: #fbc02d !important;
  background-color: #fff9c4 !important; }
  .btn-yellow.btn-lighten-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-4:focus, .btn-yellow.btn-lighten-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-4 {
  border-color: #fff9c4 !important;
  color: #fff9c4 !important; }
  .btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #fff9c4 !important; }

.progress-yellow.progress-lighten-4 {
  background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-ms-fill {
    background-color: #fff9c4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-4 .progress-bar {
      background-color: #fff9c4; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff9c4 !important; }

.border-yellow.border-lighten-4 {
  border: 1px solid #fff9c4 !important; }

.border-top-yellow.border-top-lighten-4 {
  border-top: 1px solid #fff9c4 !important; }

.border-bottom-yellow.border-bottom-lighten-4 {
  border-bottom: 1px solid #fff9c4 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-4 {
  border-left: 1px solid #fff9c4 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-4 {
  border-right: 1px solid #fff9c4 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-4 {
  border-right: 1px solid #fff9c4 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-4 {
  border-left: 1px solid #fff9c4 !important; }

.overlay-yellow.overlay-lighten-4 {
  background: #fff9c4;
  /* The Fallback */
  background: rgba(255, 249, 196, 0.8); }

.yellow.lighten-3 {
  color: #fff59d !important; }

.bg-yellow.bg-lighten-3 {
  background-color: #fff59d !important; }

.btn-yellow.btn-lighten-3 {
  border-color: #fbc02d !important;
  background-color: #fff59d !important; }
  .btn-yellow.btn-lighten-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-3:focus, .btn-yellow.btn-lighten-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-3 {
  border-color: #fff59d !important;
  color: #fff59d !important; }
  .btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #fff59d !important; }

.progress-yellow.progress-lighten-3 {
  background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-ms-fill {
    background-color: #fff59d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-3 .progress-bar {
      background-color: #fff59d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff59d !important; }

.border-yellow.border-lighten-3 {
  border: 1px solid #fff59d !important; }

.border-top-yellow.border-top-lighten-3 {
  border-top: 1px solid #fff59d !important; }

.border-bottom-yellow.border-bottom-lighten-3 {
  border-bottom: 1px solid #fff59d !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-3 {
  border-left: 1px solid #fff59d !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-3 {
  border-right: 1px solid #fff59d !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-3 {
  border-right: 1px solid #fff59d !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-3 {
  border-left: 1px solid #fff59d !important; }

.overlay-yellow.overlay-lighten-3 {
  background: #fff59d;
  /* The Fallback */
  background: rgba(255, 245, 157, 0.8); }

.yellow.lighten-2 {
  color: #fff176 !important; }

.bg-yellow.bg-lighten-2 {
  background-color: #fff176 !important; }

.btn-yellow.btn-lighten-2 {
  border-color: #fbc02d !important;
  background-color: #fff176 !important; }
  .btn-yellow.btn-lighten-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-2:focus, .btn-yellow.btn-lighten-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-2 {
  border-color: #fff176 !important;
  color: #fff176 !important; }
  .btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #fff176 !important; }

.progress-yellow.progress-lighten-2 {
  background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-ms-fill {
    background-color: #fff176; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-2 .progress-bar {
      background-color: #fff176; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff176 !important; }

.border-yellow.border-lighten-2 {
  border: 1px solid #fff176 !important; }

.border-top-yellow.border-top-lighten-2 {
  border-top: 1px solid #fff176 !important; }

.border-bottom-yellow.border-bottom-lighten-2 {
  border-bottom: 1px solid #fff176 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-2 {
  border-left: 1px solid #fff176 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-2 {
  border-right: 1px solid #fff176 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-2 {
  border-right: 1px solid #fff176 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-2 {
  border-left: 1px solid #fff176 !important; }

.overlay-yellow.overlay-lighten-2 {
  background: #fff176;
  /* The Fallback */
  background: rgba(255, 241, 118, 0.8); }

.yellow.lighten-1 {
  color: #ffee58 !important; }

.bg-yellow.bg-lighten-1 {
  background-color: #ffee58 !important; }

.btn-yellow.btn-lighten-1 {
  border-color: #fbc02d !important;
  background-color: #ffee58 !important; }
  .btn-yellow.btn-lighten-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-1:focus, .btn-yellow.btn-lighten-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-1 {
  border-color: #ffee58 !important;
  color: #ffee58 !important; }
  .btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #ffee58 !important; }

.progress-yellow.progress-lighten-1 {
  background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-ms-fill {
    background-color: #ffee58; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-1 .progress-bar {
      background-color: #ffee58; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffee58 !important; }

.border-yellow.border-lighten-1 {
  border: 1px solid #ffee58 !important; }

.border-top-yellow.border-top-lighten-1 {
  border-top: 1px solid #ffee58 !important; }

.border-bottom-yellow.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffee58 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-1 {
  border-left: 1px solid #ffee58 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-1 {
  border-right: 1px solid #ffee58 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-1 {
  border-right: 1px solid #ffee58 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-1 {
  border-left: 1px solid #ffee58 !important; }

.overlay-yellow.overlay-lighten-1 {
  background: #ffee58;
  /* The Fallback */
  background: rgba(255, 238, 88, 0.8); }

.yellow {
  color: #ffeb3b !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }
  .bg-yellow .card-header, .bg-yellow .card-footer {
    background-color: transparent; }

.toast-yellow {
  background-color: #ffeb3b; }

.alert-yellow {
  border-color: #ffeb3b !important;
  background-color: #ffef5f !important;
  color: #887a00 !important; }
  .alert-yellow .alert-link {
    color: #5f5500 !important; }

.border-yellow {
  border-color: #ffeb3b; }

.border-top-yellow {
  border-top-color: #ffeb3b; }

.border-bottom-yellow {
  border-bottom-color: #ffeb3b; }

[dir="ltr"] .border-left-yellow {
  border-left-color: #ffeb3b; }

[dir="ltr"] .border-right-yellow {
  border-right-color: #ffeb3b; }

[dir="rtl"] .border-left-yellow {
  border-right-color: #ffeb3b; }

[dir="rtl"] .border-right-yellow {
  border-left-color: #ffeb3b; }

.badge-yellow {
  background-color: #ffeb3b; }

.panel-yellow {
  border-color: #ffeb3b; }
  .panel-yellow .panel-heading {
    color: #FFF;
    border-color: #ffeb3b;
    background-color: #ffee55; }

.bg-yellow.tag-glow, .border-yellow.tag-glow {
  box-shadow: 0px 0px 10px #ffeb3b; }

.overlay-yellow {
  background: #ffeb3b;
  /* The Fallback */
  background: rgba(255, 235, 59, 0.8); }

.card.card-outline-yellow {
  border-width: 1px;
  border-style: solid;
  border-color: #ffeb3b;
  background-color: transparent; }
  .card.card-outline-yellow .card-header, .card.card-outline-yellow .card-footer {
    background-color: transparent; }

.btn-yellow.btn-flat {
  background-color: transparent !important;
  color: #ffeb3b;
  border: none; }

.btn-yellow.btn-raised, .btn-yellow.btn-fab {
  background-color: #ffeb3b !important;
  color: #fff !important;
  border-color: #ffeb3b; }
  .btn-yellow.btn-raised.active, .btn-yellow.btn-fab.active {
    background-color: #ffe717 !important;
    border-color: #ffe717 !important; }

.btn-group-raised .btn-yellow {
  background-color: #ffeb3b !important;
  color: #fff !important; }

.btn-outline-yellow {
  border: 1px solid;
  border-color: #ffeb3b;
  background-color: transparent;
  color: #ffeb3b;
  box-shadow: none !important; }
  .btn-outline-yellow:focus {
    background-color: transparent !important;
    color: #ffeb3b !important;
    box-shadow: transparent !important; }
  .btn-outline-yellow.active {
    background-color: #ffeb3b !important;
    color: #FFF !important; }
  .btn-outline-yellow:hover {
    background-color: #ffe92c !important;
    color: #FFF !important; }
    .btn-outline-yellow:hover svg {
      color: #FFF !important; }

.btn-outline-yellow.btn-raised, .btn-outline-yellow.btn-fab {
  border: 1px solid;
  border-color: #ffeb3b;
  background-color: transparent;
  color: #ffeb3b;
  box-shadow: none !important; }
  .btn-outline-yellow.btn-raised:focus, .btn-outline-yellow.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-yellow.btn-raised.active, .btn-outline-yellow.btn-fab.active {
    background-color: #ffeb3b !important;
    color: #FFF !important; }
  .btn-outline-yellow.btn-raised:hover, .btn-outline-yellow.btn-fab:hover {
    background-color: #ffe92c !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-yellow {
  background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-webkit-progress-value {
    background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-moz-progress-bar {
    background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-ms-fill {
    background-color: #ffeb3b; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-yellow .progress-bar {
      background-color: #ffeb3b; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffeb3b !important; }

.yellow.darken-1 {
  color: #fdd835 !important; }

.bg-yellow.bg-darken-1 {
  background-color: #fdd835 !important; }

.btn-yellow.btn-darken-1 {
  border-color: #fbc02d !important;
  background-color: #fdd835 !important; }
  .btn-yellow.btn-darken-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-1:focus, .btn-yellow.btn-darken-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-1 {
  border-color: #fdd835 !important;
  color: #fdd835 !important; }
  .btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #fdd835 !important; }

.progress-yellow.progress-darken-1 {
  background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-ms-fill {
    background-color: #fdd835; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-1 .progress-bar {
      background-color: #fdd835; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fdd835 !important; }

.border-yellow.border-darken-1 {
  border: 1px solid #fdd835 !important; }

.border-top-yellow.border-top-darken-1 {
  border-top: 1px solid #fdd835 !important; }

.border-bottom-yellow.border-bottom-darken-1 {
  border-bottom: 1px solid #fdd835 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-1 {
  border-left: 1px solid #fdd835 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-1 {
  border-right: 1px solid #fdd835 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-1 {
  border-right: 1px solid #fdd835 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-1 {
  border-left: 1px solid #fdd835 !important; }

.overlay-yellow.overlay-darken-1 {
  background: #fdd835;
  /* The Fallback */
  background: rgba(253, 216, 53, 0.8); }

.yellow.darken-2 {
  color: #fbc02d !important; }

.bg-yellow.bg-darken-2 {
  background-color: #fbc02d !important; }

.btn-yellow.btn-darken-2 {
  border-color: #fbc02d !important;
  background-color: #fbc02d !important; }
  .btn-yellow.btn-darken-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-2:focus, .btn-yellow.btn-darken-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-2 {
  border-color: #fbc02d !important;
  color: #fbc02d !important; }
  .btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #fbc02d !important; }

.progress-yellow.progress-darken-2 {
  background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-webkit-progress-value {
    background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-moz-progress-bar {
    background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-ms-fill {
    background-color: #fbc02d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-2 .progress-bar {
      background-color: #fbc02d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbc02d !important; }

.border-yellow.border-darken-2 {
  border: 1px solid #fbc02d !important; }

.border-top-yellow.border-top-darken-2 {
  border-top: 1px solid #fbc02d !important; }

.border-bottom-yellow.border-bottom-darken-2 {
  border-bottom: 1px solid #fbc02d !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-2 {
  border-left: 1px solid #fbc02d !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-2 {
  border-right: 1px solid #fbc02d !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-2 {
  border-right: 1px solid #fbc02d !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-2 {
  border-left: 1px solid #fbc02d !important; }

.overlay-yellow.overlay-darken-2 {
  background: #fbc02d;
  /* The Fallback */
  background: rgba(251, 192, 45, 0.8); }

.yellow.darken-3 {
  color: #f9a825 !important; }

.bg-yellow.bg-darken-3 {
  background-color: #f9a825 !important; }

.btn-yellow.btn-darken-3 {
  border-color: #fbc02d !important;
  background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-3:focus, .btn-yellow.btn-darken-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-3 {
  border-color: #f9a825 !important;
  color: #f9a825 !important; }
  .btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #f9a825 !important; }

.progress-yellow.progress-darken-3 {
  background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-webkit-progress-value {
    background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-moz-progress-bar {
    background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-ms-fill {
    background-color: #f9a825; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-3 .progress-bar {
      background-color: #f9a825; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9a825 !important; }

.border-yellow.border-darken-3 {
  border: 1px solid #f9a825 !important; }

.border-top-yellow.border-top-darken-3 {
  border-top: 1px solid #f9a825 !important; }

.border-bottom-yellow.border-bottom-darken-3 {
  border-bottom: 1px solid #f9a825 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-3 {
  border-left: 1px solid #f9a825 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-3 {
  border-right: 1px solid #f9a825 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-3 {
  border-right: 1px solid #f9a825 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-3 {
  border-left: 1px solid #f9a825 !important; }

.overlay-yellow.overlay-darken-3 {
  background: #f9a825;
  /* The Fallback */
  background: rgba(249, 168, 37, 0.8); }

.yellow.darken-4 {
  color: #f57f17 !important; }

.bg-yellow.bg-darken-4 {
  background-color: #f57f17 !important; }

.btn-yellow.btn-darken-4 {
  border-color: #fbc02d !important;
  background-color: #f57f17 !important; }
  .btn-yellow.btn-darken-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-4:focus, .btn-yellow.btn-darken-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-4 {
  border-color: #f57f17 !important;
  color: #f57f17 !important; }
  .btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #f57f17 !important; }

.progress-yellow.progress-darken-4 {
  background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-webkit-progress-value {
    background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-moz-progress-bar {
    background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-ms-fill {
    background-color: #f57f17; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-4 .progress-bar {
      background-color: #f57f17; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57f17 !important; }

.border-yellow.border-darken-4 {
  border: 1px solid #f57f17 !important; }

.border-top-yellow.border-top-darken-4 {
  border-top: 1px solid #f57f17 !important; }

.border-bottom-yellow.border-bottom-darken-4 {
  border-bottom: 1px solid #f57f17 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-4 {
  border-left: 1px solid #f57f17 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-4 {
  border-right: 1px solid #f57f17 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-4 {
  border-right: 1px solid #f57f17 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-4 {
  border-left: 1px solid #f57f17 !important; }

.overlay-yellow.overlay-darken-4 {
  background: #f57f17;
  /* The Fallback */
  background: rgba(245, 127, 23, 0.8); }

.yellow.accent-1 {
  color: #ffff8d !important; }

.bg-yellow.bg-accent-1 {
  background-color: #ffff8d !important; }

.btn-yellow.btn-accent-1 {
  border-color: #fbc02d !important;
  background-color: #ffff8d !important; }
  .btn-yellow.btn-accent-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-1:focus, .btn-yellow.btn-accent-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-1 {
  border-color: #ffff8d !important;
  color: #ffff8d !important; }
  .btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #ffff8d !important; }

.progress-yellow.progress-accent-1 {
  background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-ms-fill {
    background-color: #ffff8d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-1 .progress-bar {
      background-color: #ffff8d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffff8d !important; }

.border-yellow.border-accent-1 {
  border: 1px solid #ffff8d !important; }

.border-top-yellow.border-top-accent-1 {
  border-top: 1px solid #ffff8d !important; }

.border-bottom-yellow.border-bottom-accent-1 {
  border-bottom: 1px solid #ffff8d !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-1 {
  border-left: 1px solid #ffff8d !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-1 {
  border-right: 1px solid #ffff8d !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-1 {
  border-right: 1px solid #ffff8d !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-1 {
  border-left: 1px solid #ffff8d !important; }

.overlay-yellow.overlay-accent-1 {
  background: #ffff8d;
  /* The Fallback */
  background: rgba(255, 255, 141, 0.8); }

.yellow.accent-2 {
  color: #ff0 !important; }

.bg-yellow.bg-accent-2 {
  background-color: #ff0 !important; }

.btn-yellow.btn-accent-2 {
  border-color: #fbc02d !important;
  background-color: #ff0 !important; }
  .btn-yellow.btn-accent-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-2:focus, .btn-yellow.btn-accent-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-2 {
  border-color: #ff0 !important;
  color: #ff0 !important; }
  .btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #ff0 !important; }

.progress-yellow.progress-accent-2 {
  background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-ms-fill {
    background-color: #ff0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-2 .progress-bar {
      background-color: #ff0; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff0 !important; }

.border-yellow.border-accent-2 {
  border: 1px solid #ff0 !important; }

.border-top-yellow.border-top-accent-2 {
  border-top: 1px solid #ff0 !important; }

.border-bottom-yellow.border-bottom-accent-2 {
  border-bottom: 1px solid #ff0 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-2 {
  border-left: 1px solid #ff0 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-2 {
  border-right: 1px solid #ff0 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-2 {
  border-right: 1px solid #ff0 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-2 {
  border-left: 1px solid #ff0 !important; }

.overlay-yellow.overlay-accent-2 {
  background: #ff0;
  /* The Fallback */
  background: rgba(255, 255, 0, 0.8); }

.yellow.accent-3 {
  color: #ffea00 !important; }

.bg-yellow.bg-accent-3 {
  background-color: #ffea00 !important; }

.btn-yellow.btn-accent-3 {
  border-color: #fbc02d !important;
  background-color: #ffea00 !important; }
  .btn-yellow.btn-accent-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-3:focus, .btn-yellow.btn-accent-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-3 {
  border-color: #ffea00 !important;
  color: #ffea00 !important; }
  .btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #ffea00 !important; }

.progress-yellow.progress-accent-3 {
  background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-ms-fill {
    background-color: #ffea00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-3 .progress-bar {
      background-color: #ffea00; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffea00 !important; }

.border-yellow.border-accent-3 {
  border: 1px solid #ffea00 !important; }

.border-top-yellow.border-top-accent-3 {
  border-top: 1px solid #ffea00 !important; }

.border-bottom-yellow.border-bottom-accent-3 {
  border-bottom: 1px solid #ffea00 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-3 {
  border-left: 1px solid #ffea00 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-3 {
  border-right: 1px solid #ffea00 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-3 {
  border-right: 1px solid #ffea00 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-3 {
  border-left: 1px solid #ffea00 !important; }

.overlay-yellow.overlay-accent-3 {
  background: #ffea00;
  /* The Fallback */
  background: rgba(255, 234, 0, 0.8); }

.yellow.accent-4 {
  color: #ffd600 !important; }

.bg-yellow.bg-accent-4 {
  background-color: #ffd600 !important; }

.btn-yellow.btn-accent-4 {
  border-color: #fbc02d !important;
  background-color: #ffd600 !important; }
  .btn-yellow.btn-accent-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-4:focus, .btn-yellow.btn-accent-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-4 {
  border-color: #ffd600 !important;
  color: #ffd600 !important; }
  .btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #ffd600 !important; }

.progress-yellow.progress-accent-4 {
  background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-ms-fill {
    background-color: #ffd600; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-4 .progress-bar {
      background-color: #ffd600; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd600 !important; }

.border-yellow.border-accent-4 {
  border: 1px solid #ffd600 !important; }

.border-top-yellow.border-top-accent-4 {
  border-top: 1px solid #ffd600 !important; }

.border-bottom-yellow.border-bottom-accent-4 {
  border-bottom: 1px solid #ffd600 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-4 {
  border-left: 1px solid #ffd600 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-4 {
  border-right: 1px solid #ffd600 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-4 {
  border-right: 1px solid #ffd600 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-4 {
  border-left: 1px solid #ffd600 !important; }

.overlay-yellow.overlay-accent-4 {
  background: #ffd600;
  /* The Fallback */
  background: rgba(255, 214, 0, 0.8); }

.amber.lighten-5 {
  color: #fff8e1 !important; }

.bg-amber.bg-lighten-5 {
  background-color: #fff8e1 !important; }

.btn-amber.btn-lighten-5 {
  border-color: #ffa000 !important;
  background-color: #fff8e1 !important; }
  .btn-amber.btn-lighten-5:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-5:focus, .btn-amber.btn-lighten-5:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-5 {
  border-color: #fff8e1 !important;
  color: #fff8e1 !important; }
  .btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #fff8e1 !important; }

.progress-amber.progress-lighten-5 {
  background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-ms-fill {
    background-color: #fff8e1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-5 .progress-bar {
      background-color: #fff8e1; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff8e1 !important; }

.border-amber.border-lighten-5 {
  border: 1px solid #fff8e1 !important; }

.border-top-amber.border-top-lighten-5 {
  border-top: 1px solid #fff8e1 !important; }

.border-bottom-amber.border-bottom-lighten-5 {
  border-bottom: 1px solid #fff8e1 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-5 {
  border-left: 1px solid #fff8e1 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-5 {
  border-right: 1px solid #fff8e1 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-5 {
  border-right: 1px solid #fff8e1 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-5 {
  border-left: 1px solid #fff8e1 !important; }

.overlay-amber.overlay-lighten-5 {
  background: #fff8e1;
  /* The Fallback */
  background: rgba(255, 248, 225, 0.8); }

.amber.lighten-4 {
  color: #ffecb3 !important; }

.bg-amber.bg-lighten-4 {
  background-color: #ffecb3 !important; }

.btn-amber.btn-lighten-4 {
  border-color: #ffa000 !important;
  background-color: #ffecb3 !important; }
  .btn-amber.btn-lighten-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-4:focus, .btn-amber.btn-lighten-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-4 {
  border-color: #ffecb3 !important;
  color: #ffecb3 !important; }
  .btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #ffecb3 !important; }

.progress-amber.progress-lighten-4 {
  background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-ms-fill {
    background-color: #ffecb3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-4 .progress-bar {
      background-color: #ffecb3; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffecb3 !important; }

.border-amber.border-lighten-4 {
  border: 1px solid #ffecb3 !important; }

.border-top-amber.border-top-lighten-4 {
  border-top: 1px solid #ffecb3 !important; }

.border-bottom-amber.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffecb3 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-4 {
  border-left: 1px solid #ffecb3 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-4 {
  border-right: 1px solid #ffecb3 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-4 {
  border-right: 1px solid #ffecb3 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-4 {
  border-left: 1px solid #ffecb3 !important; }

.overlay-amber.overlay-lighten-4 {
  background: #ffecb3;
  /* The Fallback */
  background: rgba(255, 236, 179, 0.8); }

.amber.lighten-3 {
  color: #ffe082 !important; }

.bg-amber.bg-lighten-3 {
  background-color: #ffe082 !important; }

.btn-amber.btn-lighten-3 {
  border-color: #ffa000 !important;
  background-color: #ffe082 !important; }
  .btn-amber.btn-lighten-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-3:focus, .btn-amber.btn-lighten-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-3 {
  border-color: #ffe082 !important;
  color: #ffe082 !important; }
  .btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #ffe082 !important; }

.progress-amber.progress-lighten-3 {
  background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-ms-fill {
    background-color: #ffe082; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-3 .progress-bar {
      background-color: #ffe082; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe082 !important; }

.border-amber.border-lighten-3 {
  border: 1px solid #ffe082 !important; }

.border-top-amber.border-top-lighten-3 {
  border-top: 1px solid #ffe082 !important; }

.border-bottom-amber.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffe082 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-3 {
  border-left: 1px solid #ffe082 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-3 {
  border-right: 1px solid #ffe082 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-3 {
  border-right: 1px solid #ffe082 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-3 {
  border-left: 1px solid #ffe082 !important; }

.overlay-amber.overlay-lighten-3 {
  background: #ffe082;
  /* The Fallback */
  background: rgba(255, 224, 130, 0.8); }

.amber.lighten-2 {
  color: #ffd54f !important; }

.bg-amber.bg-lighten-2 {
  background-color: #ffd54f !important; }

.btn-amber.btn-lighten-2 {
  border-color: #ffa000 !important;
  background-color: #ffd54f !important; }
  .btn-amber.btn-lighten-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-2:focus, .btn-amber.btn-lighten-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-2 {
  border-color: #ffd54f !important;
  color: #ffd54f !important; }
  .btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #ffd54f !important; }

.progress-amber.progress-lighten-2 {
  background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-ms-fill {
    background-color: #ffd54f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-2 .progress-bar {
      background-color: #ffd54f; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd54f !important; }

.border-amber.border-lighten-2 {
  border: 1px solid #ffd54f !important; }

.border-top-amber.border-top-lighten-2 {
  border-top: 1px solid #ffd54f !important; }

.border-bottom-amber.border-bottom-lighten-2 {
  border-bottom: 1px solid #ffd54f !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-2 {
  border-left: 1px solid #ffd54f !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-2 {
  border-right: 1px solid #ffd54f !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-2 {
  border-right: 1px solid #ffd54f !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-2 {
  border-left: 1px solid #ffd54f !important; }

.overlay-amber.overlay-lighten-2 {
  background: #ffd54f;
  /* The Fallback */
  background: rgba(255, 213, 79, 0.8); }

.amber.lighten-1 {
  color: #ffca28 !important; }

.bg-amber.bg-lighten-1 {
  background-color: #ffca28 !important; }

.btn-amber.btn-lighten-1 {
  border-color: #ffa000 !important;
  background-color: #ffca28 !important; }
  .btn-amber.btn-lighten-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-1:focus, .btn-amber.btn-lighten-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-1 {
  border-color: #ffca28 !important;
  color: #ffca28 !important; }
  .btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #ffca28 !important; }

.progress-amber.progress-lighten-1 {
  background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-ms-fill {
    background-color: #ffca28; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-1 .progress-bar {
      background-color: #ffca28; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffca28 !important; }

.border-amber.border-lighten-1 {
  border: 1px solid #ffca28 !important; }

.border-top-amber.border-top-lighten-1 {
  border-top: 1px solid #ffca28 !important; }

.border-bottom-amber.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffca28 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-1 {
  border-left: 1px solid #ffca28 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-1 {
  border-right: 1px solid #ffca28 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-1 {
  border-right: 1px solid #ffca28 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-1 {
  border-left: 1px solid #ffca28 !important; }

.overlay-amber.overlay-lighten-1 {
  background: #ffca28;
  /* The Fallback */
  background: rgba(255, 202, 40, 0.8); }

.amber {
  color: #ffc107 !important; }

.bg-amber {
  background-color: #ffc107 !important; }
  .bg-amber .card-header, .bg-amber .card-footer {
    background-color: transparent; }

.toast-amber {
  background-color: #ffc107; }

.alert-amber {
  border-color: #ffc107 !important;
  background-color: #ffca2b !important;
  color: #543f00 !important; }
  .alert-amber .alert-link {
    color: #2b2000 !important; }

.border-amber {
  border-color: #ffc107; }

.border-top-amber {
  border-top-color: #ffc107; }

.border-bottom-amber {
  border-bottom-color: #ffc107; }

[dir="ltr"] .border-left-amber {
  border-left-color: #ffc107; }

[dir="ltr"] .border-right-amber {
  border-right-color: #ffc107; }

[dir="rtl"] .border-left-amber {
  border-right-color: #ffc107; }

[dir="rtl"] .border-right-amber {
  border-left-color: #ffc107; }

.badge-amber {
  background-color: #ffc107; }

.panel-amber {
  border-color: #ffc107; }
  .panel-amber .panel-heading {
    color: #FFF;
    border-color: #ffc107;
    background-color: #ffc721; }

.bg-amber.tag-glow, .border-amber.tag-glow {
  box-shadow: 0px 0px 10px #ffc107; }

.overlay-amber {
  background: #ffc107;
  /* The Fallback */
  background: rgba(255, 193, 7, 0.8); }

.card.card-outline-amber {
  border-width: 1px;
  border-style: solid;
  border-color: #ffc107;
  background-color: transparent; }
  .card.card-outline-amber .card-header, .card.card-outline-amber .card-footer {
    background-color: transparent; }

.btn-amber.btn-flat {
  background-color: transparent !important;
  color: #ffc107;
  border: none; }

.btn-amber.btn-raised, .btn-amber.btn-fab {
  background-color: #ffc107 !important;
  color: #fff !important;
  border-color: #ffc107; }
  .btn-amber.btn-raised.active, .btn-amber.btn-fab.active {
    background-color: #e2aa00 !important;
    border-color: #e2aa00 !important; }

.btn-group-raised .btn-amber {
  background-color: #ffc107 !important;
  color: #fff !important; }

.btn-outline-amber {
  border: 1px solid;
  border-color: #ffc107;
  background-color: transparent;
  color: #ffc107;
  box-shadow: none !important; }
  .btn-outline-amber:focus {
    background-color: transparent !important;
    color: #ffc107 !important;
    box-shadow: transparent !important; }
  .btn-outline-amber.active {
    background-color: #ffc107 !important;
    color: #FFF !important; }
  .btn-outline-amber:hover {
    background-color: #f7b900 !important;
    color: #FFF !important; }
    .btn-outline-amber:hover svg {
      color: #FFF !important; }

.btn-outline-amber.btn-raised, .btn-outline-amber.btn-fab {
  border: 1px solid;
  border-color: #ffc107;
  background-color: transparent;
  color: #ffc107;
  box-shadow: none !important; }
  .btn-outline-amber.btn-raised:focus, .btn-outline-amber.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-amber.btn-raised.active, .btn-outline-amber.btn-fab.active {
    background-color: #ffc107 !important;
    color: #FFF !important; }
  .btn-outline-amber.btn-raised:hover, .btn-outline-amber.btn-fab:hover {
    background-color: #f7b900 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-amber {
  background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-webkit-progress-value {
    background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-moz-progress-bar {
    background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-ms-fill {
    background-color: #ffc107; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-amber .progress-bar {
      background-color: #ffc107; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc107 !important; }

.amber.darken-1 {
  color: #ffb300 !important; }

.bg-amber.bg-darken-1 {
  background-color: #ffb300 !important; }

.btn-amber.btn-darken-1 {
  border-color: #ffa000 !important;
  background-color: #ffb300 !important; }
  .btn-amber.btn-darken-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-1:focus, .btn-amber.btn-darken-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-1 {
  border-color: #ffb300 !important;
  color: #ffb300 !important; }
  .btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #ffb300 !important; }

.progress-amber.progress-darken-1 {
  background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-webkit-progress-value {
    background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-moz-progress-bar {
    background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-ms-fill {
    background-color: #ffb300; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-1 .progress-bar {
      background-color: #ffb300; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb300 !important; }

.border-amber.border-darken-1 {
  border: 1px solid #ffb300 !important; }

.border-top-amber.border-top-darken-1 {
  border-top: 1px solid #ffb300 !important; }

.border-bottom-amber.border-bottom-darken-1 {
  border-bottom: 1px solid #ffb300 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-1 {
  border-left: 1px solid #ffb300 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-1 {
  border-right: 1px solid #ffb300 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-1 {
  border-right: 1px solid #ffb300 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-1 {
  border-left: 1px solid #ffb300 !important; }

.overlay-amber.overlay-darken-1 {
  background: #ffb300;
  /* The Fallback */
  background: rgba(255, 179, 0, 0.8); }

.amber.darken-2 {
  color: #ffa000 !important; }

.bg-amber.bg-darken-2 {
  background-color: #ffa000 !important; }

.btn-amber.btn-darken-2 {
  border-color: #ffa000 !important;
  background-color: #ffa000 !important; }
  .btn-amber.btn-darken-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-2:focus, .btn-amber.btn-darken-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-2 {
  border-color: #ffa000 !important;
  color: #ffa000 !important; }
  .btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #ffa000 !important; }

.progress-amber.progress-darken-2 {
  background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-webkit-progress-value {
    background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-moz-progress-bar {
    background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-ms-fill {
    background-color: #ffa000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-2 .progress-bar {
      background-color: #ffa000; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa000 !important; }

.border-amber.border-darken-2 {
  border: 1px solid #ffa000 !important; }

.border-top-amber.border-top-darken-2 {
  border-top: 1px solid #ffa000 !important; }

.border-bottom-amber.border-bottom-darken-2 {
  border-bottom: 1px solid #ffa000 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-2 {
  border-left: 1px solid #ffa000 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-2 {
  border-right: 1px solid #ffa000 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-2 {
  border-right: 1px solid #ffa000 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-2 {
  border-left: 1px solid #ffa000 !important; }

.overlay-amber.overlay-darken-2 {
  background: #ffa000;
  /* The Fallback */
  background: rgba(255, 160, 0, 0.8); }

.amber.darken-3 {
  color: #ff8f00 !important; }

.bg-amber.bg-darken-3 {
  background-color: #ff8f00 !important; }

.btn-amber.btn-darken-3 {
  border-color: #ffa000 !important;
  background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-3:focus, .btn-amber.btn-darken-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-3 {
  border-color: #ff8f00 !important;
  color: #ff8f00 !important; }
  .btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #ff8f00 !important; }

.progress-amber.progress-darken-3 {
  background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-ms-fill {
    background-color: #ff8f00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-3 .progress-bar {
      background-color: #ff8f00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8f00 !important; }

.border-amber.border-darken-3 {
  border: 1px solid #ff8f00 !important; }

.border-top-amber.border-top-darken-3 {
  border-top: 1px solid #ff8f00 !important; }

.border-bottom-amber.border-bottom-darken-3 {
  border-bottom: 1px solid #ff8f00 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-3 {
  border-left: 1px solid #ff8f00 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-3 {
  border-right: 1px solid #ff8f00 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-3 {
  border-right: 1px solid #ff8f00 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-3 {
  border-left: 1px solid #ff8f00 !important; }

.overlay-amber.overlay-darken-3 {
  background: #ff8f00;
  /* The Fallback */
  background: rgba(255, 143, 0, 0.8); }

.amber.darken-4 {
  color: #ff6f00 !important; }

.bg-amber.bg-darken-4 {
  background-color: #ff6f00 !important; }

.btn-amber.btn-darken-4 {
  border-color: #ffa000 !important;
  background-color: #ff6f00 !important; }
  .btn-amber.btn-darken-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-4:focus, .btn-amber.btn-darken-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-4 {
  border-color: #ff6f00 !important;
  color: #ff6f00 !important; }
  .btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #ff6f00 !important; }

.progress-amber.progress-darken-4 {
  background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-webkit-progress-value {
    background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-moz-progress-bar {
    background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-ms-fill {
    background-color: #ff6f00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-4 .progress-bar {
      background-color: #ff6f00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6f00 !important; }

.border-amber.border-darken-4 {
  border: 1px solid #ff6f00 !important; }

.border-top-amber.border-top-darken-4 {
  border-top: 1px solid #ff6f00 !important; }

.border-bottom-amber.border-bottom-darken-4 {
  border-bottom: 1px solid #ff6f00 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-4 {
  border-left: 1px solid #ff6f00 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-4 {
  border-right: 1px solid #ff6f00 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-4 {
  border-right: 1px solid #ff6f00 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-4 {
  border-left: 1px solid #ff6f00 !important; }

.overlay-amber.overlay-darken-4 {
  background: #ff6f00;
  /* The Fallback */
  background: rgba(255, 111, 0, 0.8); }

.amber.accent-1 {
  color: #ffe57f !important; }

.bg-amber.bg-accent-1 {
  background-color: #ffe57f !important; }

.btn-amber.btn-accent-1 {
  border-color: #ffa000 !important;
  background-color: #ffe57f !important; }
  .btn-amber.btn-accent-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-1:focus, .btn-amber.btn-accent-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-1 {
  border-color: #ffe57f !important;
  color: #ffe57f !important; }
  .btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #ffe57f !important; }

.progress-amber.progress-accent-1 {
  background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-ms-fill {
    background-color: #ffe57f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-1 .progress-bar {
      background-color: #ffe57f; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe57f !important; }

.border-amber.border-accent-1 {
  border: 1px solid #ffe57f !important; }

.border-top-amber.border-top-accent-1 {
  border-top: 1px solid #ffe57f !important; }

.border-bottom-amber.border-bottom-accent-1 {
  border-bottom: 1px solid #ffe57f !important; }

[dir="ltr"] .border-left-amber.border-left-accent-1 {
  border-left: 1px solid #ffe57f !important; }

[dir="ltr"] .border-right-amber.border-right-accent-1 {
  border-right: 1px solid #ffe57f !important; }

[dir="rtl"] .border-left-amber.border-left-accent-1 {
  border-right: 1px solid #ffe57f !important; }

[dir="rtl"] .border-right-amber.border-right-accent-1 {
  border-left: 1px solid #ffe57f !important; }

.overlay-amber.overlay-accent-1 {
  background: #ffe57f;
  /* The Fallback */
  background: rgba(255, 229, 127, 0.8); }

.amber.accent-2 {
  color: #ffd740 !important; }

.bg-amber.bg-accent-2 {
  background-color: #ffd740 !important; }

.btn-amber.btn-accent-2 {
  border-color: #ffa000 !important;
  background-color: #ffd740 !important; }
  .btn-amber.btn-accent-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-2:focus, .btn-amber.btn-accent-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-2 {
  border-color: #ffd740 !important;
  color: #ffd740 !important; }
  .btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #ffd740 !important; }

.progress-amber.progress-accent-2 {
  background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-ms-fill {
    background-color: #ffd740; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-2 .progress-bar {
      background-color: #ffd740; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd740 !important; }

.border-amber.border-accent-2 {
  border: 1px solid #ffd740 !important; }

.border-top-amber.border-top-accent-2 {
  border-top: 1px solid #ffd740 !important; }

.border-bottom-amber.border-bottom-accent-2 {
  border-bottom: 1px solid #ffd740 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-2 {
  border-left: 1px solid #ffd740 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-2 {
  border-right: 1px solid #ffd740 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-2 {
  border-right: 1px solid #ffd740 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-2 {
  border-left: 1px solid #ffd740 !important; }

.overlay-amber.overlay-accent-2 {
  background: #ffd740;
  /* The Fallback */
  background: rgba(255, 215, 64, 0.8); }

.amber.accent-3 {
  color: #ffc400 !important; }

.bg-amber.bg-accent-3 {
  background-color: #ffc400 !important; }

.btn-amber.btn-accent-3 {
  border-color: #ffa000 !important;
  background-color: #ffc400 !important; }
  .btn-amber.btn-accent-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-3:focus, .btn-amber.btn-accent-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-3 {
  border-color: #ffc400 !important;
  color: #ffc400 !important; }
  .btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #ffc400 !important; }

.progress-amber.progress-accent-3 {
  background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-ms-fill {
    background-color: #ffc400; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-3 .progress-bar {
      background-color: #ffc400; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc400 !important; }

.border-amber.border-accent-3 {
  border: 1px solid #ffc400 !important; }

.border-top-amber.border-top-accent-3 {
  border-top: 1px solid #ffc400 !important; }

.border-bottom-amber.border-bottom-accent-3 {
  border-bottom: 1px solid #ffc400 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-3 {
  border-left: 1px solid #ffc400 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-3 {
  border-right: 1px solid #ffc400 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-3 {
  border-right: 1px solid #ffc400 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-3 {
  border-left: 1px solid #ffc400 !important; }

.overlay-amber.overlay-accent-3 {
  background: #ffc400;
  /* The Fallback */
  background: rgba(255, 196, 0, 0.8); }

.amber.accent-4 {
  color: #ffab00 !important; }

.bg-amber.bg-accent-4 {
  background-color: #ffab00 !important; }

.btn-amber.btn-accent-4 {
  border-color: #ffa000 !important;
  background-color: #ffab00 !important; }
  .btn-amber.btn-accent-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-4:focus, .btn-amber.btn-accent-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-4 {
  border-color: #ffab00 !important;
  color: #ffab00 !important; }
  .btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #ffab00 !important; }

.progress-amber.progress-accent-4 {
  background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-ms-fill {
    background-color: #ffab00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-4 .progress-bar {
      background-color: #ffab00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab00 !important; }

.border-amber.border-accent-4 {
  border: 1px solid #ffab00 !important; }

.border-top-amber.border-top-accent-4 {
  border-top: 1px solid #ffab00 !important; }

.border-bottom-amber.border-bottom-accent-4 {
  border-bottom: 1px solid #ffab00 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-4 {
  border-left: 1px solid #ffab00 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-4 {
  border-right: 1px solid #ffab00 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-4 {
  border-right: 1px solid #ffab00 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-4 {
  border-left: 1px solid #ffab00 !important; }

.overlay-amber.overlay-accent-4 {
  background: #ffab00;
  /* The Fallback */
  background: rgba(255, 171, 0, 0.8); }

.orange.lighten-5 {
  color: #fff3e0 !important; }

.bg-orange.bg-lighten-5 {
  background-color: #fff3e0 !important; }

.btn-orange.btn-lighten-5 {
  border-color: #f57c00 !important;
  background-color: #fff3e0 !important; }
  .btn-orange.btn-lighten-5:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-5:focus, .btn-orange.btn-lighten-5:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-5 {
  border-color: #fff3e0 !important;
  color: #fff3e0 !important; }
  .btn-outline-orange.btn-outline-lighten-5:hover {
    background-color: #fff3e0 !important; }

.progress-orange.progress-lighten-5 {
  background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fff3e0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-5 .progress-bar {
      background-color: #fff3e0; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff3e0 !important; }

.border-orange.border-lighten-5 {
  border: 1px solid #fff3e0 !important; }

.border-top-orange.border-top-lighten-5 {
  border-top: 1px solid #fff3e0 !important; }

.border-bottom-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #fff3e0 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-5 {
  border-left: 1px solid #fff3e0 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-5 {
  border-right: 1px solid #fff3e0 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-5 {
  border-right: 1px solid #fff3e0 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-5 {
  border-left: 1px solid #fff3e0 !important; }

.overlay-orange.overlay-lighten-5 {
  background: #fff3e0;
  /* The Fallback */
  background: rgba(255, 243, 224, 0.8); }

.orange.lighten-4 {
  color: #ffe0b2 !important; }

.bg-orange.bg-lighten-4 {
  background-color: #ffe0b2 !important; }

.btn-orange.btn-lighten-4 {
  border-color: #f57c00 !important;
  background-color: #ffe0b2 !important; }
  .btn-orange.btn-lighten-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-4:focus, .btn-orange.btn-lighten-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-4 {
  border-color: #ffe0b2 !important;
  color: #ffe0b2 !important; }
  .btn-outline-orange.btn-outline-lighten-4:hover {
    background-color: #ffe0b2 !important; }

.progress-orange.progress-lighten-4 {
  background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffe0b2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-4 .progress-bar {
      background-color: #ffe0b2; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe0b2 !important; }

.border-orange.border-lighten-4 {
  border: 1px solid #ffe0b2 !important; }

.border-top-orange.border-top-lighten-4 {
  border-top: 1px solid #ffe0b2 !important; }

.border-bottom-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffe0b2 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-4 {
  border-left: 1px solid #ffe0b2 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-4 {
  border-right: 1px solid #ffe0b2 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-4 {
  border-right: 1px solid #ffe0b2 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-4 {
  border-left: 1px solid #ffe0b2 !important; }

.overlay-orange.overlay-lighten-4 {
  background: #ffe0b2;
  /* The Fallback */
  background: rgba(255, 224, 178, 0.8); }

.orange.lighten-3 {
  color: #ffcc80 !important; }

.bg-orange.bg-lighten-3 {
  background-color: #ffcc80 !important; }

.btn-orange.btn-lighten-3 {
  border-color: #f57c00 !important;
  background-color: #ffcc80 !important; }
  .btn-orange.btn-lighten-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-3:focus, .btn-orange.btn-lighten-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-3 {
  border-color: #ffcc80 !important;
  color: #ffcc80 !important; }
  .btn-outline-orange.btn-outline-lighten-3:hover {
    background-color: #ffcc80 !important; }

.progress-orange.progress-lighten-3 {
  background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffcc80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-3 .progress-bar {
      background-color: #ffcc80; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcc80 !important; }

.border-orange.border-lighten-3 {
  border: 1px solid #ffcc80 !important; }

.border-top-orange.border-top-lighten-3 {
  border-top: 1px solid #ffcc80 !important; }

.border-bottom-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffcc80 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-3 {
  border-left: 1px solid #ffcc80 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-3 {
  border-right: 1px solid #ffcc80 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-3 {
  border-right: 1px solid #ffcc80 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-3 {
  border-left: 1px solid #ffcc80 !important; }

.overlay-orange.overlay-lighten-3 {
  background: #ffcc80;
  /* The Fallback */
  background: rgba(255, 204, 128, 0.8); }

.orange.lighten-2 {
  color: #ffb74d !important; }

.bg-orange.bg-lighten-2 {
  background-color: #ffb74d !important; }

.btn-orange.btn-lighten-2 {
  border-color: #f57c00 !important;
  background-color: #ffb74d !important; }
  .btn-orange.btn-lighten-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-2:focus, .btn-orange.btn-lighten-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-2 {
  border-color: #ffb74d !important;
  color: #ffb74d !important; }
  .btn-outline-orange.btn-outline-lighten-2:hover {
    background-color: #ffb74d !important; }

.progress-orange.progress-lighten-2 {
  background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ffb74d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-2 .progress-bar {
      background-color: #ffb74d; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb74d !important; }

.border-orange.border-lighten-2 {
  border: 1px solid #ffb74d !important; }

.border-top-orange.border-top-lighten-2 {
  border-top: 1px solid #ffb74d !important; }

.border-bottom-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #ffb74d !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-2 {
  border-left: 1px solid #ffb74d !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-2 {
  border-right: 1px solid #ffb74d !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-2 {
  border-right: 1px solid #ffb74d !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-2 {
  border-left: 1px solid #ffb74d !important; }

.overlay-orange.overlay-lighten-2 {
  background: #ffb74d;
  /* The Fallback */
  background: rgba(255, 183, 77, 0.8); }

.orange.lighten-1 {
  color: #ffa726 !important; }

.bg-orange.bg-lighten-1 {
  background-color: #ffa726 !important; }

.btn-orange.btn-lighten-1 {
  border-color: #f57c00 !important;
  background-color: #ffa726 !important; }
  .btn-orange.btn-lighten-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-1:focus, .btn-orange.btn-lighten-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-1 {
  border-color: #ffa726 !important;
  color: #ffa726 !important; }
  .btn-outline-orange.btn-outline-lighten-1:hover {
    background-color: #ffa726 !important; }

.progress-orange.progress-lighten-1 {
  background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ffa726; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-1 .progress-bar {
      background-color: #ffa726; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa726 !important; }

.border-orange.border-lighten-1 {
  border: 1px solid #ffa726 !important; }

.border-top-orange.border-top-lighten-1 {
  border-top: 1px solid #ffa726 !important; }

.border-bottom-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffa726 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-1 {
  border-left: 1px solid #ffa726 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-1 {
  border-right: 1px solid #ffa726 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-1 {
  border-right: 1px solid #ffa726 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-1 {
  border-left: 1px solid #ffa726 !important; }

.overlay-orange.overlay-lighten-1 {
  background: #ffa726;
  /* The Fallback */
  background: rgba(255, 167, 38, 0.8); }

.orange {
  color: #ff9800 !important; }

.bg-orange {
  background-color: #ff9800 !important; }
  .bg-orange .card-header, .bg-orange .card-footer {
    background-color: transparent; }

.toast-orange {
  background-color: #ff9800; }

.alert-orange {
  border-color: #ff9800 !important;
  background-color: #ffa624 !important;
  color: #4d2e00 !important; }
  .alert-orange .alert-link {
    color: #241500 !important; }

.border-orange {
  border-color: #ff9800; }

.border-top-orange {
  border-top-color: #ff9800; }

.border-bottom-orange {
  border-bottom-color: #ff9800; }

[dir="ltr"] .border-left-orange {
  border-left-color: #ff9800; }

[dir="ltr"] .border-right-orange {
  border-right-color: #ff9800; }

[dir="rtl"] .border-left-orange {
  border-right-color: #ff9800; }

[dir="rtl"] .border-right-orange {
  border-left-color: #ff9800; }

.badge-orange {
  background-color: #ff9800; }

.panel-orange {
  border-color: #ff9800; }
  .panel-orange .panel-heading {
    color: #FFF;
    border-color: #ff9800;
    background-color: #ffa21a; }

.bg-orange.tag-glow, .border-orange.tag-glow {
  box-shadow: 0px 0px 10px #ff9800; }

.overlay-orange {
  background: #ff9800;
  /* The Fallback */
  background: rgba(255, 152, 0, 0.8); }

.card.card-outline-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #ff9800;
  background-color: transparent; }
  .card.card-outline-orange .card-header, .card.card-outline-orange .card-footer {
    background-color: transparent; }

.btn-orange.btn-flat {
  background-color: transparent !important;
  color: #ff9800;
  border: none; }

.btn-orange.btn-raised, .btn-orange.btn-fab {
  background-color: #ff9800 !important;
  color: #fff !important;
  border-color: #ff9800; }
  .btn-orange.btn-raised.active, .btn-orange.btn-fab.active {
    background-color: #db8300 !important;
    border-color: #db8300 !important; }

.btn-group-raised .btn-orange {
  background-color: #ff9800 !important;
  color: #fff !important; }

.btn-outline-orange {
  border: 1px solid;
  border-color: #ff9800;
  background-color: transparent;
  color: #ff9800;
  box-shadow: none !important; }
  .btn-outline-orange:focus {
    background-color: transparent !important;
    color: #ff9800 !important;
    box-shadow: transparent !important; }
  .btn-outline-orange.active {
    background-color: #ff9800 !important;
    color: #FFF !important; }
  .btn-outline-orange:hover {
    background-color: #f08f00 !important;
    color: #FFF !important; }
    .btn-outline-orange:hover svg {
      color: #FFF !important; }

.btn-outline-orange.btn-raised, .btn-outline-orange.btn-fab {
  border: 1px solid;
  border-color: #ff9800;
  background-color: transparent;
  color: #ff9800;
  box-shadow: none !important; }
  .btn-outline-orange.btn-raised:focus, .btn-outline-orange.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-orange.btn-raised.active, .btn-outline-orange.btn-fab.active {
    background-color: #ff9800 !important;
    color: #FFF !important; }
  .btn-outline-orange.btn-raised:hover, .btn-outline-orange.btn-fab:hover {
    background-color: #f08f00 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-orange {
  background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-webkit-progress-value {
    background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-moz-progress-bar {
    background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-ms-fill {
    background-color: #ff9800; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-orange .progress-bar {
      background-color: #ff9800; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9800 !important; }

.orange.darken-1 {
  color: #fb8c00 !important; }

.bg-orange.bg-darken-1 {
  background-color: #fb8c00 !important; }

.btn-orange.btn-darken-1 {
  border-color: #f57c00 !important;
  background-color: #fb8c00 !important; }
  .btn-orange.btn-darken-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-1:focus, .btn-orange.btn-darken-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-1 {
  border-color: #fb8c00 !important;
  color: #fb8c00 !important; }
  .btn-outline-orange.btn-outline-darken-1:hover {
    background-color: #fb8c00 !important; }

.progress-orange.progress-darken-1 {
  background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-ms-fill {
    background-color: #fb8c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-1 .progress-bar {
      background-color: #fb8c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fb8c00 !important; }

.border-orange.border-darken-1 {
  border: 1px solid #fb8c00 !important; }

.border-top-orange.border-top-darken-1 {
  border-top: 1px solid #fb8c00 !important; }

.border-bottom-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #fb8c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-1 {
  border-left: 1px solid #fb8c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-1 {
  border-right: 1px solid #fb8c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-1 {
  border-right: 1px solid #fb8c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-1 {
  border-left: 1px solid #fb8c00 !important; }

.overlay-orange.overlay-darken-1 {
  background: #fb8c00;
  /* The Fallback */
  background: rgba(251, 140, 0, 0.8); }

.orange.darken-2 {
  color: #f57c00 !important; }

.bg-orange.bg-darken-2 {
  background-color: #f57c00 !important; }

.btn-orange.btn-darken-2 {
  border-color: #f57c00 !important;
  background-color: #f57c00 !important; }
  .btn-orange.btn-darken-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-2:focus, .btn-orange.btn-darken-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-2 {
  border-color: #f57c00 !important;
  color: #f57c00 !important; }
  .btn-outline-orange.btn-outline-darken-2:hover {
    background-color: #f57c00 !important; }

.progress-orange.progress-darken-2 {
  background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-ms-fill {
    background-color: #f57c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-2 .progress-bar {
      background-color: #f57c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57c00 !important; }

.border-orange.border-darken-2 {
  border: 1px solid #f57c00 !important; }

.border-top-orange.border-top-darken-2 {
  border-top: 1px solid #f57c00 !important; }

.border-bottom-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #f57c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-2 {
  border-left: 1px solid #f57c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-2 {
  border-right: 1px solid #f57c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-2 {
  border-right: 1px solid #f57c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-2 {
  border-left: 1px solid #f57c00 !important; }

.overlay-orange.overlay-darken-2 {
  background: #f57c00;
  /* The Fallback */
  background: rgba(245, 124, 0, 0.8); }

.orange.darken-3 {
  color: #ef6c00 !important; }

.bg-orange.bg-darken-3 {
  background-color: #ef6c00 !important; }

.btn-orange.btn-darken-3 {
  border-color: #f57c00 !important;
  background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-3:focus, .btn-orange.btn-darken-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-3 {
  border-color: #ef6c00 !important;
  color: #ef6c00 !important; }
  .btn-outline-orange.btn-outline-darken-3:hover {
    background-color: #ef6c00 !important; }

.progress-orange.progress-darken-3 {
  background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-ms-fill {
    background-color: #ef6c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-3 .progress-bar {
      background-color: #ef6c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef6c00 !important; }

.border-orange.border-darken-3 {
  border: 1px solid #ef6c00 !important; }

.border-top-orange.border-top-darken-3 {
  border-top: 1px solid #ef6c00 !important; }

.border-bottom-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #ef6c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-3 {
  border-left: 1px solid #ef6c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-3 {
  border-right: 1px solid #ef6c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-3 {
  border-right: 1px solid #ef6c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-3 {
  border-left: 1px solid #ef6c00 !important; }

.overlay-orange.overlay-darken-3 {
  background: #ef6c00;
  /* The Fallback */
  background: rgba(239, 108, 0, 0.8); }

.orange.darken-4 {
  color: #e65100 !important; }

.bg-orange.bg-darken-4 {
  background-color: #e65100 !important; }

.btn-orange.btn-darken-4 {
  border-color: #f57c00 !important;
  background-color: #e65100 !important; }
  .btn-orange.btn-darken-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-4:focus, .btn-orange.btn-darken-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-4 {
  border-color: #e65100 !important;
  color: #e65100 !important; }
  .btn-outline-orange.btn-outline-darken-4:hover {
    background-color: #e65100 !important; }

.progress-orange.progress-darken-4 {
  background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-ms-fill {
    background-color: #e65100; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-4 .progress-bar {
      background-color: #e65100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e65100 !important; }

.border-orange.border-darken-4 {
  border: 1px solid #e65100 !important; }

.border-top-orange.border-top-darken-4 {
  border-top: 1px solid #e65100 !important; }

.border-bottom-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #e65100 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-4 {
  border-left: 1px solid #e65100 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-4 {
  border-right: 1px solid #e65100 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-4 {
  border-right: 1px solid #e65100 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-4 {
  border-left: 1px solid #e65100 !important; }

.overlay-orange.overlay-darken-4 {
  background: #e65100;
  /* The Fallback */
  background: rgba(230, 81, 0, 0.8); }

.orange.accent-1 {
  color: #ffd180 !important; }

.bg-orange.bg-accent-1 {
  background-color: #ffd180 !important; }

.btn-orange.btn-accent-1 {
  border-color: #f57c00 !important;
  background-color: #ffd180 !important; }
  .btn-orange.btn-accent-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-1:focus, .btn-orange.btn-accent-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-1 {
  border-color: #ffd180 !important;
  color: #ffd180 !important; }
  .btn-outline-orange.btn-outline-accent-1:hover {
    background-color: #ffd180 !important; }

.progress-orange.progress-accent-1 {
  background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ffd180; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-1 .progress-bar {
      background-color: #ffd180; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd180 !important; }

.border-orange.border-accent-1 {
  border: 1px solid #ffd180 !important; }

.border-top-orange.border-top-accent-1 {
  border-top: 1px solid #ffd180 !important; }

.border-bottom-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #ffd180 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-1 {
  border-left: 1px solid #ffd180 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-1 {
  border-right: 1px solid #ffd180 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-1 {
  border-right: 1px solid #ffd180 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-1 {
  border-left: 1px solid #ffd180 !important; }

.overlay-orange.overlay-accent-1 {
  background: #ffd180;
  /* The Fallback */
  background: rgba(255, 209, 128, 0.8); }

.orange.accent-2 {
  color: #ffab40 !important; }

.bg-orange.bg-accent-2 {
  background-color: #ffab40 !important; }

.btn-orange.btn-accent-2 {
  border-color: #f57c00 !important;
  background-color: #ffab40 !important; }
  .btn-orange.btn-accent-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-2:focus, .btn-orange.btn-accent-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-2 {
  border-color: #ffab40 !important;
  color: #ffab40 !important; }
  .btn-outline-orange.btn-outline-accent-2:hover {
    background-color: #ffab40 !important; }

.progress-orange.progress-accent-2 {
  background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ffab40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-2 .progress-bar {
      background-color: #ffab40; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab40 !important; }

.border-orange.border-accent-2 {
  border: 1px solid #ffab40 !important; }

.border-top-orange.border-top-accent-2 {
  border-top: 1px solid #ffab40 !important; }

.border-bottom-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #ffab40 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-2 {
  border-left: 1px solid #ffab40 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-2 {
  border-right: 1px solid #ffab40 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-2 {
  border-right: 1px solid #ffab40 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-2 {
  border-left: 1px solid #ffab40 !important; }

.overlay-orange.overlay-accent-2 {
  background: #ffab40;
  /* The Fallback */
  background: rgba(255, 171, 64, 0.8); }

.orange.accent-3 {
  color: #ff9100 !important; }

.bg-orange.bg-accent-3 {
  background-color: #ff9100 !important; }

.btn-orange.btn-accent-3 {
  border-color: #f57c00 !important;
  background-color: #ff9100 !important; }
  .btn-orange.btn-accent-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-3:focus, .btn-orange.btn-accent-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-3 {
  border-color: #ff9100 !important;
  color: #ff9100 !important; }
  .btn-outline-orange.btn-outline-accent-3:hover {
    background-color: #ff9100 !important; }

.progress-orange.progress-accent-3 {
  background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff9100; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-3 .progress-bar {
      background-color: #ff9100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9100 !important; }

.border-orange.border-accent-3 {
  border: 1px solid #ff9100 !important; }

.border-top-orange.border-top-accent-3 {
  border-top: 1px solid #ff9100 !important; }

.border-bottom-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #ff9100 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-3 {
  border-left: 1px solid #ff9100 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-3 {
  border-right: 1px solid #ff9100 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-3 {
  border-right: 1px solid #ff9100 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-3 {
  border-left: 1px solid #ff9100 !important; }

.overlay-orange.overlay-accent-3 {
  background: #ff9100;
  /* The Fallback */
  background: rgba(255, 145, 0, 0.8); }

.orange.accent-4 {
  color: #ff6d00 !important; }

.bg-orange.bg-accent-4 {
  background-color: #ff6d00 !important; }

.btn-orange.btn-accent-4 {
  border-color: #f57c00 !important;
  background-color: #ff6d00 !important; }
  .btn-orange.btn-accent-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-4:focus, .btn-orange.btn-accent-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-4 {
  border-color: #ff6d00 !important;
  color: #ff6d00 !important; }
  .btn-outline-orange.btn-outline-accent-4:hover {
    background-color: #ff6d00 !important; }

.progress-orange.progress-accent-4 {
  background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-ms-fill {
    background-color: #ff6d00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-4 .progress-bar {
      background-color: #ff6d00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6d00 !important; }

.border-orange.border-accent-4 {
  border: 1px solid #ff6d00 !important; }

.border-top-orange.border-top-accent-4 {
  border-top: 1px solid #ff6d00 !important; }

.border-bottom-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #ff6d00 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-4 {
  border-left: 1px solid #ff6d00 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-4 {
  border-right: 1px solid #ff6d00 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-4 {
  border-right: 1px solid #ff6d00 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-4 {
  border-left: 1px solid #ff6d00 !important; }

.overlay-orange.overlay-accent-4 {
  background: #ff6d00;
  /* The Fallback */
  background: rgba(255, 109, 0, 0.8); }

.deep-orange.lighten-5 {
  color: #fbe9e7 !important; }

.bg-deep-orange.bg-lighten-5 {
  background-color: #fbe9e7 !important; }

.btn-deep-orange.btn-lighten-5 {
  border-color: #e64a19 !important;
  background-color: #fbe9e7 !important; }
  .btn-deep-orange.btn-lighten-5:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-5:focus, .btn-deep-orange.btn-lighten-5:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-5 {
  border-color: #fbe9e7 !important;
  color: #fbe9e7 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-5:hover {
    background-color: #fbe9e7 !important; }

.progress-deep-orange.progress-lighten-5 {
  background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fbe9e7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-5 .progress-bar {
      background-color: #fbe9e7; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbe9e7 !important; }

.border-deep-orange.border-lighten-5 {
  border: 1px solid #fbe9e7 !important; }

.border-top-deep-orange.border-top-lighten-5 {
  border-top: 1px solid #fbe9e7 !important; }

.border-bottom-deep-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #fbe9e7 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-5 {
  border-left: 1px solid #fbe9e7 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-5 {
  border-right: 1px solid #fbe9e7 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-5 {
  border-right: 1px solid #fbe9e7 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-5 {
  border-left: 1px solid #fbe9e7 !important; }

.overlay-deep-orange.overlay-lighten-5 {
  background: #fbe9e7;
  /* The Fallback */
  background: rgba(251, 233, 231, 0.8); }

.deep-orange.lighten-4 {
  color: #ffccbc !important; }

.bg-deep-orange.bg-lighten-4 {
  background-color: #ffccbc !important; }

.btn-deep-orange.btn-lighten-4 {
  border-color: #e64a19 !important;
  background-color: #ffccbc !important; }
  .btn-deep-orange.btn-lighten-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-4:focus, .btn-deep-orange.btn-lighten-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-4 {
  border-color: #ffccbc !important;
  color: #ffccbc !important; }
  .btn-outline-deep-orange.btn-outline-lighten-4:hover {
    background-color: #ffccbc !important; }

.progress-deep-orange.progress-lighten-4 {
  background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffccbc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-4 .progress-bar {
      background-color: #ffccbc; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffccbc !important; }

.border-deep-orange.border-lighten-4 {
  border: 1px solid #ffccbc !important; }

.border-top-deep-orange.border-top-lighten-4 {
  border-top: 1px solid #ffccbc !important; }

.border-bottom-deep-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffccbc !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-4 {
  border-left: 1px solid #ffccbc !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-4 {
  border-right: 1px solid #ffccbc !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-4 {
  border-right: 1px solid #ffccbc !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-4 {
  border-left: 1px solid #ffccbc !important; }

.overlay-deep-orange.overlay-lighten-4 {
  background: #ffccbc;
  /* The Fallback */
  background: rgba(255, 204, 188, 0.8); }

.deep-orange.lighten-3 {
  color: #ffab91 !important; }

.bg-deep-orange.bg-lighten-3 {
  background-color: #ffab91 !important; }

.btn-deep-orange.btn-lighten-3 {
  border-color: #e64a19 !important;
  background-color: #ffab91 !important; }
  .btn-deep-orange.btn-lighten-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-3:focus, .btn-deep-orange.btn-lighten-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-3 {
  border-color: #ffab91 !important;
  color: #ffab91 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-3:hover {
    background-color: #ffab91 !important; }

.progress-deep-orange.progress-lighten-3 {
  background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffab91; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-3 .progress-bar {
      background-color: #ffab91; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab91 !important; }

.border-deep-orange.border-lighten-3 {
  border: 1px solid #ffab91 !important; }

.border-top-deep-orange.border-top-lighten-3 {
  border-top: 1px solid #ffab91 !important; }

.border-bottom-deep-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffab91 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-3 {
  border-left: 1px solid #ffab91 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-3 {
  border-right: 1px solid #ffab91 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-3 {
  border-right: 1px solid #ffab91 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-3 {
  border-left: 1px solid #ffab91 !important; }

.overlay-deep-orange.overlay-lighten-3 {
  background: #ffab91;
  /* The Fallback */
  background: rgba(255, 171, 145, 0.8); }

.deep-orange.lighten-2 {
  color: #ff8a65 !important; }

.bg-deep-orange.bg-lighten-2 {
  background-color: #ff8a65 !important; }

.btn-deep-orange.btn-lighten-2 {
  border-color: #e64a19 !important;
  background-color: #ff8a65 !important; }
  .btn-deep-orange.btn-lighten-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-2:focus, .btn-deep-orange.btn-lighten-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-2 {
  border-color: #ff8a65 !important;
  color: #ff8a65 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-2:hover {
    background-color: #ff8a65 !important; }

.progress-deep-orange.progress-lighten-2 {
  background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ff8a65; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-2 .progress-bar {
      background-color: #ff8a65; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a65 !important; }

.border-deep-orange.border-lighten-2 {
  border: 1px solid #ff8a65 !important; }

.border-top-deep-orange.border-top-lighten-2 {
  border-top: 1px solid #ff8a65 !important; }

.border-bottom-deep-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #ff8a65 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-2 {
  border-left: 1px solid #ff8a65 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-2 {
  border-right: 1px solid #ff8a65 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-2 {
  border-right: 1px solid #ff8a65 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-2 {
  border-left: 1px solid #ff8a65 !important; }

.overlay-deep-orange.overlay-lighten-2 {
  background: #ff8a65;
  /* The Fallback */
  background: rgba(255, 138, 101, 0.8); }

.deep-orange.lighten-1 {
  color: #ff7043 !important; }

.bg-deep-orange.bg-lighten-1 {
  background-color: #ff7043 !important; }

.btn-deep-orange.btn-lighten-1 {
  border-color: #e64a19 !important;
  background-color: #ff7043 !important; }
  .btn-deep-orange.btn-lighten-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-1:focus, .btn-deep-orange.btn-lighten-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-1 {
  border-color: #ff7043 !important;
  color: #ff7043 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-1:hover {
    background-color: #ff7043 !important; }

.progress-deep-orange.progress-lighten-1 {
  background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ff7043; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-1 .progress-bar {
      background-color: #ff7043; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7043 !important; }

.border-deep-orange.border-lighten-1 {
  border: 1px solid #ff7043 !important; }

.border-top-deep-orange.border-top-lighten-1 {
  border-top: 1px solid #ff7043 !important; }

.border-bottom-deep-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #ff7043 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-1 {
  border-left: 1px solid #ff7043 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-1 {
  border-right: 1px solid #ff7043 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-1 {
  border-right: 1px solid #ff7043 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-1 {
  border-left: 1px solid #ff7043 !important; }

.overlay-deep-orange.overlay-lighten-1 {
  background: #ff7043;
  /* The Fallback */
  background: rgba(255, 112, 67, 0.8); }

.deep-orange {
  color: #ff5722 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }
  .bg-deep-orange .card-header, .bg-deep-orange .card-footer {
    background-color: transparent; }

.toast-deep-orange {
  background-color: #ff5722; }

.alert-deep-orange {
  border-color: #ff5722 !important;
  background-color: #ff7246 !important;
  color: #6f1b00 !important; }
  .alert-deep-orange .alert-link {
    color: #461100 !important; }

.border-deep-orange {
  border-color: #ff5722; }

.border-top-deep-orange {
  border-top-color: #ff5722; }

.border-bottom-deep-orange {
  border-bottom-color: #ff5722; }

[dir="ltr"] .border-left-deep-orange {
  border-left-color: #ff5722; }

[dir="ltr"] .border-right-deep-orange {
  border-right-color: #ff5722; }

[dir="rtl"] .border-left-deep-orange {
  border-right-color: #ff5722; }

[dir="rtl"] .border-right-deep-orange {
  border-left-color: #ff5722; }

.badge-deep-orange {
  background-color: #ff5722; }

.panel-deep-orange {
  border-color: #ff5722; }
  .panel-deep-orange .panel-heading {
    color: #FFF;
    border-color: #ff5722;
    background-color: #ff6a3c; }

.bg-deep-orange.tag-glow, .border-deep-orange.tag-glow {
  box-shadow: 0px 0px 10px #ff5722; }

.overlay-deep-orange {
  background: #ff5722;
  /* The Fallback */
  background: rgba(255, 87, 34, 0.8); }

.card.card-outline-deep-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #ff5722;
  background-color: transparent; }
  .card.card-outline-deep-orange .card-header, .card.card-outline-deep-orange .card-footer {
    background-color: transparent; }

.btn-deep-orange.btn-flat {
  background-color: transparent !important;
  color: #ff5722;
  border: none; }

.btn-deep-orange.btn-raised, .btn-deep-orange.btn-fab {
  background-color: #ff5722 !important;
  color: #fff !important;
  border-color: #ff5722; }
  .btn-deep-orange.btn-raised.active, .btn-deep-orange.btn-fab.active {
    background-color: #fd3d00 !important;
    border-color: #fd3d00 !important; }

.btn-group-raised .btn-deep-orange {
  background-color: #ff5722 !important;
  color: #fff !important; }

.btn-outline-deep-orange {
  border: 1px solid;
  border-color: #ff5722;
  background-color: transparent;
  color: #ff5722;
  box-shadow: none !important; }
  .btn-outline-deep-orange:focus {
    background-color: transparent !important;
    color: #ff5722 !important;
    box-shadow: transparent !important; }
  .btn-outline-deep-orange.active {
    background-color: #ff5722 !important;
    color: #FFF !important; }
  .btn-outline-deep-orange:hover {
    background-color: #ff4b13 !important;
    color: #FFF !important; }
    .btn-outline-deep-orange:hover svg {
      color: #FFF !important; }

.btn-outline-deep-orange.btn-raised, .btn-outline-deep-orange.btn-fab {
  border: 1px solid;
  border-color: #ff5722;
  background-color: transparent;
  color: #ff5722;
  box-shadow: none !important; }
  .btn-outline-deep-orange.btn-raised:focus, .btn-outline-deep-orange.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-deep-orange.btn-raised.active, .btn-outline-deep-orange.btn-fab.active {
    background-color: #ff5722 !important;
    color: #FFF !important; }
  .btn-outline-deep-orange.btn-raised:hover, .btn-outline-deep-orange.btn-fab:hover {
    background-color: #ff4b13 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-deep-orange {
  background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-webkit-progress-value {
    background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-moz-progress-bar {
    background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-ms-fill {
    background-color: #ff5722; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-deep-orange .progress-bar {
      background-color: #ff5722; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5722 !important; }

.deep-orange.darken-1 {
  color: #f4511e !important; }

.bg-deep-orange.bg-darken-1 {
  background-color: #f4511e !important; }

.btn-deep-orange.btn-darken-1 {
  border-color: #e64a19 !important;
  background-color: #f4511e !important; }
  .btn-deep-orange.btn-darken-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-1:focus, .btn-deep-orange.btn-darken-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-1 {
  border-color: #f4511e !important;
  color: #f4511e !important; }
  .btn-outline-deep-orange.btn-outline-darken-1:hover {
    background-color: #f4511e !important; }

.progress-deep-orange.progress-darken-1 {
  background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-ms-fill {
    background-color: #f4511e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-1 .progress-bar {
      background-color: #f4511e; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4511e !important; }

.border-deep-orange.border-darken-1 {
  border: 1px solid #f4511e !important; }

.border-top-deep-orange.border-top-darken-1 {
  border-top: 1px solid #f4511e !important; }

.border-bottom-deep-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #f4511e !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-1 {
  border-left: 1px solid #f4511e !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-1 {
  border-right: 1px solid #f4511e !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-1 {
  border-right: 1px solid #f4511e !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-1 {
  border-left: 1px solid #f4511e !important; }

.overlay-deep-orange.overlay-darken-1 {
  background: #f4511e;
  /* The Fallback */
  background: rgba(244, 81, 30, 0.8); }

.deep-orange.darken-2 {
  color: #e64a19 !important; }

.bg-deep-orange.bg-darken-2 {
  background-color: #e64a19 !important; }

.btn-deep-orange.btn-darken-2 {
  border-color: #e64a19 !important;
  background-color: #e64a19 !important; }
  .btn-deep-orange.btn-darken-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-2:focus, .btn-deep-orange.btn-darken-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-2 {
  border-color: #e64a19 !important;
  color: #e64a19 !important; }
  .btn-outline-deep-orange.btn-outline-darken-2:hover {
    background-color: #e64a19 !important; }

.progress-deep-orange.progress-darken-2 {
  background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-ms-fill {
    background-color: #e64a19; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-2 .progress-bar {
      background-color: #e64a19; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e64a19 !important; }

.border-deep-orange.border-darken-2 {
  border: 1px solid #e64a19 !important; }

.border-top-deep-orange.border-top-darken-2 {
  border-top: 1px solid #e64a19 !important; }

.border-bottom-deep-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #e64a19 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-2 {
  border-left: 1px solid #e64a19 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-2 {
  border-right: 1px solid #e64a19 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-2 {
  border-right: 1px solid #e64a19 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-2 {
  border-left: 1px solid #e64a19 !important; }

.overlay-deep-orange.overlay-darken-2 {
  background: #e64a19;
  /* The Fallback */
  background: rgba(230, 74, 25, 0.8); }

.deep-orange.darken-3 {
  color: #d84315 !important; }

.bg-deep-orange.bg-darken-3 {
  background-color: #d84315 !important; }

.btn-deep-orange.btn-darken-3 {
  border-color: #e64a19 !important;
  background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-3:focus, .btn-deep-orange.btn-darken-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-3 {
  border-color: #d84315 !important;
  color: #d84315 !important; }
  .btn-outline-deep-orange.btn-outline-darken-3:hover {
    background-color: #d84315 !important; }

.progress-deep-orange.progress-darken-3 {
  background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-ms-fill {
    background-color: #d84315; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-3 .progress-bar {
      background-color: #d84315; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d84315 !important; }

.border-deep-orange.border-darken-3 {
  border: 1px solid #d84315 !important; }

.border-top-deep-orange.border-top-darken-3 {
  border-top: 1px solid #d84315 !important; }

.border-bottom-deep-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #d84315 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-3 {
  border-left: 1px solid #d84315 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-3 {
  border-right: 1px solid #d84315 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-3 {
  border-right: 1px solid #d84315 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-3 {
  border-left: 1px solid #d84315 !important; }

.overlay-deep-orange.overlay-darken-3 {
  background: #d84315;
  /* The Fallback */
  background: rgba(216, 67, 21, 0.8); }

.deep-orange.darken-4 {
  color: #bf360c !important; }

.bg-deep-orange.bg-darken-4 {
  background-color: #bf360c !important; }

.btn-deep-orange.btn-darken-4 {
  border-color: #e64a19 !important;
  background-color: #bf360c !important; }
  .btn-deep-orange.btn-darken-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-4:focus, .btn-deep-orange.btn-darken-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-4 {
  border-color: #bf360c !important;
  color: #bf360c !important; }
  .btn-outline-deep-orange.btn-outline-darken-4:hover {
    background-color: #bf360c !important; }

.progress-deep-orange.progress-darken-4 {
  background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-ms-fill {
    background-color: #bf360c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-4 .progress-bar {
      background-color: #bf360c; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bf360c !important; }

.border-deep-orange.border-darken-4 {
  border: 1px solid #bf360c !important; }

.border-top-deep-orange.border-top-darken-4 {
  border-top: 1px solid #bf360c !important; }

.border-bottom-deep-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #bf360c !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-4 {
  border-left: 1px solid #bf360c !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-4 {
  border-right: 1px solid #bf360c !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-4 {
  border-right: 1px solid #bf360c !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-4 {
  border-left: 1px solid #bf360c !important; }

.overlay-deep-orange.overlay-darken-4 {
  background: #bf360c;
  /* The Fallback */
  background: rgba(191, 54, 12, 0.8); }

.deep-orange.accent-1 {
  color: #ff9e80 !important; }

.bg-deep-orange.bg-accent-1 {
  background-color: #ff9e80 !important; }

.btn-deep-orange.btn-accent-1 {
  border-color: #e64a19 !important;
  background-color: #ff9e80 !important; }
  .btn-deep-orange.btn-accent-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-1:focus, .btn-deep-orange.btn-accent-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-1 {
  border-color: #ff9e80 !important;
  color: #ff9e80 !important; }
  .btn-outline-deep-orange.btn-outline-accent-1:hover {
    background-color: #ff9e80 !important; }

.progress-deep-orange.progress-accent-1 {
  background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ff9e80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-1 .progress-bar {
      background-color: #ff9e80; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9e80 !important; }

.border-deep-orange.border-accent-1 {
  border: 1px solid #ff9e80 !important; }

.border-top-deep-orange.border-top-accent-1 {
  border-top: 1px solid #ff9e80 !important; }

.border-bottom-deep-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #ff9e80 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-1 {
  border-left: 1px solid #ff9e80 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-1 {
  border-right: 1px solid #ff9e80 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-1 {
  border-right: 1px solid #ff9e80 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-1 {
  border-left: 1px solid #ff9e80 !important; }

.overlay-deep-orange.overlay-accent-1 {
  background: #ff9e80;
  /* The Fallback */
  background: rgba(255, 158, 128, 0.8); }

.deep-orange.accent-2 {
  color: #ff6e40 !important; }

.bg-deep-orange.bg-accent-2 {
  background-color: #ff6e40 !important; }

.btn-deep-orange.btn-accent-2 {
  border-color: #e64a19 !important;
  background-color: #ff6e40 !important; }
  .btn-deep-orange.btn-accent-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-2:focus, .btn-deep-orange.btn-accent-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-2 {
  border-color: #ff6e40 !important;
  color: #ff6e40 !important; }
  .btn-outline-deep-orange.btn-outline-accent-2:hover {
    background-color: #ff6e40 !important; }

.progress-deep-orange.progress-accent-2 {
  background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ff6e40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-2 .progress-bar {
      background-color: #ff6e40; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6e40 !important; }

.border-deep-orange.border-accent-2 {
  border: 1px solid #ff6e40 !important; }

.border-top-deep-orange.border-top-accent-2 {
  border-top: 1px solid #ff6e40 !important; }

.border-bottom-deep-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #ff6e40 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-2 {
  border-left: 1px solid #ff6e40 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-2 {
  border-right: 1px solid #ff6e40 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-2 {
  border-right: 1px solid #ff6e40 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-2 {
  border-left: 1px solid #ff6e40 !important; }

.overlay-deep-orange.overlay-accent-2 {
  background: #ff6e40;
  /* The Fallback */
  background: rgba(255, 110, 64, 0.8); }

.deep-orange.accent-3 {
  color: #ff3d00 !important; }

.bg-deep-orange.bg-accent-3 {
  background-color: #ff3d00 !important; }

.btn-deep-orange.btn-accent-3 {
  border-color: #e64a19 !important;
  background-color: #ff3d00 !important; }
  .btn-deep-orange.btn-accent-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-3:focus, .btn-deep-orange.btn-accent-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-3 {
  border-color: #ff3d00 !important;
  color: #ff3d00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-3:hover {
    background-color: #ff3d00 !important; }

.progress-deep-orange.progress-accent-3 {
  background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff3d00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-3 .progress-bar {
      background-color: #ff3d00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff3d00 !important; }

.border-deep-orange.border-accent-3 {
  border: 1px solid #ff3d00 !important; }

.border-top-deep-orange.border-top-accent-3 {
  border-top: 1px solid #ff3d00 !important; }

.border-bottom-deep-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #ff3d00 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-3 {
  border-left: 1px solid #ff3d00 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-3 {
  border-right: 1px solid #ff3d00 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-3 {
  border-right: 1px solid #ff3d00 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-3 {
  border-left: 1px solid #ff3d00 !important; }

.overlay-deep-orange.overlay-accent-3 {
  background: #ff3d00;
  /* The Fallback */
  background: rgba(255, 61, 0, 0.8); }

.deep-orange.accent-4 {
  color: #dd2c00 !important; }

.bg-deep-orange.bg-accent-4 {
  background-color: #dd2c00 !important; }

.btn-deep-orange.btn-accent-4 {
  border-color: #e64a19 !important;
  background-color: #dd2c00 !important; }
  .btn-deep-orange.btn-accent-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-4:focus, .btn-deep-orange.btn-accent-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-4 {
  border-color: #dd2c00 !important;
  color: #dd2c00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-4:hover {
    background-color: #dd2c00 !important; }

.progress-deep-orange.progress-accent-4 {
  background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-ms-fill {
    background-color: #dd2c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-4 .progress-bar {
      background-color: #dd2c00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dd2c00 !important; }

.border-deep-orange.border-accent-4 {
  border: 1px solid #dd2c00 !important; }

.border-top-deep-orange.border-top-accent-4 {
  border-top: 1px solid #dd2c00 !important; }

.border-bottom-deep-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #dd2c00 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-4 {
  border-left: 1px solid #dd2c00 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-4 {
  border-right: 1px solid #dd2c00 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-4 {
  border-right: 1px solid #dd2c00 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-4 {
  border-left: 1px solid #dd2c00 !important; }

.overlay-deep-orange.overlay-accent-4 {
  background: #dd2c00;
  /* The Fallback */
  background: rgba(221, 44, 0, 0.8); }

.brown.lighten-5 {
  color: #efebe9 !important; }

.bg-brown.bg-lighten-5 {
  background-color: #efebe9 !important; }

.btn-brown.btn-lighten-5 {
  border-color: #5d4037 !important;
  background-color: #efebe9 !important; }
  .btn-brown.btn-lighten-5:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-5:focus, .btn-brown.btn-lighten-5:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-5 {
  border-color: #efebe9 !important;
  color: #efebe9 !important; }
  .btn-outline-brown.btn-outline-lighten-5:hover {
    background-color: #efebe9 !important; }

.progress-brown.progress-lighten-5 {
  background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-ms-fill {
    background-color: #efebe9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-5 .progress-bar {
      background-color: #efebe9; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #efebe9 !important; }

.border-brown.border-lighten-5 {
  border: 1px solid #efebe9 !important; }

.border-top-brown.border-top-lighten-5 {
  border-top: 1px solid #efebe9 !important; }

.border-bottom-brown.border-bottom-lighten-5 {
  border-bottom: 1px solid #efebe9 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-5 {
  border-left: 1px solid #efebe9 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-5 {
  border-right: 1px solid #efebe9 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-5 {
  border-right: 1px solid #efebe9 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-5 {
  border-left: 1px solid #efebe9 !important; }

.overlay-brown.overlay-lighten-5 {
  background: #efebe9;
  /* The Fallback */
  background: rgba(239, 235, 233, 0.8); }

.brown.lighten-4 {
  color: #d7ccc8 !important; }

.bg-brown.bg-lighten-4 {
  background-color: #d7ccc8 !important; }

.btn-brown.btn-lighten-4 {
  border-color: #5d4037 !important;
  background-color: #d7ccc8 !important; }
  .btn-brown.btn-lighten-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-4:focus, .btn-brown.btn-lighten-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-4 {
  border-color: #d7ccc8 !important;
  color: #d7ccc8 !important; }
  .btn-outline-brown.btn-outline-lighten-4:hover {
    background-color: #d7ccc8 !important; }

.progress-brown.progress-lighten-4 {
  background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-ms-fill {
    background-color: #d7ccc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-4 .progress-bar {
      background-color: #d7ccc8; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d7ccc8 !important; }

.border-brown.border-lighten-4 {
  border: 1px solid #d7ccc8 !important; }

.border-top-brown.border-top-lighten-4 {
  border-top: 1px solid #d7ccc8 !important; }

.border-bottom-brown.border-bottom-lighten-4 {
  border-bottom: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-4 {
  border-left: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-4 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-4 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-4 {
  border-left: 1px solid #d7ccc8 !important; }

.overlay-brown.overlay-lighten-4 {
  background: #d7ccc8;
  /* The Fallback */
  background: rgba(215, 204, 200, 0.8); }

.brown.lighten-3 {
  color: #bcaaa4 !important; }

.bg-brown.bg-lighten-3 {
  background-color: #bcaaa4 !important; }

.btn-brown.btn-lighten-3 {
  border-color: #5d4037 !important;
  background-color: #bcaaa4 !important; }
  .btn-brown.btn-lighten-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-3:focus, .btn-brown.btn-lighten-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-3 {
  border-color: #bcaaa4 !important;
  color: #bcaaa4 !important; }
  .btn-outline-brown.btn-outline-lighten-3:hover {
    background-color: #bcaaa4 !important; }

.progress-brown.progress-lighten-3 {
  background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-ms-fill {
    background-color: #bcaaa4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-3 .progress-bar {
      background-color: #bcaaa4; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bcaaa4 !important; }

.border-brown.border-lighten-3 {
  border: 1px solid #bcaaa4 !important; }

.border-top-brown.border-top-lighten-3 {
  border-top: 1px solid #bcaaa4 !important; }

.border-bottom-brown.border-bottom-lighten-3 {
  border-bottom: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-3 {
  border-left: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-3 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-3 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-3 {
  border-left: 1px solid #bcaaa4 !important; }

.overlay-brown.overlay-lighten-3 {
  background: #bcaaa4;
  /* The Fallback */
  background: rgba(188, 170, 164, 0.8); }

.brown.lighten-2 {
  color: #a1887f !important; }

.bg-brown.bg-lighten-2 {
  background-color: #a1887f !important; }

.btn-brown.btn-lighten-2 {
  border-color: #5d4037 !important;
  background-color: #a1887f !important; }
  .btn-brown.btn-lighten-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-2:focus, .btn-brown.btn-lighten-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-2 {
  border-color: #a1887f !important;
  color: #a1887f !important; }
  .btn-outline-brown.btn-outline-lighten-2:hover {
    background-color: #a1887f !important; }

.progress-brown.progress-lighten-2 {
  background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-ms-fill {
    background-color: #a1887f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-2 .progress-bar {
      background-color: #a1887f; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a1887f !important; }

.border-brown.border-lighten-2 {
  border: 1px solid #a1887f !important; }

.border-top-brown.border-top-lighten-2 {
  border-top: 1px solid #a1887f !important; }

.border-bottom-brown.border-bottom-lighten-2 {
  border-bottom: 1px solid #a1887f !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-2 {
  border-left: 1px solid #a1887f !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-2 {
  border-right: 1px solid #a1887f !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-2 {
  border-right: 1px solid #a1887f !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-2 {
  border-left: 1px solid #a1887f !important; }

.overlay-brown.overlay-lighten-2 {
  background: #a1887f;
  /* The Fallback */
  background: rgba(161, 136, 127, 0.8); }

.brown.lighten-1 {
  color: #8d6e63 !important; }

.bg-brown.bg-lighten-1 {
  background-color: #8d6e63 !important; }

.btn-brown.btn-lighten-1 {
  border-color: #5d4037 !important;
  background-color: #8d6e63 !important; }
  .btn-brown.btn-lighten-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-1:focus, .btn-brown.btn-lighten-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-1 {
  border-color: #8d6e63 !important;
  color: #8d6e63 !important; }
  .btn-outline-brown.btn-outline-lighten-1:hover {
    background-color: #8d6e63 !important; }

.progress-brown.progress-lighten-1 {
  background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-ms-fill {
    background-color: #8d6e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-1 .progress-bar {
      background-color: #8d6e63; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8d6e63 !important; }

.border-brown.border-lighten-1 {
  border: 1px solid #8d6e63 !important; }

.border-top-brown.border-top-lighten-1 {
  border-top: 1px solid #8d6e63 !important; }

.border-bottom-brown.border-bottom-lighten-1 {
  border-bottom: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-1 {
  border-left: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-1 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-1 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-1 {
  border-left: 1px solid #8d6e63 !important; }

.overlay-brown.overlay-lighten-1 {
  background: #8d6e63;
  /* The Fallback */
  background: rgba(141, 110, 99, 0.8); }

.brown {
  color: #795548 !important; }

.bg-brown {
  background-color: #795548 !important; }
  .bg-brown .card-header, .bg-brown .card-footer {
    background-color: transparent; }

.toast-brown {
  background-color: #795548; }

.alert-brown {
  border-color: #795548 !important;
  background-color: #8f6555 !important;
  color: #090605 !important; }
  .alert-brown .alert-link {
    color: black !important; }

.border-brown {
  border-color: #795548; }

.border-top-brown {
  border-top-color: #795548; }

.border-bottom-brown {
  border-bottom-color: #795548; }

[dir="ltr"] .border-left-brown {
  border-left-color: #795548; }

[dir="ltr"] .border-right-brown {
  border-right-color: #795548; }

[dir="rtl"] .border-left-brown {
  border-right-color: #795548; }

[dir="rtl"] .border-right-brown {
  border-left-color: #795548; }

.badge-brown {
  background-color: #795548; }

.panel-brown {
  border-color: #795548; }
  .panel-brown .panel-heading {
    color: #FFF;
    border-color: #795548;
    background-color: #896052; }

.bg-brown.tag-glow, .border-brown.tag-glow {
  box-shadow: 0px 0px 10px #795548; }

.overlay-brown {
  background: #795548;
  /* The Fallback */
  background: rgba(121, 85, 72, 0.8); }

.card.card-outline-brown {
  border-width: 1px;
  border-style: solid;
  border-color: #795548;
  background-color: transparent; }
  .card.card-outline-brown .card-header, .card.card-outline-brown .card-footer {
    background-color: transparent; }

.btn-brown.btn-flat {
  background-color: transparent !important;
  color: #795548;
  border: none; }

.btn-brown.btn-raised, .btn-brown.btn-fab {
  background-color: #795548 !important;
  color: #fff !important;
  border-color: #795548; }
  .btn-brown.btn-raised.active, .btn-brown.btn-fab.active {
    background-color: #63453b !important;
    border-color: #63453b !important; }

.btn-group-raised .btn-brown {
  background-color: #795548 !important;
  color: #fff !important; }

.btn-outline-brown {
  border: 1px solid;
  border-color: #795548;
  background-color: transparent;
  color: #795548;
  box-shadow: none !important; }
  .btn-outline-brown:focus {
    background-color: transparent !important;
    color: #795548 !important;
    box-shadow: transparent !important; }
  .btn-outline-brown.active {
    background-color: #795548 !important;
    color: #FFF !important; }
  .btn-outline-brown:hover {
    background-color: #6f4e42 !important;
    color: #FFF !important; }
    .btn-outline-brown:hover svg {
      color: #FFF !important; }

.btn-outline-brown.btn-raised, .btn-outline-brown.btn-fab {
  border: 1px solid;
  border-color: #795548;
  background-color: transparent;
  color: #795548;
  box-shadow: none !important; }
  .btn-outline-brown.btn-raised:focus, .btn-outline-brown.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-brown.btn-raised.active, .btn-outline-brown.btn-fab.active {
    background-color: #795548 !important;
    color: #FFF !important; }
  .btn-outline-brown.btn-raised:hover, .btn-outline-brown.btn-fab:hover {
    background-color: #6f4e42 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-brown {
  background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-webkit-progress-value {
    background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-moz-progress-bar {
    background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-ms-fill {
    background-color: #795548; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-brown .progress-bar {
      background-color: #795548; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #795548 !important; }

.brown.darken-1 {
  color: #6d4c41 !important; }

.bg-brown.bg-darken-1 {
  background-color: #6d4c41 !important; }

.btn-brown.btn-darken-1 {
  border-color: #5d4037 !important;
  background-color: #6d4c41 !important; }
  .btn-brown.btn-darken-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-1:focus, .btn-brown.btn-darken-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-1 {
  border-color: #6d4c41 !important;
  color: #6d4c41 !important; }
  .btn-outline-brown.btn-outline-darken-1:hover {
    background-color: #6d4c41 !important; }

.progress-brown.progress-darken-1 {
  background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-webkit-progress-value {
    background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-moz-progress-bar {
    background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-ms-fill {
    background-color: #6d4c41; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-1 .progress-bar {
      background-color: #6d4c41; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6d4c41 !important; }

.border-brown.border-darken-1 {
  border: 1px solid #6d4c41 !important; }

.border-top-brown.border-top-darken-1 {
  border-top: 1px solid #6d4c41 !important; }

.border-bottom-brown.border-bottom-darken-1 {
  border-bottom: 1px solid #6d4c41 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-1 {
  border-left: 1px solid #6d4c41 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-1 {
  border-right: 1px solid #6d4c41 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-1 {
  border-right: 1px solid #6d4c41 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-1 {
  border-left: 1px solid #6d4c41 !important; }

.overlay-brown.overlay-darken-1 {
  background: #6d4c41;
  /* The Fallback */
  background: rgba(109, 76, 65, 0.8); }

.brown.darken-2 {
  color: #5d4037 !important; }

.bg-brown.bg-darken-2 {
  background-color: #5d4037 !important; }

.btn-brown.btn-darken-2 {
  border-color: #5d4037 !important;
  background-color: #5d4037 !important; }
  .btn-brown.btn-darken-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-2:focus, .btn-brown.btn-darken-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-2 {
  border-color: #5d4037 !important;
  color: #5d4037 !important; }
  .btn-outline-brown.btn-outline-darken-2:hover {
    background-color: #5d4037 !important; }

.progress-brown.progress-darken-2 {
  background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-webkit-progress-value {
    background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-moz-progress-bar {
    background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-ms-fill {
    background-color: #5d4037; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-2 .progress-bar {
      background-color: #5d4037; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5d4037 !important; }

.border-brown.border-darken-2 {
  border: 1px solid #5d4037 !important; }

.border-top-brown.border-top-darken-2 {
  border-top: 1px solid #5d4037 !important; }

.border-bottom-brown.border-bottom-darken-2 {
  border-bottom: 1px solid #5d4037 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-2 {
  border-left: 1px solid #5d4037 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-2 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-2 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-2 {
  border-left: 1px solid #5d4037 !important; }

.overlay-brown.overlay-darken-2 {
  background: #5d4037;
  /* The Fallback */
  background: rgba(93, 64, 55, 0.8); }

.brown.darken-3 {
  color: #4e342e !important; }

.bg-brown.bg-darken-3 {
  background-color: #4e342e !important; }

.btn-brown.btn-darken-3 {
  border-color: #5d4037 !important;
  background-color: #4e342e !important; }
  .btn-brown.btn-darken-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-3:focus, .btn-brown.btn-darken-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-3 {
  border-color: #4e342e !important;
  color: #4e342e !important; }
  .btn-outline-brown.btn-outline-darken-3:hover {
    background-color: #4e342e !important; }

.progress-brown.progress-darken-3 {
  background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-ms-fill {
    background-color: #4e342e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-3 .progress-bar {
      background-color: #4e342e; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4e342e !important; }

.border-brown.border-darken-3 {
  border: 1px solid #4e342e !important; }

.border-top-brown.border-top-darken-3 {
  border-top: 1px solid #4e342e !important; }

.border-bottom-brown.border-bottom-darken-3 {
  border-bottom: 1px solid #4e342e !important; }

[dir="ltr"] .border-left-brown.border-left-darken-3 {
  border-left: 1px solid #4e342e !important; }

[dir="ltr"] .border-right-brown.border-right-darken-3 {
  border-right: 1px solid #4e342e !important; }

[dir="rtl"] .border-left-brown.border-left-darken-3 {
  border-right: 1px solid #4e342e !important; }

[dir="rtl"] .border-right-brown.border-right-darken-3 {
  border-left: 1px solid #4e342e !important; }

.overlay-brown.overlay-darken-3 {
  background: #4e342e;
  /* The Fallback */
  background: rgba(78, 52, 46, 0.8); }

.brown.darken-4 {
  color: #3e2723 !important; }

.bg-brown.bg-darken-4 {
  background-color: #3e2723 !important; }

.btn-brown.btn-darken-4 {
  border-color: #5d4037 !important;
  background-color: #3e2723 !important; }
  .btn-brown.btn-darken-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-4:focus, .btn-brown.btn-darken-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-4 {
  border-color: #3e2723 !important;
  color: #3e2723 !important; }
  .btn-outline-brown.btn-outline-darken-4:hover {
    background-color: #3e2723 !important; }

.progress-brown.progress-darken-4 {
  background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-webkit-progress-value {
    background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-moz-progress-bar {
    background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-ms-fill {
    background-color: #3e2723; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-4 .progress-bar {
      background-color: #3e2723; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3e2723 !important; }

.border-brown.border-darken-4 {
  border: 1px solid #3e2723 !important; }

.border-top-brown.border-top-darken-4 {
  border-top: 1px solid #3e2723 !important; }

.border-bottom-brown.border-bottom-darken-4 {
  border-bottom: 1px solid #3e2723 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-4 {
  border-left: 1px solid #3e2723 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-4 {
  border-right: 1px solid #3e2723 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-4 {
  border-right: 1px solid #3e2723 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-4 {
  border-left: 1px solid #3e2723 !important; }

.overlay-brown.overlay-darken-4 {
  background: #3e2723;
  /* The Fallback */
  background: rgba(62, 39, 35, 0.8); }

.brown.accent-1 {
  color: #d7ccc8 !important; }

.bg-brown.bg-accent-1 {
  background-color: #d7ccc8 !important; }

.btn-brown.btn-accent-1 {
  border-color: #5d4037 !important;
  background-color: #d7ccc8 !important; }
  .btn-brown.btn-accent-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-1:focus, .btn-brown.btn-accent-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-1 {
  border-color: #d7ccc8 !important;
  color: #d7ccc8 !important; }
  .btn-outline-brown.btn-outline-accent-1:hover {
    background-color: #d7ccc8 !important; }

.progress-brown.progress-accent-1 {
  background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-webkit-progress-value {
    background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-moz-progress-bar {
    background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-ms-fill {
    background-color: #d7ccc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-1 .progress-bar {
      background-color: #d7ccc8; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d7ccc8 !important; }

.border-brown.border-accent-1 {
  border: 1px solid #d7ccc8 !important; }

.border-top-brown.border-top-accent-1 {
  border-top: 1px solid #d7ccc8 !important; }

.border-bottom-brown.border-bottom-accent-1 {
  border-bottom: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-1 {
  border-left: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-1 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-1 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-1 {
  border-left: 1px solid #d7ccc8 !important; }

.overlay-brown.overlay-accent-1 {
  background: #d7ccc8;
  /* The Fallback */
  background: rgba(215, 204, 200, 0.8); }

.brown.accent-2 {
  color: #bcaaa4 !important; }

.bg-brown.bg-accent-2 {
  background-color: #bcaaa4 !important; }

.btn-brown.btn-accent-2 {
  border-color: #5d4037 !important;
  background-color: #bcaaa4 !important; }
  .btn-brown.btn-accent-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-2:focus, .btn-brown.btn-accent-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-2 {
  border-color: #bcaaa4 !important;
  color: #bcaaa4 !important; }
  .btn-outline-brown.btn-outline-accent-2:hover {
    background-color: #bcaaa4 !important; }

.progress-brown.progress-accent-2 {
  background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-webkit-progress-value {
    background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-moz-progress-bar {
    background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-ms-fill {
    background-color: #bcaaa4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-2 .progress-bar {
      background-color: #bcaaa4; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bcaaa4 !important; }

.border-brown.border-accent-2 {
  border: 1px solid #bcaaa4 !important; }

.border-top-brown.border-top-accent-2 {
  border-top: 1px solid #bcaaa4 !important; }

.border-bottom-brown.border-bottom-accent-2 {
  border-bottom: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-2 {
  border-left: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-2 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-2 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-2 {
  border-left: 1px solid #bcaaa4 !important; }

.overlay-brown.overlay-accent-2 {
  background: #bcaaa4;
  /* The Fallback */
  background: rgba(188, 170, 164, 0.8); }

.brown.accent-3 {
  color: #8d6e63 !important; }

.bg-brown.bg-accent-3 {
  background-color: #8d6e63 !important; }

.btn-brown.btn-accent-3 {
  border-color: #5d4037 !important;
  background-color: #8d6e63 !important; }
  .btn-brown.btn-accent-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-3:focus, .btn-brown.btn-accent-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-3 {
  border-color: #8d6e63 !important;
  color: #8d6e63 !important; }
  .btn-outline-brown.btn-outline-accent-3:hover {
    background-color: #8d6e63 !important; }

.progress-brown.progress-accent-3 {
  background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-webkit-progress-value {
    background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-moz-progress-bar {
    background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-ms-fill {
    background-color: #8d6e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-3 .progress-bar {
      background-color: #8d6e63; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8d6e63 !important; }

.border-brown.border-accent-3 {
  border: 1px solid #8d6e63 !important; }

.border-top-brown.border-top-accent-3 {
  border-top: 1px solid #8d6e63 !important; }

.border-bottom-brown.border-bottom-accent-3 {
  border-bottom: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-3 {
  border-left: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-3 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-3 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-3 {
  border-left: 1px solid #8d6e63 !important; }

.overlay-brown.overlay-accent-3 {
  background: #8d6e63;
  /* The Fallback */
  background: rgba(141, 110, 99, 0.8); }

.brown.accent-4 {
  color: #5d4037 !important; }

.bg-brown.bg-accent-4 {
  background-color: #5d4037 !important; }

.btn-brown.btn-accent-4 {
  border-color: #5d4037 !important;
  background-color: #5d4037 !important; }
  .btn-brown.btn-accent-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-4:focus, .btn-brown.btn-accent-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-4 {
  border-color: #5d4037 !important;
  color: #5d4037 !important; }
  .btn-outline-brown.btn-outline-accent-4:hover {
    background-color: #5d4037 !important; }

.progress-brown.progress-accent-4 {
  background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-webkit-progress-value {
    background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-moz-progress-bar {
    background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-ms-fill {
    background-color: #5d4037; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-4 .progress-bar {
      background-color: #5d4037; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5d4037 !important; }

.border-brown.border-accent-4 {
  border: 1px solid #5d4037 !important; }

.border-top-brown.border-top-accent-4 {
  border-top: 1px solid #5d4037 !important; }

.border-bottom-brown.border-bottom-accent-4 {
  border-bottom: 1px solid #5d4037 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-4 {
  border-left: 1px solid #5d4037 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-4 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-4 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-4 {
  border-left: 1px solid #5d4037 !important; }

.overlay-brown.overlay-accent-4 {
  background: #5d4037;
  /* The Fallback */
  background: rgba(93, 64, 55, 0.8); }

.grey.lighten-5 {
  color: #fafafa !important; }

.bg-grey.bg-lighten-5 {
  background-color: #fafafa !important; }

.btn-grey.btn-lighten-5 {
  border-color: #616161 !important;
  background-color: #fafafa !important; }
  .btn-grey.btn-lighten-5:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-5:focus, .btn-grey.btn-lighten-5:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-5 {
  border-color: #fafafa !important;
  color: #fafafa !important; }
  .btn-outline-grey.btn-outline-lighten-5:hover {
    background-color: #fafafa !important; }

.progress-grey.progress-lighten-5 {
  background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #fafafa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-5 .progress-bar {
      background-color: #fafafa; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fafafa !important; }

.border-grey.border-lighten-5 {
  border: 1px solid #fafafa !important; }

.border-top-grey.border-top-lighten-5 {
  border-top: 1px solid #fafafa !important; }

.border-bottom-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #fafafa !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-5 {
  border-left: 1px solid #fafafa !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-5 {
  border-right: 1px solid #fafafa !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-5 {
  border-right: 1px solid #fafafa !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-5 {
  border-left: 1px solid #fafafa !important; }

.overlay-grey.overlay-lighten-5 {
  background: #fafafa;
  /* The Fallback */
  background: rgba(250, 250, 250, 0.8); }

.grey.lighten-4 {
  color: #f5f5f5 !important; }

.bg-grey.bg-lighten-4 {
  background-color: #f5f5f5 !important; }

.btn-grey.btn-lighten-4 {
  border-color: #616161 !important;
  background-color: #f5f5f5 !important; }
  .btn-grey.btn-lighten-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-4:focus, .btn-grey.btn-lighten-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-4 {
  border-color: #f5f5f5 !important;
  color: #f5f5f5 !important; }
  .btn-outline-grey.btn-outline-lighten-4:hover {
    background-color: #f5f5f5 !important; }

.progress-grey.progress-lighten-4 {
  background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #f5f5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-4 .progress-bar {
      background-color: #f5f5f5; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f5f5f5 !important; }

.border-grey.border-lighten-4 {
  border: 1px solid #f5f5f5 !important; }

.border-top-grey.border-top-lighten-4 {
  border-top: 1px solid #f5f5f5 !important; }

.border-bottom-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-4 {
  border-left: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-4 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-4 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-4 {
  border-left: 1px solid #f5f5f5 !important; }

.overlay-grey.overlay-lighten-4 {
  background: #f5f5f5;
  /* The Fallback */
  background: rgba(245, 245, 245, 0.8); }

.grey.lighten-3 {
  color: #eee !important; }

.bg-grey.bg-lighten-3 {
  background-color: #eee !important; }

.btn-grey.btn-lighten-3 {
  border-color: #616161 !important;
  background-color: #eee !important; }
  .btn-grey.btn-lighten-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-3:focus, .btn-grey.btn-lighten-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-3 {
  border-color: #eee !important;
  color: #eee !important; }
  .btn-outline-grey.btn-outline-lighten-3:hover {
    background-color: #eee !important; }

.progress-grey.progress-lighten-3 {
  background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #eee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-3 .progress-bar {
      background-color: #eee; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eee !important; }

.border-grey.border-lighten-3 {
  border: 1px solid #eee !important; }

.border-top-grey.border-top-lighten-3 {
  border-top: 1px solid #eee !important; }

.border-bottom-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #eee !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-3 {
  border-left: 1px solid #eee !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-3 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-3 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-3 {
  border-left: 1px solid #eee !important; }

.overlay-grey.overlay-lighten-3 {
  background: #eee;
  /* The Fallback */
  background: rgba(238, 238, 238, 0.8); }

.grey.lighten-2 {
  color: #e0e0e0 !important; }

.bg-grey.bg-lighten-2 {
  background-color: #e0e0e0 !important; }

.btn-grey.btn-lighten-2 {
  border-color: #616161 !important;
  background-color: #e0e0e0 !important; }
  .btn-grey.btn-lighten-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-2:focus, .btn-grey.btn-lighten-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-2 {
  border-color: #e0e0e0 !important;
  color: #e0e0e0 !important; }
  .btn-outline-grey.btn-outline-lighten-2:hover {
    background-color: #e0e0e0 !important; }

.progress-grey.progress-lighten-2 {
  background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #e0e0e0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-2 .progress-bar {
      background-color: #e0e0e0; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0e0e0 !important; }

.border-grey.border-lighten-2 {
  border: 1px solid #e0e0e0 !important; }

.border-top-grey.border-top-lighten-2 {
  border-top: 1px solid #e0e0e0 !important; }

.border-bottom-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #e0e0e0 !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-2 {
  border-left: 1px solid #e0e0e0 !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-2 {
  border-right: 1px solid #e0e0e0 !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-2 {
  border-right: 1px solid #e0e0e0 !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-2 {
  border-left: 1px solid #e0e0e0 !important; }

.overlay-grey.overlay-lighten-2 {
  background: #e0e0e0;
  /* The Fallback */
  background: rgba(224, 224, 224, 0.8); }

.grey.lighten-1 {
  color: #bdbdbd !important; }

.bg-grey.bg-lighten-1 {
  background-color: #bdbdbd !important; }

.btn-grey.btn-lighten-1 {
  border-color: #616161 !important;
  background-color: #bdbdbd !important; }
  .btn-grey.btn-lighten-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-1:focus, .btn-grey.btn-lighten-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-1 {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important; }
  .btn-outline-grey.btn-outline-lighten-1:hover {
    background-color: #bdbdbd !important; }

.progress-grey.progress-lighten-1 {
  background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #bdbdbd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-1 .progress-bar {
      background-color: #bdbdbd; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bdbdbd !important; }

.border-grey.border-lighten-1 {
  border: 1px solid #bdbdbd !important; }

.border-top-grey.border-top-lighten-1 {
  border-top: 1px solid #bdbdbd !important; }

.border-bottom-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-1 {
  border-left: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-1 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-1 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-1 {
  border-left: 1px solid #bdbdbd !important; }

.overlay-grey.overlay-lighten-1 {
  background: #bdbdbd;
  /* The Fallback */
  background: rgba(189, 189, 189, 0.8); }

.grey {
  color: #9e9e9e !important; }

.bg-grey {
  background-color: #9e9e9e !important; }
  .bg-grey .card-header, .bg-grey .card-footer {
    background-color: transparent; }

.toast-grey {
  background-color: #9e9e9e; }

.alert-grey {
  border-color: #9e9e9e !important;
  background-color: #b0b0b0 !important;
  color: #454545 !important; }
  .alert-grey .alert-link {
    color: #303030 !important; }

.border-grey {
  border-color: #9e9e9e; }

.border-top-grey {
  border-top-color: #9e9e9e; }

.border-bottom-grey {
  border-bottom-color: #9e9e9e; }

[dir="ltr"] .border-left-grey {
  border-left-color: #9e9e9e; }

[dir="ltr"] .border-right-grey {
  border-right-color: #9e9e9e; }

[dir="rtl"] .border-left-grey {
  border-right-color: #9e9e9e; }

[dir="rtl"] .border-right-grey {
  border-left-color: #9e9e9e; }

.badge-grey {
  background-color: #9e9e9e; }

.panel-grey {
  border-color: #9e9e9e; }
  .panel-grey .panel-heading {
    color: #FFF;
    border-color: #9e9e9e;
    background-color: #ababab; }

.bg-grey.tag-glow, .border-grey.tag-glow {
  box-shadow: 0px 0px 10px #9e9e9e; }

.overlay-grey {
  background: #9e9e9e;
  /* The Fallback */
  background: rgba(158, 158, 158, 0.8); }

.card.card-outline-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  background-color: transparent; }
  .card.card-outline-grey .card-header, .card.card-outline-grey .card-footer {
    background-color: transparent; }

.btn-grey.btn-flat {
  background-color: transparent !important;
  color: #9e9e9e;
  border: none; }

.btn-grey.btn-raised, .btn-grey.btn-fab {
  background-color: #9e9e9e !important;
  color: #fff !important;
  border-color: #9e9e9e; }
  .btn-grey.btn-raised.active, .btn-grey.btn-fab.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important; }

.btn-group-raised .btn-grey {
  background-color: #9e9e9e !important;
  color: #fff !important; }

.btn-outline-grey {
  border: 1px solid;
  border-color: #9e9e9e;
  background-color: transparent;
  color: #9e9e9e;
  box-shadow: none !important; }
  .btn-outline-grey:focus {
    background-color: transparent !important;
    color: #9e9e9e !important;
    box-shadow: transparent !important; }
  .btn-outline-grey.active {
    background-color: #9e9e9e !important;
    color: #FFF !important; }
  .btn-outline-grey:hover {
    background-color: #969696 !important;
    color: #FFF !important; }
    .btn-outline-grey:hover svg {
      color: #FFF !important; }

.btn-outline-grey.btn-raised, .btn-outline-grey.btn-fab {
  border: 1px solid;
  border-color: #9e9e9e;
  background-color: transparent;
  color: #9e9e9e;
  box-shadow: none !important; }
  .btn-outline-grey.btn-raised:focus, .btn-outline-grey.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-grey.btn-raised.active, .btn-outline-grey.btn-fab.active {
    background-color: #9e9e9e !important;
    color: #FFF !important; }
  .btn-outline-grey.btn-raised:hover, .btn-outline-grey.btn-fab:hover {
    background-color: #969696 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-grey {
  background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-webkit-progress-value {
    background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-moz-progress-bar {
    background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-ms-fill {
    background-color: #9e9e9e; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-grey .progress-bar {
      background-color: #9e9e9e; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9e9e !important; }

.grey.darken-1 {
  color: #757575 !important; }

.bg-grey.bg-darken-1 {
  background-color: #757575 !important; }

.btn-grey.btn-darken-1 {
  border-color: #616161 !important;
  background-color: #757575 !important; }
  .btn-grey.btn-darken-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-1:focus, .btn-grey.btn-darken-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-1 {
  border-color: #757575 !important;
  color: #757575 !important; }
  .btn-outline-grey.btn-outline-darken-1:hover {
    background-color: #757575 !important; }

.progress-grey.progress-darken-1 {
  background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-ms-fill {
    background-color: #757575; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-1 .progress-bar {
      background-color: #757575; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #757575 !important; }

.border-grey.border-darken-1 {
  border: 1px solid #757575 !important; }

.border-top-grey.border-top-darken-1 {
  border-top: 1px solid #757575 !important; }

.border-bottom-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #757575 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-1 {
  border-left: 1px solid #757575 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-1 {
  border-right: 1px solid #757575 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-1 {
  border-right: 1px solid #757575 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-1 {
  border-left: 1px solid #757575 !important; }

.overlay-grey.overlay-darken-1 {
  background: #757575;
  /* The Fallback */
  background: rgba(117, 117, 117, 0.8); }

.grey.darken-2 {
  color: #616161 !important; }

.bg-grey.bg-darken-2 {
  background-color: #616161 !important; }

.btn-grey.btn-darken-2 {
  border-color: #616161 !important;
  background-color: #616161 !important; }
  .btn-grey.btn-darken-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-2:focus, .btn-grey.btn-darken-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-2 {
  border-color: #616161 !important;
  color: #616161 !important; }
  .btn-outline-grey.btn-outline-darken-2:hover {
    background-color: #616161 !important; }

.progress-grey.progress-darken-2 {
  background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-ms-fill {
    background-color: #616161; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-2 .progress-bar {
      background-color: #616161; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important; }

.border-grey.border-darken-2 {
  border: 1px solid #616161 !important; }

.border-top-grey.border-top-darken-2 {
  border-top: 1px solid #616161 !important; }

.border-bottom-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #616161 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-2 {
  border-left: 1px solid #616161 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-2 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-2 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-2 {
  border-left: 1px solid #616161 !important; }

.overlay-grey.overlay-darken-2 {
  background: #616161;
  /* The Fallback */
  background: rgba(97, 97, 97, 0.8); }

.grey.darken-3 {
  color: #424242 !important; }

.bg-grey.bg-darken-3 {
  background-color: #424242 !important; }

.btn-grey.btn-darken-3 {
  border-color: #616161 !important;
  background-color: #424242 !important; }
  .btn-grey.btn-darken-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-3:focus, .btn-grey.btn-darken-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-3 {
  border-color: #424242 !important;
  color: #424242 !important; }
  .btn-outline-grey.btn-outline-darken-3:hover {
    background-color: #424242 !important; }

.progress-grey.progress-darken-3 {
  background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-ms-fill {
    background-color: #424242; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-3 .progress-bar {
      background-color: #424242; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #424242 !important; }

.border-grey.border-darken-3 {
  border: 1px solid #424242 !important; }

.border-top-grey.border-top-darken-3 {
  border-top: 1px solid #424242 !important; }

.border-bottom-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #424242 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-3 {
  border-left: 1px solid #424242 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-3 {
  border-right: 1px solid #424242 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-3 {
  border-right: 1px solid #424242 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-3 {
  border-left: 1px solid #424242 !important; }

.overlay-grey.overlay-darken-3 {
  background: #424242;
  /* The Fallback */
  background: rgba(66, 66, 66, 0.8); }

.grey.darken-4 {
  color: #212121 !important; }

.bg-grey.bg-darken-4 {
  background-color: #212121 !important; }

.btn-grey.btn-darken-4 {
  border-color: #616161 !important;
  background-color: #212121 !important; }
  .btn-grey.btn-darken-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-4:focus, .btn-grey.btn-darken-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-4 {
  border-color: #212121 !important;
  color: #212121 !important; }
  .btn-outline-grey.btn-outline-darken-4:hover {
    background-color: #212121 !important; }

.progress-grey.progress-darken-4 {
  background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-ms-fill {
    background-color: #212121; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-4 .progress-bar {
      background-color: #212121; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #212121 !important; }

.border-grey.border-darken-4 {
  border: 1px solid #212121 !important; }

.border-top-grey.border-top-darken-4 {
  border-top: 1px solid #212121 !important; }

.border-bottom-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #212121 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-4 {
  border-left: 1px solid #212121 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-4 {
  border-right: 1px solid #212121 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-4 {
  border-right: 1px solid #212121 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-4 {
  border-left: 1px solid #212121 !important; }

.overlay-grey.overlay-darken-4 {
  background: #212121;
  /* The Fallback */
  background: rgba(33, 33, 33, 0.8); }

.grey.accent-1 {
  color: #f5f5f5 !important; }

.bg-grey.bg-accent-1 {
  background-color: #f5f5f5 !important; }

.btn-grey.btn-accent-1 {
  border-color: #616161 !important;
  background-color: #f5f5f5 !important; }
  .btn-grey.btn-accent-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-1:focus, .btn-grey.btn-accent-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-1 {
  border-color: #f5f5f5 !important;
  color: #f5f5f5 !important; }
  .btn-outline-grey.btn-outline-accent-1:hover {
    background-color: #f5f5f5 !important; }

.progress-grey.progress-accent-1 {
  background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-ms-fill {
    background-color: #f5f5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-1 .progress-bar {
      background-color: #f5f5f5; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f5f5f5 !important; }

.border-grey.border-accent-1 {
  border: 1px solid #f5f5f5 !important; }

.border-top-grey.border-top-accent-1 {
  border-top: 1px solid #f5f5f5 !important; }

.border-bottom-grey.border-bottom-accent-1 {
  border-bottom: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-left-grey.border-left-accent-1 {
  border-left: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-right-grey.border-right-accent-1 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-left-grey.border-left-accent-1 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-right-grey.border-right-accent-1 {
  border-left: 1px solid #f5f5f5 !important; }

.overlay-grey.overlay-accent-1 {
  background: #f5f5f5;
  /* The Fallback */
  background: rgba(245, 245, 245, 0.8); }

.grey.accent-2 {
  color: #eee !important; }

.bg-grey.bg-accent-2 {
  background-color: #eee !important; }

.btn-grey.btn-accent-2 {
  border-color: #616161 !important;
  background-color: #eee !important; }
  .btn-grey.btn-accent-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-2:focus, .btn-grey.btn-accent-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-2 {
  border-color: #eee !important;
  color: #eee !important; }
  .btn-outline-grey.btn-outline-accent-2:hover {
    background-color: #eee !important; }

.progress-grey.progress-accent-2 {
  background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-ms-fill {
    background-color: #eee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-2 .progress-bar {
      background-color: #eee; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eee !important; }

.border-grey.border-accent-2 {
  border: 1px solid #eee !important; }

.border-top-grey.border-top-accent-2 {
  border-top: 1px solid #eee !important; }

.border-bottom-grey.border-bottom-accent-2 {
  border-bottom: 1px solid #eee !important; }

[dir="ltr"] .border-left-grey.border-left-accent-2 {
  border-left: 1px solid #eee !important; }

[dir="ltr"] .border-right-grey.border-right-accent-2 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-left-grey.border-left-accent-2 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-right-grey.border-right-accent-2 {
  border-left: 1px solid #eee !important; }

.overlay-grey.overlay-accent-2 {
  background: #eee;
  /* The Fallback */
  background: rgba(238, 238, 238, 0.8); }

.grey.accent-3 {
  color: #bdbdbd !important; }

.bg-grey.bg-accent-3 {
  background-color: #bdbdbd !important; }

.btn-grey.btn-accent-3 {
  border-color: #616161 !important;
  background-color: #bdbdbd !important; }
  .btn-grey.btn-accent-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-3:focus, .btn-grey.btn-accent-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-3 {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important; }
  .btn-outline-grey.btn-outline-accent-3:hover {
    background-color: #bdbdbd !important; }

.progress-grey.progress-accent-3 {
  background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-ms-fill {
    background-color: #bdbdbd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-3 .progress-bar {
      background-color: #bdbdbd; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bdbdbd !important; }

.border-grey.border-accent-3 {
  border: 1px solid #bdbdbd !important; }

.border-top-grey.border-top-accent-3 {
  border-top: 1px solid #bdbdbd !important; }

.border-bottom-grey.border-bottom-accent-3 {
  border-bottom: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-left-grey.border-left-accent-3 {
  border-left: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-right-grey.border-right-accent-3 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-left-grey.border-left-accent-3 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-right-grey.border-right-accent-3 {
  border-left: 1px solid #bdbdbd !important; }

.overlay-grey.overlay-accent-3 {
  background: #bdbdbd;
  /* The Fallback */
  background: rgba(189, 189, 189, 0.8); }

.grey.accent-4 {
  color: #616161 !important; }

.bg-grey.bg-accent-4 {
  background-color: #616161 !important; }

.btn-grey.btn-accent-4 {
  border-color: #616161 !important;
  background-color: #616161 !important; }
  .btn-grey.btn-accent-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-4:focus, .btn-grey.btn-accent-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-4 {
  border-color: #616161 !important;
  color: #616161 !important; }
  .btn-outline-grey.btn-outline-accent-4:hover {
    background-color: #616161 !important; }

.progress-grey.progress-accent-4 {
  background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-ms-fill {
    background-color: #616161; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-4 .progress-bar {
      background-color: #616161; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important; }

.border-grey.border-accent-4 {
  border: 1px solid #616161 !important; }

.border-top-grey.border-top-accent-4 {
  border-top: 1px solid #616161 !important; }

.border-bottom-grey.border-bottom-accent-4 {
  border-bottom: 1px solid #616161 !important; }

[dir="ltr"] .border-left-grey.border-left-accent-4 {
  border-left: 1px solid #616161 !important; }

[dir="ltr"] .border-right-grey.border-right-accent-4 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-left-grey.border-left-accent-4 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-right-grey.border-right-accent-4 {
  border-left: 1px solid #616161 !important; }

.overlay-grey.overlay-accent-4 {
  background: #616161;
  /* The Fallback */
  background: rgba(97, 97, 97, 0.8); }

.blue-grey.lighten-5 {
  color: #eceff1 !important; }

.bg-blue-grey.bg-lighten-5 {
  background-color: #eceff1 !important; }

.btn-blue-grey.btn-lighten-5 {
  border-color: #455a64 !important;
  background-color: #eceff1 !important; }
  .btn-blue-grey.btn-lighten-5:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-5:focus, .btn-blue-grey.btn-lighten-5:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-5 {
  border-color: #eceff1 !important;
  color: #eceff1 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #eceff1 !important; }

.progress-blue-grey.progress-lighten-5 {
  background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #eceff1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-5 .progress-bar {
      background-color: #eceff1; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eceff1 !important; }

.border-blue-grey.border-lighten-5 {
  border: 1px solid #eceff1 !important; }

.border-top-blue-grey.border-top-lighten-5 {
  border-top: 1px solid #eceff1 !important; }

.border-bottom-blue-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #eceff1 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-5 {
  border-left: 1px solid #eceff1 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-5 {
  border-right: 1px solid #eceff1 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-5 {
  border-right: 1px solid #eceff1 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-5 {
  border-left: 1px solid #eceff1 !important; }

.overlay-blue-grey.overlay-lighten-5 {
  background: #eceff1;
  /* The Fallback */
  background: rgba(236, 239, 241, 0.8); }

.blue-grey.lighten-4 {
  color: #cfd8dc !important; }

.bg-blue-grey.bg-lighten-4 {
  background-color: #cfd8dc !important; }

.btn-blue-grey.btn-lighten-4 {
  border-color: #455a64 !important;
  background-color: #cfd8dc !important; }
  .btn-blue-grey.btn-lighten-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-4:focus, .btn-blue-grey.btn-lighten-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-4 {
  border-color: #cfd8dc !important;
  color: #cfd8dc !important; }
  .btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #cfd8dc !important; }

.progress-blue-grey.progress-lighten-4 {
  background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #cfd8dc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-4 .progress-bar {
      background-color: #cfd8dc; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cfd8dc !important; }

.border-blue-grey.border-lighten-4 {
  border: 1px solid #cfd8dc !important; }

.border-top-blue-grey.border-top-lighten-4 {
  border-top: 1px solid #cfd8dc !important; }

.border-bottom-blue-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-4 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-4 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-4 {
  border-left: 1px solid #cfd8dc !important; }

.overlay-blue-grey.overlay-lighten-4 {
  background: #cfd8dc;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.lighten-3 {
  color: #b0bec5 !important; }

.bg-blue-grey.bg-lighten-3 {
  background-color: #b0bec5 !important; }

.btn-blue-grey.btn-lighten-3 {
  border-color: #455a64 !important;
  background-color: #b0bec5 !important; }
  .btn-blue-grey.btn-lighten-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-3:focus, .btn-blue-grey.btn-lighten-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-3 {
  border-color: #b0bec5 !important;
  color: #b0bec5 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #b0bec5 !important; }

.progress-blue-grey.progress-lighten-3 {
  background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #b0bec5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-3 .progress-bar {
      background-color: #b0bec5; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b0bec5 !important; }

.border-blue-grey.border-lighten-3 {
  border: 1px solid #b0bec5 !important; }

.border-top-blue-grey.border-top-lighten-3 {
  border-top: 1px solid #b0bec5 !important; }

.border-bottom-blue-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-3 {
  border-left: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-3 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-3 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-3 {
  border-left: 1px solid #b0bec5 !important; }

.overlay-blue-grey.overlay-lighten-3 {
  background: #b0bec5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.lighten-2 {
  color: #90a4ae !important; }

.bg-blue-grey.bg-lighten-2 {
  background-color: #90a4ae !important; }

.btn-blue-grey.btn-lighten-2 {
  border-color: #455a64 !important;
  background-color: #90a4ae !important; }
  .btn-blue-grey.btn-lighten-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-2:focus, .btn-blue-grey.btn-lighten-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-2 {
  border-color: #90a4ae !important;
  color: #90a4ae !important; }
  .btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90a4ae !important; }

.progress-blue-grey.progress-lighten-2 {
  background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #90a4ae; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-2 .progress-bar {
      background-color: #90a4ae; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90a4ae !important; }

.border-blue-grey.border-lighten-2 {
  border: 1px solid #90a4ae !important; }

.border-top-blue-grey.border-top-lighten-2 {
  border-top: 1px solid #90a4ae !important; }

.border-bottom-blue-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #90a4ae !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-2 {
  border-left: 1px solid #90a4ae !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-2 {
  border-right: 1px solid #90a4ae !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-2 {
  border-right: 1px solid #90a4ae !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-2 {
  border-left: 1px solid #90a4ae !important; }

.overlay-blue-grey.overlay-lighten-2 {
  background: #90a4ae;
  /* The Fallback */
  background: rgba(144, 164, 174, 0.8); }

.blue-grey.lighten-1 {
  color: #78909c !important; }

.bg-blue-grey.bg-lighten-1 {
  background-color: #78909c !important; }

.btn-blue-grey.btn-lighten-1 {
  border-color: #455a64 !important;
  background-color: #78909c !important; }
  .btn-blue-grey.btn-lighten-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-1:focus, .btn-blue-grey.btn-lighten-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-1 {
  border-color: #78909c !important;
  color: #78909c !important; }
  .btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909c !important; }

.progress-blue-grey.progress-lighten-1 {
  background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #78909c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-1 .progress-bar {
      background-color: #78909c; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909c !important; }

.border-blue-grey.border-lighten-1 {
  border: 1px solid #78909c !important; }

.border-top-blue-grey.border-top-lighten-1 {
  border-top: 1px solid #78909c !important; }

.border-bottom-blue-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #78909c !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-1 {
  border-left: 1px solid #78909c !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-1 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-1 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-1 {
  border-left: 1px solid #78909c !important; }

.overlay-blue-grey.overlay-lighten-1 {
  background: #78909c;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey {
  color: #607d8b !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }
  .bg-blue-grey .card-header, .bg-blue-grey .card-footer {
    background-color: transparent; }

.toast-blue-grey {
  background-color: #607d8b; }

.alert-blue-grey {
  border-color: #607d8b !important;
  background-color: #718f9d !important;
  color: #171e21 !important; }
  .alert-blue-grey .alert-link {
    color: #060809 !important; }

.border-blue-grey {
  border-color: #607d8b; }

.border-top-blue-grey {
  border-top-color: #607d8b; }

.border-bottom-blue-grey {
  border-bottom-color: #607d8b; }

[dir="ltr"] .border-left-blue-grey {
  border-left-color: #607d8b; }

[dir="ltr"] .border-right-blue-grey {
  border-right-color: #607d8b; }

[dir="rtl"] .border-left-blue-grey {
  border-right-color: #607d8b; }

[dir="rtl"] .border-right-blue-grey {
  border-left-color: #607d8b; }

.badge-blue-grey {
  background-color: #607d8b; }

.panel-blue-grey {
  border-color: #607d8b; }
  .panel-blue-grey .panel-heading {
    color: #FFF;
    border-color: #607d8b;
    background-color: #6b8a99; }

.bg-blue-grey.tag-glow, .border-blue-grey.tag-glow {
  box-shadow: 0px 0px 10px #607d8b; }

.overlay-blue-grey {
  background: #607d8b;
  /* The Fallback */
  background: rgba(96, 125, 139, 0.8); }

.card.card-outline-blue-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #607d8b;
  background-color: transparent; }
  .card.card-outline-blue-grey .card-header, .card.card-outline-blue-grey .card-footer {
    background-color: transparent; }

.btn-blue-grey.btn-flat {
  background-color: transparent !important;
  color: #607d8b;
  border: none; }

.btn-blue-grey.btn-raised, .btn-blue-grey.btn-fab {
  background-color: #607d8b !important;
  color: #fff !important;
  border-color: #607d8b; }
  .btn-blue-grey.btn-raised.active, .btn-blue-grey.btn-fab.active {
    background-color: #516a76 !important;
    border-color: #516a76 !important; }

.btn-group-raised .btn-blue-grey {
  background-color: #607d8b !important;
  color: #fff !important; }

.btn-outline-blue-grey {
  border: 1px solid;
  border-color: #607d8b;
  background-color: transparent;
  color: #607d8b;
  box-shadow: none !important; }
  .btn-outline-blue-grey:focus {
    background-color: transparent !important;
    color: #607d8b !important;
    box-shadow: transparent !important; }
  .btn-outline-blue-grey.active {
    background-color: #607d8b !important;
    color: #FFF !important; }
  .btn-outline-blue-grey:hover {
    background-color: #5a7582 !important;
    color: #FFF !important; }
    .btn-outline-blue-grey:hover svg {
      color: #FFF !important; }

.btn-outline-blue-grey.btn-raised, .btn-outline-blue-grey.btn-fab {
  border: 1px solid;
  border-color: #607d8b;
  background-color: transparent;
  color: #607d8b;
  box-shadow: none !important; }
  .btn-outline-blue-grey.btn-raised:focus, .btn-outline-blue-grey.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-blue-grey.btn-raised.active, .btn-outline-blue-grey.btn-fab.active {
    background-color: #607d8b !important;
    color: #FFF !important; }
  .btn-outline-blue-grey.btn-raised:hover, .btn-outline-blue-grey.btn-fab:hover {
    background-color: #5a7582 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-blue-grey {
  background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-webkit-progress-value {
    background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-moz-progress-bar {
    background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-ms-fill {
    background-color: #607d8b; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-blue-grey .progress-bar {
      background-color: #607d8b; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607d8b !important; }

.blue-grey.darken-1 {
  color: #546e7a !important; }

.bg-blue-grey.bg-darken-1 {
  background-color: #546e7a !important; }

.btn-blue-grey.btn-darken-1 {
  border-color: #455a64 !important;
  background-color: #546e7a !important; }
  .btn-blue-grey.btn-darken-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-1:focus, .btn-blue-grey.btn-darken-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-1 {
  border-color: #546e7a !important;
  color: #546e7a !important; }
  .btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546e7a !important; }

.progress-blue-grey.progress-darken-1 {
  background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-ms-fill {
    background-color: #546e7a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-1 .progress-bar {
      background-color: #546e7a; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546e7a !important; }

.border-blue-grey.border-darken-1 {
  border: 1px solid #546e7a !important; }

.border-top-blue-grey.border-top-darken-1 {
  border-top: 1px solid #546e7a !important; }

.border-bottom-blue-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #546e7a !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-1 {
  border-left: 1px solid #546e7a !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-1 {
  border-right: 1px solid #546e7a !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-1 {
  border-right: 1px solid #546e7a !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-1 {
  border-left: 1px solid #546e7a !important; }

.overlay-blue-grey.overlay-darken-1 {
  background: #546e7a;
  /* The Fallback */
  background: rgba(84, 110, 122, 0.8); }

.blue-grey.darken-2 {
  color: #455a64 !important; }

.bg-blue-grey.bg-darken-2 {
  background-color: #455a64 !important; }

.btn-blue-grey.btn-darken-2 {
  border-color: #455a64 !important;
  background-color: #455a64 !important; }
  .btn-blue-grey.btn-darken-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-2:focus, .btn-blue-grey.btn-darken-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-2 {
  border-color: #455a64 !important;
  color: #455a64 !important; }
  .btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455a64 !important; }

.progress-blue-grey.progress-darken-2 {
  background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-ms-fill {
    background-color: #455a64; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-2 .progress-bar {
      background-color: #455a64; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455a64 !important; }

.border-blue-grey.border-darken-2 {
  border: 1px solid #455a64 !important; }

.border-top-blue-grey.border-top-darken-2 {
  border-top: 1px solid #455a64 !important; }

.border-bottom-blue-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #455a64 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-2 {
  border-left: 1px solid #455a64 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-2 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-2 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-2 {
  border-left: 1px solid #455a64 !important; }

.overlay-blue-grey.overlay-darken-2 {
  background: #455a64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.blue-grey.darken-3 {
  color: #37474f !important; }

.bg-blue-grey.bg-darken-3 {
  background-color: #37474f !important; }

.btn-blue-grey.btn-darken-3 {
  border-color: #455a64 !important;
  background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-3:focus, .btn-blue-grey.btn-darken-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-3 {
  border-color: #37474f !important;
  color: #37474f !important; }
  .btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474f !important; }

.progress-blue-grey.progress-darken-3 {
  background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-ms-fill {
    background-color: #37474f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-3 .progress-bar {
      background-color: #37474f; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474f !important; }

.border-blue-grey.border-darken-3 {
  border: 1px solid #37474f !important; }

.border-top-blue-grey.border-top-darken-3 {
  border-top: 1px solid #37474f !important; }

.border-bottom-blue-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #37474f !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-3 {
  border-left: 1px solid #37474f !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-3 {
  border-right: 1px solid #37474f !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-3 {
  border-right: 1px solid #37474f !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-3 {
  border-left: 1px solid #37474f !important; }

.overlay-blue-grey.overlay-darken-3 {
  background: #37474f;
  /* The Fallback */
  background: rgba(55, 71, 79, 0.8); }

.blue-grey.darken-4 {
  color: #263238 !important; }

.bg-blue-grey.bg-darken-4 {
  background-color: #263238 !important; }

.btn-blue-grey.btn-darken-4 {
  border-color: #455a64 !important;
  background-color: #263238 !important; }
  .btn-blue-grey.btn-darken-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-4:focus, .btn-blue-grey.btn-darken-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-4 {
  border-color: #263238 !important;
  color: #263238 !important; }
  .btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238 !important; }

.progress-blue-grey.progress-darken-4 {
  background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-ms-fill {
    background-color: #263238; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-4 .progress-bar {
      background-color: #263238; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important; }

.border-blue-grey.border-darken-4 {
  border: 1px solid #263238 !important; }

.border-top-blue-grey.border-top-darken-4 {
  border-top: 1px solid #263238 !important; }

.border-bottom-blue-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #263238 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-4 {
  border-left: 1px solid #263238 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-4 {
  border-right: 1px solid #263238 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-4 {
  border-right: 1px solid #263238 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-4 {
  border-left: 1px solid #263238 !important; }

.overlay-blue-grey.overlay-darken-4 {
  background: #263238;
  /* The Fallback */
  background: rgba(38, 50, 56, 0.8); }

.blue-grey.accent-1 {
  color: #cfd8dc !important; }

.bg-blue-grey.bg-accent-1 {
  background-color: #cfd8dc !important; }

.btn-blue-grey.btn-accent-1 {
  border-color: #455a64 !important;
  background-color: #cfd8dc !important; }
  .btn-blue-grey.btn-accent-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-1:focus, .btn-blue-grey.btn-accent-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-1 {
  border-color: #cfd8dc !important;
  color: #cfd8dc !important; }
  .btn-outline-blue-grey.btn-outline-accent-1:hover {
    background-color: #cfd8dc !important; }

.progress-blue-grey.progress-accent-1 {
  background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-ms-fill {
    background-color: #cfd8dc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-1 .progress-bar {
      background-color: #cfd8dc; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cfd8dc !important; }

.border-blue-grey.border-accent-1 {
  border: 1px solid #cfd8dc !important; }

.border-top-blue-grey.border-top-accent-1 {
  border-top: 1px solid #cfd8dc !important; }

.border-bottom-blue-grey.border-bottom-accent-1 {
  border-bottom: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-1 {
  border-left: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-1 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-1 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-1 {
  border-left: 1px solid #cfd8dc !important; }

.overlay-blue-grey.overlay-accent-1 {
  background: #cfd8dc;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.accent-2 {
  color: #b0bec5 !important; }

.bg-blue-grey.bg-accent-2 {
  background-color: #b0bec5 !important; }

.btn-blue-grey.btn-accent-2 {
  border-color: #455a64 !important;
  background-color: #b0bec5 !important; }
  .btn-blue-grey.btn-accent-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-2:focus, .btn-blue-grey.btn-accent-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-2 {
  border-color: #b0bec5 !important;
  color: #b0bec5 !important; }
  .btn-outline-blue-grey.btn-outline-accent-2:hover {
    background-color: #b0bec5 !important; }

.progress-blue-grey.progress-accent-2 {
  background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-ms-fill {
    background-color: #b0bec5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-2 .progress-bar {
      background-color: #b0bec5; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b0bec5 !important; }

.border-blue-grey.border-accent-2 {
  border: 1px solid #b0bec5 !important; }

.border-top-blue-grey.border-top-accent-2 {
  border-top: 1px solid #b0bec5 !important; }

.border-bottom-blue-grey.border-bottom-accent-2 {
  border-bottom: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-2 {
  border-left: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-2 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-2 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-2 {
  border-left: 1px solid #b0bec5 !important; }

.overlay-blue-grey.overlay-accent-2 {
  background: #b0bec5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.accent-3 {
  color: #78909c !important; }

.bg-blue-grey.bg-accent-3 {
  background-color: #78909c !important; }

.btn-blue-grey.btn-accent-3 {
  border-color: #455a64 !important;
  background-color: #78909c !important; }
  .btn-blue-grey.btn-accent-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-3:focus, .btn-blue-grey.btn-accent-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-3 {
  border-color: #78909c !important;
  color: #78909c !important; }
  .btn-outline-blue-grey.btn-outline-accent-3:hover {
    background-color: #78909c !important; }

.progress-blue-grey.progress-accent-3 {
  background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-ms-fill {
    background-color: #78909c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-3 .progress-bar {
      background-color: #78909c; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909c !important; }

.border-blue-grey.border-accent-3 {
  border: 1px solid #78909c !important; }

.border-top-blue-grey.border-top-accent-3 {
  border-top: 1px solid #78909c !important; }

.border-bottom-blue-grey.border-bottom-accent-3 {
  border-bottom: 1px solid #78909c !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-3 {
  border-left: 1px solid #78909c !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-3 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-3 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-3 {
  border-left: 1px solid #78909c !important; }

.overlay-blue-grey.overlay-accent-3 {
  background: #78909c;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey.accent-4 {
  color: #455a64 !important; }

.bg-blue-grey.bg-accent-4 {
  background-color: #455a64 !important; }

.btn-blue-grey.btn-accent-4 {
  border-color: #455a64 !important;
  background-color: #455a64 !important; }
  .btn-blue-grey.btn-accent-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-4:focus, .btn-blue-grey.btn-accent-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-4 {
  border-color: #455a64 !important;
  color: #455a64 !important; }
  .btn-outline-blue-grey.btn-outline-accent-4:hover {
    background-color: #455a64 !important; }

.progress-blue-grey.progress-accent-4 {
  background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-ms-fill {
    background-color: #455a64; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-4 .progress-bar {
      background-color: #455a64; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455a64 !important; }

.border-blue-grey.border-accent-4 {
  border: 1px solid #455a64 !important; }

.border-top-blue-grey.border-top-accent-4 {
  border-top: 1px solid #455a64 !important; }

.border-bottom-blue-grey.border-bottom-accent-4 {
  border-bottom: 1px solid #455a64 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-4 {
  border-left: 1px solid #455a64 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-4 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-4 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-4 {
  border-left: 1px solid #455a64 !important; }

.overlay-blue-grey.overlay-accent-4 {
  background: #455a64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.shades.black {
  color: #000000 !important; }

.bg-shades.bg-black {
  background-color: #000000 !important; }

.btn-shades.btn-black {
  border-color: !important;
  background-color: #000000 !important; }
  .btn-shades.btn-black:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-black:focus, .btn-shades.btn-black:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-black {
  border-color: #000000 !important;
  color: #000000 !important; }
  .btn-outline-shades.btn-outline-black:hover {
    background-color: #000000 !important; }

.progress-shades.progress-black {
  background-color: #000000; }
  .progress-shades.progress-black[value]::-webkit-progress-value {
    background-color: #000000; }
  .progress-shades.progress-black[value]::-moz-progress-bar {
    background-color: #000000; }
  .progress-shades.progress-black[value]::-ms-fill {
    background-color: #000000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-black .progress-bar {
      background-color: #000000; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-shades.border-black {
  border: 1px solid #000000 !important; }

.border-top-shades.border-top-black {
  border-top: 1px solid #000000 !important; }

.border-bottom-shades.border-bottom-black {
  border-bottom: 1px solid #000000 !important; }

[dir="ltr"] .border-left-shades.border-left-black {
  border-left: 1px solid #000000 !important; }

[dir="ltr"] .border-right-shades.border-right-black {
  border-right: 1px solid #000000 !important; }

[dir="rtl"] .border-left-shades.border-left-black {
  border-right: 1px solid #000000 !important; }

[dir="rtl"] .border-right-shades.border-right-black {
  border-left: 1px solid #000000 !important; }

.overlay-shades.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.shades.white {
  color: #FFFFFF !important; }

.bg-shades.bg-white {
  background-color: #FFFFFF !important; }

.btn-shades.btn-white {
  border-color: !important;
  background-color: #FFFFFF !important; }
  .btn-shades.btn-white:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-white:focus, .btn-shades.btn-white:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-white {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-shades.btn-outline-white:hover {
    background-color: #FFFFFF !important; }

.progress-shades.progress-white {
  background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-white .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-shades.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-shades.border-top-white {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-shades.border-bottom-white {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-shades.border-left-white {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-shades.border-right-white {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-shades.border-left-white {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-shades.border-right-white {
  border-left: 1px solid #FFFFFF !important; }

.overlay-shades.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.shades.transparent {
  color: transparent !important; }

.bg-shades.bg-transparent {
  background-color: transparent !important; }

.btn-shades.btn-transparent {
  border-color: !important;
  background-color: transparent !important; }
  .btn-shades.btn-transparent:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-transparent:focus, .btn-shades.btn-transparent:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-transparent {
  border-color: transparent !important;
  color: transparent !important; }
  .btn-outline-shades.btn-outline-transparent:hover {
    background-color: transparent !important; }

.progress-shades.progress-transparent {
  background-color: transparent; }
  .progress-shades.progress-transparent[value]::-webkit-progress-value {
    background-color: transparent; }
  .progress-shades.progress-transparent[value]::-moz-progress-bar {
    background-color: transparent; }
  .progress-shades.progress-transparent[value]::-ms-fill {
    background-color: transparent; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-transparent .progress-bar {
      background-color: transparent; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important; }

.border-shades.border-transparent {
  border: 1px solid transparent !important; }

.border-top-shades.border-top-transparent {
  border-top: 1px solid transparent !important; }

.border-bottom-shades.border-bottom-transparent {
  border-bottom: 1px solid transparent !important; }

[dir="ltr"] .border-left-shades.border-left-transparent {
  border-left: 1px solid transparent !important; }

[dir="ltr"] .border-right-shades.border-right-transparent {
  border-right: 1px solid transparent !important; }

[dir="rtl"] .border-left-shades.border-left-transparent {
  border-right: 1px solid transparent !important; }

[dir="rtl"] .border-right-shades.border-right-transparent {
  border-left: 1px solid transparent !important; }

.overlay-shades.overlay-transparent {
  background: transparent;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.black {
  color: #000000; }

.bg-black {
  background-color: #000000; }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000; }

.border-black {
  border: 1px solid #000000; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

[dir="ltr"] .border-left-black {
  border-left: 1px solid #000000; }

[dir="ltr"] .border-right-black {
  border-right: 1px solid #000000; }

[dir="rtl"] .border-left-black {
  border-right: 1px solid #000000; }

[dir="rtl"] .border-right-black {
  border-left: 1px solid #000000; }

.white {
  color: #FFFFFF; }

.bg-white {
  background-color: #FFFFFF; }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF; }

.border-white {
  border: 1px solid #FFFFFF; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

[dir="ltr"] .border-left-white {
  border-left: 1px solid #FFFFFF; }

[dir="ltr"] .border-right-white {
  border-right: 1px solid #FFFFFF; }

[dir="rtl"] .border-left-white {
  border-right: 1px solid #FFFFFF; }

[dir="rtl"] .border-right-white {
  border-left: 1px solid #FFFFFF; }

.transparent {
  color: transparent; }

.bg-transparent {
  background-color: transparent; }

input:focus ~ .bg-transparent {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent; }

.border-transparent {
  border: 1px solid transparent; }

.border-top-transparent {
  border-top: 1px solid transparent; }

.border-bottom-transparent {
  border-bottom: 1px solid transparent; }

[dir="ltr"] .border-left-transparent {
  border-left: 1px solid transparent; }

[dir="ltr"] .border-right-transparent {
  border-right: 1px solid transparent; }

[dir="rtl"] .border-left-transparent {
  border-right: 1px solid transparent; }

[dir="rtl"] .border-right-transparent {
  border-left: 1px solid transparent; }

.card {
  font-size: 1rem;
  font-weight: 300; }

/* Shadows (from mdl http://www.getmdl.io/) */
[dir="rtl"] .ct-label.ct-horizontal.ct-end {
  align-items: flex-end;
  justify-content: flex-end; }

.gradient-indigo-light-blue {
  background-image: linear-gradient(45deg, #c5cae9, #b3e5fc);
  background-repeat: repeat-x; }

.btn.gradient-indigo-light-blue:active, .btn.gradient-indigo-light-blue.active {
  background-image: linear-gradient(45deg, #c5cae9, #b3e5fc);
  background-repeat: repeat-x; }

.gradient-light-blue-indigo {
  background-image: linear-gradient(45deg, #b3e5fc, #9fa8da);
  background-repeat: repeat-x; }

.btn.gradient-light-blue-indigo:active, .btn.gradient-light-blue-indigo.active {
  background-image: linear-gradient(45deg, #b3e5fc, #9fa8da);
  background-repeat: repeat-x; }

.gradient-yellow-green {
  background-image: linear-gradient(45deg, #ffff8d, #b9f6ca);
  background-repeat: repeat-x; }

.btn.gradient-yellow-green:active, .btn.gradient-yellow-green.active {
  background-image: linear-gradient(45deg, #ffff8d, #b9f6ca);
  background-repeat: repeat-x; }

.gradient-orange-deep-orange {
  background-image: linear-gradient(45deg, #ffe0b2, #ffccbc);
  background-repeat: repeat-x; }

.btn.gradient-orange-deep-orange:active, .btn.gradient-orange-deep-orange.active {
  background-image: linear-gradient(45deg, #ffe0b2, #ffccbc);
  background-repeat: repeat-x; }

.gradient-deep-purple-purple {
  background-image: linear-gradient(45deg, #d1c4e9, #f3e5f5);
  background-repeat: repeat-x; }

.btn.gradient-deep-purple-purple:active, .btn.gradient-deep-purple-purple.active {
  background-image: linear-gradient(45deg, #d1c4e9, #f3e5f5);
  background-repeat: repeat-x; }

.gradient-red-pink {
  background-image: linear-gradient(45deg, #ff5252, #f48fb1);
  background-repeat: repeat-x; }

.btn.gradient-red-pink:active, .btn.gradient-red-pink.active {
  background-image: linear-gradient(45deg, #ff5252, #f48fb1);
  background-repeat: repeat-x; }

.gradient-light-green-amber {
  background-image: linear-gradient(45deg, #c5e1a5, #fff8e1);
  background-repeat: repeat-x; }

.btn.gradient-light-green-amber:active, .btn.gradient-light-green-amber.active {
  background-image: linear-gradient(45deg, #c5e1a5, #fff8e1);
  background-repeat: repeat-x; }

.gradient-amber-amber {
  background-image: linear-gradient(45deg, #ff6f00, #ffca28);
  background-repeat: repeat-x; }

.btn.gradient-amber-amber:active, .btn.gradient-amber-amber.active {
  background-image: linear-gradient(45deg, #ff6f00, #ffca28);
  background-repeat: repeat-x; }

.gradient-purple-pink {
  background-image: linear-gradient(45deg, #ea80fc, #fce4ec);
  background-repeat: repeat-x; }

.btn.gradient-purple-pink:active, .btn.gradient-purple-pink.active {
  background-image: linear-gradient(45deg, #ea80fc, #fce4ec);
  background-repeat: repeat-x; }

.gradient-indigo-dark-blue {
  background-image: linear-gradient(45deg, #3949ab, #4fc3f7);
  background-repeat: repeat-x; }

.btn.gradient-indigo-dark-blue:active, .btn.gradient-indigo-dark-blue.active {
  background-image: linear-gradient(45deg, #3949ab, #4fc3f7);
  background-repeat: repeat-x; }

.gradient-teal-cyan {
  background-image: linear-gradient(45deg, #e0f2f1, #00e5ff);
  background-repeat: repeat-x; }

.btn.gradient-teal-cyan:active, .btn.gradient-teal-cyan.active {
  background-image: linear-gradient(45deg, #e0f2f1, #00e5ff);
  background-repeat: repeat-x; }

.gradient-blue-grey-blue-grey {
  background-image: linear-gradient(45deg, #cfd8dc, #546e7a);
  background-repeat: repeat-x; }

.btn.gradient-blue-grey-blue-grey:active, .btn.gradient-blue-grey-blue-grey.active {
  background-image: linear-gradient(45deg, #cfd8dc, #546e7a);
  background-repeat: repeat-x; }

.gradient-cyan-dark-green {
  background-image: linear-gradient(45deg, #0097a7, #b2ff59);
  background-repeat: repeat-x; }

.btn.gradient-cyan-dark-green:active, .btn.gradient-cyan-dark-green.active {
  background-image: linear-gradient(45deg, #0097a7, #b2ff59);
  background-repeat: repeat-x; }

.gradient-orange-amber {
  background-image: linear-gradient(45deg, #e65100, #ffa000);
  background-repeat: repeat-x; }

.btn.gradient-orange-amber:active, .btn.gradient-orange-amber.active {
  background-image: linear-gradient(45deg, #e65100, #ffa000);
  background-repeat: repeat-x; }

.gradient-indigo-blue {
  background-image: linear-gradient(45deg, #303f9f, #1976d2);
  background-repeat: repeat-x; }

.btn.gradient-indigo-blue:active, .btn.gradient-indigo-blue.active {
  background-image: linear-gradient(45deg, #303f9f, #1976d2);
  background-repeat: repeat-x; }

.gradient-brown-brown {
  background-image: linear-gradient(45deg, #6d4c41, #d7ccc8);
  background-repeat: repeat-x; }

.btn.gradient-brown-brown:active, .btn.gradient-brown-brown.active {
  background-image: linear-gradient(45deg, #6d4c41, #d7ccc8);
  background-repeat: repeat-x; }

.gradient-blue-grey-blue {
  background-image: linear-gradient(45deg, #263238, #2979ff);
  background-repeat: repeat-x; }

.btn.gradient-blue-grey-blue:active, .btn.gradient-blue-grey-blue.active {
  background-image: linear-gradient(45deg, #263238, #2979ff);
  background-repeat: repeat-x; }

.gradient-purple-deep-orange {
  background-image: linear-gradient(45deg, #8e24aa, #ff6e40);
  background-repeat: repeat-x; }

.btn.gradient-purple-deep-orange:active, .btn.gradient-purple-deep-orange.active {
  background-image: linear-gradient(45deg, #8e24aa, #ff6e40);
  background-repeat: repeat-x; }

.gradient-green-teal {
  background-image: linear-gradient(45deg, #43a047, #1de9b6);
  background-repeat: repeat-x; }

.btn.gradient-green-teal:active, .btn.gradient-green-teal.active {
  background-image: linear-gradient(45deg, #43a047, #1de9b6);
  background-repeat: repeat-x; }

.gradient-purple-light-blue {
  background-image: linear-gradient(45deg, #e040fb, #4fc3f7);
  background-repeat: repeat-x; }

.btn.gradient-purple-light-blue:active, .btn.gradient-purple-light-blue.active {
  background-image: linear-gradient(45deg, #e040fb, #4fc3f7);
  background-repeat: repeat-x; }

.gradient-cyan-cyan {
  background-image: linear-gradient(45deg, #18ffff, #00e5ff);
  background-repeat: repeat-x; }

.btn.gradient-cyan-cyan:active, .btn.gradient-cyan-cyan.active {
  background-image: linear-gradient(45deg, #18ffff, #00e5ff);
  background-repeat: repeat-x; }

.gradient-yellow-teal {
  background-image: linear-gradient(45deg, #fff9c4, #64ffda);
  background-repeat: repeat-x; }

.btn.gradient-yellow-teal:active, .btn.gradient-yellow-teal.active {
  background-image: linear-gradient(45deg, #fff9c4, #64ffda);
  background-repeat: repeat-x; }

.gradient-purple-deep-purple {
  background-image: linear-gradient(45deg, #7b1fa2, #7c4dff);
  background-repeat: repeat-x; }

.btn.gradient-purple-deep-purple:active, .btn.gradient-purple-deep-purple.active {
  background-image: linear-gradient(45deg, #7b1fa2, #7c4dff);
  background-repeat: repeat-x; }

.gradient-cyan-light-green {
  background-image: linear-gradient(45deg, #00e5ff, #ccff90);
  background-repeat: repeat-x; }

.btn.gradient-cyan-light-green:active, .btn.gradient-cyan-light-green.active {
  background-image: linear-gradient(45deg, #00e5ff, #ccff90);
  background-repeat: repeat-x; }

.gradient-purple-amber {
  background-image: linear-gradient(45deg, #d500f9, #ffa000);
  background-repeat: repeat-x; }

.btn.gradient-purple-amber:active, .btn.gradient-purple-amber.active {
  background-image: linear-gradient(45deg, #d500f9, #ffa000);
  background-repeat: repeat-x; }

.gradient-indigo-purple {
  background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
  background-repeat: repeat-x; }

.btn.gradient-indigo-purple:active, .btn.gradient-indigo-purple.active {
  background-image: linear-gradient(45deg, #303f9f, #7b1fa2);
  background-repeat: repeat-x; }

.gradient-deep-purple-blue {
  background-image: linear-gradient(45deg, #6200ea, #1976d2);
  background-repeat: repeat-x; }

.btn.gradient-deep-purple-blue:active, .btn.gradient-deep-purple-blue.active {
  background-image: linear-gradient(45deg, #6200ea, #1976d2);
  background-repeat: repeat-x; }

.gradient-deep-orange-orange {
  background-image: linear-gradient(45deg, #bf360c, #f57c00);
  background-repeat: repeat-x; }

.btn.gradient-deep-orange-orange:active, .btn.gradient-deep-orange-orange.active {
  background-image: linear-gradient(45deg, #bf360c, #f57c00);
  background-repeat: repeat-x; }

.gradient-light-blue-cyan {
  background-image: linear-gradient(45deg, #0288d1, #26c6da);
  background-repeat: repeat-x; }

.btn.gradient-light-blue-cyan:active, .btn.gradient-light-blue-cyan.active {
  background-image: linear-gradient(45deg, #0288d1, #26c6da);
  background-repeat: repeat-x; }

.gradient-blue-indigo {
  background-image: linear-gradient(45deg, #2962ff, #3949ab);
  background-repeat: repeat-x; }

.btn.gradient-blue-indigo:active, .btn.gradient-blue-indigo.active {
  background-image: linear-gradient(45deg, #2962ff, #3949ab);
  background-repeat: repeat-x; }

.gradient-strawberry {
  background-image: linear-gradient(45deg, #FE0B46, #FFAB96);
  background-repeat: repeat-x; }

.btn.gradient-strawberry:active, .btn.gradient-strawberry.active {
  background-image: linear-gradient(45deg, #FE0B46, #FFAB96);
  background-repeat: repeat-x; }

.gradient-blueberry {
  background-image: linear-gradient(45deg, #6078EA, #17EAD9);
  background-repeat: repeat-x; }

.btn.gradient-blueberry:active, .btn.gradient-blueberry.active {
  background-image: linear-gradient(45deg, #6078EA, #17EAD9);
  background-repeat: repeat-x; }

.gradient-mint {
  background-image: linear-gradient(45deg, #23BCBB, #45E994);
  background-repeat: repeat-x; }

.btn.gradient-mint:active, .btn.gradient-mint.active {
  background-image: linear-gradient(45deg, #23BCBB, #45E994);
  background-repeat: repeat-x; }

.gradient-orange {
  background-image: linear-gradient(45deg, #C471F3, #F671CD);
  background-repeat: repeat-x; }

.btn.gradient-orange:active, .btn.gradient-orange.active {
  background-image: linear-gradient(45deg, #C471F3, #F671CD);
  background-repeat: repeat-x; }

.gradient-pomegranate {
  background-image: linear-gradient(45deg, #9B3cb7, #FF396f);
  background-repeat: repeat-x; }

.btn.gradient-pomegranate:active, .btn.gradient-pomegranate.active {
  background-image: linear-gradient(45deg, #9B3cb7, #FF396f);
  background-repeat: repeat-x; }

.gradient-green-tea {
  background-image: linear-gradient(45deg, #004b91, #78cc37);
  background-repeat: repeat-x; }

.btn.gradient-green-tea:active, .btn.gradient-green-tea.active {
  background-image: linear-gradient(45deg, #004b91, #78cc37);
  background-repeat: repeat-x; }

.gradient-blackberry {
  background-image: linear-gradient(45deg, #843cf7, #38b8f2);
  background-repeat: repeat-x; }

.btn.gradient-blackberry:active, .btn.gradient-blackberry.active {
  background-image: linear-gradient(45deg, #843cf7, #38b8f2);
  background-repeat: repeat-x; }

.gradient-plum {
  background-image: linear-gradient(45deg, #e35c67, #381ce2);
  background-repeat: repeat-x; }

.btn.gradient-plum:active, .btn.gradient-plum.active {
  background-image: linear-gradient(45deg, #e35c67, #381ce2);
  background-repeat: repeat-x; }

.gradient-passion-fruit {
  background-image: linear-gradient(45deg, #8137F7, #F6AB3E);
  background-repeat: repeat-x; }

.btn.gradient-passion-fruit:active, .btn.gradient-passion-fruit.active {
  background-image: linear-gradient(45deg, #8137F7, #F6AB3E);
  background-repeat: repeat-x; }

.gradient-sublime-vivid {
  background-image: linear-gradient(45deg, #FC466B, #3F5EFB);
  background-repeat: repeat-x; }

.btn.gradient-sublime-vivid:active, .btn.gradient-sublime-vivid.active {
  background-image: linear-gradient(45deg, #FC466B, #3F5EFB);
  background-repeat: repeat-x; }

.gradient-king-yna {
  background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
  background-repeat: repeat-x; }

.btn.gradient-king-yna:active, .btn.gradient-king-yna.active {
  background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
  background-repeat: repeat-x; }

.gradient-summer {
  background-image: linear-gradient(45deg, #22c1c3, #fdbb2d);
  background-repeat: repeat-x; }

.btn.gradient-summer:active, .btn.gradient-summer.active {
  background-image: linear-gradient(45deg, #22c1c3, #fdbb2d);
  background-repeat: repeat-x; }

.gradient-crystal-clear {
  background-image: linear-gradient(45deg, #159957, #155799);
  background-repeat: repeat-x; }

.btn.gradient-crystal-clear:active, .btn.gradient-crystal-clear.active {
  background-image: linear-gradient(45deg, #159957, #155799);
  background-repeat: repeat-x; }

.gradient-celestial {
  background-image: linear-gradient(45deg, #C33764, #1D2671);
  background-repeat: repeat-x; }

.btn.gradient-celestial:active, .btn.gradient-celestial.active {
  background-image: linear-gradient(45deg, #C33764, #1D2671);
  background-repeat: repeat-x; }

.gradient-mini {
  background-image: linear-gradient(45deg, #30E8BF, #FF8235);
  background-repeat: repeat-x; }

.btn.gradient-mini:active, .btn.gradient-mini.active {
  background-image: linear-gradient(45deg, #30E8BF, #FF8235);
  background-repeat: repeat-x; }

.gradient-ibiza-sunset {
  background-image: linear-gradient(45deg, #ee0979, #ff6a00);
  background-repeat: repeat-x; }

.btn.gradient-ibiza-sunset:active, .btn.gradient-ibiza-sunset.active {
  background-image: linear-gradient(45deg, #ee0979, #ff6a00);
  background-repeat: repeat-x; }

.gradient-dawn {
  background-image: linear-gradient(45deg, #F3904F, #3B4371);
  background-repeat: repeat-x; }

.btn.gradient-dawn:active, .btn.gradient-dawn.active {
  background-image: linear-gradient(45deg, #F3904F, #3B4371);
  background-repeat: repeat-x; }

.gradient-brady-brady-fun-fun {
  background-image: linear-gradient(45deg, #00c3ff, #ffff1c);
  background-repeat: repeat-x; }

.btn.gradient-brady-brady-fun-fun:active, .btn.gradient-brady-brady-fun-fun.active {
  background-image: linear-gradient(45deg, #00c3ff, #ffff1c);
  background-repeat: repeat-x; }

.gradient-cosmic-fusion {
  background-image: linear-gradient(45deg, #ff00cc, #333399);
  background-repeat: repeat-x; }

.btn.gradient-cosmic-fusion:active, .btn.gradient-cosmic-fusion.active {
  background-image: linear-gradient(45deg, #ff00cc, #333399);
  background-repeat: repeat-x; }

.gradient-nepal {
  background-image: linear-gradient(45deg, #de6161, #2657eb);
  background-repeat: repeat-x; }

.btn.gradient-nepal:active, .btn.gradient-nepal.active {
  background-image: linear-gradient(45deg, #de6161, #2657eb);
  background-repeat: repeat-x; }

.gradient-love-couple {
  background-image: linear-gradient(45deg, #3a6186, #89253e);
  background-repeat: repeat-x; }

.btn.gradient-love-couple:active, .btn.gradient-love-couple.active {
  background-image: linear-gradient(45deg, #3a6186, #89253e);
  background-repeat: repeat-x; }

.gradient-sunset {
  background-image: linear-gradient(45deg, #0B486B, #F56217);
  background-repeat: repeat-x; }

.btn.gradient-sunset:active, .btn.gradient-sunset.active {
  background-image: linear-gradient(45deg, #0B486B, #F56217);
  background-repeat: repeat-x; }

.gradient-grapefruit-sunset {
  background-image: linear-gradient(45deg, #e96443, #904e95);
  background-repeat: repeat-x; }

.btn.gradient-grapefruit-sunset:active, .btn.gradient-grapefruit-sunset.active {
  background-image: linear-gradient(45deg, #e96443, #904e95);
  background-repeat: repeat-x; }

.gradient-politics {
  background-image: linear-gradient(45deg, #2196f3, #f44336);
  background-repeat: repeat-x; }

.btn.gradient-politics:active, .btn.gradient-politics.active {
  background-image: linear-gradient(45deg, #2196f3, #f44336);
  background-repeat: repeat-x; }

.gradient-red-ocean {
  background-image: linear-gradient(45deg, #1D4350, #A43931);
  background-repeat: repeat-x; }

.btn.gradient-red-ocean:active, .btn.gradient-red-ocean.active {
  background-image: linear-gradient(45deg, #1D4350, #A43931);
  background-repeat: repeat-x; }

.gradient-back-to-earth {
  background-image: linear-gradient(45deg, #20CAE4, #1CE5B7);
  background-repeat: repeat-x; }

.btn.gradient-back-to-earth:active, .btn.gradient-back-to-earth.active {
  background-image: linear-gradient(45deg, #20CAE4, #1CE5B7);
  background-repeat: repeat-x; }

.gradient-flickr {
  background-image: linear-gradient(45deg, #33001b, #ff0084);
  background-repeat: repeat-x; }

.btn.gradient-flickr:active, .btn.gradient-flickr.active {
  background-image: linear-gradient(45deg, #33001b, #ff0084);
  background-repeat: repeat-x; }

.gradient-virgin-america {
  background-image: linear-gradient(45deg, #7b4397, #dc2430);
  background-repeat: repeat-x; }

.btn.gradient-virgin-america:active, .btn.gradient-virgin-america.active {
  background-image: linear-gradient(45deg, #7b4397, #dc2430);
  background-repeat: repeat-x; }

.gradient-purple-bliss {
  background-image: linear-gradient(45deg, #360033, #0b8793);
  background-repeat: repeat-x; }

.btn.gradient-purple-bliss:active, .btn.gradient-purple-bliss.active {
  background-image: linear-gradient(45deg, #360033, #0b8793);
  background-repeat: repeat-x; }

.gradient-man-of-steel {
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x; }

.btn.gradient-man-of-steel:active, .btn.gradient-man-of-steel.active {
  background-image: linear-gradient(45deg, #780206, #061161);
  background-repeat: repeat-x; }

.gradient-shdow-night {
  background-image: linear-gradient(45deg, #000000, #53346D);
  background-repeat: repeat-x; }

.btn.gradient-shdow-night:active, .btn.gradient-shdow-night.active {
  background-image: linear-gradient(45deg, #000000, #53346D);
  background-repeat: repeat-x; }

.gradient-dracula {
  background-image: linear-gradient(45deg, #DC2424, #4A569D);
  background-repeat: repeat-x; }

.btn.gradient-dracula:active, .btn.gradient-dracula.active {
  background-image: linear-gradient(45deg, #DC2424, #4A569D);
  background-repeat: repeat-x; }

.gradient-bloody-mary {
  background-image: linear-gradient(45deg, #FF512F, #DD2476);
  background-repeat: repeat-x; }

.btn.gradient-bloody-mary:active, .btn.gradient-bloody-mary.active {
  background-image: linear-gradient(45deg, #FF512F, #DD2476);
  background-repeat: repeat-x; }

.gradient-harmonic-energy {
  background-image: linear-gradient(45deg, #16A085, #F4D03F);
  background-repeat: repeat-x; }

.btn.gradient-harmonic-energy:active, .btn.gradient-harmonic-energy.active {
  background-image: linear-gradient(45deg, #16A085, #F4D03F);
  background-repeat: repeat-x; }

.gradient-purple-love {
  background-image: linear-gradient(45deg, #cc2b5e, #753a88);
  background-repeat: repeat-x; }

.btn.gradient-purple-love:active, .btn.gradient-purple-love.active {
  background-image: linear-gradient(45deg, #cc2b5e, #753a88);
  background-repeat: repeat-x; }

.white {
  color: #FFFFFF !important; }

.bg-white {
  background-color: #FFFFFF !important; }
  .bg-white .card-header, .bg-white .card-footer {
    background-color: transparent; }

.toast-white {
  background-color: #FFFFFF; }

.alert-white {
  border-color: #FFFFFF !important;
  background-color: white !important;
  color: #a6a6a6 !important; }
  .alert-white .alert-link {
    color: #919191 !important; }

.border-white {
  border-color: #FFFFFF; }

.border-top-white {
  border-top-color: #FFFFFF; }

.border-bottom-white {
  border-bottom-color: #FFFFFF; }

[dir="ltr"] .border-left-white {
  border-left-color: #FFFFFF; }

[dir="ltr"] .border-right-white {
  border-right-color: #FFFFFF; }

[dir="rtl"] .border-left-white {
  border-right-color: #FFFFFF; }

[dir="rtl"] .border-right-white {
  border-left-color: #FFFFFF; }

.badge-white {
  background-color: #FFFFFF; }

.panel-white {
  border-color: #FFFFFF; }
  .panel-white .panel-heading {
    color: #FFF;
    border-color: #FFFFFF;
    background-color: white; }

.bg-white.tag-glow, .border-white.tag-glow {
  box-shadow: 0px 0px 10px #FFFFFF; }

.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.card.card-outline-white {
  border-width: 1px;
  border-style: solid;
  border-color: #FFFFFF;
  background-color: transparent; }
  .card.card-outline-white .card-header, .card.card-outline-white .card-footer {
    background-color: transparent; }

.btn-white.btn-flat {
  background-color: transparent !important;
  color: #FFFFFF;
  border: none; }

.btn-white.btn-raised, .btn-white.btn-fab {
  background-color: #FFFFFF !important;
  color: #fff !important;
  border-color: #FFFFFF; }
  .btn-white.btn-raised.active, .btn-white.btn-fab.active {
    background-color: #ededed !important;
    border-color: #ededed !important; }

.btn-group-raised .btn-white {
  background-color: #FFFFFF !important;
  color: #fff !important; }

.btn-outline-white {
  border: 1px solid;
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
  box-shadow: none !important; }
  .btn-outline-white:focus {
    background-color: transparent !important;
    color: #FFFFFF !important;
    box-shadow: transparent !important; }
  .btn-outline-white.active {
    background-color: #FFFFFF !important;
    color: #FFF !important; }
  .btn-outline-white:hover {
    background-color: #f7f7f7 !important;
    color: #FFF !important; }
    .btn-outline-white:hover svg {
      color: #FFF !important; }

.btn-outline-white.btn-raised, .btn-outline-white.btn-fab {
  border: 1px solid;
  border-color: #FFFFFF;
  background-color: transparent;
  color: #FFFFFF;
  box-shadow: none !important; }
  .btn-outline-white.btn-raised:focus, .btn-outline-white.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-white.btn-raised.active, .btn-outline-white.btn-fab.active {
    background-color: #FFFFFF !important;
    color: #FFF !important; }
  .btn-outline-white.btn-raised:hover, .btn-outline-white.btn-fab:hover {
    background-color: #f7f7f7 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-white {
  background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress .progress-bar.progress-bar-white[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-white .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.black {
  color: #000000 !important; }

.bg-black {
  background-color: #000000 !important; }
  .bg-black .card-header, .bg-black .card-footer {
    background-color: transparent; }

.toast-black {
  background-color: #000000; }

.alert-black {
  border-color: #000000 !important;
  background-color: #121212 !important;
  color: black !important; }
  .alert-black .alert-link {
    color: black !important; }

.border-black {
  border-color: #000000; }

.border-top-black {
  border-top-color: #000000; }

.border-bottom-black {
  border-bottom-color: #000000; }

[dir="ltr"] .border-left-black {
  border-left-color: #000000; }

[dir="ltr"] .border-right-black {
  border-right-color: #000000; }

[dir="rtl"] .border-left-black {
  border-right-color: #000000; }

[dir="rtl"] .border-right-black {
  border-left-color: #000000; }

.badge-black {
  background-color: #000000; }

.panel-black {
  border-color: #000000; }
  .panel-black .panel-heading {
    color: #FFF;
    border-color: #000000;
    background-color: #0d0d0d; }

.bg-black.tag-glow, .border-black.tag-glow {
  box-shadow: 0px 0px 10px #000000; }

.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.card.card-outline-black {
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  background-color: transparent; }
  .card.card-outline-black .card-header, .card.card-outline-black .card-footer {
    background-color: transparent; }

.btn-black.btn-flat {
  background-color: transparent !important;
  color: #000000;
  border: none; }

.btn-black.btn-raised, .btn-black.btn-fab {
  background-color: #000000 !important;
  color: #fff !important;
  border-color: #000000; }
  .btn-black.btn-raised.active, .btn-black.btn-fab.active {
    background-color: black !important;
    border-color: black !important; }

.btn-group-raised .btn-black {
  background-color: #000000 !important;
  color: #fff !important; }

.btn-outline-black {
  border: 1px solid;
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  box-shadow: none !important; }
  .btn-outline-black:focus {
    background-color: transparent !important;
    color: #000000 !important;
    box-shadow: transparent !important; }
  .btn-outline-black.active {
    background-color: #000000 !important;
    color: #FFF !important; }
  .btn-outline-black:hover {
    background-color: black !important;
    color: #FFF !important; }
    .btn-outline-black:hover svg {
      color: #FFF !important; }

.btn-outline-black.btn-raised, .btn-outline-black.btn-fab {
  border: 1px solid;
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  box-shadow: none !important; }
  .btn-outline-black.btn-raised:focus, .btn-outline-black.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-black.btn-raised.active, .btn-outline-black.btn-fab.active {
    background-color: #000000 !important;
    color: #FFF !important; }
  .btn-outline-black.btn-raised:hover, .btn-outline-black.btn-fab:hover {
    background-color: black !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-black {
  background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-webkit-progress-value {
    background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-moz-progress-bar {
    background-color: #000000; }
  .progress .progress-bar.progress-bar-black[value]::-ms-fill {
    background-color: #000000; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-black .progress-bar {
      background-color: #000000; } }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.primary.lighten-5 {
  color: #E0F3F4 !important; }

.bg-primary.bg-lighten-5 {
  background-color: #E0F3F4 !important; }

.btn-primary.btn-lighten-5 {
  border-color: #008B8E !important;
  background-color: #E0F3F4 !important; }
  .btn-primary.btn-lighten-5:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-5:focus, .btn-primary.btn-lighten-5:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-5 {
  border-color: #E0F3F4 !important;
  color: #E0F3F4 !important; }
  .btn-outline-primary.btn-outline-lighten-5:hover {
    background-color: #E0F3F4 !important; }

.progress-primary.progress-lighten-5 {
  background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E0F3F4; }
  .progress-primary.progress-lighten-5[value]::-ms-fill {
    background-color: #E0F3F4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-5 .progress-bar {
      background-color: #E0F3F4; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E0F3F4 !important; }

.border-primary.border-lighten-5 {
  border: 1px solid #E0F3F4 !important; }

.border-top-primary.border-top-lighten-5 {
  border-top: 1px solid #E0F3F4 !important; }

.border-bottom-primary.border-bottom-lighten-5 {
  border-bottom: 1px solid #E0F3F4 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-5 {
  border-left: 1px solid #E0F3F4 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-5 {
  border-right: 1px solid #E0F3F4 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-5 {
  border-right: 1px solid #E0F3F4 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-5 {
  border-left: 1px solid #E0F3F4 !important; }

.overlay-primary.overlay-lighten-5 {
  background: #E0F3F4;
  /* The Fallback */
  background: rgba(224, 243, 244, 0.8); }

.primary.lighten-4 {
  color: #B3E2E3 !important; }

.bg-primary.bg-lighten-4 {
  background-color: #B3E2E3 !important; }

.btn-primary.btn-lighten-4 {
  border-color: #008B8E !important;
  background-color: #B3E2E3 !important; }
  .btn-primary.btn-lighten-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-4:focus, .btn-primary.btn-lighten-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-4 {
  border-color: #B3E2E3 !important;
  color: #B3E2E3 !important; }
  .btn-outline-primary.btn-outline-lighten-4:hover {
    background-color: #B3E2E3 !important; }

.progress-primary.progress-lighten-4 {
  background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #B3E2E3; }
  .progress-primary.progress-lighten-4[value]::-ms-fill {
    background-color: #B3E2E3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-4 .progress-bar {
      background-color: #B3E2E3; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B3E2E3 !important; }

.border-primary.border-lighten-4 {
  border: 1px solid #B3E2E3 !important; }

.border-top-primary.border-top-lighten-4 {
  border-top: 1px solid #B3E2E3 !important; }

.border-bottom-primary.border-bottom-lighten-4 {
  border-bottom: 1px solid #B3E2E3 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-4 {
  border-left: 1px solid #B3E2E3 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-4 {
  border-right: 1px solid #B3E2E3 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-4 {
  border-right: 1px solid #B3E2E3 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-4 {
  border-left: 1px solid #B3E2E3 !important; }

.overlay-primary.overlay-lighten-4 {
  background: #B3E2E3;
  /* The Fallback */
  background: rgba(179, 226, 227, 0.8); }

.primary.lighten-3 {
  color: #80CED0 !important; }

.bg-primary.bg-lighten-3 {
  background-color: #80CED0 !important; }

.btn-primary.btn-lighten-3 {
  border-color: #008B8E !important;
  background-color: #80CED0 !important; }
  .btn-primary.btn-lighten-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-3:focus, .btn-primary.btn-lighten-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-3 {
  border-color: #80CED0 !important;
  color: #80CED0 !important; }
  .btn-outline-primary.btn-outline-lighten-3:hover {
    background-color: #80CED0 !important; }

.progress-primary.progress-lighten-3 {
  background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80CED0; }
  .progress-primary.progress-lighten-3[value]::-ms-fill {
    background-color: #80CED0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-3 .progress-bar {
      background-color: #80CED0; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80CED0 !important; }

.border-primary.border-lighten-3 {
  border: 1px solid #80CED0 !important; }

.border-top-primary.border-top-lighten-3 {
  border-top: 1px solid #80CED0 !important; }

.border-bottom-primary.border-bottom-lighten-3 {
  border-bottom: 1px solid #80CED0 !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-3 {
  border-left: 1px solid #80CED0 !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-3 {
  border-right: 1px solid #80CED0 !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-3 {
  border-right: 1px solid #80CED0 !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-3 {
  border-left: 1px solid #80CED0 !important; }

.overlay-primary.overlay-lighten-3 {
  background: #80CED0;
  /* The Fallback */
  background: rgba(128, 206, 208, 0.8); }

.primary.lighten-2 {
  color: #4DBABD !important; }

.bg-primary.bg-lighten-2 {
  background-color: #4DBABD !important; }

.btn-primary.btn-lighten-2 {
  border-color: #008B8E !important;
  background-color: #4DBABD !important; }
  .btn-primary.btn-lighten-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-2:focus, .btn-primary.btn-lighten-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-2 {
  border-color: #4DBABD !important;
  color: #4DBABD !important; }
  .btn-outline-primary.btn-outline-lighten-2:hover {
    background-color: #4DBABD !important; }

.progress-primary.progress-lighten-2 {
  background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4DBABD; }
  .progress-primary.progress-lighten-2[value]::-ms-fill {
    background-color: #4DBABD; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-2 .progress-bar {
      background-color: #4DBABD; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4DBABD !important; }

.border-primary.border-lighten-2 {
  border: 1px solid #4DBABD !important; }

.border-top-primary.border-top-lighten-2 {
  border-top: 1px solid #4DBABD !important; }

.border-bottom-primary.border-bottom-lighten-2 {
  border-bottom: 1px solid #4DBABD !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-2 {
  border-left: 1px solid #4DBABD !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-2 {
  border-right: 1px solid #4DBABD !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-2 {
  border-right: 1px solid #4DBABD !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-2 {
  border-left: 1px solid #4DBABD !important; }

.overlay-primary.overlay-lighten-2 {
  background: #4DBABD;
  /* The Fallback */
  background: rgba(77, 186, 189, 0.8); }

.primary.lighten-1 {
  color: #26ACAE !important; }

.bg-primary.bg-lighten-1 {
  background-color: #26ACAE !important; }

.btn-primary.btn-lighten-1 {
  border-color: #008B8E !important;
  background-color: #26ACAE !important; }
  .btn-primary.btn-lighten-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-lighten-1:focus, .btn-primary.btn-lighten-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-lighten-1 {
  border-color: #26ACAE !important;
  color: #26ACAE !important; }
  .btn-outline-primary.btn-outline-lighten-1:hover {
    background-color: #26ACAE !important; }

.progress-primary.progress-lighten-1 {
  background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26ACAE; }
  .progress-primary.progress-lighten-1[value]::-ms-fill {
    background-color: #26ACAE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-lighten-1 .progress-bar {
      background-color: #26ACAE; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26ACAE !important; }

.border-primary.border-lighten-1 {
  border: 1px solid #26ACAE !important; }

.border-top-primary.border-top-lighten-1 {
  border-top: 1px solid #26ACAE !important; }

.border-bottom-primary.border-bottom-lighten-1 {
  border-bottom: 1px solid #26ACAE !important; }

[dir="ltr"] .border-left-primary.border-left-lighten-1 {
  border-left: 1px solid #26ACAE !important; }

[dir="ltr"] .border-right-primary.border-right-lighten-1 {
  border-right: 1px solid #26ACAE !important; }

[dir="rtl"] .border-left-primary.border-left-lighten-1 {
  border-right: 1px solid #26ACAE !important; }

[dir="rtl"] .border-right-primary.border-right-lighten-1 {
  border-left: 1px solid #26ACAE !important; }

.overlay-primary.overlay-lighten-1 {
  background: #26ACAE;
  /* The Fallback */
  background: rgba(38, 172, 174, 0.8); }

.primary {
  color: #047bf8 !important; }

.bg-primary {
  background-color: #047bf8 !important; }
  .bg-primary .card-header, .bg-primary .card-footer {
    background-color: transparent; }

.toast-primary {
  background-color: #047bf8; }

.alert-primary {
  border-color: #047bf8 !important;
  background-color: #248dfb !important;
  color: #012448 !important; }
  .alert-primary .alert-link {
    color: #011020 !important; }

.border-primary {
  border-color: #047bf8; }

.border-top-primary {
  border-top-color: #047bf8; }

.border-bottom-primary {
  border-bottom-color: #047bf8; }

[dir="ltr"] .border-left-primary {
  border-left-color: #047bf8; }

[dir="ltr"] .border-right-primary {
  border-right-color: #047bf8; }

[dir="rtl"] .border-left-primary {
  border-right-color: #047bf8; }

[dir="rtl"] .border-right-primary {
  border-left-color: #047bf8; }

.badge-primary {
  background-color: #047bf8; }

.panel-primary {
  border-color: #047bf8; }
  .panel-primary .panel-heading {
    color: #FFF;
    border-color: #047bf8;
    background-color: #1a88fb; }

.bg-primary.tag-glow, .border-primary.tag-glow {
  box-shadow: 0px 0px 10px #047bf8; }

.overlay-primary {
  background: #047bf8;
  /* The Fallback */
  background: rgba(4, 123, 248, 0.8); }

.card.card-outline-primary {
  border-width: 1px;
  border-style: solid;
  border-color: #047bf8;
  background-color: transparent; }
  .card.card-outline-primary .card-header, .card.card-outline-primary .card-footer {
    background-color: transparent; }

.btn-primary.btn-flat {
  background-color: transparent !important;
  color: #047bf8;
  border: none; }

.btn-primary.btn-raised, .btn-primary.btn-fab {
  background-color: #047bf8 !important;
  color: #fff !important;
  border-color: #047bf8; }
  .btn-primary.btn-raised.active, .btn-primary.btn-fab.active {
    background-color: #036ad5 !important;
    border-color: #036ad5 !important; }

.btn-group-raised .btn-primary {
  background-color: #047bf8 !important;
  color: #fff !important; }

.btn-outline-primary {
  border: 1px solid;
  border-color: #047bf8;
  background-color: transparent;
  color: #047bf8;
  box-shadow: none !important; }
  .btn-outline-primary:focus {
    background-color: transparent !important;
    color: #047bf8 !important;
    box-shadow: transparent !important; }
  .btn-outline-primary.active {
    background-color: #047bf8 !important;
    color: #FFF !important; }
  .btn-outline-primary:hover {
    background-color: #0474e9 !important;
    color: #FFF !important; }
    .btn-outline-primary:hover svg {
      color: #FFF !important; }

.btn-outline-primary.btn-raised, .btn-outline-primary.btn-fab {
  border: 1px solid;
  border-color: #047bf8;
  background-color: transparent;
  color: #047bf8;
  box-shadow: none !important; }
  .btn-outline-primary.btn-raised:focus, .btn-outline-primary.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-primary.btn-raised.active, .btn-outline-primary.btn-fab.active {
    background-color: #047bf8 !important;
    color: #FFF !important; }
  .btn-outline-primary.btn-raised:hover, .btn-outline-primary.btn-fab:hover {
    background-color: #0474e9 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-primary {
  background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-webkit-progress-value {
    background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-moz-progress-bar {
    background-color: #047bf8; }
  .progress .progress-bar.progress-bar-primary[value]::-ms-fill {
    background-color: #047bf8; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-primary .progress-bar {
      background-color: #047bf8; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #047bf8 !important; }

.primary.darken-1 {
  color: #009598 !important; }

.bg-primary.bg-darken-1 {
  background-color: #009598 !important; }

.btn-primary.btn-darken-1 {
  border-color: #008B8E !important;
  background-color: #009598 !important; }
  .btn-primary.btn-darken-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-1:focus, .btn-primary.btn-darken-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-1 {
  border-color: #009598 !important;
  color: #009598 !important; }
  .btn-outline-primary.btn-outline-darken-1:hover {
    background-color: #009598 !important; }

.progress-primary.progress-darken-1 {
  background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-webkit-progress-value {
    background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-moz-progress-bar {
    background-color: #009598; }
  .progress-primary.progress-darken-1[value]::-ms-fill {
    background-color: #009598; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-1 .progress-bar {
      background-color: #009598; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009598 !important; }

.border-primary.border-darken-1 {
  border: 1px solid #009598 !important; }

.border-top-primary.border-top-darken-1 {
  border-top: 1px solid #009598 !important; }

.border-bottom-primary.border-bottom-darken-1 {
  border-bottom: 1px solid #009598 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-1 {
  border-left: 1px solid #009598 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-1 {
  border-right: 1px solid #009598 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-1 {
  border-right: 1px solid #009598 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-1 {
  border-left: 1px solid #009598 !important; }

.overlay-primary.overlay-darken-1 {
  background: #009598;
  /* The Fallback */
  background: rgba(0, 149, 152, 0.8); }

.primary.darken-2 {
  color: #008B8E !important; }

.bg-primary.bg-darken-2 {
  background-color: #008B8E !important; }

.btn-primary.btn-darken-2 {
  border-color: #008B8E !important;
  background-color: #008B8E !important; }
  .btn-primary.btn-darken-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-2:focus, .btn-primary.btn-darken-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-2 {
  border-color: #008B8E !important;
  color: #008B8E !important; }
  .btn-outline-primary.btn-outline-darken-2:hover {
    background-color: #008B8E !important; }

.progress-primary.progress-darken-2 {
  background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-webkit-progress-value {
    background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-moz-progress-bar {
    background-color: #008B8E; }
  .progress-primary.progress-darken-2[value]::-ms-fill {
    background-color: #008B8E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-2 .progress-bar {
      background-color: #008B8E; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008B8E !important; }

.border-primary.border-darken-2 {
  border: 1px solid #008B8E !important; }

.border-top-primary.border-top-darken-2 {
  border-top: 1px solid #008B8E !important; }

.border-bottom-primary.border-bottom-darken-2 {
  border-bottom: 1px solid #008B8E !important; }

[dir="ltr"] .border-left-primary.border-left-darken-2 {
  border-left: 1px solid #008B8E !important; }

[dir="ltr"] .border-right-primary.border-right-darken-2 {
  border-right: 1px solid #008B8E !important; }

[dir="rtl"] .border-left-primary.border-left-darken-2 {
  border-right: 1px solid #008B8E !important; }

[dir="rtl"] .border-right-primary.border-right-darken-2 {
  border-left: 1px solid #008B8E !important; }

.overlay-primary.overlay-darken-2 {
  background: #008B8E;
  /* The Fallback */
  background: rgba(0, 139, 142, 0.8); }

.primary.darken-3 {
  color: #008184 !important; }

.bg-primary.bg-darken-3 {
  background-color: #008184 !important; }

.btn-primary.btn-darken-3 {
  border-color: #008B8E !important;
  background-color: #008184 !important; }
  .btn-primary.btn-darken-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-3:focus, .btn-primary.btn-darken-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-3 {
  border-color: #008184 !important;
  color: #008184 !important; }
  .btn-outline-primary.btn-outline-darken-3:hover {
    background-color: #008184 !important; }

.progress-primary.progress-darken-3 {
  background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-webkit-progress-value {
    background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-moz-progress-bar {
    background-color: #008184; }
  .progress-primary.progress-darken-3[value]::-ms-fill {
    background-color: #008184; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-3 .progress-bar {
      background-color: #008184; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #008184 !important; }

.border-primary.border-darken-3 {
  border: 1px solid #008184 !important; }

.border-top-primary.border-top-darken-3 {
  border-top: 1px solid #008184 !important; }

.border-bottom-primary.border-bottom-darken-3 {
  border-bottom: 1px solid #008184 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-3 {
  border-left: 1px solid #008184 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-3 {
  border-right: 1px solid #008184 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-3 {
  border-right: 1px solid #008184 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-3 {
  border-left: 1px solid #008184 !important; }

.overlay-primary.overlay-darken-3 {
  background: #008184;
  /* The Fallback */
  background: rgba(0, 129, 132, 0.8); }

.primary.darken-4 {
  color: #006F73 !important; }

.bg-primary.bg-darken-4 {
  background-color: #006F73 !important; }

.btn-primary.btn-darken-4 {
  border-color: #008B8E !important;
  background-color: #006F73 !important; }
  .btn-primary.btn-darken-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-darken-4:focus, .btn-primary.btn-darken-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-darken-4 {
  border-color: #006F73 !important;
  color: #006F73 !important; }
  .btn-outline-primary.btn-outline-darken-4:hover {
    background-color: #006F73 !important; }

.progress-primary.progress-darken-4 {
  background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006F73; }
  .progress-primary.progress-darken-4[value]::-ms-fill {
    background-color: #006F73; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-darken-4 .progress-bar {
      background-color: #006F73; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006F73 !important; }

.border-primary.border-darken-4 {
  border: 1px solid #006F73 !important; }

.border-top-primary.border-top-darken-4 {
  border-top: 1px solid #006F73 !important; }

.border-bottom-primary.border-bottom-darken-4 {
  border-bottom: 1px solid #006F73 !important; }

[dir="ltr"] .border-left-primary.border-left-darken-4 {
  border-left: 1px solid #006F73 !important; }

[dir="ltr"] .border-right-primary.border-right-darken-4 {
  border-right: 1px solid #006F73 !important; }

[dir="rtl"] .border-left-primary.border-left-darken-4 {
  border-right: 1px solid #006F73 !important; }

[dir="rtl"] .border-right-primary.border-right-darken-4 {
  border-left: 1px solid #006F73 !important; }

.overlay-primary.overlay-darken-4 {
  background: #006F73;
  /* The Fallback */
  background: rgba(0, 111, 115, 0.8); }

.primary.accent-1 {
  color: #A1FBFF !important; }

.bg-primary.bg-accent-1 {
  background-color: #A1FBFF !important; }

.btn-primary.btn-accent-1 {
  border-color: #008B8E !important;
  background-color: #A1FBFF !important; }
  .btn-primary.btn-accent-1:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-1:focus, .btn-primary.btn-accent-1:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-1 {
  border-color: #A1FBFF !important;
  color: #A1FBFF !important; }
  .btn-outline-primary.btn-outline-accent-1:hover {
    background-color: #A1FBFF !important; }

.progress-primary.progress-accent-1 {
  background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-webkit-progress-value {
    background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-moz-progress-bar {
    background-color: #A1FBFF; }
  .progress-primary.progress-accent-1[value]::-ms-fill {
    background-color: #A1FBFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-1 .progress-bar {
      background-color: #A1FBFF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #A1FBFF !important; }

.border-primary.border-accent-1 {
  border: 1px solid #A1FBFF !important; }

.border-top-primary.border-top-accent-1 {
  border-top: 1px solid #A1FBFF !important; }

.border-bottom-primary.border-bottom-accent-1 {
  border-bottom: 1px solid #A1FBFF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-1 {
  border-left: 1px solid #A1FBFF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-1 {
  border-right: 1px solid #A1FBFF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-1 {
  border-right: 1px solid #A1FBFF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-1 {
  border-left: 1px solid #A1FBFF !important; }

.overlay-primary.overlay-accent-1 {
  background: #A1FBFF;
  /* The Fallback */
  background: rgba(161, 251, 255, 0.8); }

.primary.accent-2 {
  color: #6EF9FF !important; }

.bg-primary.bg-accent-2 {
  background-color: #6EF9FF !important; }

.btn-primary.btn-accent-2 {
  border-color: #008B8E !important;
  background-color: #6EF9FF !important; }
  .btn-primary.btn-accent-2:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-2:focus, .btn-primary.btn-accent-2:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-2 {
  border-color: #6EF9FF !important;
  color: #6EF9FF !important; }
  .btn-outline-primary.btn-outline-accent-2:hover {
    background-color: #6EF9FF !important; }

.progress-primary.progress-accent-2 {
  background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-webkit-progress-value {
    background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-moz-progress-bar {
    background-color: #6EF9FF; }
  .progress-primary.progress-accent-2[value]::-ms-fill {
    background-color: #6EF9FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-2 .progress-bar {
      background-color: #6EF9FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6EF9FF !important; }

.border-primary.border-accent-2 {
  border: 1px solid #6EF9FF !important; }

.border-top-primary.border-top-accent-2 {
  border-top: 1px solid #6EF9FF !important; }

.border-bottom-primary.border-bottom-accent-2 {
  border-bottom: 1px solid #6EF9FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-2 {
  border-left: 1px solid #6EF9FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-2 {
  border-right: 1px solid #6EF9FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-2 {
  border-right: 1px solid #6EF9FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-2 {
  border-left: 1px solid #6EF9FF !important; }

.overlay-primary.overlay-accent-2 {
  background: #6EF9FF;
  /* The Fallback */
  background: rgba(110, 249, 255, 0.8); }

.primary.accent-3 {
  color: #3BF7FF !important; }

.bg-primary.bg-accent-3 {
  background-color: #3BF7FF !important; }

.btn-primary.btn-accent-3 {
  border-color: #008B8E !important;
  background-color: #3BF7FF !important; }
  .btn-primary.btn-accent-3:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-3:focus, .btn-primary.btn-accent-3:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-3 {
  border-color: #3BF7FF !important;
  color: #3BF7FF !important; }
  .btn-outline-primary.btn-outline-accent-3:hover {
    background-color: #3BF7FF !important; }

.progress-primary.progress-accent-3 {
  background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3BF7FF; }
  .progress-primary.progress-accent-3[value]::-ms-fill {
    background-color: #3BF7FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-3 .progress-bar {
      background-color: #3BF7FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3BF7FF !important; }

.border-primary.border-accent-3 {
  border: 1px solid #3BF7FF !important; }

.border-top-primary.border-top-accent-3 {
  border-top: 1px solid #3BF7FF !important; }

.border-bottom-primary.border-bottom-accent-3 {
  border-bottom: 1px solid #3BF7FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-3 {
  border-left: 1px solid #3BF7FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-3 {
  border-right: 1px solid #3BF7FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-3 {
  border-right: 1px solid #3BF7FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-3 {
  border-left: 1px solid #3BF7FF !important; }

.overlay-primary.overlay-accent-3 {
  background: #3BF7FF;
  /* The Fallback */
  background: rgba(59, 247, 255, 0.8); }

.primary.accent-4 {
  color: #22F6FF !important; }

.bg-primary.bg-accent-4 {
  background-color: #22F6FF !important; }

.btn-primary.btn-accent-4 {
  border-color: #008B8E !important;
  background-color: #22F6FF !important; }
  .btn-primary.btn-accent-4:hover {
    border-color: #008B8E !important;
    background-color: #008184 !important; }
  .btn-primary.btn-accent-4:focus, .btn-primary.btn-accent-4:active {
    border-color: #008184 !important;
    background-color: #006F73 !important; }

.btn-outline-primary.btn-outline-accent-4 {
  border-color: #22F6FF !important;
  color: #22F6FF !important; }
  .btn-outline-primary.btn-outline-accent-4:hover {
    background-color: #22F6FF !important; }

.progress-primary.progress-accent-4 {
  background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-webkit-progress-value {
    background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-moz-progress-bar {
    background-color: #22F6FF; }
  .progress-primary.progress-accent-4[value]::-ms-fill {
    background-color: #22F6FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-primary.progress-accent-4 .progress-bar {
      background-color: #22F6FF; } }

input:focus ~ .bg-primary {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #22F6FF !important; }

.border-primary.border-accent-4 {
  border: 1px solid #22F6FF !important; }

.border-top-primary.border-top-accent-4 {
  border-top: 1px solid #22F6FF !important; }

.border-bottom-primary.border-bottom-accent-4 {
  border-bottom: 1px solid #22F6FF !important; }

[dir="ltr"] .border-left-primary.border-left-accent-4 {
  border-left: 1px solid #22F6FF !important; }

[dir="ltr"] .border-right-primary.border-right-accent-4 {
  border-right: 1px solid #22F6FF !important; }

[dir="rtl"] .border-left-primary.border-left-accent-4 {
  border-right: 1px solid #22F6FF !important; }

[dir="rtl"] .border-right-primary.border-right-accent-4 {
  border-left: 1px solid #22F6FF !important; }

.overlay-primary.overlay-accent-4 {
  background: #22F6FF;
  /* The Fallback */
  background: rgba(34, 246, 255, 0.8); }

.success.lighten-5 {
  color: #E2F8F0 !important; }

.bg-success.bg-lighten-5 {
  background-color: #E2F8F0 !important; }

.btn-success.btn-lighten-5 {
  border-color: #08B46B !important;
  background-color: #E2F8F0 !important; }
  .btn-success.btn-lighten-5:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-5:focus, .btn-success.btn-lighten-5:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-5 {
  border-color: #E2F8F0 !important;
  color: #E2F8F0 !important; }
  .btn-outline-success.btn-outline-lighten-5:hover {
    background-color: #E2F8F0 !important; }

.progress-success.progress-lighten-5 {
  background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E2F8F0; }
  .progress-success.progress-lighten-5[value]::-ms-fill {
    background-color: #E2F8F0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-5 .progress-bar {
      background-color: #E2F8F0; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E2F8F0 !important; }

.border-success.border-lighten-5 {
  border: 1px solid #E2F8F0 !important; }

.border-top-success.border-top-lighten-5 {
  border-top: 1px solid #E2F8F0 !important; }

.border-bottom-success.border-bottom-lighten-5 {
  border-bottom: 1px solid #E2F8F0 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-5 {
  border-left: 1px solid #E2F8F0 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-5 {
  border-right: 1px solid #E2F8F0 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-5 {
  border-right: 1px solid #E2F8F0 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-5 {
  border-left: 1px solid #E2F8F0 !important; }

.overlay-success.overlay-lighten-5 {
  background: #E2F8F0;
  /* The Fallback */
  background: rgba(226, 248, 240, 0.8); }

.success.lighten-4 {
  color: #B6EDD8 !important; }

.bg-success.bg-lighten-4 {
  background-color: #B6EDD8 !important; }

.btn-success.btn-lighten-4 {
  border-color: #08B46B !important;
  background-color: #B6EDD8 !important; }
  .btn-success.btn-lighten-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-4:focus, .btn-success.btn-lighten-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-4 {
  border-color: #B6EDD8 !important;
  color: #B6EDD8 !important; }
  .btn-outline-success.btn-outline-lighten-4:hover {
    background-color: #B6EDD8 !important; }

.progress-success.progress-lighten-4 {
  background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #B6EDD8; }
  .progress-success.progress-lighten-4[value]::-ms-fill {
    background-color: #B6EDD8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-4 .progress-bar {
      background-color: #B6EDD8; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B6EDD8 !important; }

.border-success.border-lighten-4 {
  border: 1px solid #B6EDD8 !important; }

.border-top-success.border-top-lighten-4 {
  border-top: 1px solid #B6EDD8 !important; }

.border-bottom-success.border-bottom-lighten-4 {
  border-bottom: 1px solid #B6EDD8 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-4 {
  border-left: 1px solid #B6EDD8 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-4 {
  border-right: 1px solid #B6EDD8 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-4 {
  border-right: 1px solid #B6EDD8 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-4 {
  border-left: 1px solid #B6EDD8 !important; }

.overlay-success.overlay-lighten-4 {
  background: #B6EDD8;
  /* The Fallback */
  background: rgba(182, 237, 216, 0.8); }

.success.lighten-3 {
  color: #86E1BF !important; }

.bg-success.bg-lighten-3 {
  background-color: #86E1BF !important; }

.btn-success.btn-lighten-3 {
  border-color: #08B46B !important;
  background-color: #86E1BF !important; }
  .btn-success.btn-lighten-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-3:focus, .btn-success.btn-lighten-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-3 {
  border-color: #86E1BF !important;
  color: #86E1BF !important; }
  .btn-outline-success.btn-outline-lighten-3:hover {
    background-color: #86E1BF !important; }

.progress-success.progress-lighten-3 {
  background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #86E1BF; }
  .progress-success.progress-lighten-3[value]::-ms-fill {
    background-color: #86E1BF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-3 .progress-bar {
      background-color: #86E1BF; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #86E1BF !important; }

.border-success.border-lighten-3 {
  border: 1px solid #86E1BF !important; }

.border-top-success.border-top-lighten-3 {
  border-top: 1px solid #86E1BF !important; }

.border-bottom-success.border-bottom-lighten-3 {
  border-bottom: 1px solid #86E1BF !important; }

[dir="ltr"] .border-left-success.border-left-lighten-3 {
  border-left: 1px solid #86E1BF !important; }

[dir="ltr"] .border-right-success.border-right-lighten-3 {
  border-right: 1px solid #86E1BF !important; }

[dir="rtl"] .border-left-success.border-left-lighten-3 {
  border-right: 1px solid #86E1BF !important; }

[dir="rtl"] .border-right-success.border-right-lighten-3 {
  border-left: 1px solid #86E1BF !important; }

.overlay-success.overlay-lighten-3 {
  background: #86E1BF;
  /* The Fallback */
  background: rgba(134, 225, 191, 0.8); }

.success.lighten-2 {
  color: #55D4A5 !important; }

.bg-success.bg-lighten-2 {
  background-color: #55D4A5 !important; }

.btn-success.btn-lighten-2 {
  border-color: #08B46B !important;
  background-color: #55D4A5 !important; }
  .btn-success.btn-lighten-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-2:focus, .btn-success.btn-lighten-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-2 {
  border-color: #55D4A5 !important;
  color: #55D4A5 !important; }
  .btn-outline-success.btn-outline-lighten-2:hover {
    background-color: #55D4A5 !important; }

.progress-success.progress-lighten-2 {
  background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #55D4A5; }
  .progress-success.progress-lighten-2[value]::-ms-fill {
    background-color: #55D4A5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-2 .progress-bar {
      background-color: #55D4A5; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #55D4A5 !important; }

.border-success.border-lighten-2 {
  border: 1px solid #55D4A5 !important; }

.border-top-success.border-top-lighten-2 {
  border-top: 1px solid #55D4A5 !important; }

.border-bottom-success.border-bottom-lighten-2 {
  border-bottom: 1px solid #55D4A5 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-2 {
  border-left: 1px solid #55D4A5 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-2 {
  border-right: 1px solid #55D4A5 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-2 {
  border-right: 1px solid #55D4A5 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-2 {
  border-left: 1px solid #55D4A5 !important; }

.overlay-success.overlay-lighten-2 {
  background: #55D4A5;
  /* The Fallback */
  background: rgba(85, 212, 165, 0.8); }

.success.lighten-1 {
  color: #30CB91 !important; }

.bg-success.bg-lighten-1 {
  background-color: #30CB91 !important; }

.btn-success.btn-lighten-1 {
  border-color: #08B46B !important;
  background-color: #30CB91 !important; }
  .btn-success.btn-lighten-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-lighten-1:focus, .btn-success.btn-lighten-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-lighten-1 {
  border-color: #30CB91 !important;
  color: #30CB91 !important; }
  .btn-outline-success.btn-outline-lighten-1:hover {
    background-color: #30CB91 !important; }

.progress-success.progress-lighten-1 {
  background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #30CB91; }
  .progress-success.progress-lighten-1[value]::-ms-fill {
    background-color: #30CB91; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-lighten-1 .progress-bar {
      background-color: #30CB91; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #30CB91 !important; }

.border-success.border-lighten-1 {
  border: 1px solid #30CB91 !important; }

.border-top-success.border-top-lighten-1 {
  border-top: 1px solid #30CB91 !important; }

.border-bottom-success.border-bottom-lighten-1 {
  border-bottom: 1px solid #30CB91 !important; }

[dir="ltr"] .border-left-success.border-left-lighten-1 {
  border-left: 1px solid #30CB91 !important; }

[dir="ltr"] .border-right-success.border-right-lighten-1 {
  border-right: 1px solid #30CB91 !important; }

[dir="rtl"] .border-left-success.border-left-lighten-1 {
  border-right: 1px solid #30CB91 !important; }

[dir="rtl"] .border-right-success.border-right-lighten-1 {
  border-left: 1px solid #30CB91 !important; }

.overlay-success.overlay-lighten-1 {
  background: #30CB91;
  /* The Fallback */
  background: rgba(48, 203, 145, 0.8); }

.success {
  color: #0CC27E !important; }

.bg-success {
  background-color: #0CC27E !important; }
  .bg-success .card-header, .bg-success .card-footer {
    background-color: transparent; }

.toast-success {
  background-color: #0CC27E; }

.alert-success {
  border-color: #0CC27E !important;
  background-color: #0ee494 !important;
  color: #021a11 !important; }
  .alert-success .alert-link {
    color: black !important; }

.border-success {
  border-color: #0CC27E; }

.border-top-success {
  border-top-color: #0CC27E; }

.border-bottom-success {
  border-bottom-color: #0CC27E; }

[dir="ltr"] .border-left-success {
  border-left-color: #0CC27E; }

[dir="ltr"] .border-right-success {
  border-right-color: #0CC27E; }

[dir="rtl"] .border-left-success {
  border-right-color: #0CC27E; }

[dir="rtl"] .border-right-success {
  border-left-color: #0CC27E; }

.badge-success {
  background-color: #0CC27E; }

.panel-success {
  border-color: #0CC27E; }
  .panel-success .panel-heading {
    color: #FFF;
    border-color: #0CC27E;
    background-color: #0dda8e; }

.bg-success.tag-glow, .border-success.tag-glow {
  box-shadow: 0px 0px 10px #0CC27E; }

.overlay-success {
  background: #0CC27E;
  /* The Fallback */
  background: rgba(12, 194, 126, 0.8); }

.card.card-outline-success {
  border-width: 1px;
  border-style: solid;
  border-color: #0CC27E;
  background-color: transparent; }
  .card.card-outline-success .card-header, .card.card-outline-success .card-footer {
    background-color: transparent; }

.btn-success.btn-flat {
  background-color: transparent !important;
  color: #0CC27E;
  border: none; }

.btn-success.btn-raised, .btn-success.btn-fab {
  background-color: #0CC27E !important;
  color: #fff !important;
  border-color: #0CC27E; }
  .btn-success.btn-raised.active, .btn-success.btn-fab.active {
    background-color: #0aa068 !important;
    border-color: #0aa068 !important; }

.btn-group-raised .btn-success {
  background-color: #0CC27E !important;
  color: #fff !important; }

.btn-outline-success {
  border: 1px solid;
  border-color: #0CC27E;
  background-color: transparent;
  color: #0CC27E;
  box-shadow: none !important; }
  .btn-outline-success:focus {
    background-color: transparent !important;
    color: #0CC27E !important;
    box-shadow: transparent !important; }
  .btn-outline-success.active {
    background-color: #0CC27E !important;
    color: #FFF !important; }
  .btn-outline-success:hover {
    background-color: #0bb475 !important;
    color: #FFF !important; }
    .btn-outline-success:hover svg {
      color: #FFF !important; }

.btn-outline-success.btn-raised, .btn-outline-success.btn-fab {
  border: 1px solid;
  border-color: #0CC27E;
  background-color: transparent;
  color: #0CC27E;
  box-shadow: none !important; }
  .btn-outline-success.btn-raised:focus, .btn-outline-success.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-success.btn-raised.active, .btn-outline-success.btn-fab.active {
    background-color: #0CC27E !important;
    color: #FFF !important; }
  .btn-outline-success.btn-raised:hover, .btn-outline-success.btn-fab:hover {
    background-color: #0bb475 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-success {
  background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-webkit-progress-value {
    background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-moz-progress-bar {
    background-color: #0CC27E; }
  .progress .progress-bar.progress-bar-success[value]::-ms-fill {
    background-color: #0CC27E; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-success .progress-bar {
      background-color: #0CC27E; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0CC27E !important; }

.success.darken-1 {
  color: #0ABC76 !important; }

.bg-success.bg-darken-1 {
  background-color: #0ABC76 !important; }

.btn-success.btn-darken-1 {
  border-color: #08B46B !important;
  background-color: #0ABC76 !important; }
  .btn-success.btn-darken-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-1:focus, .btn-success.btn-darken-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-1 {
  border-color: #0ABC76 !important;
  color: #0ABC76 !important; }
  .btn-outline-success.btn-outline-darken-1:hover {
    background-color: #0ABC76 !important; }

.progress-success.progress-darken-1 {
  background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-webkit-progress-value {
    background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-moz-progress-bar {
    background-color: #0ABC76; }
  .progress-success.progress-darken-1[value]::-ms-fill {
    background-color: #0ABC76; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-1 .progress-bar {
      background-color: #0ABC76; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0ABC76 !important; }

.border-success.border-darken-1 {
  border: 1px solid #0ABC76 !important; }

.border-top-success.border-top-darken-1 {
  border-top: 1px solid #0ABC76 !important; }

.border-bottom-success.border-bottom-darken-1 {
  border-bottom: 1px solid #0ABC76 !important; }

[dir="ltr"] .border-left-success.border-left-darken-1 {
  border-left: 1px solid #0ABC76 !important; }

[dir="ltr"] .border-right-success.border-right-darken-1 {
  border-right: 1px solid #0ABC76 !important; }

[dir="rtl"] .border-left-success.border-left-darken-1 {
  border-right: 1px solid #0ABC76 !important; }

[dir="rtl"] .border-right-success.border-right-darken-1 {
  border-left: 1px solid #0ABC76 !important; }

.overlay-success.overlay-darken-1 {
  background: #0ABC76;
  /* The Fallback */
  background: rgba(10, 188, 118, 0.8); }

.success.darken-2 {
  color: #08B46B !important; }

.bg-success.bg-darken-2 {
  background-color: #08B46B !important; }

.btn-success.btn-darken-2 {
  border-color: #08B46B !important;
  background-color: #08B46B !important; }
  .btn-success.btn-darken-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-2:focus, .btn-success.btn-darken-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-2 {
  border-color: #08B46B !important;
  color: #08B46B !important; }
  .btn-outline-success.btn-outline-darken-2:hover {
    background-color: #08B46B !important; }

.progress-success.progress-darken-2 {
  background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-webkit-progress-value {
    background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-moz-progress-bar {
    background-color: #08B46B; }
  .progress-success.progress-darken-2[value]::-ms-fill {
    background-color: #08B46B; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-2 .progress-bar {
      background-color: #08B46B; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #08B46B !important; }

.border-success.border-darken-2 {
  border: 1px solid #08B46B !important; }

.border-top-success.border-top-darken-2 {
  border-top: 1px solid #08B46B !important; }

.border-bottom-success.border-bottom-darken-2 {
  border-bottom: 1px solid #08B46B !important; }

[dir="ltr"] .border-left-success.border-left-darken-2 {
  border-left: 1px solid #08B46B !important; }

[dir="ltr"] .border-right-success.border-right-darken-2 {
  border-right: 1px solid #08B46B !important; }

[dir="rtl"] .border-left-success.border-left-darken-2 {
  border-right: 1px solid #08B46B !important; }

[dir="rtl"] .border-right-success.border-right-darken-2 {
  border-left: 1px solid #08B46B !important; }

.overlay-success.overlay-darken-2 {
  background: #08B46B;
  /* The Fallback */
  background: rgba(8, 180, 107, 0.8); }

.success.darken-3 {
  color: #06AC61 !important; }

.bg-success.bg-darken-3 {
  background-color: #06AC61 !important; }

.btn-success.btn-darken-3 {
  border-color: #08B46B !important;
  background-color: #06AC61 !important; }
  .btn-success.btn-darken-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-3:focus, .btn-success.btn-darken-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-3 {
  border-color: #06AC61 !important;
  color: #06AC61 !important; }
  .btn-outline-success.btn-outline-darken-3:hover {
    background-color: #06AC61 !important; }

.progress-success.progress-darken-3 {
  background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-webkit-progress-value {
    background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-moz-progress-bar {
    background-color: #06AC61; }
  .progress-success.progress-darken-3[value]::-ms-fill {
    background-color: #06AC61; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-3 .progress-bar {
      background-color: #06AC61; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #06AC61 !important; }

.border-success.border-darken-3 {
  border: 1px solid #06AC61 !important; }

.border-top-success.border-top-darken-3 {
  border-top: 1px solid #06AC61 !important; }

.border-bottom-success.border-bottom-darken-3 {
  border-bottom: 1px solid #06AC61 !important; }

[dir="ltr"] .border-left-success.border-left-darken-3 {
  border-left: 1px solid #06AC61 !important; }

[dir="ltr"] .border-right-success.border-right-darken-3 {
  border-right: 1px solid #06AC61 !important; }

[dir="rtl"] .border-left-success.border-left-darken-3 {
  border-right: 1px solid #06AC61 !important; }

[dir="rtl"] .border-right-success.border-right-darken-3 {
  border-left: 1px solid #06AC61 !important; }

.overlay-success.overlay-darken-3 {
  background: #06AC61;
  /* The Fallback */
  background: rgba(6, 172, 97, 0.8); }

.success.darken-4 {
  color: #039F4E !important; }

.bg-success.bg-darken-4 {
  background-color: #039F4E !important; }

.btn-success.btn-darken-4 {
  border-color: #08B46B !important;
  background-color: #039F4E !important; }
  .btn-success.btn-darken-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-darken-4:focus, .btn-success.btn-darken-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-darken-4 {
  border-color: #039F4E !important;
  color: #039F4E !important; }
  .btn-outline-success.btn-outline-darken-4:hover {
    background-color: #039F4E !important; }

.progress-success.progress-darken-4 {
  background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-webkit-progress-value {
    background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-moz-progress-bar {
    background-color: #039F4E; }
  .progress-success.progress-darken-4[value]::-ms-fill {
    background-color: #039F4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-darken-4 .progress-bar {
      background-color: #039F4E; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039F4E !important; }

.border-success.border-darken-4 {
  border: 1px solid #039F4E !important; }

.border-top-success.border-top-darken-4 {
  border-top: 1px solid #039F4E !important; }

.border-bottom-success.border-bottom-darken-4 {
  border-bottom: 1px solid #039F4E !important; }

[dir="ltr"] .border-left-success.border-left-darken-4 {
  border-left: 1px solid #039F4E !important; }

[dir="ltr"] .border-right-success.border-right-darken-4 {
  border-right: 1px solid #039F4E !important; }

[dir="rtl"] .border-left-success.border-left-darken-4 {
  border-right: 1px solid #039F4E !important; }

[dir="rtl"] .border-right-success.border-right-darken-4 {
  border-left: 1px solid #039F4E !important; }

.overlay-success.overlay-darken-4 {
  background: #039F4E;
  /* The Fallback */
  background: rgba(3, 159, 78, 0.8); }

.success.accent-1 {
  color: #C9FFE0 !important; }

.bg-success.bg-accent-1 {
  background-color: #C9FFE0 !important; }

.btn-success.btn-accent-1 {
  border-color: #08B46B !important;
  background-color: #C9FFE0 !important; }
  .btn-success.btn-accent-1:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-1:focus, .btn-success.btn-accent-1:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-1 {
  border-color: #C9FFE0 !important;
  color: #C9FFE0 !important; }
  .btn-outline-success.btn-outline-accent-1:hover {
    background-color: #C9FFE0 !important; }

.progress-success.progress-accent-1 {
  background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-webkit-progress-value {
    background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-moz-progress-bar {
    background-color: #C9FFE0; }
  .progress-success.progress-accent-1[value]::-ms-fill {
    background-color: #C9FFE0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-1 .progress-bar {
      background-color: #C9FFE0; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #C9FFE0 !important; }

.border-success.border-accent-1 {
  border: 1px solid #C9FFE0 !important; }

.border-top-success.border-top-accent-1 {
  border-top: 1px solid #C9FFE0 !important; }

.border-bottom-success.border-bottom-accent-1 {
  border-bottom: 1px solid #C9FFE0 !important; }

[dir="ltr"] .border-left-success.border-left-accent-1 {
  border-left: 1px solid #C9FFE0 !important; }

[dir="ltr"] .border-right-success.border-right-accent-1 {
  border-right: 1px solid #C9FFE0 !important; }

[dir="rtl"] .border-left-success.border-left-accent-1 {
  border-right: 1px solid #C9FFE0 !important; }

[dir="rtl"] .border-right-success.border-right-accent-1 {
  border-left: 1px solid #C9FFE0 !important; }

.overlay-success.overlay-accent-1 {
  background: #C9FFE0;
  /* The Fallback */
  background: rgba(201, 255, 224, 0.8); }

.success.accent-2 {
  color: #96FFC3 !important; }

.bg-success.bg-accent-2 {
  background-color: #96FFC3 !important; }

.btn-success.btn-accent-2 {
  border-color: #08B46B !important;
  background-color: #96FFC3 !important; }
  .btn-success.btn-accent-2:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-2:focus, .btn-success.btn-accent-2:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-2 {
  border-color: #96FFC3 !important;
  color: #96FFC3 !important; }
  .btn-outline-success.btn-outline-accent-2:hover {
    background-color: #96FFC3 !important; }

.progress-success.progress-accent-2 {
  background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-webkit-progress-value {
    background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-moz-progress-bar {
    background-color: #96FFC3; }
  .progress-success.progress-accent-2[value]::-ms-fill {
    background-color: #96FFC3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-2 .progress-bar {
      background-color: #96FFC3; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #96FFC3 !important; }

.border-success.border-accent-2 {
  border: 1px solid #96FFC3 !important; }

.border-top-success.border-top-accent-2 {
  border-top: 1px solid #96FFC3 !important; }

.border-bottom-success.border-bottom-accent-2 {
  border-bottom: 1px solid #96FFC3 !important; }

[dir="ltr"] .border-left-success.border-left-accent-2 {
  border-left: 1px solid #96FFC3 !important; }

[dir="ltr"] .border-right-success.border-right-accent-2 {
  border-right: 1px solid #96FFC3 !important; }

[dir="rtl"] .border-left-success.border-left-accent-2 {
  border-right: 1px solid #96FFC3 !important; }

[dir="rtl"] .border-right-success.border-right-accent-2 {
  border-left: 1px solid #96FFC3 !important; }

.overlay-success.overlay-accent-2 {
  background: #96FFC3;
  /* The Fallback */
  background: rgba(150, 255, 195, 0.8); }

.success.accent-3 {
  color: #63FFA5 !important; }

.bg-success.bg-accent-3 {
  background-color: #63FFA5 !important; }

.btn-success.btn-accent-3 {
  border-color: #08B46B !important;
  background-color: #63FFA5 !important; }
  .btn-success.btn-accent-3:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-3:focus, .btn-success.btn-accent-3:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-3 {
  border-color: #63FFA5 !important;
  color: #63FFA5 !important; }
  .btn-outline-success.btn-outline-accent-3:hover {
    background-color: #63FFA5 !important; }

.progress-success.progress-accent-3 {
  background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-webkit-progress-value {
    background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-moz-progress-bar {
    background-color: #63FFA5; }
  .progress-success.progress-accent-3[value]::-ms-fill {
    background-color: #63FFA5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-3 .progress-bar {
      background-color: #63FFA5; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #63FFA5 !important; }

.border-success.border-accent-3 {
  border: 1px solid #63FFA5 !important; }

.border-top-success.border-top-accent-3 {
  border-top: 1px solid #63FFA5 !important; }

.border-bottom-success.border-bottom-accent-3 {
  border-bottom: 1px solid #63FFA5 !important; }

[dir="ltr"] .border-left-success.border-left-accent-3 {
  border-left: 1px solid #63FFA5 !important; }

[dir="ltr"] .border-right-success.border-right-accent-3 {
  border-right: 1px solid #63FFA5 !important; }

[dir="rtl"] .border-left-success.border-left-accent-3 {
  border-right: 1px solid #63FFA5 !important; }

[dir="rtl"] .border-right-success.border-right-accent-3 {
  border-left: 1px solid #63FFA5 !important; }

.overlay-success.overlay-accent-3 {
  background: #63FFA5;
  /* The Fallback */
  background: rgba(99, 255, 165, 0.8); }

.success.accent-4 {
  color: #4AFF96 !important; }

.bg-success.bg-accent-4 {
  background-color: #4AFF96 !important; }

.btn-success.btn-accent-4 {
  border-color: #08B46B !important;
  background-color: #4AFF96 !important; }
  .btn-success.btn-accent-4:hover {
    border-color: #08B46B !important;
    background-color: #06AC61 !important; }
  .btn-success.btn-accent-4:focus, .btn-success.btn-accent-4:active {
    border-color: #06AC61 !important;
    background-color: #039F4E !important; }

.btn-outline-success.btn-outline-accent-4 {
  border-color: #4AFF96 !important;
  color: #4AFF96 !important; }
  .btn-outline-success.btn-outline-accent-4:hover {
    background-color: #4AFF96 !important; }

.progress-success.progress-accent-4 {
  background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-webkit-progress-value {
    background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-moz-progress-bar {
    background-color: #4AFF96; }
  .progress-success.progress-accent-4[value]::-ms-fill {
    background-color: #4AFF96; }
  @media screen and (min-width: 0 \0 ) {
    .progress-success.progress-accent-4 .progress-bar {
      background-color: #4AFF96; } }

input:focus ~ .bg-success {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4AFF96 !important; }

.border-success.border-accent-4 {
  border: 1px solid #4AFF96 !important; }

.border-top-success.border-top-accent-4 {
  border-top: 1px solid #4AFF96 !important; }

.border-bottom-success.border-bottom-accent-4 {
  border-bottom: 1px solid #4AFF96 !important; }

[dir="ltr"] .border-left-success.border-left-accent-4 {
  border-left: 1px solid #4AFF96 !important; }

[dir="ltr"] .border-right-success.border-right-accent-4 {
  border-right: 1px solid #4AFF96 !important; }

[dir="rtl"] .border-left-success.border-left-accent-4 {
  border-right: 1px solid #4AFF96 !important; }

[dir="rtl"] .border-right-success.border-right-accent-4 {
  border-left: 1px solid #4AFF96 !important; }

.overlay-success.overlay-accent-4 {
  background: #4AFF96;
  /* The Fallback */
  background: rgba(74, 255, 150, 0.8); }

.info.lighten-5 {
  color: #E4F7FA !important; }

.bg-info.bg-lighten-5 {
  background-color: #E4F7FA !important; }

.btn-info.btn-lighten-5 {
  border-color: #14ADCE !important;
  background-color: #E4F7FA !important; }
  .btn-info.btn-lighten-5:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-5:focus, .btn-info.btn-lighten-5:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-5 {
  border-color: #E4F7FA !important;
  color: #E4F7FA !important; }
  .btn-outline-info.btn-outline-lighten-5:hover {
    background-color: #E4F7FA !important; }

.progress-info.progress-lighten-5 {
  background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #E4F7FA; }
  .progress-info.progress-lighten-5[value]::-ms-fill {
    background-color: #E4F7FA; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-5 .progress-bar {
      background-color: #E4F7FA; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #E4F7FA !important; }

.border-info.border-lighten-5 {
  border: 1px solid #E4F7FA !important; }

.border-top-info.border-top-lighten-5 {
  border-top: 1px solid #E4F7FA !important; }

.border-bottom-info.border-bottom-lighten-5 {
  border-bottom: 1px solid #E4F7FA !important; }

[dir="ltr"] .border-left-info.border-left-lighten-5 {
  border-left: 1px solid #E4F7FA !important; }

[dir="ltr"] .border-right-info.border-right-lighten-5 {
  border-right: 1px solid #E4F7FA !important; }

[dir="rtl"] .border-left-info.border-left-lighten-5 {
  border-right: 1px solid #E4F7FA !important; }

[dir="rtl"] .border-right-info.border-right-lighten-5 {
  border-left: 1px solid #E4F7FA !important; }

.overlay-info.overlay-lighten-5 {
  background: #E4F7FA;
  /* The Fallback */
  background: rgba(228, 247, 250, 0.8); }

.info.lighten-4 {
  color: #BBEBF3 !important; }

.bg-info.bg-lighten-4 {
  background-color: #BBEBF3 !important; }

.btn-info.btn-lighten-4 {
  border-color: #14ADCE !important;
  background-color: #BBEBF3 !important; }
  .btn-info.btn-lighten-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-4:focus, .btn-info.btn-lighten-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-4 {
  border-color: #BBEBF3 !important;
  color: #BBEBF3 !important; }
  .btn-outline-info.btn-outline-lighten-4:hover {
    background-color: #BBEBF3 !important; }

.progress-info.progress-lighten-4 {
  background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #BBEBF3; }
  .progress-info.progress-lighten-4[value]::-ms-fill {
    background-color: #BBEBF3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-4 .progress-bar {
      background-color: #BBEBF3; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #BBEBF3 !important; }

.border-info.border-lighten-4 {
  border: 1px solid #BBEBF3 !important; }

.border-top-info.border-top-lighten-4 {
  border-top: 1px solid #BBEBF3 !important; }

.border-bottom-info.border-bottom-lighten-4 {
  border-bottom: 1px solid #BBEBF3 !important; }

[dir="ltr"] .border-left-info.border-left-lighten-4 {
  border-left: 1px solid #BBEBF3 !important; }

[dir="ltr"] .border-right-info.border-right-lighten-4 {
  border-right: 1px solid #BBEBF3 !important; }

[dir="rtl"] .border-left-info.border-left-lighten-4 {
  border-right: 1px solid #BBEBF3 !important; }

[dir="rtl"] .border-right-info.border-right-lighten-4 {
  border-left: 1px solid #BBEBF3 !important; }

.overlay-info.overlay-lighten-4 {
  background: #BBEBF3;
  /* The Fallback */
  background: rgba(187, 235, 243, 0.8); }

.info.lighten-3 {
  color: #8EDEEC !important; }

.bg-info.bg-lighten-3 {
  background-color: #8EDEEC !important; }

.btn-info.btn-lighten-3 {
  border-color: #14ADCE !important;
  background-color: #8EDEEC !important; }
  .btn-info.btn-lighten-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-3:focus, .btn-info.btn-lighten-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-3 {
  border-color: #8EDEEC !important;
  color: #8EDEEC !important; }
  .btn-outline-info.btn-outline-lighten-3:hover {
    background-color: #8EDEEC !important; }

.progress-info.progress-lighten-3 {
  background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #8EDEEC; }
  .progress-info.progress-lighten-3[value]::-ms-fill {
    background-color: #8EDEEC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-3 .progress-bar {
      background-color: #8EDEEC; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8EDEEC !important; }

.border-info.border-lighten-3 {
  border: 1px solid #8EDEEC !important; }

.border-top-info.border-top-lighten-3 {
  border-top: 1px solid #8EDEEC !important; }

.border-bottom-info.border-bottom-lighten-3 {
  border-bottom: 1px solid #8EDEEC !important; }

[dir="ltr"] .border-left-info.border-left-lighten-3 {
  border-left: 1px solid #8EDEEC !important; }

[dir="ltr"] .border-right-info.border-right-lighten-3 {
  border-right: 1px solid #8EDEEC !important; }

[dir="rtl"] .border-left-info.border-left-lighten-3 {
  border-right: 1px solid #8EDEEC !important; }

[dir="rtl"] .border-right-info.border-right-lighten-3 {
  border-left: 1px solid #8EDEEC !important; }

.overlay-info.overlay-lighten-3 {
  background: #8EDEEC;
  /* The Fallback */
  background: rgba(142, 222, 236, 0.8); }

.info.lighten-2 {
  color: #60D0E4 !important; }

.bg-info.bg-lighten-2 {
  background-color: #60D0E4 !important; }

.btn-info.btn-lighten-2 {
  border-color: #14ADCE !important;
  background-color: #60D0E4 !important; }
  .btn-info.btn-lighten-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-2:focus, .btn-info.btn-lighten-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-2 {
  border-color: #60D0E4 !important;
  color: #60D0E4 !important; }
  .btn-outline-info.btn-outline-lighten-2:hover {
    background-color: #60D0E4 !important; }

.progress-info.progress-lighten-2 {
  background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #60D0E4; }
  .progress-info.progress-lighten-2[value]::-ms-fill {
    background-color: #60D0E4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-2 .progress-bar {
      background-color: #60D0E4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #60D0E4 !important; }

.border-info.border-lighten-2 {
  border: 1px solid #60D0E4 !important; }

.border-top-info.border-top-lighten-2 {
  border-top: 1px solid #60D0E4 !important; }

.border-bottom-info.border-bottom-lighten-2 {
  border-bottom: 1px solid #60D0E4 !important; }

[dir="ltr"] .border-left-info.border-left-lighten-2 {
  border-left: 1px solid #60D0E4 !important; }

[dir="ltr"] .border-right-info.border-right-lighten-2 {
  border-right: 1px solid #60D0E4 !important; }

[dir="rtl"] .border-left-info.border-left-lighten-2 {
  border-right: 1px solid #60D0E4 !important; }

[dir="rtl"] .border-right-info.border-right-lighten-2 {
  border-left: 1px solid #60D0E4 !important; }

.overlay-info.overlay-lighten-2 {
  background: #60D0E4;
  /* The Fallback */
  background: rgba(96, 208, 228, 0.8); }

.info.lighten-1 {
  color: #3EC6DE !important; }

.bg-info.bg-lighten-1 {
  background-color: #3EC6DE !important; }

.btn-info.btn-lighten-1 {
  border-color: #14ADCE !important;
  background-color: #3EC6DE !important; }
  .btn-info.btn-lighten-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-lighten-1:focus, .btn-info.btn-lighten-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-lighten-1 {
  border-color: #3EC6DE !important;
  color: #3EC6DE !important; }
  .btn-outline-info.btn-outline-lighten-1:hover {
    background-color: #3EC6DE !important; }

.progress-info.progress-lighten-1 {
  background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #3EC6DE; }
  .progress-info.progress-lighten-1[value]::-ms-fill {
    background-color: #3EC6DE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-lighten-1 .progress-bar {
      background-color: #3EC6DE; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3EC6DE !important; }

.border-info.border-lighten-1 {
  border: 1px solid #3EC6DE !important; }

.border-top-info.border-top-lighten-1 {
  border-top: 1px solid #3EC6DE !important; }

.border-bottom-info.border-bottom-lighten-1 {
  border-bottom: 1px solid #3EC6DE !important; }

[dir="ltr"] .border-left-info.border-left-lighten-1 {
  border-left: 1px solid #3EC6DE !important; }

[dir="ltr"] .border-right-info.border-right-lighten-1 {
  border-right: 1px solid #3EC6DE !important; }

[dir="rtl"] .border-left-info.border-left-lighten-1 {
  border-right: 1px solid #3EC6DE !important; }

[dir="rtl"] .border-right-info.border-right-lighten-1 {
  border-left: 1px solid #3EC6DE !important; }

.overlay-info.overlay-lighten-1 {
  background: #3EC6DE;
  /* The Fallback */
  background: rgba(62, 198, 222, 0.8); }

.info {
  color: #1CBCD8 !important; }

.bg-info {
  background-color: #1CBCD8 !important; }
  .bg-info .card-header, .bg-info .card-footer {
    background-color: transparent; }

.toast-info {
  background-color: #1CBCD8; }

.alert-info {
  border-color: #1CBCD8 !important;
  background-color: #33cae5 !important;
  color: #08323a !important; }
  .alert-info .alert-link {
    color: #031316 !important; }

.border-info {
  border-color: #1CBCD8; }

.border-top-info {
  border-top-color: #1CBCD8; }

.border-bottom-info {
  border-bottom-color: #1CBCD8; }

[dir="ltr"] .border-left-info {
  border-left-color: #1CBCD8; }

[dir="ltr"] .border-right-info {
  border-right-color: #1CBCD8; }

[dir="rtl"] .border-left-info {
  border-right-color: #1CBCD8; }

[dir="rtl"] .border-right-info {
  border-left-color: #1CBCD8; }

.badge-info {
  background-color: #1CBCD8; }

.panel-info {
  border-color: #1CBCD8; }
  .panel-info .panel-heading {
    color: #FFF;
    border-color: #1CBCD8;
    background-color: #2ac8e3; }

.bg-info.tag-glow, .border-info.tag-glow {
  box-shadow: 0px 0px 10px #1CBCD8; }

.overlay-info {
  background: #1CBCD8;
  /* The Fallback */
  background: rgba(28, 188, 216, 0.8); }

.card.card-outline-info {
  border-width: 1px;
  border-style: solid;
  border-color: #1CBCD8;
  background-color: transparent; }
  .card.card-outline-info .card-header, .card.card-outline-info .card-footer {
    background-color: transparent; }

.btn-info.btn-flat {
  background-color: transparent !important;
  color: #1CBCD8;
  border: none; }

.btn-info.btn-raised, .btn-info.btn-fab {
  background-color: #1CBCD8 !important;
  color: #fff !important;
  border-color: #1CBCD8; }
  .btn-info.btn-raised.active, .btn-info.btn-fab.active {
    background-color: #18a0b8 !important;
    border-color: #18a0b8 !important; }

.btn-group-raised .btn-info {
  background-color: #1CBCD8 !important;
  color: #fff !important; }

.btn-outline-info {
  border: 1px solid;
  border-color: #1CBCD8;
  background-color: transparent;
  color: #1CBCD8;
  box-shadow: none !important; }
  .btn-outline-info:focus {
    background-color: transparent !important;
    color: #1CBCD8 !important;
    box-shadow: transparent !important; }
  .btn-outline-info.active {
    background-color: #1CBCD8 !important;
    color: #FFF !important; }
  .btn-outline-info:hover {
    background-color: #1ab0ca !important;
    color: #FFF !important; }
    .btn-outline-info:hover svg {
      color: #FFF !important; }

.btn-outline-info.btn-raised, .btn-outline-info.btn-fab {
  border: 1px solid;
  border-color: #1CBCD8;
  background-color: transparent;
  color: #1CBCD8;
  box-shadow: none !important; }
  .btn-outline-info.btn-raised:focus, .btn-outline-info.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-info.btn-raised.active, .btn-outline-info.btn-fab.active {
    background-color: #1CBCD8 !important;
    color: #FFF !important; }
  .btn-outline-info.btn-raised:hover, .btn-outline-info.btn-fab:hover {
    background-color: #1ab0ca !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-info {
  background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-webkit-progress-value {
    background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-moz-progress-bar {
    background-color: #1CBCD8; }
  .progress .progress-bar.progress-bar-info[value]::-ms-fill {
    background-color: #1CBCD8; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-info .progress-bar {
      background-color: #1CBCD8; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1CBCD8 !important; }

.info.darken-1 {
  color: #19B6D4 !important; }

.bg-info.bg-darken-1 {
  background-color: #19B6D4 !important; }

.btn-info.btn-darken-1 {
  border-color: #14ADCE !important;
  background-color: #19B6D4 !important; }
  .btn-info.btn-darken-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-1:focus, .btn-info.btn-darken-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-1 {
  border-color: #19B6D4 !important;
  color: #19B6D4 !important; }
  .btn-outline-info.btn-outline-darken-1:hover {
    background-color: #19B6D4 !important; }

.progress-info.progress-darken-1 {
  background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-webkit-progress-value {
    background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-moz-progress-bar {
    background-color: #19B6D4; }
  .progress-info.progress-darken-1[value]::-ms-fill {
    background-color: #19B6D4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-1 .progress-bar {
      background-color: #19B6D4; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #19B6D4 !important; }

.border-info.border-darken-1 {
  border: 1px solid #19B6D4 !important; }

.border-top-info.border-top-darken-1 {
  border-top: 1px solid #19B6D4 !important; }

.border-bottom-info.border-bottom-darken-1 {
  border-bottom: 1px solid #19B6D4 !important; }

[dir="ltr"] .border-left-info.border-left-darken-1 {
  border-left: 1px solid #19B6D4 !important; }

[dir="ltr"] .border-right-info.border-right-darken-1 {
  border-right: 1px solid #19B6D4 !important; }

[dir="rtl"] .border-left-info.border-left-darken-1 {
  border-right: 1px solid #19B6D4 !important; }

[dir="rtl"] .border-right-info.border-right-darken-1 {
  border-left: 1px solid #19B6D4 !important; }

.overlay-info.overlay-darken-1 {
  background: #19B6D4;
  /* The Fallback */
  background: rgba(25, 182, 212, 0.8); }

.info.darken-2 {
  color: #14ADCE !important; }

.bg-info.bg-darken-2 {
  background-color: #14ADCE !important; }

.btn-info.btn-darken-2 {
  border-color: #14ADCE !important;
  background-color: #14ADCE !important; }
  .btn-info.btn-darken-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-2:focus, .btn-info.btn-darken-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-2 {
  border-color: #14ADCE !important;
  color: #14ADCE !important; }
  .btn-outline-info.btn-outline-darken-2:hover {
    background-color: #14ADCE !important; }

.progress-info.progress-darken-2 {
  background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-webkit-progress-value {
    background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-moz-progress-bar {
    background-color: #14ADCE; }
  .progress-info.progress-darken-2[value]::-ms-fill {
    background-color: #14ADCE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-2 .progress-bar {
      background-color: #14ADCE; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #14ADCE !important; }

.border-info.border-darken-2 {
  border: 1px solid #14ADCE !important; }

.border-top-info.border-top-darken-2 {
  border-top: 1px solid #14ADCE !important; }

.border-bottom-info.border-bottom-darken-2 {
  border-bottom: 1px solid #14ADCE !important; }

[dir="ltr"] .border-left-info.border-left-darken-2 {
  border-left: 1px solid #14ADCE !important; }

[dir="ltr"] .border-right-info.border-right-darken-2 {
  border-right: 1px solid #14ADCE !important; }

[dir="rtl"] .border-left-info.border-left-darken-2 {
  border-right: 1px solid #14ADCE !important; }

[dir="rtl"] .border-right-info.border-right-darken-2 {
  border-left: 1px solid #14ADCE !important; }

.overlay-info.overlay-darken-2 {
  background: #14ADCE;
  /* The Fallback */
  background: rgba(20, 173, 206, 0.8); }

.info.darken-3 {
  color: #11A5C8 !important; }

.bg-info.bg-darken-3 {
  background-color: #11A5C8 !important; }

.btn-info.btn-darken-3 {
  border-color: #14ADCE !important;
  background-color: #11A5C8 !important; }
  .btn-info.btn-darken-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-3:focus, .btn-info.btn-darken-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-3 {
  border-color: #11A5C8 !important;
  color: #11A5C8 !important; }
  .btn-outline-info.btn-outline-darken-3:hover {
    background-color: #11A5C8 !important; }

.progress-info.progress-darken-3 {
  background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-webkit-progress-value {
    background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-moz-progress-bar {
    background-color: #11A5C8; }
  .progress-info.progress-darken-3[value]::-ms-fill {
    background-color: #11A5C8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-3 .progress-bar {
      background-color: #11A5C8; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #11A5C8 !important; }

.border-info.border-darken-3 {
  border: 1px solid #11A5C8 !important; }

.border-top-info.border-top-darken-3 {
  border-top: 1px solid #11A5C8 !important; }

.border-bottom-info.border-bottom-darken-3 {
  border-bottom: 1px solid #11A5C8 !important; }

[dir="ltr"] .border-left-info.border-left-darken-3 {
  border-left: 1px solid #11A5C8 !important; }

[dir="ltr"] .border-right-info.border-right-darken-3 {
  border-right: 1px solid #11A5C8 !important; }

[dir="rtl"] .border-left-info.border-left-darken-3 {
  border-right: 1px solid #11A5C8 !important; }

[dir="rtl"] .border-right-info.border-right-darken-3 {
  border-left: 1px solid #11A5C8 !important; }

.overlay-info.overlay-darken-3 {
  background: #11A5C8;
  /* The Fallback */
  background: rgba(17, 165, 200, 0.8); }

.info.darken-4 {
  color: #0997BF !important; }

.bg-info.bg-darken-4 {
  background-color: #0997BF !important; }

.btn-info.btn-darken-4 {
  border-color: #14ADCE !important;
  background-color: #0997BF !important; }
  .btn-info.btn-darken-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-darken-4:focus, .btn-info.btn-darken-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-darken-4 {
  border-color: #0997BF !important;
  color: #0997BF !important; }
  .btn-outline-info.btn-outline-darken-4:hover {
    background-color: #0997BF !important; }

.progress-info.progress-darken-4 {
  background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0997BF; }
  .progress-info.progress-darken-4[value]::-ms-fill {
    background-color: #0997BF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-darken-4 .progress-bar {
      background-color: #0997BF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0997BF !important; }

.border-info.border-darken-4 {
  border: 1px solid #0997BF !important; }

.border-top-info.border-top-darken-4 {
  border-top: 1px solid #0997BF !important; }

.border-bottom-info.border-bottom-darken-4 {
  border-bottom: 1px solid #0997BF !important; }

[dir="ltr"] .border-left-info.border-left-darken-4 {
  border-left: 1px solid #0997BF !important; }

[dir="ltr"] .border-right-info.border-right-darken-4 {
  border-right: 1px solid #0997BF !important; }

[dir="rtl"] .border-left-info.border-left-darken-4 {
  border-right: 1px solid #0997BF !important; }

[dir="rtl"] .border-right-info.border-right-darken-4 {
  border-left: 1px solid #0997BF !important; }

.overlay-info.overlay-darken-4 {
  background: #0997BF;
  /* The Fallback */
  background: rgba(9, 151, 191, 0.8); }

.info.accent-1 {
  color: #EAFAFF !important; }

.bg-info.bg-accent-1 {
  background-color: #EAFAFF !important; }

.btn-info.btn-accent-1 {
  border-color: #14ADCE !important;
  background-color: #EAFAFF !important; }
  .btn-info.btn-accent-1:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-1:focus, .btn-info.btn-accent-1:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-1 {
  border-color: #EAFAFF !important;
  color: #EAFAFF !important; }
  .btn-outline-info.btn-outline-accent-1:hover {
    background-color: #EAFAFF !important; }

.progress-info.progress-accent-1 {
  background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-webkit-progress-value {
    background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-moz-progress-bar {
    background-color: #EAFAFF; }
  .progress-info.progress-accent-1[value]::-ms-fill {
    background-color: #EAFAFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-1 .progress-bar {
      background-color: #EAFAFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #EAFAFF !important; }

.border-info.border-accent-1 {
  border: 1px solid #EAFAFF !important; }

.border-top-info.border-top-accent-1 {
  border-top: 1px solid #EAFAFF !important; }

.border-bottom-info.border-bottom-accent-1 {
  border-bottom: 1px solid #EAFAFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-1 {
  border-left: 1px solid #EAFAFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-1 {
  border-right: 1px solid #EAFAFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-1 {
  border-right: 1px solid #EAFAFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-1 {
  border-left: 1px solid #EAFAFF !important; }

.overlay-info.overlay-accent-1 {
  background: #EAFAFF;
  /* The Fallback */
  background: rgba(234, 250, 255, 0.8); }

.info.accent-2 {
  color: #B7EDFF !important; }

.bg-info.bg-accent-2 {
  background-color: #B7EDFF !important; }

.btn-info.btn-accent-2 {
  border-color: #14ADCE !important;
  background-color: #B7EDFF !important; }
  .btn-info.btn-accent-2:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-2:focus, .btn-info.btn-accent-2:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-2 {
  border-color: #B7EDFF !important;
  color: #B7EDFF !important; }
  .btn-outline-info.btn-outline-accent-2:hover {
    background-color: #B7EDFF !important; }

.progress-info.progress-accent-2 {
  background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-webkit-progress-value {
    background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-moz-progress-bar {
    background-color: #B7EDFF; }
  .progress-info.progress-accent-2[value]::-ms-fill {
    background-color: #B7EDFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-2 .progress-bar {
      background-color: #B7EDFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #B7EDFF !important; }

.border-info.border-accent-2 {
  border: 1px solid #B7EDFF !important; }

.border-top-info.border-top-accent-2 {
  border-top: 1px solid #B7EDFF !important; }

.border-bottom-info.border-bottom-accent-2 {
  border-bottom: 1px solid #B7EDFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-2 {
  border-left: 1px solid #B7EDFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-2 {
  border-right: 1px solid #B7EDFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-2 {
  border-right: 1px solid #B7EDFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-2 {
  border-left: 1px solid #B7EDFF !important; }

.overlay-info.overlay-accent-2 {
  background: #B7EDFF;
  /* The Fallback */
  background: rgba(183, 237, 255, 0.8); }

.info.accent-3 {
  color: #84E1FF !important; }

.bg-info.bg-accent-3 {
  background-color: #84E1FF !important; }

.btn-info.btn-accent-3 {
  border-color: #14ADCE !important;
  background-color: #84E1FF !important; }
  .btn-info.btn-accent-3:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-3:focus, .btn-info.btn-accent-3:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-3 {
  border-color: #84E1FF !important;
  color: #84E1FF !important; }
  .btn-outline-info.btn-outline-accent-3:hover {
    background-color: #84E1FF !important; }

.progress-info.progress-accent-3 {
  background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-webkit-progress-value {
    background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-moz-progress-bar {
    background-color: #84E1FF; }
  .progress-info.progress-accent-3[value]::-ms-fill {
    background-color: #84E1FF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-3 .progress-bar {
      background-color: #84E1FF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84E1FF !important; }

.border-info.border-accent-3 {
  border: 1px solid #84E1FF !important; }

.border-top-info.border-top-accent-3 {
  border-top: 1px solid #84E1FF !important; }

.border-bottom-info.border-bottom-accent-3 {
  border-bottom: 1px solid #84E1FF !important; }

[dir="ltr"] .border-left-info.border-left-accent-3 {
  border-left: 1px solid #84E1FF !important; }

[dir="ltr"] .border-right-info.border-right-accent-3 {
  border-right: 1px solid #84E1FF !important; }

[dir="rtl"] .border-left-info.border-left-accent-3 {
  border-right: 1px solid #84E1FF !important; }

[dir="rtl"] .border-right-info.border-right-accent-3 {
  border-left: 1px solid #84E1FF !important; }

.overlay-info.overlay-accent-3 {
  background: #84E1FF;
  /* The Fallback */
  background: rgba(132, 225, 255, 0.8); }

.info.accent-4 {
  color: #6ADAFF !important; }

.bg-info.bg-accent-4 {
  background-color: #6ADAFF !important; }

.btn-info.btn-accent-4 {
  border-color: #14ADCE !important;
  background-color: #6ADAFF !important; }
  .btn-info.btn-accent-4:hover {
    border-color: #14ADCE !important;
    background-color: #11A5C8 !important; }
  .btn-info.btn-accent-4:focus, .btn-info.btn-accent-4:active {
    border-color: #11A5C8 !important;
    background-color: #0997BF !important; }

.btn-outline-info.btn-outline-accent-4 {
  border-color: #6ADAFF !important;
  color: #6ADAFF !important; }
  .btn-outline-info.btn-outline-accent-4:hover {
    background-color: #6ADAFF !important; }

.progress-info.progress-accent-4 {
  background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6ADAFF; }
  .progress-info.progress-accent-4[value]::-ms-fill {
    background-color: #6ADAFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-info.progress-accent-4 .progress-bar {
      background-color: #6ADAFF; } }

input:focus ~ .bg-info {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6ADAFF !important; }

.border-info.border-accent-4 {
  border: 1px solid #6ADAFF !important; }

.border-top-info.border-top-accent-4 {
  border-top: 1px solid #6ADAFF !important; }

.border-bottom-info.border-bottom-accent-4 {
  border-bottom: 1px solid #6ADAFF !important; }

[dir="ltr"] .border-left-info.border-left-accent-4 {
  border-left: 1px solid #6ADAFF !important; }

[dir="ltr"] .border-right-info.border-right-accent-4 {
  border-right: 1px solid #6ADAFF !important; }

[dir="rtl"] .border-left-info.border-left-accent-4 {
  border-right: 1px solid #6ADAFF !important; }

[dir="rtl"] .border-right-info.border-right-accent-4 {
  border-left: 1px solid #6ADAFF !important; }

.overlay-info.overlay-accent-4 {
  background: #6ADAFF;
  /* The Fallback */
  background: rgba(106, 218, 255, 0.8); }

.warning.lighten-5 {
  color: #FFF1EC !important; }

.bg-warning.bg-lighten-5 {
  background-color: #FFF1EC !important; }

.btn-warning.btn-lighten-5 {
  border-color: #FF7A4E !important;
  background-color: #FFF1EC !important; }
  .btn-warning.btn-lighten-5:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-5:focus, .btn-warning.btn-lighten-5:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-5 {
  border-color: #FFF1EC !important;
  color: #FFF1EC !important; }
  .btn-outline-warning.btn-outline-lighten-5:hover {
    background-color: #FFF1EC !important; }

.progress-warning.progress-lighten-5 {
  background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #FFF1EC; }
  .progress-warning.progress-lighten-5[value]::-ms-fill {
    background-color: #FFF1EC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-5 .progress-bar {
      background-color: #FFF1EC; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFF1EC !important; }

.border-warning.border-lighten-5 {
  border: 1px solid #FFF1EC !important; }

.border-top-warning.border-top-lighten-5 {
  border-top: 1px solid #FFF1EC !important; }

.border-bottom-warning.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFF1EC !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-5 {
  border-left: 1px solid #FFF1EC !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-5 {
  border-right: 1px solid #FFF1EC !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-5 {
  border-right: 1px solid #FFF1EC !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-5 {
  border-left: 1px solid #FFF1EC !important; }

.overlay-warning.overlay-lighten-5 {
  background: #FFF1EC;
  /* The Fallback */
  background: rgba(255, 241, 236, 0.8); }

.warning.lighten-4 {
  color: #FFDDCF !important; }

.bg-warning.bg-lighten-4 {
  background-color: #FFDDCF !important; }

.btn-warning.btn-lighten-4 {
  border-color: #FF7A4E !important;
  background-color: #FFDDCF !important; }
  .btn-warning.btn-lighten-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-4:focus, .btn-warning.btn-lighten-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-4 {
  border-color: #FFDDCF !important;
  color: #FFDDCF !important; }
  .btn-outline-warning.btn-outline-lighten-4:hover {
    background-color: #FFDDCF !important; }

.progress-warning.progress-lighten-4 {
  background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #FFDDCF; }
  .progress-warning.progress-lighten-4[value]::-ms-fill {
    background-color: #FFDDCF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-4 .progress-bar {
      background-color: #FFDDCF; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFDDCF !important; }

.border-warning.border-lighten-4 {
  border: 1px solid #FFDDCF !important; }

.border-top-warning.border-top-lighten-4 {
  border-top: 1px solid #FFDDCF !important; }

.border-bottom-warning.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFDDCF !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-4 {
  border-left: 1px solid #FFDDCF !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-4 {
  border-right: 1px solid #FFDDCF !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-4 {
  border-right: 1px solid #FFDDCF !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-4 {
  border-left: 1px solid #FFDDCF !important; }

.overlay-warning.overlay-lighten-4 {
  background: #FFDDCF;
  /* The Fallback */
  background: rgba(255, 221, 207, 0.8); }

.warning.lighten-3 {
  color: #FFC6B0 !important; }

.bg-warning.bg-lighten-3 {
  background-color: #FFC6B0 !important; }

.btn-warning.btn-lighten-3 {
  border-color: #FF7A4E !important;
  background-color: #FFC6B0 !important; }
  .btn-warning.btn-lighten-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-3:focus, .btn-warning.btn-lighten-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-3 {
  border-color: #FFC6B0 !important;
  color: #FFC6B0 !important; }
  .btn-outline-warning.btn-outline-lighten-3:hover {
    background-color: #FFC6B0 !important; }

.progress-warning.progress-lighten-3 {
  background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #FFC6B0; }
  .progress-warning.progress-lighten-3[value]::-ms-fill {
    background-color: #FFC6B0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-3 .progress-bar {
      background-color: #FFC6B0; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFC6B0 !important; }

.border-warning.border-lighten-3 {
  border: 1px solid #FFC6B0 !important; }

.border-top-warning.border-top-lighten-3 {
  border-top: 1px solid #FFC6B0 !important; }

.border-bottom-warning.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFC6B0 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-3 {
  border-left: 1px solid #FFC6B0 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-3 {
  border-right: 1px solid #FFC6B0 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-3 {
  border-right: 1px solid #FFC6B0 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-3 {
  border-left: 1px solid #FFC6B0 !important; }

.overlay-warning.overlay-lighten-3 {
  background: #FFC6B0;
  /* The Fallback */
  background: rgba(255, 198, 176, 0.8); }

.warning.lighten-2 {
  color: #FFAF90 !important; }

.bg-warning.bg-lighten-2 {
  background-color: #FFAF90 !important; }

.btn-warning.btn-lighten-2 {
  border-color: #FF7A4E !important;
  background-color: #FFAF90 !important; }
  .btn-warning.btn-lighten-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-2:focus, .btn-warning.btn-lighten-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-2 {
  border-color: #FFAF90 !important;
  color: #FFAF90 !important; }
  .btn-outline-warning.btn-outline-lighten-2:hover {
    background-color: #FFAF90 !important; }

.progress-warning.progress-lighten-2 {
  background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #FFAF90; }
  .progress-warning.progress-lighten-2[value]::-ms-fill {
    background-color: #FFAF90; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-2 .progress-bar {
      background-color: #FFAF90; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFAF90 !important; }

.border-warning.border-lighten-2 {
  border: 1px solid #FFAF90 !important; }

.border-top-warning.border-top-lighten-2 {
  border-top: 1px solid #FFAF90 !important; }

.border-bottom-warning.border-bottom-lighten-2 {
  border-bottom: 1px solid #FFAF90 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-2 {
  border-left: 1px solid #FFAF90 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-2 {
  border-right: 1px solid #FFAF90 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-2 {
  border-right: 1px solid #FFAF90 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-2 {
  border-left: 1px solid #FFAF90 !important; }

.overlay-warning.overlay-lighten-2 {
  background: #FFAF90;
  /* The Fallback */
  background: rgba(255, 175, 144, 0.8); }

.warning.lighten-1 {
  color: #FF9E78 !important; }

.bg-warning.bg-lighten-1 {
  background-color: #FF9E78 !important; }

.btn-warning.btn-lighten-1 {
  border-color: #FF7A4E !important;
  background-color: #FF9E78 !important; }
  .btn-warning.btn-lighten-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-lighten-1:focus, .btn-warning.btn-lighten-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-lighten-1 {
  border-color: #FF9E78 !important;
  color: #FF9E78 !important; }
  .btn-outline-warning.btn-outline-lighten-1:hover {
    background-color: #FF9E78 !important; }

.progress-warning.progress-lighten-1 {
  background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #FF9E78; }
  .progress-warning.progress-lighten-1[value]::-ms-fill {
    background-color: #FF9E78; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-lighten-1 .progress-bar {
      background-color: #FF9E78; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF9E78 !important; }

.border-warning.border-lighten-1 {
  border: 1px solid #FF9E78 !important; }

.border-top-warning.border-top-lighten-1 {
  border-top: 1px solid #FF9E78 !important; }

.border-bottom-warning.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF9E78 !important; }

[dir="ltr"] .border-left-warning.border-left-lighten-1 {
  border-left: 1px solid #FF9E78 !important; }

[dir="ltr"] .border-right-warning.border-right-lighten-1 {
  border-right: 1px solid #FF9E78 !important; }

[dir="rtl"] .border-left-warning.border-left-lighten-1 {
  border-right: 1px solid #FF9E78 !important; }

[dir="rtl"] .border-right-warning.border-right-lighten-1 {
  border-left: 1px solid #FF9E78 !important; }

.overlay-warning.overlay-lighten-1 {
  background: #FF9E78;
  /* The Fallback */
  background: rgba(255, 158, 120, 0.8); }

.warning {
  color: #FF8D60 !important; }

.bg-warning {
  background-color: #FF8D60 !important; }
  .bg-warning .card-header, .bg-warning .card-footer {
    background-color: transparent; }

.toast-warning {
  background-color: #FF8D60; }

.alert-warning {
  border-color: #FF8D60 !important;
  background-color: #ffa784 !important;
  color: #ad3100 !important; }
  .alert-warning .alert-link {
    color: #842500 !important; }

.border-warning {
  border-color: #FF8D60; }

.border-top-warning {
  border-top-color: #FF8D60; }

.border-bottom-warning {
  border-bottom-color: #FF8D60; }

[dir="ltr"] .border-left-warning {
  border-left-color: #FF8D60; }

[dir="ltr"] .border-right-warning {
  border-right-color: #FF8D60; }

[dir="rtl"] .border-left-warning {
  border-right-color: #FF8D60; }

[dir="rtl"] .border-right-warning {
  border-left-color: #FF8D60; }

.badge-warning {
  background-color: #FF8D60; }

.panel-warning {
  border-color: #FF8D60; }
  .panel-warning .panel-heading {
    color: #FFF;
    border-color: #FF8D60;
    background-color: #ff9f7a; }

.bg-warning.tag-glow, .border-warning.tag-glow {
  box-shadow: 0px 0px 10px #FF8D60; }

.overlay-warning {
  background: #FF8D60;
  /* The Fallback */
  background: rgba(255, 141, 96, 0.8); }

.card.card-outline-warning {
  border-width: 1px;
  border-style: solid;
  border-color: #FF8D60;
  background-color: transparent; }
  .card.card-outline-warning .card-header, .card.card-outline-warning .card-footer {
    background-color: transparent; }

.btn-warning.btn-flat {
  background-color: transparent !important;
  color: #FF8D60;
  border: none; }

.btn-warning.btn-raised, .btn-warning.btn-fab {
  background-color: #FF8D60 !important;
  color: #fff !important;
  border-color: #FF8D60; }
  .btn-warning.btn-raised.active, .btn-warning.btn-fab.active {
    background-color: #ff733c !important;
    border-color: #ff733c !important; }

.btn-group-raised .btn-warning {
  background-color: #FF8D60 !important;
  color: #fff !important; }

.btn-outline-warning {
  border: 1px solid;
  border-color: #FF8D60;
  background-color: transparent;
  color: #FF8D60;
  box-shadow: none !important; }
  .btn-outline-warning:focus {
    background-color: transparent !important;
    color: #FF8D60 !important;
    box-shadow: transparent !important; }
  .btn-outline-warning.active {
    background-color: #FF8D60 !important;
    color: #FFF !important; }
  .btn-outline-warning:hover {
    background-color: #ff8251 !important;
    color: #FFF !important; }
    .btn-outline-warning:hover svg {
      color: #FFF !important; }

.btn-outline-warning.btn-raised, .btn-outline-warning.btn-fab {
  border: 1px solid;
  border-color: #FF8D60;
  background-color: transparent;
  color: #FF8D60;
  box-shadow: none !important; }
  .btn-outline-warning.btn-raised:focus, .btn-outline-warning.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-warning.btn-raised.active, .btn-outline-warning.btn-fab.active {
    background-color: #FF8D60 !important;
    color: #FFF !important; }
  .btn-outline-warning.btn-raised:hover, .btn-outline-warning.btn-fab:hover {
    background-color: #ff8251 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-warning {
  background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-webkit-progress-value {
    background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-moz-progress-bar {
    background-color: #FF8D60; }
  .progress .progress-bar.progress-bar-warning[value]::-ms-fill {
    background-color: #FF8D60; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-warning .progress-bar {
      background-color: #FF8D60; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8D60 !important; }

.warning.darken-1 {
  color: #FF8558 !important; }

.bg-warning.bg-darken-1 {
  background-color: #FF8558 !important; }

.btn-warning.btn-darken-1 {
  border-color: #FF7A4E !important;
  background-color: #FF8558 !important; }
  .btn-warning.btn-darken-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-1:focus, .btn-warning.btn-darken-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-1 {
  border-color: #FF8558 !important;
  color: #FF8558 !important; }
  .btn-outline-warning.btn-outline-darken-1:hover {
    background-color: #FF8558 !important; }

.progress-warning.progress-darken-1 {
  background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-webkit-progress-value {
    background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-moz-progress-bar {
    background-color: #FF8558; }
  .progress-warning.progress-darken-1[value]::-ms-fill {
    background-color: #FF8558; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-1 .progress-bar {
      background-color: #FF8558; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8558 !important; }

.border-warning.border-darken-1 {
  border: 1px solid #FF8558 !important; }

.border-top-warning.border-top-darken-1 {
  border-top: 1px solid #FF8558 !important; }

.border-bottom-warning.border-bottom-darken-1 {
  border-bottom: 1px solid #FF8558 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-1 {
  border-left: 1px solid #FF8558 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-1 {
  border-right: 1px solid #FF8558 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-1 {
  border-right: 1px solid #FF8558 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-1 {
  border-left: 1px solid #FF8558 !important; }

.overlay-warning.overlay-darken-1 {
  background: #FF8558;
  /* The Fallback */
  background: rgba(255, 133, 88, 0.8); }

.warning.darken-2 {
  color: #FF7A4E !important; }

.bg-warning.bg-darken-2 {
  background-color: #FF7A4E !important; }

.btn-warning.btn-darken-2 {
  border-color: #FF7A4E !important;
  background-color: #FF7A4E !important; }
  .btn-warning.btn-darken-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-2:focus, .btn-warning.btn-darken-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-2 {
  border-color: #FF7A4E !important;
  color: #FF7A4E !important; }
  .btn-outline-warning.btn-outline-darken-2:hover {
    background-color: #FF7A4E !important; }

.progress-warning.progress-darken-2 {
  background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-webkit-progress-value {
    background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-moz-progress-bar {
    background-color: #FF7A4E; }
  .progress-warning.progress-darken-2[value]::-ms-fill {
    background-color: #FF7A4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-2 .progress-bar {
      background-color: #FF7A4E; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7A4E !important; }

.border-warning.border-darken-2 {
  border: 1px solid #FF7A4E !important; }

.border-top-warning.border-top-darken-2 {
  border-top: 1px solid #FF7A4E !important; }

.border-bottom-warning.border-bottom-darken-2 {
  border-bottom: 1px solid #FF7A4E !important; }

[dir="ltr"] .border-left-warning.border-left-darken-2 {
  border-left: 1px solid #FF7A4E !important; }

[dir="ltr"] .border-right-warning.border-right-darken-2 {
  border-right: 1px solid #FF7A4E !important; }

[dir="rtl"] .border-left-warning.border-left-darken-2 {
  border-right: 1px solid #FF7A4E !important; }

[dir="rtl"] .border-right-warning.border-right-darken-2 {
  border-left: 1px solid #FF7A4E !important; }

.overlay-warning.overlay-darken-2 {
  background: #FF7A4E;
  /* The Fallback */
  background: rgba(255, 122, 78, 0.8); }

.warning.darken-3 {
  color: #FF7044 !important; }

.bg-warning.bg-darken-3 {
  background-color: #FF7044 !important; }

.btn-warning.btn-darken-3 {
  border-color: #FF7A4E !important;
  background-color: #FF7044 !important; }
  .btn-warning.btn-darken-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-3:focus, .btn-warning.btn-darken-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-3 {
  border-color: #FF7044 !important;
  color: #FF7044 !important; }
  .btn-outline-warning.btn-outline-darken-3:hover {
    background-color: #FF7044 !important; }

.progress-warning.progress-darken-3 {
  background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-webkit-progress-value {
    background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-moz-progress-bar {
    background-color: #FF7044; }
  .progress-warning.progress-darken-3[value]::-ms-fill {
    background-color: #FF7044; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-3 .progress-bar {
      background-color: #FF7044; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7044 !important; }

.border-warning.border-darken-3 {
  border: 1px solid #FF7044 !important; }

.border-top-warning.border-top-darken-3 {
  border-top: 1px solid #FF7044 !important; }

.border-bottom-warning.border-bottom-darken-3 {
  border-bottom: 1px solid #FF7044 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-3 {
  border-left: 1px solid #FF7044 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-3 {
  border-right: 1px solid #FF7044 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-3 {
  border-right: 1px solid #FF7044 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-3 {
  border-left: 1px solid #FF7044 !important; }

.overlay-warning.overlay-darken-3 {
  background: #FF7044;
  /* The Fallback */
  background: rgba(255, 112, 68, 0.8); }

.warning.darken-4 {
  color: #FF5D33 !important; }

.bg-warning.bg-darken-4 {
  background-color: #FF5D33 !important; }

.btn-warning.btn-darken-4 {
  border-color: #FF7A4E !important;
  background-color: #FF5D33 !important; }
  .btn-warning.btn-darken-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-darken-4:focus, .btn-warning.btn-darken-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-darken-4 {
  border-color: #FF5D33 !important;
  color: #FF5D33 !important; }
  .btn-outline-warning.btn-outline-darken-4:hover {
    background-color: #FF5D33 !important; }

.progress-warning.progress-darken-4 {
  background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-webkit-progress-value {
    background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-moz-progress-bar {
    background-color: #FF5D33; }
  .progress-warning.progress-darken-4[value]::-ms-fill {
    background-color: #FF5D33; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-darken-4 .progress-bar {
      background-color: #FF5D33; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5D33 !important; }

.border-warning.border-darken-4 {
  border: 1px solid #FF5D33 !important; }

.border-top-warning.border-top-darken-4 {
  border-top: 1px solid #FF5D33 !important; }

.border-bottom-warning.border-bottom-darken-4 {
  border-bottom: 1px solid #FF5D33 !important; }

[dir="ltr"] .border-left-warning.border-left-darken-4 {
  border-left: 1px solid #FF5D33 !important; }

[dir="ltr"] .border-right-warning.border-right-darken-4 {
  border-right: 1px solid #FF5D33 !important; }

[dir="rtl"] .border-left-warning.border-left-darken-4 {
  border-right: 1px solid #FF5D33 !important; }

[dir="rtl"] .border-right-warning.border-right-darken-4 {
  border-left: 1px solid #FF5D33 !important; }

.overlay-warning.overlay-darken-4 {
  background: #FF5D33;
  /* The Fallback */
  background: rgba(255, 93, 51, 0.8); }

.warning.accent-1 {
  color: #FFFFFF !important; }

.bg-warning.bg-accent-1 {
  background-color: #FFFFFF !important; }

.btn-warning.btn-accent-1 {
  border-color: #FF7A4E !important;
  background-color: #FFFFFF !important; }
  .btn-warning.btn-accent-1:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-1:focus, .btn-warning.btn-accent-1:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-1 {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-warning.btn-outline-accent-1:hover {
    background-color: #FFFFFF !important; }

.progress-warning.progress-accent-1 {
  background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-warning.progress-accent-1[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-1 .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-warning.border-accent-1 {
  border: 1px solid #FFFFFF !important; }

.border-top-warning.border-top-accent-1 {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-warning.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-warning.border-left-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-warning.border-right-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-warning.border-left-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-warning.border-right-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

.overlay-warning.overlay-accent-1 {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.warning.accent-2 {
  color: #FFEFEC !important; }

.bg-warning.bg-accent-2 {
  background-color: #FFEFEC !important; }

.btn-warning.btn-accent-2 {
  border-color: #FF7A4E !important;
  background-color: #FFEFEC !important; }
  .btn-warning.btn-accent-2:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-2:focus, .btn-warning.btn-accent-2:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-2 {
  border-color: #FFEFEC !important;
  color: #FFEFEC !important; }
  .btn-outline-warning.btn-outline-accent-2:hover {
    background-color: #FFEFEC !important; }

.progress-warning.progress-accent-2 {
  background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-webkit-progress-value {
    background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-moz-progress-bar {
    background-color: #FFEFEC; }
  .progress-warning.progress-accent-2[value]::-ms-fill {
    background-color: #FFEFEC; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-2 .progress-bar {
      background-color: #FFEFEC; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEFEC !important; }

.border-warning.border-accent-2 {
  border: 1px solid #FFEFEC !important; }

.border-top-warning.border-top-accent-2 {
  border-top: 1px solid #FFEFEC !important; }

.border-bottom-warning.border-bottom-accent-2 {
  border-bottom: 1px solid #FFEFEC !important; }

[dir="ltr"] .border-left-warning.border-left-accent-2 {
  border-left: 1px solid #FFEFEC !important; }

[dir="ltr"] .border-right-warning.border-right-accent-2 {
  border-right: 1px solid #FFEFEC !important; }

[dir="rtl"] .border-left-warning.border-left-accent-2 {
  border-right: 1px solid #FFEFEC !important; }

[dir="rtl"] .border-right-warning.border-right-accent-2 {
  border-left: 1px solid #FFEFEC !important; }

.overlay-warning.overlay-accent-2 {
  background: #FFEFEC;
  /* The Fallback */
  background: rgba(255, 239, 236, 0.8); }

.warning.accent-3 {
  color: #FFE4DE !important; }

.bg-warning.bg-accent-3 {
  background-color: #FFE4DE !important; }

.btn-warning.btn-accent-3 {
  border-color: #FF7A4E !important;
  background-color: #FFE4DE !important; }
  .btn-warning.btn-accent-3:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-3:focus, .btn-warning.btn-accent-3:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-3 {
  border-color: #FFE4DE !important;
  color: #FFE4DE !important; }
  .btn-outline-warning.btn-outline-accent-3:hover {
    background-color: #FFE4DE !important; }

.progress-warning.progress-accent-3 {
  background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-webkit-progress-value {
    background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-moz-progress-bar {
    background-color: #FFE4DE; }
  .progress-warning.progress-accent-3[value]::-ms-fill {
    background-color: #FFE4DE; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-3 .progress-bar {
      background-color: #FFE4DE; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFE4DE !important; }

.border-warning.border-accent-3 {
  border: 1px solid #FFE4DE !important; }

.border-top-warning.border-top-accent-3 {
  border-top: 1px solid #FFE4DE !important; }

.border-bottom-warning.border-bottom-accent-3 {
  border-bottom: 1px solid #FFE4DE !important; }

[dir="ltr"] .border-left-warning.border-left-accent-3 {
  border-left: 1px solid #FFE4DE !important; }

[dir="ltr"] .border-right-warning.border-right-accent-3 {
  border-right: 1px solid #FFE4DE !important; }

[dir="rtl"] .border-left-warning.border-left-accent-3 {
  border-right: 1px solid #FFE4DE !important; }

[dir="rtl"] .border-right-warning.border-right-accent-3 {
  border-left: 1px solid #FFE4DE !important; }

.overlay-warning.overlay-accent-3 {
  background: #FFE4DE;
  /* The Fallback */
  background: rgba(255, 228, 222, 0.8); }

.warning.accent-4 {
  color: #FFCFC4 !important; }

.bg-warning.bg-accent-4 {
  background-color: #FFCFC4 !important; }

.btn-warning.btn-accent-4 {
  border-color: #FF7A4E !important;
  background-color: #FFCFC4 !important; }
  .btn-warning.btn-accent-4:hover {
    border-color: #FF7A4E !important;
    background-color: #FF7044 !important; }
  .btn-warning.btn-accent-4:focus, .btn-warning.btn-accent-4:active {
    border-color: #FF7044 !important;
    background-color: #FF5D33 !important; }

.btn-outline-warning.btn-outline-accent-4 {
  border-color: #FFCFC4 !important;
  color: #FFCFC4 !important; }
  .btn-outline-warning.btn-outline-accent-4:hover {
    background-color: #FFCFC4 !important; }

.progress-warning.progress-accent-4 {
  background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-webkit-progress-value {
    background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-moz-progress-bar {
    background-color: #FFCFC4; }
  .progress-warning.progress-accent-4[value]::-ms-fill {
    background-color: #FFCFC4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-warning.progress-accent-4 .progress-bar {
      background-color: #FFCFC4; } }

input:focus ~ .bg-warning {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCFC4 !important; }

.border-warning.border-accent-4 {
  border: 1px solid #FFCFC4 !important; }

.border-top-warning.border-top-accent-4 {
  border-top: 1px solid #FFCFC4 !important; }

.border-bottom-warning.border-bottom-accent-4 {
  border-bottom: 1px solid #FFCFC4 !important; }

[dir="ltr"] .border-left-warning.border-left-accent-4 {
  border-left: 1px solid #FFCFC4 !important; }

[dir="ltr"] .border-right-warning.border-right-accent-4 {
  border-right: 1px solid #FFCFC4 !important; }

[dir="rtl"] .border-left-warning.border-left-accent-4 {
  border-right: 1px solid #FFCFC4 !important; }

[dir="rtl"] .border-right-warning.border-right-accent-4 {
  border-left: 1px solid #FFCFC4 !important; }

.overlay-warning.overlay-accent-4 {
  background: #FFCFC4;
  /* The Fallback */
  background: rgba(255, 207, 196, 0.8); }

.danger.lighten-5 {
  color: #FFEBED !important; }

.bg-danger.bg-lighten-5 {
  background-color: #FFEBED !important; }

.btn-danger.btn-lighten-5 {
  border-color: #FF4758 !important;
  background-color: #FFEBED !important; }
  .btn-danger.btn-lighten-5:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-5:focus, .btn-danger.btn-lighten-5:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-5 {
  border-color: #FFEBED !important;
  color: #FFEBED !important; }
  .btn-outline-danger.btn-outline-lighten-5:hover {
    background-color: #FFEBED !important; }

.progress-danger.progress-lighten-5 {
  background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #FFEBED; }
  .progress-danger.progress-lighten-5[value]::-ms-fill {
    background-color: #FFEBED; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-5 .progress-bar {
      background-color: #FFEBED; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFEBED !important; }

.border-danger.border-lighten-5 {
  border: 1px solid #FFEBED !important; }

.border-top-danger.border-top-lighten-5 {
  border-top: 1px solid #FFEBED !important; }

.border-bottom-danger.border-bottom-lighten-5 {
  border-bottom: 1px solid #FFEBED !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-5 {
  border-left: 1px solid #FFEBED !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-5 {
  border-right: 1px solid #FFEBED !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-5 {
  border-right: 1px solid #FFEBED !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-5 {
  border-left: 1px solid #FFEBED !important; }

.overlay-danger.overlay-lighten-5 {
  background: #FFEBED;
  /* The Fallback */
  background: rgba(255, 235, 237, 0.8); }

.danger.lighten-4 {
  color: #FFCDD3 !important; }

.bg-danger.bg-lighten-4 {
  background-color: #FFCDD3 !important; }

.btn-danger.btn-lighten-4 {
  border-color: #FF4758 !important;
  background-color: #FFCDD3 !important; }
  .btn-danger.btn-lighten-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-4:focus, .btn-danger.btn-lighten-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-4 {
  border-color: #FFCDD3 !important;
  color: #FFCDD3 !important; }
  .btn-outline-danger.btn-outline-lighten-4:hover {
    background-color: #FFCDD3 !important; }

.progress-danger.progress-lighten-4 {
  background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #FFCDD3; }
  .progress-danger.progress-lighten-4[value]::-ms-fill {
    background-color: #FFCDD3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-4 .progress-bar {
      background-color: #FFCDD3; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFCDD3 !important; }

.border-danger.border-lighten-4 {
  border: 1px solid #FFCDD3 !important; }

.border-top-danger.border-top-lighten-4 {
  border-top: 1px solid #FFCDD3 !important; }

.border-bottom-danger.border-bottom-lighten-4 {
  border-bottom: 1px solid #FFCDD3 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-4 {
  border-left: 1px solid #FFCDD3 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-4 {
  border-right: 1px solid #FFCDD3 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-4 {
  border-right: 1px solid #FFCDD3 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-4 {
  border-left: 1px solid #FFCDD3 !important; }

.overlay-danger.overlay-lighten-4 {
  background: #FFCDD3;
  /* The Fallback */
  background: rgba(255, 205, 211, 0.8); }

.danger.lighten-3 {
  color: #FFACB5 !important; }

.bg-danger.bg-lighten-3 {
  background-color: #FFACB5 !important; }

.btn-danger.btn-lighten-3 {
  border-color: #FF4758 !important;
  background-color: #FFACB5 !important; }
  .btn-danger.btn-lighten-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-3:focus, .btn-danger.btn-lighten-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-3 {
  border-color: #FFACB5 !important;
  color: #FFACB5 !important; }
  .btn-outline-danger.btn-outline-lighten-3:hover {
    background-color: #FFACB5 !important; }

.progress-danger.progress-lighten-3 {
  background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #FFACB5; }
  .progress-danger.progress-lighten-3[value]::-ms-fill {
    background-color: #FFACB5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-3 .progress-bar {
      background-color: #FFACB5; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFACB5 !important; }

.border-danger.border-lighten-3 {
  border: 1px solid #FFACB5 !important; }

.border-top-danger.border-top-lighten-3 {
  border-top: 1px solid #FFACB5 !important; }

.border-bottom-danger.border-bottom-lighten-3 {
  border-bottom: 1px solid #FFACB5 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-3 {
  border-left: 1px solid #FFACB5 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-3 {
  border-right: 1px solid #FFACB5 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-3 {
  border-right: 1px solid #FFACB5 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-3 {
  border-left: 1px solid #FFACB5 !important; }

.overlay-danger.overlay-lighten-3 {
  background: #FFACB5;
  /* The Fallback */
  background: rgba(255, 172, 181, 0.8); }

.danger.lighten-2 {
  color: #FF8A97 !important; }

.bg-danger.bg-lighten-2 {
  background-color: #FF8A97 !important; }

.btn-danger.btn-lighten-2 {
  border-color: #FF4758 !important;
  background-color: #FF8A97 !important; }
  .btn-danger.btn-lighten-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-2:focus, .btn-danger.btn-lighten-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-2 {
  border-color: #FF8A97 !important;
  color: #FF8A97 !important; }
  .btn-outline-danger.btn-outline-lighten-2:hover {
    background-color: #FF8A97 !important; }

.progress-danger.progress-lighten-2 {
  background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #FF8A97; }
  .progress-danger.progress-lighten-2[value]::-ms-fill {
    background-color: #FF8A97; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-2 .progress-bar {
      background-color: #FF8A97; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF8A97 !important; }

.border-danger.border-lighten-2 {
  border: 1px solid #FF8A97 !important; }

.border-top-danger.border-top-lighten-2 {
  border-top: 1px solid #FF8A97 !important; }

.border-bottom-danger.border-bottom-lighten-2 {
  border-bottom: 1px solid #FF8A97 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-2 {
  border-left: 1px solid #FF8A97 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-2 {
  border-right: 1px solid #FF8A97 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-2 {
  border-right: 1px solid #FF8A97 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-2 {
  border-left: 1px solid #FF8A97 !important; }

.overlay-danger.overlay-lighten-2 {
  background: #FF8A97;
  /* The Fallback */
  background: rgba(255, 138, 151, 0.8); }

.danger.lighten-1 {
  color: #FF7181 !important; }

.bg-danger.bg-lighten-1 {
  background-color: #FF7181 !important; }

.btn-danger.btn-lighten-1 {
  border-color: #FF4758 !important;
  background-color: #FF7181 !important; }
  .btn-danger.btn-lighten-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-lighten-1:focus, .btn-danger.btn-lighten-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-lighten-1 {
  border-color: #FF7181 !important;
  color: #FF7181 !important; }
  .btn-outline-danger.btn-outline-lighten-1:hover {
    background-color: #FF7181 !important; }

.progress-danger.progress-lighten-1 {
  background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #FF7181; }
  .progress-danger.progress-lighten-1[value]::-ms-fill {
    background-color: #FF7181; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-lighten-1 .progress-bar {
      background-color: #FF7181; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF7181 !important; }

.border-danger.border-lighten-1 {
  border: 1px solid #FF7181 !important; }

.border-top-danger.border-top-lighten-1 {
  border-top: 1px solid #FF7181 !important; }

.border-bottom-danger.border-bottom-lighten-1 {
  border-bottom: 1px solid #FF7181 !important; }

[dir="ltr"] .border-left-danger.border-left-lighten-1 {
  border-left: 1px solid #FF7181 !important; }

[dir="ltr"] .border-right-danger.border-right-lighten-1 {
  border-right: 1px solid #FF7181 !important; }

[dir="rtl"] .border-left-danger.border-left-lighten-1 {
  border-right: 1px solid #FF7181 !important; }

[dir="rtl"] .border-right-danger.border-right-lighten-1 {
  border-left: 1px solid #FF7181 !important; }

.overlay-danger.overlay-lighten-1 {
  background: #FF7181;
  /* The Fallback */
  background: rgba(255, 113, 129, 0.8); }

.danger {
  color: #FF586B !important; }

.bg-danger {
  background-color: #FF586B !important; }
  .bg-danger .card-header, .bg-danger .card-footer {
    background-color: transparent; }

.toast-danger {
  background-color: #FF586B; }

.alert-danger {
  border-color: #FF586B !important;
  background-color: #ff7c8b !important;
  color: #a50013 !important; }
  .alert-danger .alert-link {
    color: #7c000e !important; }

.border-danger {
  border-color: #FF586B; }

.border-top-danger {
  border-top-color: #FF586B; }

.border-bottom-danger {
  border-bottom-color: #FF586B; }

[dir="ltr"] .border-left-danger {
  border-left-color: #FF586B; }

[dir="ltr"] .border-right-danger {
  border-right-color: #FF586B; }

[dir="rtl"] .border-left-danger {
  border-right-color: #FF586B; }

[dir="rtl"] .border-right-danger {
  border-left-color: #FF586B; }

.badge-danger {
  background-color: #FF586B; }

.panel-danger {
  border-color: #FF586B; }
  .panel-danger .panel-heading {
    color: #FFF;
    border-color: #FF586B;
    background-color: #ff7282; }

.bg-danger.tag-glow, .border-danger.tag-glow {
  box-shadow: 0px 0px 10px #FF586B; }

.overlay-danger {
  background: #FF586B;
  /* The Fallback */
  background: rgba(255, 88, 107, 0.8); }

.card.card-outline-danger {
  border-width: 1px;
  border-style: solid;
  border-color: #FF586B;
  background-color: transparent; }
  .card.card-outline-danger .card-header, .card.card-outline-danger .card-footer {
    background-color: transparent; }

.btn-danger.btn-flat {
  background-color: transparent !important;
  color: #FF586B;
  border: none; }

.btn-danger.btn-raised, .btn-danger.btn-fab {
  background-color: #FF586B !important;
  color: #fff !important;
  border-color: #FF586B; }
  .btn-danger.btn-raised.active, .btn-danger.btn-fab.active {
    background-color: #ff344b !important;
    border-color: #ff344b !important; }

.btn-group-raised .btn-danger {
  background-color: #FF586B !important;
  color: #fff !important; }

.btn-outline-danger {
  border: 1px solid;
  border-color: #FF586B;
  background-color: transparent;
  color: #FF586B;
  box-shadow: none !important; }
  .btn-outline-danger:focus {
    background-color: transparent !important;
    color: #FF586B !important;
    box-shadow: transparent !important; }
  .btn-outline-danger.active {
    background-color: #FF586B !important;
    color: #FFF !important; }
  .btn-outline-danger:hover {
    background-color: #ff495d !important;
    color: #FFF !important; }
    .btn-outline-danger:hover svg {
      color: #FFF !important; }

.btn-outline-danger.btn-raised, .btn-outline-danger.btn-fab {
  border: 1px solid;
  border-color: #FF586B;
  background-color: transparent;
  color: #FF586B;
  box-shadow: none !important; }
  .btn-outline-danger.btn-raised:focus, .btn-outline-danger.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-danger.btn-raised.active, .btn-outline-danger.btn-fab.active {
    background-color: #FF586B !important;
    color: #FFF !important; }
  .btn-outline-danger.btn-raised:hover, .btn-outline-danger.btn-fab:hover {
    background-color: #ff495d !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-danger {
  background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-webkit-progress-value {
    background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-moz-progress-bar {
    background-color: #FF586B; }
  .progress .progress-bar.progress-bar-danger[value]::-ms-fill {
    background-color: #FF586B; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-danger .progress-bar {
      background-color: #FF586B; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF586B !important; }

.danger.darken-1 {
  color: #FF5063 !important; }

.bg-danger.bg-darken-1 {
  background-color: #FF5063 !important; }

.btn-danger.btn-darken-1 {
  border-color: #FF4758 !important;
  background-color: #FF5063 !important; }
  .btn-danger.btn-darken-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-1:focus, .btn-danger.btn-darken-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-1 {
  border-color: #FF5063 !important;
  color: #FF5063 !important; }
  .btn-outline-danger.btn-outline-darken-1:hover {
    background-color: #FF5063 !important; }

.progress-danger.progress-darken-1 {
  background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-webkit-progress-value {
    background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-moz-progress-bar {
    background-color: #FF5063; }
  .progress-danger.progress-darken-1[value]::-ms-fill {
    background-color: #FF5063; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-1 .progress-bar {
      background-color: #FF5063; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF5063 !important; }

.border-danger.border-darken-1 {
  border: 1px solid #FF5063 !important; }

.border-top-danger.border-top-darken-1 {
  border-top: 1px solid #FF5063 !important; }

.border-bottom-danger.border-bottom-darken-1 {
  border-bottom: 1px solid #FF5063 !important; }

[dir="ltr"] .border-left-danger.border-left-darken-1 {
  border-left: 1px solid #FF5063 !important; }

[dir="ltr"] .border-right-danger.border-right-darken-1 {
  border-right: 1px solid #FF5063 !important; }

[dir="rtl"] .border-left-danger.border-left-darken-1 {
  border-right: 1px solid #FF5063 !important; }

[dir="rtl"] .border-right-danger.border-right-darken-1 {
  border-left: 1px solid #FF5063 !important; }

.overlay-danger.overlay-darken-1 {
  background: #FF5063;
  /* The Fallback */
  background: rgba(255, 80, 99, 0.8); }

.danger.darken-2 {
  color: #FF4758 !important; }

.bg-danger.bg-darken-2 {
  background-color: #FF4758 !important; }

.btn-danger.btn-darken-2 {
  border-color: #FF4758 !important;
  background-color: #FF4758 !important; }
  .btn-danger.btn-darken-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-2:focus, .btn-danger.btn-darken-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-2 {
  border-color: #FF4758 !important;
  color: #FF4758 !important; }
  .btn-outline-danger.btn-outline-darken-2:hover {
    background-color: #FF4758 !important; }

.progress-danger.progress-darken-2 {
  background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-webkit-progress-value {
    background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-moz-progress-bar {
    background-color: #FF4758; }
  .progress-danger.progress-darken-2[value]::-ms-fill {
    background-color: #FF4758; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-2 .progress-bar {
      background-color: #FF4758; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF4758 !important; }

.border-danger.border-darken-2 {
  border: 1px solid #FF4758 !important; }

.border-top-danger.border-top-darken-2 {
  border-top: 1px solid #FF4758 !important; }

.border-bottom-danger.border-bottom-darken-2 {
  border-bottom: 1px solid #FF4758 !important; }

[dir="ltr"] .border-left-danger.border-left-darken-2 {
  border-left: 1px solid #FF4758 !important; }

[dir="ltr"] .border-right-danger.border-right-darken-2 {
  border-right: 1px solid #FF4758 !important; }

[dir="rtl"] .border-left-danger.border-left-darken-2 {
  border-right: 1px solid #FF4758 !important; }

[dir="rtl"] .border-right-danger.border-right-darken-2 {
  border-left: 1px solid #FF4758 !important; }

.overlay-danger.overlay-darken-2 {
  background: #FF4758;
  /* The Fallback */
  background: rgba(255, 71, 88, 0.8); }

.danger.darken-3 {
  color: #FF3D4E !important; }

.bg-danger.bg-darken-3 {
  background-color: #FF3D4E !important; }

.btn-danger.btn-darken-3 {
  border-color: #FF4758 !important;
  background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-3:focus, .btn-danger.btn-darken-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-3 {
  border-color: #FF3D4E !important;
  color: #FF3D4E !important; }
  .btn-outline-danger.btn-outline-darken-3:hover {
    background-color: #FF3D4E !important; }

.progress-danger.progress-darken-3 {
  background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-webkit-progress-value {
    background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-moz-progress-bar {
    background-color: #FF3D4E; }
  .progress-danger.progress-darken-3[value]::-ms-fill {
    background-color: #FF3D4E; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-3 .progress-bar {
      background-color: #FF3D4E; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF3D4E !important; }

.border-danger.border-darken-3 {
  border: 1px solid #FF3D4E !important; }

.border-top-danger.border-top-darken-3 {
  border-top: 1px solid #FF3D4E !important; }

.border-bottom-danger.border-bottom-darken-3 {
  border-bottom: 1px solid #FF3D4E !important; }

[dir="ltr"] .border-left-danger.border-left-darken-3 {
  border-left: 1px solid #FF3D4E !important; }

[dir="ltr"] .border-right-danger.border-right-darken-3 {
  border-right: 1px solid #FF3D4E !important; }

[dir="rtl"] .border-left-danger.border-left-darken-3 {
  border-right: 1px solid #FF3D4E !important; }

[dir="rtl"] .border-right-danger.border-right-darken-3 {
  border-left: 1px solid #FF3D4E !important; }

.overlay-danger.overlay-darken-3 {
  background: #FF3D4E;
  /* The Fallback */
  background: rgba(255, 61, 78, 0.8); }

.danger.darken-4 {
  color: #FF2D3C !important; }

.bg-danger.bg-darken-4 {
  background-color: #FF2D3C !important; }

.btn-danger.btn-darken-4 {
  border-color: #FF4758 !important;
  background-color: #FF2D3C !important; }
  .btn-danger.btn-darken-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-darken-4:focus, .btn-danger.btn-darken-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-darken-4 {
  border-color: #FF2D3C !important;
  color: #FF2D3C !important; }
  .btn-outline-danger.btn-outline-darken-4:hover {
    background-color: #FF2D3C !important; }

.progress-danger.progress-darken-4 {
  background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-webkit-progress-value {
    background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-moz-progress-bar {
    background-color: #FF2D3C; }
  .progress-danger.progress-darken-4[value]::-ms-fill {
    background-color: #FF2D3C; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-darken-4 .progress-bar {
      background-color: #FF2D3C; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FF2D3C !important; }

.border-danger.border-darken-4 {
  border: 1px solid #FF2D3C !important; }

.border-top-danger.border-top-darken-4 {
  border-top: 1px solid #FF2D3C !important; }

.border-bottom-danger.border-bottom-darken-4 {
  border-bottom: 1px solid #FF2D3C !important; }

[dir="ltr"] .border-left-danger.border-left-darken-4 {
  border-left: 1px solid #FF2D3C !important; }

[dir="ltr"] .border-right-danger.border-right-darken-4 {
  border-right: 1px solid #FF2D3C !important; }

[dir="rtl"] .border-left-danger.border-left-darken-4 {
  border-right: 1px solid #FF2D3C !important; }

[dir="rtl"] .border-right-danger.border-right-darken-4 {
  border-left: 1px solid #FF2D3C !important; }

.overlay-danger.overlay-darken-4 {
  background: #FF2D3C;
  /* The Fallback */
  background: rgba(255, 45, 60, 0.8); }

.danger.accent-1 {
  color: #FFFFFF !important; }

.bg-danger.bg-accent-1 {
  background-color: #FFFFFF !important; }

.btn-danger.btn-accent-1 {
  border-color: #FF4758 !important;
  background-color: #FFFFFF !important; }
  .btn-danger.btn-accent-1:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-1:focus, .btn-danger.btn-accent-1:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-1 {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-danger.btn-outline-accent-1:hover {
    background-color: #FFFFFF !important; }

.progress-danger.progress-accent-1 {
  background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-danger.progress-accent-1[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-1 .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-danger.border-accent-1 {
  border: 1px solid #FFFFFF !important; }

.border-top-danger.border-top-accent-1 {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-danger.border-bottom-accent-1 {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-danger.border-left-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-danger.border-right-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-danger.border-left-accent-1 {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-danger.border-right-accent-1 {
  border-left: 1px solid #FFFFFF !important; }

.overlay-danger.overlay-accent-1 {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.danger.accent-2 {
  color: #FBEBED !important; }

.bg-danger.bg-accent-2 {
  background-color: #FBEBED !important; }

.btn-danger.btn-accent-2 {
  border-color: #FF4758 !important;
  background-color: #FBEBED !important; }
  .btn-danger.btn-accent-2:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-2:focus, .btn-danger.btn-accent-2:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-2 {
  border-color: #FBEBED !important;
  color: #FBEBED !important; }
  .btn-outline-danger.btn-outline-accent-2:hover {
    background-color: #FBEBED !important; }

.progress-danger.progress-accent-2 {
  background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-webkit-progress-value {
    background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-moz-progress-bar {
    background-color: #FBEBED; }
  .progress-danger.progress-accent-2[value]::-ms-fill {
    background-color: #FBEBED; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-2 .progress-bar {
      background-color: #FBEBED; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FBEBED !important; }

.border-danger.border-accent-2 {
  border: 1px solid #FBEBED !important; }

.border-top-danger.border-top-accent-2 {
  border-top: 1px solid #FBEBED !important; }

.border-bottom-danger.border-bottom-accent-2 {
  border-bottom: 1px solid #FBEBED !important; }

[dir="ltr"] .border-left-danger.border-left-accent-2 {
  border-left: 1px solid #FBEBED !important; }

[dir="ltr"] .border-right-danger.border-right-accent-2 {
  border-right: 1px solid #FBEBED !important; }

[dir="rtl"] .border-left-danger.border-left-accent-2 {
  border-right: 1px solid #FBEBED !important; }

[dir="rtl"] .border-right-danger.border-right-accent-2 {
  border-left: 1px solid #FBEBED !important; }

.overlay-danger.overlay-accent-2 {
  background: #FBEBED;
  /* The Fallback */
  background: rgba(251, 235, 237, 0.8); }

.danger.accent-3 {
  color: #FFD9DB !important; }

.bg-danger.bg-accent-3 {
  background-color: #FFD9DB !important; }

.btn-danger.btn-accent-3 {
  border-color: #FF4758 !important;
  background-color: #FFD9DB !important; }
  .btn-danger.btn-accent-3:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-3:focus, .btn-danger.btn-accent-3:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-3 {
  border-color: #FFD9DB !important;
  color: #FFD9DB !important; }
  .btn-outline-danger.btn-outline-accent-3:hover {
    background-color: #FFD9DB !important; }

.progress-danger.progress-accent-3 {
  background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-webkit-progress-value {
    background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-moz-progress-bar {
    background-color: #FFD9DB; }
  .progress-danger.progress-accent-3[value]::-ms-fill {
    background-color: #FFD9DB; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-3 .progress-bar {
      background-color: #FFD9DB; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFD9DB !important; }

.border-danger.border-accent-3 {
  border: 1px solid #FFD9DB !important; }

.border-top-danger.border-top-accent-3 {
  border-top: 1px solid #FFD9DB !important; }

.border-bottom-danger.border-bottom-accent-3 {
  border-bottom: 1px solid #FFD9DB !important; }

[dir="ltr"] .border-left-danger.border-left-accent-3 {
  border-left: 1px solid #FFD9DB !important; }

[dir="ltr"] .border-right-danger.border-right-accent-3 {
  border-right: 1px solid #FFD9DB !important; }

[dir="rtl"] .border-left-danger.border-left-accent-3 {
  border-right: 1px solid #FFD9DB !important; }

[dir="rtl"] .border-right-danger.border-right-accent-3 {
  border-left: 1px solid #FFD9DB !important; }

.overlay-danger.overlay-accent-3 {
  background: #FFD9DB;
  /* The Fallback */
  background: rgba(255, 217, 219, 0.8); }

.danger.accent-4 {
  color: #FFBFC3 !important; }

.bg-danger.bg-accent-4 {
  background-color: #FFBFC3 !important; }

.btn-danger.btn-accent-4 {
  border-color: #FF4758 !important;
  background-color: #FFBFC3 !important; }
  .btn-danger.btn-accent-4:hover {
    border-color: #FF4758 !important;
    background-color: #FF3D4E !important; }
  .btn-danger.btn-accent-4:focus, .btn-danger.btn-accent-4:active {
    border-color: #FF3D4E !important;
    background-color: #FF2D3C !important; }

.btn-outline-danger.btn-outline-accent-4 {
  border-color: #FFBFC3 !important;
  color: #FFBFC3 !important; }
  .btn-outline-danger.btn-outline-accent-4:hover {
    background-color: #FFBFC3 !important; }

.progress-danger.progress-accent-4 {
  background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-webkit-progress-value {
    background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-moz-progress-bar {
    background-color: #FFBFC3; }
  .progress-danger.progress-accent-4[value]::-ms-fill {
    background-color: #FFBFC3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-danger.progress-accent-4 .progress-bar {
      background-color: #FFBFC3; } }

input:focus ~ .bg-danger {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFBFC3 !important; }

.border-danger.border-accent-4 {
  border: 1px solid #FFBFC3 !important; }

.border-top-danger.border-top-accent-4 {
  border-top: 1px solid #FFBFC3 !important; }

.border-bottom-danger.border-bottom-accent-4 {
  border-bottom: 1px solid #FFBFC3 !important; }

[dir="ltr"] .border-left-danger.border-left-accent-4 {
  border-left: 1px solid #FFBFC3 !important; }

[dir="ltr"] .border-right-danger.border-right-accent-4 {
  border-right: 1px solid #FFBFC3 !important; }

[dir="rtl"] .border-left-danger.border-left-accent-4 {
  border-right: 1px solid #FFBFC3 !important; }

[dir="rtl"] .border-right-danger.border-right-accent-4 {
  border-left: 1px solid #FFBFC3 !important; }

.overlay-danger.overlay-accent-4 {
  background: #FFBFC3;
  /* The Fallback */
  background: rgba(255, 191, 195, 0.8); }

.red.lighten-5 {
  color: #ffebee !important; }

.bg-red.bg-lighten-5 {
  background-color: #ffebee !important; }

.btn-red.btn-lighten-5 {
  border-color: #d32f2f !important;
  background-color: #ffebee !important; }
  .btn-red.btn-lighten-5:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-5:focus, .btn-red.btn-lighten-5:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-5 {
  border-color: #ffebee !important;
  color: #ffebee !important; }
  .btn-outline-red.btn-outline-lighten-5:hover {
    background-color: #ffebee !important; }

.progress-red.progress-lighten-5 {
  background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ffebee; }
  .progress-red.progress-lighten-5[value]::-ms-fill {
    background-color: #ffebee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-5 .progress-bar {
      background-color: #ffebee; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffebee !important; }

.border-red.border-lighten-5 {
  border: 1px solid #ffebee !important; }

.border-top-red.border-top-lighten-5 {
  border-top: 1px solid #ffebee !important; }

.border-bottom-red.border-bottom-lighten-5 {
  border-bottom: 1px solid #ffebee !important; }

[dir="ltr"] .border-left-red.border-left-lighten-5 {
  border-left: 1px solid #ffebee !important; }

[dir="ltr"] .border-right-red.border-right-lighten-5 {
  border-right: 1px solid #ffebee !important; }

[dir="rtl"] .border-left-red.border-left-lighten-5 {
  border-right: 1px solid #ffebee !important; }

[dir="rtl"] .border-right-red.border-right-lighten-5 {
  border-left: 1px solid #ffebee !important; }

.overlay-red.overlay-lighten-5 {
  background: #ffebee;
  /* The Fallback */
  background: rgba(255, 235, 238, 0.8); }

.red.lighten-4 {
  color: #ffcdd2 !important; }

.bg-red.bg-lighten-4 {
  background-color: #ffcdd2 !important; }

.btn-red.btn-lighten-4 {
  border-color: #d32f2f !important;
  background-color: #ffcdd2 !important; }
  .btn-red.btn-lighten-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-4:focus, .btn-red.btn-lighten-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-4 {
  border-color: #ffcdd2 !important;
  color: #ffcdd2 !important; }
  .btn-outline-red.btn-outline-lighten-4:hover {
    background-color: #ffcdd2 !important; }

.progress-red.progress-lighten-4 {
  background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffcdd2; }
  .progress-red.progress-lighten-4[value]::-ms-fill {
    background-color: #ffcdd2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-4 .progress-bar {
      background-color: #ffcdd2; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcdd2 !important; }

.border-red.border-lighten-4 {
  border: 1px solid #ffcdd2 !important; }

.border-top-red.border-top-lighten-4 {
  border-top: 1px solid #ffcdd2 !important; }

.border-bottom-red.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffcdd2 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-4 {
  border-left: 1px solid #ffcdd2 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-4 {
  border-right: 1px solid #ffcdd2 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-4 {
  border-right: 1px solid #ffcdd2 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-4 {
  border-left: 1px solid #ffcdd2 !important; }

.overlay-red.overlay-lighten-4 {
  background: #ffcdd2;
  /* The Fallback */
  background: rgba(255, 205, 210, 0.8); }

.red.lighten-3 {
  color: #ef9a9a !important; }

.bg-red.bg-lighten-3 {
  background-color: #ef9a9a !important; }

.btn-red.btn-lighten-3 {
  border-color: #d32f2f !important;
  background-color: #ef9a9a !important; }
  .btn-red.btn-lighten-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-3:focus, .btn-red.btn-lighten-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-3 {
  border-color: #ef9a9a !important;
  color: #ef9a9a !important; }
  .btn-outline-red.btn-outline-lighten-3:hover {
    background-color: #ef9a9a !important; }

.progress-red.progress-lighten-3 {
  background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ef9a9a; }
  .progress-red.progress-lighten-3[value]::-ms-fill {
    background-color: #ef9a9a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-3 .progress-bar {
      background-color: #ef9a9a; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef9a9a !important; }

.border-red.border-lighten-3 {
  border: 1px solid #ef9a9a !important; }

.border-top-red.border-top-lighten-3 {
  border-top: 1px solid #ef9a9a !important; }

.border-bottom-red.border-bottom-lighten-3 {
  border-bottom: 1px solid #ef9a9a !important; }

[dir="ltr"] .border-left-red.border-left-lighten-3 {
  border-left: 1px solid #ef9a9a !important; }

[dir="ltr"] .border-right-red.border-right-lighten-3 {
  border-right: 1px solid #ef9a9a !important; }

[dir="rtl"] .border-left-red.border-left-lighten-3 {
  border-right: 1px solid #ef9a9a !important; }

[dir="rtl"] .border-right-red.border-right-lighten-3 {
  border-left: 1px solid #ef9a9a !important; }

.overlay-red.overlay-lighten-3 {
  background: #ef9a9a;
  /* The Fallback */
  background: rgba(239, 154, 154, 0.8); }

.red.lighten-2 {
  color: #e57373 !important; }

.bg-red.bg-lighten-2 {
  background-color: #e57373 !important; }

.btn-red.btn-lighten-2 {
  border-color: #d32f2f !important;
  background-color: #e57373 !important; }
  .btn-red.btn-lighten-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-2:focus, .btn-red.btn-lighten-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-2 {
  border-color: #e57373 !important;
  color: #e57373 !important; }
  .btn-outline-red.btn-outline-lighten-2:hover {
    background-color: #e57373 !important; }

.progress-red.progress-lighten-2 {
  background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e57373; }
  .progress-red.progress-lighten-2[value]::-ms-fill {
    background-color: #e57373; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-2 .progress-bar {
      background-color: #e57373; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e57373 !important; }

.border-red.border-lighten-2 {
  border: 1px solid #e57373 !important; }

.border-top-red.border-top-lighten-2 {
  border-top: 1px solid #e57373 !important; }

.border-bottom-red.border-bottom-lighten-2 {
  border-bottom: 1px solid #e57373 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-2 {
  border-left: 1px solid #e57373 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-2 {
  border-right: 1px solid #e57373 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-2 {
  border-right: 1px solid #e57373 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-2 {
  border-left: 1px solid #e57373 !important; }

.overlay-red.overlay-lighten-2 {
  background: #e57373;
  /* The Fallback */
  background: rgba(229, 115, 115, 0.8); }

.red.lighten-1 {
  color: #ef5350 !important; }

.bg-red.bg-lighten-1 {
  background-color: #ef5350 !important; }

.btn-red.btn-lighten-1 {
  border-color: #d32f2f !important;
  background-color: #ef5350 !important; }
  .btn-red.btn-lighten-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-lighten-1:focus, .btn-red.btn-lighten-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-lighten-1 {
  border-color: #ef5350 !important;
  color: #ef5350 !important; }
  .btn-outline-red.btn-outline-lighten-1:hover {
    background-color: #ef5350 !important; }

.progress-red.progress-lighten-1 {
  background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ef5350; }
  .progress-red.progress-lighten-1[value]::-ms-fill {
    background-color: #ef5350; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-lighten-1 .progress-bar {
      background-color: #ef5350; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef5350 !important; }

.border-red.border-lighten-1 {
  border: 1px solid #ef5350 !important; }

.border-top-red.border-top-lighten-1 {
  border-top: 1px solid #ef5350 !important; }

.border-bottom-red.border-bottom-lighten-1 {
  border-bottom: 1px solid #ef5350 !important; }

[dir="ltr"] .border-left-red.border-left-lighten-1 {
  border-left: 1px solid #ef5350 !important; }

[dir="ltr"] .border-right-red.border-right-lighten-1 {
  border-right: 1px solid #ef5350 !important; }

[dir="rtl"] .border-left-red.border-left-lighten-1 {
  border-right: 1px solid #ef5350 !important; }

[dir="rtl"] .border-right-red.border-right-lighten-1 {
  border-left: 1px solid #ef5350 !important; }

.overlay-red.overlay-lighten-1 {
  background: #ef5350;
  /* The Fallback */
  background: rgba(239, 83, 80, 0.8); }

.red {
  color: #f44336 !important; }

.bg-red {
  background-color: #f44336 !important; }
  .bg-red .card-header, .bg-red .card-footer {
    background-color: transparent; }

.toast-red {
  background-color: #f44336; }

.alert-red {
  border-color: #f44336 !important;
  background-color: #f66358 !important;
  color: #710e06 !important; }
  .alert-red .alert-link {
    color: #4b0904 !important; }

.border-red {
  border-color: #f44336; }

.border-top-red {
  border-top-color: #f44336; }

.border-bottom-red {
  border-bottom-color: #f44336; }

[dir="ltr"] .border-left-red {
  border-left-color: #f44336; }

[dir="ltr"] .border-right-red {
  border-right-color: #f44336; }

[dir="rtl"] .border-left-red {
  border-right-color: #f44336; }

[dir="rtl"] .border-right-red {
  border-left-color: #f44336; }

.badge-red {
  background-color: #f44336; }

.panel-red {
  border-color: #f44336; }
  .panel-red .panel-heading {
    color: #FFF;
    border-color: #f44336;
    background-color: #f55a4e; }

.bg-red.tag-glow, .border-red.tag-glow {
  box-shadow: 0px 0px 10px #f44336; }

.overlay-red {
  background: #f44336;
  /* The Fallback */
  background: rgba(244, 67, 54, 0.8); }

.card.card-outline-red {
  border-width: 1px;
  border-style: solid;
  border-color: #f44336;
  background-color: transparent; }
  .card.card-outline-red .card-header, .card.card-outline-red .card-footer {
    background-color: transparent; }

.btn-red.btn-flat {
  background-color: transparent !important;
  color: #f44336;
  border: none; }

.btn-red.btn-raised, .btn-red.btn-fab {
  background-color: #f44336 !important;
  color: #fff !important;
  border-color: #f44336; }
  .btn-red.btn-raised.active, .btn-red.btn-fab.active {
    background-color: #f22314 !important;
    border-color: #f22314 !important; }

.btn-group-raised .btn-red {
  background-color: #f44336 !important;
  color: #fff !important; }

.btn-outline-red {
  border: 1px solid;
  border-color: #f44336;
  background-color: transparent;
  color: #f44336;
  box-shadow: none !important; }
  .btn-outline-red:focus {
    background-color: transparent !important;
    color: #f44336 !important;
    box-shadow: transparent !important; }
  .btn-outline-red.active {
    background-color: #f44336 !important;
    color: #FFF !important; }
  .btn-outline-red:hover {
    background-color: #f33527 !important;
    color: #FFF !important; }
    .btn-outline-red:hover svg {
      color: #FFF !important; }

.btn-outline-red.btn-raised, .btn-outline-red.btn-fab {
  border: 1px solid;
  border-color: #f44336;
  background-color: transparent;
  color: #f44336;
  box-shadow: none !important; }
  .btn-outline-red.btn-raised:focus, .btn-outline-red.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-red.btn-raised.active, .btn-outline-red.btn-fab.active {
    background-color: #f44336 !important;
    color: #FFF !important; }
  .btn-outline-red.btn-raised:hover, .btn-outline-red.btn-fab:hover {
    background-color: #f33527 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-red {
  background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-webkit-progress-value {
    background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-moz-progress-bar {
    background-color: #f44336; }
  .progress .progress-bar.progress-bar-red[value]::-ms-fill {
    background-color: #f44336; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-red .progress-bar {
      background-color: #f44336; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f44336 !important; }

.red.darken-1 {
  color: #e53935 !important; }

.bg-red.bg-darken-1 {
  background-color: #e53935 !important; }

.btn-red.btn-darken-1 {
  border-color: #d32f2f !important;
  background-color: #e53935 !important; }
  .btn-red.btn-darken-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-1:focus, .btn-red.btn-darken-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-1 {
  border-color: #e53935 !important;
  color: #e53935 !important; }
  .btn-outline-red.btn-outline-darken-1:hover {
    background-color: #e53935 !important; }

.progress-red.progress-darken-1 {
  background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-webkit-progress-value {
    background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-moz-progress-bar {
    background-color: #e53935; }
  .progress-red.progress-darken-1[value]::-ms-fill {
    background-color: #e53935; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-1 .progress-bar {
      background-color: #e53935; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e53935 !important; }

.border-red.border-darken-1 {
  border: 1px solid #e53935 !important; }

.border-top-red.border-top-darken-1 {
  border-top: 1px solid #e53935 !important; }

.border-bottom-red.border-bottom-darken-1 {
  border-bottom: 1px solid #e53935 !important; }

[dir="ltr"] .border-left-red.border-left-darken-1 {
  border-left: 1px solid #e53935 !important; }

[dir="ltr"] .border-right-red.border-right-darken-1 {
  border-right: 1px solid #e53935 !important; }

[dir="rtl"] .border-left-red.border-left-darken-1 {
  border-right: 1px solid #e53935 !important; }

[dir="rtl"] .border-right-red.border-right-darken-1 {
  border-left: 1px solid #e53935 !important; }

.overlay-red.overlay-darken-1 {
  background: #e53935;
  /* The Fallback */
  background: rgba(229, 57, 53, 0.8); }

.red.darken-2 {
  color: #d32f2f !important; }

.bg-red.bg-darken-2 {
  background-color: #d32f2f !important; }

.btn-red.btn-darken-2 {
  border-color: #d32f2f !important;
  background-color: #d32f2f !important; }
  .btn-red.btn-darken-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-2:focus, .btn-red.btn-darken-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-2 {
  border-color: #d32f2f !important;
  color: #d32f2f !important; }
  .btn-outline-red.btn-outline-darken-2:hover {
    background-color: #d32f2f !important; }

.progress-red.progress-darken-2 {
  background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-webkit-progress-value {
    background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-moz-progress-bar {
    background-color: #d32f2f; }
  .progress-red.progress-darken-2[value]::-ms-fill {
    background-color: #d32f2f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-2 .progress-bar {
      background-color: #d32f2f; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d32f2f !important; }

.border-red.border-darken-2 {
  border: 1px solid #d32f2f !important; }

.border-top-red.border-top-darken-2 {
  border-top: 1px solid #d32f2f !important; }

.border-bottom-red.border-bottom-darken-2 {
  border-bottom: 1px solid #d32f2f !important; }

[dir="ltr"] .border-left-red.border-left-darken-2 {
  border-left: 1px solid #d32f2f !important; }

[dir="ltr"] .border-right-red.border-right-darken-2 {
  border-right: 1px solid #d32f2f !important; }

[dir="rtl"] .border-left-red.border-left-darken-2 {
  border-right: 1px solid #d32f2f !important; }

[dir="rtl"] .border-right-red.border-right-darken-2 {
  border-left: 1px solid #d32f2f !important; }

.overlay-red.overlay-darken-2 {
  background: #d32f2f;
  /* The Fallback */
  background: rgba(211, 47, 47, 0.8); }

.red.darken-3 {
  color: #c62828 !important; }

.bg-red.bg-darken-3 {
  background-color: #c62828 !important; }

.btn-red.btn-darken-3 {
  border-color: #d32f2f !important;
  background-color: #c62828 !important; }
  .btn-red.btn-darken-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-3:focus, .btn-red.btn-darken-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-3 {
  border-color: #c62828 !important;
  color: #c62828 !important; }
  .btn-outline-red.btn-outline-darken-3:hover {
    background-color: #c62828 !important; }

.progress-red.progress-darken-3 {
  background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-webkit-progress-value {
    background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-moz-progress-bar {
    background-color: #c62828; }
  .progress-red.progress-darken-3[value]::-ms-fill {
    background-color: #c62828; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-3 .progress-bar {
      background-color: #c62828; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c62828 !important; }

.border-red.border-darken-3 {
  border: 1px solid #c62828 !important; }

.border-top-red.border-top-darken-3 {
  border-top: 1px solid #c62828 !important; }

.border-bottom-red.border-bottom-darken-3 {
  border-bottom: 1px solid #c62828 !important; }

[dir="ltr"] .border-left-red.border-left-darken-3 {
  border-left: 1px solid #c62828 !important; }

[dir="ltr"] .border-right-red.border-right-darken-3 {
  border-right: 1px solid #c62828 !important; }

[dir="rtl"] .border-left-red.border-left-darken-3 {
  border-right: 1px solid #c62828 !important; }

[dir="rtl"] .border-right-red.border-right-darken-3 {
  border-left: 1px solid #c62828 !important; }

.overlay-red.overlay-darken-3 {
  background: #c62828;
  /* The Fallback */
  background: rgba(198, 40, 40, 0.8); }

.red.darken-4 {
  color: #b71c1c !important; }

.bg-red.bg-darken-4 {
  background-color: #b71c1c !important; }

.btn-red.btn-darken-4 {
  border-color: #d32f2f !important;
  background-color: #b71c1c !important; }
  .btn-red.btn-darken-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-darken-4:focus, .btn-red.btn-darken-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-darken-4 {
  border-color: #b71c1c !important;
  color: #b71c1c !important; }
  .btn-outline-red.btn-outline-darken-4:hover {
    background-color: #b71c1c !important; }

.progress-red.progress-darken-4 {
  background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-webkit-progress-value {
    background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-moz-progress-bar {
    background-color: #b71c1c; }
  .progress-red.progress-darken-4[value]::-ms-fill {
    background-color: #b71c1c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-darken-4 .progress-bar {
      background-color: #b71c1c; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b71c1c !important; }

.border-red.border-darken-4 {
  border: 1px solid #b71c1c !important; }

.border-top-red.border-top-darken-4 {
  border-top: 1px solid #b71c1c !important; }

.border-bottom-red.border-bottom-darken-4 {
  border-bottom: 1px solid #b71c1c !important; }

[dir="ltr"] .border-left-red.border-left-darken-4 {
  border-left: 1px solid #b71c1c !important; }

[dir="ltr"] .border-right-red.border-right-darken-4 {
  border-right: 1px solid #b71c1c !important; }

[dir="rtl"] .border-left-red.border-left-darken-4 {
  border-right: 1px solid #b71c1c !important; }

[dir="rtl"] .border-right-red.border-right-darken-4 {
  border-left: 1px solid #b71c1c !important; }

.overlay-red.overlay-darken-4 {
  background: #b71c1c;
  /* The Fallback */
  background: rgba(183, 28, 28, 0.8); }

.red.accent-1 {
  color: #ff8a80 !important; }

.bg-red.bg-accent-1 {
  background-color: #ff8a80 !important; }

.btn-red.btn-accent-1 {
  border-color: #d32f2f !important;
  background-color: #ff8a80 !important; }
  .btn-red.btn-accent-1:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-1:focus, .btn-red.btn-accent-1:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-1 {
  border-color: #ff8a80 !important;
  color: #ff8a80 !important; }
  .btn-outline-red.btn-outline-accent-1:hover {
    background-color: #ff8a80 !important; }

.progress-red.progress-accent-1 {
  background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff8a80; }
  .progress-red.progress-accent-1[value]::-ms-fill {
    background-color: #ff8a80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-1 .progress-bar {
      background-color: #ff8a80; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a80 !important; }

.border-red.border-accent-1 {
  border: 1px solid #ff8a80 !important; }

.border-top-red.border-top-accent-1 {
  border-top: 1px solid #ff8a80 !important; }

.border-bottom-red.border-bottom-accent-1 {
  border-bottom: 1px solid #ff8a80 !important; }

[dir="ltr"] .border-left-red.border-left-accent-1 {
  border-left: 1px solid #ff8a80 !important; }

[dir="ltr"] .border-right-red.border-right-accent-1 {
  border-right: 1px solid #ff8a80 !important; }

[dir="rtl"] .border-left-red.border-left-accent-1 {
  border-right: 1px solid #ff8a80 !important; }

[dir="rtl"] .border-right-red.border-right-accent-1 {
  border-left: 1px solid #ff8a80 !important; }

.overlay-red.overlay-accent-1 {
  background: #ff8a80;
  /* The Fallback */
  background: rgba(255, 138, 128, 0.8); }

.red.accent-2 {
  color: #ff5252 !important; }

.bg-red.bg-accent-2 {
  background-color: #ff5252 !important; }

.btn-red.btn-accent-2 {
  border-color: #d32f2f !important;
  background-color: #ff5252 !important; }
  .btn-red.btn-accent-2:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-2:focus, .btn-red.btn-accent-2:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-2 {
  border-color: #ff5252 !important;
  color: #ff5252 !important; }
  .btn-outline-red.btn-outline-accent-2:hover {
    background-color: #ff5252 !important; }

.progress-red.progress-accent-2 {
  background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff5252; }
  .progress-red.progress-accent-2[value]::-ms-fill {
    background-color: #ff5252; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-2 .progress-bar {
      background-color: #ff5252; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5252 !important; }

.border-red.border-accent-2 {
  border: 1px solid #ff5252 !important; }

.border-top-red.border-top-accent-2 {
  border-top: 1px solid #ff5252 !important; }

.border-bottom-red.border-bottom-accent-2 {
  border-bottom: 1px solid #ff5252 !important; }

[dir="ltr"] .border-left-red.border-left-accent-2 {
  border-left: 1px solid #ff5252 !important; }

[dir="ltr"] .border-right-red.border-right-accent-2 {
  border-right: 1px solid #ff5252 !important; }

[dir="rtl"] .border-left-red.border-left-accent-2 {
  border-right: 1px solid #ff5252 !important; }

[dir="rtl"] .border-right-red.border-right-accent-2 {
  border-left: 1px solid #ff5252 !important; }

.overlay-red.overlay-accent-2 {
  background: #ff5252;
  /* The Fallback */
  background: rgba(255, 82, 82, 0.8); }

.red.accent-3 {
  color: #ff1744 !important; }

.bg-red.bg-accent-3 {
  background-color: #ff1744 !important; }

.btn-red.btn-accent-3 {
  border-color: #d32f2f !important;
  background-color: #ff1744 !important; }
  .btn-red.btn-accent-3:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-3:focus, .btn-red.btn-accent-3:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-3 {
  border-color: #ff1744 !important;
  color: #ff1744 !important; }
  .btn-outline-red.btn-outline-accent-3:hover {
    background-color: #ff1744 !important; }

.progress-red.progress-accent-3 {
  background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff1744; }
  .progress-red.progress-accent-3[value]::-ms-fill {
    background-color: #ff1744; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-3 .progress-bar {
      background-color: #ff1744; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff1744 !important; }

.border-red.border-accent-3 {
  border: 1px solid #ff1744 !important; }

.border-top-red.border-top-accent-3 {
  border-top: 1px solid #ff1744 !important; }

.border-bottom-red.border-bottom-accent-3 {
  border-bottom: 1px solid #ff1744 !important; }

[dir="ltr"] .border-left-red.border-left-accent-3 {
  border-left: 1px solid #ff1744 !important; }

[dir="ltr"] .border-right-red.border-right-accent-3 {
  border-right: 1px solid #ff1744 !important; }

[dir="rtl"] .border-left-red.border-left-accent-3 {
  border-right: 1px solid #ff1744 !important; }

[dir="rtl"] .border-right-red.border-right-accent-3 {
  border-left: 1px solid #ff1744 !important; }

.overlay-red.overlay-accent-3 {
  background: #ff1744;
  /* The Fallback */
  background: rgba(255, 23, 68, 0.8); }

.red.accent-4 {
  color: #d50000 !important; }

.bg-red.bg-accent-4 {
  background-color: #d50000 !important; }

.btn-red.btn-accent-4 {
  border-color: #d32f2f !important;
  background-color: #d50000 !important; }
  .btn-red.btn-accent-4:hover {
    border-color: #d32f2f !important;
    background-color: #c62828 !important; }
  .btn-red.btn-accent-4:focus, .btn-red.btn-accent-4:active {
    border-color: #c62828 !important;
    background-color: #b71c1c !important; }

.btn-outline-red.btn-outline-accent-4 {
  border-color: #d50000 !important;
  color: #d50000 !important; }
  .btn-outline-red.btn-outline-accent-4:hover {
    background-color: #d50000 !important; }

.progress-red.progress-accent-4 {
  background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-webkit-progress-value {
    background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-moz-progress-bar {
    background-color: #d50000; }
  .progress-red.progress-accent-4[value]::-ms-fill {
    background-color: #d50000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-red.progress-accent-4 .progress-bar {
      background-color: #d50000; } }

input:focus ~ .bg-red {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d50000 !important; }

.border-red.border-accent-4 {
  border: 1px solid #d50000 !important; }

.border-top-red.border-top-accent-4 {
  border-top: 1px solid #d50000 !important; }

.border-bottom-red.border-bottom-accent-4 {
  border-bottom: 1px solid #d50000 !important; }

[dir="ltr"] .border-left-red.border-left-accent-4 {
  border-left: 1px solid #d50000 !important; }

[dir="ltr"] .border-right-red.border-right-accent-4 {
  border-right: 1px solid #d50000 !important; }

[dir="rtl"] .border-left-red.border-left-accent-4 {
  border-right: 1px solid #d50000 !important; }

[dir="rtl"] .border-right-red.border-right-accent-4 {
  border-left: 1px solid #d50000 !important; }

.overlay-red.overlay-accent-4 {
  background: #d50000;
  /* The Fallback */
  background: rgba(213, 0, 0, 0.8); }

.pink.lighten-5 {
  color: #fce4ec !important; }

.bg-pink.bg-lighten-5 {
  background-color: #fce4ec !important; }

.btn-pink.btn-lighten-5 {
  border-color: #c2185b !important;
  background-color: #fce4ec !important; }
  .btn-pink.btn-lighten-5:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-5:focus, .btn-pink.btn-lighten-5:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-5 {
  border-color: #fce4ec !important;
  color: #fce4ec !important; }
  .btn-outline-pink.btn-outline-lighten-5:hover {
    background-color: #fce4ec !important; }

.progress-pink.progress-lighten-5 {
  background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fce4ec; }
  .progress-pink.progress-lighten-5[value]::-ms-fill {
    background-color: #fce4ec; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-5 .progress-bar {
      background-color: #fce4ec; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fce4ec !important; }

.border-pink.border-lighten-5 {
  border: 1px solid #fce4ec !important; }

.border-top-pink.border-top-lighten-5 {
  border-top: 1px solid #fce4ec !important; }

.border-bottom-pink.border-bottom-lighten-5 {
  border-bottom: 1px solid #fce4ec !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-5 {
  border-left: 1px solid #fce4ec !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-5 {
  border-right: 1px solid #fce4ec !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-5 {
  border-right: 1px solid #fce4ec !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-5 {
  border-left: 1px solid #fce4ec !important; }

.overlay-pink.overlay-lighten-5 {
  background: #fce4ec;
  /* The Fallback */
  background: rgba(252, 228, 236, 0.8); }

.pink.lighten-4 {
  color: #f8bbd0 !important; }

.bg-pink.bg-lighten-4 {
  background-color: #f8bbd0 !important; }

.btn-pink.btn-lighten-4 {
  border-color: #c2185b !important;
  background-color: #f8bbd0 !important; }
  .btn-pink.btn-lighten-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-4:focus, .btn-pink.btn-lighten-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-4 {
  border-color: #f8bbd0 !important;
  color: #f8bbd0 !important; }
  .btn-outline-pink.btn-outline-lighten-4:hover {
    background-color: #f8bbd0 !important; }

.progress-pink.progress-lighten-4 {
  background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f8bbd0; }
  .progress-pink.progress-lighten-4[value]::-ms-fill {
    background-color: #f8bbd0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-4 .progress-bar {
      background-color: #f8bbd0; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f8bbd0 !important; }

.border-pink.border-lighten-4 {
  border: 1px solid #f8bbd0 !important; }

.border-top-pink.border-top-lighten-4 {
  border-top: 1px solid #f8bbd0 !important; }

.border-bottom-pink.border-bottom-lighten-4 {
  border-bottom: 1px solid #f8bbd0 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-4 {
  border-left: 1px solid #f8bbd0 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-4 {
  border-right: 1px solid #f8bbd0 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-4 {
  border-right: 1px solid #f8bbd0 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-4 {
  border-left: 1px solid #f8bbd0 !important; }

.overlay-pink.overlay-lighten-4 {
  background: #f8bbd0;
  /* The Fallback */
  background: rgba(248, 187, 208, 0.8); }

.pink.lighten-3 {
  color: #f48fb1 !important; }

.bg-pink.bg-lighten-3 {
  background-color: #f48fb1 !important; }

.btn-pink.btn-lighten-3 {
  border-color: #c2185b !important;
  background-color: #f48fb1 !important; }
  .btn-pink.btn-lighten-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-3:focus, .btn-pink.btn-lighten-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-3 {
  border-color: #f48fb1 !important;
  color: #f48fb1 !important; }
  .btn-outline-pink.btn-outline-lighten-3:hover {
    background-color: #f48fb1 !important; }

.progress-pink.progress-lighten-3 {
  background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #f48fb1; }
  .progress-pink.progress-lighten-3[value]::-ms-fill {
    background-color: #f48fb1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-3 .progress-bar {
      background-color: #f48fb1; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f48fb1 !important; }

.border-pink.border-lighten-3 {
  border: 1px solid #f48fb1 !important; }

.border-top-pink.border-top-lighten-3 {
  border-top: 1px solid #f48fb1 !important; }

.border-bottom-pink.border-bottom-lighten-3 {
  border-bottom: 1px solid #f48fb1 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-3 {
  border-left: 1px solid #f48fb1 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-3 {
  border-right: 1px solid #f48fb1 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-3 {
  border-right: 1px solid #f48fb1 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-3 {
  border-left: 1px solid #f48fb1 !important; }

.overlay-pink.overlay-lighten-3 {
  background: #f48fb1;
  /* The Fallback */
  background: rgba(244, 143, 177, 0.8); }

.pink.lighten-2 {
  color: #f06292 !important; }

.bg-pink.bg-lighten-2 {
  background-color: #f06292 !important; }

.btn-pink.btn-lighten-2 {
  border-color: #c2185b !important;
  background-color: #f06292 !important; }
  .btn-pink.btn-lighten-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-2:focus, .btn-pink.btn-lighten-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-2 {
  border-color: #f06292 !important;
  color: #f06292 !important; }
  .btn-outline-pink.btn-outline-lighten-2:hover {
    background-color: #f06292 !important; }

.progress-pink.progress-lighten-2 {
  background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #f06292; }
  .progress-pink.progress-lighten-2[value]::-ms-fill {
    background-color: #f06292; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-2 .progress-bar {
      background-color: #f06292; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f06292 !important; }

.border-pink.border-lighten-2 {
  border: 1px solid #f06292 !important; }

.border-top-pink.border-top-lighten-2 {
  border-top: 1px solid #f06292 !important; }

.border-bottom-pink.border-bottom-lighten-2 {
  border-bottom: 1px solid #f06292 !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-2 {
  border-left: 1px solid #f06292 !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-2 {
  border-right: 1px solid #f06292 !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-2 {
  border-right: 1px solid #f06292 !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-2 {
  border-left: 1px solid #f06292 !important; }

.overlay-pink.overlay-lighten-2 {
  background: #f06292;
  /* The Fallback */
  background: rgba(240, 98, 146, 0.8); }

.pink.lighten-1 {
  color: #ec407a !important; }

.bg-pink.bg-lighten-1 {
  background-color: #ec407a !important; }

.btn-pink.btn-lighten-1 {
  border-color: #c2185b !important;
  background-color: #ec407a !important; }
  .btn-pink.btn-lighten-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-lighten-1:focus, .btn-pink.btn-lighten-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-lighten-1 {
  border-color: #ec407a !important;
  color: #ec407a !important; }
  .btn-outline-pink.btn-outline-lighten-1:hover {
    background-color: #ec407a !important; }

.progress-pink.progress-lighten-1 {
  background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ec407a; }
  .progress-pink.progress-lighten-1[value]::-ms-fill {
    background-color: #ec407a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-lighten-1 .progress-bar {
      background-color: #ec407a; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ec407a !important; }

.border-pink.border-lighten-1 {
  border: 1px solid #ec407a !important; }

.border-top-pink.border-top-lighten-1 {
  border-top: 1px solid #ec407a !important; }

.border-bottom-pink.border-bottom-lighten-1 {
  border-bottom: 1px solid #ec407a !important; }

[dir="ltr"] .border-left-pink.border-left-lighten-1 {
  border-left: 1px solid #ec407a !important; }

[dir="ltr"] .border-right-pink.border-right-lighten-1 {
  border-right: 1px solid #ec407a !important; }

[dir="rtl"] .border-left-pink.border-left-lighten-1 {
  border-right: 1px solid #ec407a !important; }

[dir="rtl"] .border-right-pink.border-right-lighten-1 {
  border-left: 1px solid #ec407a !important; }

.overlay-pink.overlay-lighten-1 {
  background: #ec407a;
  /* The Fallback */
  background: rgba(236, 64, 122, 0.8); }

.pink {
  color: #e91e63 !important; }

.bg-pink {
  background-color: #e91e63 !important; }
  .bg-pink .card-header, .bg-pink .card-footer {
    background-color: transparent; }

.toast-pink {
  background-color: #e91e63; }

.alert-pink {
  border-color: #e91e63 !important;
  background-color: #ec3f7a !important;
  color: #4d081f !important; }
  .alert-pink .alert-link {
    color: #280410 !important; }

.border-pink {
  border-color: #e91e63; }

.border-top-pink {
  border-top-color: #e91e63; }

.border-bottom-pink {
  border-bottom-color: #e91e63; }

[dir="ltr"] .border-left-pink {
  border-left-color: #e91e63; }

[dir="ltr"] .border-right-pink {
  border-right-color: #e91e63; }

[dir="rtl"] .border-left-pink {
  border-right-color: #e91e63; }

[dir="rtl"] .border-right-pink {
  border-left-color: #e91e63; }

.badge-pink {
  background-color: #e91e63; }

.panel-pink {
  border-color: #e91e63; }
  .panel-pink .panel-heading {
    color: #FFF;
    border-color: #e91e63;
    background-color: #eb3573; }

.bg-pink.tag-glow, .border-pink.tag-glow {
  box-shadow: 0px 0px 10px #e91e63; }

.overlay-pink {
  background: #e91e63;
  /* The Fallback */
  background: rgba(233, 30, 99, 0.8); }

.card.card-outline-pink {
  border-width: 1px;
  border-style: solid;
  border-color: #e91e63;
  background-color: transparent; }
  .card.card-outline-pink .card-header, .card.card-outline-pink .card-footer {
    background-color: transparent; }

.btn-pink.btn-flat {
  background-color: transparent !important;
  color: #e91e63;
  border: none; }

.btn-pink.btn-raised, .btn-pink.btn-fab {
  background-color: #e91e63 !important;
  color: #fff !important;
  border-color: #e91e63; }
  .btn-pink.btn-raised.active, .btn-pink.btn-fab.active {
    background-color: #cf1454 !important;
    border-color: #cf1454 !important; }

.btn-group-raised .btn-pink {
  background-color: #e91e63 !important;
  color: #fff !important; }

.btn-outline-pink {
  border: 1px solid;
  border-color: #e91e63;
  background-color: transparent;
  color: #e91e63;
  box-shadow: none !important; }
  .btn-outline-pink:focus {
    background-color: transparent !important;
    color: #e91e63 !important;
    box-shadow: transparent !important; }
  .btn-outline-pink.active {
    background-color: #e91e63 !important;
    color: #FFF !important; }
  .btn-outline-pink:hover {
    background-color: #e2165b !important;
    color: #FFF !important; }
    .btn-outline-pink:hover svg {
      color: #FFF !important; }

.btn-outline-pink.btn-raised, .btn-outline-pink.btn-fab {
  border: 1px solid;
  border-color: #e91e63;
  background-color: transparent;
  color: #e91e63;
  box-shadow: none !important; }
  .btn-outline-pink.btn-raised:focus, .btn-outline-pink.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-pink.btn-raised.active, .btn-outline-pink.btn-fab.active {
    background-color: #e91e63 !important;
    color: #FFF !important; }
  .btn-outline-pink.btn-raised:hover, .btn-outline-pink.btn-fab:hover {
    background-color: #e2165b !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-pink {
  background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-webkit-progress-value {
    background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-moz-progress-bar {
    background-color: #e91e63; }
  .progress .progress-bar.progress-bar-pink[value]::-ms-fill {
    background-color: #e91e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-pink .progress-bar {
      background-color: #e91e63; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e91e63 !important; }

.pink.darken-1 {
  color: #d81b60 !important; }

.bg-pink.bg-darken-1 {
  background-color: #d81b60 !important; }

.btn-pink.btn-darken-1 {
  border-color: #c2185b !important;
  background-color: #d81b60 !important; }
  .btn-pink.btn-darken-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-1:focus, .btn-pink.btn-darken-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-1 {
  border-color: #d81b60 !important;
  color: #d81b60 !important; }
  .btn-outline-pink.btn-outline-darken-1:hover {
    background-color: #d81b60 !important; }

.progress-pink.progress-darken-1 {
  background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-webkit-progress-value {
    background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-moz-progress-bar {
    background-color: #d81b60; }
  .progress-pink.progress-darken-1[value]::-ms-fill {
    background-color: #d81b60; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-1 .progress-bar {
      background-color: #d81b60; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d81b60 !important; }

.border-pink.border-darken-1 {
  border: 1px solid #d81b60 !important; }

.border-top-pink.border-top-darken-1 {
  border-top: 1px solid #d81b60 !important; }

.border-bottom-pink.border-bottom-darken-1 {
  border-bottom: 1px solid #d81b60 !important; }

[dir="ltr"] .border-left-pink.border-left-darken-1 {
  border-left: 1px solid #d81b60 !important; }

[dir="ltr"] .border-right-pink.border-right-darken-1 {
  border-right: 1px solid #d81b60 !important; }

[dir="rtl"] .border-left-pink.border-left-darken-1 {
  border-right: 1px solid #d81b60 !important; }

[dir="rtl"] .border-right-pink.border-right-darken-1 {
  border-left: 1px solid #d81b60 !important; }

.overlay-pink.overlay-darken-1 {
  background: #d81b60;
  /* The Fallback */
  background: rgba(216, 27, 96, 0.8); }

.pink.darken-2 {
  color: #c2185b !important; }

.bg-pink.bg-darken-2 {
  background-color: #c2185b !important; }

.btn-pink.btn-darken-2 {
  border-color: #c2185b !important;
  background-color: #c2185b !important; }
  .btn-pink.btn-darken-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-2:focus, .btn-pink.btn-darken-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-2 {
  border-color: #c2185b !important;
  color: #c2185b !important; }
  .btn-outline-pink.btn-outline-darken-2:hover {
    background-color: #c2185b !important; }

.progress-pink.progress-darken-2 {
  background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-webkit-progress-value {
    background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-moz-progress-bar {
    background-color: #c2185b; }
  .progress-pink.progress-darken-2[value]::-ms-fill {
    background-color: #c2185b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-2 .progress-bar {
      background-color: #c2185b; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c2185b !important; }

.border-pink.border-darken-2 {
  border: 1px solid #c2185b !important; }

.border-top-pink.border-top-darken-2 {
  border-top: 1px solid #c2185b !important; }

.border-bottom-pink.border-bottom-darken-2 {
  border-bottom: 1px solid #c2185b !important; }

[dir="ltr"] .border-left-pink.border-left-darken-2 {
  border-left: 1px solid #c2185b !important; }

[dir="ltr"] .border-right-pink.border-right-darken-2 {
  border-right: 1px solid #c2185b !important; }

[dir="rtl"] .border-left-pink.border-left-darken-2 {
  border-right: 1px solid #c2185b !important; }

[dir="rtl"] .border-right-pink.border-right-darken-2 {
  border-left: 1px solid #c2185b !important; }

.overlay-pink.overlay-darken-2 {
  background: #c2185b;
  /* The Fallback */
  background: rgba(194, 24, 91, 0.8); }

.pink.darken-3 {
  color: #ad1457 !important; }

.bg-pink.bg-darken-3 {
  background-color: #ad1457 !important; }

.btn-pink.btn-darken-3 {
  border-color: #c2185b !important;
  background-color: #ad1457 !important; }
  .btn-pink.btn-darken-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-3:focus, .btn-pink.btn-darken-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-3 {
  border-color: #ad1457 !important;
  color: #ad1457 !important; }
  .btn-outline-pink.btn-outline-darken-3:hover {
    background-color: #ad1457 !important; }

.progress-pink.progress-darken-3 {
  background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ad1457; }
  .progress-pink.progress-darken-3[value]::-ms-fill {
    background-color: #ad1457; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-3 .progress-bar {
      background-color: #ad1457; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ad1457 !important; }

.border-pink.border-darken-3 {
  border: 1px solid #ad1457 !important; }

.border-top-pink.border-top-darken-3 {
  border-top: 1px solid #ad1457 !important; }

.border-bottom-pink.border-bottom-darken-3 {
  border-bottom: 1px solid #ad1457 !important; }

[dir="ltr"] .border-left-pink.border-left-darken-3 {
  border-left: 1px solid #ad1457 !important; }

[dir="ltr"] .border-right-pink.border-right-darken-3 {
  border-right: 1px solid #ad1457 !important; }

[dir="rtl"] .border-left-pink.border-left-darken-3 {
  border-right: 1px solid #ad1457 !important; }

[dir="rtl"] .border-right-pink.border-right-darken-3 {
  border-left: 1px solid #ad1457 !important; }

.overlay-pink.overlay-darken-3 {
  background: #ad1457;
  /* The Fallback */
  background: rgba(173, 20, 87, 0.8); }

.pink.darken-4 {
  color: #880e4f !important; }

.bg-pink.bg-darken-4 {
  background-color: #880e4f !important; }

.btn-pink.btn-darken-4 {
  border-color: #c2185b !important;
  background-color: #880e4f !important; }
  .btn-pink.btn-darken-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-darken-4:focus, .btn-pink.btn-darken-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-darken-4 {
  border-color: #880e4f !important;
  color: #880e4f !important; }
  .btn-outline-pink.btn-outline-darken-4:hover {
    background-color: #880e4f !important; }

.progress-pink.progress-darken-4 {
  background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-webkit-progress-value {
    background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-moz-progress-bar {
    background-color: #880e4f; }
  .progress-pink.progress-darken-4[value]::-ms-fill {
    background-color: #880e4f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-darken-4 .progress-bar {
      background-color: #880e4f; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #880e4f !important; }

.border-pink.border-darken-4 {
  border: 1px solid #880e4f !important; }

.border-top-pink.border-top-darken-4 {
  border-top: 1px solid #880e4f !important; }

.border-bottom-pink.border-bottom-darken-4 {
  border-bottom: 1px solid #880e4f !important; }

[dir="ltr"] .border-left-pink.border-left-darken-4 {
  border-left: 1px solid #880e4f !important; }

[dir="ltr"] .border-right-pink.border-right-darken-4 {
  border-right: 1px solid #880e4f !important; }

[dir="rtl"] .border-left-pink.border-left-darken-4 {
  border-right: 1px solid #880e4f !important; }

[dir="rtl"] .border-right-pink.border-right-darken-4 {
  border-left: 1px solid #880e4f !important; }

.overlay-pink.overlay-darken-4 {
  background: #880e4f;
  /* The Fallback */
  background: rgba(136, 14, 79, 0.8); }

.pink.accent-1 {
  color: #ff80ab !important; }

.bg-pink.bg-accent-1 {
  background-color: #ff80ab !important; }

.btn-pink.btn-accent-1 {
  border-color: #c2185b !important;
  background-color: #ff80ab !important; }
  .btn-pink.btn-accent-1:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-1:focus, .btn-pink.btn-accent-1:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-1 {
  border-color: #ff80ab !important;
  color: #ff80ab !important; }
  .btn-outline-pink.btn-outline-accent-1:hover {
    background-color: #ff80ab !important; }

.progress-pink.progress-accent-1 {
  background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff80ab; }
  .progress-pink.progress-accent-1[value]::-ms-fill {
    background-color: #ff80ab; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-1 .progress-bar {
      background-color: #ff80ab; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff80ab !important; }

.border-pink.border-accent-1 {
  border: 1px solid #ff80ab !important; }

.border-top-pink.border-top-accent-1 {
  border-top: 1px solid #ff80ab !important; }

.border-bottom-pink.border-bottom-accent-1 {
  border-bottom: 1px solid #ff80ab !important; }

[dir="ltr"] .border-left-pink.border-left-accent-1 {
  border-left: 1px solid #ff80ab !important; }

[dir="ltr"] .border-right-pink.border-right-accent-1 {
  border-right: 1px solid #ff80ab !important; }

[dir="rtl"] .border-left-pink.border-left-accent-1 {
  border-right: 1px solid #ff80ab !important; }

[dir="rtl"] .border-right-pink.border-right-accent-1 {
  border-left: 1px solid #ff80ab !important; }

.overlay-pink.overlay-accent-1 {
  background: #ff80ab;
  /* The Fallback */
  background: rgba(255, 128, 171, 0.8); }

.pink.accent-2 {
  color: #ff4081 !important; }

.bg-pink.bg-accent-2 {
  background-color: #ff4081 !important; }

.btn-pink.btn-accent-2 {
  border-color: #c2185b !important;
  background-color: #ff4081 !important; }
  .btn-pink.btn-accent-2:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-2:focus, .btn-pink.btn-accent-2:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-2 {
  border-color: #ff4081 !important;
  color: #ff4081 !important; }
  .btn-outline-pink.btn-outline-accent-2:hover {
    background-color: #ff4081 !important; }

.progress-pink.progress-accent-2 {
  background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff4081; }
  .progress-pink.progress-accent-2[value]::-ms-fill {
    background-color: #ff4081; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-2 .progress-bar {
      background-color: #ff4081; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff4081 !important; }

.border-pink.border-accent-2 {
  border: 1px solid #ff4081 !important; }

.border-top-pink.border-top-accent-2 {
  border-top: 1px solid #ff4081 !important; }

.border-bottom-pink.border-bottom-accent-2 {
  border-bottom: 1px solid #ff4081 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-2 {
  border-left: 1px solid #ff4081 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-2 {
  border-right: 1px solid #ff4081 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-2 {
  border-right: 1px solid #ff4081 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-2 {
  border-left: 1px solid #ff4081 !important; }

.overlay-pink.overlay-accent-2 {
  background: #ff4081;
  /* The Fallback */
  background: rgba(255, 64, 129, 0.8); }

.pink.accent-3 {
  color: #f50057 !important; }

.bg-pink.bg-accent-3 {
  background-color: #f50057 !important; }

.btn-pink.btn-accent-3 {
  border-color: #c2185b !important;
  background-color: #f50057 !important; }
  .btn-pink.btn-accent-3:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-3:focus, .btn-pink.btn-accent-3:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-3 {
  border-color: #f50057 !important;
  color: #f50057 !important; }
  .btn-outline-pink.btn-outline-accent-3:hover {
    background-color: #f50057 !important; }

.progress-pink.progress-accent-3 {
  background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-webkit-progress-value {
    background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-moz-progress-bar {
    background-color: #f50057; }
  .progress-pink.progress-accent-3[value]::-ms-fill {
    background-color: #f50057; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-3 .progress-bar {
      background-color: #f50057; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f50057 !important; }

.border-pink.border-accent-3 {
  border: 1px solid #f50057 !important; }

.border-top-pink.border-top-accent-3 {
  border-top: 1px solid #f50057 !important; }

.border-bottom-pink.border-bottom-accent-3 {
  border-bottom: 1px solid #f50057 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-3 {
  border-left: 1px solid #f50057 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-3 {
  border-right: 1px solid #f50057 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-3 {
  border-right: 1px solid #f50057 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-3 {
  border-left: 1px solid #f50057 !important; }

.overlay-pink.overlay-accent-3 {
  background: #f50057;
  /* The Fallback */
  background: rgba(245, 0, 87, 0.8); }

.pink.accent-4 {
  color: #c51162 !important; }

.bg-pink.bg-accent-4 {
  background-color: #c51162 !important; }

.btn-pink.btn-accent-4 {
  border-color: #c2185b !important;
  background-color: #c51162 !important; }
  .btn-pink.btn-accent-4:hover {
    border-color: #c2185b !important;
    background-color: #ad1457 !important; }
  .btn-pink.btn-accent-4:focus, .btn-pink.btn-accent-4:active {
    border-color: #ad1457 !important;
    background-color: #880e4f !important; }

.btn-outline-pink.btn-outline-accent-4 {
  border-color: #c51162 !important;
  color: #c51162 !important; }
  .btn-outline-pink.btn-outline-accent-4:hover {
    background-color: #c51162 !important; }

.progress-pink.progress-accent-4 {
  background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-webkit-progress-value {
    background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-moz-progress-bar {
    background-color: #c51162; }
  .progress-pink.progress-accent-4[value]::-ms-fill {
    background-color: #c51162; }
  @media screen and (min-width: 0 \0 ) {
    .progress-pink.progress-accent-4 .progress-bar {
      background-color: #c51162; } }

input:focus ~ .bg-pink {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c51162 !important; }

.border-pink.border-accent-4 {
  border: 1px solid #c51162 !important; }

.border-top-pink.border-top-accent-4 {
  border-top: 1px solid #c51162 !important; }

.border-bottom-pink.border-bottom-accent-4 {
  border-bottom: 1px solid #c51162 !important; }

[dir="ltr"] .border-left-pink.border-left-accent-4 {
  border-left: 1px solid #c51162 !important; }

[dir="ltr"] .border-right-pink.border-right-accent-4 {
  border-right: 1px solid #c51162 !important; }

[dir="rtl"] .border-left-pink.border-left-accent-4 {
  border-right: 1px solid #c51162 !important; }

[dir="rtl"] .border-right-pink.border-right-accent-4 {
  border-left: 1px solid #c51162 !important; }

.overlay-pink.overlay-accent-4 {
  background: #c51162;
  /* The Fallback */
  background: rgba(197, 17, 98, 0.8); }

.purple.lighten-5 {
  color: #f3e5f5 !important; }

.bg-purple.bg-lighten-5 {
  background-color: #f3e5f5 !important; }

.btn-purple.btn-lighten-5 {
  border-color: #7b1fa2 !important;
  background-color: #f3e5f5 !important; }
  .btn-purple.btn-lighten-5:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-5:focus, .btn-purple.btn-lighten-5:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-5 {
  border-color: #f3e5f5 !important;
  color: #f3e5f5 !important; }
  .btn-outline-purple.btn-outline-lighten-5:hover {
    background-color: #f3e5f5 !important; }

.progress-purple.progress-lighten-5 {
  background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f3e5f5; }
  .progress-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #f3e5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-5 .progress-bar {
      background-color: #f3e5f5; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f3e5f5 !important; }

.border-purple.border-lighten-5 {
  border: 1px solid #f3e5f5 !important; }

.border-top-purple.border-top-lighten-5 {
  border-top: 1px solid #f3e5f5 !important; }

.border-bottom-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #f3e5f5 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-5 {
  border-left: 1px solid #f3e5f5 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-5 {
  border-right: 1px solid #f3e5f5 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-5 {
  border-right: 1px solid #f3e5f5 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-5 {
  border-left: 1px solid #f3e5f5 !important; }

.overlay-purple.overlay-lighten-5 {
  background: #f3e5f5;
  /* The Fallback */
  background: rgba(243, 229, 245, 0.8); }

.purple.lighten-4 {
  color: #e1bee7 !important; }

.bg-purple.bg-lighten-4 {
  background-color: #e1bee7 !important; }

.btn-purple.btn-lighten-4 {
  border-color: #7b1fa2 !important;
  background-color: #e1bee7 !important; }
  .btn-purple.btn-lighten-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-4:focus, .btn-purple.btn-lighten-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-4 {
  border-color: #e1bee7 !important;
  color: #e1bee7 !important; }
  .btn-outline-purple.btn-outline-lighten-4:hover {
    background-color: #e1bee7 !important; }

.progress-purple.progress-lighten-4 {
  background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #e1bee7; }
  .progress-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #e1bee7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-4 .progress-bar {
      background-color: #e1bee7; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1bee7 !important; }

.border-purple.border-lighten-4 {
  border: 1px solid #e1bee7 !important; }

.border-top-purple.border-top-lighten-4 {
  border-top: 1px solid #e1bee7 !important; }

.border-bottom-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #e1bee7 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-4 {
  border-left: 1px solid #e1bee7 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-4 {
  border-right: 1px solid #e1bee7 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-4 {
  border-right: 1px solid #e1bee7 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-4 {
  border-left: 1px solid #e1bee7 !important; }

.overlay-purple.overlay-lighten-4 {
  background: #e1bee7;
  /* The Fallback */
  background: rgba(225, 190, 231, 0.8); }

.purple.lighten-3 {
  color: #ce93d8 !important; }

.bg-purple.bg-lighten-3 {
  background-color: #ce93d8 !important; }

.btn-purple.btn-lighten-3 {
  border-color: #7b1fa2 !important;
  background-color: #ce93d8 !important; }
  .btn-purple.btn-lighten-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-3:focus, .btn-purple.btn-lighten-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-3 {
  border-color: #ce93d8 !important;
  color: #ce93d8 !important; }
  .btn-outline-purple.btn-outline-lighten-3:hover {
    background-color: #ce93d8 !important; }

.progress-purple.progress-lighten-3 {
  background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ce93d8; }
  .progress-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #ce93d8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-3 .progress-bar {
      background-color: #ce93d8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ce93d8 !important; }

.border-purple.border-lighten-3 {
  border: 1px solid #ce93d8 !important; }

.border-top-purple.border-top-lighten-3 {
  border-top: 1px solid #ce93d8 !important; }

.border-bottom-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #ce93d8 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-3 {
  border-left: 1px solid #ce93d8 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-3 {
  border-right: 1px solid #ce93d8 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-3 {
  border-right: 1px solid #ce93d8 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-3 {
  border-left: 1px solid #ce93d8 !important; }

.overlay-purple.overlay-lighten-3 {
  background: #ce93d8;
  /* The Fallback */
  background: rgba(206, 147, 216, 0.8); }

.purple.lighten-2 {
  color: #ba68c8 !important; }

.bg-purple.bg-lighten-2 {
  background-color: #ba68c8 !important; }

.btn-purple.btn-lighten-2 {
  border-color: #7b1fa2 !important;
  background-color: #ba68c8 !important; }
  .btn-purple.btn-lighten-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-2:focus, .btn-purple.btn-lighten-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-2 {
  border-color: #ba68c8 !important;
  color: #ba68c8 !important; }
  .btn-outline-purple.btn-outline-lighten-2:hover {
    background-color: #ba68c8 !important; }

.progress-purple.progress-lighten-2 {
  background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ba68c8; }
  .progress-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #ba68c8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-2 .progress-bar {
      background-color: #ba68c8; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ba68c8 !important; }

.border-purple.border-lighten-2 {
  border: 1px solid #ba68c8 !important; }

.border-top-purple.border-top-lighten-2 {
  border-top: 1px solid #ba68c8 !important; }

.border-bottom-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #ba68c8 !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-2 {
  border-left: 1px solid #ba68c8 !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-2 {
  border-right: 1px solid #ba68c8 !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-2 {
  border-right: 1px solid #ba68c8 !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-2 {
  border-left: 1px solid #ba68c8 !important; }

.overlay-purple.overlay-lighten-2 {
  background: #ba68c8;
  /* The Fallback */
  background: rgba(186, 104, 200, 0.8); }

.purple.lighten-1 {
  color: #ab47bc !important; }

.bg-purple.bg-lighten-1 {
  background-color: #ab47bc !important; }

.btn-purple.btn-lighten-1 {
  border-color: #7b1fa2 !important;
  background-color: #ab47bc !important; }
  .btn-purple.btn-lighten-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-lighten-1:focus, .btn-purple.btn-lighten-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-lighten-1 {
  border-color: #ab47bc !important;
  color: #ab47bc !important; }
  .btn-outline-purple.btn-outline-lighten-1:hover {
    background-color: #ab47bc !important; }

.progress-purple.progress-lighten-1 {
  background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ab47bc; }
  .progress-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #ab47bc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-lighten-1 .progress-bar {
      background-color: #ab47bc; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ab47bc !important; }

.border-purple.border-lighten-1 {
  border: 1px solid #ab47bc !important; }

.border-top-purple.border-top-lighten-1 {
  border-top: 1px solid #ab47bc !important; }

.border-bottom-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #ab47bc !important; }

[dir="ltr"] .border-left-purple.border-left-lighten-1 {
  border-left: 1px solid #ab47bc !important; }

[dir="ltr"] .border-right-purple.border-right-lighten-1 {
  border-right: 1px solid #ab47bc !important; }

[dir="rtl"] .border-left-purple.border-left-lighten-1 {
  border-right: 1px solid #ab47bc !important; }

[dir="rtl"] .border-right-purple.border-right-lighten-1 {
  border-left: 1px solid #ab47bc !important; }

.overlay-purple.overlay-lighten-1 {
  background: #ab47bc;
  /* The Fallback */
  background: rgba(171, 71, 188, 0.8); }

.purple {
  color: #9c27b0 !important; }

.bg-purple {
  background-color: #9c27b0 !important; }
  .bg-purple .card-header, .bg-purple .card-footer {
    background-color: transparent; }

.toast-purple {
  background-color: #9c27b0; }

.alert-purple {
  border-color: #9c27b0 !important;
  background-color: #b62dcd !important;
  color: #1a071e !important; }
  .alert-purple .alert-link {
    color: black !important; }

.border-purple {
  border-color: #9c27b0; }

.border-top-purple {
  border-top-color: #9c27b0; }

.border-bottom-purple {
  border-bottom-color: #9c27b0; }

[dir="ltr"] .border-left-purple {
  border-left-color: #9c27b0; }

[dir="ltr"] .border-right-purple {
  border-right-color: #9c27b0; }

[dir="rtl"] .border-left-purple {
  border-right-color: #9c27b0; }

[dir="rtl"] .border-right-purple {
  border-left-color: #9c27b0; }

.badge-purple {
  background-color: #9c27b0; }

.panel-purple {
  border-color: #9c27b0; }
  .panel-purple .panel-heading {
    color: #FFF;
    border-color: #9c27b0;
    background-color: #af2cc5; }

.bg-purple.tag-glow, .border-purple.tag-glow {
  box-shadow: 0px 0px 10px #9c27b0; }

.overlay-purple {
  background: #9c27b0;
  /* The Fallback */
  background: rgba(156, 39, 176, 0.8); }

.card.card-outline-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #9c27b0;
  background-color: transparent; }
  .card.card-outline-purple .card-header, .card.card-outline-purple .card-footer {
    background-color: transparent; }

.btn-purple.btn-flat {
  background-color: transparent !important;
  color: #9c27b0;
  border: none; }

.btn-purple.btn-raised, .btn-purple.btn-fab {
  background-color: #9c27b0 !important;
  color: #fff !important;
  border-color: #9c27b0; }
  .btn-purple.btn-raised.active, .btn-purple.btn-fab.active {
    background-color: #822193 !important;
    border-color: #822193 !important; }

.btn-group-raised .btn-purple {
  background-color: #9c27b0 !important;
  color: #fff !important; }

.btn-outline-purple {
  border: 1px solid;
  border-color: #9c27b0;
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none !important; }
  .btn-outline-purple:focus {
    background-color: transparent !important;
    color: #9c27b0 !important;
    box-shadow: transparent !important; }
  .btn-outline-purple.active {
    background-color: #9c27b0 !important;
    color: #FFF !important; }
  .btn-outline-purple:hover {
    background-color: #9124a3 !important;
    color: #FFF !important; }
    .btn-outline-purple:hover svg {
      color: #FFF !important; }

.btn-outline-purple.btn-raised, .btn-outline-purple.btn-fab {
  border: 1px solid;
  border-color: #9c27b0;
  background-color: transparent;
  color: #9c27b0;
  box-shadow: none !important; }
  .btn-outline-purple.btn-raised:focus, .btn-outline-purple.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-purple.btn-raised.active, .btn-outline-purple.btn-fab.active {
    background-color: #9c27b0 !important;
    color: #FFF !important; }
  .btn-outline-purple.btn-raised:hover, .btn-outline-purple.btn-fab:hover {
    background-color: #9124a3 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-purple {
  background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-webkit-progress-value {
    background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-moz-progress-bar {
    background-color: #9c27b0; }
  .progress .progress-bar.progress-bar-purple[value]::-ms-fill {
    background-color: #9c27b0; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-purple .progress-bar {
      background-color: #9c27b0; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9c27b0 !important; }

.purple.darken-1 {
  color: #8e24aa !important; }

.bg-purple.bg-darken-1 {
  background-color: #8e24aa !important; }

.btn-purple.btn-darken-1 {
  border-color: #7b1fa2 !important;
  background-color: #8e24aa !important; }
  .btn-purple.btn-darken-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-1:focus, .btn-purple.btn-darken-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-1 {
  border-color: #8e24aa !important;
  color: #8e24aa !important; }
  .btn-outline-purple.btn-outline-darken-1:hover {
    background-color: #8e24aa !important; }

.progress-purple.progress-darken-1 {
  background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #8e24aa; }
  .progress-purple.progress-darken-1[value]::-ms-fill {
    background-color: #8e24aa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-1 .progress-bar {
      background-color: #8e24aa; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8e24aa !important; }

.border-purple.border-darken-1 {
  border: 1px solid #8e24aa !important; }

.border-top-purple.border-top-darken-1 {
  border-top: 1px solid #8e24aa !important; }

.border-bottom-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #8e24aa !important; }

[dir="ltr"] .border-left-purple.border-left-darken-1 {
  border-left: 1px solid #8e24aa !important; }

[dir="ltr"] .border-right-purple.border-right-darken-1 {
  border-right: 1px solid #8e24aa !important; }

[dir="rtl"] .border-left-purple.border-left-darken-1 {
  border-right: 1px solid #8e24aa !important; }

[dir="rtl"] .border-right-purple.border-right-darken-1 {
  border-left: 1px solid #8e24aa !important; }

.overlay-purple.overlay-darken-1 {
  background: #8e24aa;
  /* The Fallback */
  background: rgba(142, 36, 170, 0.8); }

.purple.darken-2 {
  color: #7b1fa2 !important; }

.bg-purple.bg-darken-2 {
  background-color: #7b1fa2 !important; }

.btn-purple.btn-darken-2 {
  border-color: #7b1fa2 !important;
  background-color: #7b1fa2 !important; }
  .btn-purple.btn-darken-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-2:focus, .btn-purple.btn-darken-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-2 {
  border-color: #7b1fa2 !important;
  color: #7b1fa2 !important; }
  .btn-outline-purple.btn-outline-darken-2:hover {
    background-color: #7b1fa2 !important; }

.progress-purple.progress-darken-2 {
  background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #7b1fa2; }
  .progress-purple.progress-darken-2[value]::-ms-fill {
    background-color: #7b1fa2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-2 .progress-bar {
      background-color: #7b1fa2; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7b1fa2 !important; }

.border-purple.border-darken-2 {
  border: 1px solid #7b1fa2 !important; }

.border-top-purple.border-top-darken-2 {
  border-top: 1px solid #7b1fa2 !important; }

.border-bottom-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #7b1fa2 !important; }

[dir="ltr"] .border-left-purple.border-left-darken-2 {
  border-left: 1px solid #7b1fa2 !important; }

[dir="ltr"] .border-right-purple.border-right-darken-2 {
  border-right: 1px solid #7b1fa2 !important; }

[dir="rtl"] .border-left-purple.border-left-darken-2 {
  border-right: 1px solid #7b1fa2 !important; }

[dir="rtl"] .border-right-purple.border-right-darken-2 {
  border-left: 1px solid #7b1fa2 !important; }

.overlay-purple.overlay-darken-2 {
  background: #7b1fa2;
  /* The Fallback */
  background: rgba(123, 31, 162, 0.8); }

.purple.darken-3 {
  color: #6a1b9a !important; }

.bg-purple.bg-darken-3 {
  background-color: #6a1b9a !important; }

.btn-purple.btn-darken-3 {
  border-color: #7b1fa2 !important;
  background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-3:focus, .btn-purple.btn-darken-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-3 {
  border-color: #6a1b9a !important;
  color: #6a1b9a !important; }
  .btn-outline-purple.btn-outline-darken-3:hover {
    background-color: #6a1b9a !important; }

.progress-purple.progress-darken-3 {
  background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #6a1b9a; }
  .progress-purple.progress-darken-3[value]::-ms-fill {
    background-color: #6a1b9a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-3 .progress-bar {
      background-color: #6a1b9a; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6a1b9a !important; }

.border-purple.border-darken-3 {
  border: 1px solid #6a1b9a !important; }

.border-top-purple.border-top-darken-3 {
  border-top: 1px solid #6a1b9a !important; }

.border-bottom-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #6a1b9a !important; }

[dir="ltr"] .border-left-purple.border-left-darken-3 {
  border-left: 1px solid #6a1b9a !important; }

[dir="ltr"] .border-right-purple.border-right-darken-3 {
  border-right: 1px solid #6a1b9a !important; }

[dir="rtl"] .border-left-purple.border-left-darken-3 {
  border-right: 1px solid #6a1b9a !important; }

[dir="rtl"] .border-right-purple.border-right-darken-3 {
  border-left: 1px solid #6a1b9a !important; }

.overlay-purple.overlay-darken-3 {
  background: #6a1b9a;
  /* The Fallback */
  background: rgba(106, 27, 154, 0.8); }

.purple.darken-4 {
  color: #4a148c !important; }

.bg-purple.bg-darken-4 {
  background-color: #4a148c !important; }

.btn-purple.btn-darken-4 {
  border-color: #7b1fa2 !important;
  background-color: #4a148c !important; }
  .btn-purple.btn-darken-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-darken-4:focus, .btn-purple.btn-darken-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-darken-4 {
  border-color: #4a148c !important;
  color: #4a148c !important; }
  .btn-outline-purple.btn-outline-darken-4:hover {
    background-color: #4a148c !important; }

.progress-purple.progress-darken-4 {
  background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #4a148c; }
  .progress-purple.progress-darken-4[value]::-ms-fill {
    background-color: #4a148c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-darken-4 .progress-bar {
      background-color: #4a148c; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4a148c !important; }

.border-purple.border-darken-4 {
  border: 1px solid #4a148c !important; }

.border-top-purple.border-top-darken-4 {
  border-top: 1px solid #4a148c !important; }

.border-bottom-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #4a148c !important; }

[dir="ltr"] .border-left-purple.border-left-darken-4 {
  border-left: 1px solid #4a148c !important; }

[dir="ltr"] .border-right-purple.border-right-darken-4 {
  border-right: 1px solid #4a148c !important; }

[dir="rtl"] .border-left-purple.border-left-darken-4 {
  border-right: 1px solid #4a148c !important; }

[dir="rtl"] .border-right-purple.border-right-darken-4 {
  border-left: 1px solid #4a148c !important; }

.overlay-purple.overlay-darken-4 {
  background: #4a148c;
  /* The Fallback */
  background: rgba(74, 20, 140, 0.8); }

.purple.accent-1 {
  color: #ea80fc !important; }

.bg-purple.bg-accent-1 {
  background-color: #ea80fc !important; }

.btn-purple.btn-accent-1 {
  border-color: #7b1fa2 !important;
  background-color: #ea80fc !important; }
  .btn-purple.btn-accent-1:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-1:focus, .btn-purple.btn-accent-1:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-1 {
  border-color: #ea80fc !important;
  color: #ea80fc !important; }
  .btn-outline-purple.btn-outline-accent-1:hover {
    background-color: #ea80fc !important; }

.progress-purple.progress-accent-1 {
  background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ea80fc; }
  .progress-purple.progress-accent-1[value]::-ms-fill {
    background-color: #ea80fc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-1 .progress-bar {
      background-color: #ea80fc; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ea80fc !important; }

.border-purple.border-accent-1 {
  border: 1px solid #ea80fc !important; }

.border-top-purple.border-top-accent-1 {
  border-top: 1px solid #ea80fc !important; }

.border-bottom-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #ea80fc !important; }

[dir="ltr"] .border-left-purple.border-left-accent-1 {
  border-left: 1px solid #ea80fc !important; }

[dir="ltr"] .border-right-purple.border-right-accent-1 {
  border-right: 1px solid #ea80fc !important; }

[dir="rtl"] .border-left-purple.border-left-accent-1 {
  border-right: 1px solid #ea80fc !important; }

[dir="rtl"] .border-right-purple.border-right-accent-1 {
  border-left: 1px solid #ea80fc !important; }

.overlay-purple.overlay-accent-1 {
  background: #ea80fc;
  /* The Fallback */
  background: rgba(234, 128, 252, 0.8); }

.purple.accent-2 {
  color: #e040fb !important; }

.bg-purple.bg-accent-2 {
  background-color: #e040fb !important; }

.btn-purple.btn-accent-2 {
  border-color: #7b1fa2 !important;
  background-color: #e040fb !important; }
  .btn-purple.btn-accent-2:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-2:focus, .btn-purple.btn-accent-2:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-2 {
  border-color: #e040fb !important;
  color: #e040fb !important; }
  .btn-outline-purple.btn-outline-accent-2:hover {
    background-color: #e040fb !important; }

.progress-purple.progress-accent-2 {
  background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #e040fb; }
  .progress-purple.progress-accent-2[value]::-ms-fill {
    background-color: #e040fb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-2 .progress-bar {
      background-color: #e040fb; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e040fb !important; }

.border-purple.border-accent-2 {
  border: 1px solid #e040fb !important; }

.border-top-purple.border-top-accent-2 {
  border-top: 1px solid #e040fb !important; }

.border-bottom-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #e040fb !important; }

[dir="ltr"] .border-left-purple.border-left-accent-2 {
  border-left: 1px solid #e040fb !important; }

[dir="ltr"] .border-right-purple.border-right-accent-2 {
  border-right: 1px solid #e040fb !important; }

[dir="rtl"] .border-left-purple.border-left-accent-2 {
  border-right: 1px solid #e040fb !important; }

[dir="rtl"] .border-right-purple.border-right-accent-2 {
  border-left: 1px solid #e040fb !important; }

.overlay-purple.overlay-accent-2 {
  background: #e040fb;
  /* The Fallback */
  background: rgba(224, 64, 251, 0.8); }

.purple.accent-3 {
  color: #d500f9 !important; }

.bg-purple.bg-accent-3 {
  background-color: #d500f9 !important; }

.btn-purple.btn-accent-3 {
  border-color: #7b1fa2 !important;
  background-color: #d500f9 !important; }
  .btn-purple.btn-accent-3:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-3:focus, .btn-purple.btn-accent-3:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-3 {
  border-color: #d500f9 !important;
  color: #d500f9 !important; }
  .btn-outline-purple.btn-outline-accent-3:hover {
    background-color: #d500f9 !important; }

.progress-purple.progress-accent-3 {
  background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #d500f9; }
  .progress-purple.progress-accent-3[value]::-ms-fill {
    background-color: #d500f9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-3 .progress-bar {
      background-color: #d500f9; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d500f9 !important; }

.border-purple.border-accent-3 {
  border: 1px solid #d500f9 !important; }

.border-top-purple.border-top-accent-3 {
  border-top: 1px solid #d500f9 !important; }

.border-bottom-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #d500f9 !important; }

[dir="ltr"] .border-left-purple.border-left-accent-3 {
  border-left: 1px solid #d500f9 !important; }

[dir="ltr"] .border-right-purple.border-right-accent-3 {
  border-right: 1px solid #d500f9 !important; }

[dir="rtl"] .border-left-purple.border-left-accent-3 {
  border-right: 1px solid #d500f9 !important; }

[dir="rtl"] .border-right-purple.border-right-accent-3 {
  border-left: 1px solid #d500f9 !important; }

.overlay-purple.overlay-accent-3 {
  background: #d500f9;
  /* The Fallback */
  background: rgba(213, 0, 249, 0.8); }

.purple.accent-4 {
  color: #a0f !important; }

.bg-purple.bg-accent-4 {
  background-color: #a0f !important; }

.btn-purple.btn-accent-4 {
  border-color: #7b1fa2 !important;
  background-color: #a0f !important; }
  .btn-purple.btn-accent-4:hover {
    border-color: #7b1fa2 !important;
    background-color: #6a1b9a !important; }
  .btn-purple.btn-accent-4:focus, .btn-purple.btn-accent-4:active {
    border-color: #6a1b9a !important;
    background-color: #4a148c !important; }

.btn-outline-purple.btn-outline-accent-4 {
  border-color: #a0f !important;
  color: #a0f !important; }
  .btn-outline-purple.btn-outline-accent-4:hover {
    background-color: #a0f !important; }

.progress-purple.progress-accent-4 {
  background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #a0f; }
  .progress-purple.progress-accent-4[value]::-ms-fill {
    background-color: #a0f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-purple.progress-accent-4 .progress-bar {
      background-color: #a0f; } }

input:focus ~ .bg-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a0f !important; }

.border-purple.border-accent-4 {
  border: 1px solid #a0f !important; }

.border-top-purple.border-top-accent-4 {
  border-top: 1px solid #a0f !important; }

.border-bottom-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #a0f !important; }

[dir="ltr"] .border-left-purple.border-left-accent-4 {
  border-left: 1px solid #a0f !important; }

[dir="ltr"] .border-right-purple.border-right-accent-4 {
  border-right: 1px solid #a0f !important; }

[dir="rtl"] .border-left-purple.border-left-accent-4 {
  border-right: 1px solid #a0f !important; }

[dir="rtl"] .border-right-purple.border-right-accent-4 {
  border-left: 1px solid #a0f !important; }

.overlay-purple.overlay-accent-4 {
  background: #a0f;
  /* The Fallback */
  background: rgba(170, 0, 255, 0.8); }

.deep-purple.lighten-5 {
  color: #ede7f6 !important; }

.bg-deep-purple.bg-lighten-5 {
  background-color: #ede7f6 !important; }

.btn-deep-purple.btn-lighten-5 {
  border-color: #512da8 !important;
  background-color: #ede7f6 !important; }
  .btn-deep-purple.btn-lighten-5:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-5:focus, .btn-deep-purple.btn-lighten-5:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-5 {
  border-color: #ede7f6 !important;
  color: #ede7f6 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-5:hover {
    background-color: #ede7f6 !important; }

.progress-deep-purple.progress-lighten-5 {
  background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #ede7f6; }
  .progress-deep-purple.progress-lighten-5[value]::-ms-fill {
    background-color: #ede7f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-5 .progress-bar {
      background-color: #ede7f6; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ede7f6 !important; }

.border-deep-purple.border-lighten-5 {
  border: 1px solid #ede7f6 !important; }

.border-top-deep-purple.border-top-lighten-5 {
  border-top: 1px solid #ede7f6 !important; }

.border-bottom-deep-purple.border-bottom-lighten-5 {
  border-bottom: 1px solid #ede7f6 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-5 {
  border-left: 1px solid #ede7f6 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-5 {
  border-right: 1px solid #ede7f6 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-5 {
  border-right: 1px solid #ede7f6 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-5 {
  border-left: 1px solid #ede7f6 !important; }

.overlay-deep-purple.overlay-lighten-5 {
  background: #ede7f6;
  /* The Fallback */
  background: rgba(237, 231, 246, 0.8); }

.deep-purple.lighten-4 {
  color: #d1c4e9 !important; }

.bg-deep-purple.bg-lighten-4 {
  background-color: #d1c4e9 !important; }

.btn-deep-purple.btn-lighten-4 {
  border-color: #512da8 !important;
  background-color: #d1c4e9 !important; }
  .btn-deep-purple.btn-lighten-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-4:focus, .btn-deep-purple.btn-lighten-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-4 {
  border-color: #d1c4e9 !important;
  color: #d1c4e9 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-4:hover {
    background-color: #d1c4e9 !important; }

.progress-deep-purple.progress-lighten-4 {
  background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d1c4e9; }
  .progress-deep-purple.progress-lighten-4[value]::-ms-fill {
    background-color: #d1c4e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-4 .progress-bar {
      background-color: #d1c4e9; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d1c4e9 !important; }

.border-deep-purple.border-lighten-4 {
  border: 1px solid #d1c4e9 !important; }

.border-top-deep-purple.border-top-lighten-4 {
  border-top: 1px solid #d1c4e9 !important; }

.border-bottom-deep-purple.border-bottom-lighten-4 {
  border-bottom: 1px solid #d1c4e9 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-4 {
  border-left: 1px solid #d1c4e9 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-4 {
  border-right: 1px solid #d1c4e9 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-4 {
  border-right: 1px solid #d1c4e9 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-4 {
  border-left: 1px solid #d1c4e9 !important; }

.overlay-deep-purple.overlay-lighten-4 {
  background: #d1c4e9;
  /* The Fallback */
  background: rgba(209, 196, 233, 0.8); }

.deep-purple.lighten-3 {
  color: #b39ddb !important; }

.bg-deep-purple.bg-lighten-3 {
  background-color: #b39ddb !important; }

.btn-deep-purple.btn-lighten-3 {
  border-color: #512da8 !important;
  background-color: #b39ddb !important; }
  .btn-deep-purple.btn-lighten-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-3:focus, .btn-deep-purple.btn-lighten-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-3 {
  border-color: #b39ddb !important;
  color: #b39ddb !important; }
  .btn-outline-deep-purple.btn-outline-lighten-3:hover {
    background-color: #b39ddb !important; }

.progress-deep-purple.progress-lighten-3 {
  background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b39ddb; }
  .progress-deep-purple.progress-lighten-3[value]::-ms-fill {
    background-color: #b39ddb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-3 .progress-bar {
      background-color: #b39ddb; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b39ddb !important; }

.border-deep-purple.border-lighten-3 {
  border: 1px solid #b39ddb !important; }

.border-top-deep-purple.border-top-lighten-3 {
  border-top: 1px solid #b39ddb !important; }

.border-bottom-deep-purple.border-bottom-lighten-3 {
  border-bottom: 1px solid #b39ddb !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-3 {
  border-left: 1px solid #b39ddb !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-3 {
  border-right: 1px solid #b39ddb !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-3 {
  border-right: 1px solid #b39ddb !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-3 {
  border-left: 1px solid #b39ddb !important; }

.overlay-deep-purple.overlay-lighten-3 {
  background: #b39ddb;
  /* The Fallback */
  background: rgba(179, 157, 219, 0.8); }

.deep-purple.lighten-2 {
  color: #9575cd !important; }

.bg-deep-purple.bg-lighten-2 {
  background-color: #9575cd !important; }

.btn-deep-purple.btn-lighten-2 {
  border-color: #512da8 !important;
  background-color: #9575cd !important; }
  .btn-deep-purple.btn-lighten-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-2:focus, .btn-deep-purple.btn-lighten-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-2 {
  border-color: #9575cd !important;
  color: #9575cd !important; }
  .btn-outline-deep-purple.btn-outline-lighten-2:hover {
    background-color: #9575cd !important; }

.progress-deep-purple.progress-lighten-2 {
  background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #9575cd; }
  .progress-deep-purple.progress-lighten-2[value]::-ms-fill {
    background-color: #9575cd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-2 .progress-bar {
      background-color: #9575cd; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9575cd !important; }

.border-deep-purple.border-lighten-2 {
  border: 1px solid #9575cd !important; }

.border-top-deep-purple.border-top-lighten-2 {
  border-top: 1px solid #9575cd !important; }

.border-bottom-deep-purple.border-bottom-lighten-2 {
  border-bottom: 1px solid #9575cd !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-2 {
  border-left: 1px solid #9575cd !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-2 {
  border-right: 1px solid #9575cd !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-2 {
  border-right: 1px solid #9575cd !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-2 {
  border-left: 1px solid #9575cd !important; }

.overlay-deep-purple.overlay-lighten-2 {
  background: #9575cd;
  /* The Fallback */
  background: rgba(149, 117, 205, 0.8); }

.deep-purple.lighten-1 {
  color: #7e57c2 !important; }

.bg-deep-purple.bg-lighten-1 {
  background-color: #7e57c2 !important; }

.btn-deep-purple.btn-lighten-1 {
  border-color: #512da8 !important;
  background-color: #7e57c2 !important; }
  .btn-deep-purple.btn-lighten-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-lighten-1:focus, .btn-deep-purple.btn-lighten-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-lighten-1 {
  border-color: #7e57c2 !important;
  color: #7e57c2 !important; }
  .btn-outline-deep-purple.btn-outline-lighten-1:hover {
    background-color: #7e57c2 !important; }

.progress-deep-purple.progress-lighten-1 {
  background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #7e57c2; }
  .progress-deep-purple.progress-lighten-1[value]::-ms-fill {
    background-color: #7e57c2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-lighten-1 .progress-bar {
      background-color: #7e57c2; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7e57c2 !important; }

.border-deep-purple.border-lighten-1 {
  border: 1px solid #7e57c2 !important; }

.border-top-deep-purple.border-top-lighten-1 {
  border-top: 1px solid #7e57c2 !important; }

.border-bottom-deep-purple.border-bottom-lighten-1 {
  border-bottom: 1px solid #7e57c2 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-lighten-1 {
  border-left: 1px solid #7e57c2 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-lighten-1 {
  border-right: 1px solid #7e57c2 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-lighten-1 {
  border-right: 1px solid #7e57c2 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-lighten-1 {
  border-left: 1px solid #7e57c2 !important; }

.overlay-deep-purple.overlay-lighten-1 {
  background: #7e57c2;
  /* The Fallback */
  background: rgba(126, 87, 194, 0.8); }

.deep-purple {
  color: #673ab7 !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }
  .bg-deep-purple .card-header, .bg-deep-purple .card-footer {
    background-color: transparent; }

.toast-deep-purple {
  background-color: #673ab7; }

.alert-deep-purple {
  border-color: #673ab7 !important;
  background-color: #794ec7 !important;
  color: #1b0f2f !important; }
  .alert-deep-purple .alert-link {
    color: #090510 !important; }

.border-deep-purple {
  border-color: #673ab7; }

.border-top-deep-purple {
  border-top-color: #673ab7; }

.border-bottom-deep-purple {
  border-bottom-color: #673ab7; }

[dir="ltr"] .border-left-deep-purple {
  border-left-color: #673ab7; }

[dir="ltr"] .border-right-deep-purple {
  border-right-color: #673ab7; }

[dir="rtl"] .border-left-deep-purple {
  border-right-color: #673ab7; }

[dir="rtl"] .border-right-deep-purple {
  border-left-color: #673ab7; }

.badge-deep-purple {
  background-color: #673ab7; }

.panel-deep-purple {
  border-color: #673ab7; }
  .panel-deep-purple .panel-heading {
    color: #FFF;
    border-color: #673ab7;
    background-color: #7446c4; }

.bg-deep-purple.tag-glow, .border-deep-purple.tag-glow {
  box-shadow: 0px 0px 10px #673ab7; }

.overlay-deep-purple {
  background: #673ab7;
  /* The Fallback */
  background: rgba(103, 58, 183, 0.8); }

.card.card-outline-deep-purple {
  border-width: 1px;
  border-style: solid;
  border-color: #673ab7;
  background-color: transparent; }
  .card.card-outline-deep-purple .card-header, .card.card-outline-deep-purple .card-footer {
    background-color: transparent; }

.btn-deep-purple.btn-flat {
  background-color: transparent !important;
  color: #673ab7;
  border: none; }

.btn-deep-purple.btn-raised, .btn-deep-purple.btn-fab {
  background-color: #673ab7 !important;
  color: #fff !important;
  border-color: #673ab7; }
  .btn-deep-purple.btn-raised.active, .btn-deep-purple.btn-fab.active {
    background-color: #58319c !important;
    border-color: #58319c !important; }

.btn-group-raised .btn-deep-purple {
  background-color: #673ab7 !important;
  color: #fff !important; }

.btn-outline-deep-purple {
  border: 1px solid;
  border-color: #673ab7;
  background-color: transparent;
  color: #673ab7;
  box-shadow: none !important; }
  .btn-outline-deep-purple:focus {
    background-color: transparent !important;
    color: #673ab7 !important;
    box-shadow: transparent !important; }
  .btn-outline-deep-purple.active {
    background-color: #673ab7 !important;
    color: #FFF !important; }
  .btn-outline-deep-purple:hover {
    background-color: #6036ab !important;
    color: #FFF !important; }
    .btn-outline-deep-purple:hover svg {
      color: #FFF !important; }

.btn-outline-deep-purple.btn-raised, .btn-outline-deep-purple.btn-fab {
  border: 1px solid;
  border-color: #673ab7;
  background-color: transparent;
  color: #673ab7;
  box-shadow: none !important; }
  .btn-outline-deep-purple.btn-raised:focus, .btn-outline-deep-purple.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-deep-purple.btn-raised.active, .btn-outline-deep-purple.btn-fab.active {
    background-color: #673ab7 !important;
    color: #FFF !important; }
  .btn-outline-deep-purple.btn-raised:hover, .btn-outline-deep-purple.btn-fab:hover {
    background-color: #6036ab !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-deep-purple {
  background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-webkit-progress-value {
    background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-moz-progress-bar {
    background-color: #673ab7; }
  .progress .progress-bar.progress-bar-deep-purple[value]::-ms-fill {
    background-color: #673ab7; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-deep-purple .progress-bar {
      background-color: #673ab7; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #673ab7 !important; }

.deep-purple.darken-1 {
  color: #5e35b1 !important; }

.bg-deep-purple.bg-darken-1 {
  background-color: #5e35b1 !important; }

.btn-deep-purple.btn-darken-1 {
  border-color: #512da8 !important;
  background-color: #5e35b1 !important; }
  .btn-deep-purple.btn-darken-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-1:focus, .btn-deep-purple.btn-darken-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-1 {
  border-color: #5e35b1 !important;
  color: #5e35b1 !important; }
  .btn-outline-deep-purple.btn-outline-darken-1:hover {
    background-color: #5e35b1 !important; }

.progress-deep-purple.progress-darken-1 {
  background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-webkit-progress-value {
    background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-moz-progress-bar {
    background-color: #5e35b1; }
  .progress-deep-purple.progress-darken-1[value]::-ms-fill {
    background-color: #5e35b1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-1 .progress-bar {
      background-color: #5e35b1; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5e35b1 !important; }

.border-deep-purple.border-darken-1 {
  border: 1px solid #5e35b1 !important; }

.border-top-deep-purple.border-top-darken-1 {
  border-top: 1px solid #5e35b1 !important; }

.border-bottom-deep-purple.border-bottom-darken-1 {
  border-bottom: 1px solid #5e35b1 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-1 {
  border-left: 1px solid #5e35b1 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-1 {
  border-right: 1px solid #5e35b1 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-1 {
  border-right: 1px solid #5e35b1 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-1 {
  border-left: 1px solid #5e35b1 !important; }

.overlay-deep-purple.overlay-darken-1 {
  background: #5e35b1;
  /* The Fallback */
  background: rgba(94, 53, 177, 0.8); }

.deep-purple.darken-2 {
  color: #512da8 !important; }

.bg-deep-purple.bg-darken-2 {
  background-color: #512da8 !important; }

.btn-deep-purple.btn-darken-2 {
  border-color: #512da8 !important;
  background-color: #512da8 !important; }
  .btn-deep-purple.btn-darken-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-2:focus, .btn-deep-purple.btn-darken-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-2 {
  border-color: #512da8 !important;
  color: #512da8 !important; }
  .btn-outline-deep-purple.btn-outline-darken-2:hover {
    background-color: #512da8 !important; }

.progress-deep-purple.progress-darken-2 {
  background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-webkit-progress-value {
    background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-moz-progress-bar {
    background-color: #512da8; }
  .progress-deep-purple.progress-darken-2[value]::-ms-fill {
    background-color: #512da8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-2 .progress-bar {
      background-color: #512da8; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #512da8 !important; }

.border-deep-purple.border-darken-2 {
  border: 1px solid #512da8 !important; }

.border-top-deep-purple.border-top-darken-2 {
  border-top: 1px solid #512da8 !important; }

.border-bottom-deep-purple.border-bottom-darken-2 {
  border-bottom: 1px solid #512da8 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-2 {
  border-left: 1px solid #512da8 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-2 {
  border-right: 1px solid #512da8 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-2 {
  border-right: 1px solid #512da8 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-2 {
  border-left: 1px solid #512da8 !important; }

.overlay-deep-purple.overlay-darken-2 {
  background: #512da8;
  /* The Fallback */
  background: rgba(81, 45, 168, 0.8); }

.deep-purple.darken-3 {
  color: #4527a0 !important; }

.bg-deep-purple.bg-darken-3 {
  background-color: #4527a0 !important; }

.btn-deep-purple.btn-darken-3 {
  border-color: #512da8 !important;
  background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-3:focus, .btn-deep-purple.btn-darken-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-3 {
  border-color: #4527a0 !important;
  color: #4527a0 !important; }
  .btn-outline-deep-purple.btn-outline-darken-3:hover {
    background-color: #4527a0 !important; }

.progress-deep-purple.progress-darken-3 {
  background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4527a0; }
  .progress-deep-purple.progress-darken-3[value]::-ms-fill {
    background-color: #4527a0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-3 .progress-bar {
      background-color: #4527a0; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4527a0 !important; }

.border-deep-purple.border-darken-3 {
  border: 1px solid #4527a0 !important; }

.border-top-deep-purple.border-top-darken-3 {
  border-top: 1px solid #4527a0 !important; }

.border-bottom-deep-purple.border-bottom-darken-3 {
  border-bottom: 1px solid #4527a0 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-3 {
  border-left: 1px solid #4527a0 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-3 {
  border-right: 1px solid #4527a0 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-3 {
  border-right: 1px solid #4527a0 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-3 {
  border-left: 1px solid #4527a0 !important; }

.overlay-deep-purple.overlay-darken-3 {
  background: #4527a0;
  /* The Fallback */
  background: rgba(69, 39, 160, 0.8); }

.deep-purple.darken-4 {
  color: #311b92 !important; }

.bg-deep-purple.bg-darken-4 {
  background-color: #311b92 !important; }

.btn-deep-purple.btn-darken-4 {
  border-color: #512da8 !important;
  background-color: #311b92 !important; }
  .btn-deep-purple.btn-darken-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-darken-4:focus, .btn-deep-purple.btn-darken-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-darken-4 {
  border-color: #311b92 !important;
  color: #311b92 !important; }
  .btn-outline-deep-purple.btn-outline-darken-4:hover {
    background-color: #311b92 !important; }

.progress-deep-purple.progress-darken-4 {
  background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-webkit-progress-value {
    background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-moz-progress-bar {
    background-color: #311b92; }
  .progress-deep-purple.progress-darken-4[value]::-ms-fill {
    background-color: #311b92; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-darken-4 .progress-bar {
      background-color: #311b92; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #311b92 !important; }

.border-deep-purple.border-darken-4 {
  border: 1px solid #311b92 !important; }

.border-top-deep-purple.border-top-darken-4 {
  border-top: 1px solid #311b92 !important; }

.border-bottom-deep-purple.border-bottom-darken-4 {
  border-bottom: 1px solid #311b92 !important; }

[dir="ltr"] .border-left-deep-purple.border-left-darken-4 {
  border-left: 1px solid #311b92 !important; }

[dir="ltr"] .border-right-deep-purple.border-right-darken-4 {
  border-right: 1px solid #311b92 !important; }

[dir="rtl"] .border-left-deep-purple.border-left-darken-4 {
  border-right: 1px solid #311b92 !important; }

[dir="rtl"] .border-right-deep-purple.border-right-darken-4 {
  border-left: 1px solid #311b92 !important; }

.overlay-deep-purple.overlay-darken-4 {
  background: #311b92;
  /* The Fallback */
  background: rgba(49, 27, 146, 0.8); }

.deep-purple.accent-1 {
  color: #b388ff !important; }

.bg-deep-purple.bg-accent-1 {
  background-color: #b388ff !important; }

.btn-deep-purple.btn-accent-1 {
  border-color: #512da8 !important;
  background-color: #b388ff !important; }
  .btn-deep-purple.btn-accent-1:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-1:focus, .btn-deep-purple.btn-accent-1:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-1 {
  border-color: #b388ff !important;
  color: #b388ff !important; }
  .btn-outline-deep-purple.btn-outline-accent-1:hover {
    background-color: #b388ff !important; }

.progress-deep-purple.progress-accent-1 {
  background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b388ff; }
  .progress-deep-purple.progress-accent-1[value]::-ms-fill {
    background-color: #b388ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-1 .progress-bar {
      background-color: #b388ff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b388ff !important; }

.border-deep-purple.border-accent-1 {
  border: 1px solid #b388ff !important; }

.border-top-deep-purple.border-top-accent-1 {
  border-top: 1px solid #b388ff !important; }

.border-bottom-deep-purple.border-bottom-accent-1 {
  border-bottom: 1px solid #b388ff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-1 {
  border-left: 1px solid #b388ff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-1 {
  border-right: 1px solid #b388ff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-1 {
  border-right: 1px solid #b388ff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-1 {
  border-left: 1px solid #b388ff !important; }

.overlay-deep-purple.overlay-accent-1 {
  background: #b388ff;
  /* The Fallback */
  background: rgba(179, 136, 255, 0.8); }

.deep-purple.accent-2 {
  color: #7c4dff !important; }

.bg-deep-purple.bg-accent-2 {
  background-color: #7c4dff !important; }

.btn-deep-purple.btn-accent-2 {
  border-color: #512da8 !important;
  background-color: #7c4dff !important; }
  .btn-deep-purple.btn-accent-2:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-2:focus, .btn-deep-purple.btn-accent-2:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-2 {
  border-color: #7c4dff !important;
  color: #7c4dff !important; }
  .btn-outline-deep-purple.btn-outline-accent-2:hover {
    background-color: #7c4dff !important; }

.progress-deep-purple.progress-accent-2 {
  background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-webkit-progress-value {
    background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-moz-progress-bar {
    background-color: #7c4dff; }
  .progress-deep-purple.progress-accent-2[value]::-ms-fill {
    background-color: #7c4dff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-2 .progress-bar {
      background-color: #7c4dff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7c4dff !important; }

.border-deep-purple.border-accent-2 {
  border: 1px solid #7c4dff !important; }

.border-top-deep-purple.border-top-accent-2 {
  border-top: 1px solid #7c4dff !important; }

.border-bottom-deep-purple.border-bottom-accent-2 {
  border-bottom: 1px solid #7c4dff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-2 {
  border-left: 1px solid #7c4dff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-2 {
  border-right: 1px solid #7c4dff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-2 {
  border-right: 1px solid #7c4dff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-2 {
  border-left: 1px solid #7c4dff !important; }

.overlay-deep-purple.overlay-accent-2 {
  background: #7c4dff;
  /* The Fallback */
  background: rgba(124, 77, 255, 0.8); }

.deep-purple.accent-3 {
  color: #651fff !important; }

.bg-deep-purple.bg-accent-3 {
  background-color: #651fff !important; }

.btn-deep-purple.btn-accent-3 {
  border-color: #512da8 !important;
  background-color: #651fff !important; }
  .btn-deep-purple.btn-accent-3:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-3:focus, .btn-deep-purple.btn-accent-3:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-3 {
  border-color: #651fff !important;
  color: #651fff !important; }
  .btn-outline-deep-purple.btn-outline-accent-3:hover {
    background-color: #651fff !important; }

.progress-deep-purple.progress-accent-3 {
  background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-webkit-progress-value {
    background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-moz-progress-bar {
    background-color: #651fff; }
  .progress-deep-purple.progress-accent-3[value]::-ms-fill {
    background-color: #651fff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-3 .progress-bar {
      background-color: #651fff; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #651fff !important; }

.border-deep-purple.border-accent-3 {
  border: 1px solid #651fff !important; }

.border-top-deep-purple.border-top-accent-3 {
  border-top: 1px solid #651fff !important; }

.border-bottom-deep-purple.border-bottom-accent-3 {
  border-bottom: 1px solid #651fff !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-3 {
  border-left: 1px solid #651fff !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-3 {
  border-right: 1px solid #651fff !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-3 {
  border-right: 1px solid #651fff !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-3 {
  border-left: 1px solid #651fff !important; }

.overlay-deep-purple.overlay-accent-3 {
  background: #651fff;
  /* The Fallback */
  background: rgba(101, 31, 255, 0.8); }

.deep-purple.accent-4 {
  color: #6200ea !important; }

.bg-deep-purple.bg-accent-4 {
  background-color: #6200ea !important; }

.btn-deep-purple.btn-accent-4 {
  border-color: #512da8 !important;
  background-color: #6200ea !important; }
  .btn-deep-purple.btn-accent-4:hover {
    border-color: #512da8 !important;
    background-color: #4527a0 !important; }
  .btn-deep-purple.btn-accent-4:focus, .btn-deep-purple.btn-accent-4:active {
    border-color: #4527a0 !important;
    background-color: #311b92 !important; }

.btn-outline-deep-purple.btn-outline-accent-4 {
  border-color: #6200ea !important;
  color: #6200ea !important; }
  .btn-outline-deep-purple.btn-outline-accent-4:hover {
    background-color: #6200ea !important; }

.progress-deep-purple.progress-accent-4 {
  background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-webkit-progress-value {
    background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-moz-progress-bar {
    background-color: #6200ea; }
  .progress-deep-purple.progress-accent-4[value]::-ms-fill {
    background-color: #6200ea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-purple.progress-accent-4 .progress-bar {
      background-color: #6200ea; } }

input:focus ~ .bg-deep-purple {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6200ea !important; }

.border-deep-purple.border-accent-4 {
  border: 1px solid #6200ea !important; }

.border-top-deep-purple.border-top-accent-4 {
  border-top: 1px solid #6200ea !important; }

.border-bottom-deep-purple.border-bottom-accent-4 {
  border-bottom: 1px solid #6200ea !important; }

[dir="ltr"] .border-left-deep-purple.border-left-accent-4 {
  border-left: 1px solid #6200ea !important; }

[dir="ltr"] .border-right-deep-purple.border-right-accent-4 {
  border-right: 1px solid #6200ea !important; }

[dir="rtl"] .border-left-deep-purple.border-left-accent-4 {
  border-right: 1px solid #6200ea !important; }

[dir="rtl"] .border-right-deep-purple.border-right-accent-4 {
  border-left: 1px solid #6200ea !important; }

.overlay-deep-purple.overlay-accent-4 {
  background: #6200ea;
  /* The Fallback */
  background: rgba(98, 0, 234, 0.8); }

.indigo.lighten-5 {
  color: #e8eaf6 !important; }

.bg-indigo.bg-lighten-5 {
  background-color: #e8eaf6 !important; }

.btn-indigo.btn-lighten-5 {
  border-color: #303f9f !important;
  background-color: #e8eaf6 !important; }
  .btn-indigo.btn-lighten-5:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-5:focus, .btn-indigo.btn-lighten-5:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-5 {
  border-color: #e8eaf6 !important;
  color: #e8eaf6 !important; }
  .btn-outline-indigo.btn-outline-lighten-5:hover {
    background-color: #e8eaf6 !important; }

.progress-indigo.progress-lighten-5 {
  background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8eaf6; }
  .progress-indigo.progress-lighten-5[value]::-ms-fill {
    background-color: #e8eaf6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-5 .progress-bar {
      background-color: #e8eaf6; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8eaf6 !important; }

.border-indigo.border-lighten-5 {
  border: 1px solid #e8eaf6 !important; }

.border-top-indigo.border-top-lighten-5 {
  border-top: 1px solid #e8eaf6 !important; }

.border-bottom-indigo.border-bottom-lighten-5 {
  border-bottom: 1px solid #e8eaf6 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-5 {
  border-left: 1px solid #e8eaf6 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-5 {
  border-right: 1px solid #e8eaf6 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-5 {
  border-right: 1px solid #e8eaf6 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-5 {
  border-left: 1px solid #e8eaf6 !important; }

.overlay-indigo.overlay-lighten-5 {
  background: #e8eaf6;
  /* The Fallback */
  background: rgba(232, 234, 246, 0.8); }

.indigo.lighten-4 {
  color: #c5cae9 !important; }

.bg-indigo.bg-lighten-4 {
  background-color: #c5cae9 !important; }

.btn-indigo.btn-lighten-4 {
  border-color: #303f9f !important;
  background-color: #c5cae9 !important; }
  .btn-indigo.btn-lighten-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-4:focus, .btn-indigo.btn-lighten-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-4 {
  border-color: #c5cae9 !important;
  color: #c5cae9 !important; }
  .btn-outline-indigo.btn-outline-lighten-4:hover {
    background-color: #c5cae9 !important; }

.progress-indigo.progress-lighten-4 {
  background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c5cae9; }
  .progress-indigo.progress-lighten-4[value]::-ms-fill {
    background-color: #c5cae9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-4 .progress-bar {
      background-color: #c5cae9; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5cae9 !important; }

.border-indigo.border-lighten-4 {
  border: 1px solid #c5cae9 !important; }

.border-top-indigo.border-top-lighten-4 {
  border-top: 1px solid #c5cae9 !important; }

.border-bottom-indigo.border-bottom-lighten-4 {
  border-bottom: 1px solid #c5cae9 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-4 {
  border-left: 1px solid #c5cae9 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-4 {
  border-right: 1px solid #c5cae9 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-4 {
  border-right: 1px solid #c5cae9 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-4 {
  border-left: 1px solid #c5cae9 !important; }

.overlay-indigo.overlay-lighten-4 {
  background: #c5cae9;
  /* The Fallback */
  background: rgba(197, 202, 233, 0.8); }

.indigo.lighten-3 {
  color: #9fa8da !important; }

.bg-indigo.bg-lighten-3 {
  background-color: #9fa8da !important; }

.btn-indigo.btn-lighten-3 {
  border-color: #303f9f !important;
  background-color: #9fa8da !important; }
  .btn-indigo.btn-lighten-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-3:focus, .btn-indigo.btn-lighten-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-3 {
  border-color: #9fa8da !important;
  color: #9fa8da !important; }
  .btn-outline-indigo.btn-outline-lighten-3:hover {
    background-color: #9fa8da !important; }

.progress-indigo.progress-lighten-3 {
  background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #9fa8da; }
  .progress-indigo.progress-lighten-3[value]::-ms-fill {
    background-color: #9fa8da; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-3 .progress-bar {
      background-color: #9fa8da; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9fa8da !important; }

.border-indigo.border-lighten-3 {
  border: 1px solid #9fa8da !important; }

.border-top-indigo.border-top-lighten-3 {
  border-top: 1px solid #9fa8da !important; }

.border-bottom-indigo.border-bottom-lighten-3 {
  border-bottom: 1px solid #9fa8da !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-3 {
  border-left: 1px solid #9fa8da !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-3 {
  border-right: 1px solid #9fa8da !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-3 {
  border-right: 1px solid #9fa8da !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-3 {
  border-left: 1px solid #9fa8da !important; }

.overlay-indigo.overlay-lighten-3 {
  background: #9fa8da;
  /* The Fallback */
  background: rgba(159, 168, 218, 0.8); }

.indigo.lighten-2 {
  color: #7986cb !important; }

.bg-indigo.bg-lighten-2 {
  background-color: #7986cb !important; }

.btn-indigo.btn-lighten-2 {
  border-color: #303f9f !important;
  background-color: #7986cb !important; }
  .btn-indigo.btn-lighten-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-2:focus, .btn-indigo.btn-lighten-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-2 {
  border-color: #7986cb !important;
  color: #7986cb !important; }
  .btn-outline-indigo.btn-outline-lighten-2:hover {
    background-color: #7986cb !important; }

.progress-indigo.progress-lighten-2 {
  background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #7986cb; }
  .progress-indigo.progress-lighten-2[value]::-ms-fill {
    background-color: #7986cb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-2 .progress-bar {
      background-color: #7986cb; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7986cb !important; }

.border-indigo.border-lighten-2 {
  border: 1px solid #7986cb !important; }

.border-top-indigo.border-top-lighten-2 {
  border-top: 1px solid #7986cb !important; }

.border-bottom-indigo.border-bottom-lighten-2 {
  border-bottom: 1px solid #7986cb !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-2 {
  border-left: 1px solid #7986cb !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-2 {
  border-right: 1px solid #7986cb !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-2 {
  border-right: 1px solid #7986cb !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-2 {
  border-left: 1px solid #7986cb !important; }

.overlay-indigo.overlay-lighten-2 {
  background: #7986cb;
  /* The Fallback */
  background: rgba(121, 134, 203, 0.8); }

.indigo.lighten-1 {
  color: #5c6bc0 !important; }

.bg-indigo.bg-lighten-1 {
  background-color: #5c6bc0 !important; }

.btn-indigo.btn-lighten-1 {
  border-color: #303f9f !important;
  background-color: #5c6bc0 !important; }
  .btn-indigo.btn-lighten-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-lighten-1:focus, .btn-indigo.btn-lighten-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-lighten-1 {
  border-color: #5c6bc0 !important;
  color: #5c6bc0 !important; }
  .btn-outline-indigo.btn-outline-lighten-1:hover {
    background-color: #5c6bc0 !important; }

.progress-indigo.progress-lighten-1 {
  background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #5c6bc0; }
  .progress-indigo.progress-lighten-1[value]::-ms-fill {
    background-color: #5c6bc0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-lighten-1 .progress-bar {
      background-color: #5c6bc0; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5c6bc0 !important; }

.border-indigo.border-lighten-1 {
  border: 1px solid #5c6bc0 !important; }

.border-top-indigo.border-top-lighten-1 {
  border-top: 1px solid #5c6bc0 !important; }

.border-bottom-indigo.border-bottom-lighten-1 {
  border-bottom: 1px solid #5c6bc0 !important; }

[dir="ltr"] .border-left-indigo.border-left-lighten-1 {
  border-left: 1px solid #5c6bc0 !important; }

[dir="ltr"] .border-right-indigo.border-right-lighten-1 {
  border-right: 1px solid #5c6bc0 !important; }

[dir="rtl"] .border-left-indigo.border-left-lighten-1 {
  border-right: 1px solid #5c6bc0 !important; }

[dir="rtl"] .border-right-indigo.border-right-lighten-1 {
  border-left: 1px solid #5c6bc0 !important; }

.overlay-indigo.overlay-lighten-1 {
  background: #5c6bc0;
  /* The Fallback */
  background: rgba(92, 107, 192, 0.8); }

.indigo {
  color: #3f51b5 !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }
  .bg-indigo .card-header, .bg-indigo .card-footer {
    background-color: transparent; }

.toast-indigo {
  background-color: #3f51b5; }

.alert-indigo {
  border-color: #3f51b5 !important;
  background-color: #5465c4 !important;
  color: #111631 !important; }
  .alert-indigo .alert-link {
    color: #060812 !important; }

.border-indigo {
  border-color: #3f51b5; }

.border-top-indigo {
  border-top-color: #3f51b5; }

.border-bottom-indigo {
  border-bottom-color: #3f51b5; }

[dir="ltr"] .border-left-indigo {
  border-left-color: #3f51b5; }

[dir="ltr"] .border-right-indigo {
  border-right-color: #3f51b5; }

[dir="rtl"] .border-left-indigo {
  border-right-color: #3f51b5; }

[dir="rtl"] .border-right-indigo {
  border-left-color: #3f51b5; }

.badge-indigo {
  background-color: #3f51b5; }

.panel-indigo {
  border-color: #3f51b5; }
  .panel-indigo .panel-heading {
    color: #FFF;
    border-color: #3f51b5;
    background-color: #4d5ec1; }

.bg-indigo.tag-glow, .border-indigo.tag-glow {
  box-shadow: 0px 0px 10px #3f51b5; }

.overlay-indigo {
  background: #3f51b5;
  /* The Fallback */
  background: rgba(63, 81, 181, 0.8); }

.card.card-outline-indigo {
  border-width: 1px;
  border-style: solid;
  border-color: #3f51b5;
  background-color: transparent; }
  .card.card-outline-indigo .card-header, .card.card-outline-indigo .card-footer {
    background-color: transparent; }

.btn-indigo.btn-flat {
  background-color: transparent !important;
  color: #3f51b5;
  border: none; }

.btn-indigo.btn-raised, .btn-indigo.btn-fab {
  background-color: #3f51b5 !important;
  color: #fff !important;
  border-color: #3f51b5; }
  .btn-indigo.btn-raised.active, .btn-indigo.btn-fab.active {
    background-color: #36459b !important;
    border-color: #36459b !important; }

.btn-group-raised .btn-indigo {
  background-color: #3f51b5 !important;
  color: #fff !important; }

.btn-outline-indigo {
  border: 1px solid;
  border-color: #3f51b5;
  background-color: transparent;
  color: #3f51b5;
  box-shadow: none !important; }
  .btn-outline-indigo:focus {
    background-color: transparent !important;
    color: #3f51b5 !important;
    box-shadow: transparent !important; }
  .btn-outline-indigo.active {
    background-color: #3f51b5 !important;
    color: #FFF !important; }
  .btn-outline-indigo:hover {
    background-color: #3b4caa !important;
    color: #FFF !important; }
    .btn-outline-indigo:hover svg {
      color: #FFF !important; }

.btn-outline-indigo.btn-raised, .btn-outline-indigo.btn-fab {
  border: 1px solid;
  border-color: #3f51b5;
  background-color: transparent;
  color: #3f51b5;
  box-shadow: none !important; }
  .btn-outline-indigo.btn-raised:focus, .btn-outline-indigo.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-indigo.btn-raised.active, .btn-outline-indigo.btn-fab.active {
    background-color: #3f51b5 !important;
    color: #FFF !important; }
  .btn-outline-indigo.btn-raised:hover, .btn-outline-indigo.btn-fab:hover {
    background-color: #3b4caa !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-indigo {
  background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-webkit-progress-value {
    background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-moz-progress-bar {
    background-color: #3f51b5; }
  .progress .progress-bar.progress-bar-indigo[value]::-ms-fill {
    background-color: #3f51b5; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-indigo .progress-bar {
      background-color: #3f51b5; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3f51b5 !important; }

.indigo.darken-1 {
  color: #3949ab !important; }

.bg-indigo.bg-darken-1 {
  background-color: #3949ab !important; }

.btn-indigo.btn-darken-1 {
  border-color: #303f9f !important;
  background-color: #3949ab !important; }
  .btn-indigo.btn-darken-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-1:focus, .btn-indigo.btn-darken-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-1 {
  border-color: #3949ab !important;
  color: #3949ab !important; }
  .btn-outline-indigo.btn-outline-darken-1:hover {
    background-color: #3949ab !important; }

.progress-indigo.progress-darken-1 {
  background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-webkit-progress-value {
    background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-moz-progress-bar {
    background-color: #3949ab; }
  .progress-indigo.progress-darken-1[value]::-ms-fill {
    background-color: #3949ab; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-1 .progress-bar {
      background-color: #3949ab; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3949ab !important; }

.border-indigo.border-darken-1 {
  border: 1px solid #3949ab !important; }

.border-top-indigo.border-top-darken-1 {
  border-top: 1px solid #3949ab !important; }

.border-bottom-indigo.border-bottom-darken-1 {
  border-bottom: 1px solid #3949ab !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-1 {
  border-left: 1px solid #3949ab !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-1 {
  border-right: 1px solid #3949ab !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-1 {
  border-right: 1px solid #3949ab !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-1 {
  border-left: 1px solid #3949ab !important; }

.overlay-indigo.overlay-darken-1 {
  background: #3949ab;
  /* The Fallback */
  background: rgba(57, 73, 171, 0.8); }

.indigo.darken-2 {
  color: #303f9f !important; }

.bg-indigo.bg-darken-2 {
  background-color: #303f9f !important; }

.btn-indigo.btn-darken-2 {
  border-color: #303f9f !important;
  background-color: #303f9f !important; }
  .btn-indigo.btn-darken-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-2:focus, .btn-indigo.btn-darken-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-2 {
  border-color: #303f9f !important;
  color: #303f9f !important; }
  .btn-outline-indigo.btn-outline-darken-2:hover {
    background-color: #303f9f !important; }

.progress-indigo.progress-darken-2 {
  background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-webkit-progress-value {
    background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-moz-progress-bar {
    background-color: #303f9f; }
  .progress-indigo.progress-darken-2[value]::-ms-fill {
    background-color: #303f9f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-2 .progress-bar {
      background-color: #303f9f; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #303f9f !important; }

.border-indigo.border-darken-2 {
  border: 1px solid #303f9f !important; }

.border-top-indigo.border-top-darken-2 {
  border-top: 1px solid #303f9f !important; }

.border-bottom-indigo.border-bottom-darken-2 {
  border-bottom: 1px solid #303f9f !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-2 {
  border-left: 1px solid #303f9f !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-2 {
  border-right: 1px solid #303f9f !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-2 {
  border-right: 1px solid #303f9f !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-2 {
  border-left: 1px solid #303f9f !important; }

.overlay-indigo.overlay-darken-2 {
  background: #303f9f;
  /* The Fallback */
  background: rgba(48, 63, 159, 0.8); }

.indigo.darken-3 {
  color: #283593 !important; }

.bg-indigo.bg-darken-3 {
  background-color: #283593 !important; }

.btn-indigo.btn-darken-3 {
  border-color: #303f9f !important;
  background-color: #283593 !important; }
  .btn-indigo.btn-darken-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-3:focus, .btn-indigo.btn-darken-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-3 {
  border-color: #283593 !important;
  color: #283593 !important; }
  .btn-outline-indigo.btn-outline-darken-3:hover {
    background-color: #283593 !important; }

.progress-indigo.progress-darken-3 {
  background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-webkit-progress-value {
    background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-moz-progress-bar {
    background-color: #283593; }
  .progress-indigo.progress-darken-3[value]::-ms-fill {
    background-color: #283593; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-3 .progress-bar {
      background-color: #283593; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #283593 !important; }

.border-indigo.border-darken-3 {
  border: 1px solid #283593 !important; }

.border-top-indigo.border-top-darken-3 {
  border-top: 1px solid #283593 !important; }

.border-bottom-indigo.border-bottom-darken-3 {
  border-bottom: 1px solid #283593 !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-3 {
  border-left: 1px solid #283593 !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-3 {
  border-right: 1px solid #283593 !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-3 {
  border-right: 1px solid #283593 !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-3 {
  border-left: 1px solid #283593 !important; }

.overlay-indigo.overlay-darken-3 {
  background: #283593;
  /* The Fallback */
  background: rgba(40, 53, 147, 0.8); }

.indigo.darken-4 {
  color: #1a237e !important; }

.bg-indigo.bg-darken-4 {
  background-color: #1a237e !important; }

.btn-indigo.btn-darken-4 {
  border-color: #303f9f !important;
  background-color: #1a237e !important; }
  .btn-indigo.btn-darken-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-darken-4:focus, .btn-indigo.btn-darken-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-darken-4 {
  border-color: #1a237e !important;
  color: #1a237e !important; }
  .btn-outline-indigo.btn-outline-darken-4:hover {
    background-color: #1a237e !important; }

.progress-indigo.progress-darken-4 {
  background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1a237e; }
  .progress-indigo.progress-darken-4[value]::-ms-fill {
    background-color: #1a237e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-darken-4 .progress-bar {
      background-color: #1a237e; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1a237e !important; }

.border-indigo.border-darken-4 {
  border: 1px solid #1a237e !important; }

.border-top-indigo.border-top-darken-4 {
  border-top: 1px solid #1a237e !important; }

.border-bottom-indigo.border-bottom-darken-4 {
  border-bottom: 1px solid #1a237e !important; }

[dir="ltr"] .border-left-indigo.border-left-darken-4 {
  border-left: 1px solid #1a237e !important; }

[dir="ltr"] .border-right-indigo.border-right-darken-4 {
  border-right: 1px solid #1a237e !important; }

[dir="rtl"] .border-left-indigo.border-left-darken-4 {
  border-right: 1px solid #1a237e !important; }

[dir="rtl"] .border-right-indigo.border-right-darken-4 {
  border-left: 1px solid #1a237e !important; }

.overlay-indigo.overlay-darken-4 {
  background: #1a237e;
  /* The Fallback */
  background: rgba(26, 35, 126, 0.8); }

.indigo.accent-1 {
  color: #8c9eff !important; }

.bg-indigo.bg-accent-1 {
  background-color: #8c9eff !important; }

.btn-indigo.btn-accent-1 {
  border-color: #303f9f !important;
  background-color: #8c9eff !important; }
  .btn-indigo.btn-accent-1:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-1:focus, .btn-indigo.btn-accent-1:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-1 {
  border-color: #8c9eff !important;
  color: #8c9eff !important; }
  .btn-outline-indigo.btn-outline-accent-1:hover {
    background-color: #8c9eff !important; }

.progress-indigo.progress-accent-1 {
  background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-webkit-progress-value {
    background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-moz-progress-bar {
    background-color: #8c9eff; }
  .progress-indigo.progress-accent-1[value]::-ms-fill {
    background-color: #8c9eff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-1 .progress-bar {
      background-color: #8c9eff; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8c9eff !important; }

.border-indigo.border-accent-1 {
  border: 1px solid #8c9eff !important; }

.border-top-indigo.border-top-accent-1 {
  border-top: 1px solid #8c9eff !important; }

.border-bottom-indigo.border-bottom-accent-1 {
  border-bottom: 1px solid #8c9eff !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-1 {
  border-left: 1px solid #8c9eff !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-1 {
  border-right: 1px solid #8c9eff !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-1 {
  border-right: 1px solid #8c9eff !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-1 {
  border-left: 1px solid #8c9eff !important; }

.overlay-indigo.overlay-accent-1 {
  background: #8c9eff;
  /* The Fallback */
  background: rgba(140, 158, 255, 0.8); }

.indigo.accent-2 {
  color: #536dfe !important; }

.bg-indigo.bg-accent-2 {
  background-color: #536dfe !important; }

.btn-indigo.btn-accent-2 {
  border-color: #303f9f !important;
  background-color: #536dfe !important; }
  .btn-indigo.btn-accent-2:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-2:focus, .btn-indigo.btn-accent-2:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-2 {
  border-color: #536dfe !important;
  color: #536dfe !important; }
  .btn-outline-indigo.btn-outline-accent-2:hover {
    background-color: #536dfe !important; }

.progress-indigo.progress-accent-2 {
  background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-webkit-progress-value {
    background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-moz-progress-bar {
    background-color: #536dfe; }
  .progress-indigo.progress-accent-2[value]::-ms-fill {
    background-color: #536dfe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-2 .progress-bar {
      background-color: #536dfe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #536dfe !important; }

.border-indigo.border-accent-2 {
  border: 1px solid #536dfe !important; }

.border-top-indigo.border-top-accent-2 {
  border-top: 1px solid #536dfe !important; }

.border-bottom-indigo.border-bottom-accent-2 {
  border-bottom: 1px solid #536dfe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-2 {
  border-left: 1px solid #536dfe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-2 {
  border-right: 1px solid #536dfe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-2 {
  border-right: 1px solid #536dfe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-2 {
  border-left: 1px solid #536dfe !important; }

.overlay-indigo.overlay-accent-2 {
  background: #536dfe;
  /* The Fallback */
  background: rgba(83, 109, 254, 0.8); }

.indigo.accent-3 {
  color: #3d5afe !important; }

.bg-indigo.bg-accent-3 {
  background-color: #3d5afe !important; }

.btn-indigo.btn-accent-3 {
  border-color: #303f9f !important;
  background-color: #3d5afe !important; }
  .btn-indigo.btn-accent-3:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-3:focus, .btn-indigo.btn-accent-3:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-3 {
  border-color: #3d5afe !important;
  color: #3d5afe !important; }
  .btn-outline-indigo.btn-outline-accent-3:hover {
    background-color: #3d5afe !important; }

.progress-indigo.progress-accent-3 {
  background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-webkit-progress-value {
    background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-moz-progress-bar {
    background-color: #3d5afe; }
  .progress-indigo.progress-accent-3[value]::-ms-fill {
    background-color: #3d5afe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-3 .progress-bar {
      background-color: #3d5afe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3d5afe !important; }

.border-indigo.border-accent-3 {
  border: 1px solid #3d5afe !important; }

.border-top-indigo.border-top-accent-3 {
  border-top: 1px solid #3d5afe !important; }

.border-bottom-indigo.border-bottom-accent-3 {
  border-bottom: 1px solid #3d5afe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-3 {
  border-left: 1px solid #3d5afe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-3 {
  border-right: 1px solid #3d5afe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-3 {
  border-right: 1px solid #3d5afe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-3 {
  border-left: 1px solid #3d5afe !important; }

.overlay-indigo.overlay-accent-3 {
  background: #3d5afe;
  /* The Fallback */
  background: rgba(61, 90, 254, 0.8); }

.indigo.accent-4 {
  color: #304ffe !important; }

.bg-indigo.bg-accent-4 {
  background-color: #304ffe !important; }

.btn-indigo.btn-accent-4 {
  border-color: #303f9f !important;
  background-color: #304ffe !important; }
  .btn-indigo.btn-accent-4:hover {
    border-color: #303f9f !important;
    background-color: #283593 !important; }
  .btn-indigo.btn-accent-4:focus, .btn-indigo.btn-accent-4:active {
    border-color: #283593 !important;
    background-color: #1a237e !important; }

.btn-outline-indigo.btn-outline-accent-4 {
  border-color: #304ffe !important;
  color: #304ffe !important; }
  .btn-outline-indigo.btn-outline-accent-4:hover {
    background-color: #304ffe !important; }

.progress-indigo.progress-accent-4 {
  background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-webkit-progress-value {
    background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-moz-progress-bar {
    background-color: #304ffe; }
  .progress-indigo.progress-accent-4[value]::-ms-fill {
    background-color: #304ffe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-indigo.progress-accent-4 .progress-bar {
      background-color: #304ffe; } }

input:focus ~ .bg-indigo {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #304ffe !important; }

.border-indigo.border-accent-4 {
  border: 1px solid #304ffe !important; }

.border-top-indigo.border-top-accent-4 {
  border-top: 1px solid #304ffe !important; }

.border-bottom-indigo.border-bottom-accent-4 {
  border-bottom: 1px solid #304ffe !important; }

[dir="ltr"] .border-left-indigo.border-left-accent-4 {
  border-left: 1px solid #304ffe !important; }

[dir="ltr"] .border-right-indigo.border-right-accent-4 {
  border-right: 1px solid #304ffe !important; }

[dir="rtl"] .border-left-indigo.border-left-accent-4 {
  border-right: 1px solid #304ffe !important; }

[dir="rtl"] .border-right-indigo.border-right-accent-4 {
  border-left: 1px solid #304ffe !important; }

.overlay-indigo.overlay-accent-4 {
  background: #304ffe;
  /* The Fallback */
  background: rgba(48, 79, 254, 0.8); }

.blue.lighten-5 {
  color: #e3f2fd !important; }

.bg-blue.bg-lighten-5 {
  background-color: #e3f2fd !important; }

.btn-blue.btn-lighten-5 {
  border-color: #1976d2 !important;
  background-color: #e3f2fd !important; }
  .btn-blue.btn-lighten-5:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-5:focus, .btn-blue.btn-lighten-5:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-5 {
  border-color: #e3f2fd !important;
  color: #e3f2fd !important; }
  .btn-outline-blue.btn-outline-lighten-5:hover {
    background-color: #e3f2fd !important; }

.progress-blue.progress-lighten-5 {
  background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e3f2fd; }
  .progress-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e3f2fd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-5 .progress-bar {
      background-color: #e3f2fd; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e3f2fd !important; }

.border-blue.border-lighten-5 {
  border: 1px solid #e3f2fd !important; }

.border-top-blue.border-top-lighten-5 {
  border-top: 1px solid #e3f2fd !important; }

.border-bottom-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #e3f2fd !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-5 {
  border-left: 1px solid #e3f2fd !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-5 {
  border-right: 1px solid #e3f2fd !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-5 {
  border-right: 1px solid #e3f2fd !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-5 {
  border-left: 1px solid #e3f2fd !important; }

.overlay-blue.overlay-lighten-5 {
  background: #e3f2fd;
  /* The Fallback */
  background: rgba(227, 242, 253, 0.8); }

.blue.lighten-4 {
  color: #bbdefb !important; }

.bg-blue.bg-lighten-4 {
  background-color: #bbdefb !important; }

.btn-blue.btn-lighten-4 {
  border-color: #1976d2 !important;
  background-color: #bbdefb !important; }
  .btn-blue.btn-lighten-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-4:focus, .btn-blue.btn-lighten-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-4 {
  border-color: #bbdefb !important;
  color: #bbdefb !important; }
  .btn-outline-blue.btn-outline-lighten-4:hover {
    background-color: #bbdefb !important; }

.progress-blue.progress-lighten-4 {
  background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #bbdefb; }
  .progress-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #bbdefb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-4 .progress-bar {
      background-color: #bbdefb; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bbdefb !important; }

.border-blue.border-lighten-4 {
  border: 1px solid #bbdefb !important; }

.border-top-blue.border-top-lighten-4 {
  border-top: 1px solid #bbdefb !important; }

.border-bottom-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #bbdefb !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-4 {
  border-left: 1px solid #bbdefb !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-4 {
  border-right: 1px solid #bbdefb !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-4 {
  border-right: 1px solid #bbdefb !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-4 {
  border-left: 1px solid #bbdefb !important; }

.overlay-blue.overlay-lighten-4 {
  background: #bbdefb;
  /* The Fallback */
  background: rgba(187, 222, 251, 0.8); }

.blue.lighten-3 {
  color: #90caf9 !important; }

.bg-blue.bg-lighten-3 {
  background-color: #90caf9 !important; }

.btn-blue.btn-lighten-3 {
  border-color: #1976d2 !important;
  background-color: #90caf9 !important; }
  .btn-blue.btn-lighten-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-3:focus, .btn-blue.btn-lighten-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-3 {
  border-color: #90caf9 !important;
  color: #90caf9 !important; }
  .btn-outline-blue.btn-outline-lighten-3:hover {
    background-color: #90caf9 !important; }

.progress-blue.progress-lighten-3 {
  background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #90caf9; }
  .progress-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #90caf9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-3 .progress-bar {
      background-color: #90caf9; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90caf9 !important; }

.border-blue.border-lighten-3 {
  border: 1px solid #90caf9 !important; }

.border-top-blue.border-top-lighten-3 {
  border-top: 1px solid #90caf9 !important; }

.border-bottom-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #90caf9 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-3 {
  border-left: 1px solid #90caf9 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-3 {
  border-right: 1px solid #90caf9 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-3 {
  border-right: 1px solid #90caf9 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-3 {
  border-left: 1px solid #90caf9 !important; }

.overlay-blue.overlay-lighten-3 {
  background: #90caf9;
  /* The Fallback */
  background: rgba(144, 202, 249, 0.8); }

.blue.lighten-2 {
  color: #64b5f6 !important; }

.bg-blue.bg-lighten-2 {
  background-color: #64b5f6 !important; }

.btn-blue.btn-lighten-2 {
  border-color: #1976d2 !important;
  background-color: #64b5f6 !important; }
  .btn-blue.btn-lighten-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-2:focus, .btn-blue.btn-lighten-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-2 {
  border-color: #64b5f6 !important;
  color: #64b5f6 !important; }
  .btn-outline-blue.btn-outline-lighten-2:hover {
    background-color: #64b5f6 !important; }

.progress-blue.progress-lighten-2 {
  background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #64b5f6; }
  .progress-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #64b5f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-2 .progress-bar {
      background-color: #64b5f6; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64b5f6 !important; }

.border-blue.border-lighten-2 {
  border: 1px solid #64b5f6 !important; }

.border-top-blue.border-top-lighten-2 {
  border-top: 1px solid #64b5f6 !important; }

.border-bottom-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #64b5f6 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-2 {
  border-left: 1px solid #64b5f6 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-2 {
  border-right: 1px solid #64b5f6 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-2 {
  border-right: 1px solid #64b5f6 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-2 {
  border-left: 1px solid #64b5f6 !important; }

.overlay-blue.overlay-lighten-2 {
  background: #64b5f6;
  /* The Fallback */
  background: rgba(100, 181, 246, 0.8); }

.blue.lighten-1 {
  color: #42a5f5 !important; }

.bg-blue.bg-lighten-1 {
  background-color: #42a5f5 !important; }

.btn-blue.btn-lighten-1 {
  border-color: #1976d2 !important;
  background-color: #42a5f5 !important; }
  .btn-blue.btn-lighten-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-lighten-1:focus, .btn-blue.btn-lighten-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-lighten-1 {
  border-color: #42a5f5 !important;
  color: #42a5f5 !important; }
  .btn-outline-blue.btn-outline-lighten-1:hover {
    background-color: #42a5f5 !important; }

.progress-blue.progress-lighten-1 {
  background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #42a5f5; }
  .progress-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #42a5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-lighten-1 .progress-bar {
      background-color: #42a5f5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #42a5f5 !important; }

.border-blue.border-lighten-1 {
  border: 1px solid #42a5f5 !important; }

.border-top-blue.border-top-lighten-1 {
  border-top: 1px solid #42a5f5 !important; }

.border-bottom-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #42a5f5 !important; }

[dir="ltr"] .border-left-blue.border-left-lighten-1 {
  border-left: 1px solid #42a5f5 !important; }

[dir="ltr"] .border-right-blue.border-right-lighten-1 {
  border-right: 1px solid #42a5f5 !important; }

[dir="rtl"] .border-left-blue.border-left-lighten-1 {
  border-right: 1px solid #42a5f5 !important; }

[dir="rtl"] .border-right-blue.border-right-lighten-1 {
  border-left: 1px solid #42a5f5 !important; }

.overlay-blue.overlay-lighten-1 {
  background: #42a5f5;
  /* The Fallback */
  background: rgba(66, 165, 245, 0.8); }

.blue {
  color: #2196f3 !important; }

.bg-blue {
  background-color: #2196f3 !important; }
  .bg-blue .card-header, .bg-blue .card-footer {
    background-color: transparent; }

.toast-blue {
  background-color: #2196f3; }

.alert-blue {
  border-color: #2196f3 !important;
  background-color: #43a6f5 !important;
  color: #05365d !important; }
  .alert-blue .alert-link {
    color: #031f36 !important; }

.border-blue {
  border-color: #2196f3; }

.border-top-blue {
  border-top-color: #2196f3; }

.border-bottom-blue {
  border-bottom-color: #2196f3; }

[dir="ltr"] .border-left-blue {
  border-left-color: #2196f3; }

[dir="ltr"] .border-right-blue {
  border-right-color: #2196f3; }

[dir="rtl"] .border-left-blue {
  border-right-color: #2196f3; }

[dir="rtl"] .border-right-blue {
  border-left-color: #2196f3; }

.badge-blue {
  background-color: #2196f3; }

.panel-blue {
  border-color: #2196f3; }
  .panel-blue .panel-heading {
    color: #FFF;
    border-color: #2196f3;
    background-color: #39a1f4; }

.bg-blue.tag-glow, .border-blue.tag-glow {
  box-shadow: 0px 0px 10px #2196f3; }

.overlay-blue {
  background: #2196f3;
  /* The Fallback */
  background: rgba(33, 150, 243, 0.8); }

.card.card-outline-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #2196f3;
  background-color: transparent; }
  .card.card-outline-blue .card-header, .card.card-outline-blue .card-footer {
    background-color: transparent; }

.btn-blue.btn-flat {
  background-color: transparent !important;
  color: #2196f3;
  border: none; }

.btn-blue.btn-raised, .btn-blue.btn-fab {
  background-color: #2196f3 !important;
  color: #fff !important;
  border-color: #2196f3; }
  .btn-blue.btn-raised.active, .btn-blue.btn-fab.active {
    background-color: #0c84e4 !important;
    border-color: #0c84e4 !important; }

.btn-group-raised .btn-blue {
  background-color: #2196f3 !important;
  color: #fff !important; }

.btn-outline-blue {
  border: 1px solid;
  border-color: #2196f3;
  background-color: transparent;
  color: #2196f3;
  box-shadow: none !important; }
  .btn-outline-blue:focus {
    background-color: transparent !important;
    color: #2196f3 !important;
    box-shadow: transparent !important; }
  .btn-outline-blue.active {
    background-color: #2196f3 !important;
    color: #FFF !important; }
  .btn-outline-blue:hover {
    background-color: #128ff2 !important;
    color: #FFF !important; }
    .btn-outline-blue:hover svg {
      color: #FFF !important; }

.btn-outline-blue.btn-raised, .btn-outline-blue.btn-fab {
  border: 1px solid;
  border-color: #2196f3;
  background-color: transparent;
  color: #2196f3;
  box-shadow: none !important; }
  .btn-outline-blue.btn-raised:focus, .btn-outline-blue.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-blue.btn-raised.active, .btn-outline-blue.btn-fab.active {
    background-color: #2196f3 !important;
    color: #FFF !important; }
  .btn-outline-blue.btn-raised:hover, .btn-outline-blue.btn-fab:hover {
    background-color: #128ff2 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-blue {
  background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-webkit-progress-value {
    background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-moz-progress-bar {
    background-color: #2196f3; }
  .progress .progress-bar.progress-bar-blue[value]::-ms-fill {
    background-color: #2196f3; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-blue .progress-bar {
      background-color: #2196f3; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2196f3 !important; }

.blue.darken-1 {
  color: #1e88e5 !important; }

.bg-blue.bg-darken-1 {
  background-color: #1e88e5 !important; }

.btn-blue.btn-darken-1 {
  border-color: #1976d2 !important;
  background-color: #1e88e5 !important; }
  .btn-blue.btn-darken-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-1:focus, .btn-blue.btn-darken-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-1 {
  border-color: #1e88e5 !important;
  color: #1e88e5 !important; }
  .btn-outline-blue.btn-outline-darken-1:hover {
    background-color: #1e88e5 !important; }

.progress-blue.progress-darken-1 {
  background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #1e88e5; }
  .progress-blue.progress-darken-1[value]::-ms-fill {
    background-color: #1e88e5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-1 .progress-bar {
      background-color: #1e88e5; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1e88e5 !important; }

.border-blue.border-darken-1 {
  border: 1px solid #1e88e5 !important; }

.border-top-blue.border-top-darken-1 {
  border-top: 1px solid #1e88e5 !important; }

.border-bottom-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #1e88e5 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-1 {
  border-left: 1px solid #1e88e5 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-1 {
  border-right: 1px solid #1e88e5 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-1 {
  border-right: 1px solid #1e88e5 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-1 {
  border-left: 1px solid #1e88e5 !important; }

.overlay-blue.overlay-darken-1 {
  background: #1e88e5;
  /* The Fallback */
  background: rgba(30, 136, 229, 0.8); }

.blue.darken-2 {
  color: #1976d2 !important; }

.bg-blue.bg-darken-2 {
  background-color: #1976d2 !important; }

.btn-blue.btn-darken-2 {
  border-color: #1976d2 !important;
  background-color: #1976d2 !important; }
  .btn-blue.btn-darken-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-2:focus, .btn-blue.btn-darken-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-2 {
  border-color: #1976d2 !important;
  color: #1976d2 !important; }
  .btn-outline-blue.btn-outline-darken-2:hover {
    background-color: #1976d2 !important; }

.progress-blue.progress-darken-2 {
  background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #1976d2; }
  .progress-blue.progress-darken-2[value]::-ms-fill {
    background-color: #1976d2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-2 .progress-bar {
      background-color: #1976d2; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1976d2 !important; }

.border-blue.border-darken-2 {
  border: 1px solid #1976d2 !important; }

.border-top-blue.border-top-darken-2 {
  border-top: 1px solid #1976d2 !important; }

.border-bottom-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #1976d2 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-2 {
  border-left: 1px solid #1976d2 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-2 {
  border-right: 1px solid #1976d2 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-2 {
  border-right: 1px solid #1976d2 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-2 {
  border-left: 1px solid #1976d2 !important; }

.overlay-blue.overlay-darken-2 {
  background: #1976d2;
  /* The Fallback */
  background: rgba(25, 118, 210, 0.8); }

.blue.darken-3 {
  color: #1565c0 !important; }

.bg-blue.bg-darken-3 {
  background-color: #1565c0 !important; }

.btn-blue.btn-darken-3 {
  border-color: #1976d2 !important;
  background-color: #1565c0 !important; }
  .btn-blue.btn-darken-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-3:focus, .btn-blue.btn-darken-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-3 {
  border-color: #1565c0 !important;
  color: #1565c0 !important; }
  .btn-outline-blue.btn-outline-darken-3:hover {
    background-color: #1565c0 !important; }

.progress-blue.progress-darken-3 {
  background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #1565c0; }
  .progress-blue.progress-darken-3[value]::-ms-fill {
    background-color: #1565c0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-3 .progress-bar {
      background-color: #1565c0; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1565c0 !important; }

.border-blue.border-darken-3 {
  border: 1px solid #1565c0 !important; }

.border-top-blue.border-top-darken-3 {
  border-top: 1px solid #1565c0 !important; }

.border-bottom-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #1565c0 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-3 {
  border-left: 1px solid #1565c0 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-3 {
  border-right: 1px solid #1565c0 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-3 {
  border-right: 1px solid #1565c0 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-3 {
  border-left: 1px solid #1565c0 !important; }

.overlay-blue.overlay-darken-3 {
  background: #1565c0;
  /* The Fallback */
  background: rgba(21, 101, 192, 0.8); }

.blue.darken-4 {
  color: #0d47a1 !important; }

.bg-blue.bg-darken-4 {
  background-color: #0d47a1 !important; }

.btn-blue.btn-darken-4 {
  border-color: #1976d2 !important;
  background-color: #0d47a1 !important; }
  .btn-blue.btn-darken-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-darken-4:focus, .btn-blue.btn-darken-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-darken-4 {
  border-color: #0d47a1 !important;
  color: #0d47a1 !important; }
  .btn-outline-blue.btn-outline-darken-4:hover {
    background-color: #0d47a1 !important; }

.progress-blue.progress-darken-4 {
  background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #0d47a1; }
  .progress-blue.progress-darken-4[value]::-ms-fill {
    background-color: #0d47a1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-darken-4 .progress-bar {
      background-color: #0d47a1; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0d47a1 !important; }

.border-blue.border-darken-4 {
  border: 1px solid #0d47a1 !important; }

.border-top-blue.border-top-darken-4 {
  border-top: 1px solid #0d47a1 !important; }

.border-bottom-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #0d47a1 !important; }

[dir="ltr"] .border-left-blue.border-left-darken-4 {
  border-left: 1px solid #0d47a1 !important; }

[dir="ltr"] .border-right-blue.border-right-darken-4 {
  border-right: 1px solid #0d47a1 !important; }

[dir="rtl"] .border-left-blue.border-left-darken-4 {
  border-right: 1px solid #0d47a1 !important; }

[dir="rtl"] .border-right-blue.border-right-darken-4 {
  border-left: 1px solid #0d47a1 !important; }

.overlay-blue.overlay-darken-4 {
  background: #0d47a1;
  /* The Fallback */
  background: rgba(13, 71, 161, 0.8); }

.blue.accent-1 {
  color: #82b1ff !important; }

.bg-blue.bg-accent-1 {
  background-color: #82b1ff !important; }

.btn-blue.btn-accent-1 {
  border-color: #1976d2 !important;
  background-color: #82b1ff !important; }
  .btn-blue.btn-accent-1:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-1:focus, .btn-blue.btn-accent-1:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-1 {
  border-color: #82b1ff !important;
  color: #82b1ff !important; }
  .btn-outline-blue.btn-outline-accent-1:hover {
    background-color: #82b1ff !important; }

.progress-blue.progress-accent-1 {
  background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #82b1ff; }
  .progress-blue.progress-accent-1[value]::-ms-fill {
    background-color: #82b1ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-1 .progress-bar {
      background-color: #82b1ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #82b1ff !important; }

.border-blue.border-accent-1 {
  border: 1px solid #82b1ff !important; }

.border-top-blue.border-top-accent-1 {
  border-top: 1px solid #82b1ff !important; }

.border-bottom-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #82b1ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-1 {
  border-left: 1px solid #82b1ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-1 {
  border-right: 1px solid #82b1ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-1 {
  border-right: 1px solid #82b1ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-1 {
  border-left: 1px solid #82b1ff !important; }

.overlay-blue.overlay-accent-1 {
  background: #82b1ff;
  /* The Fallback */
  background: rgba(130, 177, 255, 0.8); }

.blue.accent-2 {
  color: #448aff !important; }

.bg-blue.bg-accent-2 {
  background-color: #448aff !important; }

.btn-blue.btn-accent-2 {
  border-color: #1976d2 !important;
  background-color: #448aff !important; }
  .btn-blue.btn-accent-2:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-2:focus, .btn-blue.btn-accent-2:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-2 {
  border-color: #448aff !important;
  color: #448aff !important; }
  .btn-outline-blue.btn-outline-accent-2:hover {
    background-color: #448aff !important; }

.progress-blue.progress-accent-2 {
  background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #448aff; }
  .progress-blue.progress-accent-2[value]::-ms-fill {
    background-color: #448aff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-2 .progress-bar {
      background-color: #448aff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #448aff !important; }

.border-blue.border-accent-2 {
  border: 1px solid #448aff !important; }

.border-top-blue.border-top-accent-2 {
  border-top: 1px solid #448aff !important; }

.border-bottom-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #448aff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-2 {
  border-left: 1px solid #448aff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-2 {
  border-right: 1px solid #448aff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-2 {
  border-right: 1px solid #448aff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-2 {
  border-left: 1px solid #448aff !important; }

.overlay-blue.overlay-accent-2 {
  background: #448aff;
  /* The Fallback */
  background: rgba(68, 138, 255, 0.8); }

.blue.accent-3 {
  color: #2979ff !important; }

.bg-blue.bg-accent-3 {
  background-color: #2979ff !important; }

.btn-blue.btn-accent-3 {
  border-color: #1976d2 !important;
  background-color: #2979ff !important; }
  .btn-blue.btn-accent-3:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-3:focus, .btn-blue.btn-accent-3:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-3 {
  border-color: #2979ff !important;
  color: #2979ff !important; }
  .btn-outline-blue.btn-outline-accent-3:hover {
    background-color: #2979ff !important; }

.progress-blue.progress-accent-3 {
  background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #2979ff; }
  .progress-blue.progress-accent-3[value]::-ms-fill {
    background-color: #2979ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-3 .progress-bar {
      background-color: #2979ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2979ff !important; }

.border-blue.border-accent-3 {
  border: 1px solid #2979ff !important; }

.border-top-blue.border-top-accent-3 {
  border-top: 1px solid #2979ff !important; }

.border-bottom-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #2979ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-3 {
  border-left: 1px solid #2979ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-3 {
  border-right: 1px solid #2979ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-3 {
  border-right: 1px solid #2979ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-3 {
  border-left: 1px solid #2979ff !important; }

.overlay-blue.overlay-accent-3 {
  background: #2979ff;
  /* The Fallback */
  background: rgba(41, 121, 255, 0.8); }

.blue.accent-4 {
  color: #2962ff !important; }

.bg-blue.bg-accent-4 {
  background-color: #2962ff !important; }

.btn-blue.btn-accent-4 {
  border-color: #1976d2 !important;
  background-color: #2962ff !important; }
  .btn-blue.btn-accent-4:hover {
    border-color: #1976d2 !important;
    background-color: #1565c0 !important; }
  .btn-blue.btn-accent-4:focus, .btn-blue.btn-accent-4:active {
    border-color: #1565c0 !important;
    background-color: #0d47a1 !important; }

.btn-outline-blue.btn-outline-accent-4 {
  border-color: #2962ff !important;
  color: #2962ff !important; }
  .btn-outline-blue.btn-outline-accent-4:hover {
    background-color: #2962ff !important; }

.progress-blue.progress-accent-4 {
  background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #2962ff; }
  .progress-blue.progress-accent-4[value]::-ms-fill {
    background-color: #2962ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue.progress-accent-4 .progress-bar {
      background-color: #2962ff; } }

input:focus ~ .bg-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2962ff !important; }

.border-blue.border-accent-4 {
  border: 1px solid #2962ff !important; }

.border-top-blue.border-top-accent-4 {
  border-top: 1px solid #2962ff !important; }

.border-bottom-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #2962ff !important; }

[dir="ltr"] .border-left-blue.border-left-accent-4 {
  border-left: 1px solid #2962ff !important; }

[dir="ltr"] .border-right-blue.border-right-accent-4 {
  border-right: 1px solid #2962ff !important; }

[dir="rtl"] .border-left-blue.border-left-accent-4 {
  border-right: 1px solid #2962ff !important; }

[dir="rtl"] .border-right-blue.border-right-accent-4 {
  border-left: 1px solid #2962ff !important; }

.overlay-blue.overlay-accent-4 {
  background: #2962ff;
  /* The Fallback */
  background: rgba(41, 98, 255, 0.8); }

.light-blue.lighten-5 {
  color: #e1f5fe !important; }

.bg-light-blue.bg-lighten-5 {
  background-color: #e1f5fe !important; }

.btn-light-blue.btn-lighten-5 {
  border-color: #0288d1 !important;
  background-color: #e1f5fe !important; }
  .btn-light-blue.btn-lighten-5:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-5:focus, .btn-light-blue.btn-lighten-5:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-5 {
  border-color: #e1f5fe !important;
  color: #e1f5fe !important; }
  .btn-outline-light-blue.btn-outline-lighten-5:hover {
    background-color: #e1f5fe !important; }

.progress-light-blue.progress-lighten-5 {
  background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e1f5fe; }
  .progress-light-blue.progress-lighten-5[value]::-ms-fill {
    background-color: #e1f5fe; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-5 .progress-bar {
      background-color: #e1f5fe; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e1f5fe !important; }

.border-light-blue.border-lighten-5 {
  border: 1px solid #e1f5fe !important; }

.border-top-light-blue.border-top-lighten-5 {
  border-top: 1px solid #e1f5fe !important; }

.border-bottom-light-blue.border-bottom-lighten-5 {
  border-bottom: 1px solid #e1f5fe !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-5 {
  border-left: 1px solid #e1f5fe !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-5 {
  border-right: 1px solid #e1f5fe !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-5 {
  border-right: 1px solid #e1f5fe !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-5 {
  border-left: 1px solid #e1f5fe !important; }

.overlay-light-blue.overlay-lighten-5 {
  background: #e1f5fe;
  /* The Fallback */
  background: rgba(225, 245, 254, 0.8); }

.light-blue.lighten-4 {
  color: #b3e5fc !important; }

.bg-light-blue.bg-lighten-4 {
  background-color: #b3e5fc !important; }

.btn-light-blue.btn-lighten-4 {
  border-color: #0288d1 !important;
  background-color: #b3e5fc !important; }
  .btn-light-blue.btn-lighten-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-4:focus, .btn-light-blue.btn-lighten-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-4 {
  border-color: #b3e5fc !important;
  color: #b3e5fc !important; }
  .btn-outline-light-blue.btn-outline-lighten-4:hover {
    background-color: #b3e5fc !important; }

.progress-light-blue.progress-lighten-4 {
  background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b3e5fc; }
  .progress-light-blue.progress-lighten-4[value]::-ms-fill {
    background-color: #b3e5fc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-4 .progress-bar {
      background-color: #b3e5fc; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b3e5fc !important; }

.border-light-blue.border-lighten-4 {
  border: 1px solid #b3e5fc !important; }

.border-top-light-blue.border-top-lighten-4 {
  border-top: 1px solid #b3e5fc !important; }

.border-bottom-light-blue.border-bottom-lighten-4 {
  border-bottom: 1px solid #b3e5fc !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-4 {
  border-left: 1px solid #b3e5fc !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-4 {
  border-right: 1px solid #b3e5fc !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-4 {
  border-right: 1px solid #b3e5fc !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-4 {
  border-left: 1px solid #b3e5fc !important; }

.overlay-light-blue.overlay-lighten-4 {
  background: #b3e5fc;
  /* The Fallback */
  background: rgba(179, 229, 252, 0.8); }

.light-blue.lighten-3 {
  color: #81d4fa !important; }

.bg-light-blue.bg-lighten-3 {
  background-color: #81d4fa !important; }

.btn-light-blue.btn-lighten-3 {
  border-color: #0288d1 !important;
  background-color: #81d4fa !important; }
  .btn-light-blue.btn-lighten-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-3:focus, .btn-light-blue.btn-lighten-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-3 {
  border-color: #81d4fa !important;
  color: #81d4fa !important; }
  .btn-outline-light-blue.btn-outline-lighten-3:hover {
    background-color: #81d4fa !important; }

.progress-light-blue.progress-lighten-3 {
  background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #81d4fa; }
  .progress-light-blue.progress-lighten-3[value]::-ms-fill {
    background-color: #81d4fa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-3 .progress-bar {
      background-color: #81d4fa; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81d4fa !important; }

.border-light-blue.border-lighten-3 {
  border: 1px solid #81d4fa !important; }

.border-top-light-blue.border-top-lighten-3 {
  border-top: 1px solid #81d4fa !important; }

.border-bottom-light-blue.border-bottom-lighten-3 {
  border-bottom: 1px solid #81d4fa !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-3 {
  border-left: 1px solid #81d4fa !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-3 {
  border-right: 1px solid #81d4fa !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-3 {
  border-right: 1px solid #81d4fa !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-3 {
  border-left: 1px solid #81d4fa !important; }

.overlay-light-blue.overlay-lighten-3 {
  background: #81d4fa;
  /* The Fallback */
  background: rgba(129, 212, 250, 0.8); }

.light-blue.lighten-2 {
  color: #4fc3f7 !important; }

.bg-light-blue.bg-lighten-2 {
  background-color: #4fc3f7 !important; }

.btn-light-blue.btn-lighten-2 {
  border-color: #0288d1 !important;
  background-color: #4fc3f7 !important; }
  .btn-light-blue.btn-lighten-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-2:focus, .btn-light-blue.btn-lighten-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-2 {
  border-color: #4fc3f7 !important;
  color: #4fc3f7 !important; }
  .btn-outline-light-blue.btn-outline-lighten-2:hover {
    background-color: #4fc3f7 !important; }

.progress-light-blue.progress-lighten-2 {
  background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4fc3f7; }
  .progress-light-blue.progress-lighten-2[value]::-ms-fill {
    background-color: #4fc3f7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-2 .progress-bar {
      background-color: #4fc3f7; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4fc3f7 !important; }

.border-light-blue.border-lighten-2 {
  border: 1px solid #4fc3f7 !important; }

.border-top-light-blue.border-top-lighten-2 {
  border-top: 1px solid #4fc3f7 !important; }

.border-bottom-light-blue.border-bottom-lighten-2 {
  border-bottom: 1px solid #4fc3f7 !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-2 {
  border-left: 1px solid #4fc3f7 !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-2 {
  border-right: 1px solid #4fc3f7 !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-2 {
  border-right: 1px solid #4fc3f7 !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-2 {
  border-left: 1px solid #4fc3f7 !important; }

.overlay-light-blue.overlay-lighten-2 {
  background: #4fc3f7;
  /* The Fallback */
  background: rgba(79, 195, 247, 0.8); }

.light-blue.lighten-1 {
  color: #29b6f6 !important; }

.bg-light-blue.bg-lighten-1 {
  background-color: #29b6f6 !important; }

.btn-light-blue.btn-lighten-1 {
  border-color: #0288d1 !important;
  background-color: #29b6f6 !important; }
  .btn-light-blue.btn-lighten-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-lighten-1:focus, .btn-light-blue.btn-lighten-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-lighten-1 {
  border-color: #29b6f6 !important;
  color: #29b6f6 !important; }
  .btn-outline-light-blue.btn-outline-lighten-1:hover {
    background-color: #29b6f6 !important; }

.progress-light-blue.progress-lighten-1 {
  background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #29b6f6; }
  .progress-light-blue.progress-lighten-1[value]::-ms-fill {
    background-color: #29b6f6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-lighten-1 .progress-bar {
      background-color: #29b6f6; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #29b6f6 !important; }

.border-light-blue.border-lighten-1 {
  border: 1px solid #29b6f6 !important; }

.border-top-light-blue.border-top-lighten-1 {
  border-top: 1px solid #29b6f6 !important; }

.border-bottom-light-blue.border-bottom-lighten-1 {
  border-bottom: 1px solid #29b6f6 !important; }

[dir="ltr"] .border-left-light-blue.border-left-lighten-1 {
  border-left: 1px solid #29b6f6 !important; }

[dir="ltr"] .border-right-light-blue.border-right-lighten-1 {
  border-right: 1px solid #29b6f6 !important; }

[dir="rtl"] .border-left-light-blue.border-left-lighten-1 {
  border-right: 1px solid #29b6f6 !important; }

[dir="rtl"] .border-right-light-blue.border-right-lighten-1 {
  border-left: 1px solid #29b6f6 !important; }

.overlay-light-blue.overlay-lighten-1 {
  background: #29b6f6;
  /* The Fallback */
  background: rgba(41, 182, 246, 0.8); }

.light-blue {
  color: #03a9f4 !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }
  .bg-light-blue .card-header, .bg-light-blue .card-footer {
    background-color: transparent; }

.toast-light-blue {
  background-color: #03a9f4; }

.alert-light-blue {
  border-color: #03a9f4 !important;
  background-color: #1eb7fc !important;
  color: #012f44 !important; }
  .alert-light-blue .alert-link {
    color: #00131b !important; }

.border-light-blue {
  border-color: #03a9f4; }

.border-top-light-blue {
  border-top-color: #03a9f4; }

.border-bottom-light-blue {
  border-bottom-color: #03a9f4; }

[dir="ltr"] .border-left-light-blue {
  border-left-color: #03a9f4; }

[dir="ltr"] .border-right-light-blue {
  border-right-color: #03a9f4; }

[dir="rtl"] .border-left-light-blue {
  border-right-color: #03a9f4; }

[dir="rtl"] .border-right-light-blue {
  border-left-color: #03a9f4; }

.badge-light-blue {
  background-color: #03a9f4; }

.panel-light-blue {
  border-color: #03a9f4; }
  .panel-light-blue .panel-heading {
    color: #FFF;
    border-color: #03a9f4;
    background-color: #14b4fc; }

.bg-light-blue.tag-glow, .border-light-blue.tag-glow {
  box-shadow: 0px 0px 10px #03a9f4; }

.overlay-light-blue {
  background: #03a9f4;
  /* The Fallback */
  background: rgba(3, 169, 244, 0.8); }

.card.card-outline-light-blue {
  border-width: 1px;
  border-style: solid;
  border-color: #03a9f4;
  background-color: transparent; }
  .card.card-outline-light-blue .card-header, .card.card-outline-light-blue .card-footer {
    background-color: transparent; }

.btn-light-blue.btn-flat {
  background-color: transparent !important;
  color: #03a9f4;
  border: none; }

.btn-light-blue.btn-raised, .btn-light-blue.btn-fab {
  background-color: #03a9f4 !important;
  color: #fff !important;
  border-color: #03a9f4; }
  .btn-light-blue.btn-raised.active, .btn-light-blue.btn-fab.active {
    background-color: #0391d1 !important;
    border-color: #0391d1 !important; }

.btn-group-raised .btn-light-blue {
  background-color: #03a9f4 !important;
  color: #fff !important; }

.btn-outline-light-blue {
  border: 1px solid;
  border-color: #03a9f4;
  background-color: transparent;
  color: #03a9f4;
  box-shadow: none !important; }
  .btn-outline-light-blue:focus {
    background-color: transparent !important;
    color: #03a9f4 !important;
    box-shadow: transparent !important; }
  .btn-outline-light-blue.active {
    background-color: #03a9f4 !important;
    color: #FFF !important; }
  .btn-outline-light-blue:hover {
    background-color: #039fe5 !important;
    color: #FFF !important; }
    .btn-outline-light-blue:hover svg {
      color: #FFF !important; }

.btn-outline-light-blue.btn-raised, .btn-outline-light-blue.btn-fab {
  border: 1px solid;
  border-color: #03a9f4;
  background-color: transparent;
  color: #03a9f4;
  box-shadow: none !important; }
  .btn-outline-light-blue.btn-raised:focus, .btn-outline-light-blue.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-light-blue.btn-raised.active, .btn-outline-light-blue.btn-fab.active {
    background-color: #03a9f4 !important;
    color: #FFF !important; }
  .btn-outline-light-blue.btn-raised:hover, .btn-outline-light-blue.btn-fab:hover {
    background-color: #039fe5 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-light-blue {
  background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-webkit-progress-value {
    background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-moz-progress-bar {
    background-color: #03a9f4; }
  .progress .progress-bar.progress-bar-light-blue[value]::-ms-fill {
    background-color: #03a9f4; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-light-blue .progress-bar {
      background-color: #03a9f4; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #03a9f4 !important; }

.light-blue.darken-1 {
  color: #039be5 !important; }

.bg-light-blue.bg-darken-1 {
  background-color: #039be5 !important; }

.btn-light-blue.btn-darken-1 {
  border-color: #0288d1 !important;
  background-color: #039be5 !important; }
  .btn-light-blue.btn-darken-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-1:focus, .btn-light-blue.btn-darken-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-1 {
  border-color: #039be5 !important;
  color: #039be5 !important; }
  .btn-outline-light-blue.btn-outline-darken-1:hover {
    background-color: #039be5 !important; }

.progress-light-blue.progress-darken-1 {
  background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-webkit-progress-value {
    background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-moz-progress-bar {
    background-color: #039be5; }
  .progress-light-blue.progress-darken-1[value]::-ms-fill {
    background-color: #039be5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-1 .progress-bar {
      background-color: #039be5; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #039be5 !important; }

.border-light-blue.border-darken-1 {
  border: 1px solid #039be5 !important; }

.border-top-light-blue.border-top-darken-1 {
  border-top: 1px solid #039be5 !important; }

.border-bottom-light-blue.border-bottom-darken-1 {
  border-bottom: 1px solid #039be5 !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-1 {
  border-left: 1px solid #039be5 !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-1 {
  border-right: 1px solid #039be5 !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-1 {
  border-right: 1px solid #039be5 !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-1 {
  border-left: 1px solid #039be5 !important; }

.overlay-light-blue.overlay-darken-1 {
  background: #039be5;
  /* The Fallback */
  background: rgba(3, 155, 229, 0.8); }

.light-blue.darken-2 {
  color: #0288d1 !important; }

.bg-light-blue.bg-darken-2 {
  background-color: #0288d1 !important; }

.btn-light-blue.btn-darken-2 {
  border-color: #0288d1 !important;
  background-color: #0288d1 !important; }
  .btn-light-blue.btn-darken-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-2:focus, .btn-light-blue.btn-darken-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-2 {
  border-color: #0288d1 !important;
  color: #0288d1 !important; }
  .btn-outline-light-blue.btn-outline-darken-2:hover {
    background-color: #0288d1 !important; }

.progress-light-blue.progress-darken-2 {
  background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0288d1; }
  .progress-light-blue.progress-darken-2[value]::-ms-fill {
    background-color: #0288d1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-2 .progress-bar {
      background-color: #0288d1; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0288d1 !important; }

.border-light-blue.border-darken-2 {
  border: 1px solid #0288d1 !important; }

.border-top-light-blue.border-top-darken-2 {
  border-top: 1px solid #0288d1 !important; }

.border-bottom-light-blue.border-bottom-darken-2 {
  border-bottom: 1px solid #0288d1 !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-2 {
  border-left: 1px solid #0288d1 !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-2 {
  border-right: 1px solid #0288d1 !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-2 {
  border-right: 1px solid #0288d1 !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-2 {
  border-left: 1px solid #0288d1 !important; }

.overlay-light-blue.overlay-darken-2 {
  background: #0288d1;
  /* The Fallback */
  background: rgba(2, 136, 209, 0.8); }

.light-blue.darken-3 {
  color: #0277bd !important; }

.bg-light-blue.bg-darken-3 {
  background-color: #0277bd !important; }

.btn-light-blue.btn-darken-3 {
  border-color: #0288d1 !important;
  background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-3:focus, .btn-light-blue.btn-darken-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-3 {
  border-color: #0277bd !important;
  color: #0277bd !important; }
  .btn-outline-light-blue.btn-outline-darken-3:hover {
    background-color: #0277bd !important; }

.progress-light-blue.progress-darken-3 {
  background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-webkit-progress-value {
    background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-moz-progress-bar {
    background-color: #0277bd; }
  .progress-light-blue.progress-darken-3[value]::-ms-fill {
    background-color: #0277bd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-3 .progress-bar {
      background-color: #0277bd; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0277bd !important; }

.border-light-blue.border-darken-3 {
  border: 1px solid #0277bd !important; }

.border-top-light-blue.border-top-darken-3 {
  border-top: 1px solid #0277bd !important; }

.border-bottom-light-blue.border-bottom-darken-3 {
  border-bottom: 1px solid #0277bd !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-3 {
  border-left: 1px solid #0277bd !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-3 {
  border-right: 1px solid #0277bd !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-3 {
  border-right: 1px solid #0277bd !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-3 {
  border-left: 1px solid #0277bd !important; }

.overlay-light-blue.overlay-darken-3 {
  background: #0277bd;
  /* The Fallback */
  background: rgba(2, 119, 189, 0.8); }

.light-blue.darken-4 {
  color: #01579b !important; }

.bg-light-blue.bg-darken-4 {
  background-color: #01579b !important; }

.btn-light-blue.btn-darken-4 {
  border-color: #0288d1 !important;
  background-color: #01579b !important; }
  .btn-light-blue.btn-darken-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-darken-4:focus, .btn-light-blue.btn-darken-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-darken-4 {
  border-color: #01579b !important;
  color: #01579b !important; }
  .btn-outline-light-blue.btn-outline-darken-4:hover {
    background-color: #01579b !important; }

.progress-light-blue.progress-darken-4 {
  background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-webkit-progress-value {
    background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-moz-progress-bar {
    background-color: #01579b; }
  .progress-light-blue.progress-darken-4[value]::-ms-fill {
    background-color: #01579b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-darken-4 .progress-bar {
      background-color: #01579b; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #01579b !important; }

.border-light-blue.border-darken-4 {
  border: 1px solid #01579b !important; }

.border-top-light-blue.border-top-darken-4 {
  border-top: 1px solid #01579b !important; }

.border-bottom-light-blue.border-bottom-darken-4 {
  border-bottom: 1px solid #01579b !important; }

[dir="ltr"] .border-left-light-blue.border-left-darken-4 {
  border-left: 1px solid #01579b !important; }

[dir="ltr"] .border-right-light-blue.border-right-darken-4 {
  border-right: 1px solid #01579b !important; }

[dir="rtl"] .border-left-light-blue.border-left-darken-4 {
  border-right: 1px solid #01579b !important; }

[dir="rtl"] .border-right-light-blue.border-right-darken-4 {
  border-left: 1px solid #01579b !important; }

.overlay-light-blue.overlay-darken-4 {
  background: #01579b;
  /* The Fallback */
  background: rgba(1, 87, 155, 0.8); }

.light-blue.accent-1 {
  color: #80d8ff !important; }

.bg-light-blue.bg-accent-1 {
  background-color: #80d8ff !important; }

.btn-light-blue.btn-accent-1 {
  border-color: #0288d1 !important;
  background-color: #80d8ff !important; }
  .btn-light-blue.btn-accent-1:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-1:focus, .btn-light-blue.btn-accent-1:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-1 {
  border-color: #80d8ff !important;
  color: #80d8ff !important; }
  .btn-outline-light-blue.btn-outline-accent-1:hover {
    background-color: #80d8ff !important; }

.progress-light-blue.progress-accent-1 {
  background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-webkit-progress-value {
    background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-moz-progress-bar {
    background-color: #80d8ff; }
  .progress-light-blue.progress-accent-1[value]::-ms-fill {
    background-color: #80d8ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-1 .progress-bar {
      background-color: #80d8ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80d8ff !important; }

.border-light-blue.border-accent-1 {
  border: 1px solid #80d8ff !important; }

.border-top-light-blue.border-top-accent-1 {
  border-top: 1px solid #80d8ff !important; }

.border-bottom-light-blue.border-bottom-accent-1 {
  border-bottom: 1px solid #80d8ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-1 {
  border-left: 1px solid #80d8ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-1 {
  border-right: 1px solid #80d8ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-1 {
  border-right: 1px solid #80d8ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-1 {
  border-left: 1px solid #80d8ff !important; }

.overlay-light-blue.overlay-accent-1 {
  background: #80d8ff;
  /* The Fallback */
  background: rgba(128, 216, 255, 0.8); }

.light-blue.accent-2 {
  color: #40c4ff !important; }

.bg-light-blue.bg-accent-2 {
  background-color: #40c4ff !important; }

.btn-light-blue.btn-accent-2 {
  border-color: #0288d1 !important;
  background-color: #40c4ff !important; }
  .btn-light-blue.btn-accent-2:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-2:focus, .btn-light-blue.btn-accent-2:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-2 {
  border-color: #40c4ff !important;
  color: #40c4ff !important; }
  .btn-outline-light-blue.btn-outline-accent-2:hover {
    background-color: #40c4ff !important; }

.progress-light-blue.progress-accent-2 {
  background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-webkit-progress-value {
    background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-moz-progress-bar {
    background-color: #40c4ff; }
  .progress-light-blue.progress-accent-2[value]::-ms-fill {
    background-color: #40c4ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-2 .progress-bar {
      background-color: #40c4ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #40c4ff !important; }

.border-light-blue.border-accent-2 {
  border: 1px solid #40c4ff !important; }

.border-top-light-blue.border-top-accent-2 {
  border-top: 1px solid #40c4ff !important; }

.border-bottom-light-blue.border-bottom-accent-2 {
  border-bottom: 1px solid #40c4ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-2 {
  border-left: 1px solid #40c4ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-2 {
  border-right: 1px solid #40c4ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-2 {
  border-right: 1px solid #40c4ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-2 {
  border-left: 1px solid #40c4ff !important; }

.overlay-light-blue.overlay-accent-2 {
  background: #40c4ff;
  /* The Fallback */
  background: rgba(64, 196, 255, 0.8); }

.light-blue.accent-3 {
  color: #00b0ff !important; }

.bg-light-blue.bg-accent-3 {
  background-color: #00b0ff !important; }

.btn-light-blue.btn-accent-3 {
  border-color: #0288d1 !important;
  background-color: #00b0ff !important; }
  .btn-light-blue.btn-accent-3:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-3:focus, .btn-light-blue.btn-accent-3:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-3 {
  border-color: #00b0ff !important;
  color: #00b0ff !important; }
  .btn-outline-light-blue.btn-outline-accent-3:hover {
    background-color: #00b0ff !important; }

.progress-light-blue.progress-accent-3 {
  background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00b0ff; }
  .progress-light-blue.progress-accent-3[value]::-ms-fill {
    background-color: #00b0ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-3 .progress-bar {
      background-color: #00b0ff; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b0ff !important; }

.border-light-blue.border-accent-3 {
  border: 1px solid #00b0ff !important; }

.border-top-light-blue.border-top-accent-3 {
  border-top: 1px solid #00b0ff !important; }

.border-bottom-light-blue.border-bottom-accent-3 {
  border-bottom: 1px solid #00b0ff !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-3 {
  border-left: 1px solid #00b0ff !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-3 {
  border-right: 1px solid #00b0ff !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-3 {
  border-right: 1px solid #00b0ff !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-3 {
  border-left: 1px solid #00b0ff !important; }

.overlay-light-blue.overlay-accent-3 {
  background: #00b0ff;
  /* The Fallback */
  background: rgba(0, 176, 255, 0.8); }

.light-blue.accent-4 {
  color: #0091ea !important; }

.bg-light-blue.bg-accent-4 {
  background-color: #0091ea !important; }

.btn-light-blue.btn-accent-4 {
  border-color: #0288d1 !important;
  background-color: #0091ea !important; }
  .btn-light-blue.btn-accent-4:hover {
    border-color: #0288d1 !important;
    background-color: #0277bd !important; }
  .btn-light-blue.btn-accent-4:focus, .btn-light-blue.btn-accent-4:active {
    border-color: #0277bd !important;
    background-color: #01579b !important; }

.btn-outline-light-blue.btn-outline-accent-4 {
  border-color: #0091ea !important;
  color: #0091ea !important; }
  .btn-outline-light-blue.btn-outline-accent-4:hover {
    background-color: #0091ea !important; }

.progress-light-blue.progress-accent-4 {
  background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-webkit-progress-value {
    background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-moz-progress-bar {
    background-color: #0091ea; }
  .progress-light-blue.progress-accent-4[value]::-ms-fill {
    background-color: #0091ea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-blue.progress-accent-4 .progress-bar {
      background-color: #0091ea; } }

input:focus ~ .bg-light-blue {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0091ea !important; }

.border-light-blue.border-accent-4 {
  border: 1px solid #0091ea !important; }

.border-top-light-blue.border-top-accent-4 {
  border-top: 1px solid #0091ea !important; }

.border-bottom-light-blue.border-bottom-accent-4 {
  border-bottom: 1px solid #0091ea !important; }

[dir="ltr"] .border-left-light-blue.border-left-accent-4 {
  border-left: 1px solid #0091ea !important; }

[dir="ltr"] .border-right-light-blue.border-right-accent-4 {
  border-right: 1px solid #0091ea !important; }

[dir="rtl"] .border-left-light-blue.border-left-accent-4 {
  border-right: 1px solid #0091ea !important; }

[dir="rtl"] .border-right-light-blue.border-right-accent-4 {
  border-left: 1px solid #0091ea !important; }

.overlay-light-blue.overlay-accent-4 {
  background: #0091ea;
  /* The Fallback */
  background: rgba(0, 145, 234, 0.8); }

.cyan.lighten-5 {
  color: #e0f7fa !important; }

.bg-cyan.bg-lighten-5 {
  background-color: #e0f7fa !important; }

.btn-cyan.btn-lighten-5 {
  border-color: #0097a7 !important;
  background-color: #e0f7fa !important; }
  .btn-cyan.btn-lighten-5:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-5:focus, .btn-cyan.btn-lighten-5:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-5 {
  border-color: #e0f7fa !important;
  color: #e0f7fa !important; }
  .btn-outline-cyan.btn-outline-lighten-5:hover {
    background-color: #e0f7fa !important; }

.progress-cyan.progress-lighten-5 {
  background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f7fa; }
  .progress-cyan.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f7fa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-5 .progress-bar {
      background-color: #e0f7fa; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f7fa !important; }

.border-cyan.border-lighten-5 {
  border: 1px solid #e0f7fa !important; }

.border-top-cyan.border-top-lighten-5 {
  border-top: 1px solid #e0f7fa !important; }

.border-bottom-cyan.border-bottom-lighten-5 {
  border-bottom: 1px solid #e0f7fa !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-5 {
  border-left: 1px solid #e0f7fa !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-5 {
  border-right: 1px solid #e0f7fa !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-5 {
  border-right: 1px solid #e0f7fa !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-5 {
  border-left: 1px solid #e0f7fa !important; }

.overlay-cyan.overlay-lighten-5 {
  background: #e0f7fa;
  /* The Fallback */
  background: rgba(224, 247, 250, 0.8); }

.cyan.lighten-4 {
  color: #b2ebf2 !important; }

.bg-cyan.bg-lighten-4 {
  background-color: #b2ebf2 !important; }

.btn-cyan.btn-lighten-4 {
  border-color: #0097a7 !important;
  background-color: #b2ebf2 !important; }
  .btn-cyan.btn-lighten-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-4:focus, .btn-cyan.btn-lighten-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-4 {
  border-color: #b2ebf2 !important;
  color: #b2ebf2 !important; }
  .btn-outline-cyan.btn-outline-lighten-4:hover {
    background-color: #b2ebf2 !important; }

.progress-cyan.progress-lighten-4 {
  background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2ebf2; }
  .progress-cyan.progress-lighten-4[value]::-ms-fill {
    background-color: #b2ebf2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-4 .progress-bar {
      background-color: #b2ebf2; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ebf2 !important; }

.border-cyan.border-lighten-4 {
  border: 1px solid #b2ebf2 !important; }

.border-top-cyan.border-top-lighten-4 {
  border-top: 1px solid #b2ebf2 !important; }

.border-bottom-cyan.border-bottom-lighten-4 {
  border-bottom: 1px solid #b2ebf2 !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-4 {
  border-left: 1px solid #b2ebf2 !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-4 {
  border-right: 1px solid #b2ebf2 !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-4 {
  border-right: 1px solid #b2ebf2 !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-4 {
  border-left: 1px solid #b2ebf2 !important; }

.overlay-cyan.overlay-lighten-4 {
  background: #b2ebf2;
  /* The Fallback */
  background: rgba(178, 235, 242, 0.8); }

.cyan.lighten-3 {
  color: #80deea !important; }

.bg-cyan.bg-lighten-3 {
  background-color: #80deea !important; }

.btn-cyan.btn-lighten-3 {
  border-color: #0097a7 !important;
  background-color: #80deea !important; }
  .btn-cyan.btn-lighten-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-3:focus, .btn-cyan.btn-lighten-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-3 {
  border-color: #80deea !important;
  color: #80deea !important; }
  .btn-outline-cyan.btn-outline-lighten-3:hover {
    background-color: #80deea !important; }

.progress-cyan.progress-lighten-3 {
  background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80deea; }
  .progress-cyan.progress-lighten-3[value]::-ms-fill {
    background-color: #80deea; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-3 .progress-bar {
      background-color: #80deea; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80deea !important; }

.border-cyan.border-lighten-3 {
  border: 1px solid #80deea !important; }

.border-top-cyan.border-top-lighten-3 {
  border-top: 1px solid #80deea !important; }

.border-bottom-cyan.border-bottom-lighten-3 {
  border-bottom: 1px solid #80deea !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-3 {
  border-left: 1px solid #80deea !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-3 {
  border-right: 1px solid #80deea !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-3 {
  border-right: 1px solid #80deea !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-3 {
  border-left: 1px solid #80deea !important; }

.overlay-cyan.overlay-lighten-3 {
  background: #80deea;
  /* The Fallback */
  background: rgba(128, 222, 234, 0.8); }

.cyan.lighten-2 {
  color: #4dd0e1 !important; }

.bg-cyan.bg-lighten-2 {
  background-color: #4dd0e1 !important; }

.btn-cyan.btn-lighten-2 {
  border-color: #0097a7 !important;
  background-color: #4dd0e1 !important; }
  .btn-cyan.btn-lighten-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-2:focus, .btn-cyan.btn-lighten-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-2 {
  border-color: #4dd0e1 !important;
  color: #4dd0e1 !important; }
  .btn-outline-cyan.btn-outline-lighten-2:hover {
    background-color: #4dd0e1 !important; }

.progress-cyan.progress-lighten-2 {
  background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4dd0e1; }
  .progress-cyan.progress-lighten-2[value]::-ms-fill {
    background-color: #4dd0e1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-2 .progress-bar {
      background-color: #4dd0e1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4dd0e1 !important; }

.border-cyan.border-lighten-2 {
  border: 1px solid #4dd0e1 !important; }

.border-top-cyan.border-top-lighten-2 {
  border-top: 1px solid #4dd0e1 !important; }

.border-bottom-cyan.border-bottom-lighten-2 {
  border-bottom: 1px solid #4dd0e1 !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-2 {
  border-left: 1px solid #4dd0e1 !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-2 {
  border-right: 1px solid #4dd0e1 !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-2 {
  border-right: 1px solid #4dd0e1 !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-2 {
  border-left: 1px solid #4dd0e1 !important; }

.overlay-cyan.overlay-lighten-2 {
  background: #4dd0e1;
  /* The Fallback */
  background: rgba(77, 208, 225, 0.8); }

.cyan.lighten-1 {
  color: #26c6da !important; }

.bg-cyan.bg-lighten-1 {
  background-color: #26c6da !important; }

.btn-cyan.btn-lighten-1 {
  border-color: #0097a7 !important;
  background-color: #26c6da !important; }
  .btn-cyan.btn-lighten-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-lighten-1:focus, .btn-cyan.btn-lighten-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-lighten-1 {
  border-color: #26c6da !important;
  color: #26c6da !important; }
  .btn-outline-cyan.btn-outline-lighten-1:hover {
    background-color: #26c6da !important; }

.progress-cyan.progress-lighten-1 {
  background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26c6da; }
  .progress-cyan.progress-lighten-1[value]::-ms-fill {
    background-color: #26c6da; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-lighten-1 .progress-bar {
      background-color: #26c6da; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26c6da !important; }

.border-cyan.border-lighten-1 {
  border: 1px solid #26c6da !important; }

.border-top-cyan.border-top-lighten-1 {
  border-top: 1px solid #26c6da !important; }

.border-bottom-cyan.border-bottom-lighten-1 {
  border-bottom: 1px solid #26c6da !important; }

[dir="ltr"] .border-left-cyan.border-left-lighten-1 {
  border-left: 1px solid #26c6da !important; }

[dir="ltr"] .border-right-cyan.border-right-lighten-1 {
  border-right: 1px solid #26c6da !important; }

[dir="rtl"] .border-left-cyan.border-left-lighten-1 {
  border-right: 1px solid #26c6da !important; }

[dir="rtl"] .border-right-cyan.border-right-lighten-1 {
  border-left: 1px solid #26c6da !important; }

.overlay-cyan.overlay-lighten-1 {
  background: #26c6da;
  /* The Fallback */
  background: rgba(38, 198, 218, 0.8); }

.cyan {
  color: #00bcd4 !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }
  .bg-cyan .card-header, .bg-cyan .card-footer {
    background-color: transparent; }

.toast-cyan {
  background-color: #00bcd4; }

.alert-cyan {
  border-color: #00bcd4 !important;
  background-color: #00dcf8 !important;
  color: #001e22 !important; }
  .alert-cyan .alert-link {
    color: black !important; }

.border-cyan {
  border-color: #00bcd4; }

.border-top-cyan {
  border-top-color: #00bcd4; }

.border-bottom-cyan {
  border-bottom-color: #00bcd4; }

[dir="ltr"] .border-left-cyan {
  border-left-color: #00bcd4; }

[dir="ltr"] .border-right-cyan {
  border-right-color: #00bcd4; }

[dir="rtl"] .border-left-cyan {
  border-right-color: #00bcd4; }

[dir="rtl"] .border-right-cyan {
  border-left-color: #00bcd4; }

.badge-cyan {
  background-color: #00bcd4; }

.panel-cyan {
  border-color: #00bcd4; }
  .panel-cyan .panel-heading {
    color: #FFF;
    border-color: #00bcd4;
    background-color: #00d3ee; }

.bg-cyan.tag-glow, .border-cyan.tag-glow {
  box-shadow: 0px 0px 10px #00bcd4; }

.overlay-cyan {
  background: #00bcd4;
  /* The Fallback */
  background: rgba(0, 188, 212, 0.8); }

.card.card-outline-cyan {
  border-width: 1px;
  border-style: solid;
  border-color: #00bcd4;
  background-color: transparent; }
  .card.card-outline-cyan .card-header, .card.card-outline-cyan .card-footer {
    background-color: transparent; }

.btn-cyan.btn-flat {
  background-color: transparent !important;
  color: #00bcd4;
  border: none; }

.btn-cyan.btn-raised, .btn-cyan.btn-fab {
  background-color: #00bcd4 !important;
  color: #fff !important;
  border-color: #00bcd4; }
  .btn-cyan.btn-raised.active, .btn-cyan.btn-fab.active {
    background-color: #009cb0 !important;
    border-color: #009cb0 !important; }

.btn-group-raised .btn-cyan {
  background-color: #00bcd4 !important;
  color: #fff !important; }

.btn-outline-cyan {
  border: 1px solid;
  border-color: #00bcd4;
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none !important; }
  .btn-outline-cyan:focus {
    background-color: transparent !important;
    color: #00bcd4 !important;
    box-shadow: transparent !important; }
  .btn-outline-cyan.active {
    background-color: #00bcd4 !important;
    color: #FFF !important; }
  .btn-outline-cyan:hover {
    background-color: #00aec5 !important;
    color: #FFF !important; }
    .btn-outline-cyan:hover svg {
      color: #FFF !important; }

.btn-outline-cyan.btn-raised, .btn-outline-cyan.btn-fab {
  border: 1px solid;
  border-color: #00bcd4;
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none !important; }
  .btn-outline-cyan.btn-raised:focus, .btn-outline-cyan.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-cyan.btn-raised.active, .btn-outline-cyan.btn-fab.active {
    background-color: #00bcd4 !important;
    color: #FFF !important; }
  .btn-outline-cyan.btn-raised:hover, .btn-outline-cyan.btn-fab:hover {
    background-color: #00aec5 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-cyan {
  background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-webkit-progress-value {
    background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-moz-progress-bar {
    background-color: #00bcd4; }
  .progress .progress-bar.progress-bar-cyan[value]::-ms-fill {
    background-color: #00bcd4; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-cyan .progress-bar {
      background-color: #00bcd4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bcd4 !important; }

.cyan.darken-1 {
  color: #00acc1 !important; }

.bg-cyan.bg-darken-1 {
  background-color: #00acc1 !important; }

.btn-cyan.btn-darken-1 {
  border-color: #0097a7 !important;
  background-color: #00acc1 !important; }
  .btn-cyan.btn-darken-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-1:focus, .btn-cyan.btn-darken-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-1 {
  border-color: #00acc1 !important;
  color: #00acc1 !important; }
  .btn-outline-cyan.btn-outline-darken-1:hover {
    background-color: #00acc1 !important; }

.progress-cyan.progress-darken-1 {
  background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00acc1; }
  .progress-cyan.progress-darken-1[value]::-ms-fill {
    background-color: #00acc1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-1 .progress-bar {
      background-color: #00acc1; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00acc1 !important; }

.border-cyan.border-darken-1 {
  border: 1px solid #00acc1 !important; }

.border-top-cyan.border-top-darken-1 {
  border-top: 1px solid #00acc1 !important; }

.border-bottom-cyan.border-bottom-darken-1 {
  border-bottom: 1px solid #00acc1 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-1 {
  border-left: 1px solid #00acc1 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-1 {
  border-right: 1px solid #00acc1 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-1 {
  border-right: 1px solid #00acc1 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-1 {
  border-left: 1px solid #00acc1 !important; }

.overlay-cyan.overlay-darken-1 {
  background: #00acc1;
  /* The Fallback */
  background: rgba(0, 172, 193, 0.8); }

.cyan.darken-2 {
  color: #0097a7 !important; }

.bg-cyan.bg-darken-2 {
  background-color: #0097a7 !important; }

.btn-cyan.btn-darken-2 {
  border-color: #0097a7 !important;
  background-color: #0097a7 !important; }
  .btn-cyan.btn-darken-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-2:focus, .btn-cyan.btn-darken-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-2 {
  border-color: #0097a7 !important;
  color: #0097a7 !important; }
  .btn-outline-cyan.btn-outline-darken-2:hover {
    background-color: #0097a7 !important; }

.progress-cyan.progress-darken-2 {
  background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-webkit-progress-value {
    background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-moz-progress-bar {
    background-color: #0097a7; }
  .progress-cyan.progress-darken-2[value]::-ms-fill {
    background-color: #0097a7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-2 .progress-bar {
      background-color: #0097a7; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #0097a7 !important; }

.border-cyan.border-darken-2 {
  border: 1px solid #0097a7 !important; }

.border-top-cyan.border-top-darken-2 {
  border-top: 1px solid #0097a7 !important; }

.border-bottom-cyan.border-bottom-darken-2 {
  border-bottom: 1px solid #0097a7 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-2 {
  border-left: 1px solid #0097a7 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-2 {
  border-right: 1px solid #0097a7 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-2 {
  border-right: 1px solid #0097a7 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-2 {
  border-left: 1px solid #0097a7 !important; }

.overlay-cyan.overlay-darken-2 {
  background: #0097a7;
  /* The Fallback */
  background: rgba(0, 151, 167, 0.8); }

.cyan.darken-3 {
  color: #00838f !important; }

.bg-cyan.bg-darken-3 {
  background-color: #00838f !important; }

.btn-cyan.btn-darken-3 {
  border-color: #0097a7 !important;
  background-color: #00838f !important; }
  .btn-cyan.btn-darken-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-3:focus, .btn-cyan.btn-darken-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-3 {
  border-color: #00838f !important;
  color: #00838f !important; }
  .btn-outline-cyan.btn-outline-darken-3:hover {
    background-color: #00838f !important; }

.progress-cyan.progress-darken-3 {
  background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00838f; }
  .progress-cyan.progress-darken-3[value]::-ms-fill {
    background-color: #00838f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-3 .progress-bar {
      background-color: #00838f; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00838f !important; }

.border-cyan.border-darken-3 {
  border: 1px solid #00838f !important; }

.border-top-cyan.border-top-darken-3 {
  border-top: 1px solid #00838f !important; }

.border-bottom-cyan.border-bottom-darken-3 {
  border-bottom: 1px solid #00838f !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-3 {
  border-left: 1px solid #00838f !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-3 {
  border-right: 1px solid #00838f !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-3 {
  border-right: 1px solid #00838f !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-3 {
  border-left: 1px solid #00838f !important; }

.overlay-cyan.overlay-darken-3 {
  background: #00838f;
  /* The Fallback */
  background: rgba(0, 131, 143, 0.8); }

.cyan.darken-4 {
  color: #006064 !important; }

.bg-cyan.bg-darken-4 {
  background-color: #006064 !important; }

.btn-cyan.btn-darken-4 {
  border-color: #0097a7 !important;
  background-color: #006064 !important; }
  .btn-cyan.btn-darken-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-darken-4:focus, .btn-cyan.btn-darken-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-darken-4 {
  border-color: #006064 !important;
  color: #006064 !important; }
  .btn-outline-cyan.btn-outline-darken-4:hover {
    background-color: #006064 !important; }

.progress-cyan.progress-darken-4 {
  background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-webkit-progress-value {
    background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-moz-progress-bar {
    background-color: #006064; }
  .progress-cyan.progress-darken-4[value]::-ms-fill {
    background-color: #006064; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-darken-4 .progress-bar {
      background-color: #006064; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #006064 !important; }

.border-cyan.border-darken-4 {
  border: 1px solid #006064 !important; }

.border-top-cyan.border-top-darken-4 {
  border-top: 1px solid #006064 !important; }

.border-bottom-cyan.border-bottom-darken-4 {
  border-bottom: 1px solid #006064 !important; }

[dir="ltr"] .border-left-cyan.border-left-darken-4 {
  border-left: 1px solid #006064 !important; }

[dir="ltr"] .border-right-cyan.border-right-darken-4 {
  border-right: 1px solid #006064 !important; }

[dir="rtl"] .border-left-cyan.border-left-darken-4 {
  border-right: 1px solid #006064 !important; }

[dir="rtl"] .border-right-cyan.border-right-darken-4 {
  border-left: 1px solid #006064 !important; }

.overlay-cyan.overlay-darken-4 {
  background: #006064;
  /* The Fallback */
  background: rgba(0, 96, 100, 0.8); }

.cyan.accent-1 {
  color: #84ffff !important; }

.bg-cyan.bg-accent-1 {
  background-color: #84ffff !important; }

.btn-cyan.btn-accent-1 {
  border-color: #0097a7 !important;
  background-color: #84ffff !important; }
  .btn-cyan.btn-accent-1:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-1:focus, .btn-cyan.btn-accent-1:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-1 {
  border-color: #84ffff !important;
  color: #84ffff !important; }
  .btn-outline-cyan.btn-outline-accent-1:hover {
    background-color: #84ffff !important; }

.progress-cyan.progress-accent-1 {
  background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-webkit-progress-value {
    background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-moz-progress-bar {
    background-color: #84ffff; }
  .progress-cyan.progress-accent-1[value]::-ms-fill {
    background-color: #84ffff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-1 .progress-bar {
      background-color: #84ffff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #84ffff !important; }

.border-cyan.border-accent-1 {
  border: 1px solid #84ffff !important; }

.border-top-cyan.border-top-accent-1 {
  border-top: 1px solid #84ffff !important; }

.border-bottom-cyan.border-bottom-accent-1 {
  border-bottom: 1px solid #84ffff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-1 {
  border-left: 1px solid #84ffff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-1 {
  border-right: 1px solid #84ffff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-1 {
  border-right: 1px solid #84ffff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-1 {
  border-left: 1px solid #84ffff !important; }

.overlay-cyan.overlay-accent-1 {
  background: #84ffff;
  /* The Fallback */
  background: rgba(132, 255, 255, 0.8); }

.cyan.accent-2 {
  color: #18ffff !important; }

.bg-cyan.bg-accent-2 {
  background-color: #18ffff !important; }

.btn-cyan.btn-accent-2 {
  border-color: #0097a7 !important;
  background-color: #18ffff !important; }
  .btn-cyan.btn-accent-2:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-2:focus, .btn-cyan.btn-accent-2:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-2 {
  border-color: #18ffff !important;
  color: #18ffff !important; }
  .btn-outline-cyan.btn-outline-accent-2:hover {
    background-color: #18ffff !important; }

.progress-cyan.progress-accent-2 {
  background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-webkit-progress-value {
    background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-moz-progress-bar {
    background-color: #18ffff; }
  .progress-cyan.progress-accent-2[value]::-ms-fill {
    background-color: #18ffff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-2 .progress-bar {
      background-color: #18ffff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #18ffff !important; }

.border-cyan.border-accent-2 {
  border: 1px solid #18ffff !important; }

.border-top-cyan.border-top-accent-2 {
  border-top: 1px solid #18ffff !important; }

.border-bottom-cyan.border-bottom-accent-2 {
  border-bottom: 1px solid #18ffff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-2 {
  border-left: 1px solid #18ffff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-2 {
  border-right: 1px solid #18ffff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-2 {
  border-right: 1px solid #18ffff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-2 {
  border-left: 1px solid #18ffff !important; }

.overlay-cyan.overlay-accent-2 {
  background: #18ffff;
  /* The Fallback */
  background: rgba(24, 255, 255, 0.8); }

.cyan.accent-3 {
  color: #00e5ff !important; }

.bg-cyan.bg-accent-3 {
  background-color: #00e5ff !important; }

.btn-cyan.btn-accent-3 {
  border-color: #0097a7 !important;
  background-color: #00e5ff !important; }
  .btn-cyan.btn-accent-3:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-3:focus, .btn-cyan.btn-accent-3:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-3 {
  border-color: #00e5ff !important;
  color: #00e5ff !important; }
  .btn-outline-cyan.btn-outline-accent-3:hover {
    background-color: #00e5ff !important; }

.progress-cyan.progress-accent-3 {
  background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e5ff; }
  .progress-cyan.progress-accent-3[value]::-ms-fill {
    background-color: #00e5ff; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-3 .progress-bar {
      background-color: #00e5ff; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e5ff !important; }

.border-cyan.border-accent-3 {
  border: 1px solid #00e5ff !important; }

.border-top-cyan.border-top-accent-3 {
  border-top: 1px solid #00e5ff !important; }

.border-bottom-cyan.border-bottom-accent-3 {
  border-bottom: 1px solid #00e5ff !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-3 {
  border-left: 1px solid #00e5ff !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-3 {
  border-right: 1px solid #00e5ff !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-3 {
  border-right: 1px solid #00e5ff !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-3 {
  border-left: 1px solid #00e5ff !important; }

.overlay-cyan.overlay-accent-3 {
  background: #00e5ff;
  /* The Fallback */
  background: rgba(0, 229, 255, 0.8); }

.cyan.accent-4 {
  color: #00b8d4 !important; }

.bg-cyan.bg-accent-4 {
  background-color: #00b8d4 !important; }

.btn-cyan.btn-accent-4 {
  border-color: #0097a7 !important;
  background-color: #00b8d4 !important; }
  .btn-cyan.btn-accent-4:hover {
    border-color: #0097a7 !important;
    background-color: #00838f !important; }
  .btn-cyan.btn-accent-4:focus, .btn-cyan.btn-accent-4:active {
    border-color: #00838f !important;
    background-color: #006064 !important; }

.btn-outline-cyan.btn-outline-accent-4 {
  border-color: #00b8d4 !important;
  color: #00b8d4 !important; }
  .btn-outline-cyan.btn-outline-accent-4:hover {
    background-color: #00b8d4 !important; }

.progress-cyan.progress-accent-4 {
  background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00b8d4; }
  .progress-cyan.progress-accent-4[value]::-ms-fill {
    background-color: #00b8d4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-cyan.progress-accent-4 .progress-bar {
      background-color: #00b8d4; } }

input:focus ~ .bg-cyan {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00b8d4 !important; }

.border-cyan.border-accent-4 {
  border: 1px solid #00b8d4 !important; }

.border-top-cyan.border-top-accent-4 {
  border-top: 1px solid #00b8d4 !important; }

.border-bottom-cyan.border-bottom-accent-4 {
  border-bottom: 1px solid #00b8d4 !important; }

[dir="ltr"] .border-left-cyan.border-left-accent-4 {
  border-left: 1px solid #00b8d4 !important; }

[dir="ltr"] .border-right-cyan.border-right-accent-4 {
  border-right: 1px solid #00b8d4 !important; }

[dir="rtl"] .border-left-cyan.border-left-accent-4 {
  border-right: 1px solid #00b8d4 !important; }

[dir="rtl"] .border-right-cyan.border-right-accent-4 {
  border-left: 1px solid #00b8d4 !important; }

.overlay-cyan.overlay-accent-4 {
  background: #00b8d4;
  /* The Fallback */
  background: rgba(0, 184, 212, 0.8); }

.teal.lighten-5 {
  color: #e0f2f1 !important; }

.bg-teal.bg-lighten-5 {
  background-color: #e0f2f1 !important; }

.btn-teal.btn-lighten-5 {
  border-color: #00796b !important;
  background-color: #e0f2f1 !important; }
  .btn-teal.btn-lighten-5:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-5:focus, .btn-teal.btn-lighten-5:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-5 {
  border-color: #e0f2f1 !important;
  color: #e0f2f1 !important; }
  .btn-outline-teal.btn-outline-lighten-5:hover {
    background-color: #e0f2f1 !important; }

.progress-teal.progress-lighten-5 {
  background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e0f2f1; }
  .progress-teal.progress-lighten-5[value]::-ms-fill {
    background-color: #e0f2f1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-5 .progress-bar {
      background-color: #e0f2f1; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0f2f1 !important; }

.border-teal.border-lighten-5 {
  border: 1px solid #e0f2f1 !important; }

.border-top-teal.border-top-lighten-5 {
  border-top: 1px solid #e0f2f1 !important; }

.border-bottom-teal.border-bottom-lighten-5 {
  border-bottom: 1px solid #e0f2f1 !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-5 {
  border-left: 1px solid #e0f2f1 !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-5 {
  border-right: 1px solid #e0f2f1 !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-5 {
  border-right: 1px solid #e0f2f1 !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-5 {
  border-left: 1px solid #e0f2f1 !important; }

.overlay-teal.overlay-lighten-5 {
  background: #e0f2f1;
  /* The Fallback */
  background: rgba(224, 242, 241, 0.8); }

.teal.lighten-4 {
  color: #b2dfdb !important; }

.bg-teal.bg-lighten-4 {
  background-color: #b2dfdb !important; }

.btn-teal.btn-lighten-4 {
  border-color: #00796b !important;
  background-color: #b2dfdb !important; }
  .btn-teal.btn-lighten-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-4:focus, .btn-teal.btn-lighten-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-4 {
  border-color: #b2dfdb !important;
  color: #b2dfdb !important; }
  .btn-outline-teal.btn-outline-lighten-4:hover {
    background-color: #b2dfdb !important; }

.progress-teal.progress-lighten-4 {
  background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #b2dfdb; }
  .progress-teal.progress-lighten-4[value]::-ms-fill {
    background-color: #b2dfdb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-4 .progress-bar {
      background-color: #b2dfdb; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2dfdb !important; }

.border-teal.border-lighten-4 {
  border: 1px solid #b2dfdb !important; }

.border-top-teal.border-top-lighten-4 {
  border-top: 1px solid #b2dfdb !important; }

.border-bottom-teal.border-bottom-lighten-4 {
  border-bottom: 1px solid #b2dfdb !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-4 {
  border-left: 1px solid #b2dfdb !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-4 {
  border-right: 1px solid #b2dfdb !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-4 {
  border-right: 1px solid #b2dfdb !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-4 {
  border-left: 1px solid #b2dfdb !important; }

.overlay-teal.overlay-lighten-4 {
  background: #b2dfdb;
  /* The Fallback */
  background: rgba(178, 223, 219, 0.8); }

.teal.lighten-3 {
  color: #80cbc4 !important; }

.bg-teal.bg-lighten-3 {
  background-color: #80cbc4 !important; }

.btn-teal.btn-lighten-3 {
  border-color: #00796b !important;
  background-color: #80cbc4 !important; }
  .btn-teal.btn-lighten-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-3:focus, .btn-teal.btn-lighten-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-3 {
  border-color: #80cbc4 !important;
  color: #80cbc4 !important; }
  .btn-outline-teal.btn-outline-lighten-3:hover {
    background-color: #80cbc4 !important; }

.progress-teal.progress-lighten-3 {
  background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #80cbc4; }
  .progress-teal.progress-lighten-3[value]::-ms-fill {
    background-color: #80cbc4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-3 .progress-bar {
      background-color: #80cbc4; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #80cbc4 !important; }

.border-teal.border-lighten-3 {
  border: 1px solid #80cbc4 !important; }

.border-top-teal.border-top-lighten-3 {
  border-top: 1px solid #80cbc4 !important; }

.border-bottom-teal.border-bottom-lighten-3 {
  border-bottom: 1px solid #80cbc4 !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-3 {
  border-left: 1px solid #80cbc4 !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-3 {
  border-right: 1px solid #80cbc4 !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-3 {
  border-right: 1px solid #80cbc4 !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-3 {
  border-left: 1px solid #80cbc4 !important; }

.overlay-teal.overlay-lighten-3 {
  background: #80cbc4;
  /* The Fallback */
  background: rgba(128, 203, 196, 0.8); }

.teal.lighten-2 {
  color: #4db6ac !important; }

.bg-teal.bg-lighten-2 {
  background-color: #4db6ac !important; }

.btn-teal.btn-lighten-2 {
  border-color: #00796b !important;
  background-color: #4db6ac !important; }
  .btn-teal.btn-lighten-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-2:focus, .btn-teal.btn-lighten-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-2 {
  border-color: #4db6ac !important;
  color: #4db6ac !important; }
  .btn-outline-teal.btn-outline-lighten-2:hover {
    background-color: #4db6ac !important; }

.progress-teal.progress-lighten-2 {
  background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #4db6ac; }
  .progress-teal.progress-lighten-2[value]::-ms-fill {
    background-color: #4db6ac; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-2 .progress-bar {
      background-color: #4db6ac; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4db6ac !important; }

.border-teal.border-lighten-2 {
  border: 1px solid #4db6ac !important; }

.border-top-teal.border-top-lighten-2 {
  border-top: 1px solid #4db6ac !important; }

.border-bottom-teal.border-bottom-lighten-2 {
  border-bottom: 1px solid #4db6ac !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-2 {
  border-left: 1px solid #4db6ac !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-2 {
  border-right: 1px solid #4db6ac !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-2 {
  border-right: 1px solid #4db6ac !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-2 {
  border-left: 1px solid #4db6ac !important; }

.overlay-teal.overlay-lighten-2 {
  background: #4db6ac;
  /* The Fallback */
  background: rgba(77, 182, 172, 0.8); }

.teal.lighten-1 {
  color: #26a69a !important; }

.bg-teal.bg-lighten-1 {
  background-color: #26a69a !important; }

.btn-teal.btn-lighten-1 {
  border-color: #00796b !important;
  background-color: #26a69a !important; }
  .btn-teal.btn-lighten-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-lighten-1:focus, .btn-teal.btn-lighten-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-lighten-1 {
  border-color: #26a69a !important;
  color: #26a69a !important; }
  .btn-outline-teal.btn-outline-lighten-1:hover {
    background-color: #26a69a !important; }

.progress-teal.progress-lighten-1 {
  background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #26a69a; }
  .progress-teal.progress-lighten-1[value]::-ms-fill {
    background-color: #26a69a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-lighten-1 .progress-bar {
      background-color: #26a69a; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #26a69a !important; }

.border-teal.border-lighten-1 {
  border: 1px solid #26a69a !important; }

.border-top-teal.border-top-lighten-1 {
  border-top: 1px solid #26a69a !important; }

.border-bottom-teal.border-bottom-lighten-1 {
  border-bottom: 1px solid #26a69a !important; }

[dir="ltr"] .border-left-teal.border-left-lighten-1 {
  border-left: 1px solid #26a69a !important; }

[dir="ltr"] .border-right-teal.border-right-lighten-1 {
  border-right: 1px solid #26a69a !important; }

[dir="rtl"] .border-left-teal.border-left-lighten-1 {
  border-right: 1px solid #26a69a !important; }

[dir="rtl"] .border-right-teal.border-right-lighten-1 {
  border-left: 1px solid #26a69a !important; }

.overlay-teal.overlay-lighten-1 {
  background: #26a69a;
  /* The Fallback */
  background: rgba(38, 166, 154, 0.8); }

.teal {
  color: #009688 !important; }

.bg-teal {
  background-color: #009688 !important; }
  .bg-teal .card-header, .bg-teal .card-footer {
    background-color: transparent; }

.toast-teal {
  background-color: #009688; }

.alert-teal {
  border-color: #009688 !important;
  background-color: #00baa8 !important;
  color: black !important; }
  .alert-teal .alert-link {
    color: black !important; }

.border-teal {
  border-color: #009688; }

.border-top-teal {
  border-top-color: #009688; }

.border-bottom-teal {
  border-bottom-color: #009688; }

[dir="ltr"] .border-left-teal {
  border-left-color: #009688; }

[dir="ltr"] .border-right-teal {
  border-right-color: #009688; }

[dir="rtl"] .border-left-teal {
  border-right-color: #009688; }

[dir="rtl"] .border-right-teal {
  border-left-color: #009688; }

.badge-teal {
  background-color: #009688; }

.panel-teal {
  border-color: #009688; }
  .panel-teal .panel-heading {
    color: #FFF;
    border-color: #009688;
    background-color: #00b09f; }

.bg-teal.tag-glow, .border-teal.tag-glow {
  box-shadow: 0px 0px 10px #009688; }

.overlay-teal {
  background: #009688;
  /* The Fallback */
  background: rgba(0, 150, 136, 0.8); }

.card.card-outline-teal {
  border-width: 1px;
  border-style: solid;
  border-color: #009688;
  background-color: transparent; }
  .card.card-outline-teal .card-header, .card.card-outline-teal .card-footer {
    background-color: transparent; }

.btn-teal.btn-flat {
  background-color: transparent !important;
  color: #009688;
  border: none; }

.btn-teal.btn-raised, .btn-teal.btn-fab {
  background-color: #009688 !important;
  color: #fff !important;
  border-color: #009688; }
  .btn-teal.btn-raised.active, .btn-teal.btn-fab.active {
    background-color: #007268 !important;
    border-color: #007268 !important; }

.btn-group-raised .btn-teal {
  background-color: #009688 !important;
  color: #fff !important; }

.btn-outline-teal {
  border: 1px solid;
  border-color: #009688;
  background-color: transparent;
  color: #009688;
  box-shadow: none !important; }
  .btn-outline-teal:focus {
    background-color: transparent !important;
    color: #009688 !important;
    box-shadow: transparent !important; }
  .btn-outline-teal.active {
    background-color: #009688 !important;
    color: #FFF !important; }
  .btn-outline-teal:hover {
    background-color: #00877a !important;
    color: #FFF !important; }
    .btn-outline-teal:hover svg {
      color: #FFF !important; }

.btn-outline-teal.btn-raised, .btn-outline-teal.btn-fab {
  border: 1px solid;
  border-color: #009688;
  background-color: transparent;
  color: #009688;
  box-shadow: none !important; }
  .btn-outline-teal.btn-raised:focus, .btn-outline-teal.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-teal.btn-raised.active, .btn-outline-teal.btn-fab.active {
    background-color: #009688 !important;
    color: #FFF !important; }
  .btn-outline-teal.btn-raised:hover, .btn-outline-teal.btn-fab:hover {
    background-color: #00877a !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-teal {
  background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-webkit-progress-value {
    background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-moz-progress-bar {
    background-color: #009688; }
  .progress .progress-bar.progress-bar-teal[value]::-ms-fill {
    background-color: #009688; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-teal .progress-bar {
      background-color: #009688; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #009688 !important; }

.teal.darken-1 {
  color: #00897b !important; }

.bg-teal.bg-darken-1 {
  background-color: #00897b !important; }

.btn-teal.btn-darken-1 {
  border-color: #00796b !important;
  background-color: #00897b !important; }
  .btn-teal.btn-darken-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-1:focus, .btn-teal.btn-darken-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-1 {
  border-color: #00897b !important;
  color: #00897b !important; }
  .btn-outline-teal.btn-outline-darken-1:hover {
    background-color: #00897b !important; }

.progress-teal.progress-darken-1 {
  background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-webkit-progress-value {
    background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-moz-progress-bar {
    background-color: #00897b; }
  .progress-teal.progress-darken-1[value]::-ms-fill {
    background-color: #00897b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-1 .progress-bar {
      background-color: #00897b; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00897b !important; }

.border-teal.border-darken-1 {
  border: 1px solid #00897b !important; }

.border-top-teal.border-top-darken-1 {
  border-top: 1px solid #00897b !important; }

.border-bottom-teal.border-bottom-darken-1 {
  border-bottom: 1px solid #00897b !important; }

[dir="ltr"] .border-left-teal.border-left-darken-1 {
  border-left: 1px solid #00897b !important; }

[dir="ltr"] .border-right-teal.border-right-darken-1 {
  border-right: 1px solid #00897b !important; }

[dir="rtl"] .border-left-teal.border-left-darken-1 {
  border-right: 1px solid #00897b !important; }

[dir="rtl"] .border-right-teal.border-right-darken-1 {
  border-left: 1px solid #00897b !important; }

.overlay-teal.overlay-darken-1 {
  background: #00897b;
  /* The Fallback */
  background: rgba(0, 137, 123, 0.8); }

.teal.darken-2 {
  color: #00796b !important; }

.bg-teal.bg-darken-2 {
  background-color: #00796b !important; }

.btn-teal.btn-darken-2 {
  border-color: #00796b !important;
  background-color: #00796b !important; }
  .btn-teal.btn-darken-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-2:focus, .btn-teal.btn-darken-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-2 {
  border-color: #00796b !important;
  color: #00796b !important; }
  .btn-outline-teal.btn-outline-darken-2:hover {
    background-color: #00796b !important; }

.progress-teal.progress-darken-2 {
  background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-webkit-progress-value {
    background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-moz-progress-bar {
    background-color: #00796b; }
  .progress-teal.progress-darken-2[value]::-ms-fill {
    background-color: #00796b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-2 .progress-bar {
      background-color: #00796b; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00796b !important; }

.border-teal.border-darken-2 {
  border: 1px solid #00796b !important; }

.border-top-teal.border-top-darken-2 {
  border-top: 1px solid #00796b !important; }

.border-bottom-teal.border-bottom-darken-2 {
  border-bottom: 1px solid #00796b !important; }

[dir="ltr"] .border-left-teal.border-left-darken-2 {
  border-left: 1px solid #00796b !important; }

[dir="ltr"] .border-right-teal.border-right-darken-2 {
  border-right: 1px solid #00796b !important; }

[dir="rtl"] .border-left-teal.border-left-darken-2 {
  border-right: 1px solid #00796b !important; }

[dir="rtl"] .border-right-teal.border-right-darken-2 {
  border-left: 1px solid #00796b !important; }

.overlay-teal.overlay-darken-2 {
  background: #00796b;
  /* The Fallback */
  background: rgba(0, 121, 107, 0.8); }

.teal.darken-3 {
  color: #00695c !important; }

.bg-teal.bg-darken-3 {
  background-color: #00695c !important; }

.btn-teal.btn-darken-3 {
  border-color: #00796b !important;
  background-color: #00695c !important; }
  .btn-teal.btn-darken-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-3:focus, .btn-teal.btn-darken-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-3 {
  border-color: #00695c !important;
  color: #00695c !important; }
  .btn-outline-teal.btn-outline-darken-3:hover {
    background-color: #00695c !important; }

.progress-teal.progress-darken-3 {
  background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-webkit-progress-value {
    background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-moz-progress-bar {
    background-color: #00695c; }
  .progress-teal.progress-darken-3[value]::-ms-fill {
    background-color: #00695c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-3 .progress-bar {
      background-color: #00695c; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00695c !important; }

.border-teal.border-darken-3 {
  border: 1px solid #00695c !important; }

.border-top-teal.border-top-darken-3 {
  border-top: 1px solid #00695c !important; }

.border-bottom-teal.border-bottom-darken-3 {
  border-bottom: 1px solid #00695c !important; }

[dir="ltr"] .border-left-teal.border-left-darken-3 {
  border-left: 1px solid #00695c !important; }

[dir="ltr"] .border-right-teal.border-right-darken-3 {
  border-right: 1px solid #00695c !important; }

[dir="rtl"] .border-left-teal.border-left-darken-3 {
  border-right: 1px solid #00695c !important; }

[dir="rtl"] .border-right-teal.border-right-darken-3 {
  border-left: 1px solid #00695c !important; }

.overlay-teal.overlay-darken-3 {
  background: #00695c;
  /* The Fallback */
  background: rgba(0, 105, 92, 0.8); }

.teal.darken-4 {
  color: #004d40 !important; }

.bg-teal.bg-darken-4 {
  background-color: #004d40 !important; }

.btn-teal.btn-darken-4 {
  border-color: #00796b !important;
  background-color: #004d40 !important; }
  .btn-teal.btn-darken-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-darken-4:focus, .btn-teal.btn-darken-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-darken-4 {
  border-color: #004d40 !important;
  color: #004d40 !important; }
  .btn-outline-teal.btn-outline-darken-4:hover {
    background-color: #004d40 !important; }

.progress-teal.progress-darken-4 {
  background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-webkit-progress-value {
    background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-moz-progress-bar {
    background-color: #004d40; }
  .progress-teal.progress-darken-4[value]::-ms-fill {
    background-color: #004d40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-darken-4 .progress-bar {
      background-color: #004d40; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #004d40 !important; }

.border-teal.border-darken-4 {
  border: 1px solid #004d40 !important; }

.border-top-teal.border-top-darken-4 {
  border-top: 1px solid #004d40 !important; }

.border-bottom-teal.border-bottom-darken-4 {
  border-bottom: 1px solid #004d40 !important; }

[dir="ltr"] .border-left-teal.border-left-darken-4 {
  border-left: 1px solid #004d40 !important; }

[dir="ltr"] .border-right-teal.border-right-darken-4 {
  border-right: 1px solid #004d40 !important; }

[dir="rtl"] .border-left-teal.border-left-darken-4 {
  border-right: 1px solid #004d40 !important; }

[dir="rtl"] .border-right-teal.border-right-darken-4 {
  border-left: 1px solid #004d40 !important; }

.overlay-teal.overlay-darken-4 {
  background: #004d40;
  /* The Fallback */
  background: rgba(0, 77, 64, 0.8); }

.teal.accent-1 {
  color: #a7ffeb !important; }

.bg-teal.bg-accent-1 {
  background-color: #a7ffeb !important; }

.btn-teal.btn-accent-1 {
  border-color: #00796b !important;
  background-color: #a7ffeb !important; }
  .btn-teal.btn-accent-1:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-1:focus, .btn-teal.btn-accent-1:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-1 {
  border-color: #a7ffeb !important;
  color: #a7ffeb !important; }
  .btn-outline-teal.btn-outline-accent-1:hover {
    background-color: #a7ffeb !important; }

.progress-teal.progress-accent-1 {
  background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-webkit-progress-value {
    background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-moz-progress-bar {
    background-color: #a7ffeb; }
  .progress-teal.progress-accent-1[value]::-ms-fill {
    background-color: #a7ffeb; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-1 .progress-bar {
      background-color: #a7ffeb; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a7ffeb !important; }

.border-teal.border-accent-1 {
  border: 1px solid #a7ffeb !important; }

.border-top-teal.border-top-accent-1 {
  border-top: 1px solid #a7ffeb !important; }

.border-bottom-teal.border-bottom-accent-1 {
  border-bottom: 1px solid #a7ffeb !important; }

[dir="ltr"] .border-left-teal.border-left-accent-1 {
  border-left: 1px solid #a7ffeb !important; }

[dir="ltr"] .border-right-teal.border-right-accent-1 {
  border-right: 1px solid #a7ffeb !important; }

[dir="rtl"] .border-left-teal.border-left-accent-1 {
  border-right: 1px solid #a7ffeb !important; }

[dir="rtl"] .border-right-teal.border-right-accent-1 {
  border-left: 1px solid #a7ffeb !important; }

.overlay-teal.overlay-accent-1 {
  background: #a7ffeb;
  /* The Fallback */
  background: rgba(167, 255, 235, 0.8); }

.teal.accent-2 {
  color: #64ffda !important; }

.bg-teal.bg-accent-2 {
  background-color: #64ffda !important; }

.btn-teal.btn-accent-2 {
  border-color: #00796b !important;
  background-color: #64ffda !important; }
  .btn-teal.btn-accent-2:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-2:focus, .btn-teal.btn-accent-2:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-2 {
  border-color: #64ffda !important;
  color: #64ffda !important; }
  .btn-outline-teal.btn-outline-accent-2:hover {
    background-color: #64ffda !important; }

.progress-teal.progress-accent-2 {
  background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-webkit-progress-value {
    background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-moz-progress-bar {
    background-color: #64ffda; }
  .progress-teal.progress-accent-2[value]::-ms-fill {
    background-color: #64ffda; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-2 .progress-bar {
      background-color: #64ffda; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64ffda !important; }

.border-teal.border-accent-2 {
  border: 1px solid #64ffda !important; }

.border-top-teal.border-top-accent-2 {
  border-top: 1px solid #64ffda !important; }

.border-bottom-teal.border-bottom-accent-2 {
  border-bottom: 1px solid #64ffda !important; }

[dir="ltr"] .border-left-teal.border-left-accent-2 {
  border-left: 1px solid #64ffda !important; }

[dir="ltr"] .border-right-teal.border-right-accent-2 {
  border-right: 1px solid #64ffda !important; }

[dir="rtl"] .border-left-teal.border-left-accent-2 {
  border-right: 1px solid #64ffda !important; }

[dir="rtl"] .border-right-teal.border-right-accent-2 {
  border-left: 1px solid #64ffda !important; }

.overlay-teal.overlay-accent-2 {
  background: #64ffda;
  /* The Fallback */
  background: rgba(100, 255, 218, 0.8); }

.teal.accent-3 {
  color: #1de9b6 !important; }

.bg-teal.bg-accent-3 {
  background-color: #1de9b6 !important; }

.btn-teal.btn-accent-3 {
  border-color: #00796b !important;
  background-color: #1de9b6 !important; }
  .btn-teal.btn-accent-3:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-3:focus, .btn-teal.btn-accent-3:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-3 {
  border-color: #1de9b6 !important;
  color: #1de9b6 !important; }
  .btn-outline-teal.btn-outline-accent-3:hover {
    background-color: #1de9b6 !important; }

.progress-teal.progress-accent-3 {
  background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-webkit-progress-value {
    background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-moz-progress-bar {
    background-color: #1de9b6; }
  .progress-teal.progress-accent-3[value]::-ms-fill {
    background-color: #1de9b6; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-3 .progress-bar {
      background-color: #1de9b6; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1de9b6 !important; }

.border-teal.border-accent-3 {
  border: 1px solid #1de9b6 !important; }

.border-top-teal.border-top-accent-3 {
  border-top: 1px solid #1de9b6 !important; }

.border-bottom-teal.border-bottom-accent-3 {
  border-bottom: 1px solid #1de9b6 !important; }

[dir="ltr"] .border-left-teal.border-left-accent-3 {
  border-left: 1px solid #1de9b6 !important; }

[dir="ltr"] .border-right-teal.border-right-accent-3 {
  border-right: 1px solid #1de9b6 !important; }

[dir="rtl"] .border-left-teal.border-left-accent-3 {
  border-right: 1px solid #1de9b6 !important; }

[dir="rtl"] .border-right-teal.border-right-accent-3 {
  border-left: 1px solid #1de9b6 !important; }

.overlay-teal.overlay-accent-3 {
  background: #1de9b6;
  /* The Fallback */
  background: rgba(29, 233, 182, 0.8); }

.teal.accent-4 {
  color: #00bfa5 !important; }

.bg-teal.bg-accent-4 {
  background-color: #00bfa5 !important; }

.btn-teal.btn-accent-4 {
  border-color: #00796b !important;
  background-color: #00bfa5 !important; }
  .btn-teal.btn-accent-4:hover {
    border-color: #00796b !important;
    background-color: #00695c !important; }
  .btn-teal.btn-accent-4:focus, .btn-teal.btn-accent-4:active {
    border-color: #00695c !important;
    background-color: #004d40 !important; }

.btn-outline-teal.btn-outline-accent-4 {
  border-color: #00bfa5 !important;
  color: #00bfa5 !important; }
  .btn-outline-teal.btn-outline-accent-4:hover {
    background-color: #00bfa5 !important; }

.progress-teal.progress-accent-4 {
  background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00bfa5; }
  .progress-teal.progress-accent-4[value]::-ms-fill {
    background-color: #00bfa5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-teal.progress-accent-4 .progress-bar {
      background-color: #00bfa5; } }

input:focus ~ .bg-teal {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00bfa5 !important; }

.border-teal.border-accent-4 {
  border: 1px solid #00bfa5 !important; }

.border-top-teal.border-top-accent-4 {
  border-top: 1px solid #00bfa5 !important; }

.border-bottom-teal.border-bottom-accent-4 {
  border-bottom: 1px solid #00bfa5 !important; }

[dir="ltr"] .border-left-teal.border-left-accent-4 {
  border-left: 1px solid #00bfa5 !important; }

[dir="ltr"] .border-right-teal.border-right-accent-4 {
  border-right: 1px solid #00bfa5 !important; }

[dir="rtl"] .border-left-teal.border-left-accent-4 {
  border-right: 1px solid #00bfa5 !important; }

[dir="rtl"] .border-right-teal.border-right-accent-4 {
  border-left: 1px solid #00bfa5 !important; }

.overlay-teal.overlay-accent-4 {
  background: #00bfa5;
  /* The Fallback */
  background: rgba(0, 191, 165, 0.8); }

.green.lighten-5 {
  color: #e8f5e9 !important; }

.bg-green.bg-lighten-5 {
  background-color: #e8f5e9 !important; }

.btn-green.btn-lighten-5 {
  border-color: #388e3c !important;
  background-color: #e8f5e9 !important; }
  .btn-green.btn-lighten-5:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-5:focus, .btn-green.btn-lighten-5:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-5 {
  border-color: #e8f5e9 !important;
  color: #e8f5e9 !important; }
  .btn-outline-green.btn-outline-lighten-5:hover {
    background-color: #e8f5e9 !important; }

.progress-green.progress-lighten-5 {
  background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #e8f5e9; }
  .progress-green.progress-lighten-5[value]::-ms-fill {
    background-color: #e8f5e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-5 .progress-bar {
      background-color: #e8f5e9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e8f5e9 !important; }

.border-green.border-lighten-5 {
  border: 1px solid #e8f5e9 !important; }

.border-top-green.border-top-lighten-5 {
  border-top: 1px solid #e8f5e9 !important; }

.border-bottom-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #e8f5e9 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-5 {
  border-left: 1px solid #e8f5e9 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-5 {
  border-right: 1px solid #e8f5e9 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-5 {
  border-right: 1px solid #e8f5e9 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-5 {
  border-left: 1px solid #e8f5e9 !important; }

.overlay-green.overlay-lighten-5 {
  background: #e8f5e9;
  /* The Fallback */
  background: rgba(232, 245, 233, 0.8); }

.green.lighten-4 {
  color: #c8e6c9 !important; }

.bg-green.bg-lighten-4 {
  background-color: #c8e6c9 !important; }

.btn-green.btn-lighten-4 {
  border-color: #388e3c !important;
  background-color: #c8e6c9 !important; }
  .btn-green.btn-lighten-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-4:focus, .btn-green.btn-lighten-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-4 {
  border-color: #c8e6c9 !important;
  color: #c8e6c9 !important; }
  .btn-outline-green.btn-outline-lighten-4:hover {
    background-color: #c8e6c9 !important; }

.progress-green.progress-lighten-4 {
  background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #c8e6c9; }
  .progress-green.progress-lighten-4[value]::-ms-fill {
    background-color: #c8e6c9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-4 .progress-bar {
      background-color: #c8e6c9; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c8e6c9 !important; }

.border-green.border-lighten-4 {
  border: 1px solid #c8e6c9 !important; }

.border-top-green.border-top-lighten-4 {
  border-top: 1px solid #c8e6c9 !important; }

.border-bottom-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #c8e6c9 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-4 {
  border-left: 1px solid #c8e6c9 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-4 {
  border-right: 1px solid #c8e6c9 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-4 {
  border-right: 1px solid #c8e6c9 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-4 {
  border-left: 1px solid #c8e6c9 !important; }

.overlay-green.overlay-lighten-4 {
  background: #c8e6c9;
  /* The Fallback */
  background: rgba(200, 230, 201, 0.8); }

.green.lighten-3 {
  color: #a5d6a7 !important; }

.bg-green.bg-lighten-3 {
  background-color: #a5d6a7 !important; }

.btn-green.btn-lighten-3 {
  border-color: #388e3c !important;
  background-color: #a5d6a7 !important; }
  .btn-green.btn-lighten-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-3:focus, .btn-green.btn-lighten-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-3 {
  border-color: #a5d6a7 !important;
  color: #a5d6a7 !important; }
  .btn-outline-green.btn-outline-lighten-3:hover {
    background-color: #a5d6a7 !important; }

.progress-green.progress-lighten-3 {
  background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #a5d6a7; }
  .progress-green.progress-lighten-3[value]::-ms-fill {
    background-color: #a5d6a7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-3 .progress-bar {
      background-color: #a5d6a7; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a5d6a7 !important; }

.border-green.border-lighten-3 {
  border: 1px solid #a5d6a7 !important; }

.border-top-green.border-top-lighten-3 {
  border-top: 1px solid #a5d6a7 !important; }

.border-bottom-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #a5d6a7 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-3 {
  border-left: 1px solid #a5d6a7 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-3 {
  border-right: 1px solid #a5d6a7 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-3 {
  border-right: 1px solid #a5d6a7 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-3 {
  border-left: 1px solid #a5d6a7 !important; }

.overlay-green.overlay-lighten-3 {
  background: #a5d6a7;
  /* The Fallback */
  background: rgba(165, 214, 167, 0.8); }

.green.lighten-2 {
  color: #81c784 !important; }

.bg-green.bg-lighten-2 {
  background-color: #81c784 !important; }

.btn-green.btn-lighten-2 {
  border-color: #388e3c !important;
  background-color: #81c784 !important; }
  .btn-green.btn-lighten-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-2:focus, .btn-green.btn-lighten-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-2 {
  border-color: #81c784 !important;
  color: #81c784 !important; }
  .btn-outline-green.btn-outline-lighten-2:hover {
    background-color: #81c784 !important; }

.progress-green.progress-lighten-2 {
  background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #81c784; }
  .progress-green.progress-lighten-2[value]::-ms-fill {
    background-color: #81c784; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-2 .progress-bar {
      background-color: #81c784; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #81c784 !important; }

.border-green.border-lighten-2 {
  border: 1px solid #81c784 !important; }

.border-top-green.border-top-lighten-2 {
  border-top: 1px solid #81c784 !important; }

.border-bottom-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #81c784 !important; }

[dir="ltr"] .border-left-green.border-left-lighten-2 {
  border-left: 1px solid #81c784 !important; }

[dir="ltr"] .border-right-green.border-right-lighten-2 {
  border-right: 1px solid #81c784 !important; }

[dir="rtl"] .border-left-green.border-left-lighten-2 {
  border-right: 1px solid #81c784 !important; }

[dir="rtl"] .border-right-green.border-right-lighten-2 {
  border-left: 1px solid #81c784 !important; }

.overlay-green.overlay-lighten-2 {
  background: #81c784;
  /* The Fallback */
  background: rgba(129, 199, 132, 0.8); }

.green.lighten-1 {
  color: #66bb6a !important; }

.bg-green.bg-lighten-1 {
  background-color: #66bb6a !important; }

.btn-green.btn-lighten-1 {
  border-color: #388e3c !important;
  background-color: #66bb6a !important; }
  .btn-green.btn-lighten-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-lighten-1:focus, .btn-green.btn-lighten-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-lighten-1 {
  border-color: #66bb6a !important;
  color: #66bb6a !important; }
  .btn-outline-green.btn-outline-lighten-1:hover {
    background-color: #66bb6a !important; }

.progress-green.progress-lighten-1 {
  background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #66bb6a; }
  .progress-green.progress-lighten-1[value]::-ms-fill {
    background-color: #66bb6a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-lighten-1 .progress-bar {
      background-color: #66bb6a; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #66bb6a !important; }

.border-green.border-lighten-1 {
  border: 1px solid #66bb6a !important; }

.border-top-green.border-top-lighten-1 {
  border-top: 1px solid #66bb6a !important; }

.border-bottom-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #66bb6a !important; }

[dir="ltr"] .border-left-green.border-left-lighten-1 {
  border-left: 1px solid #66bb6a !important; }

[dir="ltr"] .border-right-green.border-right-lighten-1 {
  border-right: 1px solid #66bb6a !important; }

[dir="rtl"] .border-left-green.border-left-lighten-1 {
  border-right: 1px solid #66bb6a !important; }

[dir="rtl"] .border-right-green.border-right-lighten-1 {
  border-left: 1px solid #66bb6a !important; }

.overlay-green.overlay-lighten-1 {
  background: #66bb6a;
  /* The Fallback */
  background: rgba(102, 187, 106, 0.8); }

.green {
  color: #4caf50 !important; }

.bg-green {
  background-color: #4caf50 !important; }
  .bg-green .card-header, .bg-green .card-footer {
    background-color: transparent; }

.toast-green {
  background-color: #4caf50; }

.alert-green {
  border-color: #4caf50 !important;
  background-color: #63bb67 !important;
  color: #163317 !important; }
  .alert-green .alert-link {
    color: #0a160a !important; }

.border-green {
  border-color: #4caf50; }

.border-top-green {
  border-top-color: #4caf50; }

.border-bottom-green {
  border-bottom-color: #4caf50; }

[dir="ltr"] .border-left-green {
  border-left-color: #4caf50; }

[dir="ltr"] .border-right-green {
  border-right-color: #4caf50; }

[dir="rtl"] .border-left-green {
  border-right-color: #4caf50; }

[dir="rtl"] .border-right-green {
  border-left-color: #4caf50; }

.badge-green {
  background-color: #4caf50; }

.panel-green {
  border-color: #4caf50; }
  .panel-green .panel-heading {
    color: #FFF;
    border-color: #4caf50;
    background-color: #5cb860; }

.bg-green.tag-glow, .border-green.tag-glow {
  box-shadow: 0px 0px 10px #4caf50; }

.overlay-green {
  background: #4caf50;
  /* The Fallback */
  background: rgba(76, 175, 80, 0.8); }

.card.card-outline-green {
  border-width: 1px;
  border-style: solid;
  border-color: #4caf50;
  background-color: transparent; }
  .card.card-outline-green .card-header, .card.card-outline-green .card-footer {
    background-color: transparent; }

.btn-green.btn-flat {
  background-color: transparent !important;
  color: #4caf50;
  border: none; }

.btn-green.btn-raised, .btn-green.btn-fab {
  background-color: #4caf50 !important;
  color: #fff !important;
  border-color: #4caf50; }
  .btn-green.btn-raised.active, .btn-green.btn-fab.active {
    background-color: #419645 !important;
    border-color: #419645 !important; }

.btn-group-raised .btn-green {
  background-color: #4caf50 !important;
  color: #fff !important; }

.btn-outline-green {
  border: 1px solid;
  border-color: #4caf50;
  background-color: transparent;
  color: #4caf50;
  box-shadow: none !important; }
  .btn-outline-green:focus {
    background-color: transparent !important;
    color: #4caf50 !important;
    box-shadow: transparent !important; }
  .btn-outline-green.active {
    background-color: #4caf50 !important;
    color: #FFF !important; }
  .btn-outline-green:hover {
    background-color: #47a44b !important;
    color: #FFF !important; }
    .btn-outline-green:hover svg {
      color: #FFF !important; }

.btn-outline-green.btn-raised, .btn-outline-green.btn-fab {
  border: 1px solid;
  border-color: #4caf50;
  background-color: transparent;
  color: #4caf50;
  box-shadow: none !important; }
  .btn-outline-green.btn-raised:focus, .btn-outline-green.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-green.btn-raised.active, .btn-outline-green.btn-fab.active {
    background-color: #4caf50 !important;
    color: #FFF !important; }
  .btn-outline-green.btn-raised:hover, .btn-outline-green.btn-fab:hover {
    background-color: #47a44b !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-green {
  background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-webkit-progress-value {
    background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-moz-progress-bar {
    background-color: #4caf50; }
  .progress .progress-bar.progress-bar-green[value]::-ms-fill {
    background-color: #4caf50; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-green .progress-bar {
      background-color: #4caf50; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4caf50 !important; }

.green.darken-1 {
  color: #43a047 !important; }

.bg-green.bg-darken-1 {
  background-color: #43a047 !important; }

.btn-green.btn-darken-1 {
  border-color: #388e3c !important;
  background-color: #43a047 !important; }
  .btn-green.btn-darken-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-1:focus, .btn-green.btn-darken-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-1 {
  border-color: #43a047 !important;
  color: #43a047 !important; }
  .btn-outline-green.btn-outline-darken-1:hover {
    background-color: #43a047 !important; }

.progress-green.progress-darken-1 {
  background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #43a047; }
  .progress-green.progress-darken-1[value]::-ms-fill {
    background-color: #43a047; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-1 .progress-bar {
      background-color: #43a047; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #43a047 !important; }

.border-green.border-darken-1 {
  border: 1px solid #43a047 !important; }

.border-top-green.border-top-darken-1 {
  border-top: 1px solid #43a047 !important; }

.border-bottom-green.border-bottom-darken-1 {
  border-bottom: 1px solid #43a047 !important; }

[dir="ltr"] .border-left-green.border-left-darken-1 {
  border-left: 1px solid #43a047 !important; }

[dir="ltr"] .border-right-green.border-right-darken-1 {
  border-right: 1px solid #43a047 !important; }

[dir="rtl"] .border-left-green.border-left-darken-1 {
  border-right: 1px solid #43a047 !important; }

[dir="rtl"] .border-right-green.border-right-darken-1 {
  border-left: 1px solid #43a047 !important; }

.overlay-green.overlay-darken-1 {
  background: #43a047;
  /* The Fallback */
  background: rgba(67, 160, 71, 0.8); }

.green.darken-2 {
  color: #388e3c !important; }

.bg-green.bg-darken-2 {
  background-color: #388e3c !important; }

.btn-green.btn-darken-2 {
  border-color: #388e3c !important;
  background-color: #388e3c !important; }
  .btn-green.btn-darken-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-2:focus, .btn-green.btn-darken-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-2 {
  border-color: #388e3c !important;
  color: #388e3c !important; }
  .btn-outline-green.btn-outline-darken-2:hover {
    background-color: #388e3c !important; }

.progress-green.progress-darken-2 {
  background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #388e3c; }
  .progress-green.progress-darken-2[value]::-ms-fill {
    background-color: #388e3c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-2 .progress-bar {
      background-color: #388e3c; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #388e3c !important; }

.border-green.border-darken-2 {
  border: 1px solid #388e3c !important; }

.border-top-green.border-top-darken-2 {
  border-top: 1px solid #388e3c !important; }

.border-bottom-green.border-bottom-darken-2 {
  border-bottom: 1px solid #388e3c !important; }

[dir="ltr"] .border-left-green.border-left-darken-2 {
  border-left: 1px solid #388e3c !important; }

[dir="ltr"] .border-right-green.border-right-darken-2 {
  border-right: 1px solid #388e3c !important; }

[dir="rtl"] .border-left-green.border-left-darken-2 {
  border-right: 1px solid #388e3c !important; }

[dir="rtl"] .border-right-green.border-right-darken-2 {
  border-left: 1px solid #388e3c !important; }

.overlay-green.overlay-darken-2 {
  background: #388e3c;
  /* The Fallback */
  background: rgba(56, 142, 60, 0.8); }

.green.darken-3 {
  color: #2e7d32 !important; }

.bg-green.bg-darken-3 {
  background-color: #2e7d32 !important; }

.btn-green.btn-darken-3 {
  border-color: #388e3c !important;
  background-color: #2e7d32 !important; }
  .btn-green.btn-darken-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-3:focus, .btn-green.btn-darken-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-3 {
  border-color: #2e7d32 !important;
  color: #2e7d32 !important; }
  .btn-outline-green.btn-outline-darken-3:hover {
    background-color: #2e7d32 !important; }

.progress-green.progress-darken-3 {
  background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #2e7d32; }
  .progress-green.progress-darken-3[value]::-ms-fill {
    background-color: #2e7d32; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-3 .progress-bar {
      background-color: #2e7d32; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #2e7d32 !important; }

.border-green.border-darken-3 {
  border: 1px solid #2e7d32 !important; }

.border-top-green.border-top-darken-3 {
  border-top: 1px solid #2e7d32 !important; }

.border-bottom-green.border-bottom-darken-3 {
  border-bottom: 1px solid #2e7d32 !important; }

[dir="ltr"] .border-left-green.border-left-darken-3 {
  border-left: 1px solid #2e7d32 !important; }

[dir="ltr"] .border-right-green.border-right-darken-3 {
  border-right: 1px solid #2e7d32 !important; }

[dir="rtl"] .border-left-green.border-left-darken-3 {
  border-right: 1px solid #2e7d32 !important; }

[dir="rtl"] .border-right-green.border-right-darken-3 {
  border-left: 1px solid #2e7d32 !important; }

.overlay-green.overlay-darken-3 {
  background: #2e7d32;
  /* The Fallback */
  background: rgba(46, 125, 50, 0.8); }

.green.darken-4 {
  color: #1b5e20 !important; }

.bg-green.bg-darken-4 {
  background-color: #1b5e20 !important; }

.btn-green.btn-darken-4 {
  border-color: #388e3c !important;
  background-color: #1b5e20 !important; }
  .btn-green.btn-darken-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-darken-4:focus, .btn-green.btn-darken-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-darken-4 {
  border-color: #1b5e20 !important;
  color: #1b5e20 !important; }
  .btn-outline-green.btn-outline-darken-4:hover {
    background-color: #1b5e20 !important; }

.progress-green.progress-darken-4 {
  background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #1b5e20; }
  .progress-green.progress-darken-4[value]::-ms-fill {
    background-color: #1b5e20; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-darken-4 .progress-bar {
      background-color: #1b5e20; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #1b5e20 !important; }

.border-green.border-darken-4 {
  border: 1px solid #1b5e20 !important; }

.border-top-green.border-top-darken-4 {
  border-top: 1px solid #1b5e20 !important; }

.border-bottom-green.border-bottom-darken-4 {
  border-bottom: 1px solid #1b5e20 !important; }

[dir="ltr"] .border-left-green.border-left-darken-4 {
  border-left: 1px solid #1b5e20 !important; }

[dir="ltr"] .border-right-green.border-right-darken-4 {
  border-right: 1px solid #1b5e20 !important; }

[dir="rtl"] .border-left-green.border-left-darken-4 {
  border-right: 1px solid #1b5e20 !important; }

[dir="rtl"] .border-right-green.border-right-darken-4 {
  border-left: 1px solid #1b5e20 !important; }

.overlay-green.overlay-darken-4 {
  background: #1b5e20;
  /* The Fallback */
  background: rgba(27, 94, 32, 0.8); }

.green.accent-1 {
  color: #b9f6ca !important; }

.bg-green.bg-accent-1 {
  background-color: #b9f6ca !important; }

.btn-green.btn-accent-1 {
  border-color: #388e3c !important;
  background-color: #b9f6ca !important; }
  .btn-green.btn-accent-1:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-1:focus, .btn-green.btn-accent-1:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-1 {
  border-color: #b9f6ca !important;
  color: #b9f6ca !important; }
  .btn-outline-green.btn-outline-accent-1:hover {
    background-color: #b9f6ca !important; }

.progress-green.progress-accent-1 {
  background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #b9f6ca; }
  .progress-green.progress-accent-1[value]::-ms-fill {
    background-color: #b9f6ca; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-1 .progress-bar {
      background-color: #b9f6ca; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b9f6ca !important; }

.border-green.border-accent-1 {
  border: 1px solid #b9f6ca !important; }

.border-top-green.border-top-accent-1 {
  border-top: 1px solid #b9f6ca !important; }

.border-bottom-green.border-bottom-accent-1 {
  border-bottom: 1px solid #b9f6ca !important; }

[dir="ltr"] .border-left-green.border-left-accent-1 {
  border-left: 1px solid #b9f6ca !important; }

[dir="ltr"] .border-right-green.border-right-accent-1 {
  border-right: 1px solid #b9f6ca !important; }

[dir="rtl"] .border-left-green.border-left-accent-1 {
  border-right: 1px solid #b9f6ca !important; }

[dir="rtl"] .border-right-green.border-right-accent-1 {
  border-left: 1px solid #b9f6ca !important; }

.overlay-green.overlay-accent-1 {
  background: #b9f6ca;
  /* The Fallback */
  background: rgba(185, 246, 202, 0.8); }

.green.accent-2 {
  color: #69f0ae !important; }

.bg-green.bg-accent-2 {
  background-color: #69f0ae !important; }

.btn-green.btn-accent-2 {
  border-color: #388e3c !important;
  background-color: #69f0ae !important; }
  .btn-green.btn-accent-2:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-2:focus, .btn-green.btn-accent-2:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-2 {
  border-color: #69f0ae !important;
  color: #69f0ae !important; }
  .btn-outline-green.btn-outline-accent-2:hover {
    background-color: #69f0ae !important; }

.progress-green.progress-accent-2 {
  background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #69f0ae; }
  .progress-green.progress-accent-2[value]::-ms-fill {
    background-color: #69f0ae; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-2 .progress-bar {
      background-color: #69f0ae; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #69f0ae !important; }

.border-green.border-accent-2 {
  border: 1px solid #69f0ae !important; }

.border-top-green.border-top-accent-2 {
  border-top: 1px solid #69f0ae !important; }

.border-bottom-green.border-bottom-accent-2 {
  border-bottom: 1px solid #69f0ae !important; }

[dir="ltr"] .border-left-green.border-left-accent-2 {
  border-left: 1px solid #69f0ae !important; }

[dir="ltr"] .border-right-green.border-right-accent-2 {
  border-right: 1px solid #69f0ae !important; }

[dir="rtl"] .border-left-green.border-left-accent-2 {
  border-right: 1px solid #69f0ae !important; }

[dir="rtl"] .border-right-green.border-right-accent-2 {
  border-left: 1px solid #69f0ae !important; }

.overlay-green.overlay-accent-2 {
  background: #69f0ae;
  /* The Fallback */
  background: rgba(105, 240, 174, 0.8); }

.green.accent-3 {
  color: #00e676 !important; }

.bg-green.bg-accent-3 {
  background-color: #00e676 !important; }

.btn-green.btn-accent-3 {
  border-color: #388e3c !important;
  background-color: #00e676 !important; }
  .btn-green.btn-accent-3:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-3:focus, .btn-green.btn-accent-3:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-3 {
  border-color: #00e676 !important;
  color: #00e676 !important; }
  .btn-outline-green.btn-outline-accent-3:hover {
    background-color: #00e676 !important; }

.progress-green.progress-accent-3 {
  background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #00e676; }
  .progress-green.progress-accent-3[value]::-ms-fill {
    background-color: #00e676; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-3 .progress-bar {
      background-color: #00e676; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00e676 !important; }

.border-green.border-accent-3 {
  border: 1px solid #00e676 !important; }

.border-top-green.border-top-accent-3 {
  border-top: 1px solid #00e676 !important; }

.border-bottom-green.border-bottom-accent-3 {
  border-bottom: 1px solid #00e676 !important; }

[dir="ltr"] .border-left-green.border-left-accent-3 {
  border-left: 1px solid #00e676 !important; }

[dir="ltr"] .border-right-green.border-right-accent-3 {
  border-right: 1px solid #00e676 !important; }

[dir="rtl"] .border-left-green.border-left-accent-3 {
  border-right: 1px solid #00e676 !important; }

[dir="rtl"] .border-right-green.border-right-accent-3 {
  border-left: 1px solid #00e676 !important; }

.overlay-green.overlay-accent-3 {
  background: #00e676;
  /* The Fallback */
  background: rgba(0, 230, 118, 0.8); }

.green.accent-4 {
  color: #00c853 !important; }

.bg-green.bg-accent-4 {
  background-color: #00c853 !important; }

.btn-green.btn-accent-4 {
  border-color: #388e3c !important;
  background-color: #00c853 !important; }
  .btn-green.btn-accent-4:hover {
    border-color: #388e3c !important;
    background-color: #2e7d32 !important; }
  .btn-green.btn-accent-4:focus, .btn-green.btn-accent-4:active {
    border-color: #2e7d32 !important;
    background-color: #1b5e20 !important; }

.btn-outline-green.btn-outline-accent-4 {
  border-color: #00c853 !important;
  color: #00c853 !important; }
  .btn-outline-green.btn-outline-accent-4:hover {
    background-color: #00c853 !important; }

.progress-green.progress-accent-4 {
  background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #00c853; }
  .progress-green.progress-accent-4[value]::-ms-fill {
    background-color: #00c853; }
  @media screen and (min-width: 0 \0 ) {
    .progress-green.progress-accent-4 .progress-bar {
      background-color: #00c853; } }

input:focus ~ .bg-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #00c853 !important; }

.border-green.border-accent-4 {
  border: 1px solid #00c853 !important; }

.border-top-green.border-top-accent-4 {
  border-top: 1px solid #00c853 !important; }

.border-bottom-green.border-bottom-accent-4 {
  border-bottom: 1px solid #00c853 !important; }

[dir="ltr"] .border-left-green.border-left-accent-4 {
  border-left: 1px solid #00c853 !important; }

[dir="ltr"] .border-right-green.border-right-accent-4 {
  border-right: 1px solid #00c853 !important; }

[dir="rtl"] .border-left-green.border-left-accent-4 {
  border-right: 1px solid #00c853 !important; }

[dir="rtl"] .border-right-green.border-right-accent-4 {
  border-left: 1px solid #00c853 !important; }

.overlay-green.overlay-accent-4 {
  background: #00c853;
  /* The Fallback */
  background: rgba(0, 200, 83, 0.8); }

.light-green.lighten-5 {
  color: #f1f8e9 !important; }

.bg-light-green.bg-lighten-5 {
  background-color: #f1f8e9 !important; }

.btn-light-green.btn-lighten-5 {
  border-color: #689f38 !important;
  background-color: #f1f8e9 !important; }
  .btn-light-green.btn-lighten-5:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-5:focus, .btn-light-green.btn-lighten-5:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-5 {
  border-color: #f1f8e9 !important;
  color: #f1f8e9 !important; }
  .btn-outline-light-green.btn-outline-lighten-5:hover {
    background-color: #f1f8e9 !important; }

.progress-light-green.progress-lighten-5 {
  background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f1f8e9; }
  .progress-light-green.progress-lighten-5[value]::-ms-fill {
    background-color: #f1f8e9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-5 .progress-bar {
      background-color: #f1f8e9; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f1f8e9 !important; }

.border-light-green.border-lighten-5 {
  border: 1px solid #f1f8e9 !important; }

.border-top-light-green.border-top-lighten-5 {
  border-top: 1px solid #f1f8e9 !important; }

.border-bottom-light-green.border-bottom-lighten-5 {
  border-bottom: 1px solid #f1f8e9 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-5 {
  border-left: 1px solid #f1f8e9 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-5 {
  border-right: 1px solid #f1f8e9 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-5 {
  border-right: 1px solid #f1f8e9 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-5 {
  border-left: 1px solid #f1f8e9 !important; }

.overlay-light-green.overlay-lighten-5 {
  background: #f1f8e9;
  /* The Fallback */
  background: rgba(241, 248, 233, 0.8); }

.light-green.lighten-4 {
  color: #dcedc8 !important; }

.bg-light-green.bg-lighten-4 {
  background-color: #dcedc8 !important; }

.btn-light-green.btn-lighten-4 {
  border-color: #689f38 !important;
  background-color: #dcedc8 !important; }
  .btn-light-green.btn-lighten-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-4:focus, .btn-light-green.btn-lighten-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-4 {
  border-color: #dcedc8 !important;
  color: #dcedc8 !important; }
  .btn-outline-light-green.btn-outline-lighten-4:hover {
    background-color: #dcedc8 !important; }

.progress-light-green.progress-lighten-4 {
  background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #dcedc8; }
  .progress-light-green.progress-lighten-4[value]::-ms-fill {
    background-color: #dcedc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-4 .progress-bar {
      background-color: #dcedc8; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dcedc8 !important; }

.border-light-green.border-lighten-4 {
  border: 1px solid #dcedc8 !important; }

.border-top-light-green.border-top-lighten-4 {
  border-top: 1px solid #dcedc8 !important; }

.border-bottom-light-green.border-bottom-lighten-4 {
  border-bottom: 1px solid #dcedc8 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-4 {
  border-left: 1px solid #dcedc8 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-4 {
  border-right: 1px solid #dcedc8 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-4 {
  border-right: 1px solid #dcedc8 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-4 {
  border-left: 1px solid #dcedc8 !important; }

.overlay-light-green.overlay-lighten-4 {
  background: #dcedc8;
  /* The Fallback */
  background: rgba(220, 237, 200, 0.8); }

.light-green.lighten-3 {
  color: #c5e1a5 !important; }

.bg-light-green.bg-lighten-3 {
  background-color: #c5e1a5 !important; }

.btn-light-green.btn-lighten-3 {
  border-color: #689f38 !important;
  background-color: #c5e1a5 !important; }
  .btn-light-green.btn-lighten-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-3:focus, .btn-light-green.btn-lighten-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-3 {
  border-color: #c5e1a5 !important;
  color: #c5e1a5 !important; }
  .btn-outline-light-green.btn-outline-lighten-3:hover {
    background-color: #c5e1a5 !important; }

.progress-light-green.progress-lighten-3 {
  background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #c5e1a5; }
  .progress-light-green.progress-lighten-3[value]::-ms-fill {
    background-color: #c5e1a5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-3 .progress-bar {
      background-color: #c5e1a5; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c5e1a5 !important; }

.border-light-green.border-lighten-3 {
  border: 1px solid #c5e1a5 !important; }

.border-top-light-green.border-top-lighten-3 {
  border-top: 1px solid #c5e1a5 !important; }

.border-bottom-light-green.border-bottom-lighten-3 {
  border-bottom: 1px solid #c5e1a5 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-3 {
  border-left: 1px solid #c5e1a5 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-3 {
  border-right: 1px solid #c5e1a5 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-3 {
  border-right: 1px solid #c5e1a5 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-3 {
  border-left: 1px solid #c5e1a5 !important; }

.overlay-light-green.overlay-lighten-3 {
  background: #c5e1a5;
  /* The Fallback */
  background: rgba(197, 225, 165, 0.8); }

.light-green.lighten-2 {
  color: #aed581 !important; }

.bg-light-green.bg-lighten-2 {
  background-color: #aed581 !important; }

.btn-light-green.btn-lighten-2 {
  border-color: #689f38 !important;
  background-color: #aed581 !important; }
  .btn-light-green.btn-lighten-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-2:focus, .btn-light-green.btn-lighten-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-2 {
  border-color: #aed581 !important;
  color: #aed581 !important; }
  .btn-outline-light-green.btn-outline-lighten-2:hover {
    background-color: #aed581 !important; }

.progress-light-green.progress-lighten-2 {
  background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #aed581; }
  .progress-light-green.progress-lighten-2[value]::-ms-fill {
    background-color: #aed581; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-2 .progress-bar {
      background-color: #aed581; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aed581 !important; }

.border-light-green.border-lighten-2 {
  border: 1px solid #aed581 !important; }

.border-top-light-green.border-top-lighten-2 {
  border-top: 1px solid #aed581 !important; }

.border-bottom-light-green.border-bottom-lighten-2 {
  border-bottom: 1px solid #aed581 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-2 {
  border-left: 1px solid #aed581 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-2 {
  border-right: 1px solid #aed581 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-2 {
  border-right: 1px solid #aed581 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-2 {
  border-left: 1px solid #aed581 !important; }

.overlay-light-green.overlay-lighten-2 {
  background: #aed581;
  /* The Fallback */
  background: rgba(174, 213, 129, 0.8); }

.light-green.lighten-1 {
  color: #9ccc65 !important; }

.bg-light-green.bg-lighten-1 {
  background-color: #9ccc65 !important; }

.btn-light-green.btn-lighten-1 {
  border-color: #689f38 !important;
  background-color: #9ccc65 !important; }
  .btn-light-green.btn-lighten-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-lighten-1:focus, .btn-light-green.btn-lighten-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-lighten-1 {
  border-color: #9ccc65 !important;
  color: #9ccc65 !important; }
  .btn-outline-light-green.btn-outline-lighten-1:hover {
    background-color: #9ccc65 !important; }

.progress-light-green.progress-lighten-1 {
  background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #9ccc65; }
  .progress-light-green.progress-lighten-1[value]::-ms-fill {
    background-color: #9ccc65; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-lighten-1 .progress-bar {
      background-color: #9ccc65; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9ccc65 !important; }

.border-light-green.border-lighten-1 {
  border: 1px solid #9ccc65 !important; }

.border-top-light-green.border-top-lighten-1 {
  border-top: 1px solid #9ccc65 !important; }

.border-bottom-light-green.border-bottom-lighten-1 {
  border-bottom: 1px solid #9ccc65 !important; }

[dir="ltr"] .border-left-light-green.border-left-lighten-1 {
  border-left: 1px solid #9ccc65 !important; }

[dir="ltr"] .border-right-light-green.border-right-lighten-1 {
  border-right: 1px solid #9ccc65 !important; }

[dir="rtl"] .border-left-light-green.border-left-lighten-1 {
  border-right: 1px solid #9ccc65 !important; }

[dir="rtl"] .border-right-light-green.border-right-lighten-1 {
  border-left: 1px solid #9ccc65 !important; }

.overlay-light-green.overlay-lighten-1 {
  background: #9ccc65;
  /* The Fallback */
  background: rgba(156, 204, 101, 0.8); }

.light-green {
  color: #8bc34a !important; }

.bg-light-green {
  background-color: #8bc34a !important; }
  .bg-light-green .card-header, .bg-light-green .card-footer {
    background-color: transparent; }

.toast-light-green {
  background-color: #8bc34a; }

.alert-light-green {
  border-color: #8bc34a !important;
  background-color: #9ccc65 !important;
  color: #2f4417 !important; }
  .alert-light-green .alert-link {
    color: #1a250c !important; }

.border-light-green {
  border-color: #8bc34a; }

.border-top-light-green {
  border-top-color: #8bc34a; }

.border-bottom-light-green {
  border-bottom-color: #8bc34a; }

[dir="ltr"] .border-left-light-green {
  border-left-color: #8bc34a; }

[dir="ltr"] .border-right-light-green {
  border-right-color: #8bc34a; }

[dir="rtl"] .border-left-light-green {
  border-right-color: #8bc34a; }

[dir="rtl"] .border-right-light-green {
  border-left-color: #8bc34a; }

.badge-light-green {
  background-color: #8bc34a; }

.panel-light-green {
  border-color: #8bc34a; }
  .panel-light-green .panel-heading {
    color: #FFF;
    border-color: #8bc34a;
    background-color: #97c95d; }

.bg-light-green.tag-glow, .border-light-green.tag-glow {
  box-shadow: 0px 0px 10px #8bc34a; }

.overlay-light-green {
  background: #8bc34a;
  /* The Fallback */
  background: rgba(139, 195, 74, 0.8); }

.card.card-outline-light-green {
  border-width: 1px;
  border-style: solid;
  border-color: #8bc34a;
  background-color: transparent; }
  .card.card-outline-light-green .card-header, .card.card-outline-light-green .card-footer {
    background-color: transparent; }

.btn-light-green.btn-flat {
  background-color: transparent !important;
  color: #8bc34a;
  border: none; }

.btn-light-green.btn-raised, .btn-light-green.btn-fab {
  background-color: #8bc34a !important;
  color: #fff !important;
  border-color: #8bc34a; }
  .btn-light-green.btn-raised.active, .btn-light-green.btn-fab.active {
    background-color: #79af3a !important;
    border-color: #79af3a !important; }

.btn-group-raised .btn-light-green {
  background-color: #8bc34a !important;
  color: #fff !important; }

.btn-outline-light-green {
  border: 1px solid;
  border-color: #8bc34a;
  background-color: transparent;
  color: #8bc34a;
  box-shadow: none !important; }
  .btn-outline-light-green:focus {
    background-color: transparent !important;
    color: #8bc34a !important;
    box-shadow: transparent !important; }
  .btn-outline-light-green.active {
    background-color: #8bc34a !important;
    color: #FFF !important; }
  .btn-outline-light-green:hover {
    background-color: #84bf3f !important;
    color: #FFF !important; }
    .btn-outline-light-green:hover svg {
      color: #FFF !important; }

.btn-outline-light-green.btn-raised, .btn-outline-light-green.btn-fab {
  border: 1px solid;
  border-color: #8bc34a;
  background-color: transparent;
  color: #8bc34a;
  box-shadow: none !important; }
  .btn-outline-light-green.btn-raised:focus, .btn-outline-light-green.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-light-green.btn-raised.active, .btn-outline-light-green.btn-fab.active {
    background-color: #8bc34a !important;
    color: #FFF !important; }
  .btn-outline-light-green.btn-raised:hover, .btn-outline-light-green.btn-fab:hover {
    background-color: #84bf3f !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-light-green {
  background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-webkit-progress-value {
    background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-moz-progress-bar {
    background-color: #8bc34a; }
  .progress .progress-bar.progress-bar-light-green[value]::-ms-fill {
    background-color: #8bc34a; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-light-green .progress-bar {
      background-color: #8bc34a; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8bc34a !important; }

.light-green.darken-1 {
  color: #7cb342 !important; }

.bg-light-green.bg-darken-1 {
  background-color: #7cb342 !important; }

.btn-light-green.btn-darken-1 {
  border-color: #689f38 !important;
  background-color: #7cb342 !important; }
  .btn-light-green.btn-darken-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-1:focus, .btn-light-green.btn-darken-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-1 {
  border-color: #7cb342 !important;
  color: #7cb342 !important; }
  .btn-outline-light-green.btn-outline-darken-1:hover {
    background-color: #7cb342 !important; }

.progress-light-green.progress-darken-1 {
  background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-webkit-progress-value {
    background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-moz-progress-bar {
    background-color: #7cb342; }
  .progress-light-green.progress-darken-1[value]::-ms-fill {
    background-color: #7cb342; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-1 .progress-bar {
      background-color: #7cb342; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #7cb342 !important; }

.border-light-green.border-darken-1 {
  border: 1px solid #7cb342 !important; }

.border-top-light-green.border-top-darken-1 {
  border-top: 1px solid #7cb342 !important; }

.border-bottom-light-green.border-bottom-darken-1 {
  border-bottom: 1px solid #7cb342 !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-1 {
  border-left: 1px solid #7cb342 !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-1 {
  border-right: 1px solid #7cb342 !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-1 {
  border-right: 1px solid #7cb342 !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-1 {
  border-left: 1px solid #7cb342 !important; }

.overlay-light-green.overlay-darken-1 {
  background: #7cb342;
  /* The Fallback */
  background: rgba(124, 179, 66, 0.8); }

.light-green.darken-2 {
  color: #689f38 !important; }

.bg-light-green.bg-darken-2 {
  background-color: #689f38 !important; }

.btn-light-green.btn-darken-2 {
  border-color: #689f38 !important;
  background-color: #689f38 !important; }
  .btn-light-green.btn-darken-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-2:focus, .btn-light-green.btn-darken-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-2 {
  border-color: #689f38 !important;
  color: #689f38 !important; }
  .btn-outline-light-green.btn-outline-darken-2:hover {
    background-color: #689f38 !important; }

.progress-light-green.progress-darken-2 {
  background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-webkit-progress-value {
    background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-moz-progress-bar {
    background-color: #689f38; }
  .progress-light-green.progress-darken-2[value]::-ms-fill {
    background-color: #689f38; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-2 .progress-bar {
      background-color: #689f38; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #689f38 !important; }

.border-light-green.border-darken-2 {
  border: 1px solid #689f38 !important; }

.border-top-light-green.border-top-darken-2 {
  border-top: 1px solid #689f38 !important; }

.border-bottom-light-green.border-bottom-darken-2 {
  border-bottom: 1px solid #689f38 !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-2 {
  border-left: 1px solid #689f38 !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-2 {
  border-right: 1px solid #689f38 !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-2 {
  border-right: 1px solid #689f38 !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-2 {
  border-left: 1px solid #689f38 !important; }

.overlay-light-green.overlay-darken-2 {
  background: #689f38;
  /* The Fallback */
  background: rgba(104, 159, 56, 0.8); }

.light-green.darken-3 {
  color: #558b2f !important; }

.bg-light-green.bg-darken-3 {
  background-color: #558b2f !important; }

.btn-light-green.btn-darken-3 {
  border-color: #689f38 !important;
  background-color: #558b2f !important; }
  .btn-light-green.btn-darken-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-3:focus, .btn-light-green.btn-darken-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-3 {
  border-color: #558b2f !important;
  color: #558b2f !important; }
  .btn-outline-light-green.btn-outline-darken-3:hover {
    background-color: #558b2f !important; }

.progress-light-green.progress-darken-3 {
  background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-webkit-progress-value {
    background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-moz-progress-bar {
    background-color: #558b2f; }
  .progress-light-green.progress-darken-3[value]::-ms-fill {
    background-color: #558b2f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-3 .progress-bar {
      background-color: #558b2f; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #558b2f !important; }

.border-light-green.border-darken-3 {
  border: 1px solid #558b2f !important; }

.border-top-light-green.border-top-darken-3 {
  border-top: 1px solid #558b2f !important; }

.border-bottom-light-green.border-bottom-darken-3 {
  border-bottom: 1px solid #558b2f !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-3 {
  border-left: 1px solid #558b2f !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-3 {
  border-right: 1px solid #558b2f !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-3 {
  border-right: 1px solid #558b2f !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-3 {
  border-left: 1px solid #558b2f !important; }

.overlay-light-green.overlay-darken-3 {
  background: #558b2f;
  /* The Fallback */
  background: rgba(85, 139, 47, 0.8); }

.light-green.darken-4 {
  color: #33691e !important; }

.bg-light-green.bg-darken-4 {
  background-color: #33691e !important; }

.btn-light-green.btn-darken-4 {
  border-color: #689f38 !important;
  background-color: #33691e !important; }
  .btn-light-green.btn-darken-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-darken-4:focus, .btn-light-green.btn-darken-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-darken-4 {
  border-color: #33691e !important;
  color: #33691e !important; }
  .btn-outline-light-green.btn-outline-darken-4:hover {
    background-color: #33691e !important; }

.progress-light-green.progress-darken-4 {
  background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-webkit-progress-value {
    background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-moz-progress-bar {
    background-color: #33691e; }
  .progress-light-green.progress-darken-4[value]::-ms-fill {
    background-color: #33691e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-darken-4 .progress-bar {
      background-color: #33691e; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #33691e !important; }

.border-light-green.border-darken-4 {
  border: 1px solid #33691e !important; }

.border-top-light-green.border-top-darken-4 {
  border-top: 1px solid #33691e !important; }

.border-bottom-light-green.border-bottom-darken-4 {
  border-bottom: 1px solid #33691e !important; }

[dir="ltr"] .border-left-light-green.border-left-darken-4 {
  border-left: 1px solid #33691e !important; }

[dir="ltr"] .border-right-light-green.border-right-darken-4 {
  border-right: 1px solid #33691e !important; }

[dir="rtl"] .border-left-light-green.border-left-darken-4 {
  border-right: 1px solid #33691e !important; }

[dir="rtl"] .border-right-light-green.border-right-darken-4 {
  border-left: 1px solid #33691e !important; }

.overlay-light-green.overlay-darken-4 {
  background: #33691e;
  /* The Fallback */
  background: rgba(51, 105, 30, 0.8); }

.light-green.accent-1 {
  color: #ccff90 !important; }

.bg-light-green.bg-accent-1 {
  background-color: #ccff90 !important; }

.btn-light-green.btn-accent-1 {
  border-color: #689f38 !important;
  background-color: #ccff90 !important; }
  .btn-light-green.btn-accent-1:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-1:focus, .btn-light-green.btn-accent-1:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-1 {
  border-color: #ccff90 !important;
  color: #ccff90 !important; }
  .btn-outline-light-green.btn-outline-accent-1:hover {
    background-color: #ccff90 !important; }

.progress-light-green.progress-accent-1 {
  background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ccff90; }
  .progress-light-green.progress-accent-1[value]::-ms-fill {
    background-color: #ccff90; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-1 .progress-bar {
      background-color: #ccff90; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ccff90 !important; }

.border-light-green.border-accent-1 {
  border: 1px solid #ccff90 !important; }

.border-top-light-green.border-top-accent-1 {
  border-top: 1px solid #ccff90 !important; }

.border-bottom-light-green.border-bottom-accent-1 {
  border-bottom: 1px solid #ccff90 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-1 {
  border-left: 1px solid #ccff90 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-1 {
  border-right: 1px solid #ccff90 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-1 {
  border-right: 1px solid #ccff90 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-1 {
  border-left: 1px solid #ccff90 !important; }

.overlay-light-green.overlay-accent-1 {
  background: #ccff90;
  /* The Fallback */
  background: rgba(204, 255, 144, 0.8); }

.light-green.accent-2 {
  color: #b2ff59 !important; }

.bg-light-green.bg-accent-2 {
  background-color: #b2ff59 !important; }

.btn-light-green.btn-accent-2 {
  border-color: #689f38 !important;
  background-color: #b2ff59 !important; }
  .btn-light-green.btn-accent-2:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-2:focus, .btn-light-green.btn-accent-2:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-2 {
  border-color: #b2ff59 !important;
  color: #b2ff59 !important; }
  .btn-outline-light-green.btn-outline-accent-2:hover {
    background-color: #b2ff59 !important; }

.progress-light-green.progress-accent-2 {
  background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b2ff59; }
  .progress-light-green.progress-accent-2[value]::-ms-fill {
    background-color: #b2ff59; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-2 .progress-bar {
      background-color: #b2ff59; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b2ff59 !important; }

.border-light-green.border-accent-2 {
  border: 1px solid #b2ff59 !important; }

.border-top-light-green.border-top-accent-2 {
  border-top: 1px solid #b2ff59 !important; }

.border-bottom-light-green.border-bottom-accent-2 {
  border-bottom: 1px solid #b2ff59 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-2 {
  border-left: 1px solid #b2ff59 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-2 {
  border-right: 1px solid #b2ff59 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-2 {
  border-right: 1px solid #b2ff59 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-2 {
  border-left: 1px solid #b2ff59 !important; }

.overlay-light-green.overlay-accent-2 {
  background: #b2ff59;
  /* The Fallback */
  background: rgba(178, 255, 89, 0.8); }

.light-green.accent-3 {
  color: #76ff03 !important; }

.bg-light-green.bg-accent-3 {
  background-color: #76ff03 !important; }

.btn-light-green.btn-accent-3 {
  border-color: #689f38 !important;
  background-color: #76ff03 !important; }
  .btn-light-green.btn-accent-3:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-3:focus, .btn-light-green.btn-accent-3:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-3 {
  border-color: #76ff03 !important;
  color: #76ff03 !important; }
  .btn-outline-light-green.btn-outline-accent-3:hover {
    background-color: #76ff03 !important; }

.progress-light-green.progress-accent-3 {
  background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-webkit-progress-value {
    background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-moz-progress-bar {
    background-color: #76ff03; }
  .progress-light-green.progress-accent-3[value]::-ms-fill {
    background-color: #76ff03; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-3 .progress-bar {
      background-color: #76ff03; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #76ff03 !important; }

.border-light-green.border-accent-3 {
  border: 1px solid #76ff03 !important; }

.border-top-light-green.border-top-accent-3 {
  border-top: 1px solid #76ff03 !important; }

.border-bottom-light-green.border-bottom-accent-3 {
  border-bottom: 1px solid #76ff03 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-3 {
  border-left: 1px solid #76ff03 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-3 {
  border-right: 1px solid #76ff03 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-3 {
  border-right: 1px solid #76ff03 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-3 {
  border-left: 1px solid #76ff03 !important; }

.overlay-light-green.overlay-accent-3 {
  background: #76ff03;
  /* The Fallback */
  background: rgba(118, 255, 3, 0.8); }

.light-green.accent-4 {
  color: #64dd17 !important; }

.bg-light-green.bg-accent-4 {
  background-color: #64dd17 !important; }

.btn-light-green.btn-accent-4 {
  border-color: #689f38 !important;
  background-color: #64dd17 !important; }
  .btn-light-green.btn-accent-4:hover {
    border-color: #689f38 !important;
    background-color: #558b2f !important; }
  .btn-light-green.btn-accent-4:focus, .btn-light-green.btn-accent-4:active {
    border-color: #558b2f !important;
    background-color: #33691e !important; }

.btn-outline-light-green.btn-outline-accent-4 {
  border-color: #64dd17 !important;
  color: #64dd17 !important; }
  .btn-outline-light-green.btn-outline-accent-4:hover {
    background-color: #64dd17 !important; }

.progress-light-green.progress-accent-4 {
  background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-webkit-progress-value {
    background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-moz-progress-bar {
    background-color: #64dd17; }
  .progress-light-green.progress-accent-4[value]::-ms-fill {
    background-color: #64dd17; }
  @media screen and (min-width: 0 \0 ) {
    .progress-light-green.progress-accent-4 .progress-bar {
      background-color: #64dd17; } }

input:focus ~ .bg-light-green {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #64dd17 !important; }

.border-light-green.border-accent-4 {
  border: 1px solid #64dd17 !important; }

.border-top-light-green.border-top-accent-4 {
  border-top: 1px solid #64dd17 !important; }

.border-bottom-light-green.border-bottom-accent-4 {
  border-bottom: 1px solid #64dd17 !important; }

[dir="ltr"] .border-left-light-green.border-left-accent-4 {
  border-left: 1px solid #64dd17 !important; }

[dir="ltr"] .border-right-light-green.border-right-accent-4 {
  border-right: 1px solid #64dd17 !important; }

[dir="rtl"] .border-left-light-green.border-left-accent-4 {
  border-right: 1px solid #64dd17 !important; }

[dir="rtl"] .border-right-light-green.border-right-accent-4 {
  border-left: 1px solid #64dd17 !important; }

.overlay-light-green.overlay-accent-4 {
  background: #64dd17;
  /* The Fallback */
  background: rgba(100, 221, 23, 0.8); }

.lime.lighten-5 {
  color: #f9fbe7 !important; }

.bg-lime.bg-lighten-5 {
  background-color: #f9fbe7 !important; }

.btn-lime.btn-lighten-5 {
  border-color: #afb42b !important;
  background-color: #f9fbe7 !important; }
  .btn-lime.btn-lighten-5:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-5:focus, .btn-lime.btn-lighten-5:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-5 {
  border-color: #f9fbe7 !important;
  color: #f9fbe7 !important; }
  .btn-outline-lime.btn-outline-lighten-5:hover {
    background-color: #f9fbe7 !important; }

.progress-lime.progress-lighten-5 {
  background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #f9fbe7; }
  .progress-lime.progress-lighten-5[value]::-ms-fill {
    background-color: #f9fbe7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-5 .progress-bar {
      background-color: #f9fbe7; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9fbe7 !important; }

.border-lime.border-lighten-5 {
  border: 1px solid #f9fbe7 !important; }

.border-top-lime.border-top-lighten-5 {
  border-top: 1px solid #f9fbe7 !important; }

.border-bottom-lime.border-bottom-lighten-5 {
  border-bottom: 1px solid #f9fbe7 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-5 {
  border-left: 1px solid #f9fbe7 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-5 {
  border-right: 1px solid #f9fbe7 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-5 {
  border-right: 1px solid #f9fbe7 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-5 {
  border-left: 1px solid #f9fbe7 !important; }

.overlay-lime.overlay-lighten-5 {
  background: #f9fbe7;
  /* The Fallback */
  background: rgba(249, 251, 231, 0.8); }

.lime.lighten-4 {
  color: #f0f4c3 !important; }

.bg-lime.bg-lighten-4 {
  background-color: #f0f4c3 !important; }

.btn-lime.btn-lighten-4 {
  border-color: #afb42b !important;
  background-color: #f0f4c3 !important; }
  .btn-lime.btn-lighten-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-4:focus, .btn-lime.btn-lighten-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-4 {
  border-color: #f0f4c3 !important;
  color: #f0f4c3 !important; }
  .btn-outline-lime.btn-outline-lighten-4:hover {
    background-color: #f0f4c3 !important; }

.progress-lime.progress-lighten-4 {
  background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f0f4c3; }
  .progress-lime.progress-lighten-4[value]::-ms-fill {
    background-color: #f0f4c3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-4 .progress-bar {
      background-color: #f0f4c3; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f0f4c3 !important; }

.border-lime.border-lighten-4 {
  border: 1px solid #f0f4c3 !important; }

.border-top-lime.border-top-lighten-4 {
  border-top: 1px solid #f0f4c3 !important; }

.border-bottom-lime.border-bottom-lighten-4 {
  border-bottom: 1px solid #f0f4c3 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-4 {
  border-left: 1px solid #f0f4c3 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-4 {
  border-right: 1px solid #f0f4c3 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-4 {
  border-right: 1px solid #f0f4c3 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-4 {
  border-left: 1px solid #f0f4c3 !important; }

.overlay-lime.overlay-lighten-4 {
  background: #f0f4c3;
  /* The Fallback */
  background: rgba(240, 244, 195, 0.8); }

.lime.lighten-3 {
  color: #e6ee9c !important; }

.bg-lime.bg-lighten-3 {
  background-color: #e6ee9c !important; }

.btn-lime.btn-lighten-3 {
  border-color: #afb42b !important;
  background-color: #e6ee9c !important; }
  .btn-lime.btn-lighten-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-3:focus, .btn-lime.btn-lighten-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-3 {
  border-color: #e6ee9c !important;
  color: #e6ee9c !important; }
  .btn-outline-lime.btn-outline-lighten-3:hover {
    background-color: #e6ee9c !important; }

.progress-lime.progress-lighten-3 {
  background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #e6ee9c; }
  .progress-lime.progress-lighten-3[value]::-ms-fill {
    background-color: #e6ee9c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-3 .progress-bar {
      background-color: #e6ee9c; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e6ee9c !important; }

.border-lime.border-lighten-3 {
  border: 1px solid #e6ee9c !important; }

.border-top-lime.border-top-lighten-3 {
  border-top: 1px solid #e6ee9c !important; }

.border-bottom-lime.border-bottom-lighten-3 {
  border-bottom: 1px solid #e6ee9c !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-3 {
  border-left: 1px solid #e6ee9c !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-3 {
  border-right: 1px solid #e6ee9c !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-3 {
  border-right: 1px solid #e6ee9c !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-3 {
  border-left: 1px solid #e6ee9c !important; }

.overlay-lime.overlay-lighten-3 {
  background: #e6ee9c;
  /* The Fallback */
  background: rgba(230, 238, 156, 0.8); }

.lime.lighten-2 {
  color: #dce775 !important; }

.bg-lime.bg-lighten-2 {
  background-color: #dce775 !important; }

.btn-lime.btn-lighten-2 {
  border-color: #afb42b !important;
  background-color: #dce775 !important; }
  .btn-lime.btn-lighten-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-2:focus, .btn-lime.btn-lighten-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-2 {
  border-color: #dce775 !important;
  color: #dce775 !important; }
  .btn-outline-lime.btn-outline-lighten-2:hover {
    background-color: #dce775 !important; }

.progress-lime.progress-lighten-2 {
  background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #dce775; }
  .progress-lime.progress-lighten-2[value]::-ms-fill {
    background-color: #dce775; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-2 .progress-bar {
      background-color: #dce775; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dce775 !important; }

.border-lime.border-lighten-2 {
  border: 1px solid #dce775 !important; }

.border-top-lime.border-top-lighten-2 {
  border-top: 1px solid #dce775 !important; }

.border-bottom-lime.border-bottom-lighten-2 {
  border-bottom: 1px solid #dce775 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-2 {
  border-left: 1px solid #dce775 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-2 {
  border-right: 1px solid #dce775 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-2 {
  border-right: 1px solid #dce775 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-2 {
  border-left: 1px solid #dce775 !important; }

.overlay-lime.overlay-lighten-2 {
  background: #dce775;
  /* The Fallback */
  background: rgba(220, 231, 117, 0.8); }

.lime.lighten-1 {
  color: #d4e157 !important; }

.bg-lime.bg-lighten-1 {
  background-color: #d4e157 !important; }

.btn-lime.btn-lighten-1 {
  border-color: #afb42b !important;
  background-color: #d4e157 !important; }
  .btn-lime.btn-lighten-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-lighten-1:focus, .btn-lime.btn-lighten-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-lighten-1 {
  border-color: #d4e157 !important;
  color: #d4e157 !important; }
  .btn-outline-lime.btn-outline-lighten-1:hover {
    background-color: #d4e157 !important; }

.progress-lime.progress-lighten-1 {
  background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #d4e157; }
  .progress-lime.progress-lighten-1[value]::-ms-fill {
    background-color: #d4e157; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-lighten-1 .progress-bar {
      background-color: #d4e157; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d4e157 !important; }

.border-lime.border-lighten-1 {
  border: 1px solid #d4e157 !important; }

.border-top-lime.border-top-lighten-1 {
  border-top: 1px solid #d4e157 !important; }

.border-bottom-lime.border-bottom-lighten-1 {
  border-bottom: 1px solid #d4e157 !important; }

[dir="ltr"] .border-left-lime.border-left-lighten-1 {
  border-left: 1px solid #d4e157 !important; }

[dir="ltr"] .border-right-lime.border-right-lighten-1 {
  border-right: 1px solid #d4e157 !important; }

[dir="rtl"] .border-left-lime.border-left-lighten-1 {
  border-right: 1px solid #d4e157 !important; }

[dir="rtl"] .border-right-lime.border-right-lighten-1 {
  border-left: 1px solid #d4e157 !important; }

.overlay-lime.overlay-lighten-1 {
  background: #d4e157;
  /* The Fallback */
  background: rgba(212, 225, 87, 0.8); }

.lime {
  color: #cddc39 !important; }

.bg-lime {
  background-color: #cddc39 !important; }
  .bg-lime .card-header, .bg-lime .card-footer {
    background-color: transparent; }

.toast-lime {
  background-color: #cddc39; }

.alert-lime {
  border-color: #cddc39 !important;
  background-color: #d5e157 !important;
  color: #4d540f !important; }
  .alert-lime .alert-link {
    color: #2d3109 !important; }

.border-lime {
  border-color: #cddc39; }

.border-top-lime {
  border-top-color: #cddc39; }

.border-bottom-lime {
  border-bottom-color: #cddc39; }

[dir="ltr"] .border-left-lime {
  border-left-color: #cddc39; }

[dir="ltr"] .border-right-lime {
  border-right-color: #cddc39; }

[dir="rtl"] .border-left-lime {
  border-right-color: #cddc39; }

[dir="rtl"] .border-right-lime {
  border-left-color: #cddc39; }

.badge-lime {
  background-color: #cddc39; }

.panel-lime {
  border-color: #cddc39; }
  .panel-lime .panel-heading {
    color: #FFF;
    border-color: #cddc39;
    background-color: #d2e04f; }

.bg-lime.tag-glow, .border-lime.tag-glow {
  box-shadow: 0px 0px 10px #cddc39; }

.overlay-lime {
  background: #cddc39;
  /* The Fallback */
  background: rgba(205, 220, 57, 0.8); }

.card.card-outline-lime {
  border-width: 1px;
  border-style: solid;
  border-color: #cddc39;
  background-color: transparent; }
  .card.card-outline-lime .card-header, .card.card-outline-lime .card-footer {
    background-color: transparent; }

.btn-lime.btn-flat {
  background-color: transparent !important;
  color: #cddc39;
  border: none; }

.btn-lime.btn-raised, .btn-lime.btn-fab {
  background-color: #cddc39 !important;
  color: #fff !important;
  border-color: #cddc39; }
  .btn-lime.btn-raised.active, .btn-lime.btn-fab.active {
    background-color: #becd24 !important;
    border-color: #becd24 !important; }

.btn-group-raised .btn-lime {
  background-color: #cddc39 !important;
  color: #fff !important; }

.btn-outline-lime {
  border: 1px solid;
  border-color: #cddc39;
  background-color: transparent;
  color: #cddc39;
  box-shadow: none !important; }
  .btn-outline-lime:focus {
    background-color: transparent !important;
    color: #cddc39 !important;
    box-shadow: transparent !important; }
  .btn-outline-lime.active {
    background-color: #cddc39 !important;
    color: #FFF !important; }
  .btn-outline-lime:hover {
    background-color: #cada2c !important;
    color: #FFF !important; }
    .btn-outline-lime:hover svg {
      color: #FFF !important; }

.btn-outline-lime.btn-raised, .btn-outline-lime.btn-fab {
  border: 1px solid;
  border-color: #cddc39;
  background-color: transparent;
  color: #cddc39;
  box-shadow: none !important; }
  .btn-outline-lime.btn-raised:focus, .btn-outline-lime.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-lime.btn-raised.active, .btn-outline-lime.btn-fab.active {
    background-color: #cddc39 !important;
    color: #FFF !important; }
  .btn-outline-lime.btn-raised:hover, .btn-outline-lime.btn-fab:hover {
    background-color: #cada2c !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-lime {
  background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-webkit-progress-value {
    background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-moz-progress-bar {
    background-color: #cddc39; }
  .progress .progress-bar.progress-bar-lime[value]::-ms-fill {
    background-color: #cddc39; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-lime .progress-bar {
      background-color: #cddc39; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cddc39 !important; }

.lime.darken-1 {
  color: #c0ca33 !important; }

.bg-lime.bg-darken-1 {
  background-color: #c0ca33 !important; }

.btn-lime.btn-darken-1 {
  border-color: #afb42b !important;
  background-color: #c0ca33 !important; }
  .btn-lime.btn-darken-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-1:focus, .btn-lime.btn-darken-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-1 {
  border-color: #c0ca33 !important;
  color: #c0ca33 !important; }
  .btn-outline-lime.btn-outline-darken-1:hover {
    background-color: #c0ca33 !important; }

.progress-lime.progress-darken-1 {
  background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-webkit-progress-value {
    background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-moz-progress-bar {
    background-color: #c0ca33; }
  .progress-lime.progress-darken-1[value]::-ms-fill {
    background-color: #c0ca33; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-1 .progress-bar {
      background-color: #c0ca33; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c0ca33 !important; }

.border-lime.border-darken-1 {
  border: 1px solid #c0ca33 !important; }

.border-top-lime.border-top-darken-1 {
  border-top: 1px solid #c0ca33 !important; }

.border-bottom-lime.border-bottom-darken-1 {
  border-bottom: 1px solid #c0ca33 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-1 {
  border-left: 1px solid #c0ca33 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-1 {
  border-right: 1px solid #c0ca33 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-1 {
  border-right: 1px solid #c0ca33 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-1 {
  border-left: 1px solid #c0ca33 !important; }

.overlay-lime.overlay-darken-1 {
  background: #c0ca33;
  /* The Fallback */
  background: rgba(192, 202, 51, 0.8); }

.lime.darken-2 {
  color: #afb42b !important; }

.bg-lime.bg-darken-2 {
  background-color: #afb42b !important; }

.btn-lime.btn-darken-2 {
  border-color: #afb42b !important;
  background-color: #afb42b !important; }
  .btn-lime.btn-darken-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-2:focus, .btn-lime.btn-darken-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-2 {
  border-color: #afb42b !important;
  color: #afb42b !important; }
  .btn-outline-lime.btn-outline-darken-2:hover {
    background-color: #afb42b !important; }

.progress-lime.progress-darken-2 {
  background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-webkit-progress-value {
    background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-moz-progress-bar {
    background-color: #afb42b; }
  .progress-lime.progress-darken-2[value]::-ms-fill {
    background-color: #afb42b; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-2 .progress-bar {
      background-color: #afb42b; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #afb42b !important; }

.border-lime.border-darken-2 {
  border: 1px solid #afb42b !important; }

.border-top-lime.border-top-darken-2 {
  border-top: 1px solid #afb42b !important; }

.border-bottom-lime.border-bottom-darken-2 {
  border-bottom: 1px solid #afb42b !important; }

[dir="ltr"] .border-left-lime.border-left-darken-2 {
  border-left: 1px solid #afb42b !important; }

[dir="ltr"] .border-right-lime.border-right-darken-2 {
  border-right: 1px solid #afb42b !important; }

[dir="rtl"] .border-left-lime.border-left-darken-2 {
  border-right: 1px solid #afb42b !important; }

[dir="rtl"] .border-right-lime.border-right-darken-2 {
  border-left: 1px solid #afb42b !important; }

.overlay-lime.overlay-darken-2 {
  background: #afb42b;
  /* The Fallback */
  background: rgba(175, 180, 43, 0.8); }

.lime.darken-3 {
  color: #9e9d24 !important; }

.bg-lime.bg-darken-3 {
  background-color: #9e9d24 !important; }

.btn-lime.btn-darken-3 {
  border-color: #afb42b !important;
  background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-3:focus, .btn-lime.btn-darken-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-3 {
  border-color: #9e9d24 !important;
  color: #9e9d24 !important; }
  .btn-outline-lime.btn-outline-darken-3:hover {
    background-color: #9e9d24 !important; }

.progress-lime.progress-darken-3 {
  background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-webkit-progress-value {
    background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-moz-progress-bar {
    background-color: #9e9d24; }
  .progress-lime.progress-darken-3[value]::-ms-fill {
    background-color: #9e9d24; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-3 .progress-bar {
      background-color: #9e9d24; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9d24 !important; }

.border-lime.border-darken-3 {
  border: 1px solid #9e9d24 !important; }

.border-top-lime.border-top-darken-3 {
  border-top: 1px solid #9e9d24 !important; }

.border-bottom-lime.border-bottom-darken-3 {
  border-bottom: 1px solid #9e9d24 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-3 {
  border-left: 1px solid #9e9d24 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-3 {
  border-right: 1px solid #9e9d24 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-3 {
  border-right: 1px solid #9e9d24 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-3 {
  border-left: 1px solid #9e9d24 !important; }

.overlay-lime.overlay-darken-3 {
  background: #9e9d24;
  /* The Fallback */
  background: rgba(158, 157, 36, 0.8); }

.lime.darken-4 {
  color: #827717 !important; }

.bg-lime.bg-darken-4 {
  background-color: #827717 !important; }

.btn-lime.btn-darken-4 {
  border-color: #afb42b !important;
  background-color: #827717 !important; }
  .btn-lime.btn-darken-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-darken-4:focus, .btn-lime.btn-darken-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-darken-4 {
  border-color: #827717 !important;
  color: #827717 !important; }
  .btn-outline-lime.btn-outline-darken-4:hover {
    background-color: #827717 !important; }

.progress-lime.progress-darken-4 {
  background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-webkit-progress-value {
    background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-moz-progress-bar {
    background-color: #827717; }
  .progress-lime.progress-darken-4[value]::-ms-fill {
    background-color: #827717; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-darken-4 .progress-bar {
      background-color: #827717; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #827717 !important; }

.border-lime.border-darken-4 {
  border: 1px solid #827717 !important; }

.border-top-lime.border-top-darken-4 {
  border-top: 1px solid #827717 !important; }

.border-bottom-lime.border-bottom-darken-4 {
  border-bottom: 1px solid #827717 !important; }

[dir="ltr"] .border-left-lime.border-left-darken-4 {
  border-left: 1px solid #827717 !important; }

[dir="ltr"] .border-right-lime.border-right-darken-4 {
  border-right: 1px solid #827717 !important; }

[dir="rtl"] .border-left-lime.border-left-darken-4 {
  border-right: 1px solid #827717 !important; }

[dir="rtl"] .border-right-lime.border-right-darken-4 {
  border-left: 1px solid #827717 !important; }

.overlay-lime.overlay-darken-4 {
  background: #827717;
  /* The Fallback */
  background: rgba(130, 119, 23, 0.8); }

.lime.accent-1 {
  color: #f4ff81 !important; }

.bg-lime.bg-accent-1 {
  background-color: #f4ff81 !important; }

.btn-lime.btn-accent-1 {
  border-color: #afb42b !important;
  background-color: #f4ff81 !important; }
  .btn-lime.btn-accent-1:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-1:focus, .btn-lime.btn-accent-1:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-1 {
  border-color: #f4ff81 !important;
  color: #f4ff81 !important; }
  .btn-outline-lime.btn-outline-accent-1:hover {
    background-color: #f4ff81 !important; }

.progress-lime.progress-accent-1 {
  background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f4ff81; }
  .progress-lime.progress-accent-1[value]::-ms-fill {
    background-color: #f4ff81; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-1 .progress-bar {
      background-color: #f4ff81; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4ff81 !important; }

.border-lime.border-accent-1 {
  border: 1px solid #f4ff81 !important; }

.border-top-lime.border-top-accent-1 {
  border-top: 1px solid #f4ff81 !important; }

.border-bottom-lime.border-bottom-accent-1 {
  border-bottom: 1px solid #f4ff81 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-1 {
  border-left: 1px solid #f4ff81 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-1 {
  border-right: 1px solid #f4ff81 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-1 {
  border-right: 1px solid #f4ff81 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-1 {
  border-left: 1px solid #f4ff81 !important; }

.overlay-lime.overlay-accent-1 {
  background: #f4ff81;
  /* The Fallback */
  background: rgba(244, 255, 129, 0.8); }

.lime.accent-2 {
  color: #eeff41 !important; }

.bg-lime.bg-accent-2 {
  background-color: #eeff41 !important; }

.btn-lime.btn-accent-2 {
  border-color: #afb42b !important;
  background-color: #eeff41 !important; }
  .btn-lime.btn-accent-2:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-2:focus, .btn-lime.btn-accent-2:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-2 {
  border-color: #eeff41 !important;
  color: #eeff41 !important; }
  .btn-outline-lime.btn-outline-accent-2:hover {
    background-color: #eeff41 !important; }

.progress-lime.progress-accent-2 {
  background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eeff41; }
  .progress-lime.progress-accent-2[value]::-ms-fill {
    background-color: #eeff41; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-2 .progress-bar {
      background-color: #eeff41; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eeff41 !important; }

.border-lime.border-accent-2 {
  border: 1px solid #eeff41 !important; }

.border-top-lime.border-top-accent-2 {
  border-top: 1px solid #eeff41 !important; }

.border-bottom-lime.border-bottom-accent-2 {
  border-bottom: 1px solid #eeff41 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-2 {
  border-left: 1px solid #eeff41 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-2 {
  border-right: 1px solid #eeff41 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-2 {
  border-right: 1px solid #eeff41 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-2 {
  border-left: 1px solid #eeff41 !important; }

.overlay-lime.overlay-accent-2 {
  background: #eeff41;
  /* The Fallback */
  background: rgba(238, 255, 65, 0.8); }

.lime.accent-3 {
  color: #c6ff00 !important; }

.bg-lime.bg-accent-3 {
  background-color: #c6ff00 !important; }

.btn-lime.btn-accent-3 {
  border-color: #afb42b !important;
  background-color: #c6ff00 !important; }
  .btn-lime.btn-accent-3:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-3:focus, .btn-lime.btn-accent-3:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-3 {
  border-color: #c6ff00 !important;
  color: #c6ff00 !important; }
  .btn-outline-lime.btn-outline-accent-3:hover {
    background-color: #c6ff00 !important; }

.progress-lime.progress-accent-3 {
  background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-webkit-progress-value {
    background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-moz-progress-bar {
    background-color: #c6ff00; }
  .progress-lime.progress-accent-3[value]::-ms-fill {
    background-color: #c6ff00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-3 .progress-bar {
      background-color: #c6ff00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #c6ff00 !important; }

.border-lime.border-accent-3 {
  border: 1px solid #c6ff00 !important; }

.border-top-lime.border-top-accent-3 {
  border-top: 1px solid #c6ff00 !important; }

.border-bottom-lime.border-bottom-accent-3 {
  border-bottom: 1px solid #c6ff00 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-3 {
  border-left: 1px solid #c6ff00 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-3 {
  border-right: 1px solid #c6ff00 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-3 {
  border-right: 1px solid #c6ff00 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-3 {
  border-left: 1px solid #c6ff00 !important; }

.overlay-lime.overlay-accent-3 {
  background: #c6ff00;
  /* The Fallback */
  background: rgba(198, 255, 0, 0.8); }

.lime.accent-4 {
  color: #aeea00 !important; }

.bg-lime.bg-accent-4 {
  background-color: #aeea00 !important; }

.btn-lime.btn-accent-4 {
  border-color: #afb42b !important;
  background-color: #aeea00 !important; }
  .btn-lime.btn-accent-4:hover {
    border-color: #afb42b !important;
    background-color: #9e9d24 !important; }
  .btn-lime.btn-accent-4:focus, .btn-lime.btn-accent-4:active {
    border-color: #9e9d24 !important;
    background-color: #827717 !important; }

.btn-outline-lime.btn-outline-accent-4 {
  border-color: #aeea00 !important;
  color: #aeea00 !important; }
  .btn-outline-lime.btn-outline-accent-4:hover {
    background-color: #aeea00 !important; }

.progress-lime.progress-accent-4 {
  background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-webkit-progress-value {
    background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-moz-progress-bar {
    background-color: #aeea00; }
  .progress-lime.progress-accent-4[value]::-ms-fill {
    background-color: #aeea00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-lime.progress-accent-4 .progress-bar {
      background-color: #aeea00; } }

input:focus ~ .bg-lime {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #aeea00 !important; }

.border-lime.border-accent-4 {
  border: 1px solid #aeea00 !important; }

.border-top-lime.border-top-accent-4 {
  border-top: 1px solid #aeea00 !important; }

.border-bottom-lime.border-bottom-accent-4 {
  border-bottom: 1px solid #aeea00 !important; }

[dir="ltr"] .border-left-lime.border-left-accent-4 {
  border-left: 1px solid #aeea00 !important; }

[dir="ltr"] .border-right-lime.border-right-accent-4 {
  border-right: 1px solid #aeea00 !important; }

[dir="rtl"] .border-left-lime.border-left-accent-4 {
  border-right: 1px solid #aeea00 !important; }

[dir="rtl"] .border-right-lime.border-right-accent-4 {
  border-left: 1px solid #aeea00 !important; }

.overlay-lime.overlay-accent-4 {
  background: #aeea00;
  /* The Fallback */
  background: rgba(174, 234, 0, 0.8); }

.yellow.lighten-5 {
  color: #fffde7 !important; }

.bg-yellow.bg-lighten-5 {
  background-color: #fffde7 !important; }

.btn-yellow.btn-lighten-5 {
  border-color: #fbc02d !important;
  background-color: #fffde7 !important; }
  .btn-yellow.btn-lighten-5:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-5:focus, .btn-yellow.btn-lighten-5:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-5 {
  border-color: #fffde7 !important;
  color: #fffde7 !important; }
  .btn-outline-yellow.btn-outline-lighten-5:hover {
    background-color: #fffde7 !important; }

.progress-yellow.progress-lighten-5 {
  background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fffde7; }
  .progress-yellow.progress-lighten-5[value]::-ms-fill {
    background-color: #fffde7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-5 .progress-bar {
      background-color: #fffde7; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fffde7 !important; }

.border-yellow.border-lighten-5 {
  border: 1px solid #fffde7 !important; }

.border-top-yellow.border-top-lighten-5 {
  border-top: 1px solid #fffde7 !important; }

.border-bottom-yellow.border-bottom-lighten-5 {
  border-bottom: 1px solid #fffde7 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-5 {
  border-left: 1px solid #fffde7 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-5 {
  border-right: 1px solid #fffde7 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-5 {
  border-right: 1px solid #fffde7 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-5 {
  border-left: 1px solid #fffde7 !important; }

.overlay-yellow.overlay-lighten-5 {
  background: #fffde7;
  /* The Fallback */
  background: rgba(255, 253, 231, 0.8); }

.yellow.lighten-4 {
  color: #fff9c4 !important; }

.bg-yellow.bg-lighten-4 {
  background-color: #fff9c4 !important; }

.btn-yellow.btn-lighten-4 {
  border-color: #fbc02d !important;
  background-color: #fff9c4 !important; }
  .btn-yellow.btn-lighten-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-4:focus, .btn-yellow.btn-lighten-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-4 {
  border-color: #fff9c4 !important;
  color: #fff9c4 !important; }
  .btn-outline-yellow.btn-outline-lighten-4:hover {
    background-color: #fff9c4 !important; }

.progress-yellow.progress-lighten-4 {
  background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #fff9c4; }
  .progress-yellow.progress-lighten-4[value]::-ms-fill {
    background-color: #fff9c4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-4 .progress-bar {
      background-color: #fff9c4; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff9c4 !important; }

.border-yellow.border-lighten-4 {
  border: 1px solid #fff9c4 !important; }

.border-top-yellow.border-top-lighten-4 {
  border-top: 1px solid #fff9c4 !important; }

.border-bottom-yellow.border-bottom-lighten-4 {
  border-bottom: 1px solid #fff9c4 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-4 {
  border-left: 1px solid #fff9c4 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-4 {
  border-right: 1px solid #fff9c4 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-4 {
  border-right: 1px solid #fff9c4 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-4 {
  border-left: 1px solid #fff9c4 !important; }

.overlay-yellow.overlay-lighten-4 {
  background: #fff9c4;
  /* The Fallback */
  background: rgba(255, 249, 196, 0.8); }

.yellow.lighten-3 {
  color: #fff59d !important; }

.bg-yellow.bg-lighten-3 {
  background-color: #fff59d !important; }

.btn-yellow.btn-lighten-3 {
  border-color: #fbc02d !important;
  background-color: #fff59d !important; }
  .btn-yellow.btn-lighten-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-3:focus, .btn-yellow.btn-lighten-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-3 {
  border-color: #fff59d !important;
  color: #fff59d !important; }
  .btn-outline-yellow.btn-outline-lighten-3:hover {
    background-color: #fff59d !important; }

.progress-yellow.progress-lighten-3 {
  background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #fff59d; }
  .progress-yellow.progress-lighten-3[value]::-ms-fill {
    background-color: #fff59d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-3 .progress-bar {
      background-color: #fff59d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff59d !important; }

.border-yellow.border-lighten-3 {
  border: 1px solid #fff59d !important; }

.border-top-yellow.border-top-lighten-3 {
  border-top: 1px solid #fff59d !important; }

.border-bottom-yellow.border-bottom-lighten-3 {
  border-bottom: 1px solid #fff59d !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-3 {
  border-left: 1px solid #fff59d !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-3 {
  border-right: 1px solid #fff59d !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-3 {
  border-right: 1px solid #fff59d !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-3 {
  border-left: 1px solid #fff59d !important; }

.overlay-yellow.overlay-lighten-3 {
  background: #fff59d;
  /* The Fallback */
  background: rgba(255, 245, 157, 0.8); }

.yellow.lighten-2 {
  color: #fff176 !important; }

.bg-yellow.bg-lighten-2 {
  background-color: #fff176 !important; }

.btn-yellow.btn-lighten-2 {
  border-color: #fbc02d !important;
  background-color: #fff176 !important; }
  .btn-yellow.btn-lighten-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-2:focus, .btn-yellow.btn-lighten-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-2 {
  border-color: #fff176 !important;
  color: #fff176 !important; }
  .btn-outline-yellow.btn-outline-lighten-2:hover {
    background-color: #fff176 !important; }

.progress-yellow.progress-lighten-2 {
  background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #fff176; }
  .progress-yellow.progress-lighten-2[value]::-ms-fill {
    background-color: #fff176; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-2 .progress-bar {
      background-color: #fff176; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff176 !important; }

.border-yellow.border-lighten-2 {
  border: 1px solid #fff176 !important; }

.border-top-yellow.border-top-lighten-2 {
  border-top: 1px solid #fff176 !important; }

.border-bottom-yellow.border-bottom-lighten-2 {
  border-bottom: 1px solid #fff176 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-2 {
  border-left: 1px solid #fff176 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-2 {
  border-right: 1px solid #fff176 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-2 {
  border-right: 1px solid #fff176 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-2 {
  border-left: 1px solid #fff176 !important; }

.overlay-yellow.overlay-lighten-2 {
  background: #fff176;
  /* The Fallback */
  background: rgba(255, 241, 118, 0.8); }

.yellow.lighten-1 {
  color: #ffee58 !important; }

.bg-yellow.bg-lighten-1 {
  background-color: #ffee58 !important; }

.btn-yellow.btn-lighten-1 {
  border-color: #fbc02d !important;
  background-color: #ffee58 !important; }
  .btn-yellow.btn-lighten-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-lighten-1:focus, .btn-yellow.btn-lighten-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-lighten-1 {
  border-color: #ffee58 !important;
  color: #ffee58 !important; }
  .btn-outline-yellow.btn-outline-lighten-1:hover {
    background-color: #ffee58 !important; }

.progress-yellow.progress-lighten-1 {
  background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffee58; }
  .progress-yellow.progress-lighten-1[value]::-ms-fill {
    background-color: #ffee58; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-lighten-1 .progress-bar {
      background-color: #ffee58; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffee58 !important; }

.border-yellow.border-lighten-1 {
  border: 1px solid #ffee58 !important; }

.border-top-yellow.border-top-lighten-1 {
  border-top: 1px solid #ffee58 !important; }

.border-bottom-yellow.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffee58 !important; }

[dir="ltr"] .border-left-yellow.border-left-lighten-1 {
  border-left: 1px solid #ffee58 !important; }

[dir="ltr"] .border-right-yellow.border-right-lighten-1 {
  border-right: 1px solid #ffee58 !important; }

[dir="rtl"] .border-left-yellow.border-left-lighten-1 {
  border-right: 1px solid #ffee58 !important; }

[dir="rtl"] .border-right-yellow.border-right-lighten-1 {
  border-left: 1px solid #ffee58 !important; }

.overlay-yellow.overlay-lighten-1 {
  background: #ffee58;
  /* The Fallback */
  background: rgba(255, 238, 88, 0.8); }

.yellow {
  color: #ffeb3b !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }
  .bg-yellow .card-header, .bg-yellow .card-footer {
    background-color: transparent; }

.toast-yellow {
  background-color: #ffeb3b; }

.alert-yellow {
  border-color: #ffeb3b !important;
  background-color: #ffef5f !important;
  color: #887a00 !important; }
  .alert-yellow .alert-link {
    color: #5f5500 !important; }

.border-yellow {
  border-color: #ffeb3b; }

.border-top-yellow {
  border-top-color: #ffeb3b; }

.border-bottom-yellow {
  border-bottom-color: #ffeb3b; }

[dir="ltr"] .border-left-yellow {
  border-left-color: #ffeb3b; }

[dir="ltr"] .border-right-yellow {
  border-right-color: #ffeb3b; }

[dir="rtl"] .border-left-yellow {
  border-right-color: #ffeb3b; }

[dir="rtl"] .border-right-yellow {
  border-left-color: #ffeb3b; }

.badge-yellow {
  background-color: #ffeb3b; }

.panel-yellow {
  border-color: #ffeb3b; }
  .panel-yellow .panel-heading {
    color: #FFF;
    border-color: #ffeb3b;
    background-color: #ffee55; }

.bg-yellow.tag-glow, .border-yellow.tag-glow {
  box-shadow: 0px 0px 10px #ffeb3b; }

.overlay-yellow {
  background: #ffeb3b;
  /* The Fallback */
  background: rgba(255, 235, 59, 0.8); }

.card.card-outline-yellow {
  border-width: 1px;
  border-style: solid;
  border-color: #ffeb3b;
  background-color: transparent; }
  .card.card-outline-yellow .card-header, .card.card-outline-yellow .card-footer {
    background-color: transparent; }

.btn-yellow.btn-flat {
  background-color: transparent !important;
  color: #ffeb3b;
  border: none; }

.btn-yellow.btn-raised, .btn-yellow.btn-fab {
  background-color: #ffeb3b !important;
  color: #fff !important;
  border-color: #ffeb3b; }
  .btn-yellow.btn-raised.active, .btn-yellow.btn-fab.active {
    background-color: #ffe717 !important;
    border-color: #ffe717 !important; }

.btn-group-raised .btn-yellow {
  background-color: #ffeb3b !important;
  color: #fff !important; }

.btn-outline-yellow {
  border: 1px solid;
  border-color: #ffeb3b;
  background-color: transparent;
  color: #ffeb3b;
  box-shadow: none !important; }
  .btn-outline-yellow:focus {
    background-color: transparent !important;
    color: #ffeb3b !important;
    box-shadow: transparent !important; }
  .btn-outline-yellow.active {
    background-color: #ffeb3b !important;
    color: #FFF !important; }
  .btn-outline-yellow:hover {
    background-color: #ffe92c !important;
    color: #FFF !important; }
    .btn-outline-yellow:hover svg {
      color: #FFF !important; }

.btn-outline-yellow.btn-raised, .btn-outline-yellow.btn-fab {
  border: 1px solid;
  border-color: #ffeb3b;
  background-color: transparent;
  color: #ffeb3b;
  box-shadow: none !important; }
  .btn-outline-yellow.btn-raised:focus, .btn-outline-yellow.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-yellow.btn-raised.active, .btn-outline-yellow.btn-fab.active {
    background-color: #ffeb3b !important;
    color: #FFF !important; }
  .btn-outline-yellow.btn-raised:hover, .btn-outline-yellow.btn-fab:hover {
    background-color: #ffe92c !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-yellow {
  background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-webkit-progress-value {
    background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-moz-progress-bar {
    background-color: #ffeb3b; }
  .progress .progress-bar.progress-bar-yellow[value]::-ms-fill {
    background-color: #ffeb3b; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-yellow .progress-bar {
      background-color: #ffeb3b; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffeb3b !important; }

.yellow.darken-1 {
  color: #fdd835 !important; }

.bg-yellow.bg-darken-1 {
  background-color: #fdd835 !important; }

.btn-yellow.btn-darken-1 {
  border-color: #fbc02d !important;
  background-color: #fdd835 !important; }
  .btn-yellow.btn-darken-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-1:focus, .btn-yellow.btn-darken-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-1 {
  border-color: #fdd835 !important;
  color: #fdd835 !important; }
  .btn-outline-yellow.btn-outline-darken-1:hover {
    background-color: #fdd835 !important; }

.progress-yellow.progress-darken-1 {
  background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fdd835; }
  .progress-yellow.progress-darken-1[value]::-ms-fill {
    background-color: #fdd835; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-1 .progress-bar {
      background-color: #fdd835; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fdd835 !important; }

.border-yellow.border-darken-1 {
  border: 1px solid #fdd835 !important; }

.border-top-yellow.border-top-darken-1 {
  border-top: 1px solid #fdd835 !important; }

.border-bottom-yellow.border-bottom-darken-1 {
  border-bottom: 1px solid #fdd835 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-1 {
  border-left: 1px solid #fdd835 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-1 {
  border-right: 1px solid #fdd835 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-1 {
  border-right: 1px solid #fdd835 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-1 {
  border-left: 1px solid #fdd835 !important; }

.overlay-yellow.overlay-darken-1 {
  background: #fdd835;
  /* The Fallback */
  background: rgba(253, 216, 53, 0.8); }

.yellow.darken-2 {
  color: #fbc02d !important; }

.bg-yellow.bg-darken-2 {
  background-color: #fbc02d !important; }

.btn-yellow.btn-darken-2 {
  border-color: #fbc02d !important;
  background-color: #fbc02d !important; }
  .btn-yellow.btn-darken-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-2:focus, .btn-yellow.btn-darken-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-2 {
  border-color: #fbc02d !important;
  color: #fbc02d !important; }
  .btn-outline-yellow.btn-outline-darken-2:hover {
    background-color: #fbc02d !important; }

.progress-yellow.progress-darken-2 {
  background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-webkit-progress-value {
    background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-moz-progress-bar {
    background-color: #fbc02d; }
  .progress-yellow.progress-darken-2[value]::-ms-fill {
    background-color: #fbc02d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-2 .progress-bar {
      background-color: #fbc02d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbc02d !important; }

.border-yellow.border-darken-2 {
  border: 1px solid #fbc02d !important; }

.border-top-yellow.border-top-darken-2 {
  border-top: 1px solid #fbc02d !important; }

.border-bottom-yellow.border-bottom-darken-2 {
  border-bottom: 1px solid #fbc02d !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-2 {
  border-left: 1px solid #fbc02d !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-2 {
  border-right: 1px solid #fbc02d !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-2 {
  border-right: 1px solid #fbc02d !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-2 {
  border-left: 1px solid #fbc02d !important; }

.overlay-yellow.overlay-darken-2 {
  background: #fbc02d;
  /* The Fallback */
  background: rgba(251, 192, 45, 0.8); }

.yellow.darken-3 {
  color: #f9a825 !important; }

.bg-yellow.bg-darken-3 {
  background-color: #f9a825 !important; }

.btn-yellow.btn-darken-3 {
  border-color: #fbc02d !important;
  background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-3:focus, .btn-yellow.btn-darken-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-3 {
  border-color: #f9a825 !important;
  color: #f9a825 !important; }
  .btn-outline-yellow.btn-outline-darken-3:hover {
    background-color: #f9a825 !important; }

.progress-yellow.progress-darken-3 {
  background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-webkit-progress-value {
    background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-moz-progress-bar {
    background-color: #f9a825; }
  .progress-yellow.progress-darken-3[value]::-ms-fill {
    background-color: #f9a825; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-3 .progress-bar {
      background-color: #f9a825; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f9a825 !important; }

.border-yellow.border-darken-3 {
  border: 1px solid #f9a825 !important; }

.border-top-yellow.border-top-darken-3 {
  border-top: 1px solid #f9a825 !important; }

.border-bottom-yellow.border-bottom-darken-3 {
  border-bottom: 1px solid #f9a825 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-3 {
  border-left: 1px solid #f9a825 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-3 {
  border-right: 1px solid #f9a825 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-3 {
  border-right: 1px solid #f9a825 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-3 {
  border-left: 1px solid #f9a825 !important; }

.overlay-yellow.overlay-darken-3 {
  background: #f9a825;
  /* The Fallback */
  background: rgba(249, 168, 37, 0.8); }

.yellow.darken-4 {
  color: #f57f17 !important; }

.bg-yellow.bg-darken-4 {
  background-color: #f57f17 !important; }

.btn-yellow.btn-darken-4 {
  border-color: #fbc02d !important;
  background-color: #f57f17 !important; }
  .btn-yellow.btn-darken-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-darken-4:focus, .btn-yellow.btn-darken-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-darken-4 {
  border-color: #f57f17 !important;
  color: #f57f17 !important; }
  .btn-outline-yellow.btn-outline-darken-4:hover {
    background-color: #f57f17 !important; }

.progress-yellow.progress-darken-4 {
  background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-webkit-progress-value {
    background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-moz-progress-bar {
    background-color: #f57f17; }
  .progress-yellow.progress-darken-4[value]::-ms-fill {
    background-color: #f57f17; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-darken-4 .progress-bar {
      background-color: #f57f17; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57f17 !important; }

.border-yellow.border-darken-4 {
  border: 1px solid #f57f17 !important; }

.border-top-yellow.border-top-darken-4 {
  border-top: 1px solid #f57f17 !important; }

.border-bottom-yellow.border-bottom-darken-4 {
  border-bottom: 1px solid #f57f17 !important; }

[dir="ltr"] .border-left-yellow.border-left-darken-4 {
  border-left: 1px solid #f57f17 !important; }

[dir="ltr"] .border-right-yellow.border-right-darken-4 {
  border-right: 1px solid #f57f17 !important; }

[dir="rtl"] .border-left-yellow.border-left-darken-4 {
  border-right: 1px solid #f57f17 !important; }

[dir="rtl"] .border-right-yellow.border-right-darken-4 {
  border-left: 1px solid #f57f17 !important; }

.overlay-yellow.overlay-darken-4 {
  background: #f57f17;
  /* The Fallback */
  background: rgba(245, 127, 23, 0.8); }

.yellow.accent-1 {
  color: #ffff8d !important; }

.bg-yellow.bg-accent-1 {
  background-color: #ffff8d !important; }

.btn-yellow.btn-accent-1 {
  border-color: #fbc02d !important;
  background-color: #ffff8d !important; }
  .btn-yellow.btn-accent-1:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-1:focus, .btn-yellow.btn-accent-1:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-1 {
  border-color: #ffff8d !important;
  color: #ffff8d !important; }
  .btn-outline-yellow.btn-outline-accent-1:hover {
    background-color: #ffff8d !important; }

.progress-yellow.progress-accent-1 {
  background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffff8d; }
  .progress-yellow.progress-accent-1[value]::-ms-fill {
    background-color: #ffff8d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-1 .progress-bar {
      background-color: #ffff8d; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffff8d !important; }

.border-yellow.border-accent-1 {
  border: 1px solid #ffff8d !important; }

.border-top-yellow.border-top-accent-1 {
  border-top: 1px solid #ffff8d !important; }

.border-bottom-yellow.border-bottom-accent-1 {
  border-bottom: 1px solid #ffff8d !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-1 {
  border-left: 1px solid #ffff8d !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-1 {
  border-right: 1px solid #ffff8d !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-1 {
  border-right: 1px solid #ffff8d !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-1 {
  border-left: 1px solid #ffff8d !important; }

.overlay-yellow.overlay-accent-1 {
  background: #ffff8d;
  /* The Fallback */
  background: rgba(255, 255, 141, 0.8); }

.yellow.accent-2 {
  color: #ff0 !important; }

.bg-yellow.bg-accent-2 {
  background-color: #ff0 !important; }

.btn-yellow.btn-accent-2 {
  border-color: #fbc02d !important;
  background-color: #ff0 !important; }
  .btn-yellow.btn-accent-2:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-2:focus, .btn-yellow.btn-accent-2:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-2 {
  border-color: #ff0 !important;
  color: #ff0 !important; }
  .btn-outline-yellow.btn-outline-accent-2:hover {
    background-color: #ff0 !important; }

.progress-yellow.progress-accent-2 {
  background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff0; }
  .progress-yellow.progress-accent-2[value]::-ms-fill {
    background-color: #ff0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-2 .progress-bar {
      background-color: #ff0; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff0 !important; }

.border-yellow.border-accent-2 {
  border: 1px solid #ff0 !important; }

.border-top-yellow.border-top-accent-2 {
  border-top: 1px solid #ff0 !important; }

.border-bottom-yellow.border-bottom-accent-2 {
  border-bottom: 1px solid #ff0 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-2 {
  border-left: 1px solid #ff0 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-2 {
  border-right: 1px solid #ff0 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-2 {
  border-right: 1px solid #ff0 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-2 {
  border-left: 1px solid #ff0 !important; }

.overlay-yellow.overlay-accent-2 {
  background: #ff0;
  /* The Fallback */
  background: rgba(255, 255, 0, 0.8); }

.yellow.accent-3 {
  color: #ffea00 !important; }

.bg-yellow.bg-accent-3 {
  background-color: #ffea00 !important; }

.btn-yellow.btn-accent-3 {
  border-color: #fbc02d !important;
  background-color: #ffea00 !important; }
  .btn-yellow.btn-accent-3:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-3:focus, .btn-yellow.btn-accent-3:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-3 {
  border-color: #ffea00 !important;
  color: #ffea00 !important; }
  .btn-outline-yellow.btn-outline-accent-3:hover {
    background-color: #ffea00 !important; }

.progress-yellow.progress-accent-3 {
  background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffea00; }
  .progress-yellow.progress-accent-3[value]::-ms-fill {
    background-color: #ffea00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-3 .progress-bar {
      background-color: #ffea00; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffea00 !important; }

.border-yellow.border-accent-3 {
  border: 1px solid #ffea00 !important; }

.border-top-yellow.border-top-accent-3 {
  border-top: 1px solid #ffea00 !important; }

.border-bottom-yellow.border-bottom-accent-3 {
  border-bottom: 1px solid #ffea00 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-3 {
  border-left: 1px solid #ffea00 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-3 {
  border-right: 1px solid #ffea00 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-3 {
  border-right: 1px solid #ffea00 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-3 {
  border-left: 1px solid #ffea00 !important; }

.overlay-yellow.overlay-accent-3 {
  background: #ffea00;
  /* The Fallback */
  background: rgba(255, 234, 0, 0.8); }

.yellow.accent-4 {
  color: #ffd600 !important; }

.bg-yellow.bg-accent-4 {
  background-color: #ffd600 !important; }

.btn-yellow.btn-accent-4 {
  border-color: #fbc02d !important;
  background-color: #ffd600 !important; }
  .btn-yellow.btn-accent-4:hover {
    border-color: #fbc02d !important;
    background-color: #f9a825 !important; }
  .btn-yellow.btn-accent-4:focus, .btn-yellow.btn-accent-4:active {
    border-color: #f9a825 !important;
    background-color: #f57f17 !important; }

.btn-outline-yellow.btn-outline-accent-4 {
  border-color: #ffd600 !important;
  color: #ffd600 !important; }
  .btn-outline-yellow.btn-outline-accent-4:hover {
    background-color: #ffd600 !important; }

.progress-yellow.progress-accent-4 {
  background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffd600; }
  .progress-yellow.progress-accent-4[value]::-ms-fill {
    background-color: #ffd600; }
  @media screen and (min-width: 0 \0 ) {
    .progress-yellow.progress-accent-4 .progress-bar {
      background-color: #ffd600; } }

input:focus ~ .bg-yellow {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd600 !important; }

.border-yellow.border-accent-4 {
  border: 1px solid #ffd600 !important; }

.border-top-yellow.border-top-accent-4 {
  border-top: 1px solid #ffd600 !important; }

.border-bottom-yellow.border-bottom-accent-4 {
  border-bottom: 1px solid #ffd600 !important; }

[dir="ltr"] .border-left-yellow.border-left-accent-4 {
  border-left: 1px solid #ffd600 !important; }

[dir="ltr"] .border-right-yellow.border-right-accent-4 {
  border-right: 1px solid #ffd600 !important; }

[dir="rtl"] .border-left-yellow.border-left-accent-4 {
  border-right: 1px solid #ffd600 !important; }

[dir="rtl"] .border-right-yellow.border-right-accent-4 {
  border-left: 1px solid #ffd600 !important; }

.overlay-yellow.overlay-accent-4 {
  background: #ffd600;
  /* The Fallback */
  background: rgba(255, 214, 0, 0.8); }

.amber.lighten-5 {
  color: #fff8e1 !important; }

.bg-amber.bg-lighten-5 {
  background-color: #fff8e1 !important; }

.btn-amber.btn-lighten-5 {
  border-color: #ffa000 !important;
  background-color: #fff8e1 !important; }
  .btn-amber.btn-lighten-5:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-5:focus, .btn-amber.btn-lighten-5:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-5 {
  border-color: #fff8e1 !important;
  color: #fff8e1 !important; }
  .btn-outline-amber.btn-outline-lighten-5:hover {
    background-color: #fff8e1 !important; }

.progress-amber.progress-lighten-5 {
  background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff8e1; }
  .progress-amber.progress-lighten-5[value]::-ms-fill {
    background-color: #fff8e1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-5 .progress-bar {
      background-color: #fff8e1; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff8e1 !important; }

.border-amber.border-lighten-5 {
  border: 1px solid #fff8e1 !important; }

.border-top-amber.border-top-lighten-5 {
  border-top: 1px solid #fff8e1 !important; }

.border-bottom-amber.border-bottom-lighten-5 {
  border-bottom: 1px solid #fff8e1 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-5 {
  border-left: 1px solid #fff8e1 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-5 {
  border-right: 1px solid #fff8e1 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-5 {
  border-right: 1px solid #fff8e1 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-5 {
  border-left: 1px solid #fff8e1 !important; }

.overlay-amber.overlay-lighten-5 {
  background: #fff8e1;
  /* The Fallback */
  background: rgba(255, 248, 225, 0.8); }

.amber.lighten-4 {
  color: #ffecb3 !important; }

.bg-amber.bg-lighten-4 {
  background-color: #ffecb3 !important; }

.btn-amber.btn-lighten-4 {
  border-color: #ffa000 !important;
  background-color: #ffecb3 !important; }
  .btn-amber.btn-lighten-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-4:focus, .btn-amber.btn-lighten-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-4 {
  border-color: #ffecb3 !important;
  color: #ffecb3 !important; }
  .btn-outline-amber.btn-outline-lighten-4:hover {
    background-color: #ffecb3 !important; }

.progress-amber.progress-lighten-4 {
  background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffecb3; }
  .progress-amber.progress-lighten-4[value]::-ms-fill {
    background-color: #ffecb3; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-4 .progress-bar {
      background-color: #ffecb3; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffecb3 !important; }

.border-amber.border-lighten-4 {
  border: 1px solid #ffecb3 !important; }

.border-top-amber.border-top-lighten-4 {
  border-top: 1px solid #ffecb3 !important; }

.border-bottom-amber.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffecb3 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-4 {
  border-left: 1px solid #ffecb3 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-4 {
  border-right: 1px solid #ffecb3 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-4 {
  border-right: 1px solid #ffecb3 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-4 {
  border-left: 1px solid #ffecb3 !important; }

.overlay-amber.overlay-lighten-4 {
  background: #ffecb3;
  /* The Fallback */
  background: rgba(255, 236, 179, 0.8); }

.amber.lighten-3 {
  color: #ffe082 !important; }

.bg-amber.bg-lighten-3 {
  background-color: #ffe082 !important; }

.btn-amber.btn-lighten-3 {
  border-color: #ffa000 !important;
  background-color: #ffe082 !important; }
  .btn-amber.btn-lighten-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-3:focus, .btn-amber.btn-lighten-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-3 {
  border-color: #ffe082 !important;
  color: #ffe082 !important; }
  .btn-outline-amber.btn-outline-lighten-3:hover {
    background-color: #ffe082 !important; }

.progress-amber.progress-lighten-3 {
  background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffe082; }
  .progress-amber.progress-lighten-3[value]::-ms-fill {
    background-color: #ffe082; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-3 .progress-bar {
      background-color: #ffe082; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe082 !important; }

.border-amber.border-lighten-3 {
  border: 1px solid #ffe082 !important; }

.border-top-amber.border-top-lighten-3 {
  border-top: 1px solid #ffe082 !important; }

.border-bottom-amber.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffe082 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-3 {
  border-left: 1px solid #ffe082 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-3 {
  border-right: 1px solid #ffe082 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-3 {
  border-right: 1px solid #ffe082 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-3 {
  border-left: 1px solid #ffe082 !important; }

.overlay-amber.overlay-lighten-3 {
  background: #ffe082;
  /* The Fallback */
  background: rgba(255, 224, 130, 0.8); }

.amber.lighten-2 {
  color: #ffd54f !important; }

.bg-amber.bg-lighten-2 {
  background-color: #ffd54f !important; }

.btn-amber.btn-lighten-2 {
  border-color: #ffa000 !important;
  background-color: #ffd54f !important; }
  .btn-amber.btn-lighten-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-2:focus, .btn-amber.btn-lighten-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-2 {
  border-color: #ffd54f !important;
  color: #ffd54f !important; }
  .btn-outline-amber.btn-outline-lighten-2:hover {
    background-color: #ffd54f !important; }

.progress-amber.progress-lighten-2 {
  background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffd54f; }
  .progress-amber.progress-lighten-2[value]::-ms-fill {
    background-color: #ffd54f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-2 .progress-bar {
      background-color: #ffd54f; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd54f !important; }

.border-amber.border-lighten-2 {
  border: 1px solid #ffd54f !important; }

.border-top-amber.border-top-lighten-2 {
  border-top: 1px solid #ffd54f !important; }

.border-bottom-amber.border-bottom-lighten-2 {
  border-bottom: 1px solid #ffd54f !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-2 {
  border-left: 1px solid #ffd54f !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-2 {
  border-right: 1px solid #ffd54f !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-2 {
  border-right: 1px solid #ffd54f !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-2 {
  border-left: 1px solid #ffd54f !important; }

.overlay-amber.overlay-lighten-2 {
  background: #ffd54f;
  /* The Fallback */
  background: rgba(255, 213, 79, 0.8); }

.amber.lighten-1 {
  color: #ffca28 !important; }

.bg-amber.bg-lighten-1 {
  background-color: #ffca28 !important; }

.btn-amber.btn-lighten-1 {
  border-color: #ffa000 !important;
  background-color: #ffca28 !important; }
  .btn-amber.btn-lighten-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-lighten-1:focus, .btn-amber.btn-lighten-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-lighten-1 {
  border-color: #ffca28 !important;
  color: #ffca28 !important; }
  .btn-outline-amber.btn-outline-lighten-1:hover {
    background-color: #ffca28 !important; }

.progress-amber.progress-lighten-1 {
  background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffca28; }
  .progress-amber.progress-lighten-1[value]::-ms-fill {
    background-color: #ffca28; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-lighten-1 .progress-bar {
      background-color: #ffca28; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffca28 !important; }

.border-amber.border-lighten-1 {
  border: 1px solid #ffca28 !important; }

.border-top-amber.border-top-lighten-1 {
  border-top: 1px solid #ffca28 !important; }

.border-bottom-amber.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffca28 !important; }

[dir="ltr"] .border-left-amber.border-left-lighten-1 {
  border-left: 1px solid #ffca28 !important; }

[dir="ltr"] .border-right-amber.border-right-lighten-1 {
  border-right: 1px solid #ffca28 !important; }

[dir="rtl"] .border-left-amber.border-left-lighten-1 {
  border-right: 1px solid #ffca28 !important; }

[dir="rtl"] .border-right-amber.border-right-lighten-1 {
  border-left: 1px solid #ffca28 !important; }

.overlay-amber.overlay-lighten-1 {
  background: #ffca28;
  /* The Fallback */
  background: rgba(255, 202, 40, 0.8); }

.amber {
  color: #ffc107 !important; }

.bg-amber {
  background-color: #ffc107 !important; }
  .bg-amber .card-header, .bg-amber .card-footer {
    background-color: transparent; }

.toast-amber {
  background-color: #ffc107; }

.alert-amber {
  border-color: #ffc107 !important;
  background-color: #ffca2b !important;
  color: #543f00 !important; }
  .alert-amber .alert-link {
    color: #2b2000 !important; }

.border-amber {
  border-color: #ffc107; }

.border-top-amber {
  border-top-color: #ffc107; }

.border-bottom-amber {
  border-bottom-color: #ffc107; }

[dir="ltr"] .border-left-amber {
  border-left-color: #ffc107; }

[dir="ltr"] .border-right-amber {
  border-right-color: #ffc107; }

[dir="rtl"] .border-left-amber {
  border-right-color: #ffc107; }

[dir="rtl"] .border-right-amber {
  border-left-color: #ffc107; }

.badge-amber {
  background-color: #ffc107; }

.panel-amber {
  border-color: #ffc107; }
  .panel-amber .panel-heading {
    color: #FFF;
    border-color: #ffc107;
    background-color: #ffc721; }

.bg-amber.tag-glow, .border-amber.tag-glow {
  box-shadow: 0px 0px 10px #ffc107; }

.overlay-amber {
  background: #ffc107;
  /* The Fallback */
  background: rgba(255, 193, 7, 0.8); }

.card.card-outline-amber {
  border-width: 1px;
  border-style: solid;
  border-color: #ffc107;
  background-color: transparent; }
  .card.card-outline-amber .card-header, .card.card-outline-amber .card-footer {
    background-color: transparent; }

.btn-amber.btn-flat {
  background-color: transparent !important;
  color: #ffc107;
  border: none; }

.btn-amber.btn-raised, .btn-amber.btn-fab {
  background-color: #ffc107 !important;
  color: #fff !important;
  border-color: #ffc107; }
  .btn-amber.btn-raised.active, .btn-amber.btn-fab.active {
    background-color: #e2aa00 !important;
    border-color: #e2aa00 !important; }

.btn-group-raised .btn-amber {
  background-color: #ffc107 !important;
  color: #fff !important; }

.btn-outline-amber {
  border: 1px solid;
  border-color: #ffc107;
  background-color: transparent;
  color: #ffc107;
  box-shadow: none !important; }
  .btn-outline-amber:focus {
    background-color: transparent !important;
    color: #ffc107 !important;
    box-shadow: transparent !important; }
  .btn-outline-amber.active {
    background-color: #ffc107 !important;
    color: #FFF !important; }
  .btn-outline-amber:hover {
    background-color: #f7b900 !important;
    color: #FFF !important; }
    .btn-outline-amber:hover svg {
      color: #FFF !important; }

.btn-outline-amber.btn-raised, .btn-outline-amber.btn-fab {
  border: 1px solid;
  border-color: #ffc107;
  background-color: transparent;
  color: #ffc107;
  box-shadow: none !important; }
  .btn-outline-amber.btn-raised:focus, .btn-outline-amber.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-amber.btn-raised.active, .btn-outline-amber.btn-fab.active {
    background-color: #ffc107 !important;
    color: #FFF !important; }
  .btn-outline-amber.btn-raised:hover, .btn-outline-amber.btn-fab:hover {
    background-color: #f7b900 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-amber {
  background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-webkit-progress-value {
    background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-moz-progress-bar {
    background-color: #ffc107; }
  .progress .progress-bar.progress-bar-amber[value]::-ms-fill {
    background-color: #ffc107; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-amber .progress-bar {
      background-color: #ffc107; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc107 !important; }

.amber.darken-1 {
  color: #ffb300 !important; }

.bg-amber.bg-darken-1 {
  background-color: #ffb300 !important; }

.btn-amber.btn-darken-1 {
  border-color: #ffa000 !important;
  background-color: #ffb300 !important; }
  .btn-amber.btn-darken-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-1:focus, .btn-amber.btn-darken-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-1 {
  border-color: #ffb300 !important;
  color: #ffb300 !important; }
  .btn-outline-amber.btn-outline-darken-1:hover {
    background-color: #ffb300 !important; }

.progress-amber.progress-darken-1 {
  background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-webkit-progress-value {
    background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-moz-progress-bar {
    background-color: #ffb300; }
  .progress-amber.progress-darken-1[value]::-ms-fill {
    background-color: #ffb300; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-1 .progress-bar {
      background-color: #ffb300; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb300 !important; }

.border-amber.border-darken-1 {
  border: 1px solid #ffb300 !important; }

.border-top-amber.border-top-darken-1 {
  border-top: 1px solid #ffb300 !important; }

.border-bottom-amber.border-bottom-darken-1 {
  border-bottom: 1px solid #ffb300 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-1 {
  border-left: 1px solid #ffb300 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-1 {
  border-right: 1px solid #ffb300 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-1 {
  border-right: 1px solid #ffb300 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-1 {
  border-left: 1px solid #ffb300 !important; }

.overlay-amber.overlay-darken-1 {
  background: #ffb300;
  /* The Fallback */
  background: rgba(255, 179, 0, 0.8); }

.amber.darken-2 {
  color: #ffa000 !important; }

.bg-amber.bg-darken-2 {
  background-color: #ffa000 !important; }

.btn-amber.btn-darken-2 {
  border-color: #ffa000 !important;
  background-color: #ffa000 !important; }
  .btn-amber.btn-darken-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-2:focus, .btn-amber.btn-darken-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-2 {
  border-color: #ffa000 !important;
  color: #ffa000 !important; }
  .btn-outline-amber.btn-outline-darken-2:hover {
    background-color: #ffa000 !important; }

.progress-amber.progress-darken-2 {
  background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-webkit-progress-value {
    background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-moz-progress-bar {
    background-color: #ffa000; }
  .progress-amber.progress-darken-2[value]::-ms-fill {
    background-color: #ffa000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-2 .progress-bar {
      background-color: #ffa000; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa000 !important; }

.border-amber.border-darken-2 {
  border: 1px solid #ffa000 !important; }

.border-top-amber.border-top-darken-2 {
  border-top: 1px solid #ffa000 !important; }

.border-bottom-amber.border-bottom-darken-2 {
  border-bottom: 1px solid #ffa000 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-2 {
  border-left: 1px solid #ffa000 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-2 {
  border-right: 1px solid #ffa000 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-2 {
  border-right: 1px solid #ffa000 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-2 {
  border-left: 1px solid #ffa000 !important; }

.overlay-amber.overlay-darken-2 {
  background: #ffa000;
  /* The Fallback */
  background: rgba(255, 160, 0, 0.8); }

.amber.darken-3 {
  color: #ff8f00 !important; }

.bg-amber.bg-darken-3 {
  background-color: #ff8f00 !important; }

.btn-amber.btn-darken-3 {
  border-color: #ffa000 !important;
  background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-3:focus, .btn-amber.btn-darken-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-3 {
  border-color: #ff8f00 !important;
  color: #ff8f00 !important; }
  .btn-outline-amber.btn-outline-darken-3:hover {
    background-color: #ff8f00 !important; }

.progress-amber.progress-darken-3 {
  background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ff8f00; }
  .progress-amber.progress-darken-3[value]::-ms-fill {
    background-color: #ff8f00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-3 .progress-bar {
      background-color: #ff8f00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8f00 !important; }

.border-amber.border-darken-3 {
  border: 1px solid #ff8f00 !important; }

.border-top-amber.border-top-darken-3 {
  border-top: 1px solid #ff8f00 !important; }

.border-bottom-amber.border-bottom-darken-3 {
  border-bottom: 1px solid #ff8f00 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-3 {
  border-left: 1px solid #ff8f00 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-3 {
  border-right: 1px solid #ff8f00 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-3 {
  border-right: 1px solid #ff8f00 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-3 {
  border-left: 1px solid #ff8f00 !important; }

.overlay-amber.overlay-darken-3 {
  background: #ff8f00;
  /* The Fallback */
  background: rgba(255, 143, 0, 0.8); }

.amber.darken-4 {
  color: #ff6f00 !important; }

.bg-amber.bg-darken-4 {
  background-color: #ff6f00 !important; }

.btn-amber.btn-darken-4 {
  border-color: #ffa000 !important;
  background-color: #ff6f00 !important; }
  .btn-amber.btn-darken-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-darken-4:focus, .btn-amber.btn-darken-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-darken-4 {
  border-color: #ff6f00 !important;
  color: #ff6f00 !important; }
  .btn-outline-amber.btn-outline-darken-4:hover {
    background-color: #ff6f00 !important; }

.progress-amber.progress-darken-4 {
  background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-webkit-progress-value {
    background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-moz-progress-bar {
    background-color: #ff6f00; }
  .progress-amber.progress-darken-4[value]::-ms-fill {
    background-color: #ff6f00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-darken-4 .progress-bar {
      background-color: #ff6f00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6f00 !important; }

.border-amber.border-darken-4 {
  border: 1px solid #ff6f00 !important; }

.border-top-amber.border-top-darken-4 {
  border-top: 1px solid #ff6f00 !important; }

.border-bottom-amber.border-bottom-darken-4 {
  border-bottom: 1px solid #ff6f00 !important; }

[dir="ltr"] .border-left-amber.border-left-darken-4 {
  border-left: 1px solid #ff6f00 !important; }

[dir="ltr"] .border-right-amber.border-right-darken-4 {
  border-right: 1px solid #ff6f00 !important; }

[dir="rtl"] .border-left-amber.border-left-darken-4 {
  border-right: 1px solid #ff6f00 !important; }

[dir="rtl"] .border-right-amber.border-right-darken-4 {
  border-left: 1px solid #ff6f00 !important; }

.overlay-amber.overlay-darken-4 {
  background: #ff6f00;
  /* The Fallback */
  background: rgba(255, 111, 0, 0.8); }

.amber.accent-1 {
  color: #ffe57f !important; }

.bg-amber.bg-accent-1 {
  background-color: #ffe57f !important; }

.btn-amber.btn-accent-1 {
  border-color: #ffa000 !important;
  background-color: #ffe57f !important; }
  .btn-amber.btn-accent-1:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-1:focus, .btn-amber.btn-accent-1:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-1 {
  border-color: #ffe57f !important;
  color: #ffe57f !important; }
  .btn-outline-amber.btn-outline-accent-1:hover {
    background-color: #ffe57f !important; }

.progress-amber.progress-accent-1 {
  background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffe57f; }
  .progress-amber.progress-accent-1[value]::-ms-fill {
    background-color: #ffe57f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-1 .progress-bar {
      background-color: #ffe57f; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe57f !important; }

.border-amber.border-accent-1 {
  border: 1px solid #ffe57f !important; }

.border-top-amber.border-top-accent-1 {
  border-top: 1px solid #ffe57f !important; }

.border-bottom-amber.border-bottom-accent-1 {
  border-bottom: 1px solid #ffe57f !important; }

[dir="ltr"] .border-left-amber.border-left-accent-1 {
  border-left: 1px solid #ffe57f !important; }

[dir="ltr"] .border-right-amber.border-right-accent-1 {
  border-right: 1px solid #ffe57f !important; }

[dir="rtl"] .border-left-amber.border-left-accent-1 {
  border-right: 1px solid #ffe57f !important; }

[dir="rtl"] .border-right-amber.border-right-accent-1 {
  border-left: 1px solid #ffe57f !important; }

.overlay-amber.overlay-accent-1 {
  background: #ffe57f;
  /* The Fallback */
  background: rgba(255, 229, 127, 0.8); }

.amber.accent-2 {
  color: #ffd740 !important; }

.bg-amber.bg-accent-2 {
  background-color: #ffd740 !important; }

.btn-amber.btn-accent-2 {
  border-color: #ffa000 !important;
  background-color: #ffd740 !important; }
  .btn-amber.btn-accent-2:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-2:focus, .btn-amber.btn-accent-2:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-2 {
  border-color: #ffd740 !important;
  color: #ffd740 !important; }
  .btn-outline-amber.btn-outline-accent-2:hover {
    background-color: #ffd740 !important; }

.progress-amber.progress-accent-2 {
  background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffd740; }
  .progress-amber.progress-accent-2[value]::-ms-fill {
    background-color: #ffd740; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-2 .progress-bar {
      background-color: #ffd740; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd740 !important; }

.border-amber.border-accent-2 {
  border: 1px solid #ffd740 !important; }

.border-top-amber.border-top-accent-2 {
  border-top: 1px solid #ffd740 !important; }

.border-bottom-amber.border-bottom-accent-2 {
  border-bottom: 1px solid #ffd740 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-2 {
  border-left: 1px solid #ffd740 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-2 {
  border-right: 1px solid #ffd740 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-2 {
  border-right: 1px solid #ffd740 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-2 {
  border-left: 1px solid #ffd740 !important; }

.overlay-amber.overlay-accent-2 {
  background: #ffd740;
  /* The Fallback */
  background: rgba(255, 215, 64, 0.8); }

.amber.accent-3 {
  color: #ffc400 !important; }

.bg-amber.bg-accent-3 {
  background-color: #ffc400 !important; }

.btn-amber.btn-accent-3 {
  border-color: #ffa000 !important;
  background-color: #ffc400 !important; }
  .btn-amber.btn-accent-3:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-3:focus, .btn-amber.btn-accent-3:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-3 {
  border-color: #ffc400 !important;
  color: #ffc400 !important; }
  .btn-outline-amber.btn-outline-accent-3:hover {
    background-color: #ffc400 !important; }

.progress-amber.progress-accent-3 {
  background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ffc400; }
  .progress-amber.progress-accent-3[value]::-ms-fill {
    background-color: #ffc400; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-3 .progress-bar {
      background-color: #ffc400; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffc400 !important; }

.border-amber.border-accent-3 {
  border: 1px solid #ffc400 !important; }

.border-top-amber.border-top-accent-3 {
  border-top: 1px solid #ffc400 !important; }

.border-bottom-amber.border-bottom-accent-3 {
  border-bottom: 1px solid #ffc400 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-3 {
  border-left: 1px solid #ffc400 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-3 {
  border-right: 1px solid #ffc400 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-3 {
  border-right: 1px solid #ffc400 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-3 {
  border-left: 1px solid #ffc400 !important; }

.overlay-amber.overlay-accent-3 {
  background: #ffc400;
  /* The Fallback */
  background: rgba(255, 196, 0, 0.8); }

.amber.accent-4 {
  color: #ffab00 !important; }

.bg-amber.bg-accent-4 {
  background-color: #ffab00 !important; }

.btn-amber.btn-accent-4 {
  border-color: #ffa000 !important;
  background-color: #ffab00 !important; }
  .btn-amber.btn-accent-4:hover {
    border-color: #ffa000 !important;
    background-color: #ff8f00 !important; }
  .btn-amber.btn-accent-4:focus, .btn-amber.btn-accent-4:active {
    border-color: #ff8f00 !important;
    background-color: #ff6f00 !important; }

.btn-outline-amber.btn-outline-accent-4 {
  border-color: #ffab00 !important;
  color: #ffab00 !important; }
  .btn-outline-amber.btn-outline-accent-4:hover {
    background-color: #ffab00 !important; }

.progress-amber.progress-accent-4 {
  background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ffab00; }
  .progress-amber.progress-accent-4[value]::-ms-fill {
    background-color: #ffab00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-amber.progress-accent-4 .progress-bar {
      background-color: #ffab00; } }

input:focus ~ .bg-amber {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab00 !important; }

.border-amber.border-accent-4 {
  border: 1px solid #ffab00 !important; }

.border-top-amber.border-top-accent-4 {
  border-top: 1px solid #ffab00 !important; }

.border-bottom-amber.border-bottom-accent-4 {
  border-bottom: 1px solid #ffab00 !important; }

[dir="ltr"] .border-left-amber.border-left-accent-4 {
  border-left: 1px solid #ffab00 !important; }

[dir="ltr"] .border-right-amber.border-right-accent-4 {
  border-right: 1px solid #ffab00 !important; }

[dir="rtl"] .border-left-amber.border-left-accent-4 {
  border-right: 1px solid #ffab00 !important; }

[dir="rtl"] .border-right-amber.border-right-accent-4 {
  border-left: 1px solid #ffab00 !important; }

.overlay-amber.overlay-accent-4 {
  background: #ffab00;
  /* The Fallback */
  background: rgba(255, 171, 0, 0.8); }

.orange.lighten-5 {
  color: #fff3e0 !important; }

.bg-orange.bg-lighten-5 {
  background-color: #fff3e0 !important; }

.btn-orange.btn-lighten-5 {
  border-color: #f57c00 !important;
  background-color: #fff3e0 !important; }
  .btn-orange.btn-lighten-5:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-5:focus, .btn-orange.btn-lighten-5:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-5 {
  border-color: #fff3e0 !important;
  color: #fff3e0 !important; }
  .btn-outline-orange.btn-outline-lighten-5:hover {
    background-color: #fff3e0 !important; }

.progress-orange.progress-lighten-5 {
  background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fff3e0; }
  .progress-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fff3e0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-5 .progress-bar {
      background-color: #fff3e0; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fff3e0 !important; }

.border-orange.border-lighten-5 {
  border: 1px solid #fff3e0 !important; }

.border-top-orange.border-top-lighten-5 {
  border-top: 1px solid #fff3e0 !important; }

.border-bottom-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #fff3e0 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-5 {
  border-left: 1px solid #fff3e0 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-5 {
  border-right: 1px solid #fff3e0 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-5 {
  border-right: 1px solid #fff3e0 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-5 {
  border-left: 1px solid #fff3e0 !important; }

.overlay-orange.overlay-lighten-5 {
  background: #fff3e0;
  /* The Fallback */
  background: rgba(255, 243, 224, 0.8); }

.orange.lighten-4 {
  color: #ffe0b2 !important; }

.bg-orange.bg-lighten-4 {
  background-color: #ffe0b2 !important; }

.btn-orange.btn-lighten-4 {
  border-color: #f57c00 !important;
  background-color: #ffe0b2 !important; }
  .btn-orange.btn-lighten-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-4:focus, .btn-orange.btn-lighten-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-4 {
  border-color: #ffe0b2 !important;
  color: #ffe0b2 !important; }
  .btn-outline-orange.btn-outline-lighten-4:hover {
    background-color: #ffe0b2 !important; }

.progress-orange.progress-lighten-4 {
  background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffe0b2; }
  .progress-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffe0b2; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-4 .progress-bar {
      background-color: #ffe0b2; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffe0b2 !important; }

.border-orange.border-lighten-4 {
  border: 1px solid #ffe0b2 !important; }

.border-top-orange.border-top-lighten-4 {
  border-top: 1px solid #ffe0b2 !important; }

.border-bottom-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffe0b2 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-4 {
  border-left: 1px solid #ffe0b2 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-4 {
  border-right: 1px solid #ffe0b2 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-4 {
  border-right: 1px solid #ffe0b2 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-4 {
  border-left: 1px solid #ffe0b2 !important; }

.overlay-orange.overlay-lighten-4 {
  background: #ffe0b2;
  /* The Fallback */
  background: rgba(255, 224, 178, 0.8); }

.orange.lighten-3 {
  color: #ffcc80 !important; }

.bg-orange.bg-lighten-3 {
  background-color: #ffcc80 !important; }

.btn-orange.btn-lighten-3 {
  border-color: #f57c00 !important;
  background-color: #ffcc80 !important; }
  .btn-orange.btn-lighten-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-3:focus, .btn-orange.btn-lighten-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-3 {
  border-color: #ffcc80 !important;
  color: #ffcc80 !important; }
  .btn-outline-orange.btn-outline-lighten-3:hover {
    background-color: #ffcc80 !important; }

.progress-orange.progress-lighten-3 {
  background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffcc80; }
  .progress-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffcc80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-3 .progress-bar {
      background-color: #ffcc80; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffcc80 !important; }

.border-orange.border-lighten-3 {
  border: 1px solid #ffcc80 !important; }

.border-top-orange.border-top-lighten-3 {
  border-top: 1px solid #ffcc80 !important; }

.border-bottom-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffcc80 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-3 {
  border-left: 1px solid #ffcc80 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-3 {
  border-right: 1px solid #ffcc80 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-3 {
  border-right: 1px solid #ffcc80 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-3 {
  border-left: 1px solid #ffcc80 !important; }

.overlay-orange.overlay-lighten-3 {
  background: #ffcc80;
  /* The Fallback */
  background: rgba(255, 204, 128, 0.8); }

.orange.lighten-2 {
  color: #ffb74d !important; }

.bg-orange.bg-lighten-2 {
  background-color: #ffb74d !important; }

.btn-orange.btn-lighten-2 {
  border-color: #f57c00 !important;
  background-color: #ffb74d !important; }
  .btn-orange.btn-lighten-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-2:focus, .btn-orange.btn-lighten-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-2 {
  border-color: #ffb74d !important;
  color: #ffb74d !important; }
  .btn-outline-orange.btn-outline-lighten-2:hover {
    background-color: #ffb74d !important; }

.progress-orange.progress-lighten-2 {
  background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ffb74d; }
  .progress-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ffb74d; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-2 .progress-bar {
      background-color: #ffb74d; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffb74d !important; }

.border-orange.border-lighten-2 {
  border: 1px solid #ffb74d !important; }

.border-top-orange.border-top-lighten-2 {
  border-top: 1px solid #ffb74d !important; }

.border-bottom-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #ffb74d !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-2 {
  border-left: 1px solid #ffb74d !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-2 {
  border-right: 1px solid #ffb74d !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-2 {
  border-right: 1px solid #ffb74d !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-2 {
  border-left: 1px solid #ffb74d !important; }

.overlay-orange.overlay-lighten-2 {
  background: #ffb74d;
  /* The Fallback */
  background: rgba(255, 183, 77, 0.8); }

.orange.lighten-1 {
  color: #ffa726 !important; }

.bg-orange.bg-lighten-1 {
  background-color: #ffa726 !important; }

.btn-orange.btn-lighten-1 {
  border-color: #f57c00 !important;
  background-color: #ffa726 !important; }
  .btn-orange.btn-lighten-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-lighten-1:focus, .btn-orange.btn-lighten-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-lighten-1 {
  border-color: #ffa726 !important;
  color: #ffa726 !important; }
  .btn-outline-orange.btn-outline-lighten-1:hover {
    background-color: #ffa726 !important; }

.progress-orange.progress-lighten-1 {
  background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ffa726; }
  .progress-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ffa726; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-lighten-1 .progress-bar {
      background-color: #ffa726; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffa726 !important; }

.border-orange.border-lighten-1 {
  border: 1px solid #ffa726 !important; }

.border-top-orange.border-top-lighten-1 {
  border-top: 1px solid #ffa726 !important; }

.border-bottom-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #ffa726 !important; }

[dir="ltr"] .border-left-orange.border-left-lighten-1 {
  border-left: 1px solid #ffa726 !important; }

[dir="ltr"] .border-right-orange.border-right-lighten-1 {
  border-right: 1px solid #ffa726 !important; }

[dir="rtl"] .border-left-orange.border-left-lighten-1 {
  border-right: 1px solid #ffa726 !important; }

[dir="rtl"] .border-right-orange.border-right-lighten-1 {
  border-left: 1px solid #ffa726 !important; }

.overlay-orange.overlay-lighten-1 {
  background: #ffa726;
  /* The Fallback */
  background: rgba(255, 167, 38, 0.8); }

.orange {
  color: #ff9800 !important; }

.bg-orange {
  background-color: #ff9800 !important; }
  .bg-orange .card-header, .bg-orange .card-footer {
    background-color: transparent; }

.toast-orange {
  background-color: #ff9800; }

.alert-orange {
  border-color: #ff9800 !important;
  background-color: #ffa624 !important;
  color: #4d2e00 !important; }
  .alert-orange .alert-link {
    color: #241500 !important; }

.border-orange {
  border-color: #ff9800; }

.border-top-orange {
  border-top-color: #ff9800; }

.border-bottom-orange {
  border-bottom-color: #ff9800; }

[dir="ltr"] .border-left-orange {
  border-left-color: #ff9800; }

[dir="ltr"] .border-right-orange {
  border-right-color: #ff9800; }

[dir="rtl"] .border-left-orange {
  border-right-color: #ff9800; }

[dir="rtl"] .border-right-orange {
  border-left-color: #ff9800; }

.badge-orange {
  background-color: #ff9800; }

.panel-orange {
  border-color: #ff9800; }
  .panel-orange .panel-heading {
    color: #FFF;
    border-color: #ff9800;
    background-color: #ffa21a; }

.bg-orange.tag-glow, .border-orange.tag-glow {
  box-shadow: 0px 0px 10px #ff9800; }

.overlay-orange {
  background: #ff9800;
  /* The Fallback */
  background: rgba(255, 152, 0, 0.8); }

.card.card-outline-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #ff9800;
  background-color: transparent; }
  .card.card-outline-orange .card-header, .card.card-outline-orange .card-footer {
    background-color: transparent; }

.btn-orange.btn-flat {
  background-color: transparent !important;
  color: #ff9800;
  border: none; }

.btn-orange.btn-raised, .btn-orange.btn-fab {
  background-color: #ff9800 !important;
  color: #fff !important;
  border-color: #ff9800; }
  .btn-orange.btn-raised.active, .btn-orange.btn-fab.active {
    background-color: #db8300 !important;
    border-color: #db8300 !important; }

.btn-group-raised .btn-orange {
  background-color: #ff9800 !important;
  color: #fff !important; }

.btn-outline-orange {
  border: 1px solid;
  border-color: #ff9800;
  background-color: transparent;
  color: #ff9800;
  box-shadow: none !important; }
  .btn-outline-orange:focus {
    background-color: transparent !important;
    color: #ff9800 !important;
    box-shadow: transparent !important; }
  .btn-outline-orange.active {
    background-color: #ff9800 !important;
    color: #FFF !important; }
  .btn-outline-orange:hover {
    background-color: #f08f00 !important;
    color: #FFF !important; }
    .btn-outline-orange:hover svg {
      color: #FFF !important; }

.btn-outline-orange.btn-raised, .btn-outline-orange.btn-fab {
  border: 1px solid;
  border-color: #ff9800;
  background-color: transparent;
  color: #ff9800;
  box-shadow: none !important; }
  .btn-outline-orange.btn-raised:focus, .btn-outline-orange.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-orange.btn-raised.active, .btn-outline-orange.btn-fab.active {
    background-color: #ff9800 !important;
    color: #FFF !important; }
  .btn-outline-orange.btn-raised:hover, .btn-outline-orange.btn-fab:hover {
    background-color: #f08f00 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-orange {
  background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-webkit-progress-value {
    background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-moz-progress-bar {
    background-color: #ff9800; }
  .progress .progress-bar.progress-bar-orange[value]::-ms-fill {
    background-color: #ff9800; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-orange .progress-bar {
      background-color: #ff9800; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9800 !important; }

.orange.darken-1 {
  color: #fb8c00 !important; }

.bg-orange.bg-darken-1 {
  background-color: #fb8c00 !important; }

.btn-orange.btn-darken-1 {
  border-color: #f57c00 !important;
  background-color: #fb8c00 !important; }
  .btn-orange.btn-darken-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-1:focus, .btn-orange.btn-darken-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-1 {
  border-color: #fb8c00 !important;
  color: #fb8c00 !important; }
  .btn-outline-orange.btn-outline-darken-1:hover {
    background-color: #fb8c00 !important; }

.progress-orange.progress-darken-1 {
  background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #fb8c00; }
  .progress-orange.progress-darken-1[value]::-ms-fill {
    background-color: #fb8c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-1 .progress-bar {
      background-color: #fb8c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fb8c00 !important; }

.border-orange.border-darken-1 {
  border: 1px solid #fb8c00 !important; }

.border-top-orange.border-top-darken-1 {
  border-top: 1px solid #fb8c00 !important; }

.border-bottom-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #fb8c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-1 {
  border-left: 1px solid #fb8c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-1 {
  border-right: 1px solid #fb8c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-1 {
  border-right: 1px solid #fb8c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-1 {
  border-left: 1px solid #fb8c00 !important; }

.overlay-orange.overlay-darken-1 {
  background: #fb8c00;
  /* The Fallback */
  background: rgba(251, 140, 0, 0.8); }

.orange.darken-2 {
  color: #f57c00 !important; }

.bg-orange.bg-darken-2 {
  background-color: #f57c00 !important; }

.btn-orange.btn-darken-2 {
  border-color: #f57c00 !important;
  background-color: #f57c00 !important; }
  .btn-orange.btn-darken-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-2:focus, .btn-orange.btn-darken-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-2 {
  border-color: #f57c00 !important;
  color: #f57c00 !important; }
  .btn-outline-orange.btn-outline-darken-2:hover {
    background-color: #f57c00 !important; }

.progress-orange.progress-darken-2 {
  background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #f57c00; }
  .progress-orange.progress-darken-2[value]::-ms-fill {
    background-color: #f57c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-2 .progress-bar {
      background-color: #f57c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f57c00 !important; }

.border-orange.border-darken-2 {
  border: 1px solid #f57c00 !important; }

.border-top-orange.border-top-darken-2 {
  border-top: 1px solid #f57c00 !important; }

.border-bottom-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #f57c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-2 {
  border-left: 1px solid #f57c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-2 {
  border-right: 1px solid #f57c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-2 {
  border-right: 1px solid #f57c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-2 {
  border-left: 1px solid #f57c00 !important; }

.overlay-orange.overlay-darken-2 {
  background: #f57c00;
  /* The Fallback */
  background: rgba(245, 124, 0, 0.8); }

.orange.darken-3 {
  color: #ef6c00 !important; }

.bg-orange.bg-darken-3 {
  background-color: #ef6c00 !important; }

.btn-orange.btn-darken-3 {
  border-color: #f57c00 !important;
  background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-3:focus, .btn-orange.btn-darken-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-3 {
  border-color: #ef6c00 !important;
  color: #ef6c00 !important; }
  .btn-outline-orange.btn-outline-darken-3:hover {
    background-color: #ef6c00 !important; }

.progress-orange.progress-darken-3 {
  background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #ef6c00; }
  .progress-orange.progress-darken-3[value]::-ms-fill {
    background-color: #ef6c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-3 .progress-bar {
      background-color: #ef6c00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ef6c00 !important; }

.border-orange.border-darken-3 {
  border: 1px solid #ef6c00 !important; }

.border-top-orange.border-top-darken-3 {
  border-top: 1px solid #ef6c00 !important; }

.border-bottom-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #ef6c00 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-3 {
  border-left: 1px solid #ef6c00 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-3 {
  border-right: 1px solid #ef6c00 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-3 {
  border-right: 1px solid #ef6c00 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-3 {
  border-left: 1px solid #ef6c00 !important; }

.overlay-orange.overlay-darken-3 {
  background: #ef6c00;
  /* The Fallback */
  background: rgba(239, 108, 0, 0.8); }

.orange.darken-4 {
  color: #e65100 !important; }

.bg-orange.bg-darken-4 {
  background-color: #e65100 !important; }

.btn-orange.btn-darken-4 {
  border-color: #f57c00 !important;
  background-color: #e65100 !important; }
  .btn-orange.btn-darken-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-darken-4:focus, .btn-orange.btn-darken-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-darken-4 {
  border-color: #e65100 !important;
  color: #e65100 !important; }
  .btn-outline-orange.btn-outline-darken-4:hover {
    background-color: #e65100 !important; }

.progress-orange.progress-darken-4 {
  background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #e65100; }
  .progress-orange.progress-darken-4[value]::-ms-fill {
    background-color: #e65100; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-darken-4 .progress-bar {
      background-color: #e65100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e65100 !important; }

.border-orange.border-darken-4 {
  border: 1px solid #e65100 !important; }

.border-top-orange.border-top-darken-4 {
  border-top: 1px solid #e65100 !important; }

.border-bottom-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #e65100 !important; }

[dir="ltr"] .border-left-orange.border-left-darken-4 {
  border-left: 1px solid #e65100 !important; }

[dir="ltr"] .border-right-orange.border-right-darken-4 {
  border-right: 1px solid #e65100 !important; }

[dir="rtl"] .border-left-orange.border-left-darken-4 {
  border-right: 1px solid #e65100 !important; }

[dir="rtl"] .border-right-orange.border-right-darken-4 {
  border-left: 1px solid #e65100 !important; }

.overlay-orange.overlay-darken-4 {
  background: #e65100;
  /* The Fallback */
  background: rgba(230, 81, 0, 0.8); }

.orange.accent-1 {
  color: #ffd180 !important; }

.bg-orange.bg-accent-1 {
  background-color: #ffd180 !important; }

.btn-orange.btn-accent-1 {
  border-color: #f57c00 !important;
  background-color: #ffd180 !important; }
  .btn-orange.btn-accent-1:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-1:focus, .btn-orange.btn-accent-1:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-1 {
  border-color: #ffd180 !important;
  color: #ffd180 !important; }
  .btn-outline-orange.btn-outline-accent-1:hover {
    background-color: #ffd180 !important; }

.progress-orange.progress-accent-1 {
  background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ffd180; }
  .progress-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ffd180; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-1 .progress-bar {
      background-color: #ffd180; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffd180 !important; }

.border-orange.border-accent-1 {
  border: 1px solid #ffd180 !important; }

.border-top-orange.border-top-accent-1 {
  border-top: 1px solid #ffd180 !important; }

.border-bottom-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #ffd180 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-1 {
  border-left: 1px solid #ffd180 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-1 {
  border-right: 1px solid #ffd180 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-1 {
  border-right: 1px solid #ffd180 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-1 {
  border-left: 1px solid #ffd180 !important; }

.overlay-orange.overlay-accent-1 {
  background: #ffd180;
  /* The Fallback */
  background: rgba(255, 209, 128, 0.8); }

.orange.accent-2 {
  color: #ffab40 !important; }

.bg-orange.bg-accent-2 {
  background-color: #ffab40 !important; }

.btn-orange.btn-accent-2 {
  border-color: #f57c00 !important;
  background-color: #ffab40 !important; }
  .btn-orange.btn-accent-2:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-2:focus, .btn-orange.btn-accent-2:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-2 {
  border-color: #ffab40 !important;
  color: #ffab40 !important; }
  .btn-outline-orange.btn-outline-accent-2:hover {
    background-color: #ffab40 !important; }

.progress-orange.progress-accent-2 {
  background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ffab40; }
  .progress-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ffab40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-2 .progress-bar {
      background-color: #ffab40; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab40 !important; }

.border-orange.border-accent-2 {
  border: 1px solid #ffab40 !important; }

.border-top-orange.border-top-accent-2 {
  border-top: 1px solid #ffab40 !important; }

.border-bottom-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #ffab40 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-2 {
  border-left: 1px solid #ffab40 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-2 {
  border-right: 1px solid #ffab40 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-2 {
  border-right: 1px solid #ffab40 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-2 {
  border-left: 1px solid #ffab40 !important; }

.overlay-orange.overlay-accent-2 {
  background: #ffab40;
  /* The Fallback */
  background: rgba(255, 171, 64, 0.8); }

.orange.accent-3 {
  color: #ff9100 !important; }

.bg-orange.bg-accent-3 {
  background-color: #ff9100 !important; }

.btn-orange.btn-accent-3 {
  border-color: #f57c00 !important;
  background-color: #ff9100 !important; }
  .btn-orange.btn-accent-3:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-3:focus, .btn-orange.btn-accent-3:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-3 {
  border-color: #ff9100 !important;
  color: #ff9100 !important; }
  .btn-outline-orange.btn-outline-accent-3:hover {
    background-color: #ff9100 !important; }

.progress-orange.progress-accent-3 {
  background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff9100; }
  .progress-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff9100; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-3 .progress-bar {
      background-color: #ff9100; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9100 !important; }

.border-orange.border-accent-3 {
  border: 1px solid #ff9100 !important; }

.border-top-orange.border-top-accent-3 {
  border-top: 1px solid #ff9100 !important; }

.border-bottom-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #ff9100 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-3 {
  border-left: 1px solid #ff9100 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-3 {
  border-right: 1px solid #ff9100 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-3 {
  border-right: 1px solid #ff9100 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-3 {
  border-left: 1px solid #ff9100 !important; }

.overlay-orange.overlay-accent-3 {
  background: #ff9100;
  /* The Fallback */
  background: rgba(255, 145, 0, 0.8); }

.orange.accent-4 {
  color: #ff6d00 !important; }

.bg-orange.bg-accent-4 {
  background-color: #ff6d00 !important; }

.btn-orange.btn-accent-4 {
  border-color: #f57c00 !important;
  background-color: #ff6d00 !important; }
  .btn-orange.btn-accent-4:hover {
    border-color: #f57c00 !important;
    background-color: #ef6c00 !important; }
  .btn-orange.btn-accent-4:focus, .btn-orange.btn-accent-4:active {
    border-color: #ef6c00 !important;
    background-color: #e65100 !important; }

.btn-outline-orange.btn-outline-accent-4 {
  border-color: #ff6d00 !important;
  color: #ff6d00 !important; }
  .btn-outline-orange.btn-outline-accent-4:hover {
    background-color: #ff6d00 !important; }

.progress-orange.progress-accent-4 {
  background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #ff6d00; }
  .progress-orange.progress-accent-4[value]::-ms-fill {
    background-color: #ff6d00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-orange.progress-accent-4 .progress-bar {
      background-color: #ff6d00; } }

input:focus ~ .bg-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6d00 !important; }

.border-orange.border-accent-4 {
  border: 1px solid #ff6d00 !important; }

.border-top-orange.border-top-accent-4 {
  border-top: 1px solid #ff6d00 !important; }

.border-bottom-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #ff6d00 !important; }

[dir="ltr"] .border-left-orange.border-left-accent-4 {
  border-left: 1px solid #ff6d00 !important; }

[dir="ltr"] .border-right-orange.border-right-accent-4 {
  border-right: 1px solid #ff6d00 !important; }

[dir="rtl"] .border-left-orange.border-left-accent-4 {
  border-right: 1px solid #ff6d00 !important; }

[dir="rtl"] .border-right-orange.border-right-accent-4 {
  border-left: 1px solid #ff6d00 !important; }

.overlay-orange.overlay-accent-4 {
  background: #ff6d00;
  /* The Fallback */
  background: rgba(255, 109, 0, 0.8); }

.deep-orange.lighten-5 {
  color: #fbe9e7 !important; }

.bg-deep-orange.bg-lighten-5 {
  background-color: #fbe9e7 !important; }

.btn-deep-orange.btn-lighten-5 {
  border-color: #e64a19 !important;
  background-color: #fbe9e7 !important; }
  .btn-deep-orange.btn-lighten-5:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-5:focus, .btn-deep-orange.btn-lighten-5:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-5 {
  border-color: #fbe9e7 !important;
  color: #fbe9e7 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-5:hover {
    background-color: #fbe9e7 !important; }

.progress-deep-orange.progress-lighten-5 {
  background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fbe9e7; }
  .progress-deep-orange.progress-lighten-5[value]::-ms-fill {
    background-color: #fbe9e7; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-5 .progress-bar {
      background-color: #fbe9e7; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fbe9e7 !important; }

.border-deep-orange.border-lighten-5 {
  border: 1px solid #fbe9e7 !important; }

.border-top-deep-orange.border-top-lighten-5 {
  border-top: 1px solid #fbe9e7 !important; }

.border-bottom-deep-orange.border-bottom-lighten-5 {
  border-bottom: 1px solid #fbe9e7 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-5 {
  border-left: 1px solid #fbe9e7 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-5 {
  border-right: 1px solid #fbe9e7 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-5 {
  border-right: 1px solid #fbe9e7 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-5 {
  border-left: 1px solid #fbe9e7 !important; }

.overlay-deep-orange.overlay-lighten-5 {
  background: #fbe9e7;
  /* The Fallback */
  background: rgba(251, 233, 231, 0.8); }

.deep-orange.lighten-4 {
  color: #ffccbc !important; }

.bg-deep-orange.bg-lighten-4 {
  background-color: #ffccbc !important; }

.btn-deep-orange.btn-lighten-4 {
  border-color: #e64a19 !important;
  background-color: #ffccbc !important; }
  .btn-deep-orange.btn-lighten-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-4:focus, .btn-deep-orange.btn-lighten-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-4 {
  border-color: #ffccbc !important;
  color: #ffccbc !important; }
  .btn-outline-deep-orange.btn-outline-lighten-4:hover {
    background-color: #ffccbc !important; }

.progress-deep-orange.progress-lighten-4 {
  background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #ffccbc; }
  .progress-deep-orange.progress-lighten-4[value]::-ms-fill {
    background-color: #ffccbc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-4 .progress-bar {
      background-color: #ffccbc; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffccbc !important; }

.border-deep-orange.border-lighten-4 {
  border: 1px solid #ffccbc !important; }

.border-top-deep-orange.border-top-lighten-4 {
  border-top: 1px solid #ffccbc !important; }

.border-bottom-deep-orange.border-bottom-lighten-4 {
  border-bottom: 1px solid #ffccbc !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-4 {
  border-left: 1px solid #ffccbc !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-4 {
  border-right: 1px solid #ffccbc !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-4 {
  border-right: 1px solid #ffccbc !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-4 {
  border-left: 1px solid #ffccbc !important; }

.overlay-deep-orange.overlay-lighten-4 {
  background: #ffccbc;
  /* The Fallback */
  background: rgba(255, 204, 188, 0.8); }

.deep-orange.lighten-3 {
  color: #ffab91 !important; }

.bg-deep-orange.bg-lighten-3 {
  background-color: #ffab91 !important; }

.btn-deep-orange.btn-lighten-3 {
  border-color: #e64a19 !important;
  background-color: #ffab91 !important; }
  .btn-deep-orange.btn-lighten-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-3:focus, .btn-deep-orange.btn-lighten-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-3 {
  border-color: #ffab91 !important;
  color: #ffab91 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-3:hover {
    background-color: #ffab91 !important; }

.progress-deep-orange.progress-lighten-3 {
  background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #ffab91; }
  .progress-deep-orange.progress-lighten-3[value]::-ms-fill {
    background-color: #ffab91; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-3 .progress-bar {
      background-color: #ffab91; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ffab91 !important; }

.border-deep-orange.border-lighten-3 {
  border: 1px solid #ffab91 !important; }

.border-top-deep-orange.border-top-lighten-3 {
  border-top: 1px solid #ffab91 !important; }

.border-bottom-deep-orange.border-bottom-lighten-3 {
  border-bottom: 1px solid #ffab91 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-3 {
  border-left: 1px solid #ffab91 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-3 {
  border-right: 1px solid #ffab91 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-3 {
  border-right: 1px solid #ffab91 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-3 {
  border-left: 1px solid #ffab91 !important; }

.overlay-deep-orange.overlay-lighten-3 {
  background: #ffab91;
  /* The Fallback */
  background: rgba(255, 171, 145, 0.8); }

.deep-orange.lighten-2 {
  color: #ff8a65 !important; }

.bg-deep-orange.bg-lighten-2 {
  background-color: #ff8a65 !important; }

.btn-deep-orange.btn-lighten-2 {
  border-color: #e64a19 !important;
  background-color: #ff8a65 !important; }
  .btn-deep-orange.btn-lighten-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-2:focus, .btn-deep-orange.btn-lighten-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-2 {
  border-color: #ff8a65 !important;
  color: #ff8a65 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-2:hover {
    background-color: #ff8a65 !important; }

.progress-deep-orange.progress-lighten-2 {
  background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #ff8a65; }
  .progress-deep-orange.progress-lighten-2[value]::-ms-fill {
    background-color: #ff8a65; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-2 .progress-bar {
      background-color: #ff8a65; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff8a65 !important; }

.border-deep-orange.border-lighten-2 {
  border: 1px solid #ff8a65 !important; }

.border-top-deep-orange.border-top-lighten-2 {
  border-top: 1px solid #ff8a65 !important; }

.border-bottom-deep-orange.border-bottom-lighten-2 {
  border-bottom: 1px solid #ff8a65 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-2 {
  border-left: 1px solid #ff8a65 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-2 {
  border-right: 1px solid #ff8a65 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-2 {
  border-right: 1px solid #ff8a65 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-2 {
  border-left: 1px solid #ff8a65 !important; }

.overlay-deep-orange.overlay-lighten-2 {
  background: #ff8a65;
  /* The Fallback */
  background: rgba(255, 138, 101, 0.8); }

.deep-orange.lighten-1 {
  color: #ff7043 !important; }

.bg-deep-orange.bg-lighten-1 {
  background-color: #ff7043 !important; }

.btn-deep-orange.btn-lighten-1 {
  border-color: #e64a19 !important;
  background-color: #ff7043 !important; }
  .btn-deep-orange.btn-lighten-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-lighten-1:focus, .btn-deep-orange.btn-lighten-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-lighten-1 {
  border-color: #ff7043 !important;
  color: #ff7043 !important; }
  .btn-outline-deep-orange.btn-outline-lighten-1:hover {
    background-color: #ff7043 !important; }

.progress-deep-orange.progress-lighten-1 {
  background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #ff7043; }
  .progress-deep-orange.progress-lighten-1[value]::-ms-fill {
    background-color: #ff7043; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-lighten-1 .progress-bar {
      background-color: #ff7043; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff7043 !important; }

.border-deep-orange.border-lighten-1 {
  border: 1px solid #ff7043 !important; }

.border-top-deep-orange.border-top-lighten-1 {
  border-top: 1px solid #ff7043 !important; }

.border-bottom-deep-orange.border-bottom-lighten-1 {
  border-bottom: 1px solid #ff7043 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-lighten-1 {
  border-left: 1px solid #ff7043 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-lighten-1 {
  border-right: 1px solid #ff7043 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-lighten-1 {
  border-right: 1px solid #ff7043 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-lighten-1 {
  border-left: 1px solid #ff7043 !important; }

.overlay-deep-orange.overlay-lighten-1 {
  background: #ff7043;
  /* The Fallback */
  background: rgba(255, 112, 67, 0.8); }

.deep-orange {
  color: #ff5722 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }
  .bg-deep-orange .card-header, .bg-deep-orange .card-footer {
    background-color: transparent; }

.toast-deep-orange {
  background-color: #ff5722; }

.alert-deep-orange {
  border-color: #ff5722 !important;
  background-color: #ff7246 !important;
  color: #6f1b00 !important; }
  .alert-deep-orange .alert-link {
    color: #461100 !important; }

.border-deep-orange {
  border-color: #ff5722; }

.border-top-deep-orange {
  border-top-color: #ff5722; }

.border-bottom-deep-orange {
  border-bottom-color: #ff5722; }

[dir="ltr"] .border-left-deep-orange {
  border-left-color: #ff5722; }

[dir="ltr"] .border-right-deep-orange {
  border-right-color: #ff5722; }

[dir="rtl"] .border-left-deep-orange {
  border-right-color: #ff5722; }

[dir="rtl"] .border-right-deep-orange {
  border-left-color: #ff5722; }

.badge-deep-orange {
  background-color: #ff5722; }

.panel-deep-orange {
  border-color: #ff5722; }
  .panel-deep-orange .panel-heading {
    color: #FFF;
    border-color: #ff5722;
    background-color: #ff6a3c; }

.bg-deep-orange.tag-glow, .border-deep-orange.tag-glow {
  box-shadow: 0px 0px 10px #ff5722; }

.overlay-deep-orange {
  background: #ff5722;
  /* The Fallback */
  background: rgba(255, 87, 34, 0.8); }

.card.card-outline-deep-orange {
  border-width: 1px;
  border-style: solid;
  border-color: #ff5722;
  background-color: transparent; }
  .card.card-outline-deep-orange .card-header, .card.card-outline-deep-orange .card-footer {
    background-color: transparent; }

.btn-deep-orange.btn-flat {
  background-color: transparent !important;
  color: #ff5722;
  border: none; }

.btn-deep-orange.btn-raised, .btn-deep-orange.btn-fab {
  background-color: #ff5722 !important;
  color: #fff !important;
  border-color: #ff5722; }
  .btn-deep-orange.btn-raised.active, .btn-deep-orange.btn-fab.active {
    background-color: #fd3d00 !important;
    border-color: #fd3d00 !important; }

.btn-group-raised .btn-deep-orange {
  background-color: #ff5722 !important;
  color: #fff !important; }

.btn-outline-deep-orange {
  border: 1px solid;
  border-color: #ff5722;
  background-color: transparent;
  color: #ff5722;
  box-shadow: none !important; }
  .btn-outline-deep-orange:focus {
    background-color: transparent !important;
    color: #ff5722 !important;
    box-shadow: transparent !important; }
  .btn-outline-deep-orange.active {
    background-color: #ff5722 !important;
    color: #FFF !important; }
  .btn-outline-deep-orange:hover {
    background-color: #ff4b13 !important;
    color: #FFF !important; }
    .btn-outline-deep-orange:hover svg {
      color: #FFF !important; }

.btn-outline-deep-orange.btn-raised, .btn-outline-deep-orange.btn-fab {
  border: 1px solid;
  border-color: #ff5722;
  background-color: transparent;
  color: #ff5722;
  box-shadow: none !important; }
  .btn-outline-deep-orange.btn-raised:focus, .btn-outline-deep-orange.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-deep-orange.btn-raised.active, .btn-outline-deep-orange.btn-fab.active {
    background-color: #ff5722 !important;
    color: #FFF !important; }
  .btn-outline-deep-orange.btn-raised:hover, .btn-outline-deep-orange.btn-fab:hover {
    background-color: #ff4b13 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-deep-orange {
  background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-webkit-progress-value {
    background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-moz-progress-bar {
    background-color: #ff5722; }
  .progress .progress-bar.progress-bar-deep-orange[value]::-ms-fill {
    background-color: #ff5722; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-deep-orange .progress-bar {
      background-color: #ff5722; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff5722 !important; }

.deep-orange.darken-1 {
  color: #f4511e !important; }

.bg-deep-orange.bg-darken-1 {
  background-color: #f4511e !important; }

.btn-deep-orange.btn-darken-1 {
  border-color: #e64a19 !important;
  background-color: #f4511e !important; }
  .btn-deep-orange.btn-darken-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-1:focus, .btn-deep-orange.btn-darken-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-1 {
  border-color: #f4511e !important;
  color: #f4511e !important; }
  .btn-outline-deep-orange.btn-outline-darken-1:hover {
    background-color: #f4511e !important; }

.progress-deep-orange.progress-darken-1 {
  background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-webkit-progress-value {
    background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-moz-progress-bar {
    background-color: #f4511e; }
  .progress-deep-orange.progress-darken-1[value]::-ms-fill {
    background-color: #f4511e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-1 .progress-bar {
      background-color: #f4511e; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f4511e !important; }

.border-deep-orange.border-darken-1 {
  border: 1px solid #f4511e !important; }

.border-top-deep-orange.border-top-darken-1 {
  border-top: 1px solid #f4511e !important; }

.border-bottom-deep-orange.border-bottom-darken-1 {
  border-bottom: 1px solid #f4511e !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-1 {
  border-left: 1px solid #f4511e !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-1 {
  border-right: 1px solid #f4511e !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-1 {
  border-right: 1px solid #f4511e !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-1 {
  border-left: 1px solid #f4511e !important; }

.overlay-deep-orange.overlay-darken-1 {
  background: #f4511e;
  /* The Fallback */
  background: rgba(244, 81, 30, 0.8); }

.deep-orange.darken-2 {
  color: #e64a19 !important; }

.bg-deep-orange.bg-darken-2 {
  background-color: #e64a19 !important; }

.btn-deep-orange.btn-darken-2 {
  border-color: #e64a19 !important;
  background-color: #e64a19 !important; }
  .btn-deep-orange.btn-darken-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-2:focus, .btn-deep-orange.btn-darken-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-2 {
  border-color: #e64a19 !important;
  color: #e64a19 !important; }
  .btn-outline-deep-orange.btn-outline-darken-2:hover {
    background-color: #e64a19 !important; }

.progress-deep-orange.progress-darken-2 {
  background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-webkit-progress-value {
    background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-moz-progress-bar {
    background-color: #e64a19; }
  .progress-deep-orange.progress-darken-2[value]::-ms-fill {
    background-color: #e64a19; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-2 .progress-bar {
      background-color: #e64a19; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e64a19 !important; }

.border-deep-orange.border-darken-2 {
  border: 1px solid #e64a19 !important; }

.border-top-deep-orange.border-top-darken-2 {
  border-top: 1px solid #e64a19 !important; }

.border-bottom-deep-orange.border-bottom-darken-2 {
  border-bottom: 1px solid #e64a19 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-2 {
  border-left: 1px solid #e64a19 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-2 {
  border-right: 1px solid #e64a19 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-2 {
  border-right: 1px solid #e64a19 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-2 {
  border-left: 1px solid #e64a19 !important; }

.overlay-deep-orange.overlay-darken-2 {
  background: #e64a19;
  /* The Fallback */
  background: rgba(230, 74, 25, 0.8); }

.deep-orange.darken-3 {
  color: #d84315 !important; }

.bg-deep-orange.bg-darken-3 {
  background-color: #d84315 !important; }

.btn-deep-orange.btn-darken-3 {
  border-color: #e64a19 !important;
  background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-3:focus, .btn-deep-orange.btn-darken-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-3 {
  border-color: #d84315 !important;
  color: #d84315 !important; }
  .btn-outline-deep-orange.btn-outline-darken-3:hover {
    background-color: #d84315 !important; }

.progress-deep-orange.progress-darken-3 {
  background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-webkit-progress-value {
    background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-moz-progress-bar {
    background-color: #d84315; }
  .progress-deep-orange.progress-darken-3[value]::-ms-fill {
    background-color: #d84315; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-3 .progress-bar {
      background-color: #d84315; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d84315 !important; }

.border-deep-orange.border-darken-3 {
  border: 1px solid #d84315 !important; }

.border-top-deep-orange.border-top-darken-3 {
  border-top: 1px solid #d84315 !important; }

.border-bottom-deep-orange.border-bottom-darken-3 {
  border-bottom: 1px solid #d84315 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-3 {
  border-left: 1px solid #d84315 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-3 {
  border-right: 1px solid #d84315 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-3 {
  border-right: 1px solid #d84315 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-3 {
  border-left: 1px solid #d84315 !important; }

.overlay-deep-orange.overlay-darken-3 {
  background: #d84315;
  /* The Fallback */
  background: rgba(216, 67, 21, 0.8); }

.deep-orange.darken-4 {
  color: #bf360c !important; }

.bg-deep-orange.bg-darken-4 {
  background-color: #bf360c !important; }

.btn-deep-orange.btn-darken-4 {
  border-color: #e64a19 !important;
  background-color: #bf360c !important; }
  .btn-deep-orange.btn-darken-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-darken-4:focus, .btn-deep-orange.btn-darken-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-darken-4 {
  border-color: #bf360c !important;
  color: #bf360c !important; }
  .btn-outline-deep-orange.btn-outline-darken-4:hover {
    background-color: #bf360c !important; }

.progress-deep-orange.progress-darken-4 {
  background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-webkit-progress-value {
    background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-moz-progress-bar {
    background-color: #bf360c; }
  .progress-deep-orange.progress-darken-4[value]::-ms-fill {
    background-color: #bf360c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-darken-4 .progress-bar {
      background-color: #bf360c; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bf360c !important; }

.border-deep-orange.border-darken-4 {
  border: 1px solid #bf360c !important; }

.border-top-deep-orange.border-top-darken-4 {
  border-top: 1px solid #bf360c !important; }

.border-bottom-deep-orange.border-bottom-darken-4 {
  border-bottom: 1px solid #bf360c !important; }

[dir="ltr"] .border-left-deep-orange.border-left-darken-4 {
  border-left: 1px solid #bf360c !important; }

[dir="ltr"] .border-right-deep-orange.border-right-darken-4 {
  border-right: 1px solid #bf360c !important; }

[dir="rtl"] .border-left-deep-orange.border-left-darken-4 {
  border-right: 1px solid #bf360c !important; }

[dir="rtl"] .border-right-deep-orange.border-right-darken-4 {
  border-left: 1px solid #bf360c !important; }

.overlay-deep-orange.overlay-darken-4 {
  background: #bf360c;
  /* The Fallback */
  background: rgba(191, 54, 12, 0.8); }

.deep-orange.accent-1 {
  color: #ff9e80 !important; }

.bg-deep-orange.bg-accent-1 {
  background-color: #ff9e80 !important; }

.btn-deep-orange.btn-accent-1 {
  border-color: #e64a19 !important;
  background-color: #ff9e80 !important; }
  .btn-deep-orange.btn-accent-1:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-1:focus, .btn-deep-orange.btn-accent-1:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-1 {
  border-color: #ff9e80 !important;
  color: #ff9e80 !important; }
  .btn-outline-deep-orange.btn-outline-accent-1:hover {
    background-color: #ff9e80 !important; }

.progress-deep-orange.progress-accent-1 {
  background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-webkit-progress-value {
    background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-moz-progress-bar {
    background-color: #ff9e80; }
  .progress-deep-orange.progress-accent-1[value]::-ms-fill {
    background-color: #ff9e80; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-1 .progress-bar {
      background-color: #ff9e80; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff9e80 !important; }

.border-deep-orange.border-accent-1 {
  border: 1px solid #ff9e80 !important; }

.border-top-deep-orange.border-top-accent-1 {
  border-top: 1px solid #ff9e80 !important; }

.border-bottom-deep-orange.border-bottom-accent-1 {
  border-bottom: 1px solid #ff9e80 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-1 {
  border-left: 1px solid #ff9e80 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-1 {
  border-right: 1px solid #ff9e80 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-1 {
  border-right: 1px solid #ff9e80 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-1 {
  border-left: 1px solid #ff9e80 !important; }

.overlay-deep-orange.overlay-accent-1 {
  background: #ff9e80;
  /* The Fallback */
  background: rgba(255, 158, 128, 0.8); }

.deep-orange.accent-2 {
  color: #ff6e40 !important; }

.bg-deep-orange.bg-accent-2 {
  background-color: #ff6e40 !important; }

.btn-deep-orange.btn-accent-2 {
  border-color: #e64a19 !important;
  background-color: #ff6e40 !important; }
  .btn-deep-orange.btn-accent-2:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-2:focus, .btn-deep-orange.btn-accent-2:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-2 {
  border-color: #ff6e40 !important;
  color: #ff6e40 !important; }
  .btn-outline-deep-orange.btn-outline-accent-2:hover {
    background-color: #ff6e40 !important; }

.progress-deep-orange.progress-accent-2 {
  background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-webkit-progress-value {
    background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-moz-progress-bar {
    background-color: #ff6e40; }
  .progress-deep-orange.progress-accent-2[value]::-ms-fill {
    background-color: #ff6e40; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-2 .progress-bar {
      background-color: #ff6e40; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff6e40 !important; }

.border-deep-orange.border-accent-2 {
  border: 1px solid #ff6e40 !important; }

.border-top-deep-orange.border-top-accent-2 {
  border-top: 1px solid #ff6e40 !important; }

.border-bottom-deep-orange.border-bottom-accent-2 {
  border-bottom: 1px solid #ff6e40 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-2 {
  border-left: 1px solid #ff6e40 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-2 {
  border-right: 1px solid #ff6e40 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-2 {
  border-right: 1px solid #ff6e40 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-2 {
  border-left: 1px solid #ff6e40 !important; }

.overlay-deep-orange.overlay-accent-2 {
  background: #ff6e40;
  /* The Fallback */
  background: rgba(255, 110, 64, 0.8); }

.deep-orange.accent-3 {
  color: #ff3d00 !important; }

.bg-deep-orange.bg-accent-3 {
  background-color: #ff3d00 !important; }

.btn-deep-orange.btn-accent-3 {
  border-color: #e64a19 !important;
  background-color: #ff3d00 !important; }
  .btn-deep-orange.btn-accent-3:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-3:focus, .btn-deep-orange.btn-accent-3:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-3 {
  border-color: #ff3d00 !important;
  color: #ff3d00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-3:hover {
    background-color: #ff3d00 !important; }

.progress-deep-orange.progress-accent-3 {
  background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-webkit-progress-value {
    background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-moz-progress-bar {
    background-color: #ff3d00; }
  .progress-deep-orange.progress-accent-3[value]::-ms-fill {
    background-color: #ff3d00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-3 .progress-bar {
      background-color: #ff3d00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #ff3d00 !important; }

.border-deep-orange.border-accent-3 {
  border: 1px solid #ff3d00 !important; }

.border-top-deep-orange.border-top-accent-3 {
  border-top: 1px solid #ff3d00 !important; }

.border-bottom-deep-orange.border-bottom-accent-3 {
  border-bottom: 1px solid #ff3d00 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-3 {
  border-left: 1px solid #ff3d00 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-3 {
  border-right: 1px solid #ff3d00 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-3 {
  border-right: 1px solid #ff3d00 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-3 {
  border-left: 1px solid #ff3d00 !important; }

.overlay-deep-orange.overlay-accent-3 {
  background: #ff3d00;
  /* The Fallback */
  background: rgba(255, 61, 0, 0.8); }

.deep-orange.accent-4 {
  color: #dd2c00 !important; }

.bg-deep-orange.bg-accent-4 {
  background-color: #dd2c00 !important; }

.btn-deep-orange.btn-accent-4 {
  border-color: #e64a19 !important;
  background-color: #dd2c00 !important; }
  .btn-deep-orange.btn-accent-4:hover {
    border-color: #e64a19 !important;
    background-color: #d84315 !important; }
  .btn-deep-orange.btn-accent-4:focus, .btn-deep-orange.btn-accent-4:active {
    border-color: #d84315 !important;
    background-color: #bf360c !important; }

.btn-outline-deep-orange.btn-outline-accent-4 {
  border-color: #dd2c00 !important;
  color: #dd2c00 !important; }
  .btn-outline-deep-orange.btn-outline-accent-4:hover {
    background-color: #dd2c00 !important; }

.progress-deep-orange.progress-accent-4 {
  background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-webkit-progress-value {
    background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-moz-progress-bar {
    background-color: #dd2c00; }
  .progress-deep-orange.progress-accent-4[value]::-ms-fill {
    background-color: #dd2c00; }
  @media screen and (min-width: 0 \0 ) {
    .progress-deep-orange.progress-accent-4 .progress-bar {
      background-color: #dd2c00; } }

input:focus ~ .bg-deep-orange {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #dd2c00 !important; }

.border-deep-orange.border-accent-4 {
  border: 1px solid #dd2c00 !important; }

.border-top-deep-orange.border-top-accent-4 {
  border-top: 1px solid #dd2c00 !important; }

.border-bottom-deep-orange.border-bottom-accent-4 {
  border-bottom: 1px solid #dd2c00 !important; }

[dir="ltr"] .border-left-deep-orange.border-left-accent-4 {
  border-left: 1px solid #dd2c00 !important; }

[dir="ltr"] .border-right-deep-orange.border-right-accent-4 {
  border-right: 1px solid #dd2c00 !important; }

[dir="rtl"] .border-left-deep-orange.border-left-accent-4 {
  border-right: 1px solid #dd2c00 !important; }

[dir="rtl"] .border-right-deep-orange.border-right-accent-4 {
  border-left: 1px solid #dd2c00 !important; }

.overlay-deep-orange.overlay-accent-4 {
  background: #dd2c00;
  /* The Fallback */
  background: rgba(221, 44, 0, 0.8); }

.brown.lighten-5 {
  color: #efebe9 !important; }

.bg-brown.bg-lighten-5 {
  background-color: #efebe9 !important; }

.btn-brown.btn-lighten-5 {
  border-color: #5d4037 !important;
  background-color: #efebe9 !important; }
  .btn-brown.btn-lighten-5:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-5:focus, .btn-brown.btn-lighten-5:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-5 {
  border-color: #efebe9 !important;
  color: #efebe9 !important; }
  .btn-outline-brown.btn-outline-lighten-5:hover {
    background-color: #efebe9 !important; }

.progress-brown.progress-lighten-5 {
  background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #efebe9; }
  .progress-brown.progress-lighten-5[value]::-ms-fill {
    background-color: #efebe9; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-5 .progress-bar {
      background-color: #efebe9; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #efebe9 !important; }

.border-brown.border-lighten-5 {
  border: 1px solid #efebe9 !important; }

.border-top-brown.border-top-lighten-5 {
  border-top: 1px solid #efebe9 !important; }

.border-bottom-brown.border-bottom-lighten-5 {
  border-bottom: 1px solid #efebe9 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-5 {
  border-left: 1px solid #efebe9 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-5 {
  border-right: 1px solid #efebe9 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-5 {
  border-right: 1px solid #efebe9 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-5 {
  border-left: 1px solid #efebe9 !important; }

.overlay-brown.overlay-lighten-5 {
  background: #efebe9;
  /* The Fallback */
  background: rgba(239, 235, 233, 0.8); }

.brown.lighten-4 {
  color: #d7ccc8 !important; }

.bg-brown.bg-lighten-4 {
  background-color: #d7ccc8 !important; }

.btn-brown.btn-lighten-4 {
  border-color: #5d4037 !important;
  background-color: #d7ccc8 !important; }
  .btn-brown.btn-lighten-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-4:focus, .btn-brown.btn-lighten-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-4 {
  border-color: #d7ccc8 !important;
  color: #d7ccc8 !important; }
  .btn-outline-brown.btn-outline-lighten-4:hover {
    background-color: #d7ccc8 !important; }

.progress-brown.progress-lighten-4 {
  background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #d7ccc8; }
  .progress-brown.progress-lighten-4[value]::-ms-fill {
    background-color: #d7ccc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-4 .progress-bar {
      background-color: #d7ccc8; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d7ccc8 !important; }

.border-brown.border-lighten-4 {
  border: 1px solid #d7ccc8 !important; }

.border-top-brown.border-top-lighten-4 {
  border-top: 1px solid #d7ccc8 !important; }

.border-bottom-brown.border-bottom-lighten-4 {
  border-bottom: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-4 {
  border-left: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-4 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-4 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-4 {
  border-left: 1px solid #d7ccc8 !important; }

.overlay-brown.overlay-lighten-4 {
  background: #d7ccc8;
  /* The Fallback */
  background: rgba(215, 204, 200, 0.8); }

.brown.lighten-3 {
  color: #bcaaa4 !important; }

.bg-brown.bg-lighten-3 {
  background-color: #bcaaa4 !important; }

.btn-brown.btn-lighten-3 {
  border-color: #5d4037 !important;
  background-color: #bcaaa4 !important; }
  .btn-brown.btn-lighten-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-3:focus, .btn-brown.btn-lighten-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-3 {
  border-color: #bcaaa4 !important;
  color: #bcaaa4 !important; }
  .btn-outline-brown.btn-outline-lighten-3:hover {
    background-color: #bcaaa4 !important; }

.progress-brown.progress-lighten-3 {
  background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #bcaaa4; }
  .progress-brown.progress-lighten-3[value]::-ms-fill {
    background-color: #bcaaa4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-3 .progress-bar {
      background-color: #bcaaa4; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bcaaa4 !important; }

.border-brown.border-lighten-3 {
  border: 1px solid #bcaaa4 !important; }

.border-top-brown.border-top-lighten-3 {
  border-top: 1px solid #bcaaa4 !important; }

.border-bottom-brown.border-bottom-lighten-3 {
  border-bottom: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-3 {
  border-left: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-3 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-3 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-3 {
  border-left: 1px solid #bcaaa4 !important; }

.overlay-brown.overlay-lighten-3 {
  background: #bcaaa4;
  /* The Fallback */
  background: rgba(188, 170, 164, 0.8); }

.brown.lighten-2 {
  color: #a1887f !important; }

.bg-brown.bg-lighten-2 {
  background-color: #a1887f !important; }

.btn-brown.btn-lighten-2 {
  border-color: #5d4037 !important;
  background-color: #a1887f !important; }
  .btn-brown.btn-lighten-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-2:focus, .btn-brown.btn-lighten-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-2 {
  border-color: #a1887f !important;
  color: #a1887f !important; }
  .btn-outline-brown.btn-outline-lighten-2:hover {
    background-color: #a1887f !important; }

.progress-brown.progress-lighten-2 {
  background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #a1887f; }
  .progress-brown.progress-lighten-2[value]::-ms-fill {
    background-color: #a1887f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-2 .progress-bar {
      background-color: #a1887f; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #a1887f !important; }

.border-brown.border-lighten-2 {
  border: 1px solid #a1887f !important; }

.border-top-brown.border-top-lighten-2 {
  border-top: 1px solid #a1887f !important; }

.border-bottom-brown.border-bottom-lighten-2 {
  border-bottom: 1px solid #a1887f !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-2 {
  border-left: 1px solid #a1887f !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-2 {
  border-right: 1px solid #a1887f !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-2 {
  border-right: 1px solid #a1887f !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-2 {
  border-left: 1px solid #a1887f !important; }

.overlay-brown.overlay-lighten-2 {
  background: #a1887f;
  /* The Fallback */
  background: rgba(161, 136, 127, 0.8); }

.brown.lighten-1 {
  color: #8d6e63 !important; }

.bg-brown.bg-lighten-1 {
  background-color: #8d6e63 !important; }

.btn-brown.btn-lighten-1 {
  border-color: #5d4037 !important;
  background-color: #8d6e63 !important; }
  .btn-brown.btn-lighten-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-lighten-1:focus, .btn-brown.btn-lighten-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-lighten-1 {
  border-color: #8d6e63 !important;
  color: #8d6e63 !important; }
  .btn-outline-brown.btn-outline-lighten-1:hover {
    background-color: #8d6e63 !important; }

.progress-brown.progress-lighten-1 {
  background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #8d6e63; }
  .progress-brown.progress-lighten-1[value]::-ms-fill {
    background-color: #8d6e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-lighten-1 .progress-bar {
      background-color: #8d6e63; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8d6e63 !important; }

.border-brown.border-lighten-1 {
  border: 1px solid #8d6e63 !important; }

.border-top-brown.border-top-lighten-1 {
  border-top: 1px solid #8d6e63 !important; }

.border-bottom-brown.border-bottom-lighten-1 {
  border-bottom: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-left-brown.border-left-lighten-1 {
  border-left: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-right-brown.border-right-lighten-1 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-left-brown.border-left-lighten-1 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-right-brown.border-right-lighten-1 {
  border-left: 1px solid #8d6e63 !important; }

.overlay-brown.overlay-lighten-1 {
  background: #8d6e63;
  /* The Fallback */
  background: rgba(141, 110, 99, 0.8); }

.brown {
  color: #795548 !important; }

.bg-brown {
  background-color: #795548 !important; }
  .bg-brown .card-header, .bg-brown .card-footer {
    background-color: transparent; }

.toast-brown {
  background-color: #795548; }

.alert-brown {
  border-color: #795548 !important;
  background-color: #8f6555 !important;
  color: #090605 !important; }
  .alert-brown .alert-link {
    color: black !important; }

.border-brown {
  border-color: #795548; }

.border-top-brown {
  border-top-color: #795548; }

.border-bottom-brown {
  border-bottom-color: #795548; }

[dir="ltr"] .border-left-brown {
  border-left-color: #795548; }

[dir="ltr"] .border-right-brown {
  border-right-color: #795548; }

[dir="rtl"] .border-left-brown {
  border-right-color: #795548; }

[dir="rtl"] .border-right-brown {
  border-left-color: #795548; }

.badge-brown {
  background-color: #795548; }

.panel-brown {
  border-color: #795548; }
  .panel-brown .panel-heading {
    color: #FFF;
    border-color: #795548;
    background-color: #896052; }

.bg-brown.tag-glow, .border-brown.tag-glow {
  box-shadow: 0px 0px 10px #795548; }

.overlay-brown {
  background: #795548;
  /* The Fallback */
  background: rgba(121, 85, 72, 0.8); }

.card.card-outline-brown {
  border-width: 1px;
  border-style: solid;
  border-color: #795548;
  background-color: transparent; }
  .card.card-outline-brown .card-header, .card.card-outline-brown .card-footer {
    background-color: transparent; }

.btn-brown.btn-flat {
  background-color: transparent !important;
  color: #795548;
  border: none; }

.btn-brown.btn-raised, .btn-brown.btn-fab {
  background-color: #795548 !important;
  color: #fff !important;
  border-color: #795548; }
  .btn-brown.btn-raised.active, .btn-brown.btn-fab.active {
    background-color: #63453b !important;
    border-color: #63453b !important; }

.btn-group-raised .btn-brown {
  background-color: #795548 !important;
  color: #fff !important; }

.btn-outline-brown {
  border: 1px solid;
  border-color: #795548;
  background-color: transparent;
  color: #795548;
  box-shadow: none !important; }
  .btn-outline-brown:focus {
    background-color: transparent !important;
    color: #795548 !important;
    box-shadow: transparent !important; }
  .btn-outline-brown.active {
    background-color: #795548 !important;
    color: #FFF !important; }
  .btn-outline-brown:hover {
    background-color: #6f4e42 !important;
    color: #FFF !important; }
    .btn-outline-brown:hover svg {
      color: #FFF !important; }

.btn-outline-brown.btn-raised, .btn-outline-brown.btn-fab {
  border: 1px solid;
  border-color: #795548;
  background-color: transparent;
  color: #795548;
  box-shadow: none !important; }
  .btn-outline-brown.btn-raised:focus, .btn-outline-brown.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-brown.btn-raised.active, .btn-outline-brown.btn-fab.active {
    background-color: #795548 !important;
    color: #FFF !important; }
  .btn-outline-brown.btn-raised:hover, .btn-outline-brown.btn-fab:hover {
    background-color: #6f4e42 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-brown {
  background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-webkit-progress-value {
    background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-moz-progress-bar {
    background-color: #795548; }
  .progress .progress-bar.progress-bar-brown[value]::-ms-fill {
    background-color: #795548; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-brown .progress-bar {
      background-color: #795548; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #795548 !important; }

.brown.darken-1 {
  color: #6d4c41 !important; }

.bg-brown.bg-darken-1 {
  background-color: #6d4c41 !important; }

.btn-brown.btn-darken-1 {
  border-color: #5d4037 !important;
  background-color: #6d4c41 !important; }
  .btn-brown.btn-darken-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-1:focus, .btn-brown.btn-darken-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-1 {
  border-color: #6d4c41 !important;
  color: #6d4c41 !important; }
  .btn-outline-brown.btn-outline-darken-1:hover {
    background-color: #6d4c41 !important; }

.progress-brown.progress-darken-1 {
  background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-webkit-progress-value {
    background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-moz-progress-bar {
    background-color: #6d4c41; }
  .progress-brown.progress-darken-1[value]::-ms-fill {
    background-color: #6d4c41; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-1 .progress-bar {
      background-color: #6d4c41; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #6d4c41 !important; }

.border-brown.border-darken-1 {
  border: 1px solid #6d4c41 !important; }

.border-top-brown.border-top-darken-1 {
  border-top: 1px solid #6d4c41 !important; }

.border-bottom-brown.border-bottom-darken-1 {
  border-bottom: 1px solid #6d4c41 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-1 {
  border-left: 1px solid #6d4c41 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-1 {
  border-right: 1px solid #6d4c41 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-1 {
  border-right: 1px solid #6d4c41 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-1 {
  border-left: 1px solid #6d4c41 !important; }

.overlay-brown.overlay-darken-1 {
  background: #6d4c41;
  /* The Fallback */
  background: rgba(109, 76, 65, 0.8); }

.brown.darken-2 {
  color: #5d4037 !important; }

.bg-brown.bg-darken-2 {
  background-color: #5d4037 !important; }

.btn-brown.btn-darken-2 {
  border-color: #5d4037 !important;
  background-color: #5d4037 !important; }
  .btn-brown.btn-darken-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-2:focus, .btn-brown.btn-darken-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-2 {
  border-color: #5d4037 !important;
  color: #5d4037 !important; }
  .btn-outline-brown.btn-outline-darken-2:hover {
    background-color: #5d4037 !important; }

.progress-brown.progress-darken-2 {
  background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-webkit-progress-value {
    background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-moz-progress-bar {
    background-color: #5d4037; }
  .progress-brown.progress-darken-2[value]::-ms-fill {
    background-color: #5d4037; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-2 .progress-bar {
      background-color: #5d4037; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5d4037 !important; }

.border-brown.border-darken-2 {
  border: 1px solid #5d4037 !important; }

.border-top-brown.border-top-darken-2 {
  border-top: 1px solid #5d4037 !important; }

.border-bottom-brown.border-bottom-darken-2 {
  border-bottom: 1px solid #5d4037 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-2 {
  border-left: 1px solid #5d4037 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-2 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-2 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-2 {
  border-left: 1px solid #5d4037 !important; }

.overlay-brown.overlay-darken-2 {
  background: #5d4037;
  /* The Fallback */
  background: rgba(93, 64, 55, 0.8); }

.brown.darken-3 {
  color: #4e342e !important; }

.bg-brown.bg-darken-3 {
  background-color: #4e342e !important; }

.btn-brown.btn-darken-3 {
  border-color: #5d4037 !important;
  background-color: #4e342e !important; }
  .btn-brown.btn-darken-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-3:focus, .btn-brown.btn-darken-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-3 {
  border-color: #4e342e !important;
  color: #4e342e !important; }
  .btn-outline-brown.btn-outline-darken-3:hover {
    background-color: #4e342e !important; }

.progress-brown.progress-darken-3 {
  background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-webkit-progress-value {
    background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-moz-progress-bar {
    background-color: #4e342e; }
  .progress-brown.progress-darken-3[value]::-ms-fill {
    background-color: #4e342e; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-3 .progress-bar {
      background-color: #4e342e; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #4e342e !important; }

.border-brown.border-darken-3 {
  border: 1px solid #4e342e !important; }

.border-top-brown.border-top-darken-3 {
  border-top: 1px solid #4e342e !important; }

.border-bottom-brown.border-bottom-darken-3 {
  border-bottom: 1px solid #4e342e !important; }

[dir="ltr"] .border-left-brown.border-left-darken-3 {
  border-left: 1px solid #4e342e !important; }

[dir="ltr"] .border-right-brown.border-right-darken-3 {
  border-right: 1px solid #4e342e !important; }

[dir="rtl"] .border-left-brown.border-left-darken-3 {
  border-right: 1px solid #4e342e !important; }

[dir="rtl"] .border-right-brown.border-right-darken-3 {
  border-left: 1px solid #4e342e !important; }

.overlay-brown.overlay-darken-3 {
  background: #4e342e;
  /* The Fallback */
  background: rgba(78, 52, 46, 0.8); }

.brown.darken-4 {
  color: #3e2723 !important; }

.bg-brown.bg-darken-4 {
  background-color: #3e2723 !important; }

.btn-brown.btn-darken-4 {
  border-color: #5d4037 !important;
  background-color: #3e2723 !important; }
  .btn-brown.btn-darken-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-darken-4:focus, .btn-brown.btn-darken-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-darken-4 {
  border-color: #3e2723 !important;
  color: #3e2723 !important; }
  .btn-outline-brown.btn-outline-darken-4:hover {
    background-color: #3e2723 !important; }

.progress-brown.progress-darken-4 {
  background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-webkit-progress-value {
    background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-moz-progress-bar {
    background-color: #3e2723; }
  .progress-brown.progress-darken-4[value]::-ms-fill {
    background-color: #3e2723; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-darken-4 .progress-bar {
      background-color: #3e2723; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #3e2723 !important; }

.border-brown.border-darken-4 {
  border: 1px solid #3e2723 !important; }

.border-top-brown.border-top-darken-4 {
  border-top: 1px solid #3e2723 !important; }

.border-bottom-brown.border-bottom-darken-4 {
  border-bottom: 1px solid #3e2723 !important; }

[dir="ltr"] .border-left-brown.border-left-darken-4 {
  border-left: 1px solid #3e2723 !important; }

[dir="ltr"] .border-right-brown.border-right-darken-4 {
  border-right: 1px solid #3e2723 !important; }

[dir="rtl"] .border-left-brown.border-left-darken-4 {
  border-right: 1px solid #3e2723 !important; }

[dir="rtl"] .border-right-brown.border-right-darken-4 {
  border-left: 1px solid #3e2723 !important; }

.overlay-brown.overlay-darken-4 {
  background: #3e2723;
  /* The Fallback */
  background: rgba(62, 39, 35, 0.8); }

.brown.accent-1 {
  color: #d7ccc8 !important; }

.bg-brown.bg-accent-1 {
  background-color: #d7ccc8 !important; }

.btn-brown.btn-accent-1 {
  border-color: #5d4037 !important;
  background-color: #d7ccc8 !important; }
  .btn-brown.btn-accent-1:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-1:focus, .btn-brown.btn-accent-1:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-1 {
  border-color: #d7ccc8 !important;
  color: #d7ccc8 !important; }
  .btn-outline-brown.btn-outline-accent-1:hover {
    background-color: #d7ccc8 !important; }

.progress-brown.progress-accent-1 {
  background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-webkit-progress-value {
    background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-moz-progress-bar {
    background-color: #d7ccc8; }
  .progress-brown.progress-accent-1[value]::-ms-fill {
    background-color: #d7ccc8; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-1 .progress-bar {
      background-color: #d7ccc8; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #d7ccc8 !important; }

.border-brown.border-accent-1 {
  border: 1px solid #d7ccc8 !important; }

.border-top-brown.border-top-accent-1 {
  border-top: 1px solid #d7ccc8 !important; }

.border-bottom-brown.border-bottom-accent-1 {
  border-bottom: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-1 {
  border-left: 1px solid #d7ccc8 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-1 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-1 {
  border-right: 1px solid #d7ccc8 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-1 {
  border-left: 1px solid #d7ccc8 !important; }

.overlay-brown.overlay-accent-1 {
  background: #d7ccc8;
  /* The Fallback */
  background: rgba(215, 204, 200, 0.8); }

.brown.accent-2 {
  color: #bcaaa4 !important; }

.bg-brown.bg-accent-2 {
  background-color: #bcaaa4 !important; }

.btn-brown.btn-accent-2 {
  border-color: #5d4037 !important;
  background-color: #bcaaa4 !important; }
  .btn-brown.btn-accent-2:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-2:focus, .btn-brown.btn-accent-2:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-2 {
  border-color: #bcaaa4 !important;
  color: #bcaaa4 !important; }
  .btn-outline-brown.btn-outline-accent-2:hover {
    background-color: #bcaaa4 !important; }

.progress-brown.progress-accent-2 {
  background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-webkit-progress-value {
    background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-moz-progress-bar {
    background-color: #bcaaa4; }
  .progress-brown.progress-accent-2[value]::-ms-fill {
    background-color: #bcaaa4; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-2 .progress-bar {
      background-color: #bcaaa4; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bcaaa4 !important; }

.border-brown.border-accent-2 {
  border: 1px solid #bcaaa4 !important; }

.border-top-brown.border-top-accent-2 {
  border-top: 1px solid #bcaaa4 !important; }

.border-bottom-brown.border-bottom-accent-2 {
  border-bottom: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-2 {
  border-left: 1px solid #bcaaa4 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-2 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-2 {
  border-right: 1px solid #bcaaa4 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-2 {
  border-left: 1px solid #bcaaa4 !important; }

.overlay-brown.overlay-accent-2 {
  background: #bcaaa4;
  /* The Fallback */
  background: rgba(188, 170, 164, 0.8); }

.brown.accent-3 {
  color: #8d6e63 !important; }

.bg-brown.bg-accent-3 {
  background-color: #8d6e63 !important; }

.btn-brown.btn-accent-3 {
  border-color: #5d4037 !important;
  background-color: #8d6e63 !important; }
  .btn-brown.btn-accent-3:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-3:focus, .btn-brown.btn-accent-3:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-3 {
  border-color: #8d6e63 !important;
  color: #8d6e63 !important; }
  .btn-outline-brown.btn-outline-accent-3:hover {
    background-color: #8d6e63 !important; }

.progress-brown.progress-accent-3 {
  background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-webkit-progress-value {
    background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-moz-progress-bar {
    background-color: #8d6e63; }
  .progress-brown.progress-accent-3[value]::-ms-fill {
    background-color: #8d6e63; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-3 .progress-bar {
      background-color: #8d6e63; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #8d6e63 !important; }

.border-brown.border-accent-3 {
  border: 1px solid #8d6e63 !important; }

.border-top-brown.border-top-accent-3 {
  border-top: 1px solid #8d6e63 !important; }

.border-bottom-brown.border-bottom-accent-3 {
  border-bottom: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-3 {
  border-left: 1px solid #8d6e63 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-3 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-3 {
  border-right: 1px solid #8d6e63 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-3 {
  border-left: 1px solid #8d6e63 !important; }

.overlay-brown.overlay-accent-3 {
  background: #8d6e63;
  /* The Fallback */
  background: rgba(141, 110, 99, 0.8); }

.brown.accent-4 {
  color: #5d4037 !important; }

.bg-brown.bg-accent-4 {
  background-color: #5d4037 !important; }

.btn-brown.btn-accent-4 {
  border-color: #5d4037 !important;
  background-color: #5d4037 !important; }
  .btn-brown.btn-accent-4:hover {
    border-color: #5d4037 !important;
    background-color: #4e342e !important; }
  .btn-brown.btn-accent-4:focus, .btn-brown.btn-accent-4:active {
    border-color: #4e342e !important;
    background-color: #3e2723 !important; }

.btn-outline-brown.btn-outline-accent-4 {
  border-color: #5d4037 !important;
  color: #5d4037 !important; }
  .btn-outline-brown.btn-outline-accent-4:hover {
    background-color: #5d4037 !important; }

.progress-brown.progress-accent-4 {
  background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-webkit-progress-value {
    background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-moz-progress-bar {
    background-color: #5d4037; }
  .progress-brown.progress-accent-4[value]::-ms-fill {
    background-color: #5d4037; }
  @media screen and (min-width: 0 \0 ) {
    .progress-brown.progress-accent-4 .progress-bar {
      background-color: #5d4037; } }

input:focus ~ .bg-brown {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #5d4037 !important; }

.border-brown.border-accent-4 {
  border: 1px solid #5d4037 !important; }

.border-top-brown.border-top-accent-4 {
  border-top: 1px solid #5d4037 !important; }

.border-bottom-brown.border-bottom-accent-4 {
  border-bottom: 1px solid #5d4037 !important; }

[dir="ltr"] .border-left-brown.border-left-accent-4 {
  border-left: 1px solid #5d4037 !important; }

[dir="ltr"] .border-right-brown.border-right-accent-4 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-left-brown.border-left-accent-4 {
  border-right: 1px solid #5d4037 !important; }

[dir="rtl"] .border-right-brown.border-right-accent-4 {
  border-left: 1px solid #5d4037 !important; }

.overlay-brown.overlay-accent-4 {
  background: #5d4037;
  /* The Fallback */
  background: rgba(93, 64, 55, 0.8); }

.grey.lighten-5 {
  color: #fafafa !important; }

.bg-grey.bg-lighten-5 {
  background-color: #fafafa !important; }

.btn-grey.btn-lighten-5 {
  border-color: #616161 !important;
  background-color: #fafafa !important; }
  .btn-grey.btn-lighten-5:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-5:focus, .btn-grey.btn-lighten-5:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-5 {
  border-color: #fafafa !important;
  color: #fafafa !important; }
  .btn-outline-grey.btn-outline-lighten-5:hover {
    background-color: #fafafa !important; }

.progress-grey.progress-lighten-5 {
  background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #fafafa; }
  .progress-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #fafafa; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-5 .progress-bar {
      background-color: #fafafa; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #fafafa !important; }

.border-grey.border-lighten-5 {
  border: 1px solid #fafafa !important; }

.border-top-grey.border-top-lighten-5 {
  border-top: 1px solid #fafafa !important; }

.border-bottom-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #fafafa !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-5 {
  border-left: 1px solid #fafafa !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-5 {
  border-right: 1px solid #fafafa !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-5 {
  border-right: 1px solid #fafafa !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-5 {
  border-left: 1px solid #fafafa !important; }

.overlay-grey.overlay-lighten-5 {
  background: #fafafa;
  /* The Fallback */
  background: rgba(250, 250, 250, 0.8); }

.grey.lighten-4 {
  color: #f5f5f5 !important; }

.bg-grey.bg-lighten-4 {
  background-color: #f5f5f5 !important; }

.btn-grey.btn-lighten-4 {
  border-color: #616161 !important;
  background-color: #f5f5f5 !important; }
  .btn-grey.btn-lighten-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-4:focus, .btn-grey.btn-lighten-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-4 {
  border-color: #f5f5f5 !important;
  color: #f5f5f5 !important; }
  .btn-outline-grey.btn-outline-lighten-4:hover {
    background-color: #f5f5f5 !important; }

.progress-grey.progress-lighten-4 {
  background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #f5f5f5; }
  .progress-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #f5f5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-4 .progress-bar {
      background-color: #f5f5f5; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f5f5f5 !important; }

.border-grey.border-lighten-4 {
  border: 1px solid #f5f5f5 !important; }

.border-top-grey.border-top-lighten-4 {
  border-top: 1px solid #f5f5f5 !important; }

.border-bottom-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-4 {
  border-left: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-4 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-4 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-4 {
  border-left: 1px solid #f5f5f5 !important; }

.overlay-grey.overlay-lighten-4 {
  background: #f5f5f5;
  /* The Fallback */
  background: rgba(245, 245, 245, 0.8); }

.grey.lighten-3 {
  color: #eee !important; }

.bg-grey.bg-lighten-3 {
  background-color: #eee !important; }

.btn-grey.btn-lighten-3 {
  border-color: #616161 !important;
  background-color: #eee !important; }
  .btn-grey.btn-lighten-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-3:focus, .btn-grey.btn-lighten-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-3 {
  border-color: #eee !important;
  color: #eee !important; }
  .btn-outline-grey.btn-outline-lighten-3:hover {
    background-color: #eee !important; }

.progress-grey.progress-lighten-3 {
  background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #eee; }
  .progress-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #eee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-3 .progress-bar {
      background-color: #eee; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eee !important; }

.border-grey.border-lighten-3 {
  border: 1px solid #eee !important; }

.border-top-grey.border-top-lighten-3 {
  border-top: 1px solid #eee !important; }

.border-bottom-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #eee !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-3 {
  border-left: 1px solid #eee !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-3 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-3 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-3 {
  border-left: 1px solid #eee !important; }

.overlay-grey.overlay-lighten-3 {
  background: #eee;
  /* The Fallback */
  background: rgba(238, 238, 238, 0.8); }

.grey.lighten-2 {
  color: #e0e0e0 !important; }

.bg-grey.bg-lighten-2 {
  background-color: #e0e0e0 !important; }

.btn-grey.btn-lighten-2 {
  border-color: #616161 !important;
  background-color: #e0e0e0 !important; }
  .btn-grey.btn-lighten-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-2:focus, .btn-grey.btn-lighten-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-2 {
  border-color: #e0e0e0 !important;
  color: #e0e0e0 !important; }
  .btn-outline-grey.btn-outline-lighten-2:hover {
    background-color: #e0e0e0 !important; }

.progress-grey.progress-lighten-2 {
  background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #e0e0e0; }
  .progress-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #e0e0e0; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-2 .progress-bar {
      background-color: #e0e0e0; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #e0e0e0 !important; }

.border-grey.border-lighten-2 {
  border: 1px solid #e0e0e0 !important; }

.border-top-grey.border-top-lighten-2 {
  border-top: 1px solid #e0e0e0 !important; }

.border-bottom-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #e0e0e0 !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-2 {
  border-left: 1px solid #e0e0e0 !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-2 {
  border-right: 1px solid #e0e0e0 !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-2 {
  border-right: 1px solid #e0e0e0 !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-2 {
  border-left: 1px solid #e0e0e0 !important; }

.overlay-grey.overlay-lighten-2 {
  background: #e0e0e0;
  /* The Fallback */
  background: rgba(224, 224, 224, 0.8); }

.grey.lighten-1 {
  color: #bdbdbd !important; }

.bg-grey.bg-lighten-1 {
  background-color: #bdbdbd !important; }

.btn-grey.btn-lighten-1 {
  border-color: #616161 !important;
  background-color: #bdbdbd !important; }
  .btn-grey.btn-lighten-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-lighten-1:focus, .btn-grey.btn-lighten-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-lighten-1 {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important; }
  .btn-outline-grey.btn-outline-lighten-1:hover {
    background-color: #bdbdbd !important; }

.progress-grey.progress-lighten-1 {
  background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #bdbdbd; }
  .progress-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #bdbdbd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-lighten-1 .progress-bar {
      background-color: #bdbdbd; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bdbdbd !important; }

.border-grey.border-lighten-1 {
  border: 1px solid #bdbdbd !important; }

.border-top-grey.border-top-lighten-1 {
  border-top: 1px solid #bdbdbd !important; }

.border-bottom-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-left-grey.border-left-lighten-1 {
  border-left: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-right-grey.border-right-lighten-1 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-left-grey.border-left-lighten-1 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-right-grey.border-right-lighten-1 {
  border-left: 1px solid #bdbdbd !important; }

.overlay-grey.overlay-lighten-1 {
  background: #bdbdbd;
  /* The Fallback */
  background: rgba(189, 189, 189, 0.8); }

.grey {
  color: #9e9e9e !important; }

.bg-grey {
  background-color: #9e9e9e !important; }
  .bg-grey .card-header, .bg-grey .card-footer {
    background-color: transparent; }

.toast-grey {
  background-color: #9e9e9e; }

.alert-grey {
  border-color: #9e9e9e !important;
  background-color: #b0b0b0 !important;
  color: #454545 !important; }
  .alert-grey .alert-link {
    color: #303030 !important; }

.border-grey {
  border-color: #9e9e9e; }

.border-top-grey {
  border-top-color: #9e9e9e; }

.border-bottom-grey {
  border-bottom-color: #9e9e9e; }

[dir="ltr"] .border-left-grey {
  border-left-color: #9e9e9e; }

[dir="ltr"] .border-right-grey {
  border-right-color: #9e9e9e; }

[dir="rtl"] .border-left-grey {
  border-right-color: #9e9e9e; }

[dir="rtl"] .border-right-grey {
  border-left-color: #9e9e9e; }

.badge-grey {
  background-color: #9e9e9e; }

.panel-grey {
  border-color: #9e9e9e; }
  .panel-grey .panel-heading {
    color: #FFF;
    border-color: #9e9e9e;
    background-color: #ababab; }

.bg-grey.tag-glow, .border-grey.tag-glow {
  box-shadow: 0px 0px 10px #9e9e9e; }

.overlay-grey {
  background: #9e9e9e;
  /* The Fallback */
  background: rgba(158, 158, 158, 0.8); }

.card.card-outline-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #9e9e9e;
  background-color: transparent; }
  .card.card-outline-grey .card-header, .card.card-outline-grey .card-footer {
    background-color: transparent; }

.btn-grey.btn-flat {
  background-color: transparent !important;
  color: #9e9e9e;
  border: none; }

.btn-grey.btn-raised, .btn-grey.btn-fab {
  background-color: #9e9e9e !important;
  color: #fff !important;
  border-color: #9e9e9e; }
  .btn-grey.btn-raised.active, .btn-grey.btn-fab.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important; }

.btn-group-raised .btn-grey {
  background-color: #9e9e9e !important;
  color: #fff !important; }

.btn-outline-grey {
  border: 1px solid;
  border-color: #9e9e9e;
  background-color: transparent;
  color: #9e9e9e;
  box-shadow: none !important; }
  .btn-outline-grey:focus {
    background-color: transparent !important;
    color: #9e9e9e !important;
    box-shadow: transparent !important; }
  .btn-outline-grey.active {
    background-color: #9e9e9e !important;
    color: #FFF !important; }
  .btn-outline-grey:hover {
    background-color: #969696 !important;
    color: #FFF !important; }
    .btn-outline-grey:hover svg {
      color: #FFF !important; }

.btn-outline-grey.btn-raised, .btn-outline-grey.btn-fab {
  border: 1px solid;
  border-color: #9e9e9e;
  background-color: transparent;
  color: #9e9e9e;
  box-shadow: none !important; }
  .btn-outline-grey.btn-raised:focus, .btn-outline-grey.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-grey.btn-raised.active, .btn-outline-grey.btn-fab.active {
    background-color: #9e9e9e !important;
    color: #FFF !important; }
  .btn-outline-grey.btn-raised:hover, .btn-outline-grey.btn-fab:hover {
    background-color: #969696 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-grey {
  background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-webkit-progress-value {
    background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-moz-progress-bar {
    background-color: #9e9e9e; }
  .progress .progress-bar.progress-bar-grey[value]::-ms-fill {
    background-color: #9e9e9e; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-grey .progress-bar {
      background-color: #9e9e9e; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #9e9e9e !important; }

.grey.darken-1 {
  color: #757575 !important; }

.bg-grey.bg-darken-1 {
  background-color: #757575 !important; }

.btn-grey.btn-darken-1 {
  border-color: #616161 !important;
  background-color: #757575 !important; }
  .btn-grey.btn-darken-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-1:focus, .btn-grey.btn-darken-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-1 {
  border-color: #757575 !important;
  color: #757575 !important; }
  .btn-outline-grey.btn-outline-darken-1:hover {
    background-color: #757575 !important; }

.progress-grey.progress-darken-1 {
  background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #757575; }
  .progress-grey.progress-darken-1[value]::-ms-fill {
    background-color: #757575; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-1 .progress-bar {
      background-color: #757575; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #757575 !important; }

.border-grey.border-darken-1 {
  border: 1px solid #757575 !important; }

.border-top-grey.border-top-darken-1 {
  border-top: 1px solid #757575 !important; }

.border-bottom-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #757575 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-1 {
  border-left: 1px solid #757575 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-1 {
  border-right: 1px solid #757575 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-1 {
  border-right: 1px solid #757575 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-1 {
  border-left: 1px solid #757575 !important; }

.overlay-grey.overlay-darken-1 {
  background: #757575;
  /* The Fallback */
  background: rgba(117, 117, 117, 0.8); }

.grey.darken-2 {
  color: #616161 !important; }

.bg-grey.bg-darken-2 {
  background-color: #616161 !important; }

.btn-grey.btn-darken-2 {
  border-color: #616161 !important;
  background-color: #616161 !important; }
  .btn-grey.btn-darken-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-2:focus, .btn-grey.btn-darken-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-2 {
  border-color: #616161 !important;
  color: #616161 !important; }
  .btn-outline-grey.btn-outline-darken-2:hover {
    background-color: #616161 !important; }

.progress-grey.progress-darken-2 {
  background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #616161; }
  .progress-grey.progress-darken-2[value]::-ms-fill {
    background-color: #616161; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-2 .progress-bar {
      background-color: #616161; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important; }

.border-grey.border-darken-2 {
  border: 1px solid #616161 !important; }

.border-top-grey.border-top-darken-2 {
  border-top: 1px solid #616161 !important; }

.border-bottom-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #616161 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-2 {
  border-left: 1px solid #616161 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-2 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-2 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-2 {
  border-left: 1px solid #616161 !important; }

.overlay-grey.overlay-darken-2 {
  background: #616161;
  /* The Fallback */
  background: rgba(97, 97, 97, 0.8); }

.grey.darken-3 {
  color: #424242 !important; }

.bg-grey.bg-darken-3 {
  background-color: #424242 !important; }

.btn-grey.btn-darken-3 {
  border-color: #616161 !important;
  background-color: #424242 !important; }
  .btn-grey.btn-darken-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-3:focus, .btn-grey.btn-darken-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-3 {
  border-color: #424242 !important;
  color: #424242 !important; }
  .btn-outline-grey.btn-outline-darken-3:hover {
    background-color: #424242 !important; }

.progress-grey.progress-darken-3 {
  background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #424242; }
  .progress-grey.progress-darken-3[value]::-ms-fill {
    background-color: #424242; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-3 .progress-bar {
      background-color: #424242; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #424242 !important; }

.border-grey.border-darken-3 {
  border: 1px solid #424242 !important; }

.border-top-grey.border-top-darken-3 {
  border-top: 1px solid #424242 !important; }

.border-bottom-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #424242 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-3 {
  border-left: 1px solid #424242 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-3 {
  border-right: 1px solid #424242 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-3 {
  border-right: 1px solid #424242 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-3 {
  border-left: 1px solid #424242 !important; }

.overlay-grey.overlay-darken-3 {
  background: #424242;
  /* The Fallback */
  background: rgba(66, 66, 66, 0.8); }

.grey.darken-4 {
  color: #212121 !important; }

.bg-grey.bg-darken-4 {
  background-color: #212121 !important; }

.btn-grey.btn-darken-4 {
  border-color: #616161 !important;
  background-color: #212121 !important; }
  .btn-grey.btn-darken-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-darken-4:focus, .btn-grey.btn-darken-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-darken-4 {
  border-color: #212121 !important;
  color: #212121 !important; }
  .btn-outline-grey.btn-outline-darken-4:hover {
    background-color: #212121 !important; }

.progress-grey.progress-darken-4 {
  background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #212121; }
  .progress-grey.progress-darken-4[value]::-ms-fill {
    background-color: #212121; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-darken-4 .progress-bar {
      background-color: #212121; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #212121 !important; }

.border-grey.border-darken-4 {
  border: 1px solid #212121 !important; }

.border-top-grey.border-top-darken-4 {
  border-top: 1px solid #212121 !important; }

.border-bottom-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #212121 !important; }

[dir="ltr"] .border-left-grey.border-left-darken-4 {
  border-left: 1px solid #212121 !important; }

[dir="ltr"] .border-right-grey.border-right-darken-4 {
  border-right: 1px solid #212121 !important; }

[dir="rtl"] .border-left-grey.border-left-darken-4 {
  border-right: 1px solid #212121 !important; }

[dir="rtl"] .border-right-grey.border-right-darken-4 {
  border-left: 1px solid #212121 !important; }

.overlay-grey.overlay-darken-4 {
  background: #212121;
  /* The Fallback */
  background: rgba(33, 33, 33, 0.8); }

.grey.accent-1 {
  color: #f5f5f5 !important; }

.bg-grey.bg-accent-1 {
  background-color: #f5f5f5 !important; }

.btn-grey.btn-accent-1 {
  border-color: #616161 !important;
  background-color: #f5f5f5 !important; }
  .btn-grey.btn-accent-1:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-1:focus, .btn-grey.btn-accent-1:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-1 {
  border-color: #f5f5f5 !important;
  color: #f5f5f5 !important; }
  .btn-outline-grey.btn-outline-accent-1:hover {
    background-color: #f5f5f5 !important; }

.progress-grey.progress-accent-1 {
  background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #f5f5f5; }
  .progress-grey.progress-accent-1[value]::-ms-fill {
    background-color: #f5f5f5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-1 .progress-bar {
      background-color: #f5f5f5; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #f5f5f5 !important; }

.border-grey.border-accent-1 {
  border: 1px solid #f5f5f5 !important; }

.border-top-grey.border-top-accent-1 {
  border-top: 1px solid #f5f5f5 !important; }

.border-bottom-grey.border-bottom-accent-1 {
  border-bottom: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-left-grey.border-left-accent-1 {
  border-left: 1px solid #f5f5f5 !important; }

[dir="ltr"] .border-right-grey.border-right-accent-1 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-left-grey.border-left-accent-1 {
  border-right: 1px solid #f5f5f5 !important; }

[dir="rtl"] .border-right-grey.border-right-accent-1 {
  border-left: 1px solid #f5f5f5 !important; }

.overlay-grey.overlay-accent-1 {
  background: #f5f5f5;
  /* The Fallback */
  background: rgba(245, 245, 245, 0.8); }

.grey.accent-2 {
  color: #eee !important; }

.bg-grey.bg-accent-2 {
  background-color: #eee !important; }

.btn-grey.btn-accent-2 {
  border-color: #616161 !important;
  background-color: #eee !important; }
  .btn-grey.btn-accent-2:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-2:focus, .btn-grey.btn-accent-2:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-2 {
  border-color: #eee !important;
  color: #eee !important; }
  .btn-outline-grey.btn-outline-accent-2:hover {
    background-color: #eee !important; }

.progress-grey.progress-accent-2 {
  background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #eee; }
  .progress-grey.progress-accent-2[value]::-ms-fill {
    background-color: #eee; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-2 .progress-bar {
      background-color: #eee; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eee !important; }

.border-grey.border-accent-2 {
  border: 1px solid #eee !important; }

.border-top-grey.border-top-accent-2 {
  border-top: 1px solid #eee !important; }

.border-bottom-grey.border-bottom-accent-2 {
  border-bottom: 1px solid #eee !important; }

[dir="ltr"] .border-left-grey.border-left-accent-2 {
  border-left: 1px solid #eee !important; }

[dir="ltr"] .border-right-grey.border-right-accent-2 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-left-grey.border-left-accent-2 {
  border-right: 1px solid #eee !important; }

[dir="rtl"] .border-right-grey.border-right-accent-2 {
  border-left: 1px solid #eee !important; }

.overlay-grey.overlay-accent-2 {
  background: #eee;
  /* The Fallback */
  background: rgba(238, 238, 238, 0.8); }

.grey.accent-3 {
  color: #bdbdbd !important; }

.bg-grey.bg-accent-3 {
  background-color: #bdbdbd !important; }

.btn-grey.btn-accent-3 {
  border-color: #616161 !important;
  background-color: #bdbdbd !important; }
  .btn-grey.btn-accent-3:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-3:focus, .btn-grey.btn-accent-3:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-3 {
  border-color: #bdbdbd !important;
  color: #bdbdbd !important; }
  .btn-outline-grey.btn-outline-accent-3:hover {
    background-color: #bdbdbd !important; }

.progress-grey.progress-accent-3 {
  background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #bdbdbd; }
  .progress-grey.progress-accent-3[value]::-ms-fill {
    background-color: #bdbdbd; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-3 .progress-bar {
      background-color: #bdbdbd; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #bdbdbd !important; }

.border-grey.border-accent-3 {
  border: 1px solid #bdbdbd !important; }

.border-top-grey.border-top-accent-3 {
  border-top: 1px solid #bdbdbd !important; }

.border-bottom-grey.border-bottom-accent-3 {
  border-bottom: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-left-grey.border-left-accent-3 {
  border-left: 1px solid #bdbdbd !important; }

[dir="ltr"] .border-right-grey.border-right-accent-3 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-left-grey.border-left-accent-3 {
  border-right: 1px solid #bdbdbd !important; }

[dir="rtl"] .border-right-grey.border-right-accent-3 {
  border-left: 1px solid #bdbdbd !important; }

.overlay-grey.overlay-accent-3 {
  background: #bdbdbd;
  /* The Fallback */
  background: rgba(189, 189, 189, 0.8); }

.grey.accent-4 {
  color: #616161 !important; }

.bg-grey.bg-accent-4 {
  background-color: #616161 !important; }

.btn-grey.btn-accent-4 {
  border-color: #616161 !important;
  background-color: #616161 !important; }
  .btn-grey.btn-accent-4:hover {
    border-color: #616161 !important;
    background-color: #424242 !important; }
  .btn-grey.btn-accent-4:focus, .btn-grey.btn-accent-4:active {
    border-color: #424242 !important;
    background-color: #212121 !important; }

.btn-outline-grey.btn-outline-accent-4 {
  border-color: #616161 !important;
  color: #616161 !important; }
  .btn-outline-grey.btn-outline-accent-4:hover {
    background-color: #616161 !important; }

.progress-grey.progress-accent-4 {
  background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #616161; }
  .progress-grey.progress-accent-4[value]::-ms-fill {
    background-color: #616161; }
  @media screen and (min-width: 0 \0 ) {
    .progress-grey.progress-accent-4 .progress-bar {
      background-color: #616161; } }

input:focus ~ .bg-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #616161 !important; }

.border-grey.border-accent-4 {
  border: 1px solid #616161 !important; }

.border-top-grey.border-top-accent-4 {
  border-top: 1px solid #616161 !important; }

.border-bottom-grey.border-bottom-accent-4 {
  border-bottom: 1px solid #616161 !important; }

[dir="ltr"] .border-left-grey.border-left-accent-4 {
  border-left: 1px solid #616161 !important; }

[dir="ltr"] .border-right-grey.border-right-accent-4 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-left-grey.border-left-accent-4 {
  border-right: 1px solid #616161 !important; }

[dir="rtl"] .border-right-grey.border-right-accent-4 {
  border-left: 1px solid #616161 !important; }

.overlay-grey.overlay-accent-4 {
  background: #616161;
  /* The Fallback */
  background: rgba(97, 97, 97, 0.8); }

.blue-grey.lighten-5 {
  color: #eceff1 !important; }

.bg-blue-grey.bg-lighten-5 {
  background-color: #eceff1 !important; }

.btn-blue-grey.btn-lighten-5 {
  border-color: #455a64 !important;
  background-color: #eceff1 !important; }
  .btn-blue-grey.btn-lighten-5:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-5:focus, .btn-blue-grey.btn-lighten-5:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-5 {
  border-color: #eceff1 !important;
  color: #eceff1 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-5:hover {
    background-color: #eceff1 !important; }

.progress-blue-grey.progress-lighten-5 {
  background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-webkit-progress-value {
    background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-moz-progress-bar {
    background-color: #eceff1; }
  .progress-blue-grey.progress-lighten-5[value]::-ms-fill {
    background-color: #eceff1; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-5 .progress-bar {
      background-color: #eceff1; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #eceff1 !important; }

.border-blue-grey.border-lighten-5 {
  border: 1px solid #eceff1 !important; }

.border-top-blue-grey.border-top-lighten-5 {
  border-top: 1px solid #eceff1 !important; }

.border-bottom-blue-grey.border-bottom-lighten-5 {
  border-bottom: 1px solid #eceff1 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-5 {
  border-left: 1px solid #eceff1 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-5 {
  border-right: 1px solid #eceff1 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-5 {
  border-right: 1px solid #eceff1 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-5 {
  border-left: 1px solid #eceff1 !important; }

.overlay-blue-grey.overlay-lighten-5 {
  background: #eceff1;
  /* The Fallback */
  background: rgba(236, 239, 241, 0.8); }

.blue-grey.lighten-4 {
  color: #cfd8dc !important; }

.bg-blue-grey.bg-lighten-4 {
  background-color: #cfd8dc !important; }

.btn-blue-grey.btn-lighten-4 {
  border-color: #455a64 !important;
  background-color: #cfd8dc !important; }
  .btn-blue-grey.btn-lighten-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-4:focus, .btn-blue-grey.btn-lighten-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-4 {
  border-color: #cfd8dc !important;
  color: #cfd8dc !important; }
  .btn-outline-blue-grey.btn-outline-lighten-4:hover {
    background-color: #cfd8dc !important; }

.progress-blue-grey.progress-lighten-4 {
  background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-webkit-progress-value {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-moz-progress-bar {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-lighten-4[value]::-ms-fill {
    background-color: #cfd8dc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-4 .progress-bar {
      background-color: #cfd8dc; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cfd8dc !important; }

.border-blue-grey.border-lighten-4 {
  border: 1px solid #cfd8dc !important; }

.border-top-blue-grey.border-top-lighten-4 {
  border-top: 1px solid #cfd8dc !important; }

.border-bottom-blue-grey.border-bottom-lighten-4 {
  border-bottom: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-4 {
  border-left: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-4 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-4 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-4 {
  border-left: 1px solid #cfd8dc !important; }

.overlay-blue-grey.overlay-lighten-4 {
  background: #cfd8dc;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.lighten-3 {
  color: #b0bec5 !important; }

.bg-blue-grey.bg-lighten-3 {
  background-color: #b0bec5 !important; }

.btn-blue-grey.btn-lighten-3 {
  border-color: #455a64 !important;
  background-color: #b0bec5 !important; }
  .btn-blue-grey.btn-lighten-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-3:focus, .btn-blue-grey.btn-lighten-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-3 {
  border-color: #b0bec5 !important;
  color: #b0bec5 !important; }
  .btn-outline-blue-grey.btn-outline-lighten-3:hover {
    background-color: #b0bec5 !important; }

.progress-blue-grey.progress-lighten-3 {
  background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-webkit-progress-value {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-moz-progress-bar {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-lighten-3[value]::-ms-fill {
    background-color: #b0bec5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-3 .progress-bar {
      background-color: #b0bec5; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b0bec5 !important; }

.border-blue-grey.border-lighten-3 {
  border: 1px solid #b0bec5 !important; }

.border-top-blue-grey.border-top-lighten-3 {
  border-top: 1px solid #b0bec5 !important; }

.border-bottom-blue-grey.border-bottom-lighten-3 {
  border-bottom: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-3 {
  border-left: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-3 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-3 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-3 {
  border-left: 1px solid #b0bec5 !important; }

.overlay-blue-grey.overlay-lighten-3 {
  background: #b0bec5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.lighten-2 {
  color: #90a4ae !important; }

.bg-blue-grey.bg-lighten-2 {
  background-color: #90a4ae !important; }

.btn-blue-grey.btn-lighten-2 {
  border-color: #455a64 !important;
  background-color: #90a4ae !important; }
  .btn-blue-grey.btn-lighten-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-2:focus, .btn-blue-grey.btn-lighten-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-2 {
  border-color: #90a4ae !important;
  color: #90a4ae !important; }
  .btn-outline-blue-grey.btn-outline-lighten-2:hover {
    background-color: #90a4ae !important; }

.progress-blue-grey.progress-lighten-2 {
  background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-webkit-progress-value {
    background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-moz-progress-bar {
    background-color: #90a4ae; }
  .progress-blue-grey.progress-lighten-2[value]::-ms-fill {
    background-color: #90a4ae; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-2 .progress-bar {
      background-color: #90a4ae; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #90a4ae !important; }

.border-blue-grey.border-lighten-2 {
  border: 1px solid #90a4ae !important; }

.border-top-blue-grey.border-top-lighten-2 {
  border-top: 1px solid #90a4ae !important; }

.border-bottom-blue-grey.border-bottom-lighten-2 {
  border-bottom: 1px solid #90a4ae !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-2 {
  border-left: 1px solid #90a4ae !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-2 {
  border-right: 1px solid #90a4ae !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-2 {
  border-right: 1px solid #90a4ae !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-2 {
  border-left: 1px solid #90a4ae !important; }

.overlay-blue-grey.overlay-lighten-2 {
  background: #90a4ae;
  /* The Fallback */
  background: rgba(144, 164, 174, 0.8); }

.blue-grey.lighten-1 {
  color: #78909c !important; }

.bg-blue-grey.bg-lighten-1 {
  background-color: #78909c !important; }

.btn-blue-grey.btn-lighten-1 {
  border-color: #455a64 !important;
  background-color: #78909c !important; }
  .btn-blue-grey.btn-lighten-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-lighten-1:focus, .btn-blue-grey.btn-lighten-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-lighten-1 {
  border-color: #78909c !important;
  color: #78909c !important; }
  .btn-outline-blue-grey.btn-outline-lighten-1:hover {
    background-color: #78909c !important; }

.progress-blue-grey.progress-lighten-1 {
  background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-webkit-progress-value {
    background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-moz-progress-bar {
    background-color: #78909c; }
  .progress-blue-grey.progress-lighten-1[value]::-ms-fill {
    background-color: #78909c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-lighten-1 .progress-bar {
      background-color: #78909c; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909c !important; }

.border-blue-grey.border-lighten-1 {
  border: 1px solid #78909c !important; }

.border-top-blue-grey.border-top-lighten-1 {
  border-top: 1px solid #78909c !important; }

.border-bottom-blue-grey.border-bottom-lighten-1 {
  border-bottom: 1px solid #78909c !important; }

[dir="ltr"] .border-left-blue-grey.border-left-lighten-1 {
  border-left: 1px solid #78909c !important; }

[dir="ltr"] .border-right-blue-grey.border-right-lighten-1 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-left-blue-grey.border-left-lighten-1 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-right-blue-grey.border-right-lighten-1 {
  border-left: 1px solid #78909c !important; }

.overlay-blue-grey.overlay-lighten-1 {
  background: #78909c;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey {
  color: #607d8b !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }
  .bg-blue-grey .card-header, .bg-blue-grey .card-footer {
    background-color: transparent; }

.toast-blue-grey {
  background-color: #607d8b; }

.alert-blue-grey {
  border-color: #607d8b !important;
  background-color: #718f9d !important;
  color: #171e21 !important; }
  .alert-blue-grey .alert-link {
    color: #060809 !important; }

.border-blue-grey {
  border-color: #607d8b; }

.border-top-blue-grey {
  border-top-color: #607d8b; }

.border-bottom-blue-grey {
  border-bottom-color: #607d8b; }

[dir="ltr"] .border-left-blue-grey {
  border-left-color: #607d8b; }

[dir="ltr"] .border-right-blue-grey {
  border-right-color: #607d8b; }

[dir="rtl"] .border-left-blue-grey {
  border-right-color: #607d8b; }

[dir="rtl"] .border-right-blue-grey {
  border-left-color: #607d8b; }

.badge-blue-grey {
  background-color: #607d8b; }

.panel-blue-grey {
  border-color: #607d8b; }
  .panel-blue-grey .panel-heading {
    color: #FFF;
    border-color: #607d8b;
    background-color: #6b8a99; }

.bg-blue-grey.tag-glow, .border-blue-grey.tag-glow {
  box-shadow: 0px 0px 10px #607d8b; }

.overlay-blue-grey {
  background: #607d8b;
  /* The Fallback */
  background: rgba(96, 125, 139, 0.8); }

.card.card-outline-blue-grey {
  border-width: 1px;
  border-style: solid;
  border-color: #607d8b;
  background-color: transparent; }
  .card.card-outline-blue-grey .card-header, .card.card-outline-blue-grey .card-footer {
    background-color: transparent; }

.btn-blue-grey.btn-flat {
  background-color: transparent !important;
  color: #607d8b;
  border: none; }

.btn-blue-grey.btn-raised, .btn-blue-grey.btn-fab {
  background-color: #607d8b !important;
  color: #fff !important;
  border-color: #607d8b; }
  .btn-blue-grey.btn-raised.active, .btn-blue-grey.btn-fab.active {
    background-color: #516a76 !important;
    border-color: #516a76 !important; }

.btn-group-raised .btn-blue-grey {
  background-color: #607d8b !important;
  color: #fff !important; }

.btn-outline-blue-grey {
  border: 1px solid;
  border-color: #607d8b;
  background-color: transparent;
  color: #607d8b;
  box-shadow: none !important; }
  .btn-outline-blue-grey:focus {
    background-color: transparent !important;
    color: #607d8b !important;
    box-shadow: transparent !important; }
  .btn-outline-blue-grey.active {
    background-color: #607d8b !important;
    color: #FFF !important; }
  .btn-outline-blue-grey:hover {
    background-color: #5a7582 !important;
    color: #FFF !important; }
    .btn-outline-blue-grey:hover svg {
      color: #FFF !important; }

.btn-outline-blue-grey.btn-raised, .btn-outline-blue-grey.btn-fab {
  border: 1px solid;
  border-color: #607d8b;
  background-color: transparent;
  color: #607d8b;
  box-shadow: none !important; }
  .btn-outline-blue-grey.btn-raised:focus, .btn-outline-blue-grey.btn-fab:focus {
    background-color: transparent; }
  .btn-outline-blue-grey.btn-raised.active, .btn-outline-blue-grey.btn-fab.active {
    background-color: #607d8b !important;
    color: #FFF !important; }
  .btn-outline-blue-grey.btn-raised:hover, .btn-outline-blue-grey.btn-fab:hover {
    background-color: #5a7582 !important;
    color: #FFF !important; }

.progress .progress-bar.progress-bar-blue-grey {
  background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-webkit-progress-value {
    background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-moz-progress-bar {
    background-color: #607d8b; }
  .progress .progress-bar.progress-bar-blue-grey[value]::-ms-fill {
    background-color: #607d8b; }
  @media screen and (min-width: 0 \0 ) {
    .progress .progress-bar.progress-bar-blue-grey .progress-bar {
      background-color: #607d8b; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #607d8b !important; }

.blue-grey.darken-1 {
  color: #546e7a !important; }

.bg-blue-grey.bg-darken-1 {
  background-color: #546e7a !important; }

.btn-blue-grey.btn-darken-1 {
  border-color: #455a64 !important;
  background-color: #546e7a !important; }
  .btn-blue-grey.btn-darken-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-1:focus, .btn-blue-grey.btn-darken-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-1 {
  border-color: #546e7a !important;
  color: #546e7a !important; }
  .btn-outline-blue-grey.btn-outline-darken-1:hover {
    background-color: #546e7a !important; }

.progress-blue-grey.progress-darken-1 {
  background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-webkit-progress-value {
    background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-moz-progress-bar {
    background-color: #546e7a; }
  .progress-blue-grey.progress-darken-1[value]::-ms-fill {
    background-color: #546e7a; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-1 .progress-bar {
      background-color: #546e7a; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #546e7a !important; }

.border-blue-grey.border-darken-1 {
  border: 1px solid #546e7a !important; }

.border-top-blue-grey.border-top-darken-1 {
  border-top: 1px solid #546e7a !important; }

.border-bottom-blue-grey.border-bottom-darken-1 {
  border-bottom: 1px solid #546e7a !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-1 {
  border-left: 1px solid #546e7a !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-1 {
  border-right: 1px solid #546e7a !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-1 {
  border-right: 1px solid #546e7a !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-1 {
  border-left: 1px solid #546e7a !important; }

.overlay-blue-grey.overlay-darken-1 {
  background: #546e7a;
  /* The Fallback */
  background: rgba(84, 110, 122, 0.8); }

.blue-grey.darken-2 {
  color: #455a64 !important; }

.bg-blue-grey.bg-darken-2 {
  background-color: #455a64 !important; }

.btn-blue-grey.btn-darken-2 {
  border-color: #455a64 !important;
  background-color: #455a64 !important; }
  .btn-blue-grey.btn-darken-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-2:focus, .btn-blue-grey.btn-darken-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-2 {
  border-color: #455a64 !important;
  color: #455a64 !important; }
  .btn-outline-blue-grey.btn-outline-darken-2:hover {
    background-color: #455a64 !important; }

.progress-blue-grey.progress-darken-2 {
  background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-webkit-progress-value {
    background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-moz-progress-bar {
    background-color: #455a64; }
  .progress-blue-grey.progress-darken-2[value]::-ms-fill {
    background-color: #455a64; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-2 .progress-bar {
      background-color: #455a64; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455a64 !important; }

.border-blue-grey.border-darken-2 {
  border: 1px solid #455a64 !important; }

.border-top-blue-grey.border-top-darken-2 {
  border-top: 1px solid #455a64 !important; }

.border-bottom-blue-grey.border-bottom-darken-2 {
  border-bottom: 1px solid #455a64 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-2 {
  border-left: 1px solid #455a64 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-2 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-2 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-2 {
  border-left: 1px solid #455a64 !important; }

.overlay-blue-grey.overlay-darken-2 {
  background: #455a64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.blue-grey.darken-3 {
  color: #37474f !important; }

.bg-blue-grey.bg-darken-3 {
  background-color: #37474f !important; }

.btn-blue-grey.btn-darken-3 {
  border-color: #455a64 !important;
  background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-3:focus, .btn-blue-grey.btn-darken-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-3 {
  border-color: #37474f !important;
  color: #37474f !important; }
  .btn-outline-blue-grey.btn-outline-darken-3:hover {
    background-color: #37474f !important; }

.progress-blue-grey.progress-darken-3 {
  background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-webkit-progress-value {
    background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-moz-progress-bar {
    background-color: #37474f; }
  .progress-blue-grey.progress-darken-3[value]::-ms-fill {
    background-color: #37474f; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-3 .progress-bar {
      background-color: #37474f; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #37474f !important; }

.border-blue-grey.border-darken-3 {
  border: 1px solid #37474f !important; }

.border-top-blue-grey.border-top-darken-3 {
  border-top: 1px solid #37474f !important; }

.border-bottom-blue-grey.border-bottom-darken-3 {
  border-bottom: 1px solid #37474f !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-3 {
  border-left: 1px solid #37474f !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-3 {
  border-right: 1px solid #37474f !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-3 {
  border-right: 1px solid #37474f !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-3 {
  border-left: 1px solid #37474f !important; }

.overlay-blue-grey.overlay-darken-3 {
  background: #37474f;
  /* The Fallback */
  background: rgba(55, 71, 79, 0.8); }

.blue-grey.darken-4 {
  color: #263238 !important; }

.bg-blue-grey.bg-darken-4 {
  background-color: #263238 !important; }

.btn-blue-grey.btn-darken-4 {
  border-color: #455a64 !important;
  background-color: #263238 !important; }
  .btn-blue-grey.btn-darken-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-darken-4:focus, .btn-blue-grey.btn-darken-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-darken-4 {
  border-color: #263238 !important;
  color: #263238 !important; }
  .btn-outline-blue-grey.btn-outline-darken-4:hover {
    background-color: #263238 !important; }

.progress-blue-grey.progress-darken-4 {
  background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-webkit-progress-value {
    background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-moz-progress-bar {
    background-color: #263238; }
  .progress-blue-grey.progress-darken-4[value]::-ms-fill {
    background-color: #263238; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-darken-4 .progress-bar {
      background-color: #263238; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #263238 !important; }

.border-blue-grey.border-darken-4 {
  border: 1px solid #263238 !important; }

.border-top-blue-grey.border-top-darken-4 {
  border-top: 1px solid #263238 !important; }

.border-bottom-blue-grey.border-bottom-darken-4 {
  border-bottom: 1px solid #263238 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-darken-4 {
  border-left: 1px solid #263238 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-darken-4 {
  border-right: 1px solid #263238 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-darken-4 {
  border-right: 1px solid #263238 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-darken-4 {
  border-left: 1px solid #263238 !important; }

.overlay-blue-grey.overlay-darken-4 {
  background: #263238;
  /* The Fallback */
  background: rgba(38, 50, 56, 0.8); }

.blue-grey.accent-1 {
  color: #cfd8dc !important; }

.bg-blue-grey.bg-accent-1 {
  background-color: #cfd8dc !important; }

.btn-blue-grey.btn-accent-1 {
  border-color: #455a64 !important;
  background-color: #cfd8dc !important; }
  .btn-blue-grey.btn-accent-1:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-1:focus, .btn-blue-grey.btn-accent-1:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-1 {
  border-color: #cfd8dc !important;
  color: #cfd8dc !important; }
  .btn-outline-blue-grey.btn-outline-accent-1:hover {
    background-color: #cfd8dc !important; }

.progress-blue-grey.progress-accent-1 {
  background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-webkit-progress-value {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-moz-progress-bar {
    background-color: #cfd8dc; }
  .progress-blue-grey.progress-accent-1[value]::-ms-fill {
    background-color: #cfd8dc; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-1 .progress-bar {
      background-color: #cfd8dc; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #cfd8dc !important; }

.border-blue-grey.border-accent-1 {
  border: 1px solid #cfd8dc !important; }

.border-top-blue-grey.border-top-accent-1 {
  border-top: 1px solid #cfd8dc !important; }

.border-bottom-blue-grey.border-bottom-accent-1 {
  border-bottom: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-1 {
  border-left: 1px solid #cfd8dc !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-1 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-1 {
  border-right: 1px solid #cfd8dc !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-1 {
  border-left: 1px solid #cfd8dc !important; }

.overlay-blue-grey.overlay-accent-1 {
  background: #cfd8dc;
  /* The Fallback */
  background: rgba(207, 216, 220, 0.8); }

.blue-grey.accent-2 {
  color: #b0bec5 !important; }

.bg-blue-grey.bg-accent-2 {
  background-color: #b0bec5 !important; }

.btn-blue-grey.btn-accent-2 {
  border-color: #455a64 !important;
  background-color: #b0bec5 !important; }
  .btn-blue-grey.btn-accent-2:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-2:focus, .btn-blue-grey.btn-accent-2:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-2 {
  border-color: #b0bec5 !important;
  color: #b0bec5 !important; }
  .btn-outline-blue-grey.btn-outline-accent-2:hover {
    background-color: #b0bec5 !important; }

.progress-blue-grey.progress-accent-2 {
  background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-webkit-progress-value {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-moz-progress-bar {
    background-color: #b0bec5; }
  .progress-blue-grey.progress-accent-2[value]::-ms-fill {
    background-color: #b0bec5; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-2 .progress-bar {
      background-color: #b0bec5; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #b0bec5 !important; }

.border-blue-grey.border-accent-2 {
  border: 1px solid #b0bec5 !important; }

.border-top-blue-grey.border-top-accent-2 {
  border-top: 1px solid #b0bec5 !important; }

.border-bottom-blue-grey.border-bottom-accent-2 {
  border-bottom: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-2 {
  border-left: 1px solid #b0bec5 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-2 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-2 {
  border-right: 1px solid #b0bec5 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-2 {
  border-left: 1px solid #b0bec5 !important; }

.overlay-blue-grey.overlay-accent-2 {
  background: #b0bec5;
  /* The Fallback */
  background: rgba(176, 190, 197, 0.8); }

.blue-grey.accent-3 {
  color: #78909c !important; }

.bg-blue-grey.bg-accent-3 {
  background-color: #78909c !important; }

.btn-blue-grey.btn-accent-3 {
  border-color: #455a64 !important;
  background-color: #78909c !important; }
  .btn-blue-grey.btn-accent-3:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-3:focus, .btn-blue-grey.btn-accent-3:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-3 {
  border-color: #78909c !important;
  color: #78909c !important; }
  .btn-outline-blue-grey.btn-outline-accent-3:hover {
    background-color: #78909c !important; }

.progress-blue-grey.progress-accent-3 {
  background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-webkit-progress-value {
    background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-moz-progress-bar {
    background-color: #78909c; }
  .progress-blue-grey.progress-accent-3[value]::-ms-fill {
    background-color: #78909c; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-3 .progress-bar {
      background-color: #78909c; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #78909c !important; }

.border-blue-grey.border-accent-3 {
  border: 1px solid #78909c !important; }

.border-top-blue-grey.border-top-accent-3 {
  border-top: 1px solid #78909c !important; }

.border-bottom-blue-grey.border-bottom-accent-3 {
  border-bottom: 1px solid #78909c !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-3 {
  border-left: 1px solid #78909c !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-3 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-3 {
  border-right: 1px solid #78909c !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-3 {
  border-left: 1px solid #78909c !important; }

.overlay-blue-grey.overlay-accent-3 {
  background: #78909c;
  /* The Fallback */
  background: rgba(120, 144, 156, 0.8); }

.blue-grey.accent-4 {
  color: #455a64 !important; }

.bg-blue-grey.bg-accent-4 {
  background-color: #455a64 !important; }

.btn-blue-grey.btn-accent-4 {
  border-color: #455a64 !important;
  background-color: #455a64 !important; }
  .btn-blue-grey.btn-accent-4:hover {
    border-color: #455a64 !important;
    background-color: #37474f !important; }
  .btn-blue-grey.btn-accent-4:focus, .btn-blue-grey.btn-accent-4:active {
    border-color: #37474f !important;
    background-color: #263238 !important; }

.btn-outline-blue-grey.btn-outline-accent-4 {
  border-color: #455a64 !important;
  color: #455a64 !important; }
  .btn-outline-blue-grey.btn-outline-accent-4:hover {
    background-color: #455a64 !important; }

.progress-blue-grey.progress-accent-4 {
  background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-webkit-progress-value {
    background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-moz-progress-bar {
    background-color: #455a64; }
  .progress-blue-grey.progress-accent-4[value]::-ms-fill {
    background-color: #455a64; }
  @media screen and (min-width: 0 \0 ) {
    .progress-blue-grey.progress-accent-4 .progress-bar {
      background-color: #455a64; } }

input:focus ~ .bg-blue-grey {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #455a64 !important; }

.border-blue-grey.border-accent-4 {
  border: 1px solid #455a64 !important; }

.border-top-blue-grey.border-top-accent-4 {
  border-top: 1px solid #455a64 !important; }

.border-bottom-blue-grey.border-bottom-accent-4 {
  border-bottom: 1px solid #455a64 !important; }

[dir="ltr"] .border-left-blue-grey.border-left-accent-4 {
  border-left: 1px solid #455a64 !important; }

[dir="ltr"] .border-right-blue-grey.border-right-accent-4 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-left-blue-grey.border-left-accent-4 {
  border-right: 1px solid #455a64 !important; }

[dir="rtl"] .border-right-blue-grey.border-right-accent-4 {
  border-left: 1px solid #455a64 !important; }

.overlay-blue-grey.overlay-accent-4 {
  background: #455a64;
  /* The Fallback */
  background: rgba(69, 90, 100, 0.8); }

.shades.black {
  color: #000000 !important; }

.bg-shades.bg-black {
  background-color: #000000 !important; }

.btn-shades.btn-black {
  border-color: !important;
  background-color: #000000 !important; }
  .btn-shades.btn-black:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-black:focus, .btn-shades.btn-black:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-black {
  border-color: #000000 !important;
  color: #000000 !important; }
  .btn-outline-shades.btn-outline-black:hover {
    background-color: #000000 !important; }

.progress-shades.progress-black {
  background-color: #000000; }
  .progress-shades.progress-black[value]::-webkit-progress-value {
    background-color: #000000; }
  .progress-shades.progress-black[value]::-moz-progress-bar {
    background-color: #000000; }
  .progress-shades.progress-black[value]::-ms-fill {
    background-color: #000000; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-black .progress-bar {
      background-color: #000000; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000 !important; }

.border-shades.border-black {
  border: 1px solid #000000 !important; }

.border-top-shades.border-top-black {
  border-top: 1px solid #000000 !important; }

.border-bottom-shades.border-bottom-black {
  border-bottom: 1px solid #000000 !important; }

[dir="ltr"] .border-left-shades.border-left-black {
  border-left: 1px solid #000000 !important; }

[dir="ltr"] .border-right-shades.border-right-black {
  border-right: 1px solid #000000 !important; }

[dir="rtl"] .border-left-shades.border-left-black {
  border-right: 1px solid #000000 !important; }

[dir="rtl"] .border-right-shades.border-right-black {
  border-left: 1px solid #000000 !important; }

.overlay-shades.overlay-black {
  background: #000000;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.shades.white {
  color: #FFFFFF !important; }

.bg-shades.bg-white {
  background-color: #FFFFFF !important; }

.btn-shades.btn-white {
  border-color: !important;
  background-color: #FFFFFF !important; }
  .btn-shades.btn-white:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-white:focus, .btn-shades.btn-white:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-white {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important; }
  .btn-outline-shades.btn-outline-white:hover {
    background-color: #FFFFFF !important; }

.progress-shades.progress-white {
  background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-webkit-progress-value {
    background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-moz-progress-bar {
    background-color: #FFFFFF; }
  .progress-shades.progress-white[value]::-ms-fill {
    background-color: #FFFFFF; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-white .progress-bar {
      background-color: #FFFFFF; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF !important; }

.border-shades.border-white {
  border: 1px solid #FFFFFF !important; }

.border-top-shades.border-top-white {
  border-top: 1px solid #FFFFFF !important; }

.border-bottom-shades.border-bottom-white {
  border-bottom: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-left-shades.border-left-white {
  border-left: 1px solid #FFFFFF !important; }

[dir="ltr"] .border-right-shades.border-right-white {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-left-shades.border-left-white {
  border-right: 1px solid #FFFFFF !important; }

[dir="rtl"] .border-right-shades.border-right-white {
  border-left: 1px solid #FFFFFF !important; }

.overlay-shades.overlay-white {
  background: #FFFFFF;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.8); }

.shades.transparent {
  color: transparent !important; }

.bg-shades.bg-transparent {
  background-color: transparent !important; }

.btn-shades.btn-transparent {
  border-color: !important;
  background-color: transparent !important; }
  .btn-shades.btn-transparent:hover {
    border-color: !important;
    background-color: !important; }
  .btn-shades.btn-transparent:focus, .btn-shades.btn-transparent:active {
    border-color: !important;
    background-color: !important; }

.btn-outline-shades.btn-outline-transparent {
  border-color: transparent !important;
  color: transparent !important; }
  .btn-outline-shades.btn-outline-transparent:hover {
    background-color: transparent !important; }

.progress-shades.progress-transparent {
  background-color: transparent; }
  .progress-shades.progress-transparent[value]::-webkit-progress-value {
    background-color: transparent; }
  .progress-shades.progress-transparent[value]::-moz-progress-bar {
    background-color: transparent; }
  .progress-shades.progress-transparent[value]::-ms-fill {
    background-color: transparent; }
  @media screen and (min-width: 0 \0 ) {
    .progress-shades.progress-transparent .progress-bar {
      background-color: transparent; } }

input:focus ~ .bg-shades {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent !important; }

.border-shades.border-transparent {
  border: 1px solid transparent !important; }

.border-top-shades.border-top-transparent {
  border-top: 1px solid transparent !important; }

.border-bottom-shades.border-bottom-transparent {
  border-bottom: 1px solid transparent !important; }

[dir="ltr"] .border-left-shades.border-left-transparent {
  border-left: 1px solid transparent !important; }

[dir="ltr"] .border-right-shades.border-right-transparent {
  border-right: 1px solid transparent !important; }

[dir="rtl"] .border-left-shades.border-left-transparent {
  border-right: 1px solid transparent !important; }

[dir="rtl"] .border-right-shades.border-right-transparent {
  border-left: 1px solid transparent !important; }

.overlay-shades.overlay-transparent {
  background: transparent;
  /* The Fallback */
  background: rgba(0, 0, 0, 0.8); }

.black {
  color: #000000; }

.bg-black {
  background-color: #000000; }

input:focus ~ .bg-black {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #000000; }

.border-black {
  border: 1px solid #000000; }

.border-top-black {
  border-top: 1px solid #000000; }

.border-bottom-black {
  border-bottom: 1px solid #000000; }

[dir="ltr"] .border-left-black {
  border-left: 1px solid #000000; }

[dir="ltr"] .border-right-black {
  border-right: 1px solid #000000; }

[dir="rtl"] .border-left-black {
  border-right: 1px solid #000000; }

[dir="rtl"] .border-right-black {
  border-left: 1px solid #000000; }

.white {
  color: #FFFFFF; }

.bg-white {
  background-color: #FFFFFF; }

input:focus ~ .bg-white {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem #FFFFFF; }

.border-white {
  border: 1px solid #FFFFFF; }

.border-top-white {
  border-top: 1px solid #FFFFFF; }

.border-bottom-white {
  border-bottom: 1px solid #FFFFFF; }

[dir="ltr"] .border-left-white {
  border-left: 1px solid #FFFFFF; }

[dir="ltr"] .border-right-white {
  border-right: 1px solid #FFFFFF; }

[dir="rtl"] .border-left-white {
  border-right: 1px solid #FFFFFF; }

[dir="rtl"] .border-right-white {
  border-left: 1px solid #FFFFFF; }

.transparent {
  color: transparent; }

.bg-transparent {
  background-color: transparent; }

input:focus ~ .bg-transparent {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.21rem transparent; }

.border-transparent {
  border: 1px solid transparent; }

.border-top-transparent {
  border-top: 1px solid transparent; }

.border-bottom-transparent {
  border-bottom: 1px solid transparent; }

[dir="ltr"] .border-left-transparent {
  border-left: 1px solid transparent; }

[dir="ltr"] .border-right-transparent {
  border-right: 1px solid transparent; }

[dir="rtl"] .border-left-transparent {
  border-right: 1px solid transparent; }

[dir="rtl"] .border-right-transparent {
  border-left: 1px solid transparent; }

html {
  font-size: 0.9rem;
  width: 100%; }

body {
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  color: #000000;
  min-height: 100vh;
  background: #fff; }
  body.inverse {
    background: #333333; }
    body.inverse, body.inverse .form-control {
      color: #fff; }
    body.inverse .modal,
    body.inverse .modal .form-control,
    body.inverse .panel-default,
    body.inverse .panel-default .form-control,
    body.inverse .card,
    body.inverse .card .form-control {
      background-color: initial;
      color: initial; }

.wrapper.wrapper-full-page {
  height: auto;
  min-height: 100vh; }

.content-wrapper {
  padding: 0 15px; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.15em; }

.serif-font {
  font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }

.page-header {
  height: 60vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; }

a {
  color: #007bff; }
  a:hover, a:focus {
    color: #006fe6;
    text-decoration: none; }
  a.text-info:hover, a.text-info:focus {
    color: #148ea1; }

.badge-toolbar .badge {
  margin-right: 15px; }

.badge {
  margin-right: 5px;
  padding: 6px 10px;
  font-size: 85%;
  font-weight: 500;
  letter-spacing: 0.3px; }

.badge-pill {
  padding: 5px 6px 3px; }

.badge-warning {
  color: #fff; }

.btn {
  outline: none; }
  .btn.btn-xl {
    padding: 0.7rem;
    height: auto;
    max-height: none;
    font-size: 1.3em;
    border-radius: 2rem; }
  .btn.btn-md {
    padding: 0.356rem;
    font-size: 1.3rem; }
  .btn.btn-orange {
    background: #ED7218;
    border: 0;
    color: #ffffff; }
  .btn.btn-blue {
    background: #4561F7;
    border: 0;
    color: #ffffff; }
  .btn.btn-white {
    background: #ffffff;
    border: 1px solid #bdbdbd;
    color: #000000; }
  .btn.btn-full {
    display: block;
    width: 100%; }
  .btn.btn-cancel {
    background-color: #4661F7;
    border: none; }
  .btn.button-link {
    border: none !important;
    color: #00ADEB !important;
    background: none !important;
    font-weight: normal !important;
    padding: 0px !important; }
    .btn.button-link + .vertical-spearator {
      border-left: 1px solid #00ADEB !important;
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      margin-left: 5px;
      margin-right: 5px; }

.btn-toolbar > .btn {
  margin-right: 15px; }

.btn-group-vertical .btn[class*="btn-"] {
  margin-bottom: 0; }

.input-group {
  margin-bottom: 1rem; }
  .input-group .btn[class*="btn-"] {
    margin-bottom: 0; }

.btn-round {
  border-radius: 30px; }

.btn-square {
  border-radius: 0; }

.btn:active,
.btn.active {
  background-image: initial; }

button:hover {
  cursor: pointer; }

.btn-group > .btn.active {
  z-index: 0; }

.btn-floating {
  position: fixed;
  bottom: 20px;
  right: 20px; }

.btn-secondary {
  border-color: transparent; }

.card {
  font-weight: normal;
  border: 0;
  /*  margin: 15px 0; */
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 0 14px 0px #0000000d; }
  .card[class*="gradient-"] .card-header,
  .card[class*="gradient-"] .card-footer {
    background-color: initial; }
  .card .card-header {
    padding: 1.3rem;
    border-bottom: none;
    background-color: transparent; }
  .card .card-subtitle {
    margin-top: 10px;
    margin-bottom: 25px; }
  .card .card-block {
    padding: 0.5rem 1.5rem 1.5rem; }
  .card .card-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06); }
  .card.card-transparent {
    background-color: transparent !important; }
  .card .card {
    box-shadow: none; }
  .card .card-title {
    margin-bottom: 1rem;
    font-size: 23px;
    color: #000;
    text-transform: capitalize;
    letter-spacing: -0.02em; }
  .card .card-img {
    position: relative; }
    @media (max-width: 768px) {
      .card .card-img {
        display: none; } }
    .card .card-img .card-title {
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: 24px; }
    .card .card-img.overlap {
      margin-top: -60px; }
  .card .btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: #007bff;
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;
    font-size: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }
    .card .btn-floating.btn {
      margin: 0; }
    .card .btn-floating i {
      width: inherit;
      display: inline-block;
      text-align: center;
      color: #fff;
      font-size: 2rem;
      line-height: 40px; }
    .card .btn-floating.btn-large {
      width: 56px;
      height: 56px; }
      .card .btn-floating.btn-large.halfway-fab {
        bottom: -28px;
        padding: 6px; }
      .card .btn-floating.btn-large i {
        line-height: 56px; }
    .card .btn-floating.halfway-fab {
      position: absolute;
      right: 24px;
      bottom: -20px; }
  .card .card-reveal {
    padding: 24px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    transition: height 1s ease-in-out;
    display: none; }
    .card .card-reveal .card-title {
      cursor: pointer; }
  .card.horizontal .card-body {
    display: flex; }
  .card.horizontal .card-img {
    max-width: 50%; }
    .card.horizontal .card-img img {
      border-radius: 2px 0 0 2px;
      max-width: 100%;
      width: auto; }
  .card .card-stacked {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    position: relative; }
  .card h1.card-title,
  .card h2.card-title,
  .card h3.card-title,
  .card h4.card-title,
  .card h5.card-title,
  .card h6.card-title {
    text-transform: capitalize;
    font-weight: 400; }
  .card .card-footer {
    padding: 1rem 1.5rem;
    background: white;
    overflow: hidden; }
  .card.card-inverse .card-blockquote .blockquote-footer,
  .card.card-inverse .card-link,
  .card.card-inverse .card-subtitle,
  .card.card-inverse .card-text,
  .card.card-inverse .card-title,
  .card.card-inverse .card-footer,
  .card.card-inverse a,
  .card.card-inverse a:hover,
  .card.card-inverse label,
  .card.card-inverse span,
  .card.card-inverse .form-group:hover,
  .card.card-inverse div,
  .card.card-inverse input {
    color: #fff; }
  .card.card-inverse .form-group.is-focused .checkbox label,
  .card.card-inverse .form-group.is-focused label.checkbox-inline,
  .card.card-inverse .form-group.is-focused .radio label,
  .card.card-inverse .form-group.is-focused label.radio-inline {
    color: #fff; }
  .card.card-inverse hr {
    border-color: #fff; }
  .card.card-inverse .checkbox label {
    color: #fff; }
    .card.card-inverse .checkbox label .checkbox-decorator .check {
      border-color: rgba(255, 255, 255, 0.54); }
  .card.card-inverse input::-webkit-input-placeholder, .card.card-inverse input::-moz-placeholder, .card.card-inverse input:-ms-input-placeholder, .card.card-inverse input:-moz-placeholder {
    color: #fff; }
  .card_forgot {
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481; }
  .card_login, .card-register {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481; }
    @media (max-width: 500px) {
      .card_login, .card-register {
        justify-content: center; } }
    .card_login .card-body, .card-register .card-body {
      width: 100%;
      max-width: 360px;
      margin: 0 auto; }
    .card_login .card-terms, .card-register .card-terms {
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 10px;
      text-align: center;
      letter-spacing: -0.02em; }
      .card_login .card-terms a, .card-register .card-terms a {
        font-weight: 600;
        color: #4599F7; }
    .card_login .card-image, .card-register .card-image {
      margin-top: -42px; }
      @media (max-width: 500px) {
        .card_login .card-image, .card-register .card-image {
          display: none; } }
    .card_login .forgot-link, .card-register .forgot-link {
      font-size: 1rem;
      font-weight: 600;
      color: #499FFF; }
  .card-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px 20px;
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481; }
    @media (max-width: 768px) {
      .card-wrapper {
        padding: 20px 0 0; } }
    .card-wrapper .card {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 50px 0 0;
      background-color: transparent;
      /*  border-right: 0.5px solid #004481; */
      border-radius: 0; }
      @media (max-width: 768px) {
        .card-wrapper .card {
          padding: 0 20px 0 0; } }
      .card-wrapper .card-body {
        padding: 0; }
      .card-wrapper .card.plans {
        padding: 0 30px 0 0; }
        @media (max-width: 768px) {
          .card-wrapper .card.plans {
            padding: 0 10px 0 10px; } }
        .card-wrapper .card.plans .col:nth-child(2) {
          order: -3; }
        .card-wrapper .card.plans .col:nth-child(3) {
          order: -2; }
        .card-wrapper .card.plans .col:first-child {
          order: -1; }
      .card-wrapper .card-subtitle {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 18px;
        color: #F37920;
        letter-spacing: -0.02em; }
      .card-wrapper .card .btn {
        padding: .52rem 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: #ffffff;
        letter-spacing: -0.02em; }
      .card-wrapper .card-logo {
        display: none;
        width: 126px;
        height: 27px; }
        .card-wrapper .card-logo img {
          display: block;
          width: 100%;
          height: auto; }
    .card-wrapper_print {
      max-width: 552px;
      padding: 34px 25px;
      /* Table */ }
      .card-wrapper_print main {
        width: 100%; }
      .card-wrapper_print .card {
        padding-left: 0;
        border-left: none; }
        .card-wrapper_print .card-title > span {
          display: none; }
        .card-wrapper_print .card-for span {
          display: block;
          font-size: 19px; }
          .card-wrapper_print .card-for span.card-email {
            padding-right: 20px;
            font-size: 23px; }
        .card-wrapper_print .card-btn {
          padding-top: 20px; }
          .card-wrapper_print .card-btn .btn {
            display: none; }
        .card-wrapper_print .card-logo {
          display: block; }
      .card-wrapper_print .table th, .card-wrapper_print .table td {
        padding: 1.1rem .75rem;
        padding-right: 0;
        border-bottom: none; }
      .card-wrapper_print .table tbody tr:last-child {
        border-bottom: none; }
  .card_main {
    width: 100%; }
  .card-top {
    display: flex;
    justify-content: space-between; }
  .card-for {
    font-size: 14px;
    text-transform: initial; }
  .card-btn {
    padding-top: 12px; }
    .card-btn .btn {
      min-width: 152px; }

[dir="rtl"] .card {
  text-align: right; }
  [dir="rtl"] .card .card-img .card-title {
    right: 0;
    left: auto; }
  [dir="rtl"] .card .btn-floating.halfway-fab {
    left: 24px;
    right: auto; }
  [dir="rtl"] .card .card-reveal {
    right: 0;
    left: auto; }

.nav-tabs-vc > ul.nav {
  position: absolute;
  right: 20px;
  top: 8px;
  border: none;
  background: #fff; }
  .nav-tabs-vc > ul.nav .nav-link {
    padding: 0.5rem 0.8rem 0.2rem 0.8rem;
    border: none; }
    .nav-tabs-vc > ul.nav .nav-link.active {
      border-bottom: 2px solid #007bff; }

.nav-pills .nav-item .nav-link.active:hover {
  color: #fff; }

.nav-tabs.nav-border-bottom .nav-link:hover {
  border-color: transparent;
  border-bottom: 1px solid #dee2e6; }

.nav-tabs.nav-border-bottom .nav-link.active {
  border-radius: 0;
  border-color: transparent;
  border-bottom: 2px solid #007bff; }

.tabs-vertical {
  display: flex; }
  .tabs-vertical .nav-tabs {
    border-bottom: none;
    width: 100px;
    height: 100%;
    flex-wrap: wrap; }
    .tabs-vertical .nav-tabs .nav-item {
      width: 100%; }
      .tabs-vertical .nav-tabs .nav-item .nav-link {
        border-right: 1px solid #dee2e6; }
        .tabs-vertical .nav-tabs .nav-item .nav-link:hover {
          border-color: transparent;
          border-right: 1px solid #dee2e6; }
        .tabs-vertical .nav-tabs .nav-item .nav-link.active {
          border-color: transparent;
          border-right: 2px solid #007bff; }

form {
  /* .form-body{
      overflow: hidden;
  } */ }
  form .invalid-feedback {
    margin-top: 0; }
  form label {
    font-size: 1rem;
    margin-bottom: 4px;
    font-weight: 400;
    color: rgba(0, 68, 129, 0.6); }
  form .labelfocus {
    color: #004481; }
  form .col-form-label {
    font-size: 0.75rem;
    text-align: right; }
  form .form-group {
    margin-bottom: 1.2rem; }
  form .form-footer {
    padding-top: 10px; }
  form .form-control {
    display: block;
    width: 100%;
    padding: 0.39rem 0.775rem;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #004481;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #2DB1E3;
    border-radius: 20px;
    height: auto;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color .3s, color .3s; }
  form .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  form .form-control:focus {
    background-color: #fff;
    outline: 0;
    box-shadow: none;
    border-color: rgba(0, 68, 129, 0.23); }
  form .form-control:focus::-webkit-input-placeholder {
    color: #004481; }
  form .form-control:focus::placeholder {
    color: #004481; }
  form .form-control::-webkit-input-placeholder {
    color: #d2d6d8;
    opacity: 1; }
  form .form-control::placeholder {
    color: #d2d6d8;
    opacity: 1; }
  form .form-control:disabled, form .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  form select.form-control:not([size]):not([multiple]) {
    height: calc(2.1rem + 4px); }
  form select.form-control:focus::-ms-value {
    background-color: #fff; }
  form .form-control-position {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.2rem;
    text-align: center; }
  form .has-icon-left .form-control-position {
    right: auto;
    left: inherit; }
  form .has-icon-left .form-control {
    padding-right: .85rem;
    padding-left: 2.95rem; }
  form .has-icon-right .form-control {
    padding-right: 2.5rem; }
  form .form-section {
    color: #343a40;
    line-height: 3rem;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    margin-bottom: 20px;
    border-bottom: 1px solid #343a40; }
    form .form-section i {
      font-size: 20px;
      margin-left: 5px;
      margin-right: 5px; }
  form .form-actions {
    border-top: 1px solid #d3dce9;
    padding: 20px 0;
    margin-top: 20px; }
    form .form-actions.filled {
      background-color: #f5f7fa; }
    form .form-actions.center {
      text-align: center; }
    form .form-actions.right {
      text-align: right; }
    form .form-actions.top {
      border-top: 0;
      border-bottom: 1px solid #d3dce9;
      margin-top: 0;
      margin-bottom: 20px; }
    @media (max-width: 767.98px) {
      form .form-actions .buttons-group {
        float: left !important;
        margin-bottom: 10px; } }
  @media (min-width: 576px) {
    form.form-horizontal .form-group .label-control {
      text-align: right; } }
  form.row-separator .form-group {
    margin: 0;
    border-bottom: 1px solid #ebeff5; }
    form.row-separator .form-group .label-control {
      padding-top: 1.5rem; }
    form.row-separator .form-group > div {
      padding: 1.2rem;
      padding-right: 0; }
    form.row-separator .form-group.last {
      border-bottom: 0; }
  form.form-bordered .form-group {
    margin: 0;
    border-bottom: 1px solid #ebeff5; }
    form.form-bordered .form-group .label-control {
      padding-top: 1.5rem; }
    form.form-bordered .form-group > div {
      padding: 1.2rem;
      padding-right: 0;
      border-left: 1px solid #ebeff5; }
    form.form-bordered .form-group.last {
      border-bottom: 0; }
  form.striped-rows .form-group {
    margin: 0; }
    form.striped-rows .form-group .label-control, form.striped-rows .form-group .col-form-label {
      padding-top: 1.5rem; }
    form.striped-rows .form-group > div {
      padding: 1.2rem;
      padding-right: 0; }
    form.striped-rows .form-group:nth-child(even) {
      background-color: #f5f7fa; }
  form.striped-labels .form-group {
    margin: 0; }
    form.striped-labels .form-group .label-control {
      padding-top: 1.5rem; }
    form.striped-labels .form-group > div {
      padding: 1.2rem;
      padding-right: 0;
      background-color: white; }
    form.striped-labels .form-group:nth-child(even) {
      background-color: #f5f7fa; }

select.form-control {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

/*
TODO:M: To check with latest bootstrap version and remove it.
Bootstrap Enhance */
.custom-control {
  display: block; }
  .custom-control.inline {
    display: inline; }

.custom-checkbox #remember {
  left: unset;
  right: -20px; }

.custom-checkbox input:disabled:checked ~ .custom-control-indicator {
  opacity: 0.5; }

.custom-checkbox input:disabled ~ .custom-control-indicator {
  cursor: not-allowed; }

.custom-control-input > input:focus ~ .custom-control-indicator {
  padding: 0.51rem; }

.custom-checkbox .custom-control-label:before {
  border-radius: 50%;
  right: -1.5rem; }

.custom-control-indicator {
  width: 1.1rem;
  height: 1.075rem;
  line-height: 1.05rem;
  top: 0.085rem; }
  .custom-control-indicator + .custom-control-description {
    margin-left: 1rem; }

.form-control:disabled, .form-control[readonly] {
  background-color: #ECEFF1; }

.input-group-addon {
  background-color: #ECEFF1; }

.form-control-xl {
  padding: 0.8rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-xl:not([size]):not([multiple]) {
  height: calc(3.25rem + 2px); }

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: -4px;
  margin-left: -1rem; }

.form-control-xl, .input-group-xl > .form-control, .input-group-xl > .input-group-addon, .input-group-xl > .input-group-btn > .btn {
  padding: 0.8rem 1rem; }

.ng-touched.ng-invalid {
  border-color: #dc3545; }

[dir="rtl"] form .form-control-position {
  left: 0;
  right: auto; }

[dir="rtl"] form .has-icon-left .form-control-position {
  left: auto;
  right: inherit; }

[dir="rtl"] form .has-icon-left .form-control {
  padding-left: .85rem;
  padding-right: 2.95rem; }

[dir="rtl"] form .has-icon-right .form-control {
  padding-left: 2.5rem;
  padding-right: auto; }

[dir="rtl"] form .form-actions.right {
  text-align: left; }

@media (max-width: 767.98px) {
  [dir="rtl"] form .form-actions .buttons-group {
    float: right !important; } }

@media (min-width: 576px) {
  [dir="rtl"] form.form-horizontal .form-group .label-control {
    text-align: left; } }

[dir="rtl"] form.row-separator .form-group > div {
  padding-left: 0;
  padding-right: auto; }

[dir="rtl"] form.form-bordered .form-group > div {
  padding-left: 0;
  padding-right: auto;
  border-right: 1px solid #ebeff5; }

[dir="rtl"] form.striped-rows .form-group > div {
  padding-left: 0;
  padding-right: auto; }

[dir="rtl"] form.striped-labels .form-group > div {
  padding-left: 0;
  padding-right: auto; }

[dir="rtl"] .custom-control-indicator + .custom-control-description {
  margin-right: 1rem;
  margin-left: auto; }

[dir="rtl"] .input-group-addon input[type="radio"], [dir="rtl"] .input-group-addon input[type="checkbox"] {
  margin-right: -1rem;
  margin-left: auto; }

.form-label {
  margin-left: 0.675rem; }

.carousel-inner > .carousel-item > img {
  display: block;
  max-width: 100%;
  height: auto;
  line-height: 1; }

.modal-content {
  border-radius: 0.25rem; }
  .modal-content .modal-footer .btn {
    margin: 0; }
  .modal-content .modal-footer .btn + .btn {
    margin-left: 8px; }

.navbar {
  z-index: 10;
  border: 0;
  border-radius: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #f2f2f2; }
  .navbar .navbar-brand {
    position: relative; }
  .navbar .navbar-nav {
    display: block; }
    .navbar .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar .navbar-nav .nav-item.show > a {
      color: #ED7218; }
  .navbar .navbar-toggle {
    background-color: transparent;
    border: none; }
    .navbar .navbar-toggle .icon-bar {
      background-color: inherit;
      border: 1px solid; }
      .navbar .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 4px; }
  .navbar .navbar-search {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll; }
  .navbar .notification {
    position: absolute;
    top: -1px;
    right: 13px; }

@media (max-width: 767px) {
  #navbar-mobile.navbar-collapse .navbar-nav {
    margin: 0;
    flex-flow: row wrap; }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu {
      position: absolute; }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item {
      float: left; }
      #navbar-mobile.navbar-collapse .navbar-nav .nav-item.show > a {
        color: #ED7218; } }

/* For sm screen*/
@media (max-width: 991.98px) {
  .navbar-header {
    width: 100% !important;
    display: flex;
    justify-content: space-between; }
  .navbar-container {
    /*     width: 100%;
    display: table;
    padding: 0rem 1rem;
    margin: 0;
    height: auto; */ } }

@media (max-width: 991.98px) {
  .header-navbar .navbar-container ul.navbar-nav li {
    float: right !important; }
    .header-navbar .navbar-container ul.navbar-nav li.show > a {
      color: #ED7218; } }

@media (max-width: 991.98px) {
  [dir="rtl"] .header-navbar .navbar-container ul.navbar-nav li {
    float: left !important; } }

.popover {
  border-color: rgba(0, 157, 160, 0.2);
  padding: 0; }
  .popover .popover-header {
    background-color: #007bff;
    border-color: #007bff;
    color: white; }

.progress {
  margin-bottom: 1rem; }

h1, .h1 {
  font-size: 2.125rem; }

h2, .h2 {
  font-size: 1.7rem; }

h3, .h3 {
  font-size: 1.4875rem; }

h4, .h4 {
  font-size: 1.275rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 0.85rem;
  font-weight: 300; }

small,
.small {
  font-size: 90%; }

blockquote, .blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 1.1rem;
  border-left: 5px solid #eeeeee; }
  blockquote p, .blockquote p {
    font-style: italic; }
  blockquote footer, blockquote small, blockquote .small, .blockquote footer, .blockquote small, .blockquote .small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777777; }

.blockquote-reverse, blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right; }

li, dd {
  line-height: 2rem; }

h2.title {
  margin-bottom: 2rem; }

.text-warning {
  color: #ffc107 !important; }

.text-primary {
  color: #007bff !important; }

.text-danger {
  color: #dc3545 !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-gray {
  color: !important; }

.font-small-1 {
  font-size: 0.7rem !important; }

.font-small-2 {
  font-size: 0.8rem !important; }

.font-small-3 {
  font-size: 0.9rem !important; }

.font-medium-1 {
  font-size: 1.1rem !important; }

.font-medium-2 {
  font-size: 1.2rem !important; }

.font-medium-3 {
  font-size: 1.3rem !important; }

.font-medium-4 {
  font-size: 1.4rem !important; }

.font-medium-5 {
  font-size: 1.5rem !important; }

.font-large-1 {
  font-size: 2rem !important;
  line-height: 2rem; }

.font-large-2 {
  font-size: 3rem !important;
  line-height: 2.95rem; }

.font-large-3 {
  font-size: 4rem !important;
  line-height: 3.5rem; }

.font-large-4 {
  font-size: 5rem !important;
  line-height: 4.2rem; }

.font-large-5 {
  font-size: 6rem !important;
  line-height: 5.2rem; }

.text-bold-300 {
  font-weight: 300; }

.text-bold-400 {
  font-weight: 400; }

.text-bold-500 {
  font-weight: 500; }

.text-bold-600 {
  font-weight: 600; }

.text-bold-700 {
  font-weight: 700; }

.text-italic {
  font-style: italic; }

.text-highlight {
  padding: 4px 6px; }

[dir="rtl"] .float-left {
  float: right !important; }

[dir="rtl"] .float-right {
  float: left !important; }

[dir="rtl"] .text-right {
  text-align: left !important; }

[dir="rtl"] .text-left {
  text-align: right !important; }

.padBot30 {
  padding-bottom: 30px; }

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 18px 20px;
  background-color: rgba(255, 255, 255, 0.89);
  border-bottom: none;
  z-index: 3; }
  .header-nav li {
    position: relative;
    margin: 0 !important;
    line-height: normal;
    /*  &:first-child {
        a {
          padding-left: 0;
        }
      } */ }
    .header-nav li:last-child a {
      padding-right: 0; }
    .header-nav li:last-child:before {
      display: none; }
    .header-nav li:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 14px;
      background-color: #000C84;
      top: 0;
      left: 0; }
    .header-nav li a {
      padding: 0 15px;
      font-size: 0.785rem;
      line-height: 1rem;
      color: #000C84;
      transition: color .3s; }
      @media (min-width: 1200px) {
        .header-nav li a:hover {
          color: #ED7218; } }
  .header .header-logo {
    margin: 0 auto; }
    @media (max-width: 768px) {
      .header .header-logo {
        margin: 5px 0; } }
    .header .header-logo img {
      width: 112px; }

footer {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #01129E;
  text-align: center; }
  footer .container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 630px; }
  footer .footer-logo {
    display: block; }
  footer ul {
    text-align: center;
    display: inline-block;
    vertical-align: middle; }
    footer ul li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      padding: 0 25px;
      color: #ffffff; }
      footer ul li:first-child {
        padding-left: 0; }
      footer ul li:last-child {
        padding-right: 0; }
      footer ul li a {
        color: #ffffff;
        transition: color .3s; }
  footer .clouds_orange,
  footer .clouds_blue {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0; }
    footer .clouds_orange img,
    footer .clouds_blue img {
      display: block;
      width: 100%;
      height: auto; }

@-webkit-keyframes waveOrange {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes waveOrange {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes waveBlue {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes waveBlue {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.space-row-10 {
  margin: 0 -5px; }

.space-row-10 > [class^="col-"] {
  padding: 0 5px; }

.img-responsive {
  display: block;
  width: 100%;
  height: auto; }

.rrt-confirm-holder .rrt-confirm {
  padding-bottom: 20px; }

#cancel-sub .rrt-buttons-holder {
  display: none; }

.main-layout {
  padding: 0; }
  .main-layout a {
    display: block; }
  .main-layout .main-wrapper {
    padding-bottom: 70px;
    position: relative;
    height: calc(100vh - 100px);
    z-index: 99;
    max-width: 788px !important; }
    .main-layout .main-wrapper .main-content {
      position: relative;
      max-height: 520px;
      width: calc(100% - 140px);
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 4; }
      @media (max-width: 768px) {
        .main-layout .main-wrapper .main-content {
          width: calc(100% - 100px); } }
  .main-layout .card {
    box-shadow: none; }
    .main-layout .card h5.card-title {
      text-transform: none; }
    .main-layout .card h2.card-title {
      font-size: 14px; }
  .main-layout .list-group {
    padding-right: 0; }
    .main-layout .list-group .list-group-item {
      border: 0;
      padding: 0;
      background-color: transparent;
      margin-bottom: 20px; }
      @media (max-width: 768px) {
        .main-layout .list-group .list-group-item {
          margin-bottom: 10px; } }
      .main-layout .list-group .list-group-item h5 {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 13px;
        color: #9195D1;
        margin-bottom: 5px;
        letter-spacing: -0.02em; }
      .main-layout .list-group .list-group-item p {
        margin-bottom: 0; }
  .main-layout main {
    color: #000000; }
    .main-layout main .content {
      font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
    .main-layout main p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #a8a8a8; }
  .main-layout footer {
    font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
    .main-layout footer p {
      color: #000000;
      font-size: 1rem;
      letter-spacing: 0.3px;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
      .main-layout footer p a {
        color: #007bff;
        color: var(--primary);
        cursor: pointer;
        text-decoration: none;
        background-color: transparent; }

.menu-collapsed {
  margin-left: 60px;
  padding: 0 16px; }
  .menu-collapsed main {
    margin-top: 15px;
    color: #000000; }
    .menu-collapsed main .content {
      font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
    .menu-collapsed main p {
      color: #000000; }
  .menu-collapsed footer {
    font-family: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }
    .menu-collapsed footer p {
      color: #000000;
      font-size: 1rem;
      letter-spacing: 0.3px;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem; }
      .menu-collapsed footer p a {
        color: #007bff;
        color: var(--primary);
        cursor: pointer;
        text-decoration: none;
        background-color: transparent; }

@media (max-width: 768px) {
  .menu-collapsed {
    margin-left: 0px; } }

@media (max-width: 990px) {
  .main-layout {
    margin-left: 0px !important; } }

.animate-shake {
  -webkit-animation: 0.15s ease-in 2s infinite alternate animate-shake;
          animation: 0.15s ease-in 2s infinite alternate animate-shake;
  -webkit-animation-fill-mode: initial;
          animation-fill-mode: initial;
  top: 1px;
  position: relative; }

@-webkit-keyframes animate-shake {
  0% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg); }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg); } }

@keyframes animate-shake {
  0% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg); }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg); } }

.wrapper.layout-dark {
  background-color: #2e2f42;
  color: #bdbdc7;
  height: 100%; }
  .wrapper.layout-dark main {
    color: #bdbdc7; }
  .wrapper.layout-dark .bg-white {
    background-color: #404258 !important; }
  .wrapper.layout-dark h1,
  .wrapper.layout-dark h2,
  .wrapper.layout-dark h3,
  .wrapper.layout-dark h4,
  .wrapper.layout-dark h5,
  .wrapper.layout-dark h6,
  .wrapper.layout-dark .h1,
  .wrapper.layout-dark .h2,
  .wrapper.layout-dark .h3,
  .wrapper.layout-dark .h4,
  .wrapper.layout-dark .h5,
  .wrapper.layout-dark .h6,
  .wrapper.layout-dark .content-header,
  .wrapper.layout-dark .table th {
    color: #fff; }
  .wrapper.layout-dark p,
  .wrapper.layout-dark .content-sub-header,
  .wrapper.layout-dark .table td,
  .wrapper.layout-dark .list-group-item-action,
  .wrapper.layout-dark .nav-tabs .nav-link.active,
  .wrapper.layout-dark .content-sub-header {
    color: #bdbdc7; }
  .wrapper.layout-dark .input-group-text {
    background-color: #404258;
    color: #bdbdc7; }
  .wrapper.layout-dark .page-item.active .page-link {
    background-color: #2e2f42; }
  .wrapper.layout-dark .page-item .page-link {
    background-color: #404258;
    color: #bdbdc7;
    border-color: #616279; }
  .wrapper.layout-dark .progress {
    background-color: #2e2f42; }
  .wrapper.layout-dark .breadcrumb {
    background-color: #2e2f42; }
    .wrapper.layout-dark .breadcrumb .active.breadcrumb-item {
      color: #fff; }
  .wrapper.layout-dark svg {
    stroke: #fff !important; }
  .wrapper.layout-dark .ct-grid {
    stroke: #ffffff38; }
  .wrapper.layout-dark .ct-label {
    color: #77798e; }
  .wrapper.layout-dark .table thead th {
    border-bottom: 2px solid #616279; }
  .wrapper.layout-dark .table th,
  .wrapper.layout-dark .table td {
    border-top: 2px solid #616279; }
  .wrapper.layout-dark .text-muted {
    color: #b1b1bb !important; }
  .wrapper.layout-dark .list-group-item {
    background-color: inherit; }
  .wrapper.layout-dark .dropdown-menu {
    background-color: #404258;
    color: #bdbdc7; }
    .wrapper.layout-dark .dropdown-menu .dropdown-item {
      color: #bdbdc7; }
      .wrapper.layout-dark .dropdown-menu .dropdown-item:hover, .wrapper.layout-dark .dropdown-menu .dropdown-item:focus {
        background-color: #2e2f42;
        color: #fff; }
  .wrapper.layout-dark .navbar .navbar-nav .nav-link {
    color: #bdbdc7; }
  .wrapper.layout-dark .navbar .list-group-item {
    background-color: #404258; }
  .wrapper.layout-dark .navbar .scrollbar-container.bg-grey.bg-lighten-5 {
    background-color: #404258 !important; }
  .wrapper.layout-dark .navbar .scrollbar-container .border-bottom-grey.border-bottom-lighten-3 {
    border-color: #616279 !important; }
  .wrapper.layout-dark form label {
    color: #bdbdc7; }
  .wrapper.layout-dark form.form-bordered .form-group {
    border-color: #616279; }
    .wrapper.layout-dark form.form-bordered .form-group > div {
      border-color: #616279; }
  .wrapper.layout-dark form.striped-rows .form-group:nth-child(even) {
    background-color: #2e2f42; }
  .wrapper.layout-dark .form-control {
    background-color: #404258;
    color: #bdbdc7; }
  .wrapper.layout-dark .card {
    background-color: #404258; }
    .wrapper.layout-dark .card .card-title,
    .wrapper.layout-dark .card .card-subtitle {
      color: #fff; }
    .wrapper.layout-dark .card .card-footer,
    .wrapper.layout-dark .card .nav-item .nav-link,
    .wrapper.layout-dark .card .nav-tabs-vc > ul.nav {
      background-color: #404258; }
  .wrapper.layout-dark .email-application .email-app-sidebar,
  .wrapper.layout-dark .email-application .email-app-list-mails,
  .wrapper.layout-dark .email-application .email-app-mail-content {
    background-color: #404258;
    border-right: 1px solid #bdbdc7;
    border-color: #616279; }
  .wrapper.layout-dark .email-application .email-app-menu .form-group-compose,
  .wrapper.layout-dark .email-application .email-app-list-mails .email-actions {
    border-bottom: 1px solid #616279; }
  .wrapper.layout-dark .email-application .email-app-list-mails .email-actions {
    border-top: 1px solid #616279; }
  .wrapper.layout-dark .email-application .list-group-item.active,
  .wrapper.layout-dark .email-application .list-group-item.active:focus,
  .wrapper.layout-dark .email-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff; }
  .wrapper.layout-dark .email-application .list-group-item-action {
    color: #bdbdc7; }
    .wrapper.layout-dark .email-application .list-group-item-action.bg-grey.bg-lighten-4 {
      background-color: #4f5069 !important; }
  .wrapper.layout-dark .chat-application .chat-app-window,
  .wrapper.layout-dark .chat-application .chat-sidebar,
  .wrapper.layout-dark .chat-application .chat-fixed-search {
    background-color: #404258; }
  .wrapper.layout-dark .chat-application .chat-name,
  .wrapper.layout-dark .chat-application .chat-sidebar,
  .wrapper.layout-dark .chat-application .chat-fixed-search {
    border-color: #616279; }
  .wrapper.layout-dark .chat-application .bg-blue-grey.bg-lighten-5 {
    background-color: #4f5069 !important; }
  .wrapper.layout-dark .contact-application .contact-app-sidebar,
  .wrapper.layout-dark .contact-application .contact-app-list-mails,
  .wrapper.layout-dark .contact-application .contact-app-mail-content {
    background-color: #404258;
    border-color: #616279; }
  .wrapper.layout-dark .contact-application .contact-app-menu .form-group-compose {
    border-bottom: 1px solid #616279; }
  .wrapper.layout-dark .contact-application .list-group-item.active,
  .wrapper.layout-dark .contact-application .list-group-item.active:focus,
  .wrapper.layout-dark .contact-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff; }
  .wrapper.layout-dark .contact-application .list-group-item-action {
    color: #bdbdc7; }
    .wrapper.layout-dark .contact-application .list-group-item-action.bg-grey.bg-lighten-4 {
      background-color: #4f5069 !important; }
  .wrapper.layout-dark .todo-application .todo-app-sidebar,
  .wrapper.layout-dark .todo-application .todo-app-list-mails,
  .wrapper.layout-dark .todo-application .todo-app-mail-content {
    background-color: #404258;
    border-color: #616279; }
  .wrapper.layout-dark .todo-application .todo-app-menu .form-group-compose {
    border-bottom: 1px solid #616279; }
  .wrapper.layout-dark .todo-application .list-group-item.active,
  .wrapper.layout-dark .todo-application .list-group-item.active:focus,
  .wrapper.layout-dark .todo-application .list-group-item.active:hover {
    background-color: #009da0;
    border-color: #009da0;
    color: #fff; }
  .wrapper.layout-dark .todo-application .list-group-item-action {
    color: #bdbdc7; }
    .wrapper.layout-dark .todo-application .list-group-item-action.bg-grey.bg-lighten-4 {
      background-color: #4f5069 !important; }
  .wrapper.layout-dark .horizontal-timeline-wrapper li[data-radium="true"] {
    background-image: none !important; }
  .wrapper.layout-dark .invoice-template .bg-grey.bg-lighten-4 {
    background-color: #404258 !important; }
  .wrapper.layout-dark .login-layout {
    background-color: #404258; }
  .wrapper.layout-dark ol.progtrckr li.progtrckr-doing {
    color: #fff; }
  .wrapper.layout-dark .ReactTable .rt-thead.-filters input,
  .wrapper.layout-dark .ReactTable .rt-thead.-filters select {
    background-color: #2e2f42; }
  .wrapper.layout-dark .ReactTable .-pagination .-btn {
    color: #fff; }
  .wrapper.layout-dark .rbc-off-range-bg {
    background-color: #404258; }
  .wrapper.layout-dark .rbc-today {
    background-color: #2e2f42; }
  .wrapper.layout-dark .rbc-toolbar button {
    color: #bdbdc7; }
  .wrapper.layout-dark .customizer .customizer-content {
    background-color: #2e2f42; }

.form-group .checkbox label, .form-group .radio label {
  color: rgba(0, 0, 0, 0.87); }

.btn-group span:first-child:not(:last-child) .btn.dropdown-toggle, .input-group span:first-child:not(:last-child) .btn.dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group span:last-child:not(:first-child) .btn.dropdown-toggle, .input-group span:last-child:not(:first-child) .btn.dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group span:not(:last-child):not(:first-child) .btn.dropdown-toggle, .input-group span:not(:last-child):not(:first-child) .btn.dropdown-toggle {
  border-radius: 0; }

.list-group-item:hover {
  z-index: 0; }

.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
  background-color: #007bff;
  border-color: #007bff; }

.label {
  padding-bottom: .1em !important;
  letter-spacing: 0.5px; }
  .label.label-default {
    background-color: #777 !important; }

.media-list.media-bordered .media {
  border-top: 1px solid #E4E7ED;
  padding: 1rem 0; }
  .media-list.media-bordered .media:first-child {
    border-top: 0; }

.media img, .media iframe {
  margin-right: 1rem !important; }

.media .media-body + a img {
  margin-right: 0 !important;
  margin-left: 1rem; }

.media .media-body.text-right + a img {
  margin-left: 0; }

.media .media-body, .media .media-left, .media .media-right {
  padding-top: 0; }

.media .round-media {
  border-radius: 50%; }

.dropdown-menu {
  display: none;
  opacity: inherit; }
  .dropdown-menu .active a {
    background-color: #007bff; }
    .dropdown-menu .active a:focus, .dropdown-menu .active a:hover {
      background-color: #007bff; }
  .dropdown-menu.show {
    -webkit-transform: none;
            transform: none;
    opacity: 1; }

.form-control.open ~ .dropdown-menu {
  -webkit-transform: none;
          transform: none;
  opacity: 1; }

.tab-content {
  padding: 1rem; }

.nav-tabs .nav-link {
  height: 100%; }
  .nav-tabs .nav-link.active {
    background-color: transparent;
    border-bottom-color: white; }

.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border-bottom-color: white; }

ngb-accordion [role=tabpanel] {
  padding: 0 2.5rem !important; }

ngb-accordion .card {
  border-bottom: 2px solid #f5f7fa;
  margin: 0; }

.swal2-modal .swal2-confirm {
  background-color: #28a745 !important; }

.swal2-modal .swal2-cancel {
  background-color: #dc3545 !important; }

[dir="rtl"] .media img, [dir="rtl"] .media iframe {
  margin-left: 1rem !important;
  margin-right: auto !important; }

[dir="rtl"] .media .media-body + a img {
  margin-left: 0 !important;
  margin-right: 1rem; }

[dir="rtl"] .media .media-body.text-right + a img {
  margin-right: 0;
  margin-left: auto; }

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white; }

.chat-application {
  overflow: hidden; }
  .chat-application .content-wrapper {
    padding: 0 !important; }
  .chat-application .chat-sidebar {
    background: white;
    position: fixed;
    width: 300px;
    vertical-align: top;
    height: calc(100vh - 168px);
    border-right: 1px solid #E4E7ED;
    z-index: 1; }
  .chat-application .chat-fixed-search {
    width: 300px;
    position: fixed;
    z-index: 999;
    background: #fff;
    border-bottom: 1px solid #E4E7ED;
    border-right: 1px solid #E4E7ED; }
  .chat-application .users-list-padding {
    padding-top: 63px; }
  .chat-application .list-group-item-heading {
    font-size: 0.8rem;
    font-weight: 500; }
    .chat-application .list-group-item-heading span {
      font-size: 0.8rem;
      font-weight: 400; }
  .chat-application .chat-name {
    margin-left: 300px;
    border-bottom: 1px solid #E4E7ED; }
  .chat-application .chat-app-window {
    margin-left: 300px;
    padding: 20px 30px;
    overflow-y: scroll;
    text-align: center;
    height: calc(100vh - 323px);
    background-color: #fff; }
  .chat-application .chat-app-form {
    margin-left: 300px;
    position: relative;
    padding: 20px 10px;
    overflow: hidden; }
  .chat-application .chats {
    padding: 0; }
    .chat-application .chats .chat-body {
      display: block;
      margin: 10px 30px 0 0;
      overflow: hidden; }
      .chat-application .chats .chat-body .chat-content {
        text-align: right;
        position: relative;
        display: block;
        float: right;
        padding: 8px 15px;
        margin: 0 20px 10px 0;
        clear: both;
        color: #fff;
        background-color: #007bff;
        border-radius: 4px; }
        .chat-application .chats .chat-body .chat-content:before {
          position: absolute;
          top: 10px;
          right: -10px;
          width: 0;
          height: 0;
          content: '';
          border: 5px solid transparent;
          border-left-color: #007bff; }
        .chat-application .chats .chat-body .chat-content + .chat-content:before {
          border-color: transparent; }
        .chat-application .chats .chat-body .chat-content p {
          margin: 0;
          color: #fff; }
    .chat-application .chats .chat-avatar {
      float: right; }
      .chat-application .chats .chat-avatar .avatar {
        width: 50px;
        margin-top: -10px; }
    .chat-application .chats .time {
      color: #adb5bd;
      font-size: 12px;
      text-align: center;
      margin: 40px 0; }
    .chat-application .chats .chat-left .chat-avatar {
      float: left; }
    .chat-application .chats .chat-left .chat-body {
      margin-right: 0;
      margin-left: 30px; }
    .chat-application .chats .chat-left .chat-content {
      text-align: left;
      float: left;
      margin: 0 0 10px 20px;
      color: #343a40;
      background-color: #ffffff; }
      .chat-application .chats .chat-left .chat-content + .chat-content:before {
        border-color: transparent; }
      .chat-application .chats .chat-left .chat-content:before {
        right: auto;
        left: -10px;
        border-right-color: white;
        border-left-color: transparent; }
      .chat-application .chats .chat-left .chat-content p {
        color: #343a40; }
  .chat-application .form-control-position {
    cursor: pointer; }
    .chat-application .form-control-position.control-position-right {
      right: 18px;
      top: 2px;
      cursor: pointer; }
  @media (max-width: 767.98px) {
    .chat-application .chat-app-window {
      height: calc(100% - 132px);
      margin-left: 0; }
    .chat-application .chat-app-form {
      margin-left: 0; }
    .chat-application .chat-name {
      margin-left: 0; }
    .chat-application .chat-sidebar {
      left: 0; } }

[dir="rtl"] .chat-application .chat-sidebar {
  border-left: 1px solid #E4E7ED;
  border-right: 0px; }

[dir="rtl"] .chat-application .chat-fixed-search {
  border-left: 1px solid #E4E7ED;
  border-right: 0px; }

[dir="rtl"] .chat-application .chat-name {
  margin-right: 300px;
  margin-left: 0px; }

[dir="rtl"] .chat-application .chat-app-window {
  margin-right: 300px;
  margin-left: 0px; }

[dir="rtl"] .chat-application .chat-app-form {
  margin-right: 300px;
  margin-left: 0px; }

[dir="rtl"] .chat-application .chats .chat-body {
  margin: 10px 0 0 30px; }
  [dir="rtl"] .chat-application .chats .chat-body .chat-content {
    text-align: left;
    float: left;
    margin: 0 0 10px 20px; }
    [dir="rtl"] .chat-application .chats .chat-body .chat-content:before {
      left: -10px;
      right: auto;
      border-right-color: #007bff;
      border-left-color: transparent; }
    [dir="rtl"] .chat-application .chats .chat-body .chat-content + .chat-content:before {
      border-color: transparent; }

[dir="rtl"] .chat-application .chats .chat-avatar {
  float: left; }

[dir="rtl"] .chat-application .chats .chat-left .chat-avatar {
  float: right; }

[dir="rtl"] .chat-application .chats .chat-left .chat-body {
  margin-left: 0;
  margin-right: 30px; }

[dir="rtl"] .chat-application .chats .chat-left .chat-content {
  text-align: right;
  float: right;
  margin: 0 20px 10px 0; }
  [dir="rtl"] .chat-application .chats .chat-left .chat-content:before {
    left: auto;
    right: -10px;
    border-left-color: white;
    border-right-color: transparent; }

[dir="rtl"] .chat-application .form-control-position.control-position-right {
  left: 18px;
  right: auto; }

@media (max-width: 767.98px) {
  [dir="rtl"] .chat-application .chat-app-window {
    height: calc(100% - 132px);
    margin-left: auto;
    margin-right: 0; }
  [dir="rtl"] .chat-application .chat-app-form {
    margin-left: auto;
    margin-right: 0; }
  [dir="rtl"] .chat-application .chat-name {
    margin-left: auto;
    margin-right: 0; }
  [dir="rtl"] .chat-application .chat-sidebar {
    right: 0;
    left: auto; } }

.email-application .email-app-sidebar {
  z-index: 3;
  background: white;
  position: fixed;
  width: 250px;
  height: calc(100vh - 150px);
  border-right: 1px solid #e4e7ed; }
  .email-application .email-app-sidebar .email-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 10px 20px; }

.email-application .email-app-content {
  margin-top: 0px;
  padding: 0px;
  min-height: 100%; }

.email-application .email-app-list-mails {
  position: relative;
  padding: 0px;
  width: 100%;
  overflow-y: hidden;
  background-color: #ffffff;
  height: calc(100vh - 150px);
  overflow-x: hidden; }
  .email-application .email-app-list-mails .email-actions {
    border-top: 1px solid #e6ecf5;
    border-bottom: 1px solid #e6ecf5; }
    .email-application .email-app-list-mails .email-actions .email-action-icon {
      margin: 16px 12px; }
      .email-application .email-app-list-mails .email-actions .email-action-icon > button {
        margin: 0;
        padding: 0; }
  .email-application .email-app-list-mails .scrollbar-container {
    height: calc(100% - 140px); }
  .email-application .email-app-list-mails .list-group-item {
    padding: 0.75rem;
    cursor: pointer; }
    .email-application .email-app-list-mails .list-group-item .form-check-input {
      position: relative;
      margin: 0; }

.email-application .email-app-mail-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 0px;
  background-color: #ffffff;
  min-height: calc(100vh - 150px);
  transition: all 0.5s ease-in-out; }

.email-application .email-app-mail-content-detail {
  position: relative;
  padding: 20px;
  max-height: calc(100vh - 150px);
  overflow-y: auto; }

.email-application .hide-email-content {
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }

.email-application .email-app-menu .form-group-compose {
  border-bottom: 1px solid #e4e7ed; }

.email-application .email-app-menu .list-group-messages {
  border-bottom: 1px solid #e4e7ed; }
  .email-application .email-app-menu .list-group-messages .list-group-item {
    cursor: pointer; }
  .email-application .email-app-menu .list-group-messages a {
    padding: 0.7rem 1rem;
    font-size: 1.1rem; }
    .email-application .email-app-menu .list-group-messages a.active {
      border-radius: 3px !important; }
      .email-application .email-app-menu .list-group-messages a.active svg {
        stroke: #fff; }

.email-application .email-app-list {
  position: relative;
  height: 100%; }
  .email-application .email-app-list p {
    margin-bottom: 0.2rem; }

.email-application .list-group-item.active,
.email-application .list-group-item.active:hover,
.email-application .list-group-item.active:focus {
  color: #fff; }

.email-application .avatar-md {
  width: 40px; }

.email-application .avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  border-radius: 1000px; }

.email-application .avatar-md span.text-circle {
  width: 40px;
  height: 40px; }

.email-application .avatar span.text-circle {
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 1.2rem;
  background: #babfc7;
  display: table-cell; }

.email-application .email-app-content-wrapper {
  overflow-y: scroll;
  height: calc(100% - 30px); }
  .email-application .email-app-content-wrapper .email-app-options,
  .email-application .email-app-content-wrapper .email-app-title {
    border-bottom: 1px solid #e4e7ed; }

.email-application .chat-fixed-search {
  z-index: 999;
  background: #fff;
  padding: 2rem 1rem; }
  .email-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed; }

@media (min-width: 992px) {
  .email-app-list-mails {
    width: 35% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .email-application .email-app-mail-content {
    width: 64%; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .email-app-list-mails {
    width: 35% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .email-application .email-app-mail-content {
    width: 65%; } }

@media (min-width: 1200px) {
  .email-app-content {
    margin-left: 250px; } }

@media (max-width: 767.98px) {
  .email-app-mail-content {
    position: absolute !important;
    top: 0 !important;
    left: 15px !important;
    width: 98% !important;
    height: 100%;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    max-height: calc(100vh - 150px); } }

@media (max-width: 991.98px) {
  .email-application .email-app-sidebar {
    left: 0; } }

[dir="rtl"] .email-application .email-app-sidebar {
  border-left: 1px solid #e4e7ed;
  border-right: 0px; }

[dir="rtl"] .email-application .email-app-mail-content {
  float: right; }

@media (min-width: 992px) {
  [dir="rtl"] .email-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir="rtl"] .email-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 1200px) {
  [dir="rtl"] .email-app-content {
    margin-right: 250px;
    margin-left: 0px; } }

@media (max-width: 767.98px) {
  [dir="rtl"] .email-app-mail-content {
    right: 15px !important;
    left: auto; } }

@media (max-width: 991.98px) {
  [dir="rtl"] .email-application .email-app-sidebar {
    right: 0;
    left: auto; } }

.todo-application .todo-app-sidebar {
  z-index: 3;
  background: white;
  position: fixed;
  width: 250px;
  height: calc(100vh - 150px);
  border-right: 1px solid #e4e7ed; }
  .todo-application .todo-app-sidebar .todo-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 20px 25px; }

.todo-application .todo-app-content {
  margin-top: 0px;
  padding: 0px;
  min-height: 100%; }

.todo-application .todo-app-list-mails {
  position: relative;
  padding: 0px;
  width: 100%;
  overflow-y: hidden;
  background-color: #ffffff;
  height: calc(100vh - 150px); }

.todo-application .todo-app-mail-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 0px;
  background-color: #ffffff;
  min-height: calc(100vh - 212px);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out; }

.todo-application .todo-app-content-detail {
  position: relative;
  padding: 30px;
  max-height: calc(100vh - 212px);
  overflow-y: auto; }

.todo-application .hide-email-content {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }

.todo-application .todo-app-menu .form-group-compose {
  border-bottom: 1px solid #e4e7ed; }

.todo-application .todo-app-menu .list-group-messages .list-group-item {
  cursor: pointer; }
  .todo-application .todo-app-menu .list-group-messages .list-group-item.active {
    border-radius: 3px !important; }
    .todo-application .todo-app-menu .list-group-messages .list-group-item.active svg {
      color: #fff !important; }

.todo-application .todo-app-menu .list-group-messages a {
  padding: 0.85rem 1rem;
  font-size: 1.1rem; }

.todo-application .todo-app-list {
  position: relative;
  height: 90%;
  padding: 1rem 0; }
  .todo-application .todo-app-list p {
    margin-bottom: 0.2rem; }
  .todo-application .todo-app-list .list-group-item {
    padding: 0.75rem;
    cursor: pointer; }
    .todo-application .todo-app-list .list-group-item .form-check-input {
      position: relative;
      margin: 0; }

.todo-application .list-group-item.active,
.todo-application .list-group-item.active:hover,
.todo-application .list-group-item.active:focus {
  color: #fff;
  border-radius: 3px !important; }

.todo-application .avatar-md {
  width: 40px; }

.todo-application .avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  border-radius: 1000px; }

.todo-application .avatar-md span.text-circle {
  width: 40px;
  height: 40px; }

.todo-application .avatar span.text-circle {
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 1.2rem;
  background: #babfc7;
  display: table-cell; }

.todo-application .todo-app-content-wrapper {
  overflow-y: scroll;
  height: calc(100% - 30px); }
  .todo-application .todo-app-content-wrapper .todo-app-options,
  .todo-application .todo-app-content-wrapper .todo-app-title {
    border-bottom: 1px solid #e4e7ed; }

.todo-application .chat-fixed-search {
  z-index: 999;
  background: #fff;
  padding: 2rem 1rem; }
  .todo-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed; }

@media (min-width: 992px) {
  .todo-app-list-mails {
    width: 50% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .todo-application .todo-app-mail-content {
    width: 49%;
    height: 100%; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .todo-app-list-mails {
    width: 35% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .todo-application .todo-app-mail-content {
    width: 65%; } }

@media (min-width: 1200px) {
  .todo-app-content {
    margin-left: 250px; } }

@media (max-width: 767.98px) {
  .todo-app-mail-content {
    position: absolute !important;
    top: 0 !important;
    left: 15px !important;
    width: 98% !important;
    height: 100%;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    max-height: calc(100vh - 150px); } }

@media (max-width: 991.98px) {
  .todo-application .todo-app-sidebar {
    left: 0; } }

[dir="rtl"] .todo-application .todo-app-sidebar {
  border-left: 1px solid #e4e7ed;
  border-right: 0px; }

[dir="rtl"] .todo-application .todo-app-mail-content {
  float: right; }

@media (min-width: 992px) {
  [dir="rtl"] .todo-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir="rtl"] .todo-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 1200px) {
  [dir="rtl"] .todo-app-content {
    margin-right: 250px;
    margin-left: 0px; } }

@media (max-width: 767.98px) {
  [dir="rtl"] .todo-app-mail-content {
    right: 15px !important;
    left: auto; } }

@media (max-width: 991.98px) {
  [dir="rtl"] .todo-application .todo-app-sidebar {
    right: 0;
    left: auto; } }

.contact-application .contact-app-sidebar {
  z-index: 3;
  background: white;
  position: fixed;
  width: 250px;
  height: calc(100vh - 150px);
  border-right: 1px solid #e4e7ed; }
  .contact-application .contact-app-sidebar .contact-app-sidebar-content {
    position: relative;
    height: 100%;
    padding: 20px 25px; }

.contact-application .contact-app-content {
  margin-top: 0px;
  padding: 0px;
  min-height: 100%; }

.contact-application .contact-app-list-mails {
  position: relative;
  padding: 0px;
  width: 100%;
  overflow-y: hidden;
  background-color: #ffffff;
  height: calc(100vh - 150px); }

.contact-application .contact-app-mail-content {
  float: left;
  width: 100%;
  position: relative;
  padding: 0px;
  background-color: #ffffff;
  min-height: calc(100vh - 212px);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out; }

.contact-application .contact-app-content-detail {
  position: relative;
  padding: 30px;
  max-height: calc(100vh - 212px);
  overflow-y: auto; }

.contact-application .hide-email-content {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -webkit-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }

.contact-application .contact-app-menu .form-group-compose {
  border-bottom: 1px solid #e4e7ed; }

.contact-application .contact-app-menu .list-group-messages .list-group-item {
  cursor: pointer; }
  .contact-application .contact-app-menu .list-group-messages .list-group-item.active {
    border-radius: 3px !important; }
    .contact-application .contact-app-menu .list-group-messages .list-group-item.active svg {
      color: #fff !important; }

.contact-application .contact-app-menu .list-group-messages a {
  padding: 0.85rem 1rem;
  font-size: 1.1rem; }

.contact-application .contact-app-list {
  position: relative;
  height: 90%;
  padding: 1rem 0; }
  .contact-application .contact-app-list p {
    margin-bottom: 0.2rem; }
  .contact-application .contact-app-list .list-group-item {
    cursor: pointer; }

.contact-application .list-group-item.active,
.contact-application .list-group-item.active:hover,
.contact-application .list-group-item.active:focus {
  color: #fff; }

.contact-application .avatar-md {
  width: 40px; }

.contact-application .avatar img {
  width: 100%;
  max-width: 100%;
  height: auto;
  border: 0;
  border-radius: 1000px; }

.contact-application .avatar-md span.text-circle {
  width: 40px;
  height: 40px; }

.contact-application .avatar span.text-circle {
  text-align: center;
  vertical-align: middle;
  color: #fff;
  font-size: 1.2rem;
  background: #babfc7;
  display: table-cell; }

.contact-application .contact-app-content-wrapper {
  overflow-y: scroll;
  height: calc(100% - 30px); }
  .contact-application .contact-app-content-wrapper .contact-app-options,
  .contact-application .contact-app-content-wrapper .contact-app-title {
    border-bottom: 1px solid #e4e7ed; }

.contact-application .chat-fixed-search {
  z-index: 999;
  background: #fff;
  padding: 2rem 1rem; }
  .contact-application .chat-fixed-search fieldset {
    border-bottom: 1px solid #e4e7ed; }

@media (min-width: 992px) {
  .contact-app-list-mails {
    width: 30% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .contact-application .contact-app-mail-content {
    width: 69%;
    height: 100%; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .contact-app-list-mails {
    width: 35% !important;
    border-right: 1px solid #e6ecf5;
    float: left; }
  .contact-application .contact-app-mail-content {
    width: 65%; } }

@media (min-width: 1200px) {
  .contact-app-content {
    margin-left: 250px; } }

@media (max-width: 767.98px) {
  .contact-app-mail-content {
    position: absolute !important;
    top: 0 !important;
    left: 15px !important;
    width: 98% !important;
    height: 100%;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    max-height: calc(100vh - 150px); } }

@media (max-width: 991.98px) {
  .contact-application .contact-app-sidebar {
    left: 0; } }

[dir="rtl"] .contact-application .contact-app-sidebar {
  border-left: 1px solid #e4e7ed;
  border-right: 0px; }

[dir="rtl"] .contact-application .contact-app-mail-content {
  float: right; }

@media (min-width: 992px) {
  [dir="rtl"] .contact-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  [dir="rtl"] .contact-app-list-mails {
    border-left: 1px solid #e6ecf5;
    float: right;
    border-right: 0px; } }

@media (min-width: 1200px) {
  [dir="rtl"] .contact-app-content {
    margin-right: 250px;
    margin-left: 0px; } }

@media (max-width: 767.98px) {
  [dir="rtl"] .contact-app-mail-content {
    right: 15px !important;
    left: auto; } }

@media (max-width: 991.98px) {
  [dir="rtl"] .contact-application .contact-app-sidebar {
    right: 0;
    left: auto; } }

.gallery-thumbnail {
  max-width: 100%;
  height: auto; }

.grid-sizer, .grid-item {
  width: 20%; }

.grid-item figure,
.grid-item > div {
  margin: 0;
  margin: 0.4rem; }

/*  masonry grid media queries */
@media screen and (max-width: 2200px) {
  .grid-sizer, .grid-item {
    width: 20%; } }

@media screen and (max-width: 1800px) {
  .grid-sizer, .grid-item {
    width: 30%; } }

@media screen and (max-width: 1600px) {
  .grid-sizer, .grid-item {
    width: 33.33%; } }

@media screen and (max-width: 1224px) {
  .grid-sizer, .grid-item {
    width: 33.33%; } }

@media screen and (max-width: 980px) {
  .grid-sizer, .grid-item {
    width: 50%; } }

@media screen and (max-width: 720px) {
  .grid-sizer, .grid-item {
    width: 50%; } }

@media screen and (max-width: 480px) {
  .grid-sizer, .grid-item {
    width: 100%; } }

/* Gallery hover effects */
.grid-hover {
  position: relative;
  margin: 0 auto;
  padding: 1em 0 4em;
  list-style: none; }
  .grid-hover figure {
    position: relative;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 480px;
    max-height: 360px;
    background: #3085a3;
    text-align: center;
    cursor: pointer; }
    .grid-hover figure img {
      position: relative;
      display: block;
      min-height: 100%;
      max-width: 100%;
      opacity: 0.8; }
    .grid-hover figure figcaption {
      padding: 2em;
      color: #fff;
      text-transform: uppercase;
      font-size: 1.25em;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .grid-hover figure figcaption::before {
        pointer-events: none; }
      .grid-hover figure figcaption::after {
        pointer-events: none; }
      .grid-hover figure figcaption > a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0; }
    .grid-hover figure h2 {
      word-spacing: -0.15em;
      font-weight: 300;
      margin: 0; }
      .grid-hover figure h2 span {
        font-weight: 800; }
    .grid-hover figure p {
      margin: 0;
      letter-spacing: 1px;
      font-size: 68.5%; }

/*---------------*/
/***** Lily *****/
/*---------------*/
figure.effect-lily img {
  max-width: none;
  width: calc(100% + 50px);
  opacity: 0.7;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-40px, 0, 0);
          transform: translate3d(-40px, 0, 0); }

figure.effect-lily figcaption {
  text-align: left; }
  figure.effect-lily figcaption > div {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2em;
    width: 100%;
    height: 50%; }

figure.effect-lily h2 {
  -webkit-transform: translate3d(0, 40px, 0);
          transform: translate3d(0, 40px, 0);
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s; }

figure.effect-lily p {
  -webkit-transform: translate3d(0, 40px, 0);
          transform: translate3d(0, 40px, 0);
  color: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: opacity 0.2s, -webkit-transform 0.35s;
  transition: opacity 0.2s, transform 0.35s;
  transition: opacity 0.2s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-lily:hover img {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

figure.effect-lily:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition-delay: 0.05s;
  transition-duration: 0.35s; }

figure.effect-lily:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Sadie *****/
/*---------------*/
figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(72, 76, 97, 0) 0%, rgba(72, 76, 97, 0.8) 75%);
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(0, 50%, 0);
          transform: translate3d(0, 50%, 0);
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-sadie h2 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #484c61;
  transition: color 0.35s, -webkit-transform 0.35s;
  transition: transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

figure.effect-sadie p {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2em;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0, 10px, 0);
          transform: translate3d(0, 10px, 0); }

figure.effect-sadie:hover h2 {
  color: #fff;
  -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0);
          transform: translate3d(0, -50%, 0) translate3d(0, -40px, 0); }

figure.effect-sadie:hover figcaption::before {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Roxy *****/
/*---------------*/
figure.effect-roxy {
  background: linear-gradient(45deg, #ff89e9 0%, #05abe0 100%); }
  figure.effect-roxy img {
    max-width: none;
    width: calc(100% + 60px);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50px, 0, 0);
            transform: translate3d(-50px, 0, 0); }
  figure.effect-roxy figcaption {
    padding: 3em;
    text-align: left; }
    figure.effect-roxy figcaption::before {
      position: absolute;
      top: 30px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid #fff;
      content: '';
      opacity: 0;
      transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
      -webkit-transform: translate3d(-20px, 0, 0);
              transform: translate3d(-20px, 0, 0); }
  figure.effect-roxy h2 {
    padding: 30% 0 10px 0; }
  figure.effect-roxy p {
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0); }
  figure.effect-roxy:hover img {
    opacity: 0.7;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-roxy:hover figcaption::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-roxy:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Bubba *****/
/*---------------*/
figure.effect-bubba {
  background: #9e5406; }
  figure.effect-bubba img {
    opacity: 0.7;
    transition: opacity 0.35s; }
  figure.effect-bubba:hover img {
    opacity: 0.4; }
  figure.effect-bubba:hover figcaption::before {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  figure.effect-bubba:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  figure.effect-bubba:hover h2 {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-bubba:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-bubba figcaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1); }
  figure.effect-bubba figcaption::after {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    content: '';
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0); }
  figure.effect-bubba h2 {
    padding-top: 30%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  figure.effect-bubba p {
    padding: 20px 2.5em;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }

/*---------------*/
/***** Romeo *****/
/*---------------*/
figure.effect-romeo {
  -webkit-perspective: 1000px;
  perspective: 1000px; }
  figure.effect-romeo img {
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, 0, 300px);
            transform: translate3d(0, 0, 300px); }
  figure.effect-romeo:hover img {
    opacity: 0.6;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-romeo:hover figcaption::before {
    opacity: 0.5;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
            transform: translate3d(-50%, -50%, 0) rotate(45deg); }
  figure.effect-romeo:hover figcaption::after {
    opacity: 0.5;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
            transform: translate3d(-50%, -50%, 0) rotate(-45deg); }
  figure.effect-romeo:hover h2 {
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0);
            transform: translate3d(0, -50%, 0) translate3d(0, -100%, 0); }
  figure.effect-romeo:hover p {
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0);
            transform: translate3d(0, -50%, 0) translate3d(0, 100%, 0); }
  figure.effect-romeo figcaption::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 1px;
    background: #fff;
    content: '';
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
  figure.effect-romeo figcaption::after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 1px;
    background: #fff;
    content: '';
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-50%, -50%, 0);
            transform: translate3d(-50%, -50%, 0); }
  figure.effect-romeo h2 {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0);
            transform: translate3d(0, -50%, 0) translate3d(0, -150%, 0); }
  figure.effect-romeo p {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    padding: 0.25em 2em;
    -webkit-transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0);
            transform: translate3d(0, -50%, 0) translate3d(0, 150%, 0); }

/*---------------*/
/***** Layla *****/
/*---------------*/
figure.effect-layla {
  background: #18a367; }
  figure.effect-layla img {
    height: 390px;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }
  figure.effect-layla figcaption {
    padding: 3em; }
    figure.effect-layla figcaption::before {
      position: absolute;
      content: '';
      opacity: 0;
      top: 50px;
      right: 30px;
      bottom: 50px;
      left: 30px;
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      -webkit-transform: scale(0, 1);
              transform: scale(0, 1);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }
    figure.effect-layla figcaption::after {
      position: absolute;
      content: '';
      opacity: 0;
      top: 30px;
      right: 50px;
      bottom: 30px;
      left: 50px;
      border-right: 1px solid #fff;
      border-left: 1px solid #fff;
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 100% 0;
      transform-origin: 100% 0;
      transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }
  figure.effect-layla h2 {
    padding-top: 26%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0); }
  figure.effect-layla p {
    padding: 0.5em 2em;
    text-transform: none;
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }
  figure.effect-layla:hover img {
    opacity: 0.7;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition-delay: 0.15s; }
  figure.effect-layla:hover figcaption::before {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  figure.effect-layla:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition-delay: 0.15s; }
  figure.effect-layla:hover h2 {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition-delay: 0.15s; }
  figure.effect-layla:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    transition-delay: 0.15s; }

/*---------------*/
/***** Honey *****/
/*---------------*/
figure.effect-honey {
  background: #4a3753; }
  figure.effect-honey img {
    opacity: 0.9;
    transition: opacity 0.35s; }
  figure.effect-honey:hover img {
    opacity: 0.5; }
  figure.effect-honey:hover figcaption::before {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-honey:hover h2 {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
    figure.effect-honey:hover h2 i {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  figure.effect-honey figcaption::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background: #fff;
    content: '';
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s; }
  figure.effect-honey h2 {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em 1.5em;
    width: 100%;
    text-align: left;
    -webkit-transform: translate3d(0, -30px, 0);
            transform: translate3d(0, -30px, 0);
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s; }
    figure.effect-honey h2 i {
      font-style: normal;
      opacity: 0;
      transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
      -webkit-transform: translate3d(0, -30px, 0);
              transform: translate3d(0, -30px, 0); }

/*---------------*/
/***** Oscar *****/
/*---------------*/
figure.effect-oscar {
  background: linear-gradient(45deg, #22682a 0%, #9b4a1b 40%, #3a342a 100%); }
  figure.effect-oscar img {
    opacity: 0.9;
    transition: opacity 0.35s; }
  figure.effect-oscar figcaption {
    padding: 3em;
    background-color: rgba(58, 52, 42, 0.7);
    transition: background-color 0.35s; }
    figure.effect-oscar figcaption::before {
      position: absolute;
      top: 30px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid #fff;
      content: '';
      opacity: 0;
      transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
      -webkit-transform: scale(0);
              transform: scale(0); }
  figure.effect-oscar h2 {
    margin: 20% 0 10px 0;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0); }
  figure.effect-oscar p {
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale(0);
            transform: scale(0); }
  figure.effect-oscar:hover h2 {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-oscar:hover figcaption {
    background-color: rgba(58, 52, 42, 0); }
    figure.effect-oscar:hover figcaption::before {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1); }
  figure.effect-oscar:hover p {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  figure.effect-oscar:hover img {
    opacity: 0.4; }

/*---------------*/
/***** Marley *****/
/*---------------*/
figure.effect-marley figcaption {
  text-align: right; }

figure.effect-marley h2 {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0;
  top: 30px;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0); }
  figure.effect-marley h2::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 4px;
    background: #fff;
    content: '';
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-marley p {
  position: absolute;
  right: 30px;
  left: 30px;
  padding: 10px 0;
  bottom: 30px;
  line-height: 1.5;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-marley:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  figure.effect-marley:hover h2::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }

figure.effect-marley:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

/*---------------*/
/***** Ruby *****/
/*---------------*/
figure.effect-ruby {
  background-color: #17819c; }
  figure.effect-ruby img {
    opacity: 0.7;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: scale(1.15);
            transform: scale(1.15); }
  figure.effect-ruby:hover img {
    opacity: 0.5;
    -webkit-transform: scale(1);
            transform: scale(1); }
  figure.effect-ruby:hover h2 {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-ruby:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); }
  figure.effect-ruby h2 {
    margin-top: 20%;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  figure.effect-ruby p {
    margin: 1em 0 0;
    padding: 3em;
    border: 1px solid #fff;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, 20px, 0) scale(1.1);
            transform: translate3d(0, 20px, 0) scale(1.1); }

/*---------------*/
/***** Milo *****/
/*---------------*/
figure.effect-milo {
  background: #2e5d5a; }
  figure.effect-milo img {
    max-width: none;
    width: calc(100% + 60px);
    opacity: 1;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-30px, 0, 0) scale(1.12);
            transform: translate3d(-30px, 0, 0) scale(1.12);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  figure.effect-milo:hover img {
    opacity: 0.5;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
            transform: translate3d(0, 0, 0) scale(1); }
  figure.effect-milo:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-milo h2 {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1em 1.2em; }
  figure.effect-milo p {
    padding: 0 10px 0 0;
    width: 50%;
    border-right: 1px solid #fff;
    text-align: right;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-40px, 0, 0);
            transform: translate3d(-40px, 0, 0); }

/*---------------*/
/***** Dexter *****/
/*---------------*/
figure.effect-dexter {
  background: linear-gradient(to bottom, #258dc8 0%, #683c13 100%); }
  figure.effect-dexter img {
    transition: opacity 0.35s; }
  figure.effect-dexter:hover img {
    opacity: 0.4; }
  figure.effect-dexter:hover figcaption::after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-dexter:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-dexter figcaption {
    padding: 3em;
    text-align: left; }
    figure.effect-dexter figcaption::after {
      position: absolute;
      right: 30px;
      bottom: 30px;
      left: 30px;
      height: calc(50% - 30px);
      border: 7px solid #fff;
      content: '';
      transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      transition: transform 0.35s, -webkit-transform 0.35s;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0); }
  figure.effect-dexter p {
    position: absolute;
    right: 60px;
    bottom: 60px;
    left: 60px;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0, -100px, 0);
            transform: translate3d(0, -100px, 0); }

/*---------------*/
/***** Sarah *****/
/*---------------*/
figure.effect-sarah {
  background: #42b078; }
  figure.effect-sarah img {
    max-width: none;
    width: calc(100% + 20px);
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden; }
  figure.effect-sarah:hover img {
    opacity: 0.4;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-sarah:hover h2::after {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-sarah:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  figure.effect-sarah figcaption {
    text-align: left; }
  figure.effect-sarah h2 {
    position: relative;
    overflow: hidden;
    padding: 0.5em 0; }
    figure.effect-sarah h2::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background: #fff;
      content: '';
      transition: -webkit-transform 0.35s;
      transition: transform 0.35s;
      transition: transform 0.35s, -webkit-transform 0.35s;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); }
  figure.effect-sarah p {
    padding: 1em 0;
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }

/*---------------*/
/***** Zoe *****/
/*---------------*/
figure.effect-zoe figcaption {
  top: auto;
  bottom: 0;
  padding: 1em;
  height: 3.75em;
  background: #fff;
  color: #3c4a50;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0); }

figure.effect-zoe h2 {
  float: left;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
          transform: translate3d(0, 200%, 0);
  display: inline-block; }

figure.effect-zoe p.icon-links a {
  float: right;
  color: #3c4a50;
  font-size: 1.4em;
  transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  transition: transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 200%, 0);
          transform: translate3d(0, 200%, 0); }
  figure.effect-zoe p.icon-links a span::before {
    display: inline-block;
    padding: 8px 10px;
    font-family: 'feathericons';
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

figure.effect-zoe:hover p.icon-links a {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  figure.effect-zoe:hover p.icon-links a:hover {
    color: #252d31; }
  figure.effect-zoe:hover p.icon-links a:focus {
    color: #252d31; }
  figure.effect-zoe:hover p.icon-links a:nth-child(3) {
    transition-delay: 0.1s; }
  figure.effect-zoe:hover p.icon-links a:nth-child(2) {
    transition-delay: 0.15s; }
  figure.effect-zoe:hover p.icon-links a:first-child {
    transition-delay: 0.2s; }

figure.effect-zoe:hover p.description {
  opacity: 1; }

figure.effect-zoe:hover figcaption {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

figure.effect-zoe:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition-delay: 0.05s; }

figure.effect-zoe p.description {
  position: absolute;
  bottom: 8em;
  padding: 2em;
  color: #fff;
  text-transform: none;
  font-size: 90%;
  opacity: 0;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden; }

/*---------------*/
/***** Chico *****/
/*---------------*/
figure.effect-chico img {
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(1.12);
          transform: scale(1.12); }

figure.effect-chico:hover img {
  opacity: 0.5;
  -webkit-transform: scale(1);
          transform: scale(1); }

figure.effect-chico:hover figcaption::before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

figure.effect-chico:hover p {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1); }

figure.effect-chico figcaption {
  padding: 3em; }
  figure.effect-chico figcaption::before {
    position: absolute;
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
    border: 1px solid #fff;
    content: '';
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s; }

figure.effect-chico p {
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  margin: 0 auto;
  max-width: 200px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5); }

figure.effect-chico h2 {
  padding: 20% 0 20px 0; }

@media screen and (max-width: 50em) {
  .content {
    padding: 0 10px; }
  .grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%; } }

.display-inline {
  display: inline !important; }

.display-block {
  display: block !important; }

.display-inline-block {
  display: inline-block !important; }

.display-hidden {
  display: none !important; }

.display-table-cell {
  display: table-cell !important; }

.position-fixed {
  position: fixed !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-static {
  position: static !important; }

.position-top-0 {
  top: 0; }

.position-right-0 {
  right: 0; }

.position-bottom-0 {
  bottom: 0; }

.position-left-0 {
  left: 0; }

.no-edge-top {
  top: 0 !important; }

.no-edge-bottom {
  bottom: 0 !important; }

.no-edge-left {
  left: 0 !important; }

.no-edge-right {
  right: 0 !important; }

.cursor-pointer {
  cursor: pointer; }

.cursor-move {
  cursor: move; }

.cursor-default {
  cursor: default; }

.cursor-progress {
  cursor: progress; }

.cursor-not-allowed {
  cursor: not-allowed; }

.valign-top {
  vertical-align: top !important; }

.valign-middle {
  vertical-align: middle !important; }

.valign-bottom {
  vertical-align: bottom !important; }

.valign-baseline {
  vertical-align: baseline !important; }

.valign-text-top {
  vertical-align: top !important; }

.valign-text-bottom {
  vertical-align: text-bottom !important; }

.overflow-hidden {
  overflow: hidden; }

.overflow-visible {
  overflow: visible; }

.overflow-auto {
  overflow: auto; }

.overflow-scroll {
  overflow: scroll; }

.overflow-x-scroll {
  overflow: scroll; }

.overflow-y-scroll {
  overflow: scroll; }

.bullets-inside {
  list-style: inside; }

.list-style-circle {
  list-style: circle; }

.list-style-square {
  list-style: square; }

.list-style-icons {
  padding-left: 10px;
  margin-left: 0;
  list-style: none; }
  .list-style-icons > li i {
    float: left;
    width: 1em;
    margin: 5px 12px 0 0; }

.border {
  border: 1px solid; }

.border-top {
  border-top: 1px solid; }

.border-bottom {
  border-bottom: 1px solid; }

.border-left {
  border-left: 1px solid; }

.border-right {
  border-right: 1px solid; }

.border-2 {
  border-width: 2px !important; }

.border-top-2 {
  border-top-width: 2px !important; }

.border-bottom-2 {
  border-bottom-width: 2px !important; }

.border-left-2 {
  border-left-width: 2px !important; }

.border-right-2 {
  border-right-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-top-3 {
  border-top-width: 3px !important; }

.border-bottom-3 {
  border-bottom-width: 3px !important; }

.border-left-3 {
  border-left-width: 3px !important; }

.border-right-3 {
  border-right-width: 3px !important; }

.no-border {
  border: 0 !important; }

.no-border-top {
  border-top: 0 !important; }

.no-border-bottom {
  border-bottom: 0 !important; }

.no-border-left {
  border-left: 0 !important; }

.no-border-right {
  border-right: 0 !important; }

.no-border-top-radius {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.no-border-bottom-radius {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.no-border-top-left-radius {
  border-top-left-radius: 0 !important; }

.no-border-top-right-radius {
  border-top-right-radius: 0 !important; }

.no-border-bottom-left-radius {
  border-bottom-left-radius: 0 !important; }

.no-border-bottom-right-radius {
  border-bottom-right-radius: 0 !important; }

.box-shadow-0 {
  box-shadow: none !important; }

.box-shadow-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.box-shadow-2 {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.box-shadow-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

.box-shadow-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }

.box-shadow-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); }

.fit {
  max-width: 100% !important; }

.half-width {
  width: 50% !important; }

.full-width {
  width: 100% !important; }

.full-height {
  height: 100% !important; }

.full-height-vh {
  height: 100vh !important; }

.width-25 {
  width: 25px !important; }

.width-35 {
  width: 35px !important; }

.width-50 {
  width: 50px !important; }

.width-100 {
  width: 100px !important; }

.width-150 {
  width: 150px !important; }

.width-200 {
  width: 200px !important; }

.width-250 {
  width: 250px !important; }

.width-300 {
  width: 300px !important; }

.width-350 {
  width: 350px !important; }

.width-375 {
  width: 375px !important; }

.width-400 {
  width: 400px !important; }

.width-450 {
  width: 450px !important; }

.width-500 {
  width: 500px !important; }

.width-550 {
  width: 550px !important; }

.width-600 {
  width: 600px !important; }

.width-650 {
  width: 650px !important; }

.width-700 {
  width: 700px !important; }

.width-750 {
  width: 750px !important; }

.width-800 {
  width: 800px !important; }

.width-5-per {
  width: 5% !important; }

.width-10-per {
  width: 10% !important; }

.width-15-per {
  width: 15% !important; }

.width-20-per {
  width: 20% !important; }

.width-25-per {
  width: 25% !important; }

.width-30-per {
  width: 30% !important; }

.width-35-per {
  width: 35% !important; }

.width-40-per {
  width: 40% !important; }

.width-45-per {
  width: 45% !important; }

.width-50-per {
  width: 50% !important; }

.width-55-per {
  width: 55% !important; }

.width-60-per {
  width: 60% !important; }

.width-65-per {
  width: 65% !important; }

.width-70-per {
  width: 70% !important; }

.width-75-per {
  width: 75% !important; }

.width-80-per {
  width: 80% !important; }

.width-90-per {
  width: 90% !important; }

.width-95-per {
  width: 95% !important; }

.height-0 {
  height: 0 !important; }

.height-25 {
  height: 25px !important; }

.height-35 {
  height: 35px !important; }

.height-40 {
  height: 40px !important; }

.height-50 {
  height: 50px !important; }

.height-75 {
  height: 75px !important; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-200 {
  height: 200px !important; }

.height-250 {
  height: 250px !important; }

.height-300 {
  height: 300px !important; }

.height-350 {
  height: 350px !important; }

.height-400 {
  height: 400px !important; }

.height-450 {
  height: 450px !important; }

.height-500 {
  height: 500px !important; }

.height-550 {
  height: 550px !important; }

.height-600 {
  height: 600px !important; }

.height-650 {
  height: 650px !important; }

.height-700 {
  height: 700px !important; }

.height-750 {
  height: 750px !important; }

.height-800 {
  height: 800px !important; }

.height-5-per {
  height: 5% !important; }

.height-10-per {
  height: 10% !important; }

.height-15-per {
  height: 15% !important; }

.height-20-per {
  height: 20% !important; }

.height-25-per {
  height: 25% !important; }

.height-30-per {
  height: 30% !important; }

.height-35-per {
  height: 35% !important; }

.height-40-per {
  height: 40% !important; }

.height-45-per {
  height: 45% !important; }

.height-50-per {
  height: 50% !important; }

.height-55-per {
  height: 55% !important; }

.height-60-per {
  height: 60% !important; }

.height-65-per {
  height: 65% !important; }

.height-70-per {
  height: 70% !important; }

.height-75-per {
  height: 75% !important; }

.height-80-per {
  height: 80% !important; }

.line-height-0 {
  line-height: 0 !important; }

.line-height-1 {
  line-height: 1 !important; }

.line-height-2 {
  line-height: 1.5 !important; }

.line-height-2 {
  line-height: 2 !important; }

.rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-45-inverse {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rotate-90 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-90-inverse {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rotate-180 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-180-inverse {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.spinner {
  display: inline-block;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-reverse {
  display: inline-block;
  -webkit-animation: spin-reverse 1s linear infinite;
          animation: spin-reverse 1s linear infinite; }

@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

.bg-cover {
  background-size: cover !important; }

.bg-repeat {
  background-repeat: repeat !important; }

.bg-no-repeat {
  background-repeat: no-repeat !important; }

.img-xl {
  width: 64px !important;
  height: 64px !important; }

.img-lg {
  width: 44px !important;
  height: 44px !important; }

.img-sm {
  width: 36px !important;
  height: 36px !important; }

.img-xs {
  width: 32px !important;
  height: 32px !important; }

[dir="rtl"] .position-right-0 {
  right: auto;
  left: 0; }

[dir="rtl"] .position-left-0 {
  left: auto;
  right: 0; }

[dir="rtl"] .no-edge-left {
  right: 0 !important;
  left: auto !important; }

[dir="rtl"] .no-edge-right {
  left: 0 !important;
  right: auto !important; }

[dir="rtl"] .list-style-icons {
  padding-left: auto;
  padding-right: 10px;
  margin-right: 0;
  margin-left: auto; }
  [dir="rtl"] .list-style-icons > li i {
    float: right;
    margin: 5px 0 0 12px; }

[dir="rtl"] .border-left {
  border-right: 1px solid; }

[dir="rtl"] .border-right {
  border-left: 1px solid; }

[dir="rtl"] .border-left-2 {
  border-right-width: 2px !important; }

[dir="rtl"] .border-right-2 {
  border-left-width: 2px !important; }

[dir="rtl"] .border-left-3 {
  border-right-width: 3px !important; }

[dir="rtl"] .border-right-3 {
  border-left-width: 3px !important; }

[dir="rtl"] .no-border-left {
  border-right: 0 !important; }

[dir="rtl"] .no-border-right {
  border-left: 0 !important; }

[dir="rtl"] .no-border-top-left-radius {
  border-top-right-radius: 0 !important; }

[dir="rtl"] .no-border-top-right-radius {
  border-top-left-radius: 0 !important; }

[dir="rtl"] .no-border-bottom-left-radius {
  border-bottom-right-radius: 0 !important; }

[dir="rtl"] .no-border-bottom-right-radius {
  border-bottom-left-radius: 0 !important; }

.notification-bell-blink {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  opacity: 0.1;
  background-color: #ff4566;
  position: absolute;
  top: -6px;
  -webkit-animation: up-right 2s infinite;
          animation: up-right 2s infinite; }

.notification-bell-counter {
  position: absolute;
  text-align: center;
  font-size: 12px;
  letter-spacing: -0.5px;
  font-family: sans-serif;
  line-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #ff586b;
  color: #fff;
  right: -5px;
  top: -5px; }

@-webkit-keyframes up-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale 1, 5;
            transform: scale 1, 5;
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; } }

@keyframes up-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; }
  50% {
    -webkit-transform: scale 1, 5;
            transform: scale 1, 5;
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.1; } }

.margin-left-3 {
  margin-left: 3px; }

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  /* Safari */
  transition: opacity .2s; }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none; }
  .cd-horizontal-timeline.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1; }
  .cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto; }
    .cd-horizontal-timeline .timeline ul li, .cd-horizontal-timeline .timeline ol li {
      list-style: none; }
  .cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden; }
    .cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
      /* these are used to create a shadow effect at the sides of the timeline */
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px; }
    .cd-horizontal-timeline .events-wrapper::before {
      left: 0; }
    .cd-horizontal-timeline .events-wrapper::after {
      right: 0; }
  .cd-horizontal-timeline .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: #dfdfdf;
    /* Safari */
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s; }
  .cd-horizontal-timeline .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #007bff;
    /* IE 9 */
    -webkit-transform: scaleX(0);
    /* Safari 3-8 */
    transform: scaleX(0);
    /* IE 9 */
    -webkit-transform-origin: left center;
    /* Safari 3-8 */
    transform-origin: left center;
    /* Safari */
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s; }
  .cd-horizontal-timeline .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding-bottom: 15px;
    color: #383838;
    /* fix bug on Safari - text flickering while timeline translates */
    /* IE 9 */
    -webkit-transform: translateZ(0);
    /* Safari 3-8 */
    transform: translateZ(0); }
    .cd-horizontal-timeline .events a::after {
      /* this is used to create the event spot */
      content: '';
      position: absolute;
      left: 50%;
      right: auto;
      /* IE 9 */
      -webkit-transform: translateX(-50%);
      /* Safari 3-8 */
      transform: translateX(-50%);
      bottom: -5px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid #dfdede;
      background-color: white;
      /* Safari */
      transition: background-color .3s, border-color .3s; }
    .no-touch .cd-horizontal-timeline .events a:hover::after {
      background-color: #007bff;
      border-color: #007bff; }
    .cd-horizontal-timeline .events a.selected {
      pointer-events: none; }
      .cd-horizontal-timeline .events a.selected::after {
        background-color: #007bff;
        border-color: #007bff; }
    .cd-horizontal-timeline .events a.older-event::after {
      border-color: #007bff; }
  @media only screen and (min-width: 1100px) {
    .cd-horizontal-timeline {
      margin: 6em auto; }
      .cd-horizontal-timeline::before {
        /* never visible - this is used in jQuery to check the current MQ */
        content: 'desktop'; } }

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  /* IE 9 */
  -webkit-transform: translateY(-50%);
  /* Safari 3-8 */
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  /* Safari */
  transition: border-color .3s; }
  .cd-timeline-navigation a::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 25%;
    top: 25%;
    bottom: auto;
    right: auto; }
  .cd-timeline-navigation a.prev {
    left: 0;
    /* IE 9 */
    -webkit-transform: translateY(-50%) rotate(180deg);
    /* Safari 3-8 */
    transform: translateY(-50%) rotate(180deg); }
  .cd-timeline-navigation a.next {
    right: 0; }
  .no-touch .cd-timeline-navigation a:hover {
    border-color: #007bff; }
  .cd-timeline-navigation a.inactive {
    cursor: not-allowed; }
    .cd-timeline-navigation a.inactive::after {
      background-position: 0 -16px; }
    .no-touch .cd-timeline-navigation a.inactive:hover {
      border-color: #dfdfdf; }

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  /* Safari */
  transition: height .4s; }
  .cd-horizontal-timeline .events-content li {
    list-style: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 5%;
    opacity: 0; }
    .cd-horizontal-timeline .events-content li.selected {
      /* visible event content */
      position: relative;
      z-index: 2;
      opacity: 1;
      /* IE 9 */
      -webkit-transform: translateX(0);
      /* Safari 3-8 */
      transform: translateX(0); }
    .cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
      -webkit-animation-name: cd-enter-right;
      /* Safari 4.0 - 8.0 */
      animation-name: cd-enter-right; }
    .cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
      -webkit-animation-name: cd-enter-left;
      /* Safari 4.0 - 8.0 */
      animation-name: cd-enter-left; }
    .cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
      -webkit-animation-direction: reverse;
      /* Safari 4.0 - 8.0 */
      animation-direction: reverse; }
  .cd-horizontal-timeline .events-content li > * {
    margin: 0 auto;
    max-width: 800px; }
  .cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto; }
    .cd-horizontal-timeline .events-content em::before {
      content: '- '; }
  .cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
    line-height: 1.6; }

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(100%);
    /* Safari 3-8 */
    transform: translateX(100%); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(0%);
    /* Safari 3-8 */
    transform: translateX(0%); } }

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(100%);
    /* Safari 3-8 */
    transform: translateX(100%); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(0%);
    /* Safari 3-8 */
    transform: translateX(0%); } }

@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(-100%);
    /* Safari 3-8 */
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(0%);
    /* Safari 3-8 */
    transform: translateX(0%); } }

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(-100%);
    /* Safari 3-8 */
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(0%);
    /* Safari 3-8 */
    transform: translateX(0%); } }

[dir="rtl"] .cd-horizontal-timeline .events-wrapper::before {
  right: 0;
  left: auto; }

[dir="rtl"] .cd-horizontal-timeline .events-wrapper::after {
  left: 0;
  right: auto; }

[dir="rtl"] .cd-horizontal-timeline .events {
  right: 0;
  left: auto; }

[dir="rtl"] .cd-horizontal-timeline .filling-line {
  right: 0;
  left: auto; }

[dir="rtl"] .cd-timeline-navigation a::after {
  /* arrow icon */
  right: 25%;
  left: auto; }

[dir="rtl"] .cd-timeline-navigation a.prev {
  right: 0;
  left: auto; }

[dir="rtl"] .cd-timeline-navigation a.next {
  left: 0;
  right: auto; }

[dir="rtl"] .cd-horizontal-timeline .events-content li {
  right: 0;
  left: auto; }

#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em; }
  #cd-timeline::before {
    /* this is the vertical line */
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: #d7e4ed; }
  @media only screen and (min-width: 1100px) {
    #cd-timeline {
      margin-top: 3em;
      margin-bottom: 3em; }
      #cd-timeline::before {
        left: 50%;
        margin-left: -2px; } }

.cd-timeline-block {
  position: relative;
  margin: 2em 0; }
  .cd-timeline-block::after {
    display: block;
    clear: both;
    content: ""; }
  .cd-timeline-block:first-child {
    margin-top: 0; }
  .cd-timeline-block:last-child {
    margin-bottom: 0; }
  @media only screen and (min-width: 1100px) {
    .cd-timeline-block {
      margin: 4em 0; }
      .cd-timeline-block:first-child {
        margin-top: 0; }
      .cd-timeline-block:last-child {
        margin-bottom: 0; } }

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05); }
  .cd-timeline-img img {
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px; }
  .cd-timeline-img.cd-picture {
    background: #75ce66; }
  .cd-timeline-img.cd-movie {
    background: #7b9d6f; }
  .cd-timeline-img.cd-location {
    background: #f0ca45; }
  @media only screen and (min-width: 1100px) {
    .cd-timeline-img {
      width: 60px;
      height: 60px;
      left: 50%;
      margin-left: -30px;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -webkit-backface-visibility: hidden; }
      .cssanimations .cd-timeline-img.is-hidden {
        visibility: hidden; }
      .cssanimations .cd-timeline-img.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-1 0.6s;
        /* Safari 4.0 - 8.0 */
        animation: cd-bounce-1 0.6s; } }

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale(0.5);
    /* Safari 3-8 */
    transform: scale(0.5); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari 3-8 */
    transform: scale(1.2); }
  100% {
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari 3-8 */
    transform: scale(1); } }

@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: scale(0.5);
    /* Safari 3-8 */
    transform: scale(0.5); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari 3-8 */
    transform: scale(1.2); }
  100% {
    /* IE 9 */
    -webkit-transform: scale(1);
    /* Safari 3-8 */
    transform: scale(1); } }

.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  background: #ffffff;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #d7e4ed; }
  .cd-timeline-content::after {
    display: block;
    clear: both;
    content: ""; }
  .cd-timeline-content h2 {
    color: #383838; }
  .cd-timeline-content .cd-read-more,
  .cd-timeline-content .cd-date {
    display: inline-block; }
  .cd-timeline-content p {
    margin: 1em 0;
    line-height: 1.6; }
  .cd-timeline-content .cd-read-more {
    float: right;
    padding: 0.8em 1em;
    background: #acb7c0;
    color: #ffffff;
    border-radius: 0.25em; }
    .no-touch .cd-timeline-content .cd-read-more:hover {
      background-color: #bac4cb; }
  .cd-timeline-content .cd-date {
    float: left;
    padding: 0.8em 0;
    opacity: 0.7; }
  .cd-timeline-content::before {
    content: "";
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 16px solid transparent;
    border-right: 16px solid #ffffff; }
  @media only screen and (min-width: 1100px) {
    .cd-timeline-content {
      margin-left: 0;
      padding: 1.6em;
      width: 45%; }
      .cd-timeline-content::before {
        top: 24px;
        left: 100%;
        border-color: transparent;
        border-left-color: #ffffff; }
      .cd-timeline-content .cd-read-more {
        float: left; }
      .cd-timeline-content .cd-date {
        position: absolute;
        width: 100%;
        left: 122%;
        top: 6px; }
      .cd-timeline-block:nth-child(even) .cd-timeline-content {
        float: right; }
        .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
          top: 24px;
          left: auto;
          right: 100%;
          border-color: transparent;
          border-right-color: #ffffff; }
        .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
          float: right; }
        .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
          left: auto;
          right: 122%;
          text-align: right; }
      .cssanimations .cd-timeline-content.is-hidden {
        visibility: hidden; }
      .cssanimations .cd-timeline-content.bounce-in {
        visibility: visible;
        -webkit-animation: cd-bounce-2 0.6s;
        /* Safari 4.0 - 8.0 */
        animation: cd-bounce-2 0.6s; } }

@media only screen and (min-width: 1100px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    /* Safari 4.0 - 8.0 */
    animation: cd-bounce-2-inverse 0.6s; } }

@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(-100px);
    /* Safari 3-8 */
    transform: translateX(-100px); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(20px);
    /* Safari 3-8 */
    transform: translateX(20px); }
  100% {
    /* IE 9 */
    -webkit-transform: translateX(0);
    /* Safari 3-8 */
    transform: translateX(0); } }

@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(-100px);
    /* Safari 3-8 */
    transform: translateX(-100px); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(20px);
    /* Safari 3-8 */
    transform: translateX(20px); }
  100% {
    /* IE 9 */
    -webkit-transform: translateX(0);
    /* Safari 3-8 */
    transform: translateX(0); } }

@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(100px);
    /* Safari 3-8 */
    transform: translateX(100px); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(-20px);
    /* Safari 3-8 */
    transform: translateX(-20px); }
  100% {
    /* IE 9 */
    -webkit-transform: translateX(0);
    /* Safari 3-8 */
    transform: translateX(0); } }

@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    /* IE 9 */
    -webkit-transform: translateX(100px);
    /* Safari 3-8 */
    transform: translateX(100px); }
  60% {
    opacity: 1;
    /* IE 9 */
    -webkit-transform: translateX(-20px);
    /* Safari 3-8 */
    transform: translateX(-20px); }
  100% {
    /* IE 9 */
    -webkit-transform: translateX(0);
    /* Safari 3-8 */
    transform: translateX(0); } }

/* Timeline */
.timeline {
  position: relative;
  display: block;
  margin: 0;
  padding: 10px 0;
  list-style: none; }

.timeline:after {
  content: " ";
  display: table;
  clear: both; }

.timeline:before {
  content: " ";
  display: table; }

.timeline > li {
  list-style: none; }

.timeline > li:nth-child(even) {
  float: left;
  clear: left; }

.timeline > li:nth-child(odd) {
  float: right;
  clear: right; }

.timeline .media {
  border-bottom: 1px solid #eee;
  font-size: 13px; }

.timeline .media p {
  font-size: 13px; }

.timeline .media:last-child {
  border-bottom: 0; }

.timeline-line + .timeline-item {
  margin-top: -20px; }

.timeline-group {
  display: block;
  position: relative;
  margin: 20px 0;
  text-align: center;
  float: none !important;
  z-index: 1; }

.timeline-poster {
  margin-top: -20px; }

.timeline-poster .btn-link {
  color: #a1aab0; }

.timeline-poster .btn-link:active {
  color: #3e5771; }

.timeline-poster .btn-link:focus {
  color: #3e5771; }

.timeline-poster .btn-link:hover {
  color: #3e5771; }

.timeline-poster .btn-link.active {
  color: #3e5771; }

#timeline.timeline-center .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background-color: #ddd;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline.timeline-center .timeline-item {
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 0 50px 35px; }

#timeline.timeline-center .timeline-item:nth-child(even) {
  padding: 0px 35px 50px 0px; }

#timeline.timeline-center .timeline-item:nth-child(even):after {
  content: "";
  position: absolute;
  right: 19px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #ccc;
  z-index: 1;
  right: 25px;
  top: 27px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #fff; }

#timeline.timeline-center .timeline-item:nth-child(even):before {
  content: "";
  position: absolute;
  right: 24px;
  top: 27px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #ccc;
  z-index: 1; }

#timeline.timeline-center .timeline-item:nth-child(even) > .timeline-badge {
  right: -20px; }

#timeline.timeline-center .timeline-item:nth-child(odd) {
  padding: 0px 0px 50px 35px; }

#timeline.timeline-center .timeline-item:nth-child(odd):after {
  content: "";
  position: absolute;
  left: 19px;
  top: 10px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ccc;
  z-index: 1;
  left: 25px;
  top: 27px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #fff; }

#timeline.timeline-center .timeline-item:nth-child(odd):before {
  content: "";
  position: absolute;
  left: 24px;
  top: 27px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ccc;
  z-index: 1; }

#timeline.timeline-center .timeline-item:nth-child(odd) > .timeline-badge {
  left: -20px; }

#timeline.timeline-center .timeline-item > .timeline-badge {
  position: absolute;
  top: 12px;
  z-index: 1; }

#timeline.timeline-center .timeline-item > .timeline-badge > span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  transition: all ease 0.3s; }

#timeline.timeline-center .timeline-item > .timeline-badge > span i {
  color: #ffffff;
  font-size: 1.6rem;
  display: block;
  line-height: 40px;
  top: 0; }

#timeline.timeline-center .timeline-item > .timeline-badge > span:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-center .timeline-item > .timeline-badge > span:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-center .timeline-item > .timeline-badge > span:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-center .timeline-item > .timeline-badge > a.active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-center .timeline-item > .timeline-panel {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px; }

#timeline.timeline-center .timeline-item > .timeline-panel:hover .timeline-actions {
  display: block; }

#timeline.timeline-right .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  width: 4px;
  background-color: #ddd;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline.timeline-right .timeline-group {
  text-align: right; }

#timeline.timeline-right .timeline-item {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px 43px 35px 0px; }

#timeline.timeline-right .timeline-item:after {
  content: "";
  position: absolute;
  right: 33px;
  top: 21px;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #fff; }

#timeline.timeline-right .timeline-item:before {
  content: "";
  position: absolute;
  right: 32px;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #ccc;
  z-index: 1; }

#timeline.timeline-right .timeline-item > .timeline-badge {
  right: -8px; }

#timeline.timeline-right .timeline-item > .timeline-badge {
  position: absolute;
  top: 12px;
  z-index: 1; }

#timeline.timeline-right .timeline-item > .timeline-badge > span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  transition: all ease 0.3s; }

#timeline.timeline-right .timeline-item > .timeline-badge > span i {
  color: #ffffff;
  font-size: 1.6rem;
  display: block;
  line-height: 40px;
  top: 0; }

#timeline.timeline-right .timeline-item > .timeline-badge > span:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-right .timeline-item > .timeline-badge > span:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-right .timeline-item > .timeline-badge > span:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-right .timeline-item > .timeline-badge > a.active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-right .timeline-item > .timeline-panel {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px; }

#timeline.timeline-right .timeline-item > .timeline-panel:hover .timeline-actions {
  display: block; }

#timeline.timeline-right .timeline-item.block:nth-child(even):before {
  right: 8px;
  left: auto; }

#timeline.timeline-right .timeline-item.block:nth-child(even):after {
  right: 11px;
  left: auto; }

#timeline.timeline-right .timeline-item.block:nth-child(odd):after {
  right: 8px;
  right: auto; }

#timeline.timeline-left .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  width: 4px;
  background-color: #ddd;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline.timeline-left .timeline-group {
  text-align: left; }

#timeline.timeline-left .timeline-item {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 0 35px 43px; }

#timeline.timeline-left .timeline-item:after {
  content: "";
  position: absolute;
  left: 33px;
  top: 21px;
  width: 0;
  height: 0;
  z-index: 1;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #fff; }

#timeline.timeline-left .timeline-item:before {
  content: "";
  position: absolute;
  left: 32px;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 12px solid #ccc;
  z-index: 1; }

#timeline.timeline-left .timeline-item > .timeline-badge {
  left: -8px; }

#timeline.timeline-left .timeline-item > .timeline-badge {
  position: absolute;
  top: 12px;
  z-index: 1; }

#timeline.timeline-left .timeline-item > .timeline-badge > span {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  transition: all ease 0.3s; }

#timeline.timeline-left .timeline-item > .timeline-badge > span i {
  color: #ffffff;
  font-size: 1.6rem;
  display: block;
  line-height: 40px;
  top: 0; }

#timeline.timeline-left .timeline-item > .timeline-badge > span:active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-left .timeline-item > .timeline-badge > span:focus {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-left .timeline-item > .timeline-badge > span:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-left .timeline-item > .timeline-badge > a.active {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

#timeline.timeline-left .timeline-item > .timeline-panel {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px; }

#timeline.timeline-left .timeline-item > .timeline-panel:hover .timeline-actions {
  display: block; }

#timeline .timeline-item.block:nth-child(even) {
  width: 100% !important;
  margin-top: 5px; }

#timeline .timeline-item.block:nth-child(even):after {
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: 8px;
  border: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff; }

#timeline .timeline-item.block:nth-child(even):before {
  left: 50%;
  right: auto;
  top: 7px;
  border: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ccc;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline .timeline-item.block:nth-child(even) > .timeline-badge {
  top: -28px;
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline .timeline-item.block:nth-child(odd) {
  width: 100% !important;
  margin-top: 5px; }

#timeline .timeline-item.block:nth-child(odd):after {
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: -10px;
  border: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff; }

#timeline .timeline-item.block:nth-child(odd):before {
  left: 50%;
  right: auto;
  top: -11px;
  border: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ccc;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

#timeline .timeline-item.block:nth-child(odd) > .timeline-badge {
  top: -28px;
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.timeline-actions {
  display: none; }

.timeline-content {
  padding: 15px; }

.timeline-footer {
  padding: 15px;
  border-top: 1px solid #eee;
  background-color: #fbfcfc;
  border-radius: 0 0 5px 5px; }

.timeline-heading {
  padding: 15px;
  font-size: 20px; }

.timeline-liveliness {
  padding: 15px;
  border-top: 1px solid #eee;
  background-color: #fbfcfc; }

.timeline-heading + .timeline-content {
  padding-top: 0; }

.timeline-date {
  font-size: 14px;
  color: #aaa; }

.timeline-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }

.timeline-embed .embed-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.timeline-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.timeline-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.timeline-embed object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.timeline-img {
  display: block;
  padding: 5px 0; }

.timeline-img.first {
  margin-right: -10px; }

.timeline-img.middle {
  margin-right: -10px;
  margin-left: -10px; }

.timeline-img.last {
  margin-left: -10px; }

.timeline-resume {
  border-top: 1px solid #eee;
  background-color: #fbfcfc; }

.timeline-avatar {
  margin-top: -2px;
  margin-right: 10px; }

.timeline-title {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
  color: #3e5771;
  text-decoration: none; }

.timeline-title > small {
  display: block;
  font-size: 12px;
  line-height: 1.5;
  color: #a1aab0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.wrapkit-content-rtl .timeline-avatar {
  margin-right: 0;
  margin-left: 10px; }

@media (min-width: 992px) {
  .timeline-item:nth-child(even) {
    padding: 0px 35px 50px 0px; }
  .timeline-item:nth-child(odd) {
    padding: 0 0px 50px 35px; }
  .timeline-item.block {
    padding-right: 0 !important; } }

@media (max-width: 991px) {
  .timeline {
    padding-left: 15px; }
  .timeline-line {
    left: 15px; }
  .timeline-group {
    display: inline-block;
    margin-left: -22px; }
  .timeline-item {
    width: 100% !important;
    margin-top: 0 !important;
    padding-right: 10px; }
  .timeline-item:nth-child(even):after {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    left: 40px;
    top: 21px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff; }
  .timeline-item:nth-child(even):before {
    left: 39px;
    top: 20px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item:nth-child(even) > .timeline-badge {
    top: 12px;
    left: -20px;
    right: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item:nth-child(odd):after {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    left: 40px;
    top: 21px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff; }
  .timeline-item:nth-child(odd):before {
    left: 39px;
    top: 20px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item:nth-child(odd) > .timeline-badge {
    top: 12px;
    left: -20px;
    right: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item.block:nth-child(even) {
    padding-bottom: 0; }
  .timeline-item.block:nth-child(even):after {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    left: 20px;
    top: 11px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff; }
  .timeline-item.block:nth-child(even):before {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item.block:nth-child(even) > .timeline-badge {
    top: 12px;
    left: -6px;
    right: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item.block:nth-child(odd) {
    padding-bottom: 0; }
  .timeline-item.block:nth-child(odd):after {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    left: 20px;
    top: 11px;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
    border-right: 11px solid #fff; }
  .timeline-item.block:nth-child(odd):before {
    left: 19px;
    top: 10px;
    border: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #ccc;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .timeline-item.block:nth-child(odd) > .timeline-badge {
    top: 12px;
    left: -6px;
    right: auto;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@media (max-width: 991px) {
  #timeline.timeline-center .timeline {
    padding: 0; }
  #timeline.timeline-center .timeline .card-block {
    text-align: left; }
  #timeline.timeline-center .timeline-line {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 4px;
    background-color: #ddd;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  #timeline.timeline-center .timeline-group {
    display: block;
    margin-left: -6px;
    text-align: center; }
  #timeline.timeline-center .timeline-item {
    display: block;
    padding: 20px 0 20px !important; }
  #timeline.timeline-center .timeline-item:nth-child(even) {
    float: none;
    clear: both; }
  #timeline.timeline-center .timeline-item:nth-child(even):after {
    display: none; }
  #timeline.timeline-center .timeline-item:nth-child(even):before {
    display: none; }
  #timeline.timeline-center .timeline-item:nth-child(odd) {
    float: none;
    clear: both; }
  #timeline.timeline-center .timeline-item:nth-child(odd):after {
    display: none; }
  #timeline.timeline-center .timeline-item:nth-child(odd):before {
    display: none; }
  #timeline.timeline-center .timeline-item > .timeline-badge {
    top: 0px !important;
    left: 47% !important; }
  #timeline.timeline-center .timeline-item.block:nth-child(even) {
    float: none;
    clear: both; }
  #timeline.timeline-center .timeline-item.block:nth-child(even):after {
    display: none; }
  #timeline.timeline-center .timeline-item.block:nth-child(even):before {
    display: none; }
  #timeline.timeline-center .timeline-item.block:nth-child(odd) {
    float: none;
    clear: both; }
  #timeline.timeline-center .timeline-item.block:nth-child(odd):after {
    display: none; }
  #timeline.timeline-center .timeline-item.block:nth-child(odd):before {
    display: none; }
  #timeline.timeline-center .timeline-item.timeline-poster > .timeline-badge {
    display: none; } }

@media (max-width: 543px) {
  #timeline.timeline-center .timeline-item > .timeline-badge {
    top: 0px !important;
    left: 44% !important; } }

.avatar img {
  border: 0;
  border-radius: 1000px; }

[dir="rtl"] {
  /* Timeline */ }
  [dir="rtl"] #cd-timeline::before {
    right: 18px;
    left: auto; }
  @media only screen and (min-width: 1100px) {
    [dir="rtl"] #cd-timeline::before {
      right: 50%;
      margin-right: -2px;
      left: auto;
      margin-left: auto; } }
  [dir="rtl"] .cd-timeline-img {
    right: 0;
    left: auto; }
    [dir="rtl"] .cd-timeline-img img {
      right: 50%;
      margin-right: -12px;
      left: auto;
      margin-left: auto; }
    @media only screen and (min-width: 1100px) {
      [dir="rtl"] .cd-timeline-img {
        right: 50%;
        margin-right: -30px;
        left: auto;
        margin-left: auto; } }
  [dir="rtl"] .cd-timeline-content {
    margin-right: 60px;
    margin-left: auto; }
    [dir="rtl"] .cd-timeline-content .cd-read-more {
      float: left; }
    [dir="rtl"] .cd-timeline-content .cd-date {
      float: right; }
    [dir="rtl"] .cd-timeline-content::before {
      left: 100%;
      right: auto; }
    @media only screen and (min-width: 1100px) {
      [dir="rtl"] .cd-timeline-content {
        margin-right: 0;
        margin-left: auto; }
        [dir="rtl"] .cd-timeline-content::before {
          right: 100%;
          left: auto; }
        [dir="rtl"] .cd-timeline-content .cd-read-more {
          float: right; }
        [dir="rtl"] .cd-timeline-content .cd-date {
          right: 122%;
          left: auto; }
        .cd-timeline-block:nth-child(even) [dir="rtl"] .cd-timeline-content {
          float: left; }
          .cd-timeline-block:nth-child(even) [dir="rtl"] .cd-timeline-content::before {
            right: auto;
            left: 100%; }
          .cd-timeline-block:nth-child(even) [dir="rtl"] .cd-timeline-content .cd-read-more {
            float: left; }
          .cd-timeline-block:nth-child(even) [dir="rtl"] .cd-timeline-content .cd-date {
            right: auto;
            left: 122%;
            text-align: left; } }
  [dir="rtl"] .timeline > li:nth-child(even) {
    float: right;
    clear: right; }
  [dir="rtl"] .timeline > li:nth-child(odd) {
    float: left;
    clear: left; }
  [dir="rtl"] #timeline.timeline-center .timeline-line {
    left: auto;
    right: 50%; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(even) {
    padding: 0px 0px 50px 35px; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(even):after {
    left: 25px;
    right: auto;
    border-left: 0px;
    border-right: 11px solid #fff; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(even):before {
    left: 24px;
    right: auto;
    border-left: 0px;
    border-right: 12px solid #ccc; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(even) > .timeline-badge {
    left: -20px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(odd) {
    padding: 0px 35px 50px 0px; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(odd):after {
    right: 19px;
    left: auto;
    border-left: 12px solid #ccc;
    border-right: 0;
    right: 25px;
    left: auto;
    border-left: 11px solid #fff;
    border-right: 0; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(odd):before {
    right: 24px;
    border-left: 12px solid #ccc;
    left: auto;
    border-right: 0; }
  [dir="rtl"] #timeline.timeline-center .timeline-item:nth-child(odd) > .timeline-badge {
    right: -20px;
    left: auto; }
  [dir="rtl"] #timeline.timeline-center .timeline-item > .timeline-badge {
    right: 12px;
    left: auto; }
  [dir="rtl"] #timeline.timeline-right .timeline-line {
    left: 12px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-right .timeline-group {
    text-align: left; }
  [dir="rtl"] #timeline.timeline-right .timeline-item {
    padding: 0px 0px 35px 43px; }
  [dir="rtl"] #timeline.timeline-right .timeline-item:after {
    left: 33px;
    border-right: 11px solid #fff;
    right: auto;
    border-left: 0px; }
  [dir="rtl"] #timeline.timeline-right .timeline-item:before {
    left: 32px;
    border-right: 12px solid #ccc;
    right: auto;
    border-left: 0px; }
  [dir="rtl"] #timeline.timeline-right .timeline-item > .timeline-badge {
    left: -8px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-right .timeline-item.block:nth-child(even):before {
    left: 8px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-right .timeline-item.block:nth-child(even):after {
    left: 11px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-right .timeline-item.block:nth-child(odd):after {
    left: 8px;
    right: auto; }
  [dir="rtl"] #timeline.timeline-left .timeline-line {
    right: 12px;
    left: auto; }
  [dir="rtl"] #timeline.timeline-left .timeline-group {
    text-align: right; }
  [dir="rtl"] #timeline.timeline-left .timeline-item {
    padding: 0 43px 35px 0; }
  [dir="rtl"] #timeline.timeline-left .timeline-item:after {
    right: 33px;
    left: 0px;
    border-right: 11px solid #fff;
    border-left: 0; }
  [dir="rtl"] #timeline.timeline-left .timeline-item:before {
    right: 32px;
    border-left: 12px solid #ccc;
    left: auto;
    border-right: 0; }
  [dir="rtl"] #timeline.timeline-left .timeline-item > .timeline-badge {
    right: -8px;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(even):after {
    right: 50%;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(even):before {
    right: 50%;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(even) > .timeline-badge {
    right: 50%;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(odd):after {
    right: 50%;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(odd):before {
    right: 50%;
    left: auto; }
  [dir="rtl"] #timeline .timeline-item.block:nth-child(odd) > .timeline-badge {
    right: 50%;
    left: auto; }
  [dir="rtl"] .timeline-embed .embed-element {
    right: 0;
    left: auto; }
  [dir="rtl"] .timeline-embed embed {
    left: auto;
    right: 0; }
  [dir="rtl"] .timeline-embed iframe {
    left: auto;
    right: 0; }
  [dir="rtl"] .timeline-embed object {
    left: auto;
    right: 0; }
  [dir="rtl"] .timeline-img.first {
    margin-left: -10px;
    margin-right: 0px; }
  [dir="rtl"] .timeline-img.last {
    margin-right: -10px;
    margin-left: 0; }
  [dir="rtl"] .timeline-avatar {
    margin-left: 10px;
    margin-right: 0px; }
  [dir="rtl"] .wrapkit-content-rtl .timeline-avatar {
    margin-left: 0;
    margin-right: 10px; }
  @media (min-width: 992px) {
    [dir="rtl"] .timeline-item:nth-child(even) {
      padding: 0px 0px 50px 35px; }
    [dir="rtl"] .timeline-item:nth-child(odd) {
      padding: 0 35px 50px 0px; }
    [dir="rtl"] .timeline-item.block {
      padding-left: 0 !important;
      padding-right: auto !important; } }
  @media (max-width: 991px) {
    [dir="rtl"] .timeline {
      padding-right: 15px;
      padding-left: 0px; }
    [dir="rtl"] .timeline-line {
      right: 15px;
      left: 0px; }
    [dir="rtl"] .timeline-group {
      margin-right: -22px;
      margin-left: 0px; }
    [dir="rtl"] .timeline-item {
      padding-left: 10px;
      padding-right: 0px; }
    [dir="rtl"] .timeline-item:nth-child(even):after {
      right: 19px;
      left: 0px;
      border-left: 12px solid #ccc;
      border-right: 0px;
      right: 40px;
      left: 0px;
      border-left: 11px solid #fff;
      border-right: 0px; }
    [dir="rtl"] .timeline-item:nth-child(even):before {
      right: 39px;
      border-left: 12px solid #ccc;
      left: 0px;
      border-right: 0px; }
    [dir="rtl"] .timeline-item:nth-child(even) > .timeline-badge {
      right: -20px;
      left: auto; }
    [dir="rtl"] .timeline-item:nth-child(odd):after {
      right: 19px;
      border-left: 12px solid #ccc;
      right: 40px;
      border-left: 11px solid #fff;
      left: 0px;
      border-right: 0px solid #ccc;
      left: 0px;
      border-right: 0px solid #fff; }
    [dir="rtl"] .timeline-item:nth-child(odd):before {
      right: 39px;
      border-left: 12px solid #ccc;
      left: 0px;
      border-right: 0px solid #ccc; }
    [dir="rtl"] .timeline-item:nth-child(odd) > .timeline-badge {
      right: -20px;
      left: auto; }
    [dir="rtl"] .timeline-item.block:nth-child(even) {
      padding-bottom: 0; }
    [dir="rtl"] .timeline-item.block:nth-child(even):after {
      right: 19px;
      border-left: 12px solid #ccc;
      right: 20px;
      border-left: 11px solid #fff;
      left: 0px;
      border-right: 0px solid #ccc;
      left: 0px;
      border-right: 0px solid #fff; }
    [dir="rtl"] .timeline-item.block:nth-child(even):before {
      right: 19px;
      border-left: 12px solid #ccc;
      left: 0px;
      border-right: 0px solid #ccc; }
    [dir="rtl"] .timeline-item.block:nth-child(even) > .timeline-badge {
      right: -6px;
      left: auto; }
    [dir="rtl"] .timeline-item.block:nth-child(odd) {
      padding-bottom: 0; }
    [dir="rtl"] .timeline-item.block:nth-child(odd):after {
      right: 19px;
      border-left: 12px solid #ccc;
      right: 20px;
      border-left: 11px solid #fff;
      left: 0px;
      border-right: 0px solid #ccc;
      left: 0px;
      border-right: 0px solid #fff; }
    [dir="rtl"] .timeline-item.block:nth-child(odd):before {
      right: 19px;
      border-left: 12px solid #ccc;
      left: 0px;
      border-right: 0px solid #ccc; }
    [dir="rtl"] .timeline-item.block:nth-child(odd) > .timeline-badge {
      right: -6px;
      left: auto; } }
  @media (max-width: 991px) {
    [dir="rtl"] #timeline.timeline-center .timeline .card-block {
      text-align: right; }
    [dir="rtl"] #timeline.timeline-center .timeline-line {
      right: 50%;
      left: auto; }
    [dir="rtl"] #timeline.timeline-center .timeline-group {
      margin-right: -6px;
      margin-left: auto; }
    [dir="rtl"] #timeline.timeline-center .timeline-item > .timeline-badge {
      right: 47% !important;
      left: auto !important; } }
  @media (max-width: 543px) {
    [dir="rtl"] .timeline-item > .timeline-badge {
      right: 44% !important;
      left: auto !important; } }

.btn-social, .btn-social-icon {
  position: relative;
  padding-left: 3.025rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff; }
  .btn-social > :first-child, .btn-social-icon > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2.275rem;
    line-height: 2.475rem;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2); }
  .btn-social.btn-lg, .btn-group-lg > .btn-social.btn, .btn-group-lg > .btn.btn-social-icon, .btn-lg.btn-social-icon {
    padding-left: 3.2rem; }
    .btn-social.btn-lg > :first-child, .btn-group-lg > .btn-social.btn > :first-child, .btn-group-lg > .btn.btn-social-icon > :first-child, .btn-lg.btn-social-icon > :first-child {
      line-height: 2.2rem;
      width: 2.2rem;
      font-size: 1.8em; }
  .btn-social.btn-sm, .btn-group-sm > .btn-social.btn, .btn-group-sm > .btn.btn-social-icon, .btn-sm.btn-social-icon {
    padding-left: 2.55rem; }
    .btn-social.btn-sm > :first-child, .btn-group-sm > .btn-social.btn > :first-child, .btn-group-sm > .btn.btn-social-icon > :first-child, .btn-sm.btn-social-icon > :first-child {
      line-height: 1.8rem;
      width: 1.8rem;
      font-size: 1.4em; }
  .btn-social.btn-xs, .btn-xs.btn-social-icon {
    padding-left: 2.15rem; }
    .btn-social.btn-xs > :first-child, .btn-xs.btn-social-icon > :first-child {
      line-height: 1.4rem;
      width: 1.4rem;
      font-size: 1.2em; }

.btn-social-icon {
  height: 2.525rem;
  width: 2.525rem;
  padding: 0; }
  .btn-social-icon > :first-child {
    border: none;
    text-align: center;
    width: 100% !important; }
  .btn-social-icon.btn-lg, .btn-group-lg > .btn-social-icon.btn {
    height: 2.2rem;
    width: 2.2rem;
    padding-left: 0;
    padding-right: 0; }
  .btn-social-icon.btn-sm, .btn-group-sm > .btn-social-icon.btn {
    height: 3.8rem;
    width: 3.8rem;
    padding-left: 0;
    padding-right: 0; }
  .btn-social-icon.btn-xs {
    height: 3.4rem;
    width: 3.4rem;
    padding-left: 0;
    padding-right: 0; }

.btn-adn {
  background-color: #d87a68;
  color: #fff !important;
  background-color: #d87a68;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-adn:focus, .btn-adn.focus {
    color: #fff;
    background-color: #ce563f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-adn:hover {
    color: #fff;
    background-color: #ce563f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-adn:active, .btn-adn.active,
  .open > .btn-adn.dropdown-toggle {
    color: #fff;
    background-color: #ce563f !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-adn:active:hover, .btn-adn:active:focus, .btn-adn:active.focus, .btn-adn.active:hover, .btn-adn.active:focus, .btn-adn.active.focus,
    .open > .btn-adn.dropdown-toggle:hover,
    .open > .btn-adn.dropdown-toggle:focus,
    .open > .btn-adn.dropdown-toggle.focus {
      color: #fff;
      background-color: #b94630 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-adn:active, .btn-adn.active,
  .open > .btn-adn.dropdown-toggle {
    background-image: none; }
  .btn-adn.disabled:hover, .btn-adn.disabled:focus, .btn-adn.disabled.focus, .btn-adn[disabled]:hover, .btn-adn[disabled]:focus, .btn-adn[disabled].focus,
  fieldset[disabled] .btn-adn:hover,
  fieldset[disabled] .btn-adn:focus,
  fieldset[disabled] .btn-adn.focus {
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-adn .badge {
    color: #d87a68;
    background-color: #fff; }

.btn-bitbucket {
  background-color: #205081;
  color: #fff !important;
  background-color: #205081;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-bitbucket:focus, .btn-bitbucket.focus {
    color: #fff;
    background-color: #163758 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-bitbucket:hover {
    color: #fff;
    background-color: #163758 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-bitbucket:active, .btn-bitbucket.active,
  .open > .btn-bitbucket.dropdown-toggle {
    color: #fff;
    background-color: #163758 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-bitbucket:active:hover, .btn-bitbucket:active:focus, .btn-bitbucket:active.focus, .btn-bitbucket.active:hover, .btn-bitbucket.active:focus, .btn-bitbucket.active.focus,
    .open > .btn-bitbucket.dropdown-toggle:hover,
    .open > .btn-bitbucket.dropdown-toggle:focus,
    .open > .btn-bitbucket.dropdown-toggle.focus {
      color: #fff;
      background-color: #0f253c !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-bitbucket:active, .btn-bitbucket.active,
  .open > .btn-bitbucket.dropdown-toggle {
    background-image: none; }
  .btn-bitbucket.disabled:hover, .btn-bitbucket.disabled:focus, .btn-bitbucket.disabled.focus, .btn-bitbucket[disabled]:hover, .btn-bitbucket[disabled]:focus, .btn-bitbucket[disabled].focus,
  fieldset[disabled] .btn-bitbucket:hover,
  fieldset[disabled] .btn-bitbucket:focus,
  fieldset[disabled] .btn-bitbucket.focus {
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-bitbucket .badge {
    color: #205081;
    background-color: #fff; }

.btn-dropbox {
  background-color: #1087dd;
  color: #fff !important;
  background-color: #1087dd;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-dropbox:focus, .btn-dropbox.focus {
    color: #fff;
    background-color: #0d6aad !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-dropbox:hover {
    color: #fff;
    background-color: #0d6aad !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-dropbox:active, .btn-dropbox.active,
  .open > .btn-dropbox.dropdown-toggle {
    color: #fff;
    background-color: #0d6aad !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-dropbox:active:hover, .btn-dropbox:active:focus, .btn-dropbox:active.focus, .btn-dropbox.active:hover, .btn-dropbox.active:focus, .btn-dropbox.active.focus,
    .open > .btn-dropbox.dropdown-toggle:hover,
    .open > .btn-dropbox.dropdown-toggle:focus,
    .open > .btn-dropbox.dropdown-toggle.focus {
      color: #fff;
      background-color: #0a568c !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-dropbox:active, .btn-dropbox.active,
  .open > .btn-dropbox.dropdown-toggle {
    background-image: none; }
  .btn-dropbox.disabled:hover, .btn-dropbox.disabled:focus, .btn-dropbox.disabled.focus, .btn-dropbox[disabled]:hover, .btn-dropbox[disabled]:focus, .btn-dropbox[disabled].focus,
  fieldset[disabled] .btn-dropbox:hover,
  fieldset[disabled] .btn-dropbox:focus,
  fieldset[disabled] .btn-dropbox.focus {
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-dropbox .badge {
    color: #1087dd;
    background-color: #fff; }

.btn-facebook {
  background-color: #3b5998;
  color: #fff !important;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-facebook:focus, .btn-facebook.focus {
    color: #fff;
    background-color: #2d4373 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-facebook:hover {
    color: #fff;
    background-color: #2d4373 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-facebook:active, .btn-facebook.active,
  .open > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-facebook:active:hover, .btn-facebook:active:focus, .btn-facebook:active.focus, .btn-facebook.active:hover, .btn-facebook.active:focus, .btn-facebook.active.focus,
    .open > .btn-facebook.dropdown-toggle:hover,
    .open > .btn-facebook.dropdown-toggle:focus,
    .open > .btn-facebook.dropdown-toggle.focus {
      color: #fff;
      background-color: #23345a !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-facebook:active, .btn-facebook.active,
  .open > .btn-facebook.dropdown-toggle {
    background-image: none; }
  .btn-facebook.disabled:hover, .btn-facebook.disabled:focus, .btn-facebook.disabled.focus, .btn-facebook[disabled]:hover, .btn-facebook[disabled]:focus, .btn-facebook[disabled].focus,
  fieldset[disabled] .btn-facebook:hover,
  fieldset[disabled] .btn-facebook:focus,
  fieldset[disabled] .btn-facebook.focus {
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-facebook .badge {
    color: #3b5998;
    background-color: #fff; }

.btn-flickr {
  background-color: #ff0084;
  color: #fff !important;
  background-color: #ff0084;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-flickr:focus, .btn-flickr.focus {
    color: #fff;
    background-color: #cc006a !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-flickr:hover {
    color: #fff;
    background-color: #cc006a !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-flickr:active, .btn-flickr.active,
  .open > .btn-flickr.dropdown-toggle {
    color: #fff;
    background-color: #cc006a !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-flickr:active:hover, .btn-flickr:active:focus, .btn-flickr:active.focus, .btn-flickr.active:hover, .btn-flickr.active:focus, .btn-flickr.active.focus,
    .open > .btn-flickr.dropdown-toggle:hover,
    .open > .btn-flickr.dropdown-toggle:focus,
    .open > .btn-flickr.dropdown-toggle.focus {
      color: #fff;
      background-color: #a80057 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-flickr:active, .btn-flickr.active,
  .open > .btn-flickr.dropdown-toggle {
    background-image: none; }
  .btn-flickr.disabled:hover, .btn-flickr.disabled:focus, .btn-flickr.disabled.focus, .btn-flickr[disabled]:hover, .btn-flickr[disabled]:focus, .btn-flickr[disabled].focus,
  fieldset[disabled] .btn-flickr:hover,
  fieldset[disabled] .btn-flickr:focus,
  fieldset[disabled] .btn-flickr.focus {
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-flickr .badge {
    color: #ff0084;
    background-color: #fff; }

.btn-foursquare {
  background-color: #f94877;
  color: #fff !important;
  background-color: #f94877;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-foursquare:focus, .btn-foursquare.focus {
    color: #fff;
    background-color: #f71752 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-foursquare:hover {
    color: #fff;
    background-color: #f71752 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-foursquare:active, .btn-foursquare.active,
  .open > .btn-foursquare.dropdown-toggle {
    color: #fff;
    background-color: #f71752 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-foursquare:active:hover, .btn-foursquare:active:focus, .btn-foursquare:active.focus, .btn-foursquare.active:hover, .btn-foursquare.active:focus, .btn-foursquare.active.focus,
    .open > .btn-foursquare.dropdown-toggle:hover,
    .open > .btn-foursquare.dropdown-toggle:focus,
    .open > .btn-foursquare.dropdown-toggle.focus {
      color: #fff;
      background-color: #e30742 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-foursquare:active, .btn-foursquare.active,
  .open > .btn-foursquare.dropdown-toggle {
    background-image: none; }
  .btn-foursquare.disabled:hover, .btn-foursquare.disabled:focus, .btn-foursquare.disabled.focus, .btn-foursquare[disabled]:hover, .btn-foursquare[disabled]:focus, .btn-foursquare[disabled].focus,
  fieldset[disabled] .btn-foursquare:hover,
  fieldset[disabled] .btn-foursquare:focus,
  fieldset[disabled] .btn-foursquare.focus {
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-foursquare .badge {
    color: #f94877;
    background-color: #fff; }

.btn-github {
  background-color: #444444;
  color: #fff !important;
  background-color: #444444;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-github:focus, .btn-github.focus {
    color: #fff;
    background-color: #2b2b2b !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-github:hover {
    color: #fff;
    background-color: #2b2b2b !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-github:active, .btn-github.active,
  .open > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #2b2b2b !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-github:active:hover, .btn-github:active:focus, .btn-github:active.focus, .btn-github.active:hover, .btn-github.active:focus, .btn-github.active.focus,
    .open > .btn-github.dropdown-toggle:hover,
    .open > .btn-github.dropdown-toggle:focus,
    .open > .btn-github.dropdown-toggle.focus {
      color: #fff;
      background-color: #191919 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-github:active, .btn-github.active,
  .open > .btn-github.dropdown-toggle {
    background-image: none; }
  .btn-github.disabled:hover, .btn-github.disabled:focus, .btn-github.disabled.focus, .btn-github[disabled]:hover, .btn-github[disabled]:focus, .btn-github[disabled].focus,
  fieldset[disabled] .btn-github:hover,
  fieldset[disabled] .btn-github:focus,
  fieldset[disabled] .btn-github.focus {
    background-color: #444444;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-github .badge {
    color: #444444;
    background-color: #fff; }

.btn-google {
  background-color: #dd4b39;
  color: #fff !important;
  background-color: #dd4b39;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-google:focus, .btn-google.focus {
    color: #fff;
    background-color: #c23321 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-google:hover {
    color: #fff;
    background-color: #c23321 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-google:active, .btn-google.active,
  .open > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #c23321 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-google:active:hover, .btn-google:active:focus, .btn-google:active.focus, .btn-google.active:hover, .btn-google.active:focus, .btn-google.active.focus,
    .open > .btn-google.dropdown-toggle:hover,
    .open > .btn-google.dropdown-toggle:focus,
    .open > .btn-google.dropdown-toggle.focus {
      color: #fff;
      background-color: #a32b1c !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-google:active, .btn-google.active,
  .open > .btn-google.dropdown-toggle {
    background-image: none; }
  .btn-google.disabled:hover, .btn-google.disabled:focus, .btn-google.disabled.focus, .btn-google[disabled]:hover, .btn-google[disabled]:focus, .btn-google[disabled].focus,
  fieldset[disabled] .btn-google:hover,
  fieldset[disabled] .btn-google:focus,
  fieldset[disabled] .btn-google.focus {
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-google .badge {
    color: #dd4b39;
    background-color: #fff; }

.btn-instagram {
  background-color: #3f729b;
  color: #fff !important;
  background-color: #3f729b;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-instagram:focus, .btn-instagram.focus {
    color: #fff;
    background-color: #305777 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-instagram:hover {
    color: #fff;
    background-color: #305777 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-instagram:active, .btn-instagram.active,
  .open > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #305777 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-instagram:active:hover, .btn-instagram:active:focus, .btn-instagram:active.focus, .btn-instagram.active:hover, .btn-instagram.active:focus, .btn-instagram.active.focus,
    .open > .btn-instagram.dropdown-toggle:hover,
    .open > .btn-instagram.dropdown-toggle:focus,
    .open > .btn-instagram.dropdown-toggle.focus {
      color: #fff;
      background-color: #26455d !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-instagram:active, .btn-instagram.active,
  .open > .btn-instagram.dropdown-toggle {
    background-image: none; }
  .btn-instagram.disabled:hover, .btn-instagram.disabled:focus, .btn-instagram.disabled.focus, .btn-instagram[disabled]:hover, .btn-instagram[disabled]:focus, .btn-instagram[disabled].focus,
  fieldset[disabled] .btn-instagram:hover,
  fieldset[disabled] .btn-instagram:focus,
  fieldset[disabled] .btn-instagram.focus {
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-instagram .badge {
    color: #3f729b;
    background-color: #fff; }

.btn-linkedin {
  background-color: #007bb6;
  color: #fff !important;
  background-color: #007bb6;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-linkedin:focus, .btn-linkedin.focus {
    color: #fff;
    background-color: #005983 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-linkedin:hover {
    color: #fff;
    background-color: #005983 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-linkedin:active, .btn-linkedin.active,
  .open > .btn-linkedin.dropdown-toggle {
    color: #fff;
    background-color: #005983 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-linkedin:active:hover, .btn-linkedin:active:focus, .btn-linkedin:active.focus, .btn-linkedin.active:hover, .btn-linkedin.active:focus, .btn-linkedin.active.focus,
    .open > .btn-linkedin.dropdown-toggle:hover,
    .open > .btn-linkedin.dropdown-toggle:focus,
    .open > .btn-linkedin.dropdown-toggle.focus {
      color: #fff;
      background-color: #00405f !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-linkedin:active, .btn-linkedin.active,
  .open > .btn-linkedin.dropdown-toggle {
    background-image: none; }
  .btn-linkedin.disabled:hover, .btn-linkedin.disabled:focus, .btn-linkedin.disabled.focus, .btn-linkedin[disabled]:hover, .btn-linkedin[disabled]:focus, .btn-linkedin[disabled].focus,
  fieldset[disabled] .btn-linkedin:hover,
  fieldset[disabled] .btn-linkedin:focus,
  fieldset[disabled] .btn-linkedin.focus {
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-linkedin .badge {
    color: #007bb6;
    background-color: #fff; }

.btn-microsoft {
  background-color: #2672ec;
  color: #fff !important;
  background-color: #2672ec;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-microsoft:focus, .btn-microsoft.focus {
    color: #fff;
    background-color: #125acd !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-microsoft:hover {
    color: #fff;
    background-color: #125acd !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-microsoft:active, .btn-microsoft.active,
  .open > .btn-microsoft.dropdown-toggle {
    color: #fff;
    background-color: #125acd !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-microsoft:active:hover, .btn-microsoft:active:focus, .btn-microsoft:active.focus, .btn-microsoft.active:hover, .btn-microsoft.active:focus, .btn-microsoft.active.focus,
    .open > .btn-microsoft.dropdown-toggle:hover,
    .open > .btn-microsoft.dropdown-toggle:focus,
    .open > .btn-microsoft.dropdown-toggle.focus {
      color: #fff;
      background-color: #0f4bac !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-microsoft:active, .btn-microsoft.active,
  .open > .btn-microsoft.dropdown-toggle {
    background-image: none; }
  .btn-microsoft.disabled:hover, .btn-microsoft.disabled:focus, .btn-microsoft.disabled.focus, .btn-microsoft[disabled]:hover, .btn-microsoft[disabled]:focus, .btn-microsoft[disabled].focus,
  fieldset[disabled] .btn-microsoft:hover,
  fieldset[disabled] .btn-microsoft:focus,
  fieldset[disabled] .btn-microsoft.focus {
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-microsoft .badge {
    color: #2672ec;
    background-color: #fff; }

.btn-odnoklassniki {
  background-color: #f4731c;
  color: #fff !important;
  background-color: #f4731c;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
    color: #fff;
    background-color: #d35b0a !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-odnoklassniki:hover {
    color: #fff;
    background-color: #d35b0a !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-odnoklassniki:active, .btn-odnoklassniki.active,
  .open > .btn-odnoklassniki.dropdown-toggle {
    color: #fff;
    background-color: #d35b0a !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-odnoklassniki:active:hover, .btn-odnoklassniki:active:focus, .btn-odnoklassniki:active.focus, .btn-odnoklassniki.active:hover, .btn-odnoklassniki.active:focus, .btn-odnoklassniki.active.focus,
    .open > .btn-odnoklassniki.dropdown-toggle:hover,
    .open > .btn-odnoklassniki.dropdown-toggle:focus,
    .open > .btn-odnoklassniki.dropdown-toggle.focus {
      color: #fff;
      background-color: #b14c09 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-odnoklassniki:active, .btn-odnoklassniki.active,
  .open > .btn-odnoklassniki.dropdown-toggle {
    background-image: none; }
  .btn-odnoklassniki.disabled:hover, .btn-odnoklassniki.disabled:focus, .btn-odnoklassniki.disabled.focus, .btn-odnoklassniki[disabled]:hover, .btn-odnoklassniki[disabled]:focus, .btn-odnoklassniki[disabled].focus,
  fieldset[disabled] .btn-odnoklassniki:hover,
  fieldset[disabled] .btn-odnoklassniki:focus,
  fieldset[disabled] .btn-odnoklassniki.focus {
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-odnoklassniki .badge {
    color: #f4731c;
    background-color: #fff; }

.btn-openid {
  background-color: #f7931e;
  color: #fff !important;
  background-color: #f7931e;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-openid:focus, .btn-openid.focus {
    color: #fff;
    background-color: #da7908 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-openid:hover {
    color: #fff;
    background-color: #da7908 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-openid:active, .btn-openid.active,
  .open > .btn-openid.dropdown-toggle {
    color: #fff;
    background-color: #da7908 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-openid:active:hover, .btn-openid:active:focus, .btn-openid:active.focus, .btn-openid.active:hover, .btn-openid.active:focus, .btn-openid.active.focus,
    .open > .btn-openid.dropdown-toggle:hover,
    .open > .btn-openid.dropdown-toggle:focus,
    .open > .btn-openid.dropdown-toggle.focus {
      color: #fff;
      background-color: #b86607 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-openid:active, .btn-openid.active,
  .open > .btn-openid.dropdown-toggle {
    background-image: none; }
  .btn-openid.disabled:hover, .btn-openid.disabled:focus, .btn-openid.disabled.focus, .btn-openid[disabled]:hover, .btn-openid[disabled]:focus, .btn-openid[disabled].focus,
  fieldset[disabled] .btn-openid:hover,
  fieldset[disabled] .btn-openid:focus,
  fieldset[disabled] .btn-openid.focus {
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-openid .badge {
    color: #f7931e;
    background-color: #fff; }

.btn-pinterest {
  background-color: #cb2027;
  color: #fff !important;
  background-color: #cb2027;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-pinterest:focus, .btn-pinterest.focus {
    color: #fff;
    background-color: #9f191f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-pinterest:hover {
    color: #fff;
    background-color: #9f191f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-pinterest:active, .btn-pinterest.active,
  .open > .btn-pinterest.dropdown-toggle {
    color: #fff;
    background-color: #9f191f !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-pinterest:active:hover, .btn-pinterest:active:focus, .btn-pinterest:active.focus, .btn-pinterest.active:hover, .btn-pinterest.active:focus, .btn-pinterest.active.focus,
    .open > .btn-pinterest.dropdown-toggle:hover,
    .open > .btn-pinterest.dropdown-toggle:focus,
    .open > .btn-pinterest.dropdown-toggle.focus {
      color: #fff;
      background-color: #801419 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-pinterest:active, .btn-pinterest.active,
  .open > .btn-pinterest.dropdown-toggle {
    background-image: none; }
  .btn-pinterest.disabled:hover, .btn-pinterest.disabled:focus, .btn-pinterest.disabled.focus, .btn-pinterest[disabled]:hover, .btn-pinterest[disabled]:focus, .btn-pinterest[disabled].focus,
  fieldset[disabled] .btn-pinterest:hover,
  fieldset[disabled] .btn-pinterest:focus,
  fieldset[disabled] .btn-pinterest.focus {
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-pinterest .badge {
    color: #cb2027;
    background-color: #fff; }

.btn-reddit {
  background-color: #eff7ff;
  color: #000 !important;
  background-color: #eff7ff;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-reddit:focus, .btn-reddit.focus {
    color: #000;
    background-color: #bcdeff !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-reddit:hover {
    color: #000;
    background-color: #bcdeff !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-reddit:active, .btn-reddit.active,
  .open > .btn-reddit.dropdown-toggle {
    color: #000;
    background-color: #bcdeff !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-reddit:active:hover, .btn-reddit:active:focus, .btn-reddit:active.focus, .btn-reddit.active:hover, .btn-reddit.active:focus, .btn-reddit.active.focus,
    .open > .btn-reddit.dropdown-toggle:hover,
    .open > .btn-reddit.dropdown-toggle:focus,
    .open > .btn-reddit.dropdown-toggle.focus {
      color: #000;
      background-color: #98ccff !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-reddit:active, .btn-reddit.active,
  .open > .btn-reddit.dropdown-toggle {
    background-image: none; }
  .btn-reddit.disabled:hover, .btn-reddit.disabled:focus, .btn-reddit.disabled.focus, .btn-reddit[disabled]:hover, .btn-reddit[disabled]:focus, .btn-reddit[disabled].focus,
  fieldset[disabled] .btn-reddit:hover,
  fieldset[disabled] .btn-reddit:focus,
  fieldset[disabled] .btn-reddit.focus {
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-reddit .badge {
    color: #eff7ff;
    background-color: #000; }

.btn-soundcloud {
  background-color: #ff5500;
  color: #fff !important;
  background-color: #ff5500;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-soundcloud:focus, .btn-soundcloud.focus {
    color: #fff;
    background-color: #cc4400 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-soundcloud:hover {
    color: #fff;
    background-color: #cc4400 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-soundcloud:active, .btn-soundcloud.active,
  .open > .btn-soundcloud.dropdown-toggle {
    color: #fff;
    background-color: #cc4400 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-soundcloud:active:hover, .btn-soundcloud:active:focus, .btn-soundcloud:active.focus, .btn-soundcloud.active:hover, .btn-soundcloud.active:focus, .btn-soundcloud.active.focus,
    .open > .btn-soundcloud.dropdown-toggle:hover,
    .open > .btn-soundcloud.dropdown-toggle:focus,
    .open > .btn-soundcloud.dropdown-toggle.focus {
      color: #fff;
      background-color: #a83800 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-soundcloud:active, .btn-soundcloud.active,
  .open > .btn-soundcloud.dropdown-toggle {
    background-image: none; }
  .btn-soundcloud.disabled:hover, .btn-soundcloud.disabled:focus, .btn-soundcloud.disabled.focus, .btn-soundcloud[disabled]:hover, .btn-soundcloud[disabled]:focus, .btn-soundcloud[disabled].focus,
  fieldset[disabled] .btn-soundcloud:hover,
  fieldset[disabled] .btn-soundcloud:focus,
  fieldset[disabled] .btn-soundcloud.focus {
    background-color: #ff5500;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-soundcloud .badge {
    color: #ff5500;
    background-color: #fff; }

.btn-tumblr {
  background-color: #2c4762;
  color: #fff !important;
  background-color: #2c4762;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-tumblr:focus, .btn-tumblr.focus {
    color: #fff;
    background-color: #1c2e3f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-tumblr:hover {
    color: #fff;
    background-color: #1c2e3f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-tumblr:active, .btn-tumblr.active,
  .open > .btn-tumblr.dropdown-toggle {
    color: #fff;
    background-color: #1c2e3f !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-tumblr:active:hover, .btn-tumblr:active:focus, .btn-tumblr:active.focus, .btn-tumblr.active:hover, .btn-tumblr.active:focus, .btn-tumblr.active.focus,
    .open > .btn-tumblr.dropdown-toggle:hover,
    .open > .btn-tumblr.dropdown-toggle:focus,
    .open > .btn-tumblr.dropdown-toggle.focus {
      color: #fff;
      background-color: #111c26 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-tumblr:active, .btn-tumblr.active,
  .open > .btn-tumblr.dropdown-toggle {
    background-image: none; }
  .btn-tumblr.disabled:hover, .btn-tumblr.disabled:focus, .btn-tumblr.disabled.focus, .btn-tumblr[disabled]:hover, .btn-tumblr[disabled]:focus, .btn-tumblr[disabled].focus,
  fieldset[disabled] .btn-tumblr:hover,
  fieldset[disabled] .btn-tumblr:focus,
  fieldset[disabled] .btn-tumblr.focus {
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-tumblr .badge {
    color: #2c4762;
    background-color: #fff; }

.btn-twitter {
  background-color: #55acee;
  color: #fff !important;
  background-color: #55acee;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    background-color: #2795e9 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-twitter:hover {
    color: #fff;
    background-color: #2795e9 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-twitter:active, .btn-twitter.active,
  .open > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #2795e9 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-twitter:active:hover, .btn-twitter:active:focus, .btn-twitter:active.focus, .btn-twitter.active:hover, .btn-twitter.active:focus, .btn-twitter.active.focus,
    .open > .btn-twitter.dropdown-toggle:hover,
    .open > .btn-twitter.dropdown-toggle:focus,
    .open > .btn-twitter.dropdown-toggle.focus {
      color: #fff;
      background-color: #1583d7 !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-twitter:active, .btn-twitter.active,
  .open > .btn-twitter.dropdown-toggle {
    background-image: none; }
  .btn-twitter.disabled:hover, .btn-twitter.disabled:focus, .btn-twitter.disabled.focus, .btn-twitter[disabled]:hover, .btn-twitter[disabled]:focus, .btn-twitter[disabled].focus,
  fieldset[disabled] .btn-twitter:hover,
  fieldset[disabled] .btn-twitter:focus,
  fieldset[disabled] .btn-twitter.focus {
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-twitter .badge {
    color: #55acee;
    background-color: #fff; }

.btn-vimeo {
  background-color: #1ab7ea;
  color: #fff !important;
  background-color: #1ab7ea;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-vimeo:focus, .btn-vimeo.focus {
    color: #fff;
    background-color: #1295bf !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vimeo:hover {
    color: #fff;
    background-color: #1295bf !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vimeo:active, .btn-vimeo.active,
  .open > .btn-vimeo.dropdown-toggle {
    color: #fff;
    background-color: #1295bf !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-vimeo:active:hover, .btn-vimeo:active:focus, .btn-vimeo:active.focus, .btn-vimeo.active:hover, .btn-vimeo.active:focus, .btn-vimeo.active.focus,
    .open > .btn-vimeo.dropdown-toggle:hover,
    .open > .btn-vimeo.dropdown-toggle:focus,
    .open > .btn-vimeo.dropdown-toggle.focus {
      color: #fff;
      background-color: #0f7b9f !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-vimeo:active, .btn-vimeo.active,
  .open > .btn-vimeo.dropdown-toggle {
    background-image: none; }
  .btn-vimeo.disabled:hover, .btn-vimeo.disabled:focus, .btn-vimeo.disabled.focus, .btn-vimeo[disabled]:hover, .btn-vimeo[disabled]:focus, .btn-vimeo[disabled].focus,
  fieldset[disabled] .btn-vimeo:hover,
  fieldset[disabled] .btn-vimeo:focus,
  fieldset[disabled] .btn-vimeo.focus {
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vimeo .badge {
    color: #1ab7ea;
    background-color: #fff; }

.btn-vk {
  background-color: #587ea3;
  color: #fff !important;
  background-color: #587ea3;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-vk:focus, .btn-vk.focus {
    color: #fff;
    background-color: #466482 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vk:hover {
    color: #fff;
    background-color: #466482 !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vk:active, .btn-vk.active,
  .open > .btn-vk.dropdown-toggle {
    color: #fff;
    background-color: #466482 !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-vk:active:hover, .btn-vk:active:focus, .btn-vk:active.focus, .btn-vk.active:hover, .btn-vk.active:focus, .btn-vk.active.focus,
    .open > .btn-vk.dropdown-toggle:hover,
    .open > .btn-vk.dropdown-toggle:focus,
    .open > .btn-vk.dropdown-toggle.focus {
      color: #fff;
      background-color: #3a526b !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-vk:active, .btn-vk.active,
  .open > .btn-vk.dropdown-toggle {
    background-image: none; }
  .btn-vk.disabled:hover, .btn-vk.disabled:focus, .btn-vk.disabled.focus, .btn-vk[disabled]:hover, .btn-vk[disabled]:focus, .btn-vk[disabled].focus,
  fieldset[disabled] .btn-vk:hover,
  fieldset[disabled] .btn-vk:focus,
  fieldset[disabled] .btn-vk.focus {
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-vk .badge {
    color: #587ea3;
    background-color: #fff; }

.btn-yahoo {
  background-color: #720e9e;
  color: #fff !important;
  background-color: #720e9e;
  border-color: rgba(0, 0, 0, 0.2); }
  .btn-yahoo:focus, .btn-yahoo.focus {
    color: #fff;
    background-color: #500a6f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-yahoo:hover {
    color: #fff;
    background-color: #500a6f !important;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-yahoo:active, .btn-yahoo.active,
  .open > .btn-yahoo.dropdown-toggle {
    color: #fff;
    background-color: #500a6f !important;
    border-color: rgba(0, 0, 0, 0.2); }
    .btn-yahoo:active:hover, .btn-yahoo:active:focus, .btn-yahoo:active.focus, .btn-yahoo.active:hover, .btn-yahoo.active:focus, .btn-yahoo.active.focus,
    .open > .btn-yahoo.dropdown-toggle:hover,
    .open > .btn-yahoo.dropdown-toggle:focus,
    .open > .btn-yahoo.dropdown-toggle.focus {
      color: #fff;
      background-color: #39074e !important;
      border-color: rgba(0, 0, 0, 0.2); }
  .btn-yahoo:active, .btn-yahoo.active,
  .open > .btn-yahoo.dropdown-toggle {
    background-image: none; }
  .btn-yahoo.disabled:hover, .btn-yahoo.disabled:focus, .btn-yahoo.disabled.focus, .btn-yahoo[disabled]:hover, .btn-yahoo[disabled]:focus, .btn-yahoo[disabled].focus,
  fieldset[disabled] .btn-yahoo:hover,
  fieldset[disabled] .btn-yahoo:focus,
  fieldset[disabled] .btn-yahoo.focus {
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2); }
  .btn-yahoo .badge {
    color: #720e9e;
    background-color: #fff; }

.btn-outline-adn {
  border: 1px solid #d87a68 !important;
  color: #d87a68 !important; }

.btn-outline-bitbucket {
  border: 1px solid #205081 !important;
  color: #205081 !important; }

.btn-outline-dropbox {
  border: 1px solid #1087dd !important;
  color: #1087dd !important; }

.btn-outline-facebook {
  border: 1px solid #3b5998 !important;
  color: #3b5998 !important; }

.btn-outline-flickr {
  border: 1px solid #ff0084 !important;
  color: #ff0084 !important; }

.btn-outline-foursquare {
  border: 1px solid #f94877 !important;
  color: #f94877 !important; }

.btn-outline-github {
  border: 1px solid #444444 !important;
  color: #444444 !important; }

.btn-outline-google {
  border: 1px solid #dd4b39 !important;
  color: #dd4b39 !important; }

.btn-outline-instagram {
  border: 1px solid #3f729b !important;
  color: #3f729b !important; }

.btn-outline-linkedin {
  border: 1px solid #007bb6 !important;
  color: #007bb6 !important; }

.btn-outline-microsoft {
  border: 1px solid #2672ec !important;
  color: #2672ec !important; }

.btn-outline-odnoklassniki {
  border: 1px solid #f4731c !important;
  color: #f4731c !important; }

.btn-outline-openid {
  border: 1px solid #f7931e !important;
  color: #f7931e !important; }

.btn-outline-pinterest {
  border: 1px solid #cb2027 !important;
  color: #cb2027 !important; }

.btn-outline-reddit {
  border: 1px solid #ff4500 !important;
  color: #ff4500 !important; }

.btn-outline-soundcloud {
  border: 1px solid #ff5500 !important;
  color: #ff5500 !important; }

.btn-outline-tumblr {
  border: 1px solid #2c4762 !important;
  color: #2c4762 !important; }

.btn-outline-twitter {
  border: 1px solid #55acee !important;
  color: #55acee !important; }

.btn-outline-vimeo {
  border: 1px solid #1ab7ea !important;
  color: #1ab7ea !important; }

.btn-outline-vk {
  border: 1px solid #587ea3 !important;
  color: #587ea3 !important; }

.btn-outline-yahoo {
  border: 1px solid #720e9e !important;
  color: #720e9e !important; }

.btn-outline-adn:hover {
  color: #ad412d;
  border: 1px solid #ad412d !important;
  background-color: transparent !important; }

.btn-outline-bitbucket:hover {
  color: #0c1d2f;
  border: 1px solid #0c1d2f !important;
  background-color: transparent !important; }

.btn-outline-dropbox:hover {
  color: #094d7e;
  border: 1px solid #094d7e !important;
  background-color: transparent !important; }

.btn-outline-facebook:hover {
  color: #1e2e4f;
  border: 1px solid #1e2e4f !important;
  background-color: transparent !important; }

.btn-outline-flickr:hover {
  color: #99004f;
  border: 1px solid #99004f !important;
  background-color: transparent !important; }

.btn-outline-foursquare:hover {
  color: #d4073d;
  border: 1px solid #d4073d !important;
  background-color: transparent !important; }

.btn-outline-github:hover {
  color: #111111;
  border: 1px solid #111111 !important;
  background-color: transparent !important; }

.btn-outline-google:hover {
  color: #96271a;
  border: 1px solid #96271a !important;
  background-color: transparent !important; }

.btn-outline-instagram:hover {
  color: #223d52;
  border: 1px solid #223d52 !important;
  background-color: transparent !important; }

.btn-outline-linkedin:hover {
  color: #003650;
  border: 1px solid #003650 !important;
  background-color: transparent !important; }

.btn-outline-microsoft:hover {
  color: #0e459e;
  border: 1px solid #0e459e !important;
  background-color: transparent !important; }

.btn-outline-odnoklassniki:hover {
  color: #a24608;
  border: 1px solid #a24608 !important;
  background-color: transparent !important; }

.btn-outline-openid:hover {
  color: #a95e06;
  border: 1px solid #a95e06 !important;
  background-color: transparent !important; }

.btn-outline-pinterest:hover {
  color: #731216;
  border: 1px solid #731216 !important;
  background-color: transparent !important; }

.btn-outline-reddit:hover {
  color: #992900;
  border: 1px solid #992900 !important;
  background-color: transparent !important; }

.btn-outline-soundcloud:hover {
  color: #993300;
  border: 1px solid #993300 !important;
  background-color: transparent !important; }

.btn-outline-tumblr:hover {
  color: #0c141c;
  border: 1px solid #0c141c !important;
  background-color: transparent !important; }

.btn-outline-twitter:hover {
  color: #147bc9;
  border: 1px solid #147bc9 !important;
  background-color: transparent !important; }

.btn-outline-vimeo:hover {
  color: #0d7091;
  border: 1px solid #0d7091 !important;
  background-color: transparent !important; }

.btn-outline-vk:hover {
  color: #344b61;
  border: 1px solid #344b61 !important;
  background-color: transparent !important; }

.btn-outline-yahoo:hover {
  color: #2e0640;
  border: 1px solid #2e0640 !important;
  background-color: transparent !important; }

.btn-adn-flat {
  color: #d87a68 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-adn-flat span {
    border: none !important; }
  .btn-adn-flat:hover {
    color: #993a28 !important;
    background-color: transparent !important; }

.btn-bitbucket-flat {
  color: #205081 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-bitbucket-flat span {
    border: none !important; }
  .btn-bitbucket-flat:hover {
    color: #07111b !important;
    background-color: transparent !important; }

.btn-dropbox-flat {
  color: #1087dd !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-dropbox-flat span {
    border: none !important; }
  .btn-dropbox-flat:hover {
    color: #073e66 !important;
    background-color: transparent !important; }

.btn-facebook-flat {
  color: #3b5998 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-facebook-flat span {
    border: none !important; }
  .btn-facebook-flat:hover {
    color: #17233c !important;
    background-color: transparent !important; }

.btn-flickr-flat {
  color: #ff0084 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-flickr-flat span {
    border: none !important; }
  .btn-flickr-flat:hover {
    color: #800042 !important;
    background-color: transparent !important; }

.btn-foursquare-flat {
  color: #f94877 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-foursquare-flat span {
    border: none !important; }
  .btn-foursquare-flat:hover {
    color: #bb0636 !important;
    background-color: transparent !important; }

.btn-github-flat {
  color: #444444 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-github-flat span {
    border: none !important; }
  .btn-github-flat:hover {
    color: #040404 !important;
    background-color: transparent !important; }

.btn-google-flat {
  color: #dd4b39 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-google-flat span {
    border: none !important; }
  .btn-google-flat:hover {
    color: #802216 !important;
    background-color: transparent !important; }

.btn-instagram-flat {
  color: #3f729b !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-instagram-flat span {
    border: none !important; }
  .btn-instagram-flat:hover {
    color: #1a2f40 !important;
    background-color: transparent !important; }

.btn-linkedin-flat {
  color: #007bb6 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-linkedin-flat span {
    border: none !important; }
  .btn-linkedin-flat:hover {
    color: #002537 !important;
    background-color: transparent !important; }

.btn-microsoft-flat {
  color: #2672ec !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-microsoft-flat span {
    border: none !important; }
  .btn-microsoft-flat:hover {
    color: #0c3b87 !important;
    background-color: transparent !important; }

.btn-odnoklassniki-flat {
  color: #f4731c !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-odnoklassniki-flat span {
    border: none !important; }
  .btn-odnoklassniki-flat:hover {
    color: #8a3c07 !important;
    background-color: transparent !important; }

.btn-openid-flat {
  color: #f7931e !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-openid-flat span {
    border: none !important; }
  .btn-openid-flat:hover {
    color: #905005 !important;
    background-color: transparent !important; }

.btn-pinterest-flat {
  color: #cb2027 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-pinterest-flat span {
    border: none !important; }
  .btn-pinterest-flat:hover {
    color: #5d0f12 !important;
    background-color: transparent !important; }

.btn-reddit-flat {
  color: #ff4500 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-reddit-flat span {
    border: none !important; }
  .btn-reddit-flat:hover {
    color: #802300 !important;
    background-color: transparent !important; }

.btn-soundcloud-flat {
  color: #ff5500 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-soundcloud-flat span {
    border: none !important; }
  .btn-soundcloud-flat:hover {
    color: #802b00 !important;
    background-color: transparent !important; }

.btn-tumblr-flat {
  color: #2c4762 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-tumblr-flat span {
    border: none !important; }
  .btn-tumblr-flat:hover {
    color: #04070a !important;
    background-color: transparent !important; }

.btn-twitter-flat {
  color: #55acee !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-twitter-flat span {
    border: none !important; }
  .btn-twitter-flat:hover {
    color: #126db2 !important;
    background-color: transparent !important; }

.btn-vimeo-flat {
  color: #1ab7ea !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-vimeo-flat span {
    border: none !important; }
  .btn-vimeo-flat:hover {
    color: #0b5e79 !important;
    background-color: transparent !important; }

.btn-vk-flat {
  color: #587ea3 !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-vk-flat span {
    border: none !important; }
  .btn-vk-flat:hover {
    color: #2b3e50 !important;
    background-color: transparent !important; }

.btn-yahoo-flat {
  color: #720e9e !important;
  background-color: transparent !important;
  border: none !important; }
  .btn-yahoo-flat span {
    border: none !important; }
  .btn-yahoo-flat:hover {
    color: #1d0429 !important;
    background-color: transparent !important; }

.btn-adn-flat.active, .btn-adn-flat:active, .btn-adn-flat:focus, .btn-adn-flat:active:focus, .btn-adn-flat:active:hover {
  background-color: transparent !important; }

.btn-bitbucket-flat.active, .btn-bitbucket-flat:active, .btn-bitbucket-flat:focus, .btn-bitbucket-flat:active:focus, .btn-bitbucket-flat:active:hover {
  background-color: transparent !important; }

.btn-dropbox-flat.active, .btn-dropbox-flat:active, .btn-dropbox-flat:focus, .btn-dropbox-flat:active:focus, .btn-dropbox-flat:active:hover {
  background-color: transparent !important; }

.btn-facebook-flat.active, .btn-facebook-flat:active, .btn-facebook-flat:focus, .btn-facebook-flat:active:focus, .btn-facebook-flat:active:hover {
  background-color: transparent !important; }

.btn-flickr-flat.active, .btn-flickr-flat:active, .btn-flickr-flat:focus, .btn-flickr-flat:active:focus, .btn-flickr-flat:active:hover {
  background-color: transparent !important; }

.btn-foursquare-flat.active, .btn-foursquare-flat:active, .btn-foursquare-flat:focus, .btn-foursquare-flat:active:focus, .btn-foursquare-flat:active:hover {
  background-color: transparent !important; }

.btn-github-flat.active, .btn-github-flat:active, .btn-github-flat:focus, .btn-github-flat:active:focus, .btn-github-flat:active:hover {
  background-color: transparent !important; }

.btn-google-flat.active, .btn-google-flat:active, .btn-google-flat:focus, .btn-google-flat:active:focus, .btn-google-flat:active:hover {
  background-color: transparent !important; }

.btn-instagram-flat.active, .btn-instagram-flat:active, .btn-instagram-flat:focus, .btn-instagram-flat:active:focus, .btn-instagram-flat:active:hover {
  background-color: transparent !important; }

.btn-linkedin-flat.active, .btn-linkedin-flat:active, .btn-linkedin-flat:focus, .btn-linkedin-flat:active:focus, .btn-linkedin-flat:active:hover {
  background-color: transparent !important; }

.btn-microsoft-flat.active, .btn-microsoft-flat:active, .btn-microsoft-flat:focus, .btn-microsoft-flat:active:focus, .btn-microsoft-flat:active:hover {
  background-color: transparent !important; }

.btn-odnoklassniki-flat.active, .btn-odnoklassniki-flat:active, .btn-odnoklassniki-flat:focus, .btn-odnoklassniki-flat:active:focus, .btn-odnoklassniki-flat:active:hover {
  background-color: transparent !important; }

.btn-openid-flat.active, .btn-openid-flat:active, .btn-openid-flat:focus, .btn-openid-flat:active:focus, .btn-openid-flat:active:hover {
  background-color: transparent !important; }

.btn-pinterest-flat.active, .btn-pinterest-flat:active, .btn-pinterest-flat:focus, .btn-pinterest-flat:active:focus, .btn-pinterest-flat:active:hover {
  background-color: transparent !important; }

.btn-reddit-flat.active, .btn-reddit-flat:active, .btn-reddit-flat:focus, .btn-reddit-flat:active:focus, .btn-reddit-flat:active:hover {
  background-color: transparent !important; }

.btn-soundcloud-flat.active, .btn-soundcloud-flat:active, .btn-soundcloud-flat:focus, .btn-soundcloud-flat:active:focus, .btn-soundcloud-flat:active:hover {
  background-color: transparent !important; }

.btn-tumblr-flat.active, .btn-tumblr-flat:active, .btn-tumblr-flat:focus, .btn-tumblr-flat:active:focus, .btn-tumblr-flat:active:hover {
  background-color: transparent !important; }

.btn-twitter-flat.active, .btn-twitter-flat:active, .btn-twitter-flat:focus, .btn-twitter-flat:active:focus, .btn-twitter-flat:active:hover {
  background-color: transparent !important; }

.btn-vimeo-flat.active, .btn-vimeo-flat:active, .btn-vimeo-flat:focus, .btn-vimeo-flat:active:focus, .btn-vimeo-flat:active:hover {
  background-color: transparent !important; }

.btn-vk-flat.active, .btn-vk-flat:active, .btn-vk-flat:focus, .btn-vk-flat:active:focus, .btn-vk-flat:active:hover {
  background-color: transparent !important; }

.btn-yahoo-flat.active, .btn-yahoo-flat:active, .btn-yahoo-flat:focus, .btn-yahoo-flat:active:focus, .btn-yahoo-flat:active:hover {
  background-color: transparent !important; }

[dir="rtl"] .btn-social, [dir="rtl"] .btn-social-icon {
  padding-right: 3.025rem;
  padding-left: auto;
  text-align: right; }
  [dir="rtl"] .btn-social > :first-child, [dir="rtl"] .btn-social-icon > :first-child {
    right: 0;
    left: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-right: 0px; }
  [dir="rtl"] .btn-social.btn-lg, [dir="rtl"] .btn-group-lg > .btn-social.btn, [dir="rtl"] .btn-group-lg > .btn.btn-social-icon, [dir="rtl"] .btn-lg.btn-social-icon {
    padding-right: 3.2rem;
    padding-left: 0px; }
  [dir="rtl"] .btn-social.btn-sm, [dir="rtl"] .btn-group-sm > .btn-social.btn, [dir="rtl"] .btn-group-sm > .btn.btn-social-icon, [dir="rtl"] .btn-sm.btn-social-icon {
    padding-right: 2.55rem;
    padding-left: 0px; }
  [dir="rtl"] .btn-social.btn-xs, [dir="rtl"] .btn-xs.btn-social-icon {
    padding-right: 2.15rem;
    padding-left: 0px; }

/*========== Coming Soon Background Image =========*/
.comingsoonVideo .app-content .coming-soon-content {
  z-index: 99; }

.clockCard {
  float: left;
  border-radius: .50rem; }

.getting-started {
  font-size: 2.4rem;
  border-radius: 60px;
  min-width: 360px; }

.countdownFormat {
  font-size: 16px;
  padding-bottom: 0px; }

.socialIcon i {
  font-size: 28px;
  padding: 5px; }

.clockFormat {
  border-radius: 60px; }

[dir="rtl"] .clockCard {
  float: right; }

#search-nav .nav {
  border-bottom: 2px solid #e0e0e0; }

#search-nav .nav-link.active {
  padding-bottom: 6px;
  border-bottom: 2px solid #007bff;
  margin-bottom: -2px; }

#search-results .media-search {
  padding-top: 5px; }

#search-results p {
  font-size: 0.9rem; }

#search-results .media-list {
  padding: 0; }
  #search-results .media-list .media {
    padding: 1.25rem;
    margin-top: 0; }

hr {
  border-color: #a8a8a8; }

ul.list-inline i {
  line-height: 18px; }

ul.list-inline li {
  display: inline-block; }

ul.list-inline.list-inline-pipe > li + li:before {
  content: ' | ';
  color: #868e96; }

.circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 2s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 2s ease-in-out infinite;
  stroke-linecap: round; }

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@-webkit-keyframes color {
  100%,
  0% {
    stroke: #007bff; }
  50% {
    stroke: #ffc107; }
  100% {
    stroke: #007bff; } }

@keyframes color {
  100%,
  0% {
    stroke: #007bff; }
  50% {
    stroke: #ffc107; }
  100% {
    stroke: #007bff; } }

/*           Animations              */
.animation-transition-general {
  transition: all 300ms linear; }

.animation-transition-slow {
  transition: all 370ms linear; }

.animation-transition-fast {
  transition: all 150ms ease 0s; }

legend {
  border-bottom: 0; }

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent; }
  *:focus {
    outline: 0; }

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important; }

.table-borderless tbody tr td, .table-borderless tbody tr th {
  border: 0; }

datetime .form-group {
  margin: 0; }

.btn-round {
  border-radius: 30px; }

.fonticon-container {
  padding: 10px; }
  .fonticon-container > .fonticon-wrap {
    float: left;
    width: 40px;
    height: 40px;
    line-height: 4.8rem;
    text-align: center;
    border-radius: 0.1875rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    transition: all .2s ease-in-out; }
    .fonticon-container > .fonticon-wrap > svg {
      font-size: 2rem;
      transition: all .2s ease-in-out; }
      .fonticon-container > .fonticon-wrap > svg.fa {
        font-size: 2rem !important; }
    .fonticon-container > .fonticon-wrap.youtube {
      width: 100px; }
  .fonticon-container:hover svg {
    color: #007bff;
    font-size: 2.9rem;
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
  .fonticon-container > .fonticon-classname, .fonticon-container > .fonticon-unit {
    display: block;
    font-size: 1.5rem;
    line-height: 1.8; }
  .fonticon-container > .fonticon-unit {
    font-size: 1rem;
    font-style: italic; }

.langimg {
  width: 30px;
  margin-right: 5px; }

.notification-dropdown {
  width: 400px; }
  .notification-dropdown .noti-list {
    position: relative;
    height: 290px; }
  .notification-dropdown.dropdown-menu {
    padding: 0; }
  .notification-dropdown .noti-text {
    color: #6c757d;
    font-size: 90%; }
  .notification-dropdown .dropdown-item:focus i, .notification-dropdown .dropdown-item:hover i {
    color: #007bff !important; }
  .notification-dropdown .dropdown-item.active i, .notification-dropdown .dropdown-item.active .noti-title, .notification-dropdown .dropdown-item.active .noti-text, .notification-dropdown .dropdown-item:active i, .notification-dropdown .dropdown-item:active .noti-title, .notification-dropdown .dropdown-item:active .noti-text {
    color: white !important; }

@media (min-width: 768px) {
  .regular-button {
    width: 50% !important;
    margin: 5px auto;
    min-width: 100px; } }

.dropdown-menu.dropdown-menu-right.show {
  right: auto;
  left: 0; }
  .dropdown-menu.dropdown-menu-right.show .dropdown-item {
    text-align: right; }

.custom-control-input:active ~ .custom-control-indicator {
  background-color: #007bff; }

.dragdrop-container {
  min-height: 200px; }

.content-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 2;
  visibility: hidden; }
  .content-overlay.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.6); }

[dir="rtl"] .fonticon-container > .fonticon-wrap {
  float: right;
  margin-left: 1rem;
  margin-right: auto; }

[dir="rtl"] .langimg {
  margin-left: 5px;
  margin-right: auto; }

.ReactTable .rt-thead {
  overflow-y: scroll; }

.loader-bg {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #3C4252; }

.loader-content {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%; }
  .loader-content .logo {
    margin-left: -72px;
    margin-top: -152px; }
  .loader-content .loading {
    margin-left: -30px;
    margin-top: 30px; }

.sweet-loading {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px; }
  .sweet-loading.relative-spinner {
    margin-top: 0;
    position: relative;
    display: inline-block; }

.rrt-title {
  text-align: right; }

.rrt-text {
  text-align: right; }

#user-profile .profile-with-cover {
  position: relative; }
  #user-profile .profile-with-cover .profil-cover-details {
    margin-top: -50px; }
    #user-profile .profile-with-cover .profil-cover-details .profile-image img.img-border {
      border: 5px solid #fff; }

.profile-section .profile-menu {
  position: relative;
  top: -30px;
  padding-bottom: 0;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around; }
  .profile-section .profile-menu li a {
    display: block; }
    .profile-section .profile-menu li a.active {
      border-bottom: 1px solid #009DA0; }

@media (max-width: 550px) {
  #user-profile .profile-section .profile-menu {
    top: 0px; }
  #user-profile .profile-with-cover .profile-cover-buttons {
    top: 15px;
    right: 0; }
  #user-profile h3.card-title {
    font-size: 1rem; } }

[dir="rtl"] #user-profile .profile-with-cover .profile-cover-buttons {
  left: 0;
  right: auto; }

[dir="rtl"] .profile-section .profile-menu {
  padding-right: 15px;
  padding-left: auto; }

@media (max-width: 550px) {
  [dir="rtl"] #user-profile .profile-with-cover .profile-cover-buttons {
    left: 0;
    right: auto; } }

.wrapper {
  position: relative;
  top: 0; }
  .wrapper .content-header {
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem; }
  .wrapper .content-sub-header {
    color: #3f587e;
    margin-bottom: 1rem; }
  .wrapper .collapsed.app-sidebar {
    width: 60px; }
    .wrapper .collapsed.app-sidebar:hover {
      width: 250px; }
      .wrapper .collapsed.app-sidebar:hover .nav li.has-sub > a:after {
        display: inherit; }
      .wrapper .collapsed.app-sidebar:hover .menu-item-text,
      .wrapper .collapsed.app-sidebar:hover .badge {
        display: inline-block; }
      .wrapper .collapsed.app-sidebar:hover .logo .text {
        display: inline-block; }
      .wrapper .collapsed.app-sidebar:hover .nav-toggle {
        display: block !important; }
    .wrapper .collapsed.app-sidebar .menu-item-text {
      visibility: hidden; }
    .wrapper .collapsed.app-sidebar .badge {
      visibility: hidden; }
    .wrapper .collapsed.app-sidebar .logo .text {
      visibility: hidden; }
    .wrapper .collapsed.app-sidebar .item-arrow {
      visibility: hidden; }
    .wrapper .collapsed.app-sidebar .nav-toggle {
      visibility: hidden !important; }
    .wrapper .collapsed.app-sidebar .nav-close {
      visibility: hidden !important; }
  .wrapper .collapsed .navbar .navbar-header {
    padding-left: 50px; }
  .wrapper .collapsed .main-panel .main-content {
    padding-left: 60px; }
  .wrapper .collapsed footer {
    padding-left: 60px; }
  .wrapper.menu-collapsed .app-sidebar .navigation li.has-sub > a:after {
    display: none; }

.bootstrap-navbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center; }

.app-sidebar,
.off-canvas-sidebar {
  position: relative;
  width: 140px;
  min-width: 140px;
  height: 100%; }
  @media (max-width: 768px) {
    .app-sidebar,
    .off-canvas-sidebar {
      width: 100px;
      min-width: 100px; } }
  .app-sidebar.hide-sidebar,
  .off-canvas-sidebar.hide-sidebar {
    transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .app-sidebar .sidebar-content,
  .off-canvas-sidebar .sidebar-content {
    position: relative;
    overflow: auto;
    z-index: 4; }
  .app-sidebar .logo-img,
  .off-canvas-sidebar .logo-img {
    width: 30px;
    display: inline-block;
    max-height: 30px;
    margin-right: 1rem; }
  .app-sidebar .logo-tim,
  .off-canvas-sidebar .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden; }
    .app-sidebar .logo-tim img,
    .off-canvas-sidebar .logo-tim img {
      width: 60px;
      height: 60px; }
  .app-sidebar .navigation,
  .off-canvas-sidebar .navigation {
    margin: 0;
    padding-left: 0;
    list-style: none; }
    .app-sidebar .navigation li,
    .off-canvas-sidebar .navigation li {
      position: relative;
      display: block;
      margin-bottom: 31px; }
      .app-sidebar .navigation li:first-child,
      .off-canvas-sidebar .navigation li:first-child {
        padding-top: 0; }
      .app-sidebar .navigation li:last-child,
      .off-canvas-sidebar .navigation li:last-child {
        padding-bottom: 0; }
      .app-sidebar .navigation li.has-sub > a > .item-arrow svg,
      .off-canvas-sidebar .navigation li.has-sub > a > .item-arrow svg {
        -webkit-transform: rotate(0);
                transform: rotate(0);
        transition: -webkit-transform 0.2s ease-in-out;
        position: absolute;
        right: 15px;
        top: 18px; }
      .app-sidebar .navigation li.has-sub > a,
      .off-canvas-sidebar .navigation li.has-sub > a {
        margin-bottom: 0; }
      .app-sidebar .navigation li.open > a > .item-arrow svg,
      .off-canvas-sidebar .navigation li.open > a > .item-arrow svg {
        -webkit-transform: rotate(90deg);
        transform: rotate(180deg); }
      .app-sidebar .navigation li.open > a,
      .off-canvas-sidebar .navigation li.open > a {
        background: rgba(200, 200, 200, 0.2); }
      .app-sidebar .navigation li > a,
      .off-canvas-sidebar .navigation li > a {
        display: block;
        font-size: 13px;
        line-height: 20px;
        margin: 0;
        background-color: transparent;
        color: #004481;
        transition: color 0.3s linear; }
      .app-sidebar .navigation li:not(.open) > ul,
      .off-canvas-sidebar .navigation li:not(.open) > ul {
        display: none; }
      .app-sidebar .navigation li:hover > a:hover:not([disabled]),
      .off-canvas-sidebar .navigation li:hover > a:hover:not([disabled]) {
        color: #f37920; }
      .app-sidebar .navigation li > a.active,
      .off-canvas-sidebar .navigation li > a.active {
        font-weight: 700; }
        .app-sidebar .navigation li > a.active:before,
        .off-canvas-sidebar .navigation li > a.active:before {
          left: 0; }
      .app-sidebar .navigation li ul,
      .off-canvas-sidebar .navigation li ul {
        padding: 0;
        margin: 0;
        overflow: hidden;
        font-size: 1rem; }
        .app-sidebar .navigation li ul li a,
        .off-canvas-sidebar .navigation li ul li a {
          display: block;
          padding: 8px 18px 8px 60px; }
        .app-sidebar .navigation li ul li ul li a,
        .off-canvas-sidebar .navigation li ul li ul li a {
          padding: 8px 18px 8px 25px; }
    .app-sidebar .navigation .badge.badge-pill,
    .off-canvas-sidebar .navigation .badge.badge-pill {
      position: absolute;
      top: 16px;
      right: 32px;
      padding: 4px 8px;
      font-weight: 400;
      transition: width 0.3s cubic-bezier(0, 0, 0.2, 1); }
    .app-sidebar .navigation i,
    .off-canvas-sidebar .navigation i {
      font-size: 18px;
      float: left;
      margin-right: 10px;
      line-height: 2rem;
      width: 30px;
      text-align: center;
      color: #a9afbb; }
  .app-sidebar .sidebar-background,
  .off-canvas-sidebar .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center; }
    .app-sidebar .sidebar-background:after,
    .off-canvas-sidebar .sidebar-background:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: "";
      display: block; }
  .app-sidebar .logo,
  .off-canvas-sidebar .logo {
    position: relative;
    padding: 20px 10px;
    z-index: 4; }
    .app-sidebar .logo p,
    .off-canvas-sidebar .logo p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: #fff;
      line-height: 20px; }
    .app-sidebar .logo .logo-text,
    .off-canvas-sidebar .logo .logo-text {
      text-transform: uppercase;
      padding: 5px 0 5px 5px;
      display: block;
      font-size: 1.6rem;
      letter-spacing: 2px;
      color: #000000;
      text-align: left;
      font-weight: 400; }
  .app-sidebar .logo-tim,
  .off-canvas-sidebar .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden; }
    .app-sidebar .logo-tim img,
    .off-canvas-sidebar .logo-tim img {
      width: 60px;
      height: 60px; }
  .app-sidebar:after, .app-sidebar:before,
  .off-canvas-sidebar:after,
  .off-canvas-sidebar:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
  .app-sidebar:before,
  .off-canvas-sidebar:before {
    opacity: 0.33; }
  .app-sidebar:after,
  .off-canvas-sidebar:after {
    z-index: 3;
    opacity: 1; }
  .app-sidebar[data-image]:after, .app-sidebar.has-image:after,
  .off-canvas-sidebar[data-image]:after,
  .off-canvas-sidebar.has-image:after {
    opacity: 0.77; }
  .app-sidebar[data-background-color="white"],
  .off-canvas-sidebar[data-background-color="white"] {
    background-color: #fff; }
    .app-sidebar[data-background-color="white"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="white"] .sidebar-background:after {
      background: #fff;
      opacity: 0.9; }
    .app-sidebar[data-background-color="white"] .navigation i, .app-sidebar[data-background-color="white"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="white"] .navigation i,
    .off-canvas-sidebar[data-background-color="white"] .navigation li > a {
      color: #000000; }
    .app-sidebar[data-background-color="white"] .logo-text,
    .off-canvas-sidebar[data-background-color="white"] .logo-text {
      color: #000000; }
    .app-sidebar[data-background-color="white"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="white"] .sidebar-header .nav-toggle {
      color: #000000; }
    .app-sidebar[data-background-color="white"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="white"] .sidebar-header .nav-close {
      color: #000000; }
  .app-sidebar[data-background-color="black"],
  .off-canvas-sidebar[data-background-color="black"] {
    background-color: #282a3c; }
    .app-sidebar[data-background-color="black"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="black"] .sidebar-background:after {
      background: #282a3c;
      opacity: 0.9; }
    .app-sidebar[data-background-color="black"] .navigation i, .app-sidebar[data-background-color="black"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="black"] .navigation i,
    .off-canvas-sidebar[data-background-color="black"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="black"] .logo-text,
    .off-canvas-sidebar[data-background-color="black"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="black"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="black"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="black"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="black"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="blue"],
  .off-canvas-sidebar[data-background-color="blue"] {
    background-color: #5a82f6; }
    .app-sidebar[data-background-color="blue"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="blue"] .sidebar-background:after {
      background: #5a82f6;
      opacity: 0.9; }
    .app-sidebar[data-background-color="blue"] .navigation i, .app-sidebar[data-background-color="blue"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="blue"] .navigation i,
    .off-canvas-sidebar[data-background-color="blue"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="blue"] .logo-text,
    .off-canvas-sidebar[data-background-color="blue"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="blue"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="blue"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="blue"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="blue"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="red"],
  .off-canvas-sidebar[data-background-color="red"] {
    background-color: #d34048; }
    .app-sidebar[data-background-color="red"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="red"] .sidebar-background:after {
      background: #d34048;
      opacity: 0.9; }
    .app-sidebar[data-background-color="red"] .navigation i, .app-sidebar[data-background-color="red"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="red"] .navigation i,
    .off-canvas-sidebar[data-background-color="red"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="red"] .logo-text,
    .off-canvas-sidebar[data-background-color="red"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="red"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="red"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="red"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="red"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="purple"],
  .off-canvas-sidebar[data-background-color="purple"] {
    background-color: #7367f0; }
    .app-sidebar[data-background-color="purple"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="purple"] .sidebar-background:after {
      background: #7367f0;
      opacity: 0.9; }
    .app-sidebar[data-background-color="purple"] .navigation i, .app-sidebar[data-background-color="purple"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="purple"] .navigation i,
    .off-canvas-sidebar[data-background-color="purple"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="purple"] .logo-text,
    .off-canvas-sidebar[data-background-color="purple"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="purple"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="purple"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="purple"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="purple"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="orange"],
  .off-canvas-sidebar[data-background-color="orange"] {
    background-color: #e64a19; }
    .app-sidebar[data-background-color="orange"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="orange"] .sidebar-background:after {
      background: #e64a19;
      opacity: 0.9; }
    .app-sidebar[data-background-color="orange"] .navigation i, .app-sidebar[data-background-color="orange"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="orange"] .navigation i,
    .off-canvas-sidebar[data-background-color="orange"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="orange"] .logo-text,
    .off-canvas-sidebar[data-background-color="orange"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="orange"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="orange"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="orange"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="orange"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="navy-blue"],
  .off-canvas-sidebar[data-background-color="navy-blue"] {
    background-color: #0e5cad; }
    .app-sidebar[data-background-color="navy-blue"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="navy-blue"] .sidebar-background:after {
      background: #0e5cad;
      opacity: 0.9; }
    .app-sidebar[data-background-color="navy-blue"] .navigation i, .app-sidebar[data-background-color="navy-blue"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="navy-blue"] .navigation i,
    .off-canvas-sidebar[data-background-color="navy-blue"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="navy-blue"] .logo-text,
    .off-canvas-sidebar[data-background-color="navy-blue"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="navy-blue"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="navy-blue"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="navy-blue"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="navy-blue"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="pomegranate"],
  .off-canvas-sidebar[data-background-color="pomegranate"] {
    background-image: linear-gradient(45deg, #9b3cb7, #ff396f);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="pomegranate"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="pomegranate"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #9b3cb7, #ff396f);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="pomegranate"] .navigation i, .app-sidebar[data-background-color="pomegranate"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="pomegranate"] .navigation i,
    .off-canvas-sidebar[data-background-color="pomegranate"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="pomegranate"] .logo-text,
    .off-canvas-sidebar[data-background-color="pomegranate"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="pomegranate"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="pomegranate"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="pomegranate"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="pomegranate"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="king-yna"],
  .off-canvas-sidebar[data-background-color="king-yna"] {
    background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="king-yna"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="king-yna"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #1a2a6c, #b21f1f);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="king-yna"] .navigation i, .app-sidebar[data-background-color="king-yna"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="king-yna"] .navigation i,
    .off-canvas-sidebar[data-background-color="king-yna"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="king-yna"] .logo-text,
    .off-canvas-sidebar[data-background-color="king-yna"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="king-yna"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="king-yna"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="king-yna"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="king-yna"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="ibiza-sunset"],
  .off-canvas-sidebar[data-background-color="ibiza-sunset"] {
    background-image: linear-gradient(45deg, #ee0979, #ff6a00);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="ibiza-sunset"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #ee0979, #ff6a00);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="ibiza-sunset"] .navigation i, .app-sidebar[data-background-color="ibiza-sunset"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .navigation i,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="ibiza-sunset"] .logo-text,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="ibiza-sunset"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="ibiza-sunset"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="ibiza-sunset"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="flickr"],
  .off-canvas-sidebar[data-background-color="flickr"] {
    background-image: linear-gradient(45deg, #33001b, #ff0084);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="flickr"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="flickr"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #33001b, #ff0084);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="flickr"] .navigation i, .app-sidebar[data-background-color="flickr"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="flickr"] .navigation i,
    .off-canvas-sidebar[data-background-color="flickr"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="flickr"] .logo-text,
    .off-canvas-sidebar[data-background-color="flickr"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="flickr"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="flickr"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="flickr"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="flickr"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="purple-bliss"],
  .off-canvas-sidebar[data-background-color="purple-bliss"] {
    background-image: linear-gradient(45deg, #360033, #0b8793);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="purple-bliss"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #360033, #0b8793);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="purple-bliss"] .navigation i, .app-sidebar[data-background-color="purple-bliss"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .navigation i,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="purple-bliss"] .logo-text,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="purple-bliss"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="purple-bliss"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="purple-bliss"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="man-of-steel"],
  .off-canvas-sidebar[data-background-color="man-of-steel"] {
    background-image: linear-gradient(45deg, #780206, #061161);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="man-of-steel"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #780206, #061161);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="man-of-steel"] .navigation i, .app-sidebar[data-background-color="man-of-steel"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .navigation i,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="man-of-steel"] .logo-text,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="man-of-steel"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="man-of-steel"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="man-of-steel"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="purple-love"],
  .off-canvas-sidebar[data-background-color="purple-love"] {
    background-image: linear-gradient(45deg, #cc2b5e, #753a88);
    background-repeat: repeat-x; }
    .app-sidebar[data-background-color="purple-love"] .sidebar-background:after,
    .off-canvas-sidebar[data-background-color="purple-love"] .sidebar-background:after {
      background-image: linear-gradient(45deg, #cc2b5e, #753a88);
      background-repeat: repeat-x;
      opacity: 0.9; }
    .app-sidebar[data-background-color="purple-love"] .navigation i, .app-sidebar[data-background-color="purple-love"] .navigation li > a,
    .off-canvas-sidebar[data-background-color="purple-love"] .navigation i,
    .off-canvas-sidebar[data-background-color="purple-love"] .navigation li > a {
      color: #fff; }
    .app-sidebar[data-background-color="purple-love"] .logo-text,
    .off-canvas-sidebar[data-background-color="purple-love"] .logo-text {
      color: #fff; }
    .app-sidebar[data-background-color="purple-love"] .sidebar-header .nav-toggle,
    .off-canvas-sidebar[data-background-color="purple-love"] .sidebar-header .nav-toggle {
      color: #fff; }
    .app-sidebar[data-background-color="purple-love"] .sidebar-header .nav-close,
    .off-canvas-sidebar[data-background-color="purple-love"] .sidebar-header .nav-close {
      color: #fff; }
  .app-sidebar[data-background-color="white"] .navigation li a,
  .off-canvas-sidebar[data-background-color="white"] .navigation li a {
    color: #000000; }
  .app-sidebar[data-background-color="black"] .navigation li a,
  .off-canvas-sidebar[data-background-color="black"] .navigation li a {
    color: #fff; }
  .app-sidebar[data-background-color="red"] .navigation li a,
  .off-canvas-sidebar[data-background-color="red"] .navigation li a {
    color: #fff; }
  .app-sidebar[data-background-color="white"] .logo-text,
  .off-canvas-sidebar[data-background-color="white"] .logo-text {
    color: #000000; }
  .app-sidebar[data-background-color="white"] .sidebar-header .nav-toggle,
  .off-canvas-sidebar[data-background-color="white"] .sidebar-header .nav-toggle {
    color: #000000; }
  .app-sidebar[data-color="blue"] .moving-tab,
  .off-canvas-sidebar[data-color="blue"] .moving-tab {
    background-color: #17a2b8; }
  .app-sidebar[data-color="green"] .moving-tab,
  .off-canvas-sidebar[data-color="green"] .moving-tab {
    background-color: #28a745; }
  .app-sidebar[data-color="orange"] .moving-tab,
  .off-canvas-sidebar[data-color="orange"] .moving-tab {
    background-color: #ffc107; }
  .app-sidebar[data-color="red"] .moving-tab,
  .off-canvas-sidebar[data-color="red"] .moving-tab {
    background-color: #dc3545; }
  .app-sidebar[data-color="purple"] .moving-tab,
  .off-canvas-sidebar[data-color="purple"] .moving-tab {
    background-color: #007bff; }

@media (max-width: 767.98px) {
  .app-sidebar,
  .off-canvas-sidebar {
    box-shadow: none; } }

.sidebar-header .nav-toggle {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 17px;
  top: 32px;
  color: white; }

.sidebar-header .nav-close {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 14px;
  top: 32px;
  color: white; }

.off-canvas-sidebar .navigation > li > a,
.off-canvas-sidebar .navigation > li > a:hover {
  color: #fff; }

.off-canvas-sidebar .navigation > li > a:focus {
  background: rgba(200, 200, 200, 0.2); }

.main-panel {
  position: relative;
  z-index: 2;
  float: right;
  min-height: 100%;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1); }
  .main-panel .main-content {
    position: relative;
    min-height: calc(100% - 123px);
    transition: all 0.3s ease; }
  .main-panel div .footer {
    border-top: 1px solid #e7e7e7; }
  .main-panel > .navbar {
    margin-bottom: 0; }

.bootstrap-navbar .navigation {
  margin-top: 20px;
  float: none; }

.main-panel {
  max-height: 100%;
  height: 100%;
  width: 100%; }

.sidebar-sm .app-sidebar {
  width: 220px; }

.sidebar-lg .app-sidebar {
  width: 280px; }

.wrapper[dir="rtl"].collapsed .navbar .navbar-header {
  padding-right: 50px;
  padding-left: auto; }

.wrapper[dir="rtl"].collapsed .main-panel .main-content {
  padding-right: 60px;
  padding-left: auto; }

.wrapper[dir="rtl"].collapsed footer {
  padding-right: 60px;
  padding-left: auto; }

[dir="rtl"] .content-header {
  text-align: right; }

[dir="rtl"] .content-sub-header {
  text-align: right; }

[dir="rtl"] .wrapper.collapsed .navbar .navbar-header {
  padding-right: 50px;
  padding-left: auto; }

[dir="rtl"] .wrapper.collapsed .main-panel .main-content {
  padding-right: 60px;
  padding-left: auto; }

[dir="rtl"] .wrapper.collapsed footer {
  padding-right: 60px;
  padding-left: auto; }

[dir="rtl"] .bootstrap-navbar {
  right: 0;
  left: auto; }

[dir="rtl"] .app-sidebar,
[dir="rtl"] .off-canvas-sidebar {
  right: 0;
  left: auto; }
  [dir="rtl"] .app-sidebar.hide-sidebar,
  [dir="rtl"] .off-canvas-sidebar.hide-sidebar {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  [dir="rtl"] .app-sidebar .logo-img,
  [dir="rtl"] .off-canvas-sidebar .logo-img {
    margin-left: 15px;
    margin-right: auto; }
  [dir="rtl"] .app-sidebar .logo-tim,
  [dir="rtl"] .off-canvas-sidebar .logo-tim {
    float: right; }
  [dir="rtl"] .app-sidebar .navigation,
  [dir="rtl"] .off-canvas-sidebar .navigation {
    padding-right: 0;
    padding-left: auto; }
    [dir="rtl"] .app-sidebar .navigation li.has-sub > a:after,
    [dir="rtl"] .off-canvas-sidebar .navigation li.has-sub > a:after {
      left: 15px;
      right: auto; }
    [dir="rtl"] .app-sidebar .navigation li:first-child > a,
    [dir="rtl"] .off-canvas-sidebar .navigation li:first-child > a {
      margin: 0; }
    [dir="rtl"] .app-sidebar .navigation li > a,
    [dir="rtl"] .off-canvas-sidebar .navigation li > a {
      padding: 0;
      /*  margin: 5.2px 0; */
      text-align: right; }
      @media (max-width: 768px) {
        [dir="rtl"] .app-sidebar .navigation li > a,
        [dir="rtl"] .off-canvas-sidebar .navigation li > a {
          padding: 0 10px 0 2px; } }
      [dir="rtl"] .app-sidebar .navigation li > a:before,
      [dir="rtl"] .off-canvas-sidebar .navigation li > a:before {
        right: -100%;
        left: auto;
        border-left: solid 5px #dc3545;
        border-right: 0px; }
    [dir="rtl"] .app-sidebar .navigation li.active > a:before,
    [dir="rtl"] .off-canvas-sidebar .navigation li.active > a:before {
      right: 0;
      left: auto; }
    [dir="rtl"] .app-sidebar .navigation li ul li a,
    [dir="rtl"] .off-canvas-sidebar .navigation li ul li a {
      padding: 8px 60px 8px 18px; }
    [dir="rtl"] .app-sidebar .navigation li ul li ul li a,
    [dir="rtl"] .off-canvas-sidebar .navigation li ul li ul li a {
      padding: 8px 70px 8px 18px; }
    [dir="rtl"] .app-sidebar .navigation .badge.badge-pill,
    [dir="rtl"] .off-canvas-sidebar .navigation .badge.badge-pill {
      left: 28px;
      right: auto; }
    [dir="rtl"] .app-sidebar .navigation i,
    [dir="rtl"] .off-canvas-sidebar .navigation i {
      float: right;
      margin-left: 10px;
      margin-right: auto; }
  [dir="rtl"] .app-sidebar .sidebar-background,
  [dir="rtl"] .off-canvas-sidebar .sidebar-background {
    right: 0;
    left: auto; }
  [dir="rtl"] .app-sidebar .logo p,
  [dir="rtl"] .off-canvas-sidebar .logo p {
    float: right; }
  [dir="rtl"] .app-sidebar .logo .logo-text,
  [dir="rtl"] .off-canvas-sidebar .logo .logo-text {
    text-align: right; }
  [dir="rtl"] .app-sidebar .logo-tim,
  [dir="rtl"] .off-canvas-sidebar .logo-tim {
    float: right; }
  [dir="rtl"] .app-sidebar:after, [dir="rtl"] .app-sidebar:before,
  [dir="rtl"] .off-canvas-sidebar:after,
  [dir="rtl"] .off-canvas-sidebar:before {
    right: 0;
    left: auto; }

[dir="rtl"] .sidebar-header .nav-toggle {
  left: 14px;
  right: auto; }

[dir="rtl"] .sidebar-header .nav-close {
  left: 14px;
  right: auto; }

[dir="rtl"] .main-panel {
  float: left; }

@media (min-width: 992px) {
  .main-panel .main-content {
    padding-left: 250px; } }

@media (max-width: 992px) {
  .container,
  .container-sm,
  .container-md {
    max-width: none !important; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .app-sidebar {
    transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: none; }
  .main-layout .main-wrapper .main-content {
    margin-left: 0; }
  .navbar-toggle {
    display: block; }
  .navbar-toggle .icon-bar {
    display: block;
    position: relative;
    background: #fff;
    width: 24px;
    height: 2px;
    border-radius: 1px;
    margin: 0 auto; }
  .navbar-header .navbar-toggle {
    margin: 14px 15px 14px 0;
    width: 30px;
    height: 30px; }
  .navbar-header .navbar-collapse-toggle {
    margin: 20px 0px 10px 15px; }
  .navbar-form {
    margin-top: 0.75rem !important; }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent; }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  @keyframes fadeIn {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } } }

@media (max-width: 776px) {
  .navbar-form {
    width: 250px; }
  .navbar {
    padding: 0; } }

@media (min-width: 320px) {
  .navbar-header {
    width: 100%; }
  .navbar-right {
    float: right !important; }
  .navbar-nav > li {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px; } }

@media (max-width: 640px) {
  .navbar-form {
    width: 200px; }
  .notification-dropdown {
    width: 300px; }
    .notification-dropdown .noti-wrapper {
      white-space: initial; } }

@media (max-width: 320px) {
  .navbar-right {
    display: none; }
  .notification-dropdown {
    width: 250px; } }

@media (min-width: 992px) {
  [dir="rtl"] .main-panel .main-content {
    padding-right: 250px;
    padding-left: 0 !important; }
  [dir="rtl"] .navbar .navbar-header {
    padding-right: 240px;
    padding-left: 0 !important; }
  [dir="rtl"] .navbar .navbar-container {
    position: absolute;
    left: 20px; }
  [dir="rtl"] footer {
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-sm .main-panel .main-content {
    padding-right: 220px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-sm .navbar .navbar-header {
    padding-right: 210px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-sm .navbar .navbar-container {
    padding-right: 220px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-sm footer {
    padding-right: 220px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-lg .main-panel .main-content {
    padding-right: 280px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-lg .navbar .navbar-header {
    padding-right: 270px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-lg .navbar .navbar-container {
    padding-right: 280px;
    padding-left: 0 !important; }
  [dir="rtl"] .sidebar-lg footer {
    padding-right: 280px;
    padding-left: 0 !important; } }

@media (max-width: 992px) {
  [dir="rtl"] .navbar-header .navbar-toggle {
    margin: 10px 0 10px 15px; }
  [dir="rtl"] .navbar-header .navbar-collapse-toggle {
    margin: 20px 15px 10px 0px; } }

@media (min-width: 320px) {
  [dir="rtl"] .navbar-header {
    float: right; }
  [dir="rtl"] .navbar-right {
    float: left !important; }
  [dir="rtl"] .navbar-nav > li {
    float: right; } }

.row-eq-height [class^="col"] {
  display: flex;
  flex-direction: column; }

.row-eq-height [class^="col"] > div {
  flex-grow: 1; }

.statusCircle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 auto; }

.btn {
  border-radius: 15px;
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 15px;
  max-height: 30px; }

.btn-primary {
  color: #007bff;
  color: var(--primary);
  border: 2px solid #007bff;
  border: 2px solid var(--primary); }
  .btn-primary:hover {
    background: #fff;
    background: #007bff;
    background: var(--primary);
    border-color: #007bff;
    border-color: var(--primary); }
  .btn-primary:disabled {
    background: #fff;
    background: #007bff;
    background: var(--primary);
    border-color: #007bff;
    border-color: var(--primary); }

.page-item.active .page-link {
  background-color: #007bff;
  background-color: var(--primary);
  border-color: #007bff;
  border-color: var(--primary); }

.page-item .page-link {
  color: #007bff;
  color: var(--primary); }

.btn-secondary {
  color: #868e96;
  border: 2px solid #868e96; }

.btn-danger {
  color: #ff586b;
  border: 2px solid #ff586b; }

.btn-warning {
  color: #ff8d60;
  border: 2px solid #ff8d60; }

.btn-success {
  color: #0cc27e;
  border: 2px solid #0cc27e; }

.w-100 {
  width: 100% !important; }

.h-100 {
  height: 100% !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

a {
  cursor: pointer; }

.fonticon-wrap {
  width: 2%;
  float: left;
  margin: 0.25rem; }
  .fonticon-wrap i.fa {
    font-size: 1.35rem !important; }

.round {
  border-radius: 1.5rem; }

.square {
  border-radius: 0; }

img.img-border {
  border: 5px solid #fff; }

ul.no-list-style {
  list-style: none; }

[class*="gradient-"] {
  background-repeat: repeat !important; }

.shadow-z-1 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.shadow-z-1-hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.shadow-z-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.shadow-z-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

.shadow-z-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }

.shadow-z-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); }

.big-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36); }

.shadow-big {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.shadow-big-navbar {
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.sidebar-shadow {
  box-shadow: 2px 10px 30px 0px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.card-shadow {
  box-shadow: 0 6px 0px 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06); }

[dir="rtl"] .fonticon-wrap {
  float: right; }

@media (max-width: 550px) {
  .rbc-btn-group {
    white-space: pre-wrap !important; } }

.table thead th {
  font-weight: 400; }

.table {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #004481; }
  .table tbody tr {
    border-bottom: 1px solid rgba(0, 68, 129, 0.3); }
  .table td {
    border-top: none; }
    .table td.color_blue {
      color: #00ADEB; }

.pre-icon {
  color: #007bff;
  color: var(--primary);
  position: absolute;
  bottom: 5px;
  font-size: 24px;
  left: -38px; }

.pre-link {
  padding-right: 5px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #004481; }

.row-eq-height [class^="col"] {
  display: flex;
  flex-direction: column; }

.row-eq-height [class^="col"] > div {
  flex-grow: 1; }

.statusCircle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 auto; }

.btn {
  border-radius: 15px;
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 15px;
  max-height: 30px; }

.btn-primary {
  color: #007bff;
  color: var(--primary);
  border: 2px solid #007bff;
  border: 2px solid var(--primary); }
  .btn-primary:hover {
    background: #fff;
    background: #007bff;
    background: var(--primary);
    border-color: #007bff;
    border-color: var(--primary); }
  .btn-primary:disabled {
    background: #fff;
    background: #007bff;
    background: var(--primary);
    border-color: #007bff;
    border-color: var(--primary); }

.page-item.active .page-link {
  background-color: #007bff;
  background-color: var(--primary);
  border-color: #007bff;
  border-color: var(--primary); }

.page-item .page-link {
  color: #007bff;
  color: var(--primary); }

.btn-secondary {
  color: #868e96;
  border: 2px solid #868e96; }

.btn-danger {
  color: #ff586b;
  border: 2px solid #ff586b; }

.btn-warning {
  color: #ff8d60;
  border: 2px solid #ff8d60; }

.btn-success {
  color: #0cc27e;
  border: 2px solid #0cc27e; }

.w-100 {
  width: 100% !important; }

.h-100 {
  height: 100% !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

a {
  cursor: pointer; }

.fonticon-wrap {
  width: 2%;
  float: left;
  margin: 0.25rem; }
  .fonticon-wrap i.fa {
    font-size: 1.35rem !important; }

.round {
  border-radius: 1.5rem; }

.square {
  border-radius: 0; }

img.img-border {
  border: 5px solid #fff; }

ul.no-list-style {
  list-style: none; }

[class*="gradient-"] {
  background-repeat: repeat !important; }

.shadow-z-1 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.shadow-z-1-hover {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.shadow-z-2 {
  box-shadow: 0 10px 18px 0 rgba(62, 57, 107, 0.2); }

.shadow-z-3 {
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

.shadow-z-4 {
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21); }

.shadow-z-5 {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22); }

.big-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.18), 0 8px 16px rgba(0, 0, 0, 0.36); }

.shadow-big {
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.shadow-big-navbar {
  box-shadow: 0 10px 20px -12px rgba(0, 0, 0, 0.42), 0 3px 20px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.sidebar-shadow {
  box-shadow: 2px 10px 30px 0px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2); }

.card-shadow {
  box-shadow: 0 6px 0px 0 rgba(0, 0, 0, 0.01), 0 15px 32px 0 rgba(0, 0, 0, 0.06); }

[dir="rtl"] .fonticon-wrap {
  float: right; }

@media (max-width: 550px) {
  .rbc-btn-group {
    white-space: pre-wrap !important; } }

.table thead th {
  font-weight: 400; }

.table {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #004481; }
  .table tbody tr {
    border-bottom: 1px solid rgba(0, 68, 129, 0.3); }
  .table td {
    border-top: none; }
    .table td.color_blue {
      color: #00ADEB; }

.pre-icon {
  color: #007bff;
  color: var(--primary);
  position: absolute;
  bottom: 5px;
  font-size: 24px;
  left: -38px; }

.pre-link {
  padding-right: 5px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #004481; }

@media (max-width: 768px) {
  .regular-button {
    width: 50% !important;
    margin: 5px auto;
    min-width: 100px; } }

.customizer {
  width: 400px;
  right: -400px;
  padding: 0;
  background-color: #FFF;
  z-index: 1051;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  transition: right 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); }
  .customizer.open {
    right: 0; }
  .customizer .customizer-content {
    position: relative;
    height: 100%; }
  .customizer a.customizer-toggle {
    background: #FFF;
    color: #007bff;
    display: block;
    box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.1); }
  .customizer a.customizer-close {
    color: #000; }
  .customizer .customizer-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 7px;
    width: auto;
    z-index: 10; }
  .customizer #rtl-icon {
    position: absolute;
    right: -1px;
    top: 35%;
    width: 54px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    margin-top: 50px; }
  .customizer .customizer-toggle {
    position: absolute;
    top: 35%;
    width: 54px;
    height: 50px;
    left: -54px;
    text-align: center;
    line-height: 50px;
    cursor: pointer; }
  .customizer .color-options a {
    white-space: pre; }
  .customizer .cz-bg-color {
    margin: 0 auto; }
    .customizer .cz-bg-color span:hover {
      cursor: pointer; }
    .customizer .cz-bg-color span.white {
      color: #ddd !important; }
    .customizer .cz-bg-color .selected {
      border: 3px solid #314fe5; }
  .customizer .cz-bg-image:hover {
    cursor: pointer; }
  .customizer .cz-bg-image img.rounded {
    border-radius: 1rem !important;
    border: 2px solid #e6e6e6;
    margin-right: 8px; }
    .customizer .cz-bg-image img.rounded.selected {
      border: 2px solid #FF586B; }

.color-palette .color-group li {
  list-style-type: none; }
  .color-palette .color-group li.main-color {
    border-bottom: 4px solid #fafafa; }
  .color-palette .color-group li.color {
    padding: 10px 15px 11px;
    margin: 0; }
    .color-palette .color-group li.color.main-color .name {
      display: block;
      margin-bottom: 53px;
      font-size: 2rem; }
    .color-palette .color-group li.color .hex {
      float: right;
      text-transform: uppercase;
      display: block; }

.color-palette .color-group:last-child li.main-color {
  border-bottom: 4px solid #fafafa; }

.color-palette .color-group:last-child li.color {
  padding: 10px 15px 11px;
  margin: 0; }
  .color-palette .color-group:last-child li.color.main-color .name {
    display: block;
    margin-bottom: 53px;
    font-size: 2rem; }
  .color-palette .color-group:last-child li.color .hex {
    float: right;
    text-transform: uppercase;
    display: block; }

.color-palette .light-strong span {
  color: #fff; }

.color-palette .dark span {
  color: rgba(0, 0, 0, 0.87); }

.color-palette span {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 500; }
  .color-palette span.light-strong {
    color: #fff; }
  .color-palette span.dark {
    color: rgba(0, 0, 0, 0.87); }

#kbModal-body {
  height: 500px;
  overflow-y: scroll; }

.header-logo-wrap {
  text-align: center;
  margin-bottom: 25px;
  padding-top: 14px; }
  .header-logo-wrap img {
    width: 104px;
    margin-bottom: 7px;
    /*  .card-outer & {
      width: 70px;
    } */ }
  .header-logo-wrap > b {
    color: #000;
    letter-spacing: 0.2px;
    font-size: 1.2rem;
    font-family: 'Assistant', sans-serif; }

.auth-wrapper {
  /* padding-bottom: 100px; */
  z-index: 2;
  position: relative; }
  .auth-wrapper .auth-box.card {
    margin-top: 190px; }
  .auth-wrapper .auth-logo {
    text-align: center;
    padding: 50px 40px 20px; }
    .auth-wrapper .auth-logo img {
      width: 50%; }
  .auth-wrapper .login-lang {
    display: flex;
    justify-content: flex-end; }
    .auth-wrapper .login-lang .dropdown {
      list-style: none; }
  .auth-wrapper .auth-header {
    font-weight: 400;
    font-size: 1.8rem;
    position: relative;
    z-index: 1;
    text-align: center;
    font-family: 'Assistant', sans-serif;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #000; }
  .auth-wrapper .auth-sub-header {
    color: #7f7f7f;
    padding-bottom: 1.8rem;
    font-size: 1.2rem;
    text-align: center; }
  .auth-wrapper a {
    font-weight: 600;
    color: #499FFF; }
  .auth-wrapper .auth-form-footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .auth-wrapper .auth-buttons .row:first-child {
    margin-bottom: 22px; }
    .auth-wrapper .auth-buttons .row:first-child > div {
      padding-right: 30px; }
  .auth-wrapper .auth-buttons button {
    width: 100%; }
  .auth-wrapper .my-facebook-button-class {
    background: #4561F7;
    border: 0;
    color: #ffffff;
    outline: none;
    cursor: pointer; }
    .auth-wrapper .my-facebook-button-class i {
      margin-right: 9px; }

.card-outer {
  background: #fff;
  border-radius: 18px;
  max-width: 788px;
  margin: 0 auto;
  padding-bottom: 19px;
  padding-top: 23px; }
  .is-iframe .card-outer {
    border-radius: 0;
    padding-top: 0; }

#root:not(.is-iframe) {
  overflow-x: hidden;
  height: 100vh;
  background-position: top center;
  background-image: url("/aplus_path.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0000004f; }

#root.is-iframe .card {
  box-shadow: none !important; }

#root.is-iframe .auth-box.card_login.card {
  margin-top: 0; }

.card h1.card-title {
  font-weight: 300;
  font-family: "Assistant", sans-serif;
  font-size: 27px; }

.main-layout .list-group .list-group-item h5 {
  font-weight: 700; }

.card-wrapper .card .btn {
  padding: 0.9rem;
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: -0.02em;
  width: 100% !important;
  border-radius: 30px;
  max-height: unset; }
  @media (max-width: 768px) {
    .card-wrapper .card .btn {
      width: 90% !important; } }

.card_main.card .btn {
  float: right; }

.form-label {
  font-size: 1.2rem;
  padding-right: 0.5vw;
  letter-spacing: -0.7px; }

.form-footer > div:first-child button {
  float: right; }

.form-footer > div:last-child button {
  float: left; }

@media (max-width: 768px) {
  .form-footer > div button.btn {
    float: none; } }

.sweet-loading {
  margin-top: 0 !important; }

.charges-history-title {
  font-weight: 700;
  color: #004481;
  font-size: 17px;
  font-family: "Assistant", sans-serif; }

span.no-invoices {
  font-size: 17px;
  margin-top: 50px; }

.app-sidebar {
  border-left: 0.5px solid #004481; }

@media (max-width: 768px) {
  .card-body h4 {
    display: none !important; }
  .position-relative.form-group input {
    width: 92%; }
  .col-12.col-md-12.col-xl-11 {
    padding-bottom: 20px;
    flex: 0 0 96%;
    max-width: 100%; }
  .plan-img {
    max-width: 60px; }
  .billing-desc {
    display: none; } }

.container {
  padding-right: 0;
  padding-left: 0; }

ul.navbar-nav {
  position: absolute;
  left: 0;
  display: flex;
  left: 30px;
  flex-direction: row;
  top: 23px; }
  @media (max-width: 768px) {
    ul.navbar-nav {
      left: 10px; } }
  .is-iframe ul.navbar-nav {
    top: 0; }
  ul.navbar-nav li:first-child {
    padding-left: 5px; }
    ul.navbar-nav li:first-child:after {
      content: "|";
      position: absolute;
      top: 0;
      margin-right: 7px; }
  ul.navbar-nav li .nav-link {
    padding: 0; }
  ul.navbar-nav a {
    color: #004481;
    font-size: 17px;
    font-family: Assistant, sans-serif; }

@media (max-width: 768px) {
  ul.navbar-nav a,
  #main-menu-navigation a {
    font-size: 14px; }
  #main-menu-navigation li.nav-item:last-child a {
    width: 31px;
    line-height: 1.5; } }

.navbar-nav .dropdown-menu {
  position: absolute; }

.main-wrap {
  max-width: 788px;
  margin-top: 70px; }
  .is-iframe .main-wrap {
    margin-top: 0; }

.auth-box.card_login.card {
  margin-top: 70px; }

@media (max-width: 600px) {
  .plans-wrap {
    flex-direction: column;
    width: 80%;
    margin: 0 auto; }
    .plans-wrap .plan-item {
      margin-bottom: 20px; } }

body {
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 7px;
  margin-right: 20px; }

::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 10px;
  margin-right: 20px; }

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  margin-right: 20px; }

::-webkit-scrollbar-thumb {
  background: #cfcece;
  border-radius: 10px;
  margin-right: 20px; }

.auth-box .card_login .card {
  padding-bottom: 31px; }

.rrt-message:after {
  content: 'X';
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 10px;
  font-weight: bold; }

@use "sass:math";
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }
  .animated.bounceIn {
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.bounceOut {
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .animated.bounceIn {
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn; }
  .animated.bounceOut {
    -webkit-animation-name: bounceOut;
            animation-name: bounceOut; }
  .animated.fadeIn {
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-duration: .7s;
            animation-duration: .7s; }
  .animated.fadeOut {
    -webkit-animation-name: fadeOut;
            animation-name: fadeOut;
    -webkit-animation-duration: .3s;
            animation-duration: .3s; }
  .animated.bounceInDown {
    -webkit-animation-name: bounceInDown;
            animation-name: bounceInDown; }
  .animated.bounceOutUp {
    -webkit-animation-name: bounceOutUp;
            animation-name: bounceOutUp; }

@-webkit-keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: perspective(1px) scale3d(0.3, 0.3, 0.3);
            transform: perspective(1px) scale3d(0.3, 0.3, 0.3); }
  20% {
    -webkit-transform: perspective(1px) scale3d(1.1, 1.1, 1.1);
            transform: perspective(1px) scale3d(1.1, 1.1, 1.1); }
  40% {
    -webkit-transform: perspective(1px) scale3d(0.9, 0.9, 0.9);
            transform: perspective(1px) scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1.03, 1.03, 1.03);
            transform: perspective(1px) scale3d(1.03, 1.03, 1.03); }
  80% {
    -webkit-transform: perspective(1px) scale3d(0.97, 0.97, 0.97);
            transform: perspective(1px) scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    -webkit-transform: perspective(1px) scale3d(1, 1, 1);
            transform: perspective(1px) scale3d(1, 1, 1); } }

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
            transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
            transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
            transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
            transform: translate3d(0, 25px, 0); }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
            transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
            transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #60bb71;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #db6a64;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -math(175px); }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -math(160px); } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: .94;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #60bb71; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #f7a336; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #58abc3; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #db6a64; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 80px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 80px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 22px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
    color: #000; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
    .redux-toastr .toastr .close-toastr span {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: white; }
  .redux-toastr .toastr.rrt-info {
    background-color: #58abc3; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #378298; }
  .redux-toastr .toastr.rrt-success {
    background-color: #60bb71; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #3e914d; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #f7a336; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #d87e09; }
  .redux-toastr .toastr.rrt-error {
    background-color: #db6a64; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #c5352e; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

.pulsar-dialog .modal-content {
  border: 0;
  border-radius: 5px; }
  .pulsar-dialog .modal-content .modal-header {
    padding: 35px 20px 10px;
    border-bottom: 0; }
    .pulsar-dialog .modal-content .modal-header.success {
      color: #0cc27e; }
    .pulsar-dialog .modal-content .modal-header.info {
      color: var(--primary); }
    .pulsar-dialog .modal-content .modal-header.warning {
      color: #ff8d60; }
    .pulsar-dialog .modal-content .modal-header.danger, .pulsar-dialog .modal-content .modal-header.error {
      color: #ff586b; }
  .pulsar-dialog .modal-content .modal-body h3 {
    text-align: center;
    margin-bottom: 20px; }

button {
  position: relative; }
  button .button-content {
    opacity: 1; }
    button .button-content.is-loading {
      opacity: 0; }
  button .spinner {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: text-bottom;
    border: 0.15em solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner .75s linear infinite;
            animation: spinner .75s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.app-sidebar[data-background-color="white"] {
  box-shadow: 6px 0 0 0 rgba(0, 0, 0, 0.01), 15px 0 32px 0 rgba(0, 0, 0, 0.06); }
  .app-sidebar[data-background-color="white"] .navigation .nav-item a:before {
    border-right: solid 5px var(--primary); }
  .app-sidebar[data-background-color="white"] .navigation .nav-item a i {
    color: var(--primary); }

.app-sidebar .navigation li a {
  line-height: 30px;
  font-size: 17px; }

.menu-title {
  line-height: 2rem;
  margin: 5px 0;
  display: block;
  width: 100%;
  overflow: hidden;
  padding: 10px 30px 10px 14px;
  position: relative; }
  .menu-title .menu-icon {
    top: 9px;
    left: 22px;
    position: absolute; }
    .menu-title .menu-icon svg {
      stroke-width: 1.6;
      color: #f8f9fa; }
  .menu-title .menu-item-text {
    display: block;
    margin-left: 40px;
    text-decoration: none;
    color: #f8f9fa;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    opacity: 1;
    height: auto;
    transition: width 0.3s cubic-bezier(0, 0, 0.2, 1); }
    .menu-title .menu-item-text--disabled {
      color: rgba(0, 0, 0, 0.5); }
  .menu-title .item-arrow {
    color: #f8f9fa;
    position: absolute;
    display: inline-block;
    top: 9px;
    right: 17px; }
    .menu-title .item-arrow svg {
      transition: all ease-in-out 0.5s;
      stroke-width: 1.6; }
    .menu-title .item-arrow.right-open svg {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    .menu-title .item-arrow.right-close svg {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .icon .icon-row {
    width: 45px;
    justify-content: space-between;
    margin-bottom: 5px; }
  .icon .icon-square {
    width: 20px;
    height: 20px;
    border: 3px solid var(--primary); }
  .icon .icon-text {
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7); }

.main-layout main p.tooltip-title, p.tooltip-title {
  margin-bottom: 0px;
  font-size: 13px;
  color: #3e546d;
  line-height: 1.5; }

p.tooltip-description {
  white-space: normal;
  margin-bottom: 5px;
  margin-top: 3px;
  color: #868e96 !important;
  font-size: 12px; }

.element-header-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  align-items: flex-end;
  justify-content: space-between; }
  .element-header-wrapper > *:first-child {
    flex: 0 1 auto; }

.element-header {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 0;
  z-index: 1; }
  .element-header:after {
    content: "";
    background-color: var(--primary);
    width: 25px;
    height: 4px;
    border-radius: 0px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0; }

.ReactTable {
  transition: all 0.5s ease; }
  .ReactTable .rt-thead {
    overflow: auto !important; }
    .ReactTable .rt-thead.-header {
      box-shadow: none; }
    .ReactTable .rt-thead .rt-th {
      box-shadow: none;
      padding: 15px;
      font-size: 12px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.7);
      text-transform: uppercase;
      position: inherit; }
  .ReactTable .rt-tbody .rt-tr-group {
    border: 0;
    border-top: 1px solid #d8d8d8; }
  .ReactTable .rt-tbody .rt-td {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 15px; }
  .ReactTable .-pagination {
    padding-top: 20px;
    box-shadow: none;
    border-top: 2px solid #f0efeb; }
  .ReactTable .pagination-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.17);
    padding: 10px 0; }
    .ReactTable .pagination-bottom .pagination {
      margin: 0; }
  .ReactTable .-odd {
    background-color: #f4f4f4; }

.text-center {
  justify-content: center; }

.text-left {
  justify-content: flex-start; }

.text-right {
  justify-content: flex-end; }

.invalid {
  border: 2px solid #ff586b;
  border-radius: 8px; }

