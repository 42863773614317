button {
  position: relative;

  .button-content {
    opacity: 1;

    &.is-loading {
      opacity: 0;
    }
  }

  .spinner {
    display: inline-block;
    width: 1.3rem;
    height: 1.3rem;
    vertical-align: text-bottom;
    border: 0.15em solid #fff;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner .75s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
}
