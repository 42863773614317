@import "../../color";

.pulsar-dialog {
  .modal-content {
    border: 0;
    border-radius: 5px;

    .modal-header {
      padding: 35px 20px 10px;
      border-bottom: 0;

      &.success {
        color: $success;
      }

      &.info {
        color: $info;
      }

      &.warning {
        color: $warning;
      }

      &.danger, &.error {
        color: $danger;
      }
    }

    .modal-body {
      h3 {
        text-align: center;
        margin-bottom: 20px;
      }
    }
  }
}
