.btn {
  outline: none;
  &.btn-xl {
    padding: 0.7rem;
    height: auto;
    max-height: none;
    font-size: 1.3em;
    border-radius: 2rem;
  }
  &.btn-md {
    padding: 0.356rem;
    font-size: 1.3rem;
  }
  &.btn-orange {
    background: #ED7218;
    border: 0;
    color: #ffffff;
  }
  &.btn-blue {
    background: #4561F7;
    border: 0;
    color: #ffffff;
  }
  &.btn-white {
    background: #ffffff;
    border: 1px solid #bdbdbd;
    color: #000000;
  }
  &.btn-full {
    display: block;
    width: 100%;
  }
  &.btn-cancel {
    background-color: #4661F7;
    border: none;
  }
  &.button-link {
    border: none!important;
    color: #00ADEB!important;
    background: none!important;
    font-weight: normal!important;
    padding: 0px!important;
    + .vertical-spearator {
      border-left: 1px solid #00ADEB!important;
      display: inline-block;
      vertical-align: middle;
      height: 12px;
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.btn-toolbar {
  > .btn {
    margin-right: 15px;
  }
}

.btn-group-vertical {
  .btn {
    &[class*="btn-"] {
      margin-bottom: 0;
    }
  }
}

.input-group {
  .btn {
    &[class*="btn-"] {
      margin-bottom: 0;
    }
  }

  margin-bottom: 1rem;
}

// Custom round buttons
.btn-round {
  border-radius: $border-radius-extreme;
}

.btn-square {
  border-radius: 0;
}

.btn:active,
.btn.active {
  background-image: initial;
}

button:hover {
  cursor: pointer;
}

.btn-group > .btn.active {
  z-index: 0;
}

.btn-floating {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.btn-secondary {
  border-color: transparent;
}
