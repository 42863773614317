.card-outer {
  background: #fff;
  border-radius: 18px;
  max-width: 788px;
  margin: 0 auto;
  padding-bottom: 19px;
  padding-top: 23px;
  .is-iframe &{
    border-radius: 0;
    padding-top: 0;

  }
}
#root:not(.is-iframe) {
  overflow-x: hidden;
  height: 100vh;
  background-position: top center;
  background-image: url("/aplus_path.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #0000004f;
}
#root.is-iframe {
  .card {
    box-shadow: none !important;
  }
  .auth-box.card_login.card {
    margin-top: 0;
  }
}
.card h1.card-title {
  font-weight: 300;
  font-family: "Assistant", sans-serif;
  font-size: 27px;
}
.main-layout .list-group .list-group-item h5 {
  font-weight: 700;
}
.card-wrapper .card .btn {
  padding: 0.9rem;
  font-size: 17px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  letter-spacing: -0.02em;
  width: 100% !important;
  border-radius: 30px;
  max-height: unset;
  @media (max-width: 768px) {
    width: 90% !important;
  }
}
.card_main.card .btn {
  float: right;
}
.form-label {
  font-size: 1.2rem;
  padding-right: 0.5vw;
  letter-spacing: -0.7px;
}
.form-footer > div {
  &:first-child {
    button {
      float: right;
    }
  }
  &:last-child {
    button {
      float: left;
    }
  }
  @media (max-width: 768px) {
    button.btn {
      float: none;
    }
  }
}
.sweet-loading {
  margin-top: 0 !important;
}
.charges-history-title {
  font-weight: 700;
  color: #004481;
  font-size: 17px;
  font-family: "Assistant", sans-serif;
}
span.no-invoices {
  font-size: 17px;
  margin-top: 50px;
}
.app-sidebar {
  border-left: 0.5px solid #004481;
}
@media (max-width: 768px) {
  .card-body h4 {
    display: none !important;
  }
  .position-relative.form-group input {
    width: 92%;
  }
  .col-12.col-md-12.col-xl-11 {
    padding-bottom: 20px;
    flex: 0 0 96%;
    max-width: 100%;
  }
  .plan-img {
    max-width: 60px;
  }
  .billing-desc {
    display: none;
  }
}

.container {
  padding-right: 0;
  padding-left: 0;
}
ul.navbar-nav {
  position: absolute;
  left: 0;
  display: flex;
  left: 30px;
  flex-direction: row;
  top: 23px;
  @media(max-width: 768px) {
    left: 10px;
  }
  .is-iframe &{
    top: 0;
  }
  li {
    &:first-child() {
      padding-left: 5px;
      &:after {
        content: "|";
        position: absolute;
        top: 0;
        margin-right: 7px;
      }
    }
    .nav-link {
      padding: 0;
    }
  }
  a {
    color: #004481;
    font-size: 17px;
    font-family: Assistant, sans-serif;
  }
}
@media (max-width: 768px) {
  ul.navbar-nav a,
  #main-menu-navigation a {
    font-size: 14px;
  }
  #main-menu-navigation li.nav-item:last-child a {
    width: 31px;
    line-height: 1.5;
  }
}
.navbar-nav .dropdown-menu {
  position: absolute;
}
.main-wrap {
  max-width: 788px;
  margin-top: 70px;
  .is-iframe &{
    margin-top:0;

  }
}
.auth-box.card_login.card {
  margin-top: 70px;

}
@media (max-width: 600px) {
  .plans-wrap {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    .plan-item {
      margin-bottom: 20px;
    }
  }
}
body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 7px;
  margin-right: 20px;
}

::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 10px;
  margin-right: 20px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  margin-right: 20px;
}

::-webkit-scrollbar-thumb {
  background: #cfcece;
  border-radius: 10px;
  margin-right: 20px;
}
.auth-box .card_login .card {
  padding-bottom: 31px;
}
.rrt-message:after {
  content: 'X';
  cursor: pointer;
  position: absolute;
  left: 20px;
  top: 10px;
  font-weight: bold;
}