@import "../../color";

.main-layout main p.tooltip-title, p.tooltip-title {
  margin-bottom: 0px;
  font-size: 13px;
  color: $tooltip-blue;
  line-height: 1.5;
}


p.tooltip-description {
  white-space: normal;
  margin-bottom: 5px;
  margin-top: 3px;
  color: $tooltip-description !important;
  font-size: 12px;
}

