@import "../../color";

$border-color: $background-lighter-grey;

.ReactTable {
  transition: all 0.5s ease;

  .rt-thead {
    overflow: auto !important;

    &.-header {
      box-shadow: none;
    }

    .rt-th {
      box-shadow: none;
      padding: 15px;
      font-size: 12px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.7);
      text-transform: uppercase;
      position: inherit;
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border: 0;
      border-top: 1px solid #d8d8d8;
    }

    .rt-td {
      display: flex;
      align-items: center;
      font-size: 14px;
      padding: 15px;
    }
  }


  .-pagination {
    padding-top: 20px;
    box-shadow: none;
    border-top: 2px solid $border-color;
  }

  .pagination-bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.17);
    padding: 10px 0;

    .pagination {
      margin: 0;
    }
  }

  .-odd {
    background-color: #f4f4f4;
  }
}

.text-center {
  justify-content: center;
}

.text-left {
  justify-content: flex-start;
}

.text-right {
  justify-content: flex-end;
}
