@import '../color';

// Equal height cards/col in row
.row-eq-height {
  [class^="col"] {
    display: flex;
    flex-direction: column;
  }

  [class^="col"] > div {
    flex-grow: 1;
  }
}

.statusCircle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin: 0 auto;
}

.btn {
  border-radius: 15px;
  background-color: transparent;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 15px;
  max-height: 30px;
}

.btn-primary {
  color: $primary;
  border: 2px solid $primary;

  &:hover {
    background: $white;
    background: $primary;
    border-color: $primary;
  }

  &:disabled {
    background: $white;
    background: $primary;
    border-color: $primary;
  }
}

.page-item {
  &.active {
    .page-link {
      background-color: $primary;
      border-color: $primary;
    }
  }

  .page-link {
    color: $primary;
  }
}

.btn-secondary {
  color: $secondary;
  border: 2px solid $secondary;
}

.btn-danger {
  color: $danger;
  border: 2px solid $danger;
}

.btn-warning {
  color: $warning;
  border: 2px solid $warning;
}

.btn-success {
  color: $success;
  border: 2px solid $success;
}

// Width and height
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

// Margin and Padding

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

a {
  cursor: pointer;
}

// Icons
.fonticon-wrap {
  width: 2%;
  float: left;
  margin: 0.25rem;

  i.fa {
    font-size: 1.35rem !important;
  }
}

.round {
  border-radius: 1.5rem;
}

.square {
  border-radius: 0;
}

img {
  &.img-border {
    border: 5px solid #fff;
  }
}

ul {
  &.no-list-style {
    list-style: none;
  }
}

[class*="gradient-"] {
  background-repeat: repeat !important;
}

// Shadow classes
.shadow-z-1 {
  @include shadow-z-1();
}

.shadow-z-1-hover {
  @include shadow-z-1-hover();
}

.shadow-z-2 {
  @include shadow-z-2();
}

.shadow-z-3 {
  @include shadow-z-3();
}

.shadow-z-4 {
  @include shadow-z-4();
}

.shadow-z-5 {
  @include shadow-z-5();
}

.big-shadow {
  @include big-shadow();
}

.shadow-big {
  @include shadow-big();
}

.shadow-big-navbar {
  @include shadow-big-navbar();
}

.sidebar-shadow {
  @include sidebar-shadow();
}

.card-shadow {
  @include card-shadow();
}

[dir="rtl"] {
  // Icons
  .fonticon-wrap {
    float: right;
  }
}

@media (max-width: 550px) {
  .rbc-btn-group {
    white-space: pre-wrap !important;
  }
}

.table thead th {
  font-weight: 400;
}
.table {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #004481;

  tbody {

    tr {
      border-bottom: 1px solid rgba(0, 68, 129, .3);
    }
  }

  td {
    border-top: none;

    &.color_blue {
        color: #00ADEB;
    }
  }
}


.pre-icon {
  color: $primary;
  position: absolute;
  bottom: 5px;
  font-size: 24px;
  left: -38px;
}

.pre-link {
  padding-right: 5px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #004481;
}
