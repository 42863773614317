@import '../../color';

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-row {
    width: 45px;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .icon-square {
    width: 20px;
    height: 20px;
    border: 3px solid $primary;
  }

  .icon-text {
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.7);
  }
}
