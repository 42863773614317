@import "../../color";
@import "../../util";
@import "../../variables";

.main-layout {
  a {
    display: block;
  }
  padding: 0;
  .main-wrapper{
    padding-bottom: 70px;
    position: relative;
    height: calc(100vh - 100px);
    z-index: 99;
    max-width: 788px !important;
    .main-content {
      position: relative;
      max-height: 520px;
      width: calc(100% - 140px);
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 4;
      @media (max-width: 768px) {
        width: calc(100% - 100px);
      }
    }
  }
  .card{
    box-shadow: none;
    h5.card-title{
      text-transform: none;
    }
    h2.card-title{
      font-size: 14px;
    }
  }
  .list-group{
    padding-right: 0;
    .list-group-item{
      border: 0;
      padding: 0;
      background-color: transparent;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        margin-bottom: 10px;

      }
      h5 {
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 13px;
        color: #9195D1;
        margin-bottom: 5px;
        letter-spacing: -0.02em;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  main {
    color: $heading-color;
    // min-height: calc(100vh - 420px);

    .content {
      font-family: $font-family-content;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #a8a8a8;
      // letter-spacing: -0.02em;
    }
  }

  footer {
    font-family: $font-family-content;

    p {
      color: $heading-color;
      font-size: 1rem;
      letter-spacing: 0.3px;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      a {
        color: $primary;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}

.menu-collapsed {
  margin-left: 60px;
  padding: 0 16px;

  main {
    margin-top: 15px;
    color: $heading-color;

    .content {
      font-family: $font-family-content;
    }

    p {
      color: $content-color;
    }
  }

  footer {
    font-family: $font-family-content;

    p {
      color: $heading-color;
      font-size: 1rem;
      letter-spacing: 0.3px;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      a {
        color: $primary;
        cursor: pointer;
        text-decoration: none;
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 768px) {
  .menu-collapsed {
    margin-left: 0px;
  }
}

@media (max-width: 990px) {
  .main-layout {
    margin-left: 0px !important;
  }
}

.animate-shake {
  animation: 0.15s ease-in 2s infinite alternate animate-shake;
  animation-fill-mode: initial;
  top: 1px;
  position: relative;
}

@keyframes animate-shake {
  0% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
}
