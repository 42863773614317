// Theme Colors
// $theme-colors: (
//   primary: #002D63,
//   success: #0CC27E,
//   info: #1CBCD8,
//   warning: #FF8D60,
//   danger: #FF586B,
//   secondary: #868e96,
//   light: #f8f9fa,
//   dark: #343a40
// );
$font-size-base:              0.85rem !default;
$font-size-root: 14px;
// Spacing

$spacer: 1.5rem;

$spacers: (
        0: 0,
        1: ($spacer * .25),
        2: ($spacer * .50),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
) !default;

// Body
$body-bg: #ffffff;

// Fonts
$font-family-sans-serif: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-content: "Rubik", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$font-size-root: .9rem;

//Navbar
$navbar-padding-y: 0.75rem;
$navbar-padding-x: 0;

//Footer
$footer-height: 50px;
