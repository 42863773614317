$gradient-purple: linear-gradient(45deg, #9b3cb7, #ff396f);
$gradient-red: linear-gradient(45deg, #9b3cb7, #ff396f);
$gradient-darkpink: linear-gradient(45deg, #33001b, #ff0084);
$gradient-darkblue: linear-gradient(45deg, #360033, #0b8793);
$gradient-brownish: linear-gradient(45deg, #780206, #061161);
$gradient-pinkish: linear-gradient(45deg, #33001b, #ff0084);
$gradient-buleish: linear-gradient(45deg, #1a2a6c, #b21f1f);
$gradient-primary: #fff;

//safari hack for gradient charts
$ecommerceDashboard-gradient-path: '/dashboard/ecommerceDashboard';
$analyticsDashboard-gradient-path: '/dashboard/analyticsDashboard';
$chartist-gradient-path: '/charts/chartist';