.app-sidebar[data-background-color="white"] {
  box-shadow: 6px 0 0 0 rgba(#000, 0.01), 15px 0 32px 0 rgba(#000, 0.06);

  .navigation .nav-item a {
    &:before {
      border-right: solid 5px var(--primary);
    }

    i {
      color: var(--primary);
    }
  }
}
.app-sidebar {
  .navigation {
    li {
      a {
        line-height: 30px;
    font-size: 17px;
      }
    }
  }
}
