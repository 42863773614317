.auth-wrapper {
  /* padding-bottom: 100px; */
  z-index: 2;
  position: relative;
  .auth-box {
    &.card {
      margin-top: 190px;
    }
  }
 
  .auth-logo {
    text-align: center;
    padding: 50px 40px 20px;

    img {
      width: 50%;
    }
  }

  .login-lang {
    display: flex;
    justify-content: flex-end;

    .dropdown {
      list-style: none;
    }
  }

  .auth-header {
    font-weight: 400;
    font-size: 1.8rem;
    position: relative;
    z-index: 1;
    text-align: center;
    font-family: 'Assistant', sans-serif;
    font-weight: 300;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #000;
  }
  .auth-sub-header {
    color: rgb(127,127,127);
    padding-bottom: 1.8rem;
    font-size: 1.2rem;
    text-align: center;
  }
  a {
    font-weight: 600;
    color: #499FFF;
  }
  .auth-form-footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // a:
  }

  .auth-buttons {

    .row:first-child {
      margin-bottom: 22px;
      & > div{
        padding-right: 30px;
      }
    }

    button {
      width: 100%;
    }
  }
  .my-facebook-button-class{
    background: #4561F7;
    border: 0;
    color: #ffffff;
    outline: none;
    cursor: pointer;
    i {
      margin-right: 9px;
    }
  }
}

// .auth-background {
//   background-position: 0 0;

//   &::before {
//     content: "";
//     position: absolute;
//     z-index: -1;
//     top: 0px;
//     left: 0px;
//     bottom: 0px;
//     right: 0px;
//   }
// }
