//https://www.google.com/design/spec/components/cards.html#cards-content-blocks
.card {
  font-weight: normal;
  border: 0;
 /*  margin: 15px 0; */
  padding: 30px;
  background: #fff;
  border-radius: 18px;
  box-shadow: 0 0 14px 0px #0000000d;
  @extend %std-font;

  &[class*="gradient-"] {
    .card-header,
    .card-footer {
      background-color: initial;
    }
  }

  .card-header {
    padding: 1.3rem;
    border-bottom: none;
    background-color: transparent;
  }

  .card-subtitle {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .card-block {
    padding: 0.5rem 1.5rem 1.5rem;
  }

  .card-bordered {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  &.card-transparent {
    background-color: transparent !important;
  }

  .card {
    box-shadow: none;
  }

  .card-title {
    margin-bottom: 1rem;
    font-size: 23px;
    color: #000;
    text-transform: capitalize;
    letter-spacing: -0.02em;
  }

  .card-img {
    position: relative;
    @media(max-width: 768px) {
      display: none;
    }
    .card-title {
      color: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 100%;
      padding: 24px;
    }

    &.overlap {
      margin-top: -60px;
    }
  }

  .btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: theme-color("primary");
    border-radius: 50%;
    transition: 0.3s;
    cursor: pointer;
    vertical-align: middle;
    font-size: 1rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

    &.btn {
      margin: 0;
    }

    i {
      width: inherit;
      display: inline-block;
      text-align: center;
      color: #fff;
      font-size: 2rem;
      line-height: 40px;
    }

    &.btn-large {
      width: 56px;
      height: 56px;

      &.halfway-fab {
        bottom: -28px;
        padding: 6px;
      }

      i {
        line-height: 56px;
      }
    }

    &.halfway-fab {
      position: absolute;
      right: 24px;
      bottom: -20px;
    }
  }

  .card-reveal {
    padding: 24px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    overflow-y: auto;
    left: 0;
    top: 100%;
    height: 100%;
    z-index: 3;
    transition: height 1s ease-in-out;
    display: none;

    .card-title {
      cursor: pointer;
    }
  }

  &.horizontal {
    .card-body {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .card-img {
      max-width: 50%;

      img {
        border-radius: 2px 0 0 2px;
        max-width: 100%;
        width: auto;
      }
    }
  }

  .card-stacked {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
  }

  // any h* will signal the larger size should render as 24sp
  @include headings() {
    &.card-title {
      text-transform: capitalize;
      font-weight: 400;
    }
  }

  .card-footer {
    // assume footer is the set of actions?
    padding: 1rem 1.5rem;
    background: white;
    overflow: hidden;
  }

  &.card-inverse {
    .card-blockquote .blockquote-footer,
    .card-link,
    .card-subtitle,
    .card-text,
    .card-title,
    .card-footer,
    a,
    a:hover,
    label,
    span,
    .form-group:hover,
    div,
    input {
      color: #fff;
    }

    .form-group.is-focused {
      .checkbox label,
      label.checkbox-inline,
      .radio label,
      label.radio-inline {
        color: #fff;
      }
    }

    hr {
      border-color: #fff;
    }

    .checkbox {
      label {
        color: #fff;

        .checkbox-decorator {
          .check {
            border-color: rgba(255, 255, 255, 0.54);
          }
        }
      }
    }

    input {
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: #fff;
      }
    }
  }
  &_forgot {
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481;
  }
  &_login, &-register {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 450px;
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481;
    @media(max-width: 500px) {
      justify-content: center;
    }
    .card-body {
      width: 100%;
      max-width: 360px;
      margin: 0 auto;
    }

    .card-terms {
      padding-top: 15px;
      padding-bottom: 15px;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 10px;
      text-align: center;
      letter-spacing: -0.02em;

      a {
        font-weight: 600;
        color: #4599F7;
      }
    }

    .card-image {
      margin-top: -42px;
      @media(max-width: 500px) {
        display: none;
      }
    }

    .forgot-link {
      font-size: 1rem;
      font-weight: 600;
      color: #499FFF;
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 30px 20px;
    font-family: 'Assistant', sans-serif;
    letter-spacing: -0.02em;
    color: #004481;
    @media(max-width: 768px) {
      padding: 20px 0 0;
    }
    .card {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 50px 0 0;
      @media(max-width: 768px) {
        padding: 0 20px 0 0;
      }
      background-color: transparent;
      /*  border-right: 0.5px solid #004481; */
      border-radius: 0;
      
      &-body {
        padding: 0;
      }
      &.plans{
        padding: 0 30px 0 0;
        @media(max-width: 768px) {
          padding: 0 10px 0 10px;
        }
       .col:nth-child(2) {
          order: -3;
      }
       .col:nth-child(3) {
          order: -2;
      }
       .col:first-child {
          order: -1;
      }
      }
      &-subtitle {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 18px;
        color: #F37920;
        letter-spacing: -0.02em;
      }

      .btn {
        padding: .52rem 0;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: $colorWhite;
        letter-spacing: -0.02em;
      }

      &-logo {
        display: none;
        width: 126px;
        height: 27px;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &_print {
      max-width: 552px;
      padding: 34px 25px;

      main {
        width: 100%;
      }

      .card {
        padding-left: 0;
        border-left: none;

        &-title {
          > span {
            display: none;
          }
        }

        &-for {
          span {
            display: block;
            font-size: 19px;

            &.card-email {
              padding-right: 20px;
              font-size: 23px;
            }
          }
        }

        &-btn {
          padding-top: 20px;

          .btn {
            display: none;
          }
        }

        &-logo {
          display: block;
        }
      }

      /* Table */
      .table {
        th, td {
          padding: 1.1rem .75rem;
          padding-right: 0;
          border-bottom: none;
        }

        tbody {
          tr {
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  &_main {
    width: 100%;
  }

  &-top {
    display: flex;
    justify-content: space-between;
  }
  &-for {
    font-size: 14px;
    text-transform: initial;
  }
  &-btn {
    padding-top: 12px;

    .btn {
      min-width: 152px;
    }
  }
}

[dir="rtl"] {
  .card {
    text-align: right;

    .card-img {
      .card-title {
        right: 0;
        left: auto;
      }
    }

    .btn-floating {
      &.halfway-fab {
        left: 24px;
        right: auto;
      }
    }

    .card-reveal {
      right: 0;
      left: auto;
    }
  }
}
