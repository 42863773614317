.header-logo-wrap {
  text-align: center;
  margin-bottom: 25px;
  padding-top: 14px;
  img {
    width: 104px;
    margin-bottom: 7px;
   /*  .card-outer & {
      width: 70px;
    } */
  }
  & > b {
    color: #000;
    letter-spacing: 0.2px;
    font-size: 1.2rem;
    font-family: 'Assistant', sans-serif;
  }
}
