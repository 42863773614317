@import "../../color";

.element-header-wrapper {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  align-items: flex-end;
  justify-content: space-between;

  > *:first-child {
    flex: 0 1 auto;
  }
}

.element-header {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 0;
  z-index: 1;

  &:after {
    content: "";
    background-color: $primary;
    width: 25px;
    height: 4px;
    border-radius: 0px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
}
