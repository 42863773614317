.padBot30 {
  padding-bottom: 30px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 18px 20px;
  background-color: rgba(255, 255, 255, .89);
  border-bottom: none;
  z-index: 3;
  &-nav {
    li {
      position: relative;
      margin: 0 !important;
      line-height: normal;

     /*  &:first-child {
        a {
          padding-left: 0;
        }
      } */

      &:last-child {
        a {
          padding-right: 0;
        }

        &:before {
          display: none;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 14px;
        background-color: #000C84;
        top: 0;
        left: 0;
      }

    a {
        padding: 0 15px;
        font-size: 0.785rem;
        line-height: 1rem;
        color: #000C84;
        transition: color .3s;

        @media (min-width: 1200px) {
          &:hover {
            color: #ED7218;
          }
        }
      }
    }
  }
  .header-logo{
    margin: 0 auto;
    @media(max-width: 768px) {
      margin: 5px 0;
    }
    img{
      width: 112px;
    }
  }
}

footer {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #01129E;
  text-align: center;
  .container{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 630px;
  }
  .footer-logo{
    display: block;
    
  }
  ul {
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    li {
      list-style: none;
      display: inline-block;
      vertical-align: middle;
      padding: 0 25px;
      color: #ffffff;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        color: #ffffff;
        transition: color .3s;
      }
    }
  }

  .clouds_orange,
  .clouds_blue {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    // transition: transform .3s;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

@keyframes waveOrange {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes waveBlue {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0);
  }
}

.space-row-10 {
	margin: 0 -5px
}
.space-row-10>[class^="col-"] {
	padding: 0 5px
}

.img-responsive {
  display: block;
  width: 100%;
  height: auto;
}
.rrt-confirm-holder .rrt-confirm{
  padding-bottom: 20px;
}
#cancel-sub .rrt-buttons-holder{
  display: none;
}
