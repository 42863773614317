//variables for social
$social-facebook: #3b5998;
$social-twitter: #55acee;
$social-pinterest: #cc2127;
$social-google: #dd4b39;
$social-linkedin: #0976b4;
$social-dribbble: #ea4c89;
$social-github: #333333;
$social-youtube: #e52d27;
$social-instagram: #125688;
$social-reddit: #ff4500;
$social-tumblr: #35465c;
$social-behance: #1769ff;

$transparent-bg: rgba(200, 200, 200, 0.2) !default;

$heading-color: #000000;
$content-color: #000000;
$background-light-grey: #e8e7e3 !default;
$background-lighter-grey: #f0efeb !default;
$font-background-light-grey: #9c9b99 !default;
$font-hover-background-light-grey: #5e5e5c !default;

$colorWhite: #ffffff;
$colorBlack04: rgba(0, 0, 0, 0.4);
$colorBlack05: rgba(0, 0, 0, 0.5);
$colorBlack06: rgba(0, 0, 0, 0.6);
$colorBlack07: rgba(0, 0, 0, 0.7);
$dark-gray: #e8eaee;
$light-blue: var(--primary);
$dark-blue: var(--primary);

$primary: var(--primary);
$primary-dark: var(--primary-dark);
$primary-light: var(--primary);
$success: #0cc27e;
$info: var(--primary);
$warning: #ff8d60;
$danger: #ff586b;
$secondary: #868e96;
$light: #f8f9fa;
$dark: #343a40;
$black: #000000;
$body-bg: #f5f7fa;
$progress-bar-bg: #d5e8ff;
$progress-bar-fill: $light-blue;
$grey: rgba(0, 0, 0, 0.3);

$tooltip-blue: #3e546d;
$tooltip-description: rgb(134, 142, 150);

